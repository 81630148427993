import _ from 'lodash'
import dateFns from 'date-fns'
import moment from 'moment'

import { handleToast, handleToastServiceError } from '../../../../core/utils/UtilsToast'
import DoctorApi from '../../../../services/apis/doctor/DoctorApi'
import AreaApi from '../../../../services/apis/AreaApi'
import TitleApi from '../../../../services/apis/TitleApi'
import DataConfig from '../config/DataConfig'

const DoctorFormHandler = {
    buildForm: function () {
        this.PrefixDropdown = this.DropDownFormView({
            meta: {
                label: "",
                placeholder: "",
            },
            name: "title",
            stateName: "prefixOptions",
            className: "title",
            control: this.state.Form.controls.title
        })

        this.TextName = this.TextInputFormView({
            label: "",
            validator: "required",
            name: "name",
            placeholder: "Nama Lengkap NaKes",
            control: this.state.Form.controls.name
        })

        this.genderField = this.RadioInputFormView({
            name: "gender",
            meta: {
                label: "Jenis Kelamin",
                validator: "",
                readonly: true,
                className: "hos-doctor",
                options: [
                    {
                        value: "M",
                        name: "Laki-laki",
                    },
                    {
                        value: "F",
                        name: "Perempuan",
                    }
                ]
            },
            control: this.state.Form.controls.gender
        })

        this.dobField = this.DatePickerFormView({
            meta: {
                label: "Tanggal Lahir",
                readonly: false,
                maxDate: new Date(),
                className: 'hos-doctor',
            },
            name: "dob",
            control: this.state.Form.controls.dob
        })

        this.TextSip = this.TextInputFormView({
            label: "No. SIP NaKes",
            name: "sip_no",
            maxLength: 45,
            placeholder: "No. SIP NaKes",
            validator: 'required',
            control: this.state.Form.controls.sip_no
        })

        this.TextEmailDokter = this.TextInputEmailFormView({
            label: "Email NaKes",
            name: "email",
            placeholder: "Email NaKes",
            control: this.state.Form.controls.email
        })

        this.TextStrNo = this.TextInputFormDoctorView({
            label: "No Str NaKes",
            name: "str_no",
            placeholder: "No Str NaKes",
            control: this.state.Form.controls.str_no
        })

        this.SpecialistDropdown = this.DropDownFormView({
            meta: {
                validator: "required",
                label: "Spesialisasi",
                EditshowOnlyText: false,
                placeholder: "Spesialisasi"
            },
            name: "id_doctor_specialist",
            stateName: "specialistOption",
            placeholder: "Spesialisasi",
            control: this.state.Form.controls.id_doctor_specialist
        })

        this.TextTelephone = this.TextInputFormView({
            label: "Telepon NaKes",
            inputType: "tel",
            name: "telephone",
            placeholder: "Telepon NaKes",
            maxLength: 15,
            control: this.state.Form.controls.telephone
        })

        this.TextAddress = this.TextAreaFormView({
            label: "Alamat",
            name: "address",
            placeholder: "Alamat",
            control: this.state.Form.controls.address
        })

        this.ProvinsiDropdown = this.DropDownFormView({
            meta: {
                label: "Provinsi",
                placeholder: "Pilih Provinsi",
            },
            name: "propinsi",
            stateName: "listProvinsi",
            placeholder: "",
            control: this.state.Form.controls.propinsi
        })

        this.KotaDropdown = this.DropDownFormView({
            meta: {
                label: "Kota",
                placeholder: "Pilih Kota",
            },
            name: "kota",
            stateName: "listKota",
            placeholder: "",
            control: this.state.Form.controls.kota
        })

        this.KecamatanDropdown = this.DropDownFormView({
            meta: {
                label: "Kecamatan",
                placeholder: "Pilih Kecamatan",
            },
            name: "kecamatan",
            stateName: "listKec",
            placeholder: "",
            control: this.state.Form.controls.kecamatan
        })

        this.TextProfile = this.RichEditorInputFormView({
            label: "Profile",
            name: "profile",
            control: this.state.Form.controls.profile
        })

        this.EstimationDropdown = this.DropDownFormView({
            meta: {
                label: "Estimasi Penanganan / Pasien",
                placeholder: "Estimasi Waktu"
            },
            name: "estimation_time",
            stateName: "estimationOptions",
            placeholder: "",
            control: this.state.Form.controls.estimation_time
        })

        this.SwitchCanBook = this.SwitchFormView({
            meta: {
                label: "Temu Dokter",
            },
            name: "can_book",
            placeholder: "Temu Dokter",
            control: this.state.Form.controls.can_book
        })

        this.SwitchShowInApps = this.SwitchFormView({
            meta: {
                label: "Tampilkan di Aplikasi",
            },
            name: "show_in_apps",
            placeholder: "Tampilkan di Aplikasi",
            control: this.state.Form.controls.show_in_apps
        })

        this.SwitchVideoCall = this.SwitchFormView({
            meta: {
                label: "Konsultasi Video Call",
            },
            name: "video_call",
            placeholder: "Konsultasi Video Call",
            control: this.state.Form.controls.video_call
        })

        this.SwitchChat = this.SwitchFormView({
            meta: {
                label: "Konsultasi Live Chat",
            },
            name: "chat_status",
            placeholder: "Konsultasi Live Chat",
            control: this.state.Form.controls.chat_status
        })

        this.SwitchOrderChat = this.SwitchFormView({
            meta: {
                label: "Peresepan melalui Chat",
                // callBack: this.disApotekSelect,
            },
            name: "order_from_chat",
            placeholder: "Peresepan melalui Chat",
            control: this.state.Form.controls.order_from_chat
        })

        this.ApotekDropdown = this.DropDownFormApotekView({
            meta: {
                label: "Assign Apotek (Optional)",
                placeholder: "Hubungkan NaKes dengan apotek"
            },
            name: "apotek",
            stateName: "listApotek",
            placeholder: "",
            control: this.state.Form.controls.apotek
        })

        this.ChatType = this.DropDownFormView({
            meta: {
                label: "Tipe Konsultasi",
                placeholder: "Tipe Konsultasi",
                options: DataConfig.chatType
            },
            name: "chat_type",
            control: this.state.Form.controls.chat_type
        })

        this.FreelanceType = this.DropDownFormView({
            meta: {
                label: 'Tipe Freelance',
                placeholder: 'Tipe Freelance',
                options: DataConfig.freelanceType
            },
            name: 'chat_type_freelance',
            control: this.state.Form.controls.chat_type_freelance
        })

        this.graduationDateField = this.DatePickerFormView({
            meta: {
                label: "Tanggal Kelulusan",
                readonly: false,
                maxDate: new Date(),
                className: 'hos-doctor',
            },
            name: "graduation_date",
            control: this.state.Form.controls.graduation_date
        })
        
        this.strNoExpiredField = this.DatePickerFormView({
            meta: {
                label: "Str No Expired",
                readonly: false,
                className: 'hos-doctor',
                validator: "required",
            },
            name: "str_no_expired",
            control: this.state.Form.controls.str_no_expired
        })
        
        this.sipNoExpiredField = this.DatePickerFormView({
            meta: {
                label: "SIP No Expired",
                readonly: false,
                className: 'hos-doctor',
                validator: "required",
            },
            name: "sip_no_expired",
            control: this.state.Form.controls.sip_no_expired
        })

        this.doctorPriceField =   this.TextInputPriceFormView({
            validator: 'required',
            label: 'Harga NaKes',
            name: 'price',
            placeholder: 'Harga NaKes',
            inputType: 'price',
            maxLength: 11,
            control: this.state.Form.controls.price
        })

        this.doctorSpecialPriceField =   this.TextInputPriceFormView({
            validator: 'required',
            label: 'Harga Spesial NaKes',
            name: 'special_price',
            placeholder: 'Harga Spesial NaKes',
            inputType: 'price',
            maxLength: 11,
            control: this.state.Form.controls.special_price
        })
    },
    handleOpenModal(type) {
        this.setState({
            isEdit: false,
            isModalOpen: true,
            ModalActionSelected: type
        })
    },
    handleCloseModal(){
        this.setState({
            isModalOpen: false,
            doctorEditId: null,
            modalNotifyContent: null,
            eduExpId: null,
            universitas: '',
            jurusan: '',
            tahun_lulus: '',
            praktek: '',
            kota: '',
            bulan_tahun_mulai: '',
            bulan_tahun_selesai: '',
            expPresent: false,
            medicalActionsTmpValue: ''
        })
    },
    handleInputChange(field, value) {
        if (field === 'bulan_tahun_mulai') {
            this.setState({ bulan_tahun_selesai: '' })
        }
        this.setState({ [field]: value })
    },
    handleSubmitAdditional(ModalActionSelected){
        let objSave = null
        const {
            educations,
            experiences,
            eduExpId,
            isEdit,
            universitas,
            jurusan,
            tahun_lulus: tahunLulus,
            praktek,
            kota,
            bulan_tahun_mulai: bulanTahunMulai,
            bulan_tahun_selesai: bulanTahunSelesai,
            expPresent,
            medical_actions,
            medicalActionsTmpValue
        } = this.state
        let blnTahunMulai = ''
        let blnTahunSelesai = ''

        if (bulanTahunMulai) {
            let date = bulanTahunMulai

            if (typeof bulanTahunMulai === 'string') {
                const btm = bulanTahunMulai.split('-')
                date = `${btm[1]}-${btm[0]}`
            }

            blnTahunMulai = moment(new Date(date)).format('M-YYYY')
        }

        if (bulanTahunSelesai) {
            let date = bulanTahunSelesai

            if (typeof bulanTahunSelesai === 'string') {
                if (bulanTahunSelesai !== 'sekarang') {
                    const bts = bulanTahunSelesai.split('-')
                    date = moment(new Date(`${bts[1]}-${bts[0]}`)).format('M-YYYY')
                } else {
                    date = ''
                }
            } else {
                date = moment(new Date(date)).format('M-YYYY')
            }

            blnTahunSelesai = date
        }

        const edu = { universitas, jurusan, tahun_lulus: tahunLulus && parseInt(tahunLulus, 10) }
        const exp = {
            praktek,
            kota,
            bulan_tahun_mulai: blnTahunMulai,
            bulan_tahun_selesai: expPresent ? 'sekarang' : blnTahunSelesai,
        }

        if (!isEdit) {
            if (ModalActionSelected === 'education') {
                objSave = _.compact([...educations, edu])
                this.setState({ educations: objSave })
            } else if(ModalActionSelected === 'experience') {
                objSave = _.compact([...experiences, exp])
                this.setState({ experiences: objSave })
            } else if(ModalActionSelected === 'medical_actions'){
                let finalMedicalActions = medical_actions;
                finalMedicalActions.push(medicalActionsTmpValue)
                this.setState({ medical_actions: finalMedicalActions })
            }
        } else {
            let modifyEducation = educations
            let modifyExperiences = experiences
            let modifiedMedicalActions = medical_actions

            if (ModalActionSelected === 'education') {
                modifyEducation[eduExpId] = edu
                this.setState({ educations: modifyEducation })
            } else if(ModalActionSelected === 'experience') {
                modifyExperiences[eduExpId] = exp
                this.setState({ experiences: modifyExperiences })
            } else if(ModalActionSelected === 'medical_actions') {
                modifiedMedicalActions[eduExpId] = medicalActionsTmpValue
                this.setState({ medical_actions: modifiedMedicalActions })
            }
        }

        this.handleCloseModal()
    },
    valueBuilder(data) {
        this.valueObject = {
            id: "",
            name: "",
            email: "",
            gender: "",
            dob: "",
            prefix: "",
            title: "",
            str_no: "",
            sip_no: "",
            id_doctor_specialist: "",
            telephone: "",
            address: "",
            profile: "",
            estimation_time: 0,
            educations: "",
            experiences: "",
            medical_actions: "",
            photo: "",
            image: "",
            can_book: false,
            status: 0,
            chat_status: false,
            show_in_apps: false,
            video_call: false,
            order_from_chat: false,
            chat_type: DataConfig.chatType[0].value,
            chat_type_freelance: DataConfig.freelanceType[0].value,
            apotek: "",
            propinsi: "",
            kota: "",
            kecamatan: "",
        }

        this.getDropdownSpecialist()

        if (data) {
            const provinsi = data.propinsi
            const kota = data.kota
            const kecamatan = data.kecamatan

            if (provinsi.value) {
                this.fetchListKota(provinsi.value, true)
            }

            if (kota.value) {
                this.fetchListKec(kota.value, true)
            }

            this.fillForm(data)
            this.setState({
                apotek: data.apotek,
                idProvinsiTmp: provinsi.value,
                namaProvinsiTmp: provinsi.label,
                idKotaTmp: kota.value,
                namaKotaTmp: kota.label,
                idKecamatanTmp: kecamatan.value,
                namaKecamatanTmp: kecamatan.label,
            })
        }
    },

    redirectToBase: function(e) {
        const me = this
        
        if(e){
            handleToastServiceError(e)
            setTimeout(() => {
                me.props.history.push('/doctor/list')
            }, 3000)
        } else {
            me.props.history.push('/doctor/list')
        }
    },

    fillForm(dataProvider) {
        let me = this

        Object.keys(dataProvider).map(function (key) {
            if (typeof me.valueObject[key] === 'boolean') {
                me.valueObject[key] = !!dataProvider[key]
            } else {
                me.valueObject[key] = dataProvider[key] ? dataProvider[key].toString() : ''
                if (key === 'title') {
                    me.valueObject['title'] = dataProvider[key]
                        ? dataProvider[key].toString()
                        : ''
                } else if (key === 'chat_type') {
                    me.valueObject['chat_type'] = me.valueObject['chat_type']
                        ? me.valueObject['chat_type']
                        : DataConfig.chatType[0].value
                } else if (key === 'educations' || key === 'experiences' || key === 'medical_actions') {
                    if(key === 'medical_actions'){
                        const value = JSON.parse(me.valueObject[key]);
                        me.valueObject[key] = value;
                    }else{
                        me.valueObject[key] = dataProvider[key] || []
                    }
                } else if (key === 'propinsi' || key === 'kota' || key === 'kecamatan') {
                    me.valueObject[key] = dataProvider[key].value
                }
            }
            return true
        })

        this.state.Form.patchValue(me.valueObject)
        me.setState({
            isEdit: true,
            file: me.valueObject['image'],
            experiences: me.valueObject['experiences'],
            educations: me.valueObject['educations'],
            medical_actions: me.valueObject['medical_actions']
        })
    },

    getDropdownSpecialist: async function() {
        let me = this
        try {
            let response = await DoctorApi.getSpecialist()
            let specialists = response.data.data
            me.setState({
                specialistOption: specialists,
            })
        } catch (e) {
            me.redirectToBase(e)
        }
    },

    handleAdditional: function (e) {

        let mode = this.state.additionalMode

        if(mode === 'education'){
            let educations = this.state.educations
            let isEdit = this.state.editEducation
        
            if(isEdit){
                const idx = this.state.currentAdditionalIndex
                educations[idx].value = e.target.value
            }else{
                const theInput = {
                    index: educations.length + 1,
                    value: e.target.value
                }    
                educations.push(theInput)
            }
                
            this.setState({
                educations: educations,
                editEducation: false,
            })
        }else{
            let experiences = this.state.experiences
            let isEdit = this.state.editExperiences
        
            if(isEdit){
                const idx = this.state.currentAdditionalIndex
                experiences[idx].value = e.target.value
            }else{
                const theInput = {
                    index: experiences.length + 1,
                    value: e.target.value
                }    
                experiences.push(theInput)
            }
                
            this.setState({
                experiences: experiences,
                editExperiences: false,
            })
        }

        this.setState({
            currentAdditionalIndex: 0,
            currentAdditionalValue: ''
        })
        this.handleCloseModal()
    },

    handleUpload: function(fileContent, originalFile){
        const tempObj = {
            content: fileContent,
            name: originalFile.name,
            original: originalFile
        }
        this.setState({
            file: tempObj
        })
    },

    handleShow: function(modalShow, mode){
        this.setState({
            modalShow: modalShow,
            additionalMode: mode
        })
    },

    handleConfirmDelete(e, idx, mode){
        e.preventDefault()
        let currentEducation = this.state.educations
        let currentExperience = this.state.experiences
        let currentMedicalActions = this.state.medical_actions
        if(mode === 'education'){
            if(currentEducation[idx]){
                currentEducation.splice(idx, 1)
            }
            this.setState({
                educations: currentEducation
            })
        }else if(mode === 'experience'){
            if(currentExperience[idx]){
                currentExperience.splice(idx, 1)
            }
            this.setState({
                experiences: currentExperience
            })
        } else if(mode === 'medical_actions'){
            if(currentMedicalActions[idx]){
                currentMedicalActions.splice(idx, 1)
            }
            this.setState({
                medical_actions: currentMedicalActions
            })
        }
    },

    handleConfirmDeleteClose: function(){
        this.setState({
            confirmDelete:false,
        })
    },

    handleAdditionalChange: function(additionalState){
        let mode = this.state.additionalMode

        if(mode === 'education'){
            this.setState({educations: additionalState})
        } else if(mode === 'experience') {
            this.setState({experiences: additionalState}) 
        } else if(mode === 'medical_actions') {
            this.setState({
                medical_actions: additionalState
            })
        }
    },

    handleAdditionalEdit: function(idx, mode){
        let modalFields = null
        let expPresent = false
        const educations = this.state.educations
        const experiences = this.state.experiences
        const medicalActions = this.state.medical_actions

        if (mode === 'education') {
            if (educations[idx]) {
                let data = educations[idx]

                if (data.constructor.name === 'Object') {
                    modalFields = {
                        universitas: data.universitas,
                        jurusan: data.jurusan,
                        tahun_lulus: data.tahun_lulus && parseInt(data.tahun_lulus, 10),
                    }
                } else {
                    modalFields = {
                        universitas: data,
                    }
                }
            }
        } else if(mode === 'experience'){
            if (experiences[idx]) {
                let data = experiences[idx]
                expPresent = data.bulan_tahun_selesai === 'sekarang'

                if (data.constructor.name === 'Object') {
                    let bulanTahunMulai = ''
                    let bulanTahunSelesai = ''

                    if (data.bulan_tahun_mulai) {
                        let date = data.bulan_tahun_mulai

                        if (typeof data.bulan_tahun_mulai === 'string') {
                            const btm = data.bulan_tahun_mulai.split('-')
                            date = `${btm[1]}-${btm[0]}`
                        }

                        bulanTahunMulai = new Date(date)
                    }

                    if (data.bulan_tahun_selesai) {
                        let date = data.bulan_tahun_selesai

                        if (typeof data.bulan_tahun_selesai === 'string') {
                            if (data.bulan_tahun_selesai !== 'sekarang') {
                                const bts = data.bulan_tahun_selesai.split('-')
                                date = new Date(`${bts[1]}-${bts[0]}`)
                            } else {
                                date = ''
                            }
                        } else {
                            date = new Date(date)
                        }

                        bulanTahunSelesai = date
                    }

                    modalFields = {
                        praktek: data.praktek,
                        kota: data.kota,
                        bulan_tahun_mulai: bulanTahunMulai,
                        bulan_tahun_selesai: bulanTahunSelesai,
                    }
                } else {
                    modalFields = {
                        praktek: data,
                    }
                }
            }
        }else if(mode === 'medical_actions') {
            const currentEditValue = medicalActions[idx]
            if(currentEditValue){
                modalFields = {
                    medicalActionsTmpValue: currentEditValue
                }
            }
        }

        this.setState({
            eduExpId: idx,
            isModalOpen: true,
            isEdit: true,
            ModalActionSelected: mode,
            expPresent,
            ...modalFields,
        })
    },
    fetchDataByStr(strNo) {
        const hospitalId = this.props.currentHospital
        DoctorApi.checkstr(strNo, hospitalId).then(result => {
            if (result.data.code === 200) {
                this.setState({
                    isModalOpen: true,
                    doctorEditId: result.data.data.id,
                    isFetchingDoctorByStr: false,
                    modalNotifyContent: 'can_create'
                })
                this.valueBuilder(result.data.data)
            } else if (result.data.code === 203) {
                this.setState({
                    isModalOpen: true,
                    doctorEditId: result.data.data.id,
                    isFetchingDoctorByStr: false,
                    modalNotifyContent: 'can_create_new'
                })
                this.getDropdownSpecialist()
            } else if (result.data.code === 202) {
                this.setState({
                    isModalOpen: true,
                    isFetchingDoctorByStr: false,
                    doctorEditId: result.data.data.id,
                    modalNotifyContent: 'unable_create'
                })
            }
        }).catch(error => {
            let code = error.status
            let message = error.message
            const errData = error.response

            if (!_.isEmpty(errData)) {
                code = errData.data.code
                message = errData.data.message
            }

            this.setState({
                isModalOpen: true,
                isFetchingDoctorByStr: false,
                modalNotifyContent: code === 400 ? 'reach_max' : 'unable_create',
                modalNotifyMessage: message,
            })
        })
    },
    fetchListApotek(){
        let firstOpt = [{
            value: "",
            label: "None"
        }]
        DoctorApi.listApotek().then(result => {
            if(result.data.data){
                const apotekOpt = result.data.data.map((item) => {
                    return {
                        value: item.code,
                        label: `${item.name} (${item.code}) - ${item.type}`,
                    }
                })

                const opt = firstOpt.concat(apotekOpt)
                this.setState({
                    listApotek: opt,
                    listApotekPath: process.env.REACT_APP_API_HOST.replace('admin', 'lists/pharmacy?search='),
                
                })
            }
        })
    },
    fetchListProvince() {
        let firstOpt = [{
            value: '',
            label: 'Pilih Provinsi'
        }]

        AreaApi.provinsi().then(result => {
            if (result.data.data) {
                const opt = firstOpt.concat(result.data.data)
                this.setState({
                    listProvinsi: opt,
                    listKota: [],
                })
            }
        }).catch(() => {
            handleToastServiceError({ message: 'Something went wrong' })
        })
    },
    fetchListKota(provId, resetVal) {
        let firstOpt = [{
            value: '-',
            label: 'Pilih Kota'
        }]

        if (resetVal) {
            this.state.Form.patchValue({
                kota: '-',
                kecamatan: '-',
            })
        } else {
            this.setState({ listKota: firstOpt })
        }

        if (provId) {
            AreaApi.kota(provId).then(result => {
                if (result.data.data) {
                    const opt = firstOpt.concat(result.data.data)
                    this.setState({ listKota: opt })
                }
            }).catch(() => {
                handleToastServiceError({ message: 'Something went wrong' })
            })
        }
    },
    fetchListKec(kotaId, resetVal) {
        let firstOpt = [{
            value: '-',
            label: 'Pilih Kecamatan'
        }]

        if (resetVal) {
            this.state.Form.patchValue({ kecamatan: '-' })
        } else {
            this.setState({ listKec: firstOpt })
        }

        if (kotaId) {
            AreaApi.kecamatan(kotaId).then(result => {
                if (result.data.data) {
                    const opt = firstOpt.concat(result.data.data)
                    this.setState({ listKec: opt })
                }
            }).catch(() => {
                handleToastServiceError({ message: 'Something went wrong' })
            })
        }
    },
    fetchListTitle: async function () {
        try {
            const response = await TitleApi.get()
            const prefixOptions = response.data.data

            this.setState({ prefixOptions })
        } catch (e) {
            handleToastServiceError(e)
        }
    },
    redirectTo(url){
        this.setState({
            redirectTo: url
        })
    },
    handleAdditionalDelete: function(){
        let idx = this.state.currentAdditionalIndex
        let mode = this.state.additionalMode
        
        if(mode === 'education'){
            const educations = this.state.educations
            let newEducations = []

            Object.keys(educations).map(function(i){        
                if(parseInt(i, 10) !== idx){
                    newEducations.push(educations[i])
                }
                return true
            }) 
            this.setState({
                educations: newEducations
            })
        }else if(mode === 'experience'){
            const experiences = this.state.experiences
            let newExperiences = []

            Object.keys(experiences).map(function(i){        
                if(parseInt(i, 10) !== idx){
                    newExperiences.push(experiences[i])
                }
                return true
            }) 
            this.setState({
                experiences: newExperiences
            })
        }        

        this.handleConfirmDeleteClose()
    },
    handleSubmit: async function (e) {
        e.preventDefault()
        let formValue = Object.assign({}, this.state.Form.value)
        
        let response
        let formElement = ['estimation_time', 'id_doctor_specialist', 'title']

        this.setState({ loading: true })

        formElement.map((v) => {
            if(formValue[v].label){
                formValue[v] = formValue[v].value
            }
            return true
        })

        const specialityName = _.find(this.state.specialistOption, {
            value: parseInt(formValue['id_doctor_specialist'], 10)
        }).label
        formValue['speciality_name'] = specialityName

        let idKota = this.state.idKota || this.state.idKotaTmp
        let namaKota = this.state.namaKota || this.state.namaKotaTmp
        let idKecamatan = this.state.idKecamatan || this.state.idKecamatanTmp
        let namaKecamatan = this.state.namaKecamatan || this.state.namaKecamatanTmp

        if (this.state.idProvinsi === '' || this.state.idKota === '-') {
            idKota = 0
            namaKota = ''
            idKecamatan = 0
            namaKecamatan = ''
        } else if (this.state.idKecamatan === '-') {
            idKecamatan = 0
            namaKecamatan = ''
        }

        //assign custom value
        formValue['telephone'] = formValue['telephone'].replace(/[^0-9]/g, '')
        formValue['photo_filename'] = formValue.photo
        // formValue['photo'] = this.state.file.content
        formValue['photo'] = this.state.foto
        formValue['id_hospital'] = this.props.currentHospital
        formValue['id_propinsi'] = this.state.idProvinsi || this.state.idProvinsiTmp
        formValue['nama_propinsi'] = this.state.namaProvinsi || this.state.namaProvinsiTmp
        formValue['id_kota'] = idKota
        formValue['nama_kota'] = namaKota
        formValue['id_kecamatan'] = idKecamatan
        formValue['nama_kecamatan'] = namaKecamatan
        formValue['educations'] = this.state.educations
        formValue['experiences'] = this.state.experiences
        formValue['medical_actions'] = this.state.medical_actions
        formValue['estimation_time'] = parseInt(formValue['estimation_time'], 10)
        formValue['id_doctor_specialist'] = parseInt(formValue['id_doctor_specialist'], 10)

        //assign form data
        delete(formValue['status'])
        delete(formValue['propinsi'])
        delete(formValue['kota'])
        delete(formValue['kecamatan'])

        formValue['apotek'] = !this.state.selectedApotek ? "": this.state.selectedApotek.value
        // let optApotek = ''
        // if (formValue['apotek'] instanceof Object !== false) {
        //     if (formValue['apotek'].value !== '') {
        //         optApotek = formValue['apotek'].value
        //     }
        // } else if (this.state.apotek !== null) {
        //     optApotek = this.state.apotek
        // }
        // formValue['apotek'] = optApotek

        if (formValue['chat_type_freelance'] instanceof Object !== false) {
            formValue['chat_type_freelance'] = formValue['chat_type_freelance'].value
        }

        if (!this.state.isEdit) {
            delete(formValue['id'])
        }else{
            formValue['id'] = formValue['id_doctor']
        }

        if(!formValue['str_no']){
            formValue['str_no'] = this.props.match.params.strno
        }

        formValue['doctor_status'] = formValue['doctor_status'] ? 1 : 0

        if(!formValue['chat_status']){
            formValue['chat_type'] = ''
            formValue['order_from_chat'] = false
        }else{
            if(formValue['chat_type'] instanceof Object !== false){
                formValue['chat_type'] = formValue['chat_type'].value
            }

            if(formValue['order_from_chat'] instanceof Object !== false){
                formValue['order_from_chat'] = formValue['order_from_chat'].value
            }

            // if (formValue['order_from_chat']) {
            //     formValue['apotek'] = ''
            // }
        }

        if (!formValue['show_in_apps']) {
            formValue['video_call'] = false
            formValue['can_book'] = false
            delete (formValue['estimation_time'])
        } else {
            if (formValue['video_call'] instanceof Object !== false) {
                formValue['video_call'] = formValue['video_call'].value
            }

            if (formValue['can_book'] instanceof Object !== false) {
                formValue['can_book'] = formValue['can_book'].value
            }
        }

        if (formValue['chat_type'] !== 'Freelance') {
            formValue['chat_type_freelance'] = ''
        }

        if (formValue.dob) {
            formValue.dob = dateFns.format(formValue.dob, 'YYYY-MM-DD')
        }

        if (formValue.graduation_date) {
            formValue.graduation_date = dateFns.format(formValue.graduation_date, 'YYYY-MM-DD')
        }
        if (formValue.str_no_expired) {
            formValue.str_no_expired = dateFns.format(formValue.str_no_expired, 'YYYY-MM-DD')
        }
        if (formValue.sip_no_expired) {
            formValue.sip_no_expired = dateFns.format(formValue.sip_no_expired, 'YYYY-MM-DD')
        }

        formValue.price = parseInt(formValue.price.replace(/\./g, '').substring(0, 9), 10)
        formValue.special_price = parseInt(formValue.special_price.replace(/\./g, '').substring(0, 9), 10)

        try {
            response = await DoctorApi.post(formValue)
        } catch (e) {
            handleToastServiceError(e)
            this.setState({ loading: false })
            return
        }

        if (response.data.code !== 200) {
            handleToastServiceError(response.data.message)
            return
        }

        if (!formValue['can_book']) {
            setTimeout(() => {
                handleToast(response.data.message)
            }, 0)
        }

        this.setState({
            loading: false,
            postStatus: true,
            can_book: formValue['can_book'],
            id: response.data.data.id
        })
    },
    disApotekSelect: function(value) {
        const chatStatus = this.state.Form.value.chat_status

        if (chatStatus && value) {
            this.state.Form.get('apotek').disable()
            this.state.Form.patchValue({ apotek: '' })
        } else {
            this.state.Form.get('apotek').enable()
        }
    },
    validationEnd(currentDate, minDate) {
        let date = minDate
        if (typeof minDate === 'string') {
            const btm = minDate.split('-')
            date = `${btm[1]}-${btm[0]}`
        }

        return currentDate.isSameOrAfter(moment(new Date(date)))
            && currentDate.isBefore(moment(new Date()))
    },
}

export default DoctorFormHandler