import React, { Component } from 'react'

class ContentTemplate extends Component 
{
    render() {
        return (
            <div>
                {this.props.children}
            </div>
        )
    }
}

export default ContentTemplate

// {React.cloneElement(this.props.children, { loggedIn: true })}