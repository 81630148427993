import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { loginMapStateToProps, loginMapDispatchToProps } from '../../../../core/const/LoginConst'
import ArrowDown from '../../../../themes/v1/img/ico-arrow-down.png'

class Information extends Component
{
    constructor(props) {
        super(props)

        this.state = {
            educations: props.educations,
            experiences: props.experiences,
            itemsToShow: {
                educations: 2,
                experiences: 2
            },
            expanded: {
                educations: false,
                experiences: false
            }
        }
    }

    showMore(type) {
        const { educations, experiences, itemsToShow, expanded } = this.state
        const theState = (type === 'educations') ? educations.length : experiences.length
        let theExpanded = {}
        if (type === 'educations') {
            theExpanded = {
                educations: (itemsToShow.educations === 2) ? true : false,
                experiences: expanded.experiences
            }

            itemsToShow.educations === 2 ? (
                this.setState({
                    itemsToShow: {
                        educations: theState,
                        experiences: itemsToShow.experiences,
                    },
                    expanded: theExpanded,
                })
            ) : (
                this.setState({
                    itemsToShow: {
                        educations: 2, 
                        experiences: itemsToShow.experiences,
                    },
                    expanded: theExpanded,
                })
            )
        } else {
            theExpanded = {
                experiences: (itemsToShow.experiences === 2) ? true : false,
                educations: expanded.educations
            }

            itemsToShow.experiences === 2 ? (
                this.setState({
                    itemsToShow: {
                        educations: itemsToShow.educations,
                        experiences: theState,
                    },
                    expanded: theExpanded,
                })
            ) : (
                this.setState({
                    itemsToShow: {
                        educations: itemsToShow.educations,
                        experiences: 2,
                    },
                    expanded: theExpanded,
                })
            )
        }
    }

    render(){
        const { educations, experiences, itemsToShow, expanded } = this.state

        if(this.props.loadingData){
            return(<div>Loading...</div>)
        }
        const doctorData = this.props.doctorData

        return(
            <div className="tab-information">
                <div className="information-contact">
                    <h1>Informasi NaKes</h1>
                    <div className="row">
                        <span className="label">Nama</span>
                        <span className="content">{ doctorData.name }</span>
                    </div>
                    <div className="row">
                        <span className="label">email</span>
                        <span className="content">{ doctorData.email }</span>
                    </div>
                    <div className="row">
                        <span className="label">No. STR</span>
                        <span className="content">{ doctorData.str_no }</span>
                    </div>
                    <div className="row">
                        <span className="label">No. SIP</span>
                        <span className="content">{ doctorData.sip_no }</span>
                    </div>
                    <div className="row">
                        <span className="label">Telepon</span>
                        <span className="content">{this.props.telephone}</span>
                    </div>
                    <div className="row">
                        <span className="label">Alamat</span>
                        <span className="content">{this.props.address}</span>
                    </div>                    
                </div>
                <div className="information-additional">
                    <h1>Pendidikan</h1>
                    <ul>
                        {educations.slice(0, itemsToShow.educations).map((item, i) => (
                            <li key={i}>
                                { `${item.universitas}${item.jurusan
                                    ? ` - ${item.jurusan}` : ''}${item.tahun_lulus
                                        ? `, ${item.tahun_lulus}` : ''}`}
                            </li>
                        ))}
                    </ul>
                    <button
                        className={(educations.length <= 2) ? 'no-display' : 'showmore'}
                        onClick={e => this.showMore('educations')}
                    >
                        {
                            expanded.educations
                                ? (<span>Tampilkan sebagian</span>)
                                : (<span>Tampilkan semua <img src={ArrowDown} alt="" /></span>)
                        }
                    </button>
                    <h1>Pengalaman</h1>
                    <ul>
                        {experiences.slice(0, itemsToShow.experiences).map((item, i) => {
                            let bulanTahunMulai = ''
                            let bulanTahunSelesai = ''

                            if (item.bulan_tahun_mulai) {
                                let date = item.bulan_tahun_mulai
                                if (typeof item.bulan_tahun_mulai === 'string') {
                                    const btm = item.bulan_tahun_mulai.split('-')
                                    date = `${btm[1]}-${btm[0]}`
                                }
                                bulanTahunMulai = moment(new Date(date)).format('MM-YYYY')
                            }

                            if (item.bulan_tahun_selesai) {
                                let date = item.bulan_tahun_selesai

                                if (typeof item.bulan_tahun_selesai === 'string') {
                                    if (item.bulan_tahun_selesai !== 'sekarang') {
                                        const bts = item.bulan_tahun_selesai.split('-')
                                        date = moment(new Date(`${bts[1]}-${bts[0]}`)).format('MM-YYYY')
                                    } else {
                                        date = 'Sekarang'
                                    }
                                } else {
                                    date = moment(new Date(date)).format('MM-YYYY')
                                }

                                bulanTahunSelesai = date
                            }

                            return (
                                <li key={i}>
                                    {`${item.praktek}${item.kota
                                        ? ` - ${item.kota}` : ''}${item.bulan_tahun_mulai
                                            ? `, ${bulanTahunMulai} - ${bulanTahunSelesai}`
                                            : ''}`}
                                </li>
                            )
                        })}
                    </ul>
                    <button
                        className={(experiences.length <= 2) ? 'no-display' : 'showmore'}
                        onClick={e => this.showMore('experiences')}
                    >
                        {expanded.experiences
                            ? (<span>Tampilkan sebagian</span>)
                            : (<span>Tampilkan semua <img src={ArrowDown} alt="" /></span>)
                        }
                    </button>
                </div>
            </div>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Information)