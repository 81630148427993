import { axiosBase } from './MasterConfig'

const axios = axiosBase()

const groupUrl = 'master/patient'

export default class PatientMAPi {
    static list = (page, pageSize, sortProperties, sortValue, filterData) => {
        let filter = '';
        const sortP = sortProperties ? `&sort=${sortProperties}` : ''
        const sortV = sortValue ? `&order=${sortValue}` : ''

        if (filterData !== '{}') {
            filter = `&filter=${filterData}`
        }

        return axios.get(`/${groupUrl}?page=${page}&limit=${pageSize}${sortP}${sortV}${filter}`)
    }
    static detail = (id) => axios.get(`/${groupUrl}/${id}`)
    static filter = () => axios.get(`/${groupUrl}/filter`)
}
