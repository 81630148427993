import React from 'react'
import _ from 'lodash'
import dateFns from 'date-fns'
import moment from 'moment'
import Chance from 'chance'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'
import { faTrash, faPencilAlt, faEllipsisV } from '@fortawesome/free-solid-svg-icons'

import BankApi from '../../../../services/apis/BankApi'
import DoctorApi from '../../../../services/apis/doctor/DoctorApi'
import HospitalApi from '../../../../services/apis/HospitalApi'
import TitleApi from '../../../../services/apis/TitleApi'
import { handleToast, handleToastServiceError } from '../../../../core/utils/UtilsToast'

const chance = new Chance()

const saveData = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (data, fileName) {
        const blob = new Blob([data], { type: "octet/stream" }),
            url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}())

const GridHandler = {
    onPanelClose() {
        this.setState({
            isPaneOpen: false,
            panelData: {},
            selectedHospital: [],
            fileList: [],
            formApprFaskesTouched: false,
        })
    },
    onPanelOpen(data) {
        const me = this
        const headTitle = <div>{`${data.approval === 'pending' ? 'Approval' : 'Detail'} NaKes`}</div>
        const { hospitalList } = me.state

        DoctorApi.detailMaster(data.id).then(function (response) {
            const {
                data: {
                    data,
                },
            } = response

            me.getTitle(data.title || '')
            me.getSpecialist(data.id_doctor_specialist || '')
            me.loadBank(data.rekening_bank || '')

            if (me.state.hospitalList.length === 0 && data.approval === 'pending') {
                me.getHospital()
            }

            const selectedHospital = _.filter(hospitalList, ({ value }) => _.every([
                _.includes(data.hospital_ids, value),
            ]));
            const fileList = [
                {
                    key: 1,
                    name: "STR NaKes",
                    file: data.file_str,
                },
                {
                    name: "SIP NaKes",
                    key: 2,
                    file: data.file_sip,
                },
                {
                    name: "NPWP",
                    key: 3,
                    file: data.file_npwp,
                },
                {
                    name: "Buku Tabungan",
                    key: 4,
                    file: data.file_rekening,
                },
            ]

            // if (data.approval === 'approved') {
            //     me.state.FormEditDoctor.get('email').disable()
            // } else {
            //     me.state.FormEditDoctor.get('email').enable()
            // }

            if (data.approval !== 'pending') {
                data.selected_hospital = data.hospital_ids
            } else {
                data.selected_hospital = []
            }

            data.educations = data.educations || []
            data.experiences = data.experiences || []

            setTimeout(() => {
                me.state.FormEditDoctor.patchValue(data)
                me.setState({ isPaneOpen: true })
            }, 0);

            me.setState({
                panelData: {
                    headTitle,
                    ...data,
                },
                selectedHospital,
                photoProfile: data.photo,
                fileList: fileList.filter(data => data.file !== '')
            })
        }, function (err) {
            handleToastServiceError(err)
        })
    },
    buildForm() {
        this.titleField = this.DropDownFormView({
            meta: {
                label: "",
                placeholder: "",
            },
            name: "title",
            stateName: "prefixOptions",
            className: "title",
            control: this.state.FormEditDoctor.controls.title
        })

        this.genderField = this.RadioInputFormView({
            name: "gender",
            meta: {
                label: "Jenis Kelamin",
                validator: "",
                readonly: true,
                className: "appr-doctor",
                options: [
                    {
                        value: "M",
                        name: "Laki-laki",
                    },
                    {
                        value: "F",
                        name: "Perempuan",
                    }
                ]
            },
            control: this.state.FormEditDoctor.controls.gender
        })

        this.dobField = this.DatePickerFormView({
            meta: {
                label: "Tanggal Lahir",
                readonly: false,
                maxDate: new Date()
            },
            name: "dob",
            control: this.state.FormEditDoctor.controls.dob
        })

        this.specialistField = this.DropDownFormView({
            meta: {
                validator: "required",
                label: "Spesialisasi",
                EditshowOnlyText: false,
                placeholder: "Spesialisasi"
            },
            name: "id_doctor_specialist",
            stateName: "specialistOption",
            placeholder: "Spesialisasi",
            control: this.state.FormEditDoctor.controls.id_doctor_specialist
        })

        this.nameField = this.TextInputFormView({
            label: "",
            validator: "required",
            name: "name",
            placeholder: "Nama Lengkap NaKes",
            control: this.state.FormEditDoctor.controls.name
        })

        this.emailField = this.TextInputEmailFormView({
            label: "Email",
            validator: "required",
            name: "email",
            placeholder: "Email",
            inputType: "email",
            control: this.state.FormEditDoctor.controls.email,
        })

        this.telField = this.TextInputPhoneFormView({
            label: "No. Telepon",
            inputType: "tel",
            name: "telephone",
            placeholder: "No. Telepon",
            maxLength: 15,
            control: this.state.FormEditDoctor.controls.telephone
        })

        this.strField = this.TextInputFormView({
            validator: "required",
            label: "Nomor STR NaKes",
            name: "str_no",
            placeholder: "Nomor STR NaKes",
            control: this.state.FormEditDoctor.controls.str_no
        })

        this.sipField = this.TextInputFormView({
            label: "Nomor SIP NaKes",
            name: "sip_no",
            placeholder: "Nomor SIP NaKes",
            maxLength: 35,
            control: this.state.FormEditDoctor.controls.sip_no
        })

        this.npwpNameField = this.TextInputFormView({
            label: "Nama Pemilik NPWP",
            validator: "required",
            name: "npwp_name",
            placeholder: "Nama Pemilik NPWP",
            control: this.state.FormEditDoctor.controls.npwp_name
        })

        this.rekeningNameField = this.TextInputFormView({
            label: "Nama Pemilik Rekening",
            validator: "required",
            name: "rekening_name",
            placeholder: "Nama Pemilik Rekening",
            control: this.state.FormEditDoctor.controls.rekening_name
        })

        this.bankField = this.DropDownFormView({
            meta: {
                label: "Rekening Bank",
                EditshowOnlyText: false,
                placeholder: "Rekening Bank",
            },
            name: "rekening_bank",
            stateName: "bankOption",
            control: this.state.FormEditDoctor.controls.rekening_bank
        })

        this.noRekField = this.TextInputFormView({
            label: "Nomor Rekening Bank",
            inputType: "tel",
            name: "rekening_no",
            placeholder: "Nomor Rekening Bank",
            maxLength: 20,
            control: this.state.FormEditDoctor.controls.rekening_no
        })
    },
    gridColumn: function (ContextMenuProvider) {
        const { dataLogin } = this.props
        return [
            {
                Header: "No. STR",
                accessor: "str",
                maxWidth: 190,
                filterable: false,
                filterType: 'str',
                headerClassName: "-sort",
                Cell: (row) => {
                    return (<div className="row-float">{row.original.str || '-'}</div>)
                }
            },
            {
                Header: "Nama",
                accessor: "doctor_name",
                filterable: false,
                headerClassName: "-sort",
                Cell: row => {
                    if (this.props.isDashboard) {
                        return (
                            <div className="row-float">
                                {`${row.original.title || ''} ${row.original.doctor_name}`}
                            </div>
                        )
                    } else {
                        return (
                            <div className="row-float">
                                <NavLink
                                    className={row.original.draft ? 'is-draft' : 'name-nondraft'}
                                    to={'/doctor/detail/' + row.original.id}
                                >
                                    {row.original.draft
                                        ? <strong>{'[DRAFT] '}</strong>
                                        : `${row.original.title} `}
                                    {row.original.doctor_name}
                                </NavLink>
                            </div>
                        )
                    }
                }
            },
            {
                Header: "Spesialisasi",
                accessor: "specialist_name",
                headerClassName: "-sort",
                Cell: (row) => {
                    return (
                        <div className="row-float">{row.original.specialist_name}</div>
                    )
                }
            },
            {
                Header: "Email",
                accessor: "email",
                maxWidth: 250,
                filterable: false,
                headerClassName: "-sort",
                Cell: (row) => {
                    return (<div className="row-float">{row.original.email || '-'}</div>)
                }
            },
            {
                Header: "Channel",
                accessor: "channel",
                headerClassName: `-sort center-th${this.props.isDashboard ? '' : ' hidden'}`,
                className: "center-td",
                maxWidth: 110,
                show: this.props.isDashboard,
                Cell: (row) => {
                    let icon = 'monitor'

                    if (row.original.channel === 'android') {
                        icon = 'android'
                    } else if (row.original.channel === 'ios') {
                        icon = 'apple'
                    }

                    return <img
                        src={`${process.env.REACT_APP_URL}/img/ico-${icon}.svg`}
                        alt={icon} />
                },
            },
            {
                Header: "Approval",
                accessor: "approval",
                headerClassName: `-sort center-th${this.props.isDashboard ? '' : ' hidden'}`,
                className: "center-td",
                maxWidth: 110,
                show: this.props.isDashboard,
                Cell: (row) => {
                    let badgeApprove = 'badge-danger'

                    if (row.original.approval === 'rejected') {
                        badgeApprove = 'badge-default'
                    } else if (row.original.approval === 'approved') {
                        badgeApprove = 'badge-success'
                    }

                    return (
                        <div className="badge-label">
                            <span
                                className={
                                    `badge badge-pill ${badgeApprove}`
                                }
                            >
                                {_.startCase(row.original.approval)}
                            </span>
                        </div>
                    )
                }
            },
            {
                Header: this.props.isDashboard ? "Verifikasi" : "Aktivasi",
                accessor: "active",
                headerClassName: `-sort center-th${!this.props.isDashboard ? '' : ' hidden'}`,
                className: "center-td",
                maxWidth: 110,
                show: !this.props.isDashboard,
                Cell: row => {
                    let status = false

                    if (row.original.active) {
                        status = true
                    }

                    return (
                        <div className="badge-label">
                            <span
                                className={
                                    `badge badge-pill ${status ? 'badge-success' : 'badge-danger'}`
                                }
                            >
                                {status ? "Sudah" : "Belum"}
                            </span>
                        </div>
                    )
                }
            },
            {
                Header: "Aktivasi",
                accessor: "active",
                headerClassName: `-sort center-th${this.props.isDashboard ? '' : ' hidden'}`,
                className: "center-td",
                maxWidth: 110,
                show: this.props.isDashboard,
                Cell: row => {
                    let status = false

                    if ((this.props.isDashboard && row.original.active) || row.original.verified) {
                        status = true
                    }

                    return (
                        <div className="badge-label">
                            <span
                                className={
                                    `badge badge-pill ${status ? 'badge-success' : 'badge-danger'}`
                                }
                            >
                                {status ? "Sudah" : "Belum"}
                            </span>
                        </div>
                    )
                }
            },
            {
                Header: "Aplikasi",
                accessor: "aplikasi",
                headerClassName: `-sort center-th${!this.props.isDashboard ? '' : ' hidden'}`,
                className: "center-td",
                maxWidth: 110,
                show: !this.props.isDashboard,
                Cell: row => {
                    let status = false

                    if (row.original.show_in_apps) {
                        status = true
                    }

                    return (
                        <div className="badge-label">
                            <span
                                className={
                                    `badge badge-pill ${status ? 'badge-success' : 'badge-danger'}`
                                }
                            >
                                {status ? "Ya" : "Tidak"}
                            </span>
                        </div>
                    )
                }
            },
            {
                Header: "Booking",
                accessor: "booking",
                headerClassName: `-sort center-th${!this.props.isDashboard ? '' : ' hidden'}`,
                className: "center-td",
                maxWidth: 110,
                show: !this.props.isDashboard,
                Cell: row => {
                    let status = false

                    if (row.original.booking) {
                        status = true
                    }

                    return (
                        <div className="badge-label">
                            <span
                                className={
                                    `badge badge-pill ${status ? 'badge-success' : 'badge-danger'}`
                                }
                            >
                                {status ? "Ya" : "Tidak"}
                            </span>
                        </div>
                    )
                }
            },
            {
                sortable: false,
                maxWidth: 50,
                headerClassName: `${!this.isNurse ? '' : ' hidden'}`,
                show: !this.isNurse ? true : false,
                filterable: false,
                filterType: 'reset',
                Cell: row => {
                    let idMenu = ''
                    if (row.original.active !== false || _.isEmpty(row.original.email)) {
                        idMenu = 'menu_id'
                    } else {
                        idMenu = 'menu_activated_id'
                    }

                    // /mdoctor/edit/' + row.original.id
                    if (this.props.isDashboard) {
                        return (
                            <ContextMenuProvider
                                id={row.original.approval === 'pending'
                                    ? 'menu_dashboard_pending_id'
                                    : 'menu_dashboard_detail_id'}
                                data={row.original}
                                event="onClick"
                            >
                                <button className="action-row">
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                </button>
                            </ContextMenuProvider>
                        )
                    } else if (row.original.draft) {
                        return (
                            <NavLink
                                to={'/doctor/schedule/' + row.original.id}
                                className="action-row"
                            >
                                <span
                                    className="dot-action"
                                    data-for="input-data"
                                    data-tip='Lanjutkan isi data'
                                >
                                    <ReactTooltip
                                        id='input-data'
                                        place="top"
                                        className="cinnamon-tooltip grid"
                                    />
                                </span>
                            </NavLink>
                        )
                    }

                    return (
                        <ContextMenuProvider
                            id={idMenu}
                            className={!row.original.draft ? "" : "no-display"}
                            data={row.original} event="onClick">
                            <button className="action-row">
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                        </ContextMenuProvider>
                    )
                }
            },
            {
                filterable: false,
                sortable: false,
                width: 50,
                headerClassName: `${!this.isNurse ? '' : ' hidden'}`,
                show: (!this.isNurse) ? true : false,
                Cell: row => {
                return (
                    dataLogin.user_type === "a" ? "" :
                    <button
                        className={
                            ((!this.props.isDashboard && !row.original.draft)
                                || (this.props.isDashboard && row.original.allow_delete))
                                ? "action-row"
                                : "no-display"
                        }
                        onClick={() => this.handleClick('delete', row.original)}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                )
            }
            }
        ]
    },
    handleClick: function (action, theRow) {
        let ModalData = null
        let contentModal = `Hapus NaKes <strong>${theRow.title || ''} ${theRow.doctor_name}</strong> akan otomatis menghapus jadwal di RS/Klinik ini sekaligus.`
        const { history } = this.props

        if (this.props.isDashboard) {
            contentModal = `Anda akan menghapus data NaKes <strong>${theRow.title || ''} ${theRow.doctor_name}</strong>. Apakah Anda Yakin?`
        }

        switch (action) {
            case 'delete':
                ModalData = {
                    action: "delete_doctor",
                    id: theRow.id,
                    content: contentModal,
                    buttonConfirmText: this.props.isDashboard ? "Ya, Hapus NaKes" : "Ok",
                    buttonCancelText: "Batalkan",
                    ConfirmFunction: null
                }
                this.handleOpenModal(ModalData)
                break
            case 'activate':
                ModalData = {
                    action: "reactivate_doctor",
                    id: theRow.id,
                    emailToReactivate: theRow.email,
                    content: `Kirim email aktivasi ulang ke <br /> ${theRow.email}. Apakah Anda Yakin?`,
                    buttonConfirmText: "Ya, Kirim ulang email",
                    buttonCancelText: "Batalkan",
                    ConfirmFunction: null
                }
                this.handleOpenModal(ModalData)
                break
            case 'edit':
                this.setState({ dataProvider: theRow, editTrigger: true })
                this.updateStatusState()
                history.push('/doctor/edit/' + theRow.id)
                window.scrollTo(0, 0);
                break
            case 'doctor_reject':
                ModalData = {
                    action: 'reject',
                    id: theRow.id,
                    content: `Apakah anda yakin ingin menolak <strong>${theRow.title || ''} ${theRow.doctor_name}</strong>?<br/>Status <strong>${theRow.title || ''} ${theRow.doctor_name}</strong> akan menjadi ditolak`,
                    buttonConfirmText: 'Ya',
                    buttonCancelText: 'Tidak',
                    ConfirmFunction: null
                }
                this.handleOpenModal(ModalData)
                break
            default:
                history.push('/doctor/schedule/' + theRow.id)
                window.scrollTo(0, 0);
                break
        }
    },
    bulkDelete: function () {
        let me = this
        let deleted = []
        let id = 0
        const {
            selection,
            data,
        } = this.state

        selection.forEach(value => {
            let foundData = data.filter(obj => obj[this.keyTable] === value)
            id = foundData[0].id
            deleted.push(id)
        })

        DoctorApi.bulkDelete({ 'ids': deleted }, this.props.isDashboard).then(function (response) {
            if (response.status !== 200) {
                handleToastServiceError({ message: 'Something went wrong' })
                return
            } else if (response.data.status === false) {
                handleToastServiceError(response.data)
                return
            }

            const state = {
                pageSize: me.pageSize,
                page: me.page,
                sorted: me.sorted,
                filtered: me.state.filtered,
            }

            me.fetchData(state, null)
            me.setState({ data: data, deleted: deleted, selection: [], fetchingGrid: false })
            me.handleCloseModal()

            handleToast(response.data.message)
        }, function (err) {
            handleToastServiceError(err, () => {
                me.handleCloseModal()
            })
        })
    },
    handleOpenModal(ModalData) {
        this.setState({
            isEdit: false,
            isModalOpen: true,
            ModalData: ModalData,
            activeIndex: ModalData.index || 0,
        })
    },
    handleCloseModal() {
        this.setState({
            isModalOpen: false,
            ModalData: null,
            activeIndex: 0,
            eduExpSubmit: false,
            universitas: '',
            jurusan: '',
            tahun_lulus: '',
            praktek: '',
            kota: '',
            bulan_tahun_mulai: '',
            bulan_tahun_selesai: '',
            expPresent: false,
        })
    },
    modalHandleSubmit() {
        let me = this
        const ModalData = me.state.ModalData

        if (ModalData.action === 'delete_doctor') {
            DoctorApi.delete(ModalData.id, this.props.isDashboard).then(function (response) {
                if (response.status !== 200) {
                    handleToastServiceError({ message: 'Something went wrong' })
                    return
                } else if (response.data.status === false) {
                    handleToastServiceError(response.data)
                    return
                }

                const state = {
                    pageSize: me.pageSize,
                    page: me.page,
                    sorted: me.sorted,
                    filtered: me.state.filtered,
                }

                me.fetchData(state, null)
                handleToast(response.data.message)
                me.handleCloseModal()
            }, function (err) {
                handleToastServiceError(err)
                me.handleCloseModal()
            })
        } else if (ModalData.action === 'reactivate_doctor') {
            const JsonData = {
                email: ModalData.emailToReactivate
            }
            DoctorApi.reactivate(JsonData).then((response) => {
                let state = {
                    pageSize: me.pageSize,
                    page: me.page,
                    sorted: me.sorted,
                    filter: ''
                }
                me.fetchData(state, null)
                handleToast(response.data.message)
                me.handleCloseModal()
            })
        } else if (ModalData.action === 'delete_doctor_bulk') {
            this.bulkDelete()
        }
    },
    bruteBulkDownloadTrigger() {
        const allSelected = this.checkboxTable.props.selectAll
        if (!allSelected) {
            this.checkboxTable.props.toggleAll()
        }

        this.setState({
            bulkBruteDownload: true
        })
    },

    downloadHandler() {
        let { selection, data, bulkBruteDownload } = this.state
        let downloaded = []
        let me = this
        let id = 0
        let theObject = {}

        if (bulkBruteDownload) {
            theObject = {
                ids: 'all',
                id_hospital: this.props.currentHospital
            }
        } else {
            selection.forEach(value => {
                let foundData = data.filter(obj => obj[this.keyTable] === value)
                id = foundData[0].id
                downloaded.push(id)
            })

            theObject = {
                ids: downloaded.join(),
                id_hospital: this.props.currentHospital
            }
        }

        DoctorApi.bulkDownload(JSON.stringify(theObject)).then(function (response) {
            if (response.status !== 200) {
                handleToast('Something went wrong')
                return
            }
            //now download the file
            saveData(response.data, 'List_NaKes.csv')
            me.setState({
                fetchingGrid: false
            })
            let state = {
                pageSize: me.pageSize,
                page: me.page,
                sorted: me.sorted,
                filter: ''
            }
            me.fetchData(state, null)
            me.setState({ data: data, downloaded: downloaded, selection: [] })
            me.handleCloseModal()

        }, function (err) {
            // handleToast('Something went wrong ' + err.message)
            handleToastServiceError(err, () => {
                me.handleCloseModal()
            })
        })

    },

    clearBruteDownload() {
        if (this.state.bulkBruteDownload) {
            this.checkboxTable.props.toggleAll()
            this.setState({
                bulkBruteDownload: false,
                selection: []
            })
        }
    },

    fetchData: async function (state, instance) {
        this.setState({ loading: true })
        this.pageSize = state.pageSize
        this.page = state.page
        let sort_property = ''
        let sort_value = ''
        let res
        let fArray = {}
        const idHospital = this.props.currentHospital

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'desc' : 'asc'
            this.sorted = state.sorted
        }

        if (state.filtered && state.filtered.length > 0) {
            state.filtered.map(val =>
                fArray[val.id] = val.value
            )
        }

        const filterData = JSON.stringify(fArray)

        try {
            res = await DoctorApi.get(
                state.page + 1,
                state.pageSize,
                sort_property,
                sort_value,
                filterData,
                idHospital,
                this.props.isDashboard,
            )

            this.setState({ selection: [] })
        } catch (e) {
            this.setState({
                loading: false
            })
            handleToastServiceError(e)
            return
        }

        if (React.isValidElement(res)) {
            return
        }

        if (res.data.code === 200) {
            let newRows = res.data.data.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })

            this.setState({
                data: newRows,
                pages: res.data.data.total_page,
                rows: res.data.data.total_rows,
                loading: false,
                disableHighlight: this.state.disableHighlight
            })

        }
    },

    refreshData() {
        this.fetchData({
            page: this.page,
            pageSize: this.pageSize,
            filter: '',
            sorted: this.sorted,
        })
    },

    handleConfirmModal: function () {
        let me = this
        if (this.state.actionModal === 'bulk') {
            this.bulkDelete()
            return
        }
        DoctorApi.delete(this.state.deletedId, me.props.currentHospital).then(function (response) {
            let state = {
                pageSize: me.pageSize,
                page: me.page,
                sorted: me.sorted,
                filter: ''
            }
            me.fetchData(state, null)
            handleToast(response.data.message)
            me.handleCloseModal()
        }, function (err) {
            handleToastServiceError(err, () => {
                me.handleCloseModal()
            })
        })
    },
    getFilter: async function (isDashboard) {
        try {
            let response = { data: {} }
            const idHospital = this.props.currentHospital

            if (isDashboard) {
                response = await DoctorApi.doctorFilter()
            } else {
                response = await DoctorApi.doctorFilterHospital(idHospital)
            }

            const { data } = response.data
            const filterList = data

            this.setState({ filterList })
        } catch (e) {
            handleToastServiceError(e)
        }
    },
    getTitle: async function (title) {
        if (this.state.prefixOptions.length === 0) {
            try {
                const response = await TitleApi.get()
                const prefixOptions = response.data.data

                this.setState({ prefixOptions })
                this.state.FormEditDoctor.patchValue({ title })
            } catch (e) {
                handleToastServiceError(e)
            }
        }
    },
    getSpecialist: async function (id_doctor_specialist) {
        if (this.state.specialistOption.length === 0) {
            try {
                const response = await DoctorApi.getSpecialist()
                const specialistOption = response.data.data

                this.setState({ specialistOption })
                this.state.FormEditDoctor.patchValue({ id_doctor_specialist })
            } catch (e) {
                handleToastServiceError(e)
            }
        }
    },
    getHospital: async function (keyword) {
        this.setState({ isSelectLoading: true })

        await HospitalApi.getHospital(keyword).then((res) => {
            const { data } = res.data

            if (res.status === 200) {
                this.setState({
                    hospitalList: data,
                    hospitalListState: data,
                    isSelectLoading: false,
                })
            }
        })
    },
    doctorReject: function (e) {
        e.preventDefault()

        const { Form, panelData } = this.state
        const { reason, others } = Form.value
        const setReason = {
            reason: !_.isEmpty(others) ? others : reason
        }

        DoctorApi.reject(panelData.id, setReason).then((res) => {
            const { message } = res.data

            if (res.status === 200) {
                this.fetchData({
                    page: this.page,
                    pageSize: this.pageSize,
                    filtered: this.state.filtered,
                    sorted: this.sorted,
                })

                handleToast(message)
            }

            this.handleCloseModal()
            this.onPanelClose()

            setTimeout(() => {
                Form.reset()
            }, 500);
        }, (e) => {
            handleToastServiceError(e)
        })
    },
    doctorUpdate: function (e) {
        e.preventDefault()

        const { panelData, FormEditDoctor, fileChanged } = this.state
        const formValue = FormEditDoctor.value
        formValue.email = formValue.email || panelData.email
        formValue.approval = formValue.approval === 'pending'
        formValue.npwp = !_.isEmpty(formValue.npwp)
            ? (formValue.npwp).replace(/[.-]/g, '')
            : 0

        if (formValue.approval) {
            formValue.hospital_ids = formValue.selected_hospital.map(v => {
                return v.value
            })
        } else {
            formValue.hospital_ids = formValue.selected_hospital
        }

        if (formValue.dob) {
            formValue.dob = dateFns.format(formValue.dob, 'YYYY-MM-DD')
        }

        if (formValue.title !== null && typeof formValue.title === 'object') {
            formValue.title = formValue.title.value
        }

        formValue.specialist = formValue.id_doctor_specialist
        if (formValue.id_doctor_specialist !== null && typeof formValue.id_doctor_specialist === 'object') {
            formValue.specialist = formValue.id_doctor_specialist.value
        }

        if (formValue.rekening_bank !== null && typeof formValue.rekening_bank === 'object') {
            formValue.rekening_bank = formValue.rekening_bank.value
        }

        if (!fileChanged) {
            delete formValue.photo
        }

        delete formValue.id_doctor_specialist
        delete formValue.selected_hospital
        // if(!formValue.npwp_name){
        //     alert('Please Check & Input  Npwp Name.'
        //     )
        // }else if(!formValue.npwp){
        //     alert('Please Check & Input  Npwp.'
        //     )
        // }else if(!formValue.str_no){
        //     alert('Please Check & Input  STR No.'
        //     )
        // }else if(!formValue.rekening_bank){
        //     alert('Please Check & Input Rekening Bank.'
        //     )
        // }else if(!formValue.rekening_name){
        //     alert('Please Check & Input Rekening Name.'
        //     )
        // }else if(!formValue.rekening_no){
        //     alert('Please Check & Input Rekening No.'
        //     )
        // }else{
        DoctorApi.updateMaster(panelData.id, formValue).then((res) => {
            if (res.status === 200) {
                setTimeout(() => {
                    this.fetchData({
                        page: this.page,
                        pageSize: this.pageSize,
                        filtered: this.state.filtered,
                        sorted: this.sorted,
                    })
                }, 800);

                handleToast(res.data.message)
                this.setState({ fileChanged: false })
                this.onPanelClose()
            }
        }).catch(e => {
            let message = e.message
            const errData = e.response

            if (!_.isEmpty(errData) && errData.data !== '') {
                message = errData.data.message
                if (!_.isArray(errData.data.data)) {
                    message = Object.keys(errData.data.data).map(k => errData.data.data[k]).join('\n')
                }
            }

            handleToastServiceError({ message })
        })
    // }
    },
    handleInputChange(field, value) {
        if (field === 'bulan_tahun_mulai') {
            this.setState({ bulan_tahun_selesai: '' })
        }
        this.setState({ [field]: value })
    },
    handleSubmitAdditional(ModalActionSelected) {
        let objSave = null
        const {
            FormEditDoctor,
            eduExpId,
            isEdit,
            universitas,
            jurusan,
            tahun_lulus: tahunLulus,
            praktek,
            kota,
            bulan_tahun_mulai: bulanTahunMulai,
            bulan_tahun_selesai: bulanTahunSelesai,
            expPresent,
        } = this.state
        let blnTahunMulai = ''
        let blnTahunSelesai = ''

        if (bulanTahunMulai) {
            let date = bulanTahunMulai

            if (typeof bulanTahunMulai === 'string') {
                const btm = bulanTahunMulai.split('-')
                date = `${btm[1]}-${btm[0]}`
            }

            blnTahunMulai = moment(new Date(date)).format('M-YYYY')
        }

        if (bulanTahunSelesai) {
            let date = bulanTahunSelesai

            if (typeof bulanTahunSelesai === 'string') {
                if (bulanTahunSelesai !== 'sekarang') {
                    const bts = bulanTahunSelesai.split('-')
                    date = moment(new Date(`${bts[1]}-${bts[0]}`)).format('M-YYYY')
                } else {
                    date = ''
                }
            } else {
                date = moment(new Date(date)).format('M-YYYY')
            }

            blnTahunSelesai = date
        }

        const formValue = FormEditDoctor.value
        const edu = { universitas, jurusan, tahun_lulus: tahunLulus }
        const exp = {
            praktek,
            kota,
            bulan_tahun_mulai: blnTahunMulai,
            bulan_tahun_selesai: expPresent ? 'sekarang' : blnTahunSelesai,
        }

        this.setState({ eduExpSubmit: true })

        if (!isEdit) {
            if (ModalActionSelected === 'education') {
                if (!this.isNotValid(edu)) {
                    objSave = _.compact([...formValue.educations, edu])
                    FormEditDoctor.patchValue({ educations: objSave })
                    this.handleCloseModal()
                }
            } else {
                if (!this.isNotValid(exp)) {
                    objSave = _.compact([...formValue.experiences, exp])
                    FormEditDoctor.patchValue({ experiences: objSave })
                    this.handleCloseModal()
                }
            }
        } else {
            let modifyEducation = formValue.educations
            let modifyExperiences = formValue.experiences

            if (ModalActionSelected === 'education') {
                if (!this.isNotValid(edu)) {
                    modifyEducation[eduExpId] = edu
                    FormEditDoctor.patchValue({ educations: modifyEducation })
                    this.handleCloseModal()
                }
            } else {
                if (!this.isNotValid(exp)) {
                    modifyExperiences[eduExpId] = exp
                    FormEditDoctor.patchValue({ experiences: modifyExperiences })
                    this.handleCloseModal()
                }
            }
        }
    },
    handleAdditionalEdit: function (idx, mode) {
        let modalFields = null
        let expPresent = false
        const { FormEditDoctor } = this.state
        const formValue = FormEditDoctor.value
        const educations = formValue.educations
        const experiences = formValue.experiences

        if (mode === 'education') {
            if (educations[idx]) {
                let data = educations[idx]

                if (data.constructor.name === 'Object') {
                    modalFields = {
                        universitas: data.universitas,
                        jurusan: data.jurusan,
                        tahun_lulus: data.tahun_lulus && parseInt(data.tahun_lulus, 10),
                    }
                } else {
                    modalFields = {
                        universitas: data,
                    }
                }
            }
        } else {
            if (experiences[idx]) {
                let data = experiences[idx]
                expPresent = data.bulan_tahun_selesai === 'sekarang'

                if (data.constructor.name === 'Object') {
                    let bulanTahunMulai = ''
                    let bulanTahunSelesai = ''

                    if (data.bulan_tahun_mulai) {
                        let date = data.bulan_tahun_mulai

                        if (typeof data.bulan_tahun_mulai === 'string') {
                            const btm = data.bulan_tahun_mulai.split('-')
                            date = `${btm[1]}-${btm[0]}`
                        }

                        bulanTahunMulai = new Date(date)
                    }

                    if (data.bulan_tahun_selesai) {
                        let date = data.bulan_tahun_selesai

                        if (typeof data.bulan_tahun_selesai === 'string') {
                            if (data.bulan_tahun_selesai !== 'sekarang') {
                                const bts = data.bulan_tahun_selesai.split('-')
                                date = new Date(`${bts[1]}-${bts[0]}`)
                            } else {
                                date = ''
                            }
                        } else {
                            date = new Date(date)
                        }

                        bulanTahunSelesai = date
                    }

                    modalFields = {
                        praktek: data.praktek,
                        kota: data.kota,
                        bulan_tahun_mulai: bulanTahunMulai,
                        bulan_tahun_selesai: bulanTahunSelesai,
                    }
                } else {
                    modalFields = {
                        praktek: data,
                    }
                }
            }
        }

        this.setState({
            eduExpId: idx,
            isModalOpen: true,
            isEdit: true,
            ModalData: { action: mode },
            expPresent,
            ...modalFields,
        })
    },
    handleConfirmDelete(e, idx, mode) {
        e.preventDefault()
        const { FormEditDoctor } = this.state
        const formValue = FormEditDoctor.value
        let currentEducation = formValue.educations
        let currentExperience = formValue.experiences

        if (mode === 'education') {
            if (currentEducation[idx]) {
                currentEducation.splice(idx, 1)
            }

            FormEditDoctor.patchValue({ educations: currentEducation })
        } else {
            if (currentExperience[idx]) {
                currentExperience.splice(idx, 1)
            }

            FormEditDoctor.patchValue({ experiences: currentExperience })
        }
    },
    loadBank(rekening_bank) {
        const me = this
        const { bankOption } = me.state

        if (bankOption.length === 0) {
            BankApi.list().then(function (response) {
                const {
                    data: {
                        data,
                    },
                } = response

                me.setState({ bankOption: data })
                me.state.FormEditDoctor.patchValue({ rekening_bank })
            })
        }
    },
    downloadFile(link, fileName) {
        fetch(link)
            .then(response => {
                if (response.status === 200) {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob)
                        let a = document.createElement('a')
                        a.href = url
                        a.download = fileName
                        a.click()
                    })
                } else {
                    handleToastServiceError({
                        message: 'File not found.'
                    })
                }
            });
    },
    isNotValid: function (obj) {
        return undefined !== Object.keys(obj).find(key => {
            const optional = ['bulan_tahun_mulai', 'bulan_tahun_selesai'].includes(key)
            return !optional && obj[key] === ''
        })
    },
    validationEnd(currentDate, minDate) {
        let date = minDate
        if (typeof minDate === 'string') {
            const btm = minDate.split('-')
            date = `${btm[1]}-${btm[0]}`
        }

        return currentDate.isSameOrAfter(moment(new Date(date)))
            && currentDate.isBefore(moment(new Date()))
    },
}

export default GridHandler
