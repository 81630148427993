import { isEmpty, isArray } from 'lodash'
import dateFns from "date-fns"
import IndonesianLocale from 'date-fns/locale/id'

import Api from '../../../services/apis/Appointment/Main'
import Config from '../config/DataConfig'
import { handleToastServiceError } from '../../../core/utils/UtilsToast'

const Handler = {
    isValidDate(dateStr) {
        let string = dateStr.split('-')
        const year = parseInt(string[0], 0)
        const month = parseInt(string[1], 0)
        const day = parseInt(string[2], 0)
        var d = new Date(year, month - 1, day)
        
        if (d.getFullYear() === year && (d.getMonth() + 1) === month && d.getDate() === day) {
            return true
        }
        return false
    },
    onPanelOpen(){
        this.setState({
            FormPatientShow: false,
            patientFilter: {
                dob: null,
                fullname: null
            },
            isPaneOpen: true
        })
    },
    onPanelClose(){
        this.setState({
            isPaneOpen: false
        })
    },
    onHandleBack(){
        this.setState({
            FormPatientShow: false
        })
    },
    handleShowAddPatient(){
        this.setState({
            FormPatientShow: true
        })
    },
    fetchScheduleInfo(ScheduleId){
        Api.view(ScheduleId).then((result) => {
            if(result.data.data && result.data.data.doctor_data){
                const doctor_data = result.data.data.doctor_data
                const schedule_data = result.data.data
                this.setState({
                    isLoading: false,
                    scheduleData: {
                        ...this.state.scheduleData,
                        doctorId: doctor_data.id,
                        doctorName: doctor_data.name,
                        date: dateFns.format(this.props.match.params.chosenDate, 'D MMMM YYYY', {locale:IndonesianLocale}) ,
                        time: `${ schedule_data.time_start } - ${schedule_data.time_end}`
                    }
                })
            }
        })
    },
    handleChangeDob(pickedDate){
        this.setState({
            patientFilter: {
                ...this.state.patientFilter,
                dob: pickedDate
            }
        })
    },
    handleChangeName(fullname) {
        this.setState({
            patientFilter: {
                ...this.state.patientFilter,
                fullname,
            }
        })
    },
    handleChangePhone(phone) {
        this.setState({
            patientFilter: {
                ...this.state.patientFilter,
                phone,
            }
        })
    },
    handleAssignPatient(patientObj){
        this.props.onSavePatient(patientObj)
        this.setState({
            isPaneOpen: false
        })
    },
    handleBookAppointment(){
        this.setState({
            isSubmiting: true
        })
        const hospitalId = this.props.currentHospital
        const doctorId = this.props.scheduleData.doctorId
        const patientId = this.props.chosenPatient.id

        const postJson = {
            patient_id: patientId,
            doctor_id: doctorId,
            date: this.ChosenDate,
            schedule_id: parseInt(this.ScheduleId),
            hospital_id: hospitalId,
            time_start: this.state.patientSelectSch
                ? this.state.schSelected
                : '',
        }

        Api.createBooking(JSON.stringify(postJson)).then((result) => {
            if(result.data.data){
                this.props.onSaveAppointment(result.data.data)
                this.setState({
                    redirectTo: Config.resultUrl
                })        
            }else{
                handleToastServiceError({
                    message: 'Booking sudah terbuat untuk pasien terpilih'
                })
            }

            this.setState({
                isSubmiting: false
            })
        }).catch(e => {
            let message = e.message
            const errData = e.response

            if (!isEmpty(errData) && errData.data !== '') {
                message = errData.data.message
                if (!isArray(errData.data.data)) {
                    message = Object.keys(errData.data.data).map(k => errData.data.data[k]).join('\n')
                }
            }

            this.setState({ isSubmiting: false })
            handleToastServiceError({ message })
        })
    }
}

export default Handler