import axios, { axiosBase } from '../MasterConfig'

const axiosB = axiosBase()

const groupUrl = "hospital";
const listTypeUrl = "hospital_type"

class List {
    static get = (page, pageSize, sortProperties, sortValue, filterData) => axios.get(`/${groupUrl}/list?page=${page}&sort=${sortProperties}&order=${sortValue}&limit=${pageSize}&filter=${filterData}`)
    static post = (data) => axios.post(`/${groupUrl}/create`, data)
    static delete = (id) => axios.delete(`/${groupUrl}/delete/${id}`)
    static update = (id, data) => axios.put(`/${groupUrl}/update/${id}`, data)
    static bulkDelete = (data) => axios.post(`/${groupUrl}/bulkdelete`, data)
    static view = (id) => axios.get(`/${groupUrl}/get/${id}`)
    static listType = () => axios.get(`/${listTypeUrl}/list?page=1&limit=200`)
    static updateFormPatient = (id, data) => axios.put(`/${groupUrl}/updatepatientform/${id}`, data)
    static detail = (id) => axios.get(`/${groupUrl}/detail/${id}`)
    static save = (data) => axios.post(`/${ groupUrl }/save`, data)
    static reactivate = (data) => axios.post(`/${ groupUrl }/reactivatespv`, data)
    static getHospitalDashboard = (page, pageSize, sortProperties, sortValue, filterData) => {
        let setSort = ''

        if (sortProperties) {
            setSort = `&sort=${sortProperties}&order=${sortValue || 'desc'}`
        }

        return axiosB.get(`/${groupUrl}?page=${page}${setSort}&limit=${pageSize}&filter=${filterData}`)
    }
    static getInfoHospital = (idHospital) => axiosB.get(`/${groupUrl}/dashboard/info/${idHospital}`)
    static getAppointmentSumm = (idHospital, period) => axiosB.get(`/${groupUrl}/dashboard/appointment/${idHospital}?period=${period}`)
    static getSchDoctor = (idHospital, period) => axiosB.get(`/${groupUrl}/dashboard/doctor/${idHospital}?period=${period}`)
    static getActiveDoctor = (idHospital) => axiosB.get(`/${groupUrl}/dashboard/schedule/${idHospital}`)
    static getQueueDoctor = (idHospital, schId) => axiosB.get(`/${groupUrl}/dashboard/queue/${idHospital}?schedule_id=${schId}`)
    static getPatient = (idHospital, type, period) => {
        let setPeriod = '';
        if (period) {
            setPeriod = `&period=${period}`
        }

        return axiosB.get(`/${groupUrl}/dashboard/patient/${idHospital}?type=${type}${setPeriod}`)
    }
}
export default List;