import Api from '../../../../../services/apis/ServicesM'
import SpecialistApi from '../../../../../services/apis/SpecialitiesAPI'
import CategoryListApi from '../../../../../services/apis/CategoryListApi'
import {
    handleToastServiceError
} from '../../../../../core/utils/UtilsToast'

const FormHandler = {
    buildForm() {
        this.DropDownKategoriDate = this.DropDownFormView({
            meta: {
                label: "Kategori Tanggal",
                placeholder: "Pilih Kategori",
            },
            name: "kategori_date",
            nameText: "kategori_date",
            control: this.state.Form.controls.kategori_date,
            stateName: 'dateRange',
        })
    },
    buildValue(data) {
        console.log(data)
        this.state.Form.patchValue(data)
    },
    handleUpload(file) {
        this.setState({ file })
        this.state.Form.patchValue({ photo: file })
    },
    handleChangeDataSelect(data) {
        console.log(data)
        const { Form } = this.state
        Form.patchValue({ specialist_ids: data })
        this.setState({
            specialistSelected: data,
            specialistFieldTouched: true,
        })
    },
    handleDateRangePicker(data){
        console.log(data)
    },
    DateRange(data) {
        console.log(data)
    },
    handleSubmit: async function (e) {
        e.preventDefault();
        this.setState({ loading: true })
        const {dataFaskes, dataKategori, startDate, endDate } = this.state
        const formValue = Object.assign({}, this.state.Form.value)
        if(startDate.length === 0){
            alert("Silahkan isi Tanggal")
            window.location = '/report-layanan'
        }else{
        try {
            Api.download(JSON.stringify({
                date:{type: formValue.kategori_date.value, start: startDate, end: endDate},
                hospital_id: dataFaskes.value,
                category_id: dataKategori.value
            })).then((response) =>
            {
                if(response){
                    this.setState({ loading: false })
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    const blob = new Blob([response.data], {type: "octet/stream"}),
                    url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = `Layanan-${startDate}-${endDate}.csv`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    // window.location = '/'
                }else{
                    this.setState({ loading: false })
                    handleToastServiceError({message: "Gagal Download Csv"})
                    alert("Gagal Download Csv")
                    // window.location = '/'
                }
            });
        } catch (response) {
            this.setState({ loading: false })
            handleToastServiceError(response)
            // window.location = '/'
        }
    }
    },
    getCategoryList(){
        let me = this
        if(!this.props.previewMode){
            let requestApi =  CategoryListApi.parentService()
            requestApi.then(response => {
                me.setState({
                    categoryListOpt: response.data.data.rows.map(el => {
                        return {
                            value: el.id,
                            label: el.name
                        }
                    }),
                })
            }).catch( error => {
                console.log(error)
            })
        }
    },
    getFilter(){
        let me = this
        if(!this.props.previewMode){
            let requestApi =  Api.getFilterExport()
            requestApi.then(response => {
                let range = response.data.data.date.options.map(el => {
                    return {
                        value: el.value,
                        label: el.label
                    }
                })
                let faskes = response.data.data.hospital_id.options.map(el => {
                    return {
                        value: el.value,
                        label: el.label
                    }
                })
                let category = response.data.data.category_id.options.map(el => {
                    return {
                        value: el.value,
                        label: el.label
                    }
                })
                me.setState({
                    dateRange: range,
                    faskes: faskes,
                    category: category,
                    FaskesAll: response.data.data.hospital_id,
                    faskesPath: response.data.data.hospital_id.path,
                    categoryAll: response.data.data.category_id,
                    categoryPath: response.data.data.category_id.path,
                })
            }).catch( error => {
                console.log(error)
            })
        }
    },
    getSpecialistList(){
        let me = this
        if(!this.props.previewMode){
            let requestApi =  SpecialistApi.specialist()
            requestApi.then(response => {
                me.setState({
                    specialistOpt: response.data.data.rows.map(el => {
                        return {
                            value: el.value,
                            label: el.label
                        }
                    }),
                })
            }).catch( error => {
                console.log(error)
            })
        }
    },
}

export default FormHandler