import { toast } from 'react-toastify'

export const handleToast = (message, setType) => {
    let type = 'success'
    if(setType){
        type = setType
    }
    toast(message, {
        className: `cinnamon-toastify ${ type }`,
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        draggablePercent: 50
    })
}
/**
 *
 * @param e
 * @param fn
 */
export const handleToastServiceError = (e, fn) => {
    toast.error((typeof e.response !== 'undefined' ? e.response.data.message : e.message), {
        position: "top-center",
        className: "cinnamon-toastify warning",
        autoClose: 5000,
        hideProgressBar: true,
        pauseOnHover: false,
        closeOnClick: true,
        draggable: false,
        draggablePercent: 50,
        onClose: () => {
            if(typeof fn === 'function'){
                fn()
            }

            if(typeof e.response === 'undefined'){
                return
            }

            if (e.response.status === 401 || e.response.status === 403) {
                window.location = '/'
            }

            if (e.response.status === 498) {
                window.location = '/'
            }
        }
    })
}
