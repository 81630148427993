import React from "react";
import { Route, Switch } from "react-router-dom";
//packages
import Login from "../package/login/Login";
import Logout from "../package/login/Logout";
import ForgotPass from "../package/login/ForgotPass";
import ResetPass from "../package/login/ResetPass";
import Activation from "../package/login/Activation";
import DoctorConfirmation from "../package/login/DoctorConfirm";
import DoctorConfirmationSuccess from "../package/login/component/DoctorConfirm/DoctorConfirmSuccess";
import DoctorConfirmationFail from "../package/login/component/DoctorConfirm/DoctorConfirmFail";
import DashboardFacility from "../package/dashboard/DashboardFacility";
import DashboardFixAccount from "../package/dashboard/DashboardFixAccount";
import DashboardTestingEmail from "../package/dashboard/DashboardTestingEmail";
import DashboardTitle from "../package/dashboard/DashboardTitle";
import DashboardServices from "../package/dashboard/DashboardServices";
import DashboardSpecialist from "../package/dashboard/DashboardSpecialist";
import DashboardChangePass from "../package/dashboard/DashboardChangePass";
import DashboardMain from "../package/dashboard/DashboardMain";
import DashboardDoctor from "../package/dashboard/DashboardDoctor";
import DashboardPatient from "../package/dashboard/DashboardPatient";
import DashboardUser from "../package/dashboard/DashboardUser";
import DashboardRapidTest from "../package/dashboard/DashboardRapidTest";
import DashboardDaftarAppointment from "../package/dashboard/DashboardDaftarAppointment";
import DashboardReportAppointment from "../package/dashboard/DashboardReportAppointment";
import DashboardReportFaskes from "../package/dashboard/DashboardReportFaskes";
import DashboardDiagnosis from "../package/dashboard/DashboardDiagnosis";
import DashboardSubscription from "../package/dashboard/DashboardSubscription";
import DashboardSubscriptionFaskes from "../package/dashboard/DashboardSubscriptionFaskes";
import DashboardRoleSetting from "../package/dashboard/DashboardRoleSetting";
import DashboardListFaskes from "../package/dashboard/DashboardListFaskes";
import DashboardDoctorInformation from "../package/dashboard/DashboardDoctorInformation";
import DashboardDaftarLayanan from "../package/dashboard/DashboardDaftarLayanan";
import DashboardSpesialities from "../package/dashboard/DashboardSpesialities";
import RoleAdd from "../package/dashboard/components/superadmin/setting/FormRoleAdd";
import DashboardHospital from "../package/dashboard/DashboardHospital";
import VerifyClinic from "../package/dashboard/VerifyClinic";
import UserMain from "../package/administration/UserMain";
import DsmapMain from "../package/dsmap/DsmapMain";
import AddMap from "../package/dsmap/AddMap";
import PageNotFound from "../template/PageNotFound";
import PageUnauthorized from "../template/PageUnauthorized";
import UserForm from "../package/administration/UserForm";
import UnauthorizeTemplate from "../template/UnauthorizeTemplate";
import DoctorSpecialistMain from "../package/doctor/specialist/DoctorSpecialistMain";
import AddDoctorSpecialist from "../package/doctor/specialist/AddDoctorSpecialist";
import DoctorMain from "../package/doctor/list/Main";
import DoctorDetail from "../package/doctor/detail/Detail";
import PreAddDoctor from "../package/doctor/list/PreAdd";
import AddDoctor from "../package/doctor/list/Add";
import EditDoctor from "../package/doctor/list/Edit";
import Queue from "../package/doctor/list/Queue";
import DoctorScheduleMain from "../package/doctor/schedule/Main";

//packages
import HospitalMain from "../package/hospital/Main";
import HospitalAdd from "../package/hospital/Add";
import HospitalTypeMain from "../package/hospitalType/Main";
import HospitalTypeAdd from "../package/hospitalType/Add";
import HospitalFacilityMain from "../package/hospitalFacility/Main";
import HospitalFacilityAdd from "../package/hospitalFacility/Add";

import PatientMain from "../package/patient/Main";
import PatientAdd from "../package/patient/Add";
import PatientFormBuilder from "../package/patient/FormBuilder";

import EMRCreate from "../package/emr/Create";
import EMRList from "../package/emr/List";

import BillingList from "../package/billing/List";
import BillingDetail from "../package/billing/Detail";

import Appointment from "../package/appointment/Main";
import AppointmentAssign from "../package/appointment/Assign";
import AppointmentResult from "../package/appointment/Result";
import ServiceFeeList from "../package/serviceFee/Main";
import ServicesList from "../package/services/List";
import ServicesScheduleMain from "../package/services/schedule/Main";
import ServiceFeeCreate from "../package/serviceFee/Create";
import InfoHospital from "../package/infoHospital/Main";
import ChangePassword from "../package/changePassword/Main";

import AppointmentList from "../package/appointmentList/Main";

import SPGList from "../package/administration/Spg/Main";
import SPGForm from "../package/administration/Spg/Form";

import SpecialitiesList from "../package/specialities/List";
import DashboardReportLayanan from "../package/dashboard/DashboardReportLayanan";
import DashboardReportNakes from "../package/dashboard/DashboardReportNakes";

const handling = (store) => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route exact path="/logout" component={Logout} />
    <Route exact path="/forgot-pass" component={ForgotPass} />
    <Route exact path="/reset-password/:verification" component={ResetPass} />
    <Route exact path="/activation/:verification" component={Activation} />
    <Route
      exact
      path="/confirmation/:tokenId/:HospitalId"
      component={DoctorConfirmation}
    />
    <Route
      exact
      path="/confirmation/success"
      component={DoctorConfirmationSuccess}
    />
    <Route exact path="/confirmation/fail" component={DoctorConfirmationFail} />

    {/* Dashboard Start */}
    <Route
      exact
      path="/dashboard"
      component={(props) => (
        <DashboardMain
          {...props}
          {...store}
          title="Dashboard"
          moduleName="Dashboard"
        />
      )}
    />
    <Route
      exact
      path="/doctor-list"
      component={(props) => {
        return (
          <DashboardDoctor
            {...props}
            {...store}
            title="Data Dokter / Tenaga Medis"
            moduleName="Dashboard"
          />
        );
      }}
    />
    <Route
      exact
      path="/patient-list"
      component={(props) => {
        return (
          <DashboardPatient
            {...props}
            {...store}
            title="Data Pasien"
            moduleName="Dashboard"
          />
        );
      }}
    />
    <Route
      exact
      path="/user-list"
      component={(props) => {
        return (
          <DashboardUser
            {...props}
            {...store}
            title="Data User"
            moduleName="Dashboard"
          />
        );
      }}
    />
    <Route
      exact
      path="/diagnosis"
      component={(props) => {
        return (
          <DashboardDiagnosis
            {...props}
            {...store}
            title="Master Diagnosis"
            moduleName="Dashboard"
          />
        );
      }}
    />
    <Route
      exact
      path="/rapid-test"
      component={(props) => {
        return (
          <DashboardRapidTest
            {...props}
            {...store}
            title="Rapid Test"
            moduleName="Dashboard"
          />
        );
      }}
    />
    <Route
      exact
      path="/daftar-appointment"
      component={(props) => {
        return (
          <DashboardDaftarAppointment
            {...props}
            {...store}
            title="Appointment"
            moduleName="Dashboard"
          />
        );
      }}
    />
    <Route
      exact
      path="/report-appointment"
      component={(props) => {
        return (
          <DashboardReportAppointment
            {...props}
            {...store}
            title="Report Appointment"
            moduleName="Report"
          />
        );
      }}
    />
    <Route
      exact
      path="/report-layanan"
      component={(props) => {
        return (
          <DashboardReportLayanan
            {...props}
            {...store}
            title="Report Layanan"
            moduleName="Report"
          />
        );
      }}
    />
    <Route
      exact
      path="/report-nakes"
      component={(props) => {
        return (
          <DashboardReportNakes
            {...props}
            {...store}
            title="Report Nakes"
            moduleName="Report"
          />
        );
      }}
    />
    <Route
      exact
      path="/report-faskes"
      component={(props) => {
        return (
          <DashboardReportFaskes
            {...props}
            {...store}
            title="Report Faskes"
            moduleName="Report"
          />
        );
      }}
    />
    <Route
      exact
      path="/list-faskes"
      component={(props) => {
        return (
          <DashboardListFaskes
            {...props}
            {...store}
            title="FasKes"
            moduleName="Dashboard"
          />
        );
      }}
    />
    <Route
      exact
      path="/doctor-information"
      component={(props) => {
        return (
          <DashboardDoctorInformation
            {...props}
            {...store}
            title="Tenaga Kesehatan"
            moduleName="Dashboard"
          />
        );
      }}
    />
    <Route
      exact
      path="/subscription/packages"
      component={(props) => {
        return (
          <DashboardSubscription
            {...props}
            {...store}
            title="Pengaturan Paket"
            moduleName="Dashboard"
          />
        );
      }}
    />
    <Route
      exact
      path="/subscription/faskes"
      component={(props) => {
        return (
          <DashboardSubscriptionFaskes
            {...props}
            {...store}
            title="Paket Faskes"
            moduleName="Dashboard"
          />
        );
      }}
    />
    <Route
      exact
      path="/role-list"
      component={(props) => {
        return (
          <DashboardRoleSetting
            {...props}
            {...store}
            title="Pengaturan Peran"
            moduleName="Dashboard"
          />
        );
      }}
    />
    <Route
      exact
      path="/services"
      component={(props) => {
        return (
          <DashboardServices
            {...props}
            {...store}
            title="Kategori Layanan"
            moduleName="Dashboard"
          />
        );
      }}
    />
    <Route
      exact
      path="/specialities-list"
      component={(props) => {
        return (
          <DashboardSpesialities
            {...props}
            {...store}
            title="Kategori Spesialisasi"
            moduleName="Dashboard"
          />
        );
      }}
    />
    <Route
      exact
      path="/role-add"
      component={(props) => {
        return (
          <RoleAdd
            {...props}
            {...store}
            title="Tambah Peran"
            moduleName="Dashboard"
          />
        );
      }}
    />
    <Route
      exact
      path="/fix-account"
      component={(props) => {
        return (
          <DashboardFixAccount
            {...props}
            {...store}
            title="Perbaikan Akun NaKes / Tenaga Medis"
            moduleName="Dashboard"
          />
        );
      }}
    />
    <Route
      exact
      path="/facility-list"
      component={(props) => {
        return (
          <DashboardFacility
            {...props}
            {...store}
            title="Daftar Fasilitas"
            moduleName="Dashboard"
          />
        );
      }}
    />
    <Route
      exact
      path="/title-list"
      component={(props) => {
        return (
          <DashboardTitle
            {...props}
            {...store}
            title="Daftar Gelar"
            moduleName="Dashboard"
          />
        );
      }}
    />
    <Route
      exact
      path="/specialist-list"
      component={(props) => {
        return (
          <DashboardSpecialist
            {...props}
            {...store}
            title="Daftar Spesialisasi"
            moduleName="Dashboard"
          />
        );
      }}
    />
    <Route
      exact
      path="/testing-email"
      component={(props) => {
        return (
          <DashboardTestingEmail
            {...props}
            {...store}
            title="Testing Email"
            moduleName="Dashboard"
          />
        );
      }}
    />
    <Route
      exact
      path="/change-password"
      component={(props) => {
        return (
          <DashboardChangePass
            {...props}
            {...store}
            title="Ubah Password"
            moduleName="Setting"
          />
        );
      }}
    />
    <Route
      exact
      path="/dashboard/verify/:id"
      component={(props) => (
        <VerifyClinic
          {...props}
          {...store}
          title="Verifikasi Data"
          moduleName="Dashboard"
        />
      )}
    />
    <Route
      exact
      path="/dashboardhospital"
      component={(props) => (
        <DashboardHospital {...props} {...store} moduleName="Dashboard" />
      )}
    />
    <Route
      exact
      path="/hospital/services"
      component={(props) => {
        return (
          <ServicesList
            {...props}
            {...store}
            title="Layanan"
            moduleName="Hospital-services"
          />
        );
      }}
    />
    <Route
      exact
      path="/hospital/services/schedule/:serviceId"
      component={(props) => (
        <ServicesScheduleMain
          {...props}
          {...store}
          title="Tambah Jadwal Layanan"
          moduleName="Hospital"
        />
      )}
    />
    <Route
      exact
      path="/specialities"
      component={(props) => {
        return (
          <SpecialitiesList
            {...props}
            {...store}
            title="Spesialisasi"
            moduleName="specialities"
          />
        );
      }}
    />
    {/* Dashboard End */}

    {/* Administration Start */}
    <Route
      exact
      path="/administration/service-fee"
      component={(props) => {
        return (
          <ServiceFeeList
            {...props}
            {...store}
            title="Daftar Layanan"
            moduleName="Setting"
          />
        );
      }}
    />
    <Route
      exact
      path="/administration/service-fee/create"
      component={(props) => {
        return (
          <ServiceFeeCreate
            {...props}
            {...store}
            title="Tambah Layanan"
            moduleName="Setting"
          />
        );
      }}
    />
    <Route
      exact
      path="/administration/service-fee/edit/:id"
      component={(props) => {
        return (
          <ServiceFeeCreate
            {...props}
            {...store}
            title="Edit Layanan"
            moduleName="Setting"
          />
        );
      }}
    />
    <Route
      exact
      path="/administration/change-password"
      component={(props) => {
        return (
          <ChangePassword
            {...props}
            {...store}
            title="Ubah Password"
            moduleName="Setting"
          />
        );
      }}
    />
    <Route
      exact
      path="/administration/user"
      component={(props) => (
        <UserMain
          {...props}
          {...store}
          title="Pengaturan Admin"
          moduleName="User"
        />
      )}
    />
    <Route
      exact
      path="/administration/user/add"
      component={(props) => (
        <UserForm {...props} {...store} title="Create User" moduleName="User" />
      )}
    />
    <Route
      exact
      path="/administration/user/edit/:id"
      component={(props) => (
        <UserForm {...props} {...store} title="Edit User" moduleName="User" />
      )}
    />
    <Route
      exact
      path="/administration/info-hospital"
      component={(props) => (
        <InfoHospital
          {...props}
          {...store}
          title="Informasi Rumah Sakit"
          moduleName="Setting"
        />
      )}
    />
    <Route
      exact
      path="/administration/form-patient"
      component={(props) => (
        <PatientFormBuilder
          {...props}
          {...store}
          title="Pengaturan Formulir"
          moduleName="Setting"
        />
      )}
    />
    <Route
      exact
      path="/administration/spg"
      component={(props) => (
        <SPGList
          {...props}
          {...store}
          title="Pengaturan SPG"
          moduleName="Setting"
        />
      )}
    />
    <Route
      exact
      path="/administration/spg/add"
      component={(props) => (
        <SPGForm
          {...props}
          {...store}
          title="Tambah SPG"
          moduleName="Setting"
        />
      )}
    />
    <Route
      exact
      path="/administration/spg/edit/:id"
      component={(props) => (
        <SPGForm {...props} {...store} title="Ubah SPG" moduleName="Setting" />
      )}
    />
    <Route
      exact
      path="/redirectunauthorize/:id"
      component={(props) => <UnauthorizeTemplate {...props} />}
    />
    <Route exact path="/redirectunauthorize" component={UnauthorizeTemplate} />
    <Route exact path="/unauthorized" component={PageUnauthorized} />
    {/* Administration End */}

    {/* Doctor */}
    <Route
      exact
      path="/doctor/list"
      component={(props) => (
        <DoctorMain
          {...props}
          {...store}
          title="Data Dokter / Tenaga Medis"
          moduleName="Doctor"
        />
      )}
    />
    <Route
      exact
      path="/doctor/findstr"
      component={(props) => (
        <PreAddDoctor
          {...props}
          {...store}
          title="Tambah Dokter / Tenaga Medis"
          subtitle="Isi informasi NaKes"
          moduleName="Doctor"
        />
      )}
    />
    <Route
      exact
      path="/doctor/add/:strno"
      component={(props) => (
        <AddDoctor
          {...props}
          {...store}
          title="Tambah Dokter / Tenaga Medis"
          subtitle="Isi informasi Nakes"
          moduleName="Doctor"
        />
      )}
    />
    <Route
      exact
      path="/doctor/detail/:id"
      component={(props) => (
        <DoctorDetail
          {...props}
          {...store}
          title="Detail NaKes / Tenaga Medis"
          moduleName="Doctor"
        />
      )}
    />
    <Route
      exact
      path="/doctor/edit/:id"
      component={(props) => (
        <EditDoctor
          {...props}
          {...store}
          title="Edit Dokter / Tenaga Medis"
          moduleName="Doctor"
        />
      )}
    />
    <Route
      exact
      path="/mdoctor/edit/:id"
      component={(props) => (
        <EditDoctor
          {...props}
          {...store}
          title="Edit Dokter / Tenaga Medis"
          moduleName="Doctor"
          isDashboard
        />
      )}
    />
    <Route
      exact
      path="/doctor/edit/:id/:continue"
      component={(props) => (
        <EditDoctor
          {...props}
          {...store}
          title="Edit Dokter / Tenaga Medis"
          moduleName="Doctor"
        />
      )}
    />
    <Route
      exact
      path="/doctor/specialist"
      component={(props) => (
        <DoctorSpecialistMain
          {...props}
          {...store}
          title="Doctor Specialist"
          moduleName="DoctorSpecialist"
        />
      )}
    />
    <Route
      exact
      path="/doctor/specialist/add"
      component={(props) => (
        <AddDoctorSpecialist
          {...props}
          {...store}
          title="Add Doctor Specialist"
          moduleName="DoctorSpecialist"
        />
      )}
    />
    <Route
      exact
      path="/doctor/specialist/edit/:id"
      component={(props) => (
        <AddDoctorSpecialist
          {...props}
          {...store}
          title="Edit Doctor Specialist"
          moduleName="DoctorSpecialist"
        />
      )}
    />
    <Route
      exact
      path="/doctor/schedule/:doctorId"
      component={(props) => (
        <DoctorScheduleMain
          {...props}
          {...store}
          title="Tambah Dokter / Tenaga Medis"
          moduleName="Doctor"
        />
      )}
    />
    <Route
      exact
      path="/doctor/queue/:scheduleId"
      component={(props) => (
        <Queue {...props} {...store} title="Queue NaKes" moduleName="Doctor" />
      )}
    />
    {/* Doctor */}

    {/* Hospital Start */}
    <Route
      exact
      path="/master/hospital/list"
      component={(props) => (
        <HospitalMain
          {...props}
          {...store}
          title="Master - Rumah Sakit"
          moduleName="Hospitals"
        />
      )}
    />
    <Route
      exact
      path="/master/hospital/add"
      component={(props) => (
        <HospitalAdd
          {...props}
          {...store}
          title="Tambah Rumah Sakit"
          moduleName="Hospitals"
        />
      )}
    />
    <Route
      exact
      path="/master/hospital/edit/:id"
      component={(props) => (
        <HospitalAdd
          {...props}
          {...store}
          title="Update Rumah Sakit"
          moduleName="Hospitals"
        />
      )}
    />
    <Route
      exact
      path="/master/hospital/type"
      component={(props) => (
        <HospitalTypeMain
          {...props}
          {...store}
          title="Hospital Type Manager"
          moduleName="HospitalType"
        />
      )}
    />
    <Route
      exact
      path="/master/hospital/type/add"
      component={(props) => (
        <HospitalTypeAdd
          {...props}
          {...store}
          title="Hospital Type"
          subtitle="Hospital Type Form"
          moduleName="HospitalType"
        />
      )}
    />
    <Route
      exact
      path="/master/hospital/type/edit/:id"
      component={(props) => (
        <HospitalTypeAdd
          {...props}
          {...store}
          title="Edit Hospital Type"
          subtitle="Hospital Type Form"
          moduleName="HospitalType"
        />
      )}
    />
    <Route
      exact
      path="/master/hospital/facility"
      component={(props) => (
        <HospitalFacilityMain
          {...props}
          {...store}
          title="Hospital Facility Manager"
          moduleName="HospitalFacility"
        />
      )}
    />
    <Route
      exact
      path="/master/hospital/facility/add"
      component={(props) => (
        <HospitalFacilityAdd
          {...props}
          {...store}
          title="Create Hospital Facility"
          moduleName="HospitalFacility"
        />
      )}
    />
    <Route
      exact
      path="/master/hospital/facility/edit/:id"
      component={(props) => (
        <HospitalFacilityAdd
          {...props}
          {...store}
          title="Edit Hospital Facility"
          moduleName="HospitalFacility"
        />
      )}
    />
    {/* Hospital End */}

    {/* Patient Route */}
    <Route
      exact
      path="/patient/list"
      component={(props) => (
        <PatientMain
          {...props}
          {...store}
          title="Data Pasien"
          moduleName="Patient"
        />
      )}
    />
    <Route
      exact
      path="/patient/add"
      component={(props) => (
        <PatientAdd
          {...props}
          {...store}
          title="Tambah Pasien"
          subtitle="Informasi Pasien"
          moduleName="Patient"
        />
      )}
    />
    <Route
      exact
      path="/patient/edit/:id"
      component={(props) => (
        <PatientAdd
          {...props}
          {...store}
          title="Ubah Pasien"
          subtitle="Informasi Pasien"
          moduleName="Patient"
        />
      )}
    />
    {/* Patient Route */}

    {/* Resume Route */}
    <Route
      exact
      path="/emr/create"
      component={(props) => {
        return (
          <EMRCreate
            {...props}
            {...store}
            title="Tambah Resume Medis"
            moduleName="EMR"
          />
        );
      }}
    />
    <Route
      exact
      path="/emr/edit/:id"
      component={(props) => {
        return (
          <EMRCreate
            {...props}
            {...store}
            title="Edit Resume Medis"
            moduleName="EMR"
          />
        );
      }}
    />
    <Route
      exact
      path="/emr/list"
      component={(props) => {
        return (
          <EMRList
            {...props}
            {...store}
            title="Resume Medis"
            moduleName="EMR"
          />
        );
      }}
    />
    <Route
      exact
      path="/billing/list"
      component={(props) => {
        return (
          <BillingList
            {...props}
            {...store}
            title="Tagihan"
            moduleName="Billing"
          />
        );
      }}
    />
    <Route
      exact
      path="/billing/create"
      component={(props) => {
        return (
          <BillingDetail
            {...props}
            {...store}
            title="Tambah Tagihan"
            moduleName="Billing"
          />
        );
      }}
    />
    <Route
      exact
      path="/billing/detail/:id"
      component={(props) => {
        return (
          <BillingDetail
            {...props}
            {...store}
            title="Detail Tagihan"
            moduleName="Billing"
          />
        );
      }}
    />
    {/* End Resume Route */}

    {/* Appointment Route*/}
    <Route
      exact
      path="/appointment"
      component={(props) => (
        <Appointment
          {...props}
          {...store}
          title="Tambah Antrian"
          moduleName="Appointment"
        />
      )}
    />
    <Route
      exact
      path="/appointment/assign/:chosenDate/:scheduleid"
      component={(props) => (
        <AppointmentAssign
          {...props}
          {...store}
          title="Tambah Antrian"
          moduleName="Appointment"
        />
      )}
    />
    <Route
      exact
      path="/appointment/result"
      component={(props) => (
        <AppointmentResult {...props} {...store} moduleName="Appointment" />
      )}
    />
    {/* Appointment Route*/}

    {/* Appointment List Route*/}
    <Route
      exact
      path="/appointment/list"
      component={(props) => (
        <AppointmentList
          {...props}
          {...store}
          title="Appointment"
          moduleName="Appointment"
        />
      )}
    />
    {/* Appointment List Route*/}

    <Route
      exact
      path="/hospital/dsmap"
      component={(props) => (
        <DsmapMain
          {...props}
          {...store}
          title="Doctor Specialist Map"
          moduleName="DoctorSpecialistMap"
        />
      )}
    />
    <Route
      exact
      path="/hospital/dsmap/add"
      component={(props) => (
        <AddMap
          {...props}
          {...store}
          title="Create Doctor Specialist Map"
          moduleName="DoctorSpecialistMap"
        />
      )}
    />
    <Route
      exact
      path="/hospital/dsmap/edit/:id"
      component={(props) => (
        <AddMap
          {...props}
          {...store}
          title="Edit Doctor Specialist Map"
          moduleName="DoctorSpecialistMap"
        />
      )}
    />
    <Route
      exact
      path="/daftar-layanan"
      component={(props) => (
        <DashboardDaftarLayanan
          {...props}
          {...store}
          title="Layanan"
          moduleName="Dashboard"
        />
      )}
    />
    <Route exact path="/redirectunauthorize" component={UnauthorizeTemplate} />
    <Route component={PageNotFound} />
  </Switch>
);

export default handling;
