import Api from '../../../../services/apis/MedicalServices/ServiceSchedule'
import DataConfig from '../config/DataConfig.js'
import dateFns from "date-fns";
import { isEmpty } from 'lodash'

const FormHandler = {
    buildForm: function () {
        this.DropDownStartTime = this.DropDownView({
            meta: {
                label: "Mulai",
                validator: "",
                callBack: this.getEndTime,
            },
            name: "time_start",
            nameText: "time_start",
            control: this.state.Form.controls.time_start,
            stateName: 'timeList',
        })

        this.DropDownEndTime = this.DropDownView({
            meta: {
                label: "Selesai",
                validator: "",
                callBack: this.handleQuota,
            },
            name: "time_end",
            control: this.state.Form.controls.time_end,
            stateName: 'timeListEnd',
        })

        this.CheckBoxDay = this.CheckBoxView({
            name: "day",     
            meta: {
                validator: "",
                options: this.state.dayOpt
            },
            control: this.state.Form.controls.day,
            stateName: 'dayOpt'
        })

        this.TextKuota = this.TextInputView({
            label: "Name",
            validator: "required",
            name: "max_patient_count",
            inputType: "number",
            control: this.state.Form.controls.max_patient_count,
            meta: {
                callBack: this.setQuota,
            },
        })

        this.DropDownLimitDay = this.DropDownView({
            meta: {
                label: "Pilih Hari",
                validator: "",
            },
            name: "limit_dday",
            nameText: "limit_dday",
            control: this.state.Form.controls.limit_dday,
            stateName: 'dayLimitList',
        })

        this.DropDownLimitTime = this.DropDownView({
            meta: {
                label: "Pilih Jam",
                validator: "",
            },
            name: "limit_dtime",
            nameText: "limit_dtime",
            control: this.state.Form.controls.limit_dtime,
            stateName: 'timeList',
        })
        
        this.DropDownPeriode = this.DropDownView({
            meta: {
                label: "Periode",
                validator: "",
                forceDisabled: true
            },
            name: "recurring_weeks",
            control: this.state.Form.controls.recurring_weeks,
            stateName: 'weekOpt',
        })

        this.DateStart = this.DatePickerView({
            meta: {
                label: "Tanggal Mulai",
                validator: "",
                minDate: this.state.selectedDate
            },
            name: "start_date",
            control: this.state.Form.controls.start_date
        })

        this.DateEnd = this.DatePickerView({
            meta: {
                label: "Tanggal Selesai",
                validator: "",
                minDate: this.state.selectedDate
            },
            name: "end_date",
            control: this.state.Form.controls.end_date
        })
    },

    valueBuilder: function () {
        let me = this
        me.valueObject = {
            id: 0,
            time_start: "",
            time_end: "",
            max_patient_count: 0,
            recurring_weeks: this.getLabelFromConfig("weekOpt", 1),
            day: [],
            start_date: "",
            end_date: "",
            limit_dday: "",
            limit_dtime: "",
            video_call: false,
        }
        if (me.props.location.state !== undefined) {
            let dataProvider = me.props.location.state.dataProvider
            me.fillForm(dataProvider)
            
        } else if (me.state.isEdit && me.state.editId !== null) {
            const editId = me.state.editId
            Api.view(editId).then((response) => {
                const responseData = response.data.data
                const recurrWeek = responseData.recurring_weeks ? responseData.recurring_weeks : 1

                if(responseData.time_start){
                    this.setEndTimeList(responseData.time_start)
                }

                const dataToFill = {
                    id: responseData.id,
                    time_start: responseData.time_start,
                    time_end: responseData.time_end,
                    max_patient_count: responseData.max_patient_count ? responseData.max_patient_count : 0,
                    recurring_weeks: DataConfig.weekOpt.find(option => option.value === recurrWeek).label,
                    start_date: responseData.start_date,
                    end_date: responseData.end_date,
                    limit_dday: responseData.limit_dday,
                    limit_dtime: responseData.limit_dtime,
                    video_call: responseData.video_call,
                }

                me.fillForm(dataToFill)
            }, (e) => {
                setTimeout(function(){ 

                }, 3000);
            })
        }
    },
    setEndTimeList: function(selectedTimeStart){
        const configVal = DataConfig.listTime
        const indexFromConfig = DataConfig.listTime.find(option => option.label === selectedTimeStart).value
        let EndTimeList = []
        configVal.map((value, index) => {
            if(index > indexFromConfig){
                EndTimeList.push(value)
            }

            return EndTimeList
        })

        this.setState({
            timeListEnd: EndTimeList
        })
    },

    getEndTime: function(me, name){
        const pickedStartTime = me.state.Form.value[name].value
        const formValue = Object.assign({}, me.state.Form.value)
        const timeStart = formValue.time_start
        const timeEnd = formValue.time_end
        const configVal = DataConfig.listTime
        let EndTimeList = []
        configVal.map((value, index) => {
            if(index > pickedStartTime){
                EndTimeList.push(value)
            }

            return EndTimeList
        })

        me.getMaxQuota(timeStart, timeEnd)
        me.setState({ timeListEnd: EndTimeList })
        me.state.Form.patchValue({ time_end: '' })
    },

    handleQuota(me) {
        const formValue = Object.assign({}, me.state.Form.value)
        const timeStart = formValue.time_start
        const timeEnd = formValue.time_end

        me.getMaxQuota(timeStart, timeEnd)
    },

    setQuota(me, name) {
        const pickedQuota = me.state.Form.value[name] !== ''
            ? parseInt(me.state.Form.value[name], 10)
            : me.state.Form.value[name]
        const formValue = Object.assign({}, me.state.Form.value)
        const timeStart = formValue.time_start
        const timeEnd = formValue.time_end

        const quotaMax = me.getMaxQuota(timeStart, timeEnd, true)

        me.state.Form.patchValue({
            max_patient_count: pickedQuota > quotaMax
                ? quotaMax
                : pickedQuota,
        })
    },

    onChangeLimitForm(){
        this.setState({
            displayLimitForm: !this.state.displayLimitForm
        })
    },

    onChangeQuotaForm() {
        this.setState({
            displayQuotaForm: !this.state.displayQuotaForm
        })
    },

    onChangeVideoForm(){
        this.setState({
            displayVideoForm: !this.state.displayVideoForm
        })
    },

    getMaxQuota(ts, te, getVal) {
        let maxQuota = 0
        const { Form } = this.state
        const {
            serviceData: {
                estimation_time,
            },
        } = this.props
        const formValue = Object.assign({}, Form.value)

        const getTS = ts || formValue.time_start
        const getTE = te || formValue.time_end
        const start = typeof getTS === 'object'
            ? getTS.label
            : getTS
        const end = typeof getTE === 'object'
            ? getTE.label
            : getTE

        if (!isEmpty(start) && !isEmpty(end)) {
            const now = new Date();
            const tStart = start.split(":");
            const tEnd = end.split(":");
            const timeStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), tStart[0], tStart[1], 0)
            const timeEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), tEnd[0], tEnd[1], 0)
            const diff = timeEnd.getTime() - timeStart.getTime()
            const minutes = Math.floor(diff / 1000 / 60)
            maxQuota = Math.floor(minutes / estimation_time)
        }

        if (getVal) {
            return maxQuota
        } else {
            Form.patchValue({ max_patient_count: maxQuota })
        }
    },

    fillForm: function(dataProvider) {
        let me = this
        Object.keys(dataProvider).map(function (key) {
            if (me.valueObject.hasOwnProperty(key)) {
                if (typeof me.valueObject[key] === 'boolean') {
                    me.valueObject[key] = !!dataProvider[key]
                } else if (key === 'start_date' || key === 'end_date') {
                    me.valueObject[key] = dataProvider[key] ? new Date(dataProvider[key]) : null
                } else if (key === 'limit_dday') {
                    me.valueObject['limit_dday'] = dataProvider[key] ? `H-${dataProvider[key]}` : 0
                } else if (key === 'max_patient_count') {
                    me.valueObject['max_patient_count'] = dataProvider[key] ? dataProvider[key] : 0
                }  else {
                    me.valueObject[key] = dataProvider[key] ? dataProvider[key] : ''
                }
            }
            return true
        })

        this.state.Form.patchValue(me.valueObject)
        me.setState({
            isEdit: true,
            displayVideoForm: me.valueObject.video_call,
            displayQuotaForm: me.valueObject['max_patient_count'] && me.valueObject['max_patient_count'] > 0 ? true : false
        })

        if(typeof me.valueObject['start_date'] !== 'undefined' && typeof me.valueObject['end_date'] !== 'undefined'){
            me.setState({displayLimitForm:true})
        }
    },

    resetLimit() {
        this.state.Form.patchValue({
            limit_dday: 0,
            limit_dtime: '',
        })
    },

    getValueFromConfig(name, labelVal){
        if(labelVal && typeof labelVal !== 'object'){
            return DataConfig[name].find(option => option.label === labelVal).value
        }else{
            return labelVal
        }
    },

    handleSubmit: async function (e) {
        e.preventDefault()
        let me = this
        me.setState({submitingForm: true})
        let formValue = Object.assign({}, me.state.Form.value)
        let response
        let id = formValue['id']
        const { displayLimitForm, displayVideoForm } = me.state
        const selectedDate = this.props.selectedDate

        formValue.day = formValue.day.filter((elem, index, self) => {
            return index === self.indexOf(elem);
        })

        formValue.time_start = formValue.time_start instanceof Object === true
            ? formValue.time_start.label
            : formValue.time_start
        formValue.time_end = formValue.time_end instanceof Object === true
            ? formValue.time_end.label
            : formValue.time_end
        formValue.limit_dday = formValue.limit_dday instanceof Object === true
            ? formValue.limit_dday.value
            : formValue.limit_dday
                ? parseInt((formValue.limit_dday).replace('H-', ''))
                : 0
        formValue.limit_dtime = formValue.limit_dtime instanceof Object === true
            ? formValue.limit_dtime.label
            : formValue.limit_dtime

        if (me.state.isEdit) {
            delete formValue.day
        }

        if(typeof formValue.recurring_weeks === 'object'){
            formValue.recurring_weeks = formValue.recurring_weeks.value
        }else{
            formValue.recurring_weeks = this.getValueFromConfig('weekOpt', formValue.recurring_weeks)
        }

        if (!me.state.displayQuotaForm) {
            formValue.max_patient_count = 0
        }

        formValue.start_date = formValue.start_date
            ? dateFns.format(formValue.start_date, 'YYYY-MM-DD')
            : dateFns.format(selectedDate, 'YYYY-MM-DD')
        formValue.end_date = formValue.end_date
            ? dateFns.format(formValue.end_date, 'YYYY-MM-DD')
            : null

        if (!displayLimitForm) {
            formValue.end_date = null
        }

        formValue.video_call = displayVideoForm

        if (this.state.isEdit) {
            try {
                response = await Api.update(id, JSON.stringify(formValue))
            } catch (e) {
                const data = e.response.data
                me.setState({
                    submitingForm: false,
                    errorForm: (data && data.massage !== '')
                        ? data.message
                        : 'Failed to create schedule'
                })
                return
            }

            //error handling
            if (!response.data.status && response.data.status !== 200) {
                me.setState({
                    submitingForm: false,
                    errorForm : response.data.message
                })
                return
            }

            me.setState({
                postStatus: true,
                submitingForm: false
            })

            return
        }

        try {
            response = await Api.post(JSON.stringify(formValue))
        } catch (e) {
            const data = e.response.data
            me.setState({
                submitingForm: false,
                errorForm: (data && data.massage !== '')
                    ? data.message
                    : 'Failed to create schedule'
            })

            return
        }

        //error handling
        if (!response.data.status && response.data.status !== 200) {
            me.setState({
                submitingForm: false,
                errorForm : response.data.message
            })
            return
        }

        me.setState({
            postStatus: true,
            submitingForm: false
        })
    }
}

export default FormHandler