import { isEmpty } from 'lodash'
import { axiosBase } from './MasterConfig'

const axiosB = axiosBase()
const groupUrl = 'hospital/emr'

export default class MRHospitalApi {
    static list = (hId, page, pageSize, sortProperties, sortValue, filterData) => {
        let setSort = ''
        let setFilter = ''

        if (sortProperties) {
            setSort = `&sort=${sortProperties}&order=${sortValue}`
        }

        if (!isEmpty(JSON.parse(filterData))) {
            setFilter = `&filter=${filterData}`
        }

        return axiosB.get(`/${groupUrl}?page=${page}&hospital_id=${hId}&limit=${pageSize}${setFilter}${setSort}`)
    }
    static detail = (hId, id) => axiosB.get(`/${groupUrl}/${id}?hospital_id=${hId}`)
    static filter = (hId) => axiosB.get(`/${groupUrl}/filter?hospital_id=${hId}`)
    static doctor = (hId) => axiosB.get(`/${groupUrl}/doctor?hospital_id=${hId}`)
    static patient = (hId, did) => axiosB.get(`/${groupUrl}/patient?hospital_id=${hId}&doctor_id=${did}`)
    static patientRecord = (hId, pid) => axiosB.get(`/${groupUrl}/patientrecord?hospital_id=${hId}&patient_id=${pid}`)
    static create = (data) => axiosB.post(`/${groupUrl}`, data)
    static update = (id, data) => axiosB.put(`/${groupUrl}/${id}`, data)
}
