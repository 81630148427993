import React from 'react'
import {
    NavLink
} from 'react-router-dom'
import Icon from '../../../../core/utils/Icons'

class ModalNotifyContent extends React.Component{
    render(){
        const setStrNo = this.props.setStrNo
        const typeNotify = this.props.modalNotifyContent
        const modalNotifyMessage = this.props.modalNotifyMessage
        let textButton = 'Submit'
        let Text = () => {
            return null
        }
        if (typeNotify === 'can_create') {
            Text = () => {
                return (
                    <div className="content custom-content">
                        NaKes dengan No. Str <strong>{setStrNo}</strong> ditemukan dalam sistem kami,
                        silahkan lengkapi data NaKes untuk dihubungkan ke rumah sakit
                    </div>
                )
            }
            textButton = 'Lanjut isi Data'
        } else if (typeNotify === 'unable_create') {
            Text = () => {
                return (
                    <div className="content custom-content">
                        NaKes dengan No. Str <strong>{setStrNo}</strong> sudah terdaftar di rumah sakit
                    </div>
                )
            }
            textButton = 'Ganti No. Str'
        } else if (typeNotify === 'can_create_new') {
            Text = () => {
                return (
                    <div className="content custom-content">
                        NaKes dengan No. Str <strong>{setStrNo}</strong> <br /> tidak ditemukan dalam sistem kami,
                        silahkan lengkapi form dibawah untuk membuat data NaKes baru
                    </div>
                )
            }
            textButton = 'Tambah NaKes Baru'
        } else if (typeNotify === 'reach_max') {
            Text = () => {
                return (
                    <div className="content custom-content">{modalNotifyMessage} </div>
                )
            }
            textButton = 'Ganti No. Str'
        }

        return(
            <div className="master-modal-content notify-doctor-modal">
                <div className="master-modal-head">
                    <span className="icon-exclamation">!</span>
                </div>
                <div className="master-modal-body" >
                    <div className="content custom-content">
                        <Text/>
                    </div>
                </div>
                <div className="master-modal-foot foot-doctor">
                    {
                        typeNotify === 'reach_max'
                            ? <NavLink
                                className="button-link"
                                style={{ float: 'unset' }}
                                to="/doctor/findstr"
                            >
                                {textButton}
                            </NavLink>
                            : typeNotify !== 'unable_create' ?
                                <button
                                    type="button"
                                    className="master-btn confirm confirm-doctor"
                                    onClick={this.props.handleClose}
                                >
                                    {textButton}
                                </button> :
                                <div className="wrap-button-unable">
                                    <NavLink
                                        className="button-link"
                                        to="/doctor/findstr"
                                    >
                                        {textButton}
                                    </NavLink>
                                    <NavLink
                                        className="button-link invert"
                                        to={`/doctor/edit/${this.props.doctorEditId}/continue`}
                                    >
                                        Ubah Data Nakes
                                        </NavLink>
                                </div>
                    }
                </div>
                <NavLink className="close-modal-notify" to="/doctor/findstr">
                    <img src={ Icon.close } alt="back to insert str no"/>
                </NavLink>
            </div>
        )
    }
}

export default ModalNotifyContent