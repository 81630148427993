import React from 'react'
import IconTool from '../IconTool'

class CheckBox extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            valueText : "",
            showOption: false
        }

        this.showOption = this.showOption.bind(this)
        this.hideOption = this.hideOption.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleChangeLabel = this.handleChangeLabel.bind(this)
        this.handleCheckRequired = this.handleCheckRequired.bind(this)
        this.handleNewValue = this.handleNewValue.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handleDeleteOption = this.handleDeleteOption.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    showOption(){
        this.setState({
            showOption: true
        })
    }

    hideOption(){
        this.setState({
            showOption: false
        })
    }

    componentWillMount(){
        document.addEventListener('mousedown', this.handleClick, false)
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleClick, false)
    }

    handleClick = (e) => {
        if(e.target.className === 'preview'){
            this.props.togglePreview()
        }else if(!this.node.contains(e.target) && e.target.className !== 'submit'){
            this.hideOption()
        }
    }

    handleChangeLabel(ev){
        const currentField = this.props.fields
        let builderData = this.props.builderData
        const index = builderData.findIndex(data => data.id === currentField.id)
        if(index !== -1){
            builderData[index].fieldLabel = ev.target.value
        }

        this.props.mutateBuilderData(builderData)
    }

    handleCheckRequired(ev){
        const currentField = this.props.fields
        let builderData = this.props.builderData
        const index = builderData.findIndex(data => data.id === currentField.id)
        if(index !== -1){
            builderData[index].fieldOptional = !builderData[index].fieldOptional
        }

        this.props.mutateBuilderData(builderData)
    }

    handleNewValue(e){
        this.setState({
            valueText: e.target.value
        })
    }

    handleKeyPress(e){
        if(e.keyCode === 13 && this.state.valueText.length > 0){
            const currentField = this.props.fields
            let builderData = this.props.builderData
            const index = builderData.findIndex(data => data.id === currentField.id)
            let option = builderData[index].option

            if(index !== -1){
                option.push({
                    value: (option.length + 1).toString(),
                    name: this.state.valueText
                })
                builderData[index].option = option
                this.setState({
                    valueText: ""
                })
                this.props.mutateBuilderData(builderData)
            }
        }
    }

    handleKeyDown(e){
        if(e.keyCode === 13){
            this.inputOptionText.focus()
        }
    }

    handleDeleteOption(ev, opt){
        const currentField = this.props.fields
        let builderData = this.props.builderData
        const index = builderData.findIndex(data => data.id === currentField.id)

        if(index !== -1){
            let option = builderData[index].option
            const newOption = option.filter((el) => (
                el.value !== opt.value
            ))

            builderData[index].option = newOption
            this.props.mutateBuilderData(builderData)
        }
    }

    render(){
        const showOption = this.state.showOption
        const currentField = this.props.fields
        return(
            <div className="field-data" ref={node => { this.node = node }}>
                <div className="field-data-head">
                    <i className="fas fa-bars icon"></i>
                    <IconTool fieldKey={currentField.fieldKey}/>
                    <input 
                        className="label-name" 
                        type="text" 
                        maxLength={30} 
                        placeholder="Masukkan Label Multi Choice" 
                        onFocus={ this.showOption }
                        onChange={ this.handleChangeLabel }
                        onKeyDown={ this.handleKeyDown }
                        value={ currentField.fieldLabel }
                    />
                    <div className="delete-button" onClick={ this.props.handleDelete }>
                        <i className="fas fa-trash icon"></i>
                    </div>
                </div>
                <div className={ showOption ? "field-data-body show" : "field-data-body hide" }>
                    <div className="content-body">
                        <ul className="current-option">
                        {
                            currentField.option.map(((opt, key) => (
                                <li key={key}>
                                    <span>{opt.name}</span>
                                    <div className="delete-option" onClick={ (ev) => this.handleDeleteOption(ev, opt) }>
                                        <i className="fas fa-times"></i>
                                    </div>
                                </li>
                            )))
                        }
                        </ul>
                        <div className="add-option">
                            <i className="far fa-circle"></i>
                            <input 
                                ref={ (input) => {this.inputOptionText = input} }
                                type="text" 
                                onChange={ this.handleNewValue }
                                onKeyDown={ this.handleKeyPress }
                                value={ this.state.valueText}/>
                        </div>
                        <label>
                            <input 
                                type="checkbox" 
                                name="set_optional" 
                                className="setopt" 
                                defaultChecked={ currentField.fieldOptional }
                                onChange={ this.handleCheckRequired }
                            />
                            <span>Optional Field</span>
                        </label>
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckBox