import React from 'react'
import {FieldControl} from "react-reactive-form"

const FileInputForm = {
    FileInputFormView: function({label, validator, name, callBack, filter, icon, stateLabel, statePlaceholder, control, caption, inputType, fieldControlAdditional}) {
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}        
        Object.assign(control, additionalController)

        return <FieldControl
            name={name}
            render={(obj) => this.FileInputFormRender(obj, callBack, filter, icon, stateLabel, statePlaceholder, control)}
            meta={{ label: label, name: name, validator: validator ,caption: caption  ,inputType: inputType || 'file' }}
            control = { control }
        />
    },
    onChangeEvent: function (e, onChangeEvent, name, callBack, control) {
        onChangeEvent(e)
        if(callBack){
            callBack(this, e, name, control)
        }
    },
    showFileIcon: function(icon, type, me){
        if(type === 'doctor'){
            if(me.state.Form.value.doctor)
                return <img src={icon} alt="" />
        }else{
            if(me.state.Form.value.patient)
                return <img src={icon} alt="" />
        }
    },
    FileInputFormRender: function({handler, touched, hasError, meta}, callBack, filter, icon, stateLabel, statePlaceholder, control) {
        return (
            <div onBlur={this.handleBlur} className={touched && hasError(meta.validator) ? "form-line has-danger" : "form-line"}>
                <div className="form-group wrapper-uploader">
                    <div className="info-uploader">
                        <span className="caption">{meta.caption}</span>
                        <span className="filename">
                            {this.showFileIcon(icon, meta.name, this)}
                            {this.state[statePlaceholder]}
                        </span>
                    </div>
                    <div className="file-uploader">
                        <input type={meta.inputType} className="form-control form-control-success input-file-upload" 
                            accept={filter}
                            {...handler()}
                            onChange={(e) => this.onChangeEvent(e, handler().onChange, meta.name, callBack, control)}
                            id={`${meta.name}`}  />
                        <label id={`label-${meta.name}`} className="form-control-label label-file-upload" htmlFor={`${meta.name}`}>
                            {this.state[stateLabel]}
                            {/* {meta.validator && <span style={{color: 'red'}}>*</span>} */}
                        </label>
                    </div>
                </div>
            </div>
        )
    },
}

export default FileInputForm
