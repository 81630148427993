import { Component } from 'react'
import { isDenied } from '../core/utils/UtilsPermission'

class AccessPermission extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { children, currentHospital, dataLogin, module, onRequestLogout } = this.props

    if (isDenied(currentHospital, dataLogin, module)) {
      onRequestLogout()
    }

    return children
  }
}

export default AccessPermission;
