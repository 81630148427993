import React from 'react'
import Chance from 'chance'
import { isEmpty } from 'lodash'

import Api from '../../../../../../services/apis/Subscription'
import { handleToastServiceError } from '../../../../../../core/utils/UtilsToast'

const chance = new Chance()

const GridHandler = {
    onPanelOpen(data) {
        this.setState({
            isPanelOpen: true,
            panelData: {
                headTitle: <div>Edit Paket Faskes</div>,
                isEdit: !isEmpty(data),
                ...data
            },
        })
    },
    onPanelClose() {
        const hId = this.getId()

        if (hId) {
            setTimeout(() => {
                this.props.history.push('/subscription/faskes')
            }, 500);
        }

        this.setState({
            isPanelOpen: false,
            panelData: {},
        })
    },
    gridColumn() {
        return [
            {
                Header: 'Nama Faskes',
                accessor: 'name',
                headerClassName: '-sort',
                Cell: (row) => {
                    return row.original.name || '-'
                },
            },
            {
                Header: 'Periode',
                accessor: 'periode',
                headerClassName: '-sort',
                Cell: (row) => {
                    const periodStart = row.original.package_start_date
                        ? `${row.original.package_start_date} - `
                        : '-'
                    const periodEnd = row.original.package_end_date || ''
                    return `${periodStart}${periodEnd}`
                },
            },
            {
                Header: 'Paket',
                accessor: 'package_name',
                headerClassName: '-sort',
                Cell: (row) => {
                    const { history } = this.props
                    const pakcageId = row.original.package_id

                    return (
                        <div
                            className={pakcageId > 0 ? 'clickable-field' : ''}
                            onClick={() => pakcageId > 0
                                ? history.push(`/subscription/packages?id=${pakcageId}`) : {}}
                            style={pakcageId > 0 ? { fontWeight: 500 } : {}}
                        >
                            {row.original.package_name || '-'}
                        </div>
                    )
                },
            },
            {
                Header: 'Status',
                accessor: 'package_status',
                maxWidth: 150,
                headerClassName: '-sort',
                Cell: (row) => {
                    const badgeStyle = row.original.package_status === 'Aktif'
                        ? 'badge-primary'
                        : row.original.package_status === 'Belum Mulai'
                            ? 'badge-success'
                            : row.original.package_status === 'Kadaluarsa'
                                ? 'badge-default'
                                : 'badge-danger'

                    return (
                        <div className="badge-label">
                            <span className={`badge badge-pill ${badgeStyle}`}>
                                {row.original.package_status}
                            </span>
                        </div>
                    )
                },
            },
            {
                Header: 'Tagihan',
                accessor: 'package_price',
                headerClassName: '-sort',
                Cell: (row) => {
                    return this.toRp(row.original.package_price)
                },
            },
            {
                Header: 'Status Pembayaran',
                accessor: 'payment_status',
                headerClassName: '-sort',
                Cell: (row) => {
                    const badgeStyle = row.original.payment_status === 'Dibayar'
                        ? 'badge-primary'
                        : 'badge-danger'

                    return (
                        <div className="badge-label">
                            <span className={`badge badge-pill ${badgeStyle}`}>
                                {row.original.payment_status}
                            </span>
                        </div>
                    )
                },
            },
            {
                Header: 'Action',
                maxWidth: 58,
                sortable: false,
                className: "grid-action",
                Cell: (row) => {
                    return (
                        <div
                            onClick={() => this.onPanelOpen(row.original)}
                            style={{ cursor: 'pointer', textAlign: 'center' }}
                        >
                            Edit
                        </div>
                    )
                },
            },
        ]
    },
    fetchData: async function (state) {
        this.setState({ loading: true })
        this.page = state.page
        this.pageSize = state.pageSize

        let res = {}
        let fArray = {}
        let sort_property = ''
        let sort_value = ''

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'DESC' : 'ASC'
            this.sorted = state.sorted
        }

        if (state.filtered.length > 0) {
            state.filtered.map(val => {
                if (val.value !== '') {
                    fArray[val.id] = val.value
                }
                return true
            })
        }

        const filterData = JSON.stringify(fArray)

        try {
            res = await Api.listFaskes(
                this.page + 1,
                this.pageSize,
                sort_property,
                sort_value,
                filterData,
            )
        } catch (e) {
            this.setState({ loading: false })

            handleToastServiceError(e)
            return
        }

        const {
            status,
            data: resData,
        } = res

        if (status === 200) {
            const newRows = resData.data.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })

            this.setState({
                loading: false,
                data: newRows,
                pages: resData.data.total_page,
                rows: resData.data.total_rows,
            })
        } else {
            this.setState({ loading: false })
        }
    },
    getFilterList: function () {
        Api.filterFaskes().then((res) => {
            const {
                status,
                data: resData,
            } = res
            if (status === 200) {
                const { data } = resData
                const filterList = data

                this.setState({ filterList })
            }
        })
    },
    getPackages: async function (keyword) {
        this.setState({ isSelectLoading: true })
        const params = keyword ? `?search=${keyword}` : ''

        await Api.package(params).then((res) => {
            const { status, data } = res.data

            if (status) {
                this.setState({
                    packageList: data,
                    packageListState: data,
                    isSelectLoading: false,
                })
            }
        })
    },
    toRp(number) {
        if (number) {
            return `Rp ${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
        }

        return 'Rp 0 (Gratis)'
    },
    getId() {
        const params = new URLSearchParams(window.location.search)
        const hId = params.get('id')
        return hId
    },
}

export default GridHandler
