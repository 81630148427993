import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'

class SearchText extends Component {
    constructor(props) {
        super(props)

        this.state = {
            keyword: '',
        }

        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        const { filterKeyword: keyword } = this.props
        this.setState({ keyword })
    }

    handleChangeInput(e) {
        this.setState({ keyword: e.target.value })
    }

    handleSubmit(e) {
        e.preventDefault()

        const { keyword } = this.state
        const { actionType, handlerSubmit } = this.props
        const payload = {
            id: actionType,
            value: keyword,
        }

        handlerSubmit(actionType, payload)
    }

    render() {
        const { keyword } = this.state
        const { title, tooltip, className } = this.props

        return (
            <div className="wrap-search-filter">
                <ReactTooltip className="cinnamon-tooltip custom-action" effect="solid" />
                <form onSubmit={this.handleSubmit} autoComplete="off">
                    <input
                        type="text"
                        name="filter-input-text"
                        placeholder={title}
                        value={keyword}
                        data-tip={tooltip}
                        className={`search-byfilter-keyword${className ? ` ${className}` : ''}`}
                        onChange={this.handleChangeInput}
                    />
                    <button className="button-search-byfilter">
                        <i className="fas fa-search" />
                    </button>
                </form>
            </div>
        )
    }
}

export default SearchText
