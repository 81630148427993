import React, { Component } from 'react'
import RoleHelper from '../../../../../core/helper/RoleHelper'

class ButtonCancelSchedule extends Component {
    render() {
        const {
            dataLogin,
            currentHospital,
            schedule,
            openCancelModal,
        } = this.props

        const isDoctor = RoleHelper.isDoctor(dataLogin, currentHospital)
        const isNurse = RoleHelper.isNurse(dataLogin)

        if (isDoctor || isNurse) {
            return null
        }

        return (
            <button
                onClick={e => openCancelModal(schedule)}
                className="btn-cancel-schedule"><span>Batalkan Jadwal</span>
            </button>
        )
    }
}

export default ButtonCancelSchedule