import React from 'react'
import Icon from '../../../core/utils/Icons'
import { Redirect } from 'react-router-dom'
import Select from 'react-select'
import SliderPanel from '../../../components/wizard/SliderPanel'
import SearchPatient from '../components/search/PatientSearch'
import Checkbox from '../../dashboard/components/Checkbox'
import Form from '../components/assign/FormAddPatient'
import Handler from '../handler/AssignPatientHandler'
import PatientResultBox from '../components/assign/PatientResult'
import { connect } from 'react-redux'
import { appointmentMapStateToProps, appointmentMapDispatchToProps } from '../../../core/const/AppointmentConst'

class AssignPatient extends React.Component {
    constructor(props) {
        super(props)
        this.ScheduleId = this.props.match.params.scheduleid
        this.ChosenDate = this.props.match.params.chosenDate
        this.state = {
            isPaneOpen: false,
            isLoading: true,
            isSubmiting: false,
            scheduleData: {
                doctorId: null,
                doctorName: '...',
                date: '...',
                time: '...'
            },
            panelData: {
                headTitle: "Cari Pasien"
            },
            FormPatientShow: false,
            patientFilter: {
                dob: null,
                fullname: null,
                phone: null,
            },
            redirectTo: null,
            patientSelectSch: false,
            schSelected: '',
        }

        Object.assign(this, Handler)
        this.onPanelOpen = this.onPanelOpen.bind(this)
        this.onPanelClose = this.onPanelClose.bind(this)
        this.handleShowAddPatient = this.handleShowAddPatient.bind(this)
        this.onHandleBack = this.onHandleBack.bind(this)
        this.fetchScheduleInfo = this.fetchScheduleInfo.bind(this)
        this.handleChangeDob = this.handleChangeDob.bind(this)
        this.handleChangeName = this.handleChangeName.bind(this)
        this.handleChangePhone = this.handleChangePhone.bind(this)
        this.handleAssignPatient = this.handleAssignPatient.bind(this)
        this.handleBookAppointment = this.handleBookAppointment.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        const { scheduleData } = nextProps
        this.setState({ schSelected: scheduleData.time_options ? scheduleData.time_options[0] : '' })
    }

    onChangeSch = (data) => {
        this.setState({ schSelected: data.value })
    }

    onRemove = () => {
        const { scheduleData } = this.props

        this.setState({
            patientSelectSch: false,
            schSelected: scheduleData.time_options ? scheduleData.time_options[0] : '',
        })
    }

    render() {
        const { scheduleData, chosenPatient, isLoading } = this.props
        const {
            redirectTo,
            FormPatientShow,
            isSubmiting,
            isPaneOpen,
            panelData,
            patientFilter,
            patientSelectSch,
            schSelected,
        } = this.state

        if (redirectTo) {
            return (<Redirect to={redirectTo} />)
        }

        const timeOptions = scheduleData.time_options && scheduleData.time_options.map(time => {
            return {
                label: time,
                value: time,
            }
        })

        return (
            <div>
                <div className="wrap-assign-patient">
                    <h3>Dokter & Jadwal</h3>
                    <div className="doctor-info">
                        <div className="col-doctor-name">
                            {(scheduleData.doctorTitle || '') + ' ' + (scheduleData.doctorName || '')}
                        </div>
                        <div className="col-schedule-info">
                            <div className="date">
                                <img src={Icon.calendar} alt="calendar" />
                                <span>{scheduleData.date || ''}</span>
                            </div>
                            <div className="time">
                                <i className="far fa-clock"></i>
                                <span>{scheduleData.time || ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className="wrap-patient">
                        <h3>Pasien</h3>
                        <PatientResultBox
                            onPanelOpen={this.onPanelOpen}
                            isLoading={isLoading}
                            chosenPatient={chosenPatient}
                        />

                        {chosenPatient && scheduleData.is_custom_schedule
                            ? (
                                <div className="patient-sel-sch">
                                    <Checkbox
                                        options={[{
                                            value: true,
                                            label: 'Pasien memilih waktu kedatangan',
                                        }]}
                                        prefix="resultAppointment"
                                        onCheck={() => this.setState({ patientSelectSch: true })}
                                        onRemove={() => this.onRemove()}
                                        items={[patientSelectSch]}
                                        class="wrap-checkbox-left result-appointment"
                                    />
                                    {patientSelectSch
                                        ? (
                                            <Select
                                                className="dropdown-filter select-sch"
                                                classNamePrefix="react-select"
                                                options={timeOptions}
                                                value={timeOptions.filter(option => option.value === (schSelected))}
                                                onChange={value => this.onChangeSch(value)}
                                            />
                                        ) : null}
                                </div>
                            ) : null}

                        <div className="wrap-button">
                            <button
                                className={!chosenPatient || !scheduleData || isSubmiting
                                    ? "disabled"
                                    : null}
                                onClick={chosenPatient && scheduleData
                                    ? this.handleBookAppointment
                                    : null}
                            >
                                {!isSubmiting ? 'Daftarkan' : 'Loading'}
                            </button>
                        </div>
                    </div>
                </div>
                <SliderPanel
                    {...this.props}
                    open={isPaneOpen}
                    onPanelClose={this.onPanelClose}
                    onHandleBack={FormPatientShow ? this.onHandleBack : null}
                    panelData={panelData}
                    width="75%"
                >
                    {
                        FormPatientShow ?
                            <Form
                                {...this.props}
                                handleAssignPatient={this.handleAssignPatient}
                            /> :
                            <SearchPatient
                                {...this.props}
                                handleChangeDob={this.handleChangeDob}
                                handleChangeName={this.handleChangeName}
                                handleChangePhone={this.handleChangePhone}
                                patientFilter={patientFilter}
                                handleAssignPatient={this.handleAssignPatient}
                                handleShowAddPatient={this.handleShowAddPatient}
                            />
                    }
                </SliderPanel>
            </div>
        )
    }
}

export default connect(appointmentMapStateToProps, appointmentMapDispatchToProps)(AssignPatient)