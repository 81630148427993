import React, { Component } from 'react'
import {
    FormBuilder,
    FieldGroup
} from 'react-reactive-form'

import { debounce } from 'lodash'
import TextInputForm from '../../../../../core/utils/forminput/TextInputForm'
import TextAreaForm from '../../../../../core/utils/forminput/TextAreaForm'
import RadioInputForm from '../../../../../core/utils/wizard/RadioInputForm'
import Handler from '../../../handler/superadmin/reportNakes/FormHandler'
import DropDownForm from '../../../../../core/utils/wizard/DropDownForm'

import SearchSelectFilterReport from '../../../../../components/grid/actions/SearchSelectFilterReport'

import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            file: [],
            filtered: [],
            dateRange: [],
            city: [],
            cityAll: [],
            cityPath: "",
            dokter: [],
            dokterPath: "",
            dataDokter: [],
            dataFaskes: [],
            speciality: [],
            specialityAll: [],
            specialityPath: "",
            dataSpeciality: [],
            consultation_type: [],
            consultation_typeAll: [],
            consultation_typePath: "",
            dataConsultation_type: [],
            dataInstitutionType: [],
            dataCity: [],
            showApps: [],
            institutionType:[''],
            allowBooking:[],
            keyword: '',
            startDate:'',
            endDate:'',
            startDateED:'',
            endDateED:'',
            hospitalList: [],
            hospitalListState: [],
            errorDate: false,
            selectedHospital: [],
            selectedFaskes: [],
            dataIT:[],
            dataHospital: [],
            consultation_video_call: [],
            consultation_in_app: [],
            approval: [],
            isSelectLoading: false,
            formApprFaskesTouched: false,
            Form: FormBuilder.group({
                faskes: [''],
                city:[''],
                dokter: [''],
                speciality: [''],
                resep_chat: [[]],
                consultation_in_app: [[]],
                consultation_video_call: [[]],
                approval: [[]],
            })
        }
        this.handleChangeInput = this.handleChangeInput.bind(this)

        Object.assign(this, TextInputForm)
        this.TextInputFormView = this.TextInputFormView.bind(this)
        Object.assign(this, TextAreaForm)
        this.TextAreaFormView = this.TextAreaFormView.bind(this)
        Object.assign(this, RadioInputForm)
        this.RadioInputFormView = this.RadioInputFormView.bind(this)
        Object.assign(this, DropDownForm)
        this.DropDownFormView = this.DropDownFormView.bind(this)

        // assign to handler
        Object.assign(this, Handler)
        this.handleDateRangePicker = this.handleDateRangePicker.bind(this)
        this.DateRange = this.DateRange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.buildValue = this.buildValue.bind(this)
        this.handleUpload = this.handleUpload.bind(this)
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.onChangeInputDebounced = debounce(this.onChangeInputDebounced, 500)
        this.filtered = []

        this.buildForm()
    }

    componentDidMount() {
        this.getFilter();
    }
    
    handleApply = (event, picker) => {
        this.setState({startDate: picker.startDate.format('YYYY-MM-DD')})
        this.setState({endDate: picker.endDate.format('YYYY-MM-DD')})
        var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
        var firstDate = new Date(picker.startDate.format('YYYY/MM/DD'));
        var secondDate = new Date(picker.endDate.format('YYYY/MM/DD'));
        var diffDays = Math.round(Math.round((secondDate.getTime() - firstDate.getTime()) / (oneDay)));
        var dates = diffDays < 31 ? false : true;
        this.setState({errorDate: dates})
        };
    handleApplyED = (event, picker) => {
        this.setState({startDateED: picker.startDate.format('YYYY-MM-DD')})
        this.setState({endDateED: picker.endDate.format('YYYY-MM-DD')})
        var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
        var firstDate = new Date(picker.startDate.format('YYYY/MM/DD'));
        var secondDate = new Date(picker.endDate.format('YYYY/MM/DD'));
        var diffDays = Math.round(Math.round((secondDate.getTime() - firstDate.getTime()) / (oneDay)));
        var dates = diffDays < 31 ? false : true;
        this.setState({errorDate: dates})
        };
    handleCancel = (event, picker) => {
        picker.element.val('');
    };

    handleChangeInput(e) {
        this.setState({ keyword: e.target.value })
    }
    
    handleCity(value) {
        this.setState({ dataCity: value })
    }
    handleConsultationType(value) {
        this.setState({ dataConsultation_type: value })
    }

    handleDokter(value) {
        this.setState({ dataDokter: value })
    }

    handleFaskes(value) {
        this.setState({ dataFaskes: value })
    }
    
    handleSpeciality(value) {
        this.setState({ dataSpeciality: value })
    }

    onChangeInputDebounced(keyword) {
        this.getHospital(keyword)
    }

    render() {
        const {
            loading,
            Form,
            dataCity,
            city,
            cityAll,
            cityPath,
            dataDokter,
            dokter,
            dokterPath,
            dokterAll,
            dataFaskes,
            faskes,
            faskesPath,
            faskesAll,
            dataSpeciality,
            speciality,
            specialityAll,
            specialityPath,
            dataConsultation_type,
            consultation_type,
            consultation_typeAll,
            consultation_typePath,
            keyword,
        } = this.state

        return (
            <FieldGroup control={Form}
                strict={false}
                render={({ invalid }) => {
                    return (
                        <div
                            className="wizard-form custom-form"
                            style={{ display: 'flex', margin: '18px auto 70px' }}
                        >
                            <form onSubmit={this.handleSubmit} autoComplete="off">
                                <div className="form-line">
                                    <label htmlFor="specialist_ids" className="form-control-label">
                                        Pilih Tanggal <span style={{color: 'red'}}> *</span>
                                    </label>
                                    <div className="form-group">
                                        <DateRangePicker initialSettings={{ showDropdowns: true }} onApply={this.handleApply} >
                                            <input type="text" className="form-control form-control-success datepickernew"/>
                                        </DateRangePicker>
                                    </div>
                                    {this.state.errorDate && (
										<div className='d-flex justify-content-start ml-2 mt-2'>
											<span style={{ color: 'red', fontSize: '10px' }}>Interval tanggal tidak boleh lebih dari 31 hari</span>
										</div>
									)}
                                </div>
                                <div className="form-line">
                                    <label htmlFor="dokter" className="form-control-label">
                                        Nama Dokter <span style={{color: 'red'}}> *</span>
                                    </label>
                                    <div className="form-group-wizard subscription">
                                        <SearchSelectFilterReport
                                            filterOpt={dokter} 
                                            path={dokterPath}
                                            ref={dokterAll}
                                            key={dokter}
                                            value={keyword}
                                            handlerChangeOpt={(type, value) => this.handleDokter(value)}
                                            selectedOpt={dataDokter.length === 0 ? (dokter && dokter[0]) : dataDokter}
                                         />
                                    </div>
                                </div>     
                                <div className="form-line">
                                    <label htmlFor="faskes" className="form-control-label">
                                        Nama Faskes <span style={{color: 'red'}}> *</span>
                                    </label>
                                    <div className="form-group-wizard subscription">
                                        <SearchSelectFilterReport
                                            filterOpt={faskes} 
                                            path={faskesPath}
                                            ref={faskesAll}
                                            key={faskes}
                                            value={keyword}
                                            handlerChangeOpt={(type, value) => this.handleFaskes(value)}
                                            selectedOpt={dataFaskes.length === 0 ? (faskes && faskes[0]) : dataFaskes}
                                         />
                                         {}
                                    </div>
                                </div>   
                                <div className="form-line">
                                    <label htmlFor="speciality" className="form-control-label">
                                        Spesialisasi <span style={{color: 'red'}}> *</span>
                                    </label>
                                    <div className="form-group-wizard subscription">
                                        <SearchSelectFilterReport
                                            filterOpt={speciality} 
                                            path={specialityPath}
                                            ref={specialityAll}
                                            key={speciality}
                                            value={keyword}
                                            handlerChangeOpt={(type, value) => this.handleSpeciality(value)}
                                            selectedOpt={dataSpeciality.length === 0 ? (speciality && speciality[0]) : dataSpeciality}
                                         />
                                    </div>
                                </div>
                                <div className="form-line">
                                    <label htmlFor="city" className="form-control-label">
                                        Nama Kota <span style={{color: 'red'}}> *</span>
                                    </label>
                                    <div className="form-group-wizard subscription">
                                        <SearchSelectFilterReport
                                            filterOpt={city} 
                                            path={cityPath}
                                            ref={cityAll}
                                            key={city}
                                            value={keyword}
                                            handlerChangeOpt={(type, value) => this.handleCity(value)}
                                            selectedOpt={dataCity.length === 0 ? (city && city[0]) : dataCity}
                                         />
                                    </div>
                                </div> 
                                <div className="form-line">
                                    <label htmlFor="consultation_type" className="form-control-label">
                                        Tipe Konsultasi <span style={{color: 'red'}}> *</span>
                                    </label>
                                    <div className="form-group-wizard subscription">
                                        <SearchSelectFilterReport
                                            filterOpt={consultation_type} 
                                            path={consultation_typePath}
                                            ref={consultation_typeAll}
                                            key={consultation_type}
                                            value={keyword}
                                            handlerChangeOpt={(type, value) => this.handleConsultationType(value)}
                                            selectedOpt={dataConsultation_type.length === 0 ? (consultation_type && consultation_type[0]) : dataConsultation_type}
                                         />
                                    </div>
                                </div>   
                                <div className="form-line">
                                    <label htmlFor="sip_expired_date" className="form-control-label">
                                        STR Expired Date
                                    </label>
                                    <div className="form-group">
                                        <DateRangePicker initialSettings={{ showDropdowns: true }} onApply={this.handleApplyED} >
                                            <input type="text" className="form-control form-control-success datepickernew"/>
                                        </DateRangePicker>
                                    </div>
                                    {/* {this.state.errorDate && (
										<div className='d-flex justify-content-start ml-2 mt-2'>
											<span style={{ color: 'red', fontSize: '10px' }}>Interval tanggal tidak boleh lebih dari 31 hari</span>
										</div>
									)} */}
                                </div> 
                                {this.DropDownKonsultasiApp}
                                {this.DropDownKonsultasiVC}
                                {this.DropDownPeresepanChat}
                                {this.DropDownApproval}
                                {
                                    this.state.errorDate === true ? '' :
                                    <div className="form-btn-action" style={{float:'right'}}>
                                        <button
                                            type="submit"
                                            className={
                                                `save itgbtn primary`
                                            }
                                            disabled={loading || invalid}
                                            onSubmit={this.handleSubmit}
                                        >
                                            {loading
                                                ? 'Loading...'
                                                : 'Download'}
                                        </button>
                                    </div>
                                }
                                    
                            </form>
                        </div>
                    )
                }}
            />
        )
    }
}

export default Form