import React, { Component } from 'react'
import MasterTemplate from '../../template/MasterTemplate'
import Config from './config/UrlConfig'
import Grid from './components/Grid'
import { Redirect } from 'react-router-dom'

export default class Main extends Component
{
    constructor(props) {
        super(props)
        this.state = {
            dataGrid : [],
            isEdit: false,
        }
        this.callBackState = this.callBackState.bind(this)
    }

    callBackState(state){
        this.setState({
          dataGrid : state,
          isEdit: state.editTrigger
        })
    }

    render() {
        const { isEdit, dataGrid } = this.state;
        const { history, store } = this.props;
        if(isEdit){
            return <Redirect to={{
              pathname: Config.EditUrl + '/' + dataGrid.dataProvider.id,
              state: { dataProvider: dataGrid.dataProvider }
            }} />
        }
        return (
            <MasterTemplate {...this.props}>
                <Grid callBackState={this.callBackState} history={history} store={store}/>
            </MasterTemplate>
        )
    }
}