import React, { Component } from 'react'
import MasterTemplate from '../../../template/MasterTemplate'
import DoctorSpecialistGrid from './components/DoctorSpecialistGrid'
import { Redirect } from 'react-router-dom'

export default class DoctorSpecialistMain extends Component
{
    constructor(props) {
        super(props)
        this.state = {
            dataGrid : [],
            isEdit: false,
        }
        this.callBackState = this.callBackState.bind(this)
    }

    callBackState(state){
        this.setState({
          dataGrid : state,
          isEdit: state.editTrigger
        })
    }

    render() {
        const { isEdit, dataGrid } = this.state
        const { history, store } = this.props
        if(isEdit){
            return <Redirect to={{
              pathname: '/doctor/specialist/edit/' + dataGrid.dataProvider.id,
              state: { dataProvider: dataGrid.dataProvider }
            }} />
        }
        return (
            <MasterTemplate {...this.props}>
                <DoctorSpecialistGrid callBackState={this.callBackState} history={history} store={store}/>
            </MasterTemplate>
        )
    }
}