/* eslint-disable */

import React, { Component } from 'react'
import BackButton from '../../../../themes/v1/img/layout/icon/arrow-prev.png'
import FormOriginalPatient from '../../components/FormBuilder/FormPreview'

class PreviewModal extends Component
{
    componentWillUpdate(nextProps, nextState){
        if(nextProps.previewMode === true){
            document.body.classList.add('overflow-on')
        }else{
            document.body.classList.remove('overflow-on')
        }
    }

    render(){
        if(this.props.previewMode){
            return(
                <div className="preview-form-page">
                    <div className="header">
                        <div className="title">
                            <a className="button-back-wizard" onClick={ this.props.togglePreview }>
                                <img src={ BackButton } alt="Back" />
                            </a>
                            <div>
                                <span className="main">Preview Formulir</span>
                            </div>
                        </div>
                    </div>
                    <div className="body">
                        <FormOriginalPatient {...this.props}/>
                        <div className="wrap-button">
                            <div className="btn-preview-submit">
                                Simpan
                            </div>
                        </div>
                    </div>
                </div>
            )
        }else{
            return null
        }
    }
}

export default PreviewModal