/* eslint-disable */

import React from 'react'
import { DragDropContainer } from 'react-drag-drop-container';
import BuilderConfig from '../../config/FormBuilderConfig'
import icoPlus from '../../../../themes/v1/img/layout/icon/plus.png'
import ReactTooltip from 'react-tooltip'
import IconTool from './IconTool'

class Tool extends React.Component {
  render() {
    const tools = BuilderConfig.inputTypes
    return (
      <div className="wrap-form-tool">
        <div className="header">
          <h3>Daftar Formulir</h3>
          <span>"Drag and drop" formulir ke bagian kiri</span>
        </div>

        <div className="body">
          <ul className="input-tools">
            {
              tools.map(option => {
                return(
                  <li key={option.key}>
                    <DragDropContainer
                      dragData={ option.key } 
                      targetKey={ BuilderConfig.targetDrop }
                      dragClone={ true }
                      onDrop={ this.props.handleItemDrop }
                    >
                      <a data-tip={ option.desc } data-for="tool-info">
                        <i className="fas fa-bars icon"></i>
                      </a>
                      <ReactTooltip 
                        id='tool-info' 
                        place="left" 
                        className="cinnamon-tooltip"
                        delayShow={1000}
                      />
                      <IconTool fieldKey={ option.key } />
                      <span className="tool-label">{option.label}</span>
                      <button className="manual-add" title="Tambah field" onClick={() => this.props.handleAdd(option.key)}>
                        <img src={ icoPlus } alt="add field" />
                      </button>
                    </DragDropContainer>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    )
  }
}

export default Tool