import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, Redirect } from 'react-router-dom'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../core/const/LoginConst'

import MasterTemplate from '../../template/MasterTemplate'
import AccessPermission from '../../template/AccessPermission'
import WidgetInfoRS from './components/WidgetInfoRS'
import WidgetSummary from './components/WidgetSummary'
import WidgetActiveDoctor from './components/WidgetActiveDoctor'
import WidgetPatient from './components/WidgetPatient'
import RoleHelper from '../../core/helper/RoleHelper'
import HospitalDashHandler from './handler/HospitalDashHandler'

class DashboardHospital extends Component {
    constructor(props) {
        super(props)

        this.state = {
            infoHosp: {},
            appSum: {},
            schDoctor: {},
            activeDoctor: [],
            patientTotal: {},
            patientNew: {},
            patientCancel: {},
            appLabel: [
                'Pasien yang mendaftar',
                'Sudah daftar ulang',
                'Belum dikonfirmasi',
            ],
            doctorLabel: [
                'Memiliki jadwal',
                'Tidak memiliki jadwal',
            ],
            period: [
                {
                    label: 'Hari ini',
                    value: 'today',
                },
                {
                    label: 'Minggu ini',
                    value: '7days',
                },
                {
                    label: 'Bulan ini',
                    value: '30days',
                },
            ],
            periodDoctor: [
                {
                    label: 'Hari ini',
                    value: 'today',
                },
                {
                    label: '7 hari kedepan',
                    value: '7days',
                },
                {
                    label: '30 hari kedepan',
                    value: '30days',
                },
            ],
            periodSelectedAppoint: 'today',
            periodSelectedDoctor: 'today',
            periodSelectedPatientNew: 'today',
            periodSelectedPatientCancel: 'today',
        }

        Object.assign(this, HospitalDashHandler)
        this.infoHospital = this.infoHospital.bind(this)
        this.appointmentSumm = this.appointmentSumm.bind(this)
        this.scheduleDoctor = this.scheduleDoctor.bind(this)
        this.activeDoctor = this.activeDoctor.bind(this)
        this.dataPatient = this.dataPatient.bind(this)
        this.getNotifHandle = this.getNotifHandle.bind(this)
        this.isSuperadmin = RoleHelper.isSuperadmin(props.dataLogin)
    }

    componentDidMount() {
        const {
            periodSelectedAppoint,
            periodSelectedDoctor,
            periodSelectedPatientNew,
            periodSelectedPatientCancel,
        } = this.state
        const { currentHospital } = this.props

        window.scrollTo(0, 0);

        if (currentHospital !== null) {
            this.infoHospital(currentHospital);
            this.appointmentSumm(currentHospital, periodSelectedAppoint);
            this.scheduleDoctor(currentHospital, periodSelectedDoctor);
            this.activeDoctor(currentHospital);
            this.dataPatient('patientTotal', currentHospital, 'total');
            this.dataPatient('patientNew', currentHospital, 'new', periodSelectedPatientNew);
            this.dataPatient('patientCancel', currentHospital, 'cancel', periodSelectedPatientCancel);

            if (!this.isSuperadmin) {
                this.getNotifHandle(currentHospital);
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const {
            periodSelectedAppoint,
            periodSelectedDoctor,
            periodSelectedPatientNew,
            periodSelectedPatientCancel,
        } = this.state
        const { currentHospital } = this.props

        if (currentHospital !== nextProps.currentHospital) {
            if (nextProps.currentHospital !== null) {
                this.infoHospital(nextProps.currentHospital);
                this.appointmentSumm(nextProps.currentHospital, periodSelectedAppoint);
                this.scheduleDoctor(nextProps.currentHospital, periodSelectedDoctor);
                this.activeDoctor(nextProps.currentHospital);
                this.dataPatient('patientTotal', nextProps.currentHospital, 'total');
                this.dataPatient('patientNew', nextProps.currentHospital, 'new', periodSelectedPatientNew);
                this.dataPatient('patientCancel', nextProps.currentHospital, 'cancel', periodSelectedPatientCancel);
            }
        }
    }

    generateCount = (label, count) => {
        const result = Object.keys(count).map((item, i) => {
            return {
                label: label[i],
                count: count[item],
            }
        })

        return result
    }

    handlePeriodAppoint = (data) => {
        const periodSelected = data.value
        const { currentHospital } = this.props

        this.setState({ periodSelectedAppoint: periodSelected })
        this.appointmentSumm(currentHospital, periodSelected);
    }

    handlePeriodDoctor = (data) => {
        const periodSelected = data.value
        const { currentHospital } = this.props

        this.setState({ periodSelectedDoctor: periodSelected })
        this.scheduleDoctor(currentHospital, periodSelected);
    }

    handlePeriodPatientNew = (data) => {
        const periodSelected = data.value
        const { currentHospital } = this.props

        this.setState({ periodSelectedPatientNew: periodSelected })
        this.dataPatient('patientNew', currentHospital, 'new', periodSelected);
    }

    handlePeriodPatientCancel = (data) => {
        const periodSelected = data.value
        const { currentHospital } = this.props

        this.setState({ periodSelectedPatientCancel: periodSelected })
        this.dataPatient('patientCancel', currentHospital, 'cancel', periodSelected);
    }

    render() {
        const {
            infoHosp,
            appSum,
            schDoctor,
            activeDoctor,
            patientTotal,
            patientNew,
            patientCancel,
            appLabel,
            doctorLabel,
            period,
            periodDoctor,
            periodSelectedAppoint,
            periodSelectedDoctor,
            periodSelectedPatientNew,
            periodSelectedPatientCancel,
        } = this.state
        const { dataLogin } = this.props

        if (dataLogin && dataLogin.user_type === 'd') {
            return <Redirect to="/appointment/list" />
        }

        return (
            <AccessPermission {...this.props} module="dashboard">
                <MasterTemplate {...this.props} >
                    <div className={`dashboard-hospital ${this.isSuperadmin ? 'adjust-padding' : ''}`}>
                        <div className="grid-count-box">
                            <WidgetInfoRS
                                data={infoHosp}
                                className="info-rs"
                            />
                            <WidgetSummary
                                label="Appointment"
                                data={this.generateCount(appLabel, appSum)}
                                period={period}
                                periodSelected={periodSelectedAppoint}
                                onChange={this.handlePeriodAppoint}
                                className="appointment"
                            />
                            <WidgetSummary
                                label="Dokter"
                                data={this.generateCount(doctorLabel, schDoctor)}
                                period={periodDoctor}
                                periodSelected={periodSelectedDoctor}
                                onChange={this.handlePeriodDoctor}
                                className="doctor"
                            />
                        </div>
                        <div className="wrapper-patient">
                            <WidgetPatient
                                label="Total Pasien"
                                data={patientTotal}
                                className="total"
                            />
                            <WidgetPatient
                                label="Pasien Baru"
                                data={patientNew}
                                period={period}
                                periodSelected={periodSelectedPatientNew}
                                onChange={this.handlePeriodPatientNew}
                            />
                            <WidgetPatient
                                status="reverse"
                                label="Pasien Tidak Hadir"
                                data={patientCancel}
                                period={period}
                                periodSelected={periodSelectedPatientCancel}
                                onChange={this.handlePeriodPatientCancel}
                            />
                        </div>
                        <div className="wrapper-doctor">
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                                <div className="label">
                                    <div className="title">Jadwal Aktif</div>
                                    <div className="subtitle">{activeDoctor.length} Jadwal</div>
                                </div>
                                <div className="action">
                                    <NavLink to="/appointment/list">Lihat Semua</NavLink>
                                </div>
                            </div>
                            <div className="wrapper-active-doctor">
                                {activeDoctor.length > 0
                                    ? activeDoctor.map(item => <WidgetActiveDoctor key={item.id} {...item} {...this.props} />)
                                    : null}
                            </div>
                        </div>
                    </div>
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(DashboardHospital)
