import React from 'react'
import { Redirect } from 'react-router-dom'
import ReactTable from 'react-table'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import { ContextMenuProvider } from 'react-contexify'

import SliderPanel from '../../../../../components/wizard/SliderPanel'
import MasterModal from '../../../../../core/utils/MasterModal'
import MasterGrid from '../../../../../components/grid/MasterGrid'
import FormRoleEdit from './FormRoleEdit'
import GridHandler from '../../../handler/superadmin/setting/GridHandler'

const CheckboxTable = checkboxHOC(ReactTable)

class Grid extends MasterGrid {
    constructor(props) {
        super(props)

        Object.assign(this, GridHandler)

        this.onPanelOpen = this.onPanelOpen.bind(this)
        this.onPanelClose = this.onPanelClose.bind(this)
        this.filterable = false
        this.gridColumn = this.gridColumn.bind(this)
        this.gridMapping = (this.selectedTableStatus) ? CheckboxTable : ReactTable
        this.hidePagination = true
        this.page = 0
        this.pageSize = 20
        this.selectedTableStatus = false
        this.sorted = []

        /* own property */
        this.fetchData = this.fetchData.bind(this)
        this.extraProperties = {
            onFetchData: this.fetchData,
            filterAble: true
        }

        /* data */
        const loading = (this.extraProperties.hasOwnProperty('onFetchData')) ? true : false
        const data = (this.dataFromRedux) ? this.props.data : ((loading) ? [] : this.getData())
        const columns = this.getColumns(data)
        const finalColumn = this.gridColumn(ContextMenuProvider, columns)
        const pages = (loading) ? null : 1

        /* modal */
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.modalHandleSubmit = this.modalHandleSubmit.bind(this)

        this.handleDelete = this.handleDelete.bind(this)

        this.state = {
            isPaneOpen: false,
            panelData: {
                headTitle: 'Hak Akses'
            },
            data,
            dataEdit: {},
            columns: finalColumn,
            selection: [],
            deleted: [],
            selectAll: false,
            loading: loading,
            pages: pages,
            filtered: [],
            isModalOpen: false,
            ModalData: null,
            redirectAdd: false,
        }
    }

    handleDelete = (data) => {
        const ModalData = {
            id: 'data.id',
            action: 'delete_role',
            content: 'Konfirmasi hapus peran ini. <br />Apakah Anda Yakin?',
            buttonConfirmText: 'Konfirmasi',
            buttonCancelText: 'Kembali',
        }

        this.handleOpenModal(ModalData)
    }

    addRole = () => {
        this.setState({ redirectAdd: true })
    }

    render() {
        const {
            dataEdit,
            isModalOpen,
            redirectAdd,
        } = this.state

        if (redirectAdd) {
            return (
                <Redirect push to="/role-add" />
            )
        }

        return (
            <div>
                <div className="title-wrapper">
                    <h2 className="no-margin-top page-title">{this.props.title}</h2>
                    <button onClick={this.addRole}>Tambah Peran</button>
                </div>
                <section className="tables">
                    <div className="row">
                        <div className="col-lg-12 cn-table-container">
                            <div className="card">
                                {super.render()}
                            </div>
                        </div>
                    </div>
                </section>
                <SliderPanel
                    {...this.props}
                    open={this.state.isPaneOpen}
                    onPanelClose={this.onPanelClose}
                    panelData={this.state.panelData}>
                    <FormRoleEdit dataEdit={dataEdit} handleOpenModal={this.handleDelete} />
                </SliderPanel>
                <MasterModal
                    className="master-modal"
                    isOpen={isModalOpen}
                    handleClose={this.handleCloseModal}
                    handleSubmit={this.modalHandleSubmit}
                    ModalData={this.state.ModalData} />
            </div>
        )
    }
}

export default Grid