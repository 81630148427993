import { isEmpty } from 'lodash'
import { axiosBase } from './MasterConfig'

const axiosB = axiosBase()
const groupUrl = 'hospital/invoice'

export default class BillingHospitalApi {
    static list = (hId, page, pageSize, sortProperties, sortValue, filterData) => {
        let setSort = ''
        let setFilter = ''

        if (sortProperties) {
            setSort = `&sort=${sortProperties}&order=${sortValue}`
        }

        if (!isEmpty(JSON.parse(filterData))) {
            setFilter = `&filter=${filterData}`
        }

        return axiosB.get(`/${groupUrl}?hospital_id=${hId}&page=${page}&limit=${pageSize}${setFilter}${setSort}`)
    }
    static detail = (id, hId) => axiosB.get(`/${groupUrl}/${id}?hospital_id=${hId}`)
    static create = (data) => axiosB.post(`/${groupUrl}`, data)
    static update = (id, data) => axiosB.put(`/${groupUrl}/${id}`, data)
    static patient = (hId, search) => axiosB.get(`/${groupUrl}/patient?hospital_id=${hId}&search=${search}`)
}
