import React, {Component} from 'react'
import {FieldGroup, FormBuilder, Validators} from "react-reactive-form"
import SubmitButtonForm from '../../../core/utils/SubmitButtonForm'
import TextInputForm from '../../../core/utils/wizard/TextInputForm'
import DropDownForm from '../../../core/utils/wizard/DropDownForm'
import UserFormHandler from '../handler/UserFormHandler'
import AccessComponent from './AccessComponent'
import { 
    loginMapDispatchToProps, 
    loginMapStateToProps
} from '../../../core/const/LoginConst'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import TextInputEmailForm from '../../../core/utils/wizard/TextInputEmailForm'
import { UserConfig } from '../Config'
import SliderPanel from '../../../components/wizard/SliderPanel'
import PolyList from './User/PolySlider'
import ChosenPoly from './User/ChosenPoly'

class UserFormComponent extends Component {
    constructor(props) {
        super(props)

        Object.assign(this, TextInputForm)
        Object.assign(this, DropDownForm)
        Object.assign(this, TextInputEmailForm)
        Object.assign(this, UserFormHandler)

        // custom validation must place here bind first
        this.customErrorDefinedHere = this.customErrorDefinedHere.bind(this)
        // end of custom validation

        this.state = {
            name: "",
            email: "",
            password: "",
            postStatus: false,
            isPaneOpen: false,
            isEdit: false,
            panelData: {
                headTitle: 'Akses Poliklinik'
            },
            polyOpt: [],
            Form: FormBuilder.group({
                id: [""],
                name: ["", Validators.required],
                role: ["", Validators.required],
                email: [
                    "", [Validators.required, Validators.email]
                ],
                poly: [[], Validators.required],
                telephone: ["", Validators.required],
                active: [ true ],
            })
        }

        this.TextInputFormView = this.TextInputFormView.bind(this)
        this.TextInputEmailFormView = this.TextInputEmailFormView.bind(this)
        this.DropDownFormView = this.DropDownFormView.bind(this)

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.valueBuilder = this.valueBuilder.bind(this)
        this.onPanelOpen = this.onPanelOpen.bind(this)
        this.onPanelClose = this.onPanelClose.bind(this)
        this.fillForm = this.fillForm.bind(this)
        this.fetchPoly = this.fetchPoly.bind(this)

        this.buildForm()

    }
    // example custom error
    customErrorDefinedHere(control) {
        let validate = false;

        if (!this.state) {
            return;
        }
        if (this.state.form.value.password === control.value) {
            validate = true
        }
        return (validate && control.value.length > 0) ? null : { pwdConfMatch: true}
    }
    // end of example custom error
    componentDidMount() {
        this.valueBuilder()
    }

    renderAccess(){
        let theReturn = []
        const accessCount = this.state.userAccess.length
        for(let i = 0; i < accessCount; i++ ){
            theReturn.push(<AccessComponent 
                key={i}
                idx={i}
                onChange={this.handleAccessAdd.bind(this)}
                onClose={this.handleAccessClose.bind(this)}
                onDelete={this.handleAccessDelete.bind(this)}
                polyclinic={this.state.optionPoly} 
                role={this.state.optionRole} 
                value={this.state.userAccess[i]}
                />)
        }
        return theReturn
    }

    handleAccessAdd(thePoly, theRole, idx){
        let accessList = this.state.userAccess
        let accessCount = accessList.length
        const checkAccess = thePoly.value + "-" + theRole.value
        let temp = ''
        const newAccess = {
            polyclinic: thePoly.value,
            role: theRole.value
        }
        for(let i=0; i<accessCount; i++){
            temp = accessList[i].poly + "-" + accessList[i].role
            if(checkAccess === temp){
                return
            }
        }
        accessList[idx] = newAccess
        this.setState({
            userAccess: accessList,
        })
    }

    handleAccessClose(idx){
        let accessList = this.state.userAccess
        accessList.splice(idx,1)
        this.setState({
            userAccess: accessList
        })
    }

    handleAccessDelete(idx){
        let accessList = this.state.userAccess
        accessList[idx] = {
            polyclinic: null,
            role: null
        }
        this.setState({
            userAccess: accessList
        })
        this.renderAccess()
    }

    handleAdd(event){
        event.preventDefault()
        let accessList = this.state.userAccess
        
        const temp = {
            polyclinic: 0,
            role: 0,
        }

        accessList.push(temp)
        this.setState({
            userAccess: accessList
        })
        this.renderAccess()
    }

    displayPoly(polyKeys){
        const fetchedPoly = this.state.polyOpt
        let displayData = []
        if(fetchedPoly){
            fetchedPoly.map((data) => {
                if(polyKeys.indexOf(data.value) !== -1){
                    displayData.push(data)
                }

                return false
            })
        }

        return displayData
    }

    render() {
        const { postStatus, Form } = this.state
        const pickedPoly = this.state.Form.controls.poly.value
        const poly = this.displayPoly(pickedPoly)

        if (postStatus) {
            return <Redirect to={ UserConfig.baseUrl }/>
        }

        return (
            <div className="wizard-form admin-form">
                {/** start form */}
                <FieldGroup
                    control={Form}
                    strict={false}
                    render={({get, invalid}) => {
                        return (
                            <form onSubmit={() => this.handleSubmit} className="form-horizontal form-poly">
                                { this.TextName }
                                { this.TextEmail }
                                <small className="form-small">Informasi login dan kata sandi akan dikirimkan ke alamat ini.</small>
                                { this.TextTelephone }
                                { this.DropDownRole }
                                <div className="poly-list-box">
                                    <h3>Poliklinik</h3>
                                    <div className="subtitle">Pilih akses poliklinik</div>
                                    {
                                        poly.length > 0 ?
                                        <div>
                                            <ChosenPoly poly={ poly }/>
                                            <button 
                                                type="button"
                                                className="change-poly regular-button invert"
                                                onClick={ this.onPanelOpen }
                                            >
                                                Ubah
                                            </button>
                                        </div> : 
                                        <div className="poly-empty">
                                            <span>Akses Poliklinik</span>
                                            <button type="button" onClick={ this.onPanelOpen }>Pilih Poliklinik</button>
                                        </div>
                                    }
                                </div>
                                <SubmitButtonForm invalid={invalid}
                                    showCancel={true}
                                    submitText={"Simpan"}
                                    onSubmit={(e) => this.handleSubmit(e)}
                                    onCancel={(e) => this.props.history.push(UserConfig.baseUrl)}
                                />
                            </form>
                        )
                    }}
                />
                {/** end of form */}
                <SliderPanel 
                    { ...this.props } open={ this.state.isPaneOpen } 
                    onPanelClose={ this.onPanelClose } 
                    panelData={ this.state.panelData }>
                    <PolyList 
                        {  ...this.props } 
                        onPanelClose={this.onPanelClose} 
                        control={ this.state.Form.controls.poly }
                        polyOpt={ this.state.polyOpt }
                    />
                </SliderPanel>
            </div>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(UserFormComponent)