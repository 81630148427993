import React from 'react'
import Chance from 'chance'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import Api from '../../../../../services/apis/PatientMAPi'
import { handleToastServiceError } from '../../../../../core/utils/UtilsToast'

const chance = new Chance()

const GridHandler = {
    onPanelClose() {
        this.setState({
            isPaneOpen: false,
        })
    },
    onPanelOpen(data) {
        const { id } = data
        this.getDetail(id, 'user')
    },
    onPanelCloseFam() {
        this.setState({
            isPaneOpenFam: false,
        })
    },
    onPanelOpenFam(id) {
        this.getDetail(id, 'pasien')
    },
    getDetail(id, type) {
        const { detailPatient } = this.state
        const headTitle = <div>{`Detail ${type === 'user' ? 'User' : 'Pasien'}`}</div>

        if (type === 'user') {
            this.setState({ loading: true })
        } else {
            this.setState({ loadingFam: true })
        }

        if (id === null) {
            this.setState({
                isPaneOpenFam: true,
                detailFamily: detailPatient,
                panelDataFam: { headTitle },
            })
        } else {
            Api.detail(id).then(response => {
                if (response.data.code === 200) {
                    if (type === 'user') {
                        this.setState({
                            isPaneOpen: true,
                            detailPatient: response.data.data,
                            panelData: { headTitle },
                        })
                    } else {
                        this.setState({
                            isPaneOpenFam: true,
                            detailFamily: response.data.data,
                            panelDataFam: { headTitle },
                        })
                    }
                }

                if (type === 'user') {
                    this.setState({ loading: false })
                } else {
                    this.setState({ loadingFam: false })
                }

            }, err => {
                handleToastServiceError(err)

                if (type === 'user') {
                    this.setState({ loading: false })
                } else {
                    this.setState({ loadingFam: false })
                }

            })
        }

    },
    getFilterList: async function () {
        try {
            const response = await Api.filter()
            const { data } = response.data
            const filterList = data

            this.setState({ filterList })
        } catch (e) {
            handleToastServiceError(e)
        }
    },
    gridColumn() {
        return [
            {
                Header: "Nama User",
                accessor: "nama",
                sortable: true,
                headerClassName: "-sort",
            },
            {
                Header: "Email",
                accessor: "email",
                sortable: true,
                headerClassName: "-sort",
                Cell: row => row.original.email || '-'
            },
            {
                Header: "No. Telepon",
                accessor: "telepon",
                filterType: "number",
                sortable: true,
                headerClassName: "-sort",
            },
            {
                Header: "",
                width: 45,
                className: 'text-center',
                filterType: "reset",
                sortable: false,
                Cell: (row) => {
                    return (
                        <button
                            className="action-row"
                            onClick={() => this.onPanelOpen(row.original)}
                        >
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </button>
                    )
                }
            }
        ]
    },
    fetchData: async function (state) {
        this.setState({ loading: true })
        this.page = state.page
        this.pageSize = state.pageSize

        let res = {}
        let fArray = {}
        let sortProperty = ''
        let sortValue = ''

        state.filtered.map(val =>
            fArray[val.id] = val.value
        )
        const filterData = JSON.stringify(fArray)

        if (state.sorted !== null && state.sorted.length > 0) {
            sortProperty = state.sorted[0].id
            sortValue = (state.sorted[0].desc) ? 'desc' : 'asc'
        }

        try {
            res = await Api.list(
                state.page + 1,
                state.pageSize,
                sortProperty,
                sortValue,
                filterData,
            )
        } catch (e) {
            this.setState({
                loading: false
            })

            handleToastServiceError(e)
            return
        }

        const {
            status,
            data: resData,
        } = res

        if (status === 200) {
            let newRows = resData.data.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })

            this.setState({
                data: newRows,
                pages: resData.data.total_page,
                rows: resData.data.total_rows,
                loading: false,
            })
        } else {
            this.setState({
                loading: false
            })
        }
    }
}

export default GridHandler
