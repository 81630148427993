import { 
    MODAL_SHOW, 
    MODAL_HIDE,
    initialState
} from '../../../core/const/ModalConst'

export function modalReducer(state = initialState, action) {
    switch (action.type) {
        case MODAL_SHOW:
            return {
                isShow: true,
                ModalComponent: action.ModalComponent,
                title: action.title
            };
        case MODAL_HIDE:
            return {
                isShow: false,
                ModalComponent: null,
                title: ''
            };
        default:
            return state;
    }
  }
  