import React, { Component } from 'react'
import { StickyContainer } from 'react-sticky'
import { connect } from 'react-redux'

import { loginMapStateToProps, loginMapDispatchToProps } from '../../core/const/LoginConst'
import AccessPermission from '../../template/AccessPermission'
import MasterTemplate from '../../template/MasterTemplate'
import StickyNav from './components/sectionscroll/StickyNav'
import HospitalServicesApi from '../../services/apis/Hospital/HospitalServices'
import Form from './components/Form'

class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataGrid: [],
            isEdit: false,
            isSticky: false,
            institutionList: []
        }
    }

    setSticky = (isSticky) => {
        if (this.state.isSticky !== isSticky) {
            this.setState({ isSticky })
        }
    }

    componentDidMount(){
        HospitalServicesApi.listInstitution().then((res) => {
            if (res.status === 200) {
                const { data: institutionList } = res.data

                this.setState({
                    institutionList: institutionList
                })
            }
        }).catch(error => {
            console.log('Something went wrong:', error)
            return
        })
    }

    render() {
        const { institutionList } = this.state
        const breadcrumbs = [
            {
                label: 'Dashboard',
                path: '/dashboardhospital',
            },
            {
                label: 'Pengaturan',
                path: null,
            },
        ]

        return (
            <AccessPermission {...this.props} module="setting_hospital">
                <MasterTemplate {...this.props} breadcrumbs={breadcrumbs}>
                    <StickyContainer className="hospital-info-container">
                        <StickyNav setSticky={this.setSticky} accessBy="hospital" />
                        {
                            institutionList && institutionList.length > 0 && 
                            <Form {...this.props} isSticky={this.state.isSticky} institutionList={institutionList}/>
                        }
                    </StickyContainer>
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Main)
