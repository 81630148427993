import { handleToast, handleToastServiceError } from '../../../core/utils/UtilsToast'
import Axios from '../../../services/apis/MasterConfig'

const formHandler = {
    buildForm: function() {
        this.FileDoctor = this.FileInputFormView({
            validator: 'required',
            name: 'doctor',
            callBack: this.updateForm,
            filter: '.csv',
            icon: this.state.iconFile,
            stateLabel: "labelUploader",
            statePlaceholder: "doctorLabel",
            control: this.state.Form.controls.doctor,
            caption: 'Data Dokter'
        })
        this.FilePatient = this.FileInputFormView({
            validator: 'required',
            name: 'patient',
            callBack: this.updateForm,
            filter: '.csv',
            icon: this.state.iconFile,
            stateLabel: "labelUploader",
            statePlaceholder: "patientLabel",
            control: this.state.Form.controls.patient,
            caption: 'Data Pasien'
        })
    },
    handleClose: function(){
        this.props.onCancel()
    },
    handleSubmit: async function(e){
        e.preventDefault()
        let me = this
        let response
        let formValue = Object.assign({}, me.state.Form.value)
        
        const formData = me.setFormData(formValue)
        const headersPost = {
            'Content-Type': 'multipart/form-data' 
        }
        delete(formValue[''])
        try {
            response = await Axios.post('/dashboard/bulkdata',formData, {headers: headersPost, onUploadProgress: this.handleProgress})
            console.log(response)
        } catch (e) {
            handleToastServiceError(e)
            me.setState({
                percentage: 0
            })
            return
        }

        if (response.data.code === 200) {
            const { history } = me.props
            history.push('/dashboard')
            me.props.onCancel()    
        }

        handleToast(response.data.message)
        
    },
    handleProgress(progressEvent){
        console.log(progressEvent.loaded, progressEvent.total)
        let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
        this.setState({
            percentage: percentCompleted
        })
    },
    updateForm(me, event, type){
    
        const value = event.target.value
        const file = event.target.files[0]
        const valid = me.state.Form.controls.valid
        let arrayFiles = me.state.files
        if(!value)
            return

        const separator = (value.search("/") > -1) ? "/" : "\\"
        const array = value.split(separator)
        const name = array[array.length - 1]
        if(type === 'doctor'){
            const control = me.state.Form.controls.doctor_file
            control.patchValue(file)
            me.setState({
                doctorLabel: name,
            })
        }else{
            const control = me.state.Form.controls.patient_file
            control.patchValue(file)
            me.setState({
                patientLabel: name,
            })
        }
        valid.patchValue('true')
        arrayFiles.push(name)
        me.setState({
            labelUploader: 'Perbarui',
            showIcon: true,
            files: arrayFiles,
        })
    },
}

export default formHandler