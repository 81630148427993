import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../core/const/LoginConst'
import Config from './config/DataConfig'
import MasterTemplate from '../../template/MasterTemplate'
import Calendar from './components/Calendar'
import MasterModal from '../../core/utils/MasterModal'
import AccessPermission from '../../template/AccessPermission'

class Main extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbData: [
                {
                    no: '1',
                    label: 'Pilih Jadwal',
                    finished: false,
                    path: '/appointment',
                },
                {
                    no: '2',
                    label: 'Pilih Pasien',
                    finished: false,
                    path: '/appointment/assign',
                    onClick: (e) => {
                        e.preventDefault()
                        return false
                    }
                }
            ],
            isModalOpen: false,
            ModalData: null
        }
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.modalHandleSubmit = this.modalHandleSubmit.bind(this)
    }

    handleOpenModal() {
        const ModalData = {
            action: "exit_appointment",
            id: null,
            content: "Anda akan keluar dari halaman Tambah Antrian, apakah anda yakin?",
            buttonConfirmText: "Ya, Keluar",
            buttonCancelText: "Tidak",
            ConfirmFunction: null
        }
        this.setState({
            isModalOpen: true,
            ModalData: ModalData
        })
    }

    handleCloseModal() {
        this.setState({
            isModalOpen: false,
            ModalData: null
        })
    }

    modalHandleSubmit() {
        let me = this
        const ModalData = me.state.ModalData

        if (ModalData.action === 'exit_appointment') {
            this.setState({
                redirectTo: '/dashboardhospital'
            })
        }
    }

    render() {
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} />
        }

        return (
            <AccessPermission {...this.props} module="appointment">
                <MasterTemplate
                    {...this.props}
                    onBeforeClose={this.handleOpenModal}
                    wizard={true}
                    cancelUrl={Config.cancelUrl}
                    breadcrumbData={this.state.breadcrumbData}
                    wizardShowCloseButton={true}
                >
                    <Calendar {...this.props} hospitalId={this.props.currentHospital} />
                    <MasterModal
                        className="master-modal"
                        isOpen={this.state.isModalOpen}
                        handleClose={this.handleCloseModal}
                        handleSubmit={this.modalHandleSubmit}
                        ModalData={this.state.ModalData}
                    />
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Main)
