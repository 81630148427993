import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import connect from 'react-redux/es/connect/connect'

import DashboardTemplate from '../../template/DashboardTemplate'
import Form from '../changePassword/components/Form'
import { loginMapStateToProps } from '../../core/const/LoginConst'

class DashboardChangePass extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        let isAdmin = false
        if (this.props.isLoggin === true) {
            isAdmin = this.props.dataLogin.superadmin
        }

        if (!isAdmin) {
            return (
                <Redirect to="/" />
            )
        }

        return (
            <DashboardTemplate {...this.props} >
                <div className="dashboard-container wrapper-facility specialist">
                    <div className="container-fluid">
                        <div className="title-wrapper">
                            <h2 className="no-margin-top page-title">{this.props.title}</h2>
                        </div>
                        <Form {...this.props} />
                    </div>
                </div>
            </DashboardTemplate>
        )
    }
}

export default connect(loginMapStateToProps, null)(DashboardChangePass)
