import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { loginMapStateToProps, loginMapDispatchToProps } from '../../core/const/LoginConst'
import AccessPermission from '../../template/AccessPermission'
import MasterTemplate from '../../template/MasterTemplate'
import Form from './components/Form'

class Create extends Component {
    constructor(props) {
        super(props)
        this.state = {
            redirectTo: null,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (!this.props.dataLogin) {
            this.setState({
                redirectTo: "/"
            })
        }
    }

    render() {
        const { redirectTo } = this.state
        const breadcrumbs = [
            {
                label: 'Dashboard',
                path: '/dashboardhospital',
            },
            {
                label: 'Pengaturan',
                path: '/administration/info-hospital',
            },
            {
                label: 'Ubah Password',
                path: null,
            },
        ]

        if (redirectTo) {
            return (<Redirect to={redirectTo} />)
        }

        return (
            <AccessPermission {...this.props} module="setting_hospital">
                <MasterTemplate {...this.props} breadcrumbs={breadcrumbs}>
                    <div className="container-fluid">
                        <Form {...this.props} />
                    </div>
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Create)
