import React from 'react'
import { FieldControl } from 'react-reactive-form'
import NumberFormat from 'react-number-format'

import validateReturn from '../ValidatorForm'

const TextInputForm = {
    TextInputFormView: function ({ 
        label,
        validator,
        name,
        placeholder,
        control,
        readonly,
        inputType,
        maxLength,
        callback,
        fieldControlAdditional,
        max
    }) {
        const callBack = callback || null
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}
        Object.assign(control, additionalController)

        return <FieldControl
            name={name}
            render={(obj) => this.TextInputFormRender(obj, callBack)}
            meta={{
                label: label,
                name: name,
                maxLength: maxLength,
                readonly: readonly,
                placeholder: placeholder,
                validator: validator,
                inputType: inputType || 'text',
                max: max
            }}
            control={control}
        />
    },
    validateExtra(e, inputType, name, callback) {
        if (inputType === 'tel') {
            e.target.value = e.target.value.replace(/[^0-9]/g, '')
        }

        if (typeof callback === 'function') {
            callback(this, name)
        }
    },
    TextInputFormRender: function ({ handler, touched, hasError, meta }, callback) {
        const isRequired = meta.validator === 'required' ? true : false

        return (
            <div className={touched && hasError(meta.validator)
                ? "form-line has-danger"
                : "form-line"}
            >
                {
                    meta.label ?
                        <label
                            id={`label-${meta.name}`}
                            className="form-control-label"
                            htmlFor={`${meta.name}`}
                        >
                            {meta.label}
                            {isRequired ? <span className="required">*</span> : null}
                        </label> :
                        null
                }
                <div className="form-group">
                    {
                        meta.inputType === 'currency' &&
                        <NumberFormat 
                            id={meta.name}
                            className="form-control form-control-success"
                            placeholder={meta.placeholder}
                            maxLength={meta.maxLength}
                            prefix={'Rp '}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            allowLeadingZeros={false}
                            allowEmptyFormatting={false}
                            {...handler()}
                        />
                    }
                    {
                        meta.inputType === 'number' && 
                            <NumberFormat
                                id={meta.name}
                                className="form-control form-control-success"
                                placeholder={meta.placeholder}
                                maxLength={meta.maxLength}
                                decimalSeparator={','}
                                allowLeadingZeros={false}
                                isAllowed={(inputObj) => {
                                    let isAllowed = true;
                                    if(meta.max){
                                        const { floatValue } = inputObj
                                        if(floatValue > meta.max) {
                                            isAllowed = false
                                        }
                                    }
                                    return isAllowed;
                                }}
                                {...handler()}
                            />
                    }
                    {
                        !['number', 'currency'].includes(meta.inputType)  && 
                            <input
                                className="form-control form-control-success"
                                type={meta.inputType}
                                placeholder={meta.placeholder}
                                id={meta.name}
                                {...handler()}
                                readOnly={meta.readonly}
                                maxLength={meta.maxLength}
                                max={meta.max}
                                onKeyUp={(e) => this.validateExtra(e, meta.inputType, meta.name, callback)}
                            />
                    }
                </div>
                <span className="error-info">
                    {
                        touched
                        && hasError(meta.validator)
                        && validateReturn(meta.validator)
                    }
                </span>
            </div>
        )
    }
}

export default TextInputForm
