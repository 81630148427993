import React from 'react'
import Select from 'react-select';

class SearchTreeSelect extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentWillReceiveProps(nextProps) {
        const { element } = nextProps

        if (element.handlerInputOpt && element.keyword !== '') {
            this.refs[element.innerRef].focus()
        }
    }

    render() {
        const { element } = this.props

        return (
            <div className={`wrap-search-filter select ${element.className}`}>
                <Select
                    classNamePrefix="react-select"
                    name="filter-select"
                    placeholder={element.title}
                    onChange={val => element.handlerChangeOpt(element.actionType, val)}
                    onInputChange={element.handlerInputOpt}
                    options={element.filterOpt}
                    value={element.selectedOpt}
                    inputValue={element.keyword}
                    ref={element.innerRef}
                    openMenuOnFocus
                />
            </div>
        )
    }
}

export default SearchTreeSelect
