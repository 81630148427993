import React from 'react'
import { FieldControl } from "react-reactive-form";

const RadioInputForm = {
    RadioInputFormView: function({name, meta, control, fieldControlAdditional}) {
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}
        Object.assign(control, additionalController)
        return <FieldControl
            name = {name}
            strict = {false}
            meta = {{...meta}}
            render={(obj) => this.RadioInputFormRender(obj, name)}
            control = {control}
        />
    },
    RadioInputFormRender: function( {handler, touched, hasError, meta}) {
        return (
            <div className={touched && hasError(meta.validator) ? "form-line has-danger" : "form-line"}>
                <div className="wrap-options">
                    <span className="radio">{ meta.label }</span>
                    <div className="container-option">
                        {
                            meta.options.map((option, index) => {
                                return (
                                    <div key={ index } className="wrap-radio">
                                        <label className="container-radio">
                                            {option.name}
                                            <input 
                                                id={ option.value }
                                                readOnly={ meta.readonly }
                                                {...handler("radio", option.value)}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    },
}
export default RadioInputForm