import DashboardApi from './../../../services/apis/Dashboard/Api'
import { handleToast, handleToastServiceError } from '../../../core/utils/UtilsToast'

const formHandler = {
    buildForm: function () {
        this.TextCode = this.TextInputFormView({
            label: 'Kode RS/Klinik',
            validator: 'required',
            name: 'code',
            placeholder: 'Isi Kode RS/Klinik',
            maxLength: 10,
            control: this.state.Form.controls.code
        })
        this.TextName = this.TextInputFormView({
            label: 'Nama Institusi',
            validator: 'required',
            name: 'name',
            placeholder: 'Isi nama institusi',
            maxLength: 255,
            control: this.state.Form.controls.name
        })
        
        this.TextEmail = this.TextInputEmailFormView({
            label: 'Email Penanggung Jawab',
            validator: 'required',
            name: 'pic_email',
            placeholder: 'Isi email penanggung jawab',
            maxLength: 50,
            control: this.state.Form.controls.pic_email
        })

        this.TextPicName = this.TextInputFormView({
            label: 'Nama Penanggung Jawab',
            validator: 'required',
            name: 'pic_name',
            placeholder: 'Isi nama penanggung jawab',
            maxLength: 255,
            control: this.state.Form.controls.pic_name
        })

        this.DropDownType = this.DropDownFormView({
            meta: {
                label: "Jenis Institusi",
                placeholder: "Pilih jenis institusi",
                validator: "required",
                options: this.state.typeOption
            },
            name: "institution_type",
            control: this.state.Form.controls.institution_type,
        })
    },
    handleSubmit: async function (e) {
        e.preventDefault()

        if(this.state.isSubmit)
            return

        let formValue = Object.assign({}, this.state.Form.value)
        let temp = formValue.institution_type
        formValue.institution_type = temp.value
        let me = this
        let response

        if (this.state.file.name && this.state.file.original) {
            formValue['certificate'] = this.state.file.original
        }

        const formData = this.setFormData(formValue)
        
        try {
            me.setState({
                isSubmit: true,
            })
            response = await DashboardApi.post(formData)
            delete (formValue[''])
        } catch (e) {
            handleToastServiceError(e)
            return
        }
        
        if (response.data.code === 200) {
            const { history } = this.props
            this.props.onCancel()
            history.push('/dashboard')
        }

        handleToast(response.data.message)
    },
    setFormData(formJson) {
        const form = new FormData()
        for (const key in formJson) {
            if (typeof formJson[key] !== 'undefined') {
                form.append(key, formJson[key])
            }
        }
        return form
    },
    handleClose(){
        this.props.onCancel()
    },
    handleUpload: function(fileContent, originalFile){
        const tempObj = {
            content: fileContent,
            name: originalFile.name,
            original: originalFile
        }
        this.setState({
            file: tempObj
        })
    },
}

export default formHandler