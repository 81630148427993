import React from 'react'
import * as Sentry from '@sentry/browser'
import ReactDOM from 'react-dom'
import ReactNotification from 'react-notifications-component';
import { Provider } from "react-redux"
import { store, persistor } from "./services/flux/Store"
import { PersistGate } from 'redux-persist/lib/integration/react'
/** hello file */
import MainRouter from './routes/MainRouter'
/** Font **/
import WebFont from 'webfontloader'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser, faUserAstronaut, faHome } from '@fortawesome/free-solid-svg-icons'
import { messaging } from './init-fcm'
import handleNotif from './core/utils/UtilsNotif'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-notifications-component/dist/theme.css'
import 'react-datetime/css/react-datetime.css'
import './index.css'

if (process.env.REACT_APP_SENTRY_ENABLE === 'true') {
    Sentry.init({
        environment: process.env.REACT_APP_ENV,
        dsn: process.env.REACT_APP_SENTRY_DSN,
    })
}

/** ----------------- Start --------------------- **/
library.add(faUser, faUserAstronaut, faHome)
WebFont.load({
    google: {
        families: ['Roboto:300,400,500,700']
    }
})

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then(registration => {
            if (messaging) {
                messaging.useServiceWorker(registration);

                messaging.requestPermission()
                    .then(async function () {
                        const token = await messaging.getToken();
                        try {
                            localStorage.setItem('fcmT', token);
                        } catch (e) {
                            console.log('Local Storage is disabled');
                        }
                    })
                    .catch(function (err) {
                        console.log('Unable to get permission to notify.', err);
                    });
            }
        }).catch(err => {
            // https://stackoverflow.com/questions/62909289/how-do-i-handle-a-rejected-promise-in-a-service-worker-install-event
            console.error('Service worker failed to register.', err);
        });

    // https://firebase.google.com/docs/cloud-messaging/concept-options#notification-messages-with-optional-data-payload
    navigator.serviceWorker.addEventListener('message', ({ data }) => {
        const {
            firebaseMessaging: {
                payload: {
                    data: messageData,
                },
            },
        } = data;
        console.log('addEventListener', messageData);
        handleNotif(messageData);
    });
}

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ReactNotification className="notif-fcm" />
            <MainRouter store={store} />
        </PersistGate>
    </Provider>
    , document.getElementById('root'));
