import React, { Component } from 'react'
import Select from 'react-select'
import { debounce } from 'lodash'

import { axiosBase } from '../../../services/apis/MasterConfig'

class SearchSelectFilterReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            options: [],
        }

        this.onChangeInputDebounced = debounce(this.onChangeInputDebounced, 500)
        this.handleChangeInput = this.handleChangeInput.bind(this)
    }

    componentDidMount() {
        const { filterOpt: options } = this.props
        this.setState({ options })
    }

    handleChangeInput(keyword) {
        if (keyword.length >= 3) {
            this.setState({ loading: true })
            this.onChangeInputDebounced(keyword)
        }
    }

    onChangeInputDebounced(keyword) {
        const { path } = this.props
        const axios = axiosBase()

        axios.get(`${path}${keyword}`).then(({ data }) => {
            // const { data: options } = data
            let options = data.data.map(item => {
                return {
                    value: item.code,
                    label: `${item.name} (${item.code}) - ${item.type}`,
                    name: item.name
                }
            })
            this.setState({
                loading: false,
                options,
            })
        })
    }

    render() {
        const { loading, options } = this.state
        const {
            className,
            title,
            selectedOpt,
            keyword,
            actionType,
            handlerChangeOpt,
        } = this.props
        
        return (
            <div className={`wrap-search-filter-report select ${className}`}>
                <Select
                    classNamePrefix="react-select"
                    name="filter-select"
                    isLoading={loading}
                    placeholder={title}
                    options={options}
                    value={selectedOpt}
                    inputValue={keyword}
                    openMenuOnFocus
                    onInputChange={this.handleChangeInput}
                    onChange={val => handlerChangeOpt(actionType, val)}
                />
            </div>
        )
    }
}

export default SearchSelectFilterReport
