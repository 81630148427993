import { isEmpty } from 'lodash'

import { axiosBase, axiosSuperadmin } from './MasterConfig'

const axios = axiosBase()
const axiosS = axiosSuperadmin()

const groupListsUrl = 'lists'
const groupMgmtUrl = 'hospitalfacility'

export default class FacilityApi {
    static get = () => axios.get(`/${groupListsUrl}/fasilitas`)
    static list = (page, pageSize, sortProperties, sortValue, filterData) => {
        let setSort = ''
        let setFilter = ''

        if (sortProperties) {
            setSort = `&sort=${sortProperties}&order=${sortValue}`
        }

        if (!isEmpty(JSON.parse(filterData))) {
            setFilter = `&filter=${filterData}`
        }

        return axiosS.get(`/${groupMgmtUrl}?page=${page}&limit=${pageSize}${setFilter}${setSort}`)
    }
    static post = (data) => axiosS.post(`/${groupMgmtUrl}`, data)
    static put = (id, data) => axiosS.put(`/${groupMgmtUrl}/${id}`, data)
    static delete = (id) => axiosS.delete(`/${groupMgmtUrl}/${id}`)
    static filter = () => axiosS.get(`/${groupMgmtUrl}/filter`)
}
