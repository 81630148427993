import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { isArray } from 'lodash'
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap'

class Breadcrumbs extends Component {
    render() {
        const { breadcrumbs, className } = this.props

        if (isArray(breadcrumbs)) {
            return (
                <Container fluid>
                    <Breadcrumb className={`arrow${className ? ` ${className}` : ''}`}>
                        {
                            breadcrumbs.map((item, i) => (
                                <BreadcrumbItem key={i} active={item.path === null}>
                                    {item.path
                                        ? <NavLink to={item.path}>{item.label}</NavLink>
                                        : item.label}
                                </BreadcrumbItem>
                            ))
                        }
                    </Breadcrumb>
                </Container>
            )
        }

        return null
    }
}

export default Breadcrumbs
