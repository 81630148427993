import { makeData } from '../../core/utils/UtilsEditTable'
import Chance from "chance"
const chance = new Chance()

const MasterGridHandler = {
    onClickContext: function( { event, ref, data, dataFromProvider }, action, key, callBack ) {
        switch(action) {
            case 'delete':
                let id = dataFromProvider.original[key]

                let origin = this.state.data;
        
                let filter = origin.filter(v => v[key] === id),
                index = origin.indexOf(filter[0]);
        
                origin.splice(index, 1);

                this.setState({data: origin, deleted: [...this.state.deleted, filter[0]]})

                this.updateStatusState();
                
                if (this.extraProperties.hasOwnProperty('onFetchData')) {
                    let state = {
                        pageSize: this.pageSize,
                        page: 0,
                        sorted: [],
                        filtered: []
                    }

                    this.fetchData(state, null)
                }
                
                callBack(dataFromProvider.original)
            break;
            default:
        }
    },
    getData: function() {
        return makeData().map(item => {
            // using chancejs to generate guid
            // shortid is probably better but seems to have performance issues
            // on codesandbox.io
            const _id = chance.guid();
            return {
              _id,
              ...item
            };
        });
    },
    getColumns: function(data) {
        if (typeof data === 'undefined' || data.length <= 0) return [];

        const columns = [];
        const sample = data[0];
        const keyTable = this.keyTable;

        Object.keys(sample).forEach(key => {
          if (key !== keyTable && !this.ignoreColumn.includes(key)) {
            columns.push({
              accessor: key,
              Header: key
            });
          }
        });
        return columns;
    },
    toggleSelection: function(requestKey, shift, row) {
        let key = requestKey.replace('select-', '')
        let selection = this.state.selection
        let dataLength = this.selectedMax
        const keyIndex = selection.indexOf(key)
        const { data } = this.state

        if (!this.isDashboard || (this.isDashboard && row.allow_delete)) {
          // check to see if the key exists
          if (keyIndex >= 0) {
            // it does exist so we will remove it using destructing
            selection = [
              ...selection.slice(0, keyIndex),
              ...selection.slice(keyIndex + 1)
            ];
          } else {
            // it does not exist so add it
            selection.push(key);
          }
        }

        if (dataLength === 0) {
          dataLength = data.length
        }

        // update the state
        this.setState({ 
          selection,
          selectAll: dataLength === selection.length,
        });
    },
    toggleAll: function(type) {
        const selectAll = type !== 'deselect-all'
          ? this.state.selectAll ? false : true
          : false
        const selection = [];
        
        if (selectAll) {
          // we need to get at the internals of ReactTable
          const wrappedInstance = this.checkboxTable.getWrappedInstance();
          // the 'sortedData' property contains the currently accessible records based on the filter and sort
          const currentRecords = wrappedInstance.getResolvedState().sortedData;
          // we just push all the IDs onto the selection array

          currentRecords.forEach((item, i) => {
            if (this.gridType === 'Appointment') {
              if (item._original.send_email) {
                selection.push(item._original._id);
              }
            } else if (this.selectedMax > 0) {
              if (i < this.selectedMax) {
                selection.push(item._original._id);
              }
            } else {
              selection.push(item._original._id);
            }
          });
        }
        this.setState({ selectAll, selection });    
        this.updateStatusState()
    },
    isSelected: function(key) {
        return this.state.selection.includes(key);
    },
    gridOnPageChange: function(props) {
        this.setState({pages: props + 1})
    }
}

export default MasterGridHandler;
