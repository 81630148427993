import React, { Component } from 'react'
import dateFns from 'date-fns'
import IndonesianLocale from 'date-fns/locale/id'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import ReactTooltip from 'react-tooltip';

class SearchRangeNew extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.elementData = this.props

        this.onKeyPress = this.onKeyPress.bind(this)
    }

    onKeyPress = (event) => {
        let maxLength = 2
        const inputType = event.target.getAttribute('name')
        const value = event.target.value

        if (inputType === 'year') {
            maxLength = 4
        }

        if (value.length >= maxLength) {
            event.preventDefault();
            return false;
        }

        return true;
    }

    render() {
        const { isOpen, actionType, filterKeyword, className, handlerChange, dataSelectedOrder } = this.props
        const now = new Date()
        const date = ('0' + now.getDate()).slice(-2)
        const month = dateFns.format(now, 'MMM', { locale: IndonesianLocale })
        const year = `${now.getFullYear()}`

        let isEmptyM = ''
        if (!filterKeyword) {
            isEmptyM = ' empty'
        }
        
        const elementData = this.elementData
        return (
            <div className={`wrap-search-filter wizard-datepicker ${className}${isEmptyM}`} >
                <ReactTooltip className="cinnamon-tooltip custom-action" effect="solid" anchorId="my-element"/>
                <span data-tip={ elementData.tooltip }>
                <DateRangePicker
                    locale="id-ID"
                    isOpen={isOpen}
                    dayPlaceholder={date}
                    monthPlaceholder={month}
                    yearPlaceholder={year}
                    format="d MMM y"
                    value={dataSelectedOrder}
                    onChange={val => handlerChange(actionType, val)}
                    onKeyPress={this.onKeyPress}
                    className="react-date-picker__wrapper"
                    calendarIcon={filterKeyword
                        ? null
                        : <i className="fas fa-calendar-alt" />}
                    clearIcon={!filterKeyword
                        ? null
                        : <i className="fas fa-times" />}
                    
                />  
                </span>
            </div>
        )
    }
}

export default SearchRangeNew
