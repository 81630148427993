import {handleToast, handleToastServiceError} from '../../../core/utils/UtilsToast'
import Api from '../../../services/apis/Patient/Patient'
import ApiArea from '../../../services/apis/AreaApi'
import HospitalApi from '../../../services/apis/Hospital/List'
import {
    FormControl,
    FormGroup,
    Validators
} from "react-reactive-form"
import Config from '../config/UrlConfig'

const FormHandler = {
    buildForm: function () {
        this.TextFirstName = this.TextInputFormView({
            label: "Nama Depan Pasien",
            placeholder: "Isi nama depan pasien",
            validator: "required",
            name: "nama_depan",
            control: this.state.Form.controls.nama_depan
        })
        this.TextLastName = this.TextInputFormView({
            label: "Nama Belakang Pasien",
            placeholder: "Isi nama belakang pasien",
            validator: "required",
            name: "nama_belakang",
            control: this.state.Form.controls.nama_belakang
        })

        this.TextBirthPlace = this.TextInputFormView({
            label: "Tempat Lahir",
            placeholder: "Isi tempat lahir pasien",
            name: "tempat_lahir",
            control: this.state.Form.controls.tempat_lahir
        })

        this.TextPhone = this.TextInputPhoneFormView({
            label: "Telepon",
            placeholder: "Isi telepon pasien",
            inputType: "tel",
            name: "telepon",
            maxLength: 15,
            control: this.state.Form.controls.telepon
        })

        this.TextEmail = this.TextInputEmailFormView({
            label: "Email",
            placeholder: "Isi email pasien",
            name: "email",
            control: this.state.Form.controls.email
        })

        this.RadioGender = this.RadioInputFormView({
            name: "jenis_kelamin",            
            meta: {
                label: "Jenis Kelamin",
                options: [
                    {
                        value: "M",
                        name: "Laki-laki",
                    },
                    {
                        value: "F",
                        name: "Perempuan",
                    }
                ]
            },
            control: this.state.Form.controls.jenis_kelamin
        })

        this.TextHeight = this.TextInputFormView({
            label: "Tinggi Badan",
            placeholder: "Isi tinggi badan pasien (cm)",
            name: "tinggi_badan",
            inputType: "number",
            control: this.state.Form.controls.tinggi_badan
        })

        this.TextWeight = this.TextInputFormView({
            label: "Berat Badan",
            placeholder: "Isi berat badan pasien (kg)",
            name: "berat_badan",
            inputType: "number",
            control: this.state.Form.controls.berat_badan
        })

        this.DropDownBloodType = this.DropDownFormView({
            meta: {
                label: "Golongan Darah",
                placeholder: "Pilih golongan darah",
            },
            name: "gol_darah",
            control: this.state.Form.controls.gol_darah,
            stateName: "bloodTypeOpt",
        })

        // Start Address Form

        this.TextAddress = this.TextAreaFormView({
            label: "Alamat",
            placeholder: "Isi alamat pasien",
            name: "alamat",
            control: this.state.Form.controls.alamat
        })

        this.DropDownPropinsi = this.DropDownAreaFormView({
            meta: {
                label: "Propinsi",
                placeholder: "Pilih propinsi",
                callBack: this.getDropdownKota,
                EditshowOnlyText: false
            },
            name: "propinsi_id",
            nameText: "propinsi",
            control: this.state.Form.controls.propinsi_id,
            stateName: 'propinsiOpt',
        })

        this.DropDownKota = this.DropDownAreaFormView({
            meta: {
                label: "Kota",
                placeholder: "Pilih kota",
                callBack: this.getDropdownKecamatan,
            },
            name: "kota_id",
            nameText: "kota",
            control: this.state.Form.controls.kota_id,
            stateName: 'kotaOpt',
        })

        this.DropDownKecamatan = this.DropDownAreaFormView({
            meta: {
                label: "Kecamatan",
                placeholder: "Pilih kecamatan",
                callBack: this.getDropdownKelurahan,
            },
            name: "kecamatan_id",
            nameText: "kecamatan",
            control: this.state.Form.controls.kecamatan_id,
            stateName: 'kecamatanOpt',
        })

        this.DropDownKelurahan = this.DropDownAreaFormView({
            meta: {
                label: "Kelurahan",
                placeholder: "Pilih kelurahan",
                callBack: this.setKodePos,
            },
            name: "kelurahan_id",
            nameText: "kelurahan",
            control: this.state.Form.controls.kelurahan_id,
            stateName: 'kelurahanOpt',
        })

        // End Address Form

        this.DropDownTypeId = this.DropDownFormView({
            meta: {
                label: "Jenis Identitas",
                placeholder: "Pilih jenis identitas",
            },
            name: "tipe_identitas",
            control: this.state.Form.controls.tipe_identitas,
            stateName: 'idTypeOpt',
        })

        this.TextIdNo = this.TextInputFormView({
            label: "Nomor Identitas",
            placeholder: "Isi nomor identitas",
            maxLength: 16,
            name: "no_identitas",
            control: this.state.Form.controls.no_identitas
        })

        this.TextCondPatient = this.TextAreaFormView({
            label: "Kondisi Pasien",
            placeholder: "Isi kondisi pasien",
            name: "kondisi_pasien",
            control: this.state.Form.controls.kondisi_pasien
        })

        this.DatePickDob = this.DatePickerFormView({
            meta: {
                label: "Tanggal Lahir Pasien",
                validator: "required",
                maxDate: new Date()
            },
            name: "tanggal_lahir",
            control: this.state.Form.controls.tanggal_lahir,
            stateName: 'tanggal_lahir',
        })

        this.TextPICName = this.TextInputFormView({
            label: "Nama Penanggung Jawab",
            placeholder: "Isi nama penanggung jawab",
            name: "nama_png_jawab",
            control: this.state.Form.controls.nama_png_jawab
        })

        this.TextPICPhone = this.TextInputFormView({
            label: "Telepon",
            placeholder: "Isi nomor telepon",
            name: "telepon_png_jawab",
            inputType: "tel",
            maxLength: 15,
            control: this.state.Form.controls.telepon_png_jawab
        })

        this.TextPICEmail = this.TextInputEmailFormView({
            label: "Email",
            placeholder: "Isi email",
            name: "email_png_jawab",                                                  
            control: this.state.Form.controls.email_png_jawab,
            inputType: "email"
        })

        this.RadioPICRelationship = this.RadioInputFormView({
            name: "relasi_png_jawab",            
            meta: {
                label: "Hubungan dengan Pasien",
                options: [
                    {
                        value: "Orang Tua",
                        name: "Orang Tua",
                    },
                    {
                        value: "Suami",
                        name: "Suami",
                    },
                    {
                        value: "Istri",
                        name: "Istri",
                    },
                    {
                        value: "Anak",
                        name: "Anak",
                    },
                    {
                        value: "Saudara",
                        name: "Saudara",
                    },
                    {
                        value: "Lainnya",
                        name: "Lainnya",
                    },
                ]
            },
            control: this.state.Form.controls.relasi_png_jawab
        })
    },

    redirectToBase: function(e) {
        const me = this
        if(e){
            handleToastServiceError(e)
            setTimeout(() => {
                me.props.history.push('/')
            }, 3000)
        } else {
            me.props.history.push('/')
        }
    },

    getDropdownPropinsi() {
        let me = this
        if(!this.props.previewMode){
            let requestApi = ApiArea.provinsi()
            requestApi.then(response => {
                me.setState({
                    propinsiOpt: response.data.data,
                })
            }).catch( error => {
                console.log(error)
            })
        }
    },
    getDropdownKota(me, name){
        const formMustBeCleared = [
            'kota_id', 'kecamatan_id', 'kelurahan_id'
        ]
        const optionMustBeCleared = [
            'kotaOpt', 'kecamatanOpt', 'kelurahanOpt'
        ]

        me.resetDependentSelect(me, formMustBeCleared, optionMustBeCleared)
        const idPropinsi = me.state.Form.value[name].value
        const requestApi = ApiArea.kota(idPropinsi)

        requestApi.then(response => {
            me.setState({
                kotaOpt: response.data.data,
                editChanged: true
            })
        }).catch(error => {
            console.log(error)
        })
    },
    getDropdownKecamatan(me, name){
        const formMustBeCleared = [
            'kecamatan_id', 'kelurahan_id'
        ]
        const optionMustBeCleared = [
            'kecamatanOpt', 'kelurahanOpt'
        ]
        me.resetDependentSelect(me, formMustBeCleared, optionMustBeCleared)

        const idKota = me.state.Form.value[name].value
        const requestApi = ApiArea.kecamatan(idKota)
        requestApi.then(response => {
            me.setState({
                kecamatanOpt: response.data.data
            })
        }).catch(error => {
            console.log(error)
        })
    },
    getDropdownKelurahan(me, name){
        const formMustBeCleared = [
            'kelurahan_id'
        ]
        const optionMustBeCleared = [
            'kelurahanOpt'
        ]
        me.resetDependentSelect(me, formMustBeCleared, optionMustBeCleared)

        const idKecamatan = me.state.Form.value[name].value
        const requestApi = ApiArea.kelurahan(idKecamatan)

        requestApi.then(response => {
            me.setState({
                kelurahanOpt: response.data.data
            })
        }).catch(error => {
            console.log(error)
        })
    },
    resetDependentSelect: function(me, mustBeCleared, optBeCleared){
        mustBeCleared.map((value) => {
            if(me.state.Form.controls[value]){
                me.state.Form.controls[value].reset()
            }
            return false
        })

        optBeCleared.map((value) => {
            me.setState({
                [value]: []
            })
            return true
        })
    },
    setKodePos: async function (me, name) {
        const idKelurahan = me.state.Form.value[name].value
        const findKodepos = me.state.kelurahanOpt.find((data) => data.value === idKelurahan);
        const kodepos = findKodepos.kodepos || ''
        me.state.Form.controls.kodepos.setValue(kodepos)
    },
    valueBuilder: async function () {
        this.valueObject = {
            id: "",
            nama_depan: "",
            nama_belakang: "",
            tempat_lahir: "",
            email: "",
            telepon: "",
            tipe_identitas: "",
            no_identitas: "",
            tanggal_lahir: "",
            tinggi_badan: "",
            berat_badan: "",
            gol_darah: "",
            kondisi_pasien: "",
            alamat: "",
            jenis_kelamin: "",
            propinsi: "",
            kota: "",
            kecamatan: "",
            kelurahan: "",
            propinsi_id: "",
            kota_id: "",
            kecamatan_id: "",
            kelurahan_id: "",            
            kodepos: "",
            nama_png_jawab: "",
            email_png_jawab: "",
            telepon_png_jawab: "",
            relasi_png_jawab: ""
        }

        await this.getDropdownPropinsi()

        if (this.props.location.state !== undefined) {
            let dataProvider = this.props.location.state.dataProvider

            this.fillForm(dataProvider)
            
        } else if (this.props.history.location.pathname.includes(Config.EditUrl)) {
            let me = this
            Api.view(this.props.match.params.id).then((response) => {
                me.fillForm(response.data.data)
            }, (e) => {
                handleToastServiceError(e)
                setTimeout(function(){ me.props.history.push(Config.GridUrl); }, 3000);
            })
        }

    },


    fillForm: function(dataProvider) {
        let me = this
        Object.keys(dataProvider).map(function (key) {
            if (me.valueObject.hasOwnProperty(key)) {
                if(typeof me.valueObject[key] === 'boolean') {
                    me.valueObject[key] = !!dataProvider[key]
                } else if(key === 'tanggal_lahir') {
                    me.valueObject[key] = new Date(dataProvider[key])
                } else if(key === 'propinsi_id'){
                    if(dataProvider['propinsi_id']){
                        me.valueObject[key] = {
                            label: dataProvider['propinsi'],
                            value: dataProvider['propinsi_id']
                        }
                    }
                } else if(key === 'kota_id'){
                    if(dataProvider['kota_id']){
                        me.valueObject[key] = {
                            label: dataProvider['kota'],
                            value: dataProvider['kota_id']
                        }
                    }
                } else if(key === 'kecamatan_id'){
                    if(dataProvider['kecamatan_id']){
                        me.valueObject[key] = {
                            label: dataProvider['kecamatan'],
                            value: dataProvider['kecamatan_id']
                        }
                    }
                } else if(key === 'kelurahan_id'){
                    if(dataProvider['kelurahan_id']){
                        me.valueObject[key] = {
                            label: dataProvider['kelurahan'],
                            value: dataProvider['kelurahan_id']
                        }
                    }
                } else {
                    me.valueObject[key] = dataProvider[key] ? dataProvider[key].toString() : ''
                }
            }
            return true
        })

        this.state.Form.patchValue(me.valueObject)
        const customFieldValue = dataProvider.custom_fields
        me.setState({
            isEdit: true,
            filledForm: me.valueObject,
            customFieldValue: customFieldValue
        })
        me.fillCustomField(customFieldValue)
    },
    getDefaultValueCustomBuilder(key, obj){
        switch(key){
            case 'radio' : 
                return obj.option[0].value
            case 'checkbox' :
                return []
            case 'dropdown' :
                return []
            default :
                return ""
        }
    },
    getDefaultValidatorCustomBuilder(obj){
        let validator = []
        if(!obj.fieldOptional){
            validator.push(Validators.required)
        }

        if(obj.fieldSubType === 'email'){
            validator.push(Validators.email)
        }

        return validator
    },

    loadCustomField() {
        const hospitalId = this.state.hospitalId

        HospitalApi.view(hospitalId).then(response => {
            if (response.data.code === 200) {
                this.valueBuilder()
                if (response.data.data) {
                    const customField = JSON.parse(response.data.data.custom_patient_fields)
                    if (customField && customField.formBuilder) {
                        this.setState({
                            customField: customField.formBuilder,
                            customFieldTitle: customField.formTitle
                        })

                        let fieldInject = []
                        customField.formBuilder.map(obj => (
                            fieldInject[obj.fieldLabel] = new FormControl(
                                this.getDefaultValueCustomBuilder(obj.fieldKey, obj),
                                this.getDefaultValidatorCustomBuilder(obj)
                            )
                        ))

                        const fieldArray = this.state.Form.get("custom_fields")
                        const form = new FormGroup(fieldInject)
                        fieldArray.push(form)
                    }
                }
            }
        }).catch(() => {
            handleToastServiceError({ message: 'Something went wrong' })
        })
    },
    fillCustomField(obj){
        const CustomForm = this.state.Form.get("custom_fields").controls[0]
        for (var key in obj) {
            const value = obj[key]
            CustomForm.controls[key] && CustomForm.controls[key].patchValue(value)
        }
    },
    dateToString(Obj){
        const dateFull = new Date(Obj)
        if(dateFull){
            const mon = dateFull.getMonth() + 1
            const date = dateFull.getDate()
            return dateFull.getFullYear() + '-' + (mon > 9 ? '' : '0') + mon  + '-' + (date > 9 ? '' : '0') + date
        }
        return null
    },
    handleSubmit: async function (e) {
        if(this.props.previewMode){
            return
        }
        e.preventDefault()

        // Custom assign form data
        let formValue = Object.assign({}, this.state.Form.value)
        formValue.nama_depan = formValue.nama_depan ? formValue.nama_depan.trim() : ''
        formValue.nama_belakang = formValue.nama_belakang ? formValue.nama_belakang.trim() : ''
        formValue['hospital_id'] = this.props.currentHospital
        formValue['tinggi_badan'] = formValue['tinggi_badan']
            ? parseInt(formValue['tinggi_badan'], 10) : 0
        formValue['berat_badan'] = formValue['berat_badan']
            ? parseInt(formValue['berat_badan'], 10) : 0
        formValue['tanggal_lahir'] = this.dateToString(formValue['tanggal_lahir'])

        if(formValue['tipe_identitas'] instanceof Object === true){
            formValue['tipe_identitas'] = formValue['tipe_identitas'].value
        }

        if(formValue['gol_darah'] instanceof Object === true){
            formValue['gol_darah'] = formValue['gol_darah'].value
        }

        if(formValue['propinsi_id'] instanceof Object === true){
            formValue['propinsi'] = formValue['propinsi_id'].label
            formValue['propinsi_id'] = formValue['propinsi_id'].value
        } else {
            formValue['propinsi_id'] = formValue['propinsi_id'] || 0
        }

        if(formValue['kota_id'] instanceof Object === true){
            formValue['kota'] = formValue['kota_id'].label
            formValue['kota_id'] = formValue['kota_id'].value
        } else {
            formValue['kota_id'] = formValue['kota_id'] || 0
        }

        if(formValue['kelurahan_id'] instanceof Object === true){
            formValue['kelurahan'] = formValue['kelurahan_id'].label
            formValue['kelurahan_id'] = formValue['kelurahan_id'].value
        } else {
            formValue['kelurahan_id'] = formValue['kelurahan_id'] || 0
        }

        if(formValue['kecamatan_id'] instanceof Object === true){
            formValue['kecamatan'] = formValue['kecamatan_id'].label
            formValue['kecamatan_id'] = formValue['kecamatan_id'].value
        } else {
            formValue['kecamatan_id'] = formValue['kecamatan_id'] || 0
        }

        formValue['kodepos'] = formValue['kodepos']
            ? parseInt(formValue['kodepos'], 10) : 0
        formValue['status'] = 1

        // custom field parse
        let customFields = formValue['custom_fields'][0]
        if(customFields){
            for (var key in customFields) {
                if(customFields[key].value && customFields[key].label){
                    // replace object select data to value
                    customFields[key] = customFields[key].value
                }else if(customFields[key] instanceof Date){
                    // replace object date to string
                    customFields[key] = this.dateToString(customFields[key])
                }
            }
        }
        formValue['custom_fields'] = customFields

        let id = formValue['id']
        let me = this
        let response

        if (this.state.isEdit) {
            try {
                delete formValue['hospital_id']
                response = await Api.update(id, JSON.stringify(formValue))
            } catch (e) {
                handleToastServiceError(e)
                return
            }

            me.setState({postStatus: true})
            if (response.data.code !== 200) {
                handleToast(response.data.message)
                return
            }

            handleToast(response.data.message)

            return
        }

        try {
            response = await Api.post(JSON.stringify(formValue))
        } catch (e) {
            handleToastServiceError(e)
            return
        }

        me.setState({postStatus: true})
        if (response.data.code !== 200) {
            handleToast(response.data.message)
            return
        }

        handleToast(response.data.message)
    },
}

export default FormHandler