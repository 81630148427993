import React, { Component } from 'react'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faTrash} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select';
import {handleToast} from '../../../core/utils/UtilsToast'

library.add(faTrash)

export default class Bottom extends Component{
    constructor(props){
        super(props)
        this.state = {
            polyclinic: null,
            role:  null
        }
    }

    buttonDeleteAccess(){
        return(
            <button type="button" className="btn btn-del-access" onClick={(e) => this.handleDelete(e)}>
                <FontAwesomeIcon icon={faTrash}/>                      
                Hapus Akses
            </button>
        )
    }

    handlePolyChange = (polyclinic) => {
        let role = this.state.role
        const roleOpt = this.props.role
        const roleVal = this.props.value.role
        if(role === null){
            role = roleOpt.find(roleOpt => roleOpt.value === roleVal)
        }
        this.setState({ 
            polyclinic: polyclinic,
            role: role
        });
    }

    handleRoleChange = (role) => {
        let polyclinic = this.state.polyclinic
        const polyOpt = this.props.polyclinic
        const polyVal = this.props.value.polyclinic

        if(polyclinic === null){
            polyclinic = polyOpt.find(polyOpt => polyOpt.value === polyVal)
        }
        this.setState({ 
            polyclinic: polyclinic,
            role: role
         });
    }

    renderPoly(theParams){
        const polyclinic = this.props.polyclinic
        const statePoly = this.state.polyclinic
        let theValue = polyclinic.find(polyclinic => polyclinic.value === theParams.polyclinic)
        
        if(statePoly !== null)
            theValue = statePoly

        return (
            <Select
                className="dropdown-wizard"
                classNamePrefix="react-select"
                options={polyclinic}
                placeholder="Pilih Poli atau Unit"
                onChange={this.handlePolyChange}
                value={(theValue === undefined) ? null : theValue}
            />
        )    
    }

    renderRole(theParams){
        const role = this.props.role
        const stateRole = this.state.role
        let theValue = role.find(role => role.value === theParams.role)

        if(stateRole !== null)
            theValue = stateRole
        
        return (
            <Select
                className="dropdown-wizard"
                classNamePrefix="react-select"
                options={role}
                placeholder={ "Pilih posisi " }
                onChange={this.handleRoleChange}
                value={(theValue === undefined) ? null : theValue}
            />
        )    
    }

    handleSubmit(event){
        event.preventDefault()
        const { polyclinic, role } = this.state
        if((polyclinic === null) || (role === null))
            return

        const index = this.props.idx
        const canSave = polyclinic.hasOwnProperty('label') && role.hasOwnProperty('label')
        if(canSave){
            this.props.onChange(polyclinic, role, index)
            handleToast('Access saved successfully')
        }
            
    }

    handleDelete(event){
        event.preventDefault()

        if(this.canDeleteParent()){
            const index = this.props.idx
            this.props.onDelete(index)
        }else{
            this.setState({
                polyclinic: null,
                role: null,
            })
        }
    }

    canDeleteParent(){
        if((this.state.polyclinic === null) && (this.props.value.polyclinic > 0)){
            return true
        }else{
            return false
        }
    }

    handleClose(event){
        event.preventDefault()
        const index = this.props.idx
        this.props.onClose(index)
    }

    render() {
        const accessValue = this.props.value
        return (
            <div className="access-container">
                {this.renderPoly(accessValue)}
                <button 
                    type="button" 
                    className="btn btn-access-cancel btn-secondary" 
                    onClick={(e) => this.handleClose(e)}>
                        <span>Batal</span>
                </button>
                <button 
                    type="button" 
                    className="btn btn-access-save btn-primary" 
                    onClick={(e) => this.handleSubmit(e)}>
                        <span>Simpan</span>
                </button>
                {this.buttonDeleteAccess()}
            </div>
        )
    }
}