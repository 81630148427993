import React from 'react'
import { connect } from 'react-redux'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../core/const/LoginConst'
import { Redirect } from 'react-router-dom' 
import SAconfig from './config/superadmin'
import MasterTemplate from '../../template/MasterTemplate'
import MasterModal from '../../core/utils/MasterModal'
import HospitalServicesApi from '../../services/apis/Hospital/HospitalServices'
import { StickyContainer } from 'react-sticky'

import HospitalForm from '../infoHospital/components/Form'
import StickyNav from '../infoHospital/components/sectionscroll/StickyNav'

class VerifyClinic extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            breadcrumbData: [],
            isModalOpen: false,
            ModalData: null,
            title: null,
            isSticky: false,
            institutionList: []
        }
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.modalHandleSubmit = this.modalHandleSubmit.bind(this)
        this.setTitle = this.setTitle.bind(this)
        this.pushRedirect = this.pushRedirect.bind(this)
    }

    setTitle(title){
        this.setState({
            title: title 
        })
    }

    handleOpenModal() {
        const ModalData = {
            action: "exit_appointment",
            id: null,
            content: "Anda akan keluar dari halaman Tambah Antrian, apakah anda yakin?",
            buttonConfirmText: "Ya, Keluar",
            buttonCancelText: "Tidak",
            ConfirmFunction: null
        }
        this.setState({
            isModalOpen: true,
            ModalData: ModalData
        })
    }

    handleCloseModal(){
        this.setState({
            isModalOpen: false,
            ModalData: null
        })
    }

    modalHandleSubmit(){
        let me = this
        const ModalData = me.state.ModalData

        if(ModalData.action === 'exit_appointment'){
            this.setState({
                redirectTo: '/'
            })
        }
    }

    pushRedirect(path){
        this.setState({
            redirectTo: path
        })
    }

    setSticky = (isSticky) => {
        if (this.state.isSticky !== isSticky) {
            this.setState({ isSticky })
        }
    }

    componentDidMount(){
        HospitalServicesApi.listInstitution().then((res) => {
            if (res.status === 200) {
                const { data: institutionList } = res.data

                this.setState({
                    institutionList: institutionList
                })
            }
        }).catch(error => {
            console.log('Something went wrong:', error)
            return
        })
    }

    render() {
        const verifyClinicId = this.props.match.params.id
        if(this.state.redirectTo){
            return <Redirect to={ this.state.redirectTo } />
        }
        let classString = ''
        if(!this.state.title){
            classString = 'hide'
        }
        return(
            <MasterTemplate
                {...this.props}
                wizard={ true }
                onBeforeClose={ this.handleOpenModal }
                cancelUrl={ SAconfig.cancelUrl }
                backUrl={ SAconfig.backUrl }
                breadcrumbData={ this.state.breadcrumbData }
                wizardShowCloseButton={ false } 
            >
                <div className="wrap-verify-clinic">
                    {
                        classString === 'hide' ?
                        <span>Loading...</span> :
                        null
                    }
                    <StickyContainer
                        className={`hospital-info-container verify-container ${classString}`}>
                        <h3 className="title-clinic">{this.state.title}</h3>
                        <StickyNav setSticky={this.setSticky} accessBy="verify" />
                        {
                            this.state.institutionList?.length > 0 &&
                            <HospitalForm
                                verifyClinicId={verifyClinicId}
                                setTitle={this.setTitle}
                                onCancel={() => this.pushRedirect('/')}
                                submitLabel={'Konfirmasi'}
                                isSticky={this.state.isSticky}
                                institutionList={this.state.institutionList}
                                {...this.props}
                            />
                        }
                    </StickyContainer>
                </div>
                <MasterModal
                    className="master-modal"
                    isOpen={this.state.isModalOpen}
                    handleClose={this.handleCloseModal}
                    handleSubmit={this.modalHandleSubmit}
                    ModalData={this.state.ModalData}
                />
            </MasterTemplate>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(VerifyClinic)