import { isEmpty } from 'lodash'
import dateFns from 'date-fns'
import Config from '../config/Config'
import Api from '../../../services/apis/Hospital/List'
import ApiArea from '../../../services/apis/AreaApi'
import BankApi from '../../../services/apis/BankApi'
import FacilityApi from '../../../services/apis/FacilityApi'
import { 
    handleToast, 
    handleToastServiceError
} from '../../../core/utils/UtilsToast'

const FormHandler = {
    onPanelClose(){
        this.setState({
            isPaneOpen: false
        })

        setTimeout(() => {
            this.setState({
                schPractice: false
            })
        }, 500);
    },
    onPanelOpen(state){
        this.setState({
            isPaneOpen: true,
            schPractice: state === 'sch-practice' ? true : false
        })
    },
    onPanelCloseTwo(){
        this.setState({
            isPaneOpenTwo: false,
            schPractice: true
        })
    },
    onPanelOpenTwo(){
        this.setState({
            isPaneOpenTwo: true
        })
    },
    fetchFacilities: async function () {
        await FacilityApi.get().then((res) => {
            if (res.status === 200) {
                const { data } = res.data
                const facilitiesOpt = data.map((item) => {
                    return {
                        value: item.id,
                        name: item.name,
                    }
                })

                this.setState({
                    facilitiesOpt
                })
            }
        }).catch(error => {
            console.log('Something went wrong:', error)
        })
    },
    buildForm(){
        this.DropDownPropinsi = this.DropDownAreaFormView({
            meta: {
                label: "Propinsi",
                placeholder: "Pilih propinsi",
                callBack: this.getDropdownKota,
                EditshowOnlyText: false
            },
            name: "propinsi_id",
            nameText: "propinsi",
            control: this.state.Form.controls.propinsi_id,
            stateName: 'propinsiOpt',
        })

        this.DropDownKota = this.DropDownAreaFormView({
            meta: {
                label: "Kota",
                placeholder: "Pilih kota",
                callBack: this.getDropdownKecamatan,
            },
            name: "kota_id",
            nameText: "kota",
            control: this.state.Form.controls.kota_id,
            stateName: 'kotaOpt',
        })

        this.DropDownKecamatan = this.DropDownAreaFormView({
            meta: {
                label: "Kecamatan",
                placeholder: "Pilih kecamatan",
                callBack: this.getDropdownKelurahan,
            },
            name: "kecamatan_id",
            nameText: "kecamatan",
            control: this.state.Form.controls.kecamatan_id,
            stateName: 'kecamatanOpt',
        })

        this.DropDownKelurahan = this.DropDownAreaFormView({
            meta: {
                label: "Kelurahan",
                placeholder: "Pilih kelurahan",
                callBack: this.setKodePos,
            },
            name: "kelurahan_id",
            nameText: "kelurahan",
            control: this.state.Form.controls.kelurahan_id,
            stateName: 'kelurahanOpt',
        })
        this.DropDownInstitutionType = this.DropDownView({
            meta: {
                placeholder: "Pilih Jenis Institusi",
                validator: "required",
                callBack: this.handleInstitutionChange,
                options: Config.InstitutionOption,
                disabled: this.props.verifyClinicId ? true : false
            },
            name: "insitution_type",
            control: this.state.Form.controls.institution_type,
            stateName: "institutionList"
        })
        this.DropDownClinicType = this.DropDownView({
            meta: {
                placeholder: "Pilih Jenis Klinik",
                validator: "required",
                label: "Pilih Jenis Klinik",
            },
            name: "clinic_type",
            control: this.state.Form.controls.clinic_type,
            stateName: "clinicType",
            placeholder: "Pilih Jenis Klinik",
        })
        this.DropDownHospitalType = this.DropDownView({
            meta: {
                placeholder: "Pilih Jenis Rumah Sakit",
                validator: "required",
                label: "Pilih Jenis Rumah Sakit",
            },
            name: "hospital_type",
            control: this.state.Form.controls.hospital_type,
            stateName: "hospitalType",
            placeholder: "Pilih Jenis Rumah Sakit",
        })
        this.DropDownInstitutionCategory = this.DropDownView({
            meta: {
                placeholder: "Pilih Tipe Institusi Kesehatan",
                validator: "required",
                options: Config.CategoryOption,
            },
            name: "gov_type",
            control: this.state.Form.controls.gov_type,
            stateName: "categoryList",
        })

        this.textInstitutionName = this.TextInputFormView({
            validator: "required",
            name: "name",
            placeholder: "Nama Institusi",
            control: this.state.Form.controls.name
        })

        this.textInstitutionCode = this.TextInputFormView({
            validator: "required",
            name: "code",
            placeholder: "Kode Institusi",
            maxLength: 10,
            control: this.state.Form.controls.code
        })
        
        this.textSIO = this.TextInputFormView({
            label: "SIO NO",
            validator: "required",
            name: "sio_no",
            placeholder: "SIO NO",
            control: this.state.Form.controls.sio_no,
        })

        this.sioNoExpiredField = this.DatePickerFormView({
            meta: {
                label: "SIO No Expired",
                readonly: false,
                className: 'hos-doctor',
                validator: "required",
                minDate: new Date(),
            },
            name: "sio_no_expired",
            control: this.state.Form.controls.sio_no_expired
        })

        this.TextDesc = this.RichEditorInputFormView({
            label: "Deskripsi Faskes",
            name: "description",
            placeholder: "Deskripsi Faskes",
            className: "faskes-desc",
            control: this.state.Form.controls.description
        })

        
        this.npwpNameField = this.TextInputFormView({
            label: "Nama Pemilik NPWP",
            validator: "required",
            name: "npwp_name",
            placeholder: "Nama Pemilik NPWP",
            control: this.state.Form.controls.npwp_name,
            readonly: this.isSuperadmin ? false : true,
        })

        this.rekeningNameField = this.TextInputFormView({
            label: "Nama Pemilik Rekening",
            validator: "required",
            name: "rekening_name",
            placeholder: "Nama Pemilik Rekening",
            control: this.state.Form.controls.rekening_name,
            readonly: this.isSuperadmin ? false : true,
        })

        this.bankField = this.DropDownView({
            meta: {
                label: "Rekening Bank",
                EditshowOnlyText: false,
                placeholder: "Rekening Bank",
                validator: "required",
                readonly: this.isSuperadmin ? false : true,
            },
            name: "rekening_bank",
            stateName: "bankOption",
            control: this.state.Form.controls.rekening_bank,
        })

        this.noRekField = this.TextInputFormView({
            label: "Nomor Rekening Bank",
            validator: "required",
            inputType: "tel",
            name: "rekening_no",
            placeholder: "Nomor Rekening Bank",
            maxLength: 20,
            control: this.state.Form.controls.rekening_no,
            readonly: this.isSuperadmin ? false : true,
        })

        this.textPhone = this.TextInputFormView({
            label: "Telepon",
            validator: "required",
            name: "phone",
            inputType: "tel",
            maxLength: 15,
            placeholder: "Nomor Telepon",
            control: this.state.Form.controls.phone
        })

        this.TextStreet = this.TextAreaFormView({
            label: "Alamat",
            name: "street",
            placeholder: "Alamat Lengkap",
            control: this.state.Form.controls.street
        })

        this.PicName = this.TextInputFormView({
            name: "pic_name",
            placeholder: "Nama Penanggung Jawab",
            readonly: this.isSuperadmin ? false : true,
            control: this.state.Form.controls.pic_name
        })

        this.PicEmail = this.TextInputEmailFormView({
            name: "pic_email",
            placeholder: "Email Penanggung Jawab",
            readonly: this.isSuperadmin ? false : true,
            control: this.state.Form.controls.pic_email
        })

        this.PicPhone = this.TextInputFormView({
            label: "Telepon",
            name: "pic_phone",
            placeholder: "Telepon Penanggung Jawab",
            inputType: "tel",
            maxLength: 15,
            control: this.state.Form.controls.pic_phone
        })

        this.CheckBoxDay = this.CheckBoxFormView({
            name: "open_days",     
            meta: {
                validator: "",
                options: Config.listDay
            },
            control: this.state.Form.controls.open_days
        })

        this.DropDownOpenTime = this.DropDownView({
            meta: {
                validator: "",
                callBack: this.getEndTime,
            },
            name: "open_time",
            nameText: "open_time",
            control: this.state.Form.controls.open_time,
            stateName: 'timeList',
        })

        this.DropDownCloseTime = this.DropDownView({
            meta: {
                validator: ""
            },
            name: "close_time",
            nameText: "close_time",
            control: this.state.Form.controls.close_time,
            stateName: 'timeListEnd',
        })

        this.RadioIGD = this.RadioInputFormView({
            name: "igd",            
            meta: {
                label: "Ketersediaan IGD",
                validator: "",
                options: Config.igdMenu
            },
            control: this.state.Form.controls.igd
        })

        this.SwitchStatus = this.SwitchFormView({
            meta: {
                label: "Dapat diakses pasien dari aplikasi KlikDokter?",
            },
            name: "status",
            placeholder: "Dapat diakses pasien dari aplikasi KlikDokter?",
            control: this.state.Form.controls.status
        })

        this.SwitchAllowBooking = this.SwitchFormView({
            meta: {
                label: "Diizinkan untuk booking?",
            },
            name: "allow_booking",
            placeholder: "Diizinkan untuk booking?",
            control: this.state.Form.controls.allow_booking
        })

        this.SwitchAllowSelSch = this.SwitchFormView({
            meta: {
                label: "Izinkan pasien memilih jam kedatangan",
            },
            name: "is_custom_schedule",
            placeholder: "Izinkan pasien memilih jam kedatangan",
            control: this.state.Form.controls.is_custom_schedule
        })

        this.textLat = this.TextInputFormView({
            name: "lat",
            label: "Latitude",
            placeholder: "Latitude",
            control: this.state.Form.controls.lat
        })

        this.textLng = this.TextInputFormView({
            name: "lng",
            label: "Longitude",
            placeholder: "Longitude",
            control: this.state.Form.controls.lng
        })
    },

    getDropdownPropinsi() {
        let me = this
        if(!this.props.previewMode){
            let requestApi = ApiArea.provinsi()
            requestApi.then(response => {
                me.setState({
                    propinsiOpt: response.data.data,
                })
            }).catch( error => {
                console.log(error)
            })
        }
    },
    getDropdownKota(me, name){
        const formMustBeCleared = [
            'kota_id', 'kecamatan_id', 'kelurahan_id'
        ]
        const optionMustBeCleared = [
            'kotaOpt', 'kecamatanOpt', 'kelurahanOpt'
        ]

        me.resetDependentSelect(me, formMustBeCleared, optionMustBeCleared)
        const idPropinsi = me.state.Form.value[name].value
        const requestApi = ApiArea.kota(idPropinsi)
        
        requestApi.then(response => {
            me.setState({
                kotaOpt: response.data.data,
                editChanged: true
            })
        }).catch(error => {
            console.log(error)
        })
    },
    getDropdownKecamatan(me, name){
        const formMustBeCleared = [
            'kecamatan_id', 'kelurahan_id'
        ]
        const optionMustBeCleared = [
            'kecamatanOpt', 'kelurahanOpt'
        ]
        me.resetDependentSelect(me, formMustBeCleared, optionMustBeCleared)
        const idKota = me.state.Form.value[name].value
        const requestApi = ApiArea.kecamatan(idKota)
        requestApi.then(response => {
            me.setState({
                kecamatanOpt: response.data.data
            })
        }).catch(error => {
            console.log(error)
        })
    },
    getDropdownKelurahan(me, name){
        const formMustBeCleared = [
            'kelurahan_id'
        ]
        const optionMustBeCleared = [
            'kelurahanOpt'
        ]
        me.resetDependentSelect(me, formMustBeCleared, optionMustBeCleared)

        const idKecamatan = me.state.Form.value[name].value
        const requestApi = ApiArea.kelurahan(idKecamatan)

        requestApi.then(response => {
            me.setState({
                kelurahanOpt: response.data.data
            })
        }).catch(error => {
            console.log(error)
        })
    },
    resetDependentSelect: function(me, mustBeCleared, optBeCleared){
        mustBeCleared.map((value) => {
            if(me.state.Form.controls[value]){
                me.state.Form.controls[value].reset()
            }
            return false
        })

        optBeCleared.map((value) => {
            me.setState({
                [value]: []
            })
            return true
        })
    },

    preloadHospital: async function(){
        this.valueObject = {
            id: "",
            institution_type: "",
            kota: "",
            propinsi: "",
            kecamatan: "",
            kelurahan:"",
            propinsi_id:"",
            kota_id:"",
            kecamatan_id:"",
            kelurahan_id:"",
            code: "",
            sio_no: "",
            sio_no_expired:"",
            id_type: "",
            status: "",
            allow_booking: "0",
            is_custom_schedule: false,
            gov_type: "",
            name: "",
            logo: "",
            photo: "",
            description: "",
            phone: "",
            street: "",
            dob: "",
            jam_besuk: "",
            open_time: "",
            close_time: "",
            open_days: "",
            facilities: "",
            pic_name: "",
            pic_email: "",
            pic_phone: "",
            lat: "",
            lng: "",
        }
        await this.getDropdownPropinsi()

        const fetchHospital = Api.detail
        let hospitalId = this.currentHospital
        if(this.props.verifyClinicId){
            hospitalId = this.props.verifyClinicId
        }
        fetchHospital(hospitalId).then(res => {
            const data = res.data.data
            data.city.shift()
            this.picEmail = data.pic_email
            this.buildOptions(data.id_type_options, 1)
            this.buildOptions(data.id_type_options, 2)
            this.setState({
                cityList: data.city,
                certificate: data.certificate,
                verified: data.verified,
                reactivate_user: data.reactivate_user,
            })
            this.fetchFacilities()
            if(this.props.setTitle){
                this.props.setTitle(data.name)
            }
            this.fillForm(data)
        }).catch(() => {
            handleToast('Something went wrong')
        })
    },

    fillForm(dataProvider) {
        let me = this
        let facilities = []

        Object.keys(dataProvider).map((key) => {
            if (me.valueObject.hasOwnProperty(key)) {
                me.valueObject[key] = dataProvider[key] ? dataProvider[key].toString() : ''
                if(key === 'institution_type'){
                    const type = me.state.institutionList.find(option => String(option.value) === String(me.valueObject[key]))
                    me.valueObject[key] = type.label
                    this.setState({
                        institutionLabel: type.label,
                        institution: type,
                    })
                }else if(key === 'jam_besuk'){
                    let open_time = ''
                    let close_time = ''
                    if(me.valueObject['jam_besuk']){
                        const arrTime = me.valueObject['jam_besuk'].split('-')
                        open_time = arrTime[0].replace(/\s/g, '')
                        close_time = arrTime[1].replace(/\s/g, '')
                    }
                    me.valueObject['open_time'] = open_time
                    me.valueObject['close_time'] = close_time
                }else if(key === 'facilities'){
                    dataProvider.facilities.map((res) => {
                        facilities.push(res.id)
                        if(res.name.toLowerCase().includes('igd')){
                            me.valueObject['igd'] = "1"
                        }
                        return true
                    })

                    me.valueObject[key] = facilities
                }else if(key === 'phone'){
                    me.valueObject[key] = me.valueObject[key].replace(/\D/, '')
                }else if(key === 'open_days'){
                    if(me.valueObject[key]){
                        me.valueObject[key] = JSON.parse(me.valueObject[key])
                    }else{
                        me.valueObject[key] = []
                    }
                }else if(key === 'gov_type'){
                    const govType = Config.CategoryOption.find(option => String(option.value) === String(me.valueObject[key]))
                    me.valueObject[key] = govType.label
                }else if(key === 'id_type'){
                    const { clinicType, hospitalType } = this.state
                    const clinicVal = clinicType.find(option => String(option.value) === String(me.valueObject[key]))
                    const hospitalVal = hospitalType.find(option => String(option.value) === String(me.valueObject[key]))
                    
                    if(clinicVal)
                        me.valueObject['clinic_type'] = clinicVal.label

                    if(hospitalVal)
                        me.valueObject['hospital_type'] = hospitalVal.label
                }else if (key === 'status') {
                    me.valueObject[key] = me.valueObject[key] === '' ? false : true
                }else if (key === 'allow_booking' || key === 'is_custom_schedule') {
                    me.valueObject[key] = me.valueObject[key] !== 'true' ? false : true
                } else if(key === 'propinsi_id'){
                    if(dataProvider['propinsi_id']){
                        me.valueObject[key] = {
                            label: dataProvider['propinsi'],
                            value: dataProvider['propinsi_id']
                        }
                    }
                } else if(key === 'kota_id'){
                    if(dataProvider['kota_id']){
                        me.valueObject[key] = {
                            label: dataProvider['kota'],
                            value: dataProvider['kota_id']
                        }
                    }
                } else if(key === 'kecamatan_id'){
                    if(dataProvider['kecamatan_id']){
                        me.valueObject[key] = {
                            label: dataProvider['kecamatan'],
                            value: dataProvider['kecamatan_id']
                        }
                    }
                } else if(key === 'kelurahan_id'){
                    if(dataProvider['kelurahan_id']){
                        me.valueObject[key] = {
                            label: dataProvider['kelurahan'],
                            value: dataProvider['kelurahan_id']
                        }
                    }
                }
            }
            return true
        })

        this.setState({
            operational: dataProvider.operational,
            FormTemp: Object.assign({}, me.valueObject),
        })

        this.state.Form.patchValue(me.valueObject)
    },
    handleInstitutionChange(){
        const selectedInstitution = this.state.Form.controls.institution_type.value
        this.setState({
            institutionLabel: selectedInstitution.label,
            institution: selectedInstitution,
        })
    },
    setEndTimeList(selectedTimeStart){
        const configVal = Config.listTime
        const indexFromConfig = configVal.find(option => option.label === selectedTimeStart).value
        let EndTimeList = []
        configVal.map((value, index) => {
            if(index > indexFromConfig){
                EndTimeList.push(value)
            }

            return EndTimeList
        })

        this.setState({
            timeListEnd: EndTimeList
        })
    },

    getEndTime(me, name){
        const pickedStartTime = me.state.Form.value[name].value
        const configVal = Config.listTime
        let EndTimeList = []
        configVal.map((value, index) => {
            if(index > pickedStartTime){
                EndTimeList.push(value)
            }

            return EndTimeList
        })

        me.setState({
            timeListEnd: EndTimeList
        })
    },
    loadBank(rekening_bank) {
        const me = this
        const { bankOption } = me.state

        if (bankOption.length === 0) {
            BankApi.list().then(function (response) {
                const {
                    data: {
                        data,
                    },
                } = response

                me.setState({ bankOption: data })
                me.state.Form.patchValue({ rekening_bank })
            })
        }
    },
    setFormData(formJson){
        const form = new FormData()
        for ( const key in formJson ) {
            if(typeof formJson[key] !== 'undefined'){
                form.append(key, formJson[key])
            }
        }
        return form
    },
    handleSubmit() {
        // Custom assign form data
        let formValue = Object.assign({}, this.state.Form.value)

        this.setState({
            submitingForm: true,
            FormTemp:  Object.assign({}, formValue),
        })

        formValue.id_type = formValue.clinic_type.hasOwnProperty('value') ? formValue.clinic_type.value : formValue.hospital_type.value
        formValue.gov_type = formValue.gov_type.value
        formValue.institution_type = this.state.institution.value

        if(formValue['propinsi_id'] instanceof Object === true){
            formValue['propinsi'] = formValue['propinsi_id'].label
            formValue['propinsi_id'] = formValue['propinsi_id'].value
        } else {
            formValue['propinsi_id'] = formValue['propinsi_id'] || 0
        }

        if(formValue['kota_id'] instanceof Object === true){
            formValue['kota'] = formValue['kota_id'].label
            formValue['kota_id'] = formValue['kota_id'].value
        } else {
            formValue['kota_id'] = formValue['kota_id'] || 0
        }

        if(formValue['kelurahan_id'] instanceof Object === true){
            formValue['kelurahan'] = formValue['kelurahan_id'].label
            formValue['kelurahan_id'] = formValue['kelurahan_id'].value
        } else {
            formValue['kelurahan_id'] = formValue['kelurahan_id'] || 0
        }

        if(formValue['kecamatan_id'] instanceof Object === true){
            formValue['kecamatan'] = formValue['kecamatan_id'].label
            formValue['kecamatan_id'] = formValue['kecamatan_id'].value
        } else {
            formValue['kecamatan_id'] = formValue['kecamatan_id'] || 0
        }
        // return
        delete(formValue.clinic_type)
        delete(formValue.hospital_type)
        
        //photo logo
        if(typeof formValue.photo === 'string'){
            delete(formValue.photo)
        }
        if(typeof formValue.logo === 'string'){
            delete(formValue.logo)
        }

        //fasilitas
        if(formValue.facilities && formValue.facilities.length > 0){
            formValue.facilities = formValue.facilities.join()
        }else{
            delete(formValue.facilities)
        }

        //jam besuk
        if(formValue.open_time instanceof Object === true || formValue.close_time instanceof Object === true){
            formValue.open_time = formValue.open_time.label
            formValue.close_time = formValue.close_time.label
        }

        if(formValue.open_time && formValue.close_time){
            formValue.jam_besuk = `${ formValue.open_time }-${ formValue.close_time }` 
            delete formValue.open_time
            delete formValue.close_time
        }

        //parse json open days
        if(formValue.open_days){
            formValue.open_days = JSON.stringify(formValue.open_days)
        }else{
            delete(formValue.open_days)
        }

        if(this.props.verifyClinicId){
            formValue.verified = true
        }

        if (formValue.sio_no_expired) {
            formValue.sio_no_expired = dateFns.format(formValue.sio_no_expired, 'YYYY-MM-DD')
        }

        if(!formValue.status){
            formValue.allow_booking = false
        }
        
        if (formValue.rekening_bank !== null && typeof formValue.rekening_bank === 'object') {
            formValue.rekening_bank = formValue.rekening_bank.value
        }
        formValue.npwp = formValue.npwp
            ? (formValue.npwp).replace(/[.-]/g, '')
            : 0

        
        delete this.state.operational.toggle
        formValue.operational = JSON.stringify(this.state.operational)

        const formData = this.setFormData(formValue)
        
        // if(!formValue.sio_no){
        //     handleToastServiceError({
        //         message: 'Please Check & Input SIO No.'
        //     })
        // }else if(!formValue.sio_no_expired){
        //     handleToastServiceError({
        //         message: 'Please Check & Input SIO No expired.'
        //     })
        // }else if(formValue.npwp === 0 || !formValue.npwp){
        //     handleToastServiceError({
        //         message: 'Please Check & Input  Npwp No.'
        //     })
        // }else if(!formValue.npwp_name){
        //     handleToastServiceError({
        //         message: 'Please Check & Input Npwp Name.'
        //     })
        // }else if(!formValue.rekening_bank){
        //     handleToastServiceError({
        //         message: 'Please Check & Input Rekening Bank.'
        //     })
        // }else if(!formValue.rekening_name){
        //     handleToastServiceError({
        //         message: 'Please Check & Input Rekening Name.'
        //     })
        // }else if(!formValue.rekening_no){
        //     handleToastServiceError({
        //         message: 'Please Check & Input Rekening No.'
        //     })
        // }else{
        Api.save(formData).then(response => {
            this.setState({
                submitingForm: false,
                isChangedOperate: false,
            })

            if (this.props.verifyClinicId) {
                this.props.onCancel()
            }

            handleToast(response.data.message)
            return
        }).catch((error) => {
            let message = error.message
            let errData = error.response.data

            if (!isEmpty(errData)) {
                errData = errData.data
                message = Object.keys(errData).map(k => errData[k]).join('\n')
            }

            this.setState({
                submitingForm: false
            })

            handleToastServiceError({ message })
            return
        })
    // }
    },
    buildOptions(data, type){
        let options = []
        if(type === 1){
            data.map(value => {
                    if(value.type === 1)
                        options.push({
                            value: value.id,
                            label: value.name
                        })

                    return true
                }
            )
            this.setState({
                hospitalType: options
            })
        }else{
            data.map(value => {
                    if(value.type === 2)
                        options.push({
                            value: value.id,
                            label: value.name
                        })
                    return true
                }
            )
            this.setState({
                clinicType: options
            })
        }
    },
    downloadCert(link) {
        fetch(link)
            .then(response => {
                if (response.status === 200) {
                    const fileName = response.url.split('/')[response.url.split('/').length - 1];
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob)
                        let a = document.createElement('a')
                        a.href = url
                        a.download = fileName
                        a.click()
                    })
                } else {
                    handleToastServiceError({
                        message: 'File not found.'
                    })
                }
            });
    },
    verifySPv() {
        const data = {
            'id_hospital': this.currentHospital,
            'pic_email': this.picEmail
        }

        Api.reactivate(data).then(response => {
            handleToast(response.data.message)
            return
        }).catch((error) => {
            handleToastServiceError(error)
            return
        })
    },
}

export default FormHandler
