import React from 'react'
import validateReturn from '../ValidatorForm'

import { FieldControl } from "react-reactive-form"
import DatePicker from "react-date-picker"
import Icon from '../Icons'

const DatePickerForm = {
    DatePickerIcon() {
        return (
            <img src={Icon.calendar} className="icon" alt="calendar" />
        )
    },
    ClearIcon() {
        return (
            <img src={Icon.close} className="icon close" alt="clear" />
        )
    },
    DatePickerFormView: function ({ name, stateName, stateCombo, meta, control, fieldControlAdditional }) {
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}
        Object.assign(control, additionalController);
        name = stateCombo ? stateCombo : name
        meta.name = name
        return <FieldControl
            name={name}
            strict={false}
            meta={{ ...meta }}
            render={(obj) => this.DatePickerFormRender(obj, control)}
            control={control}
        />
    },
    parseDate(dateStr) {
        if (dateStr) {
            if (dateStr instanceof Date) {
                return dateStr
            } else {
                return new Date(dateStr)
            }
        }

        return null
    },
    onKeyPress(event) {
        let maxLength = 2
        const inputType = event.target.getAttribute('name')
        const value = event.target.value

        if (inputType === 'year') {
            maxLength = 4
        }

        if (value.length >= maxLength) {
            event.preventDefault();
            return false;
        }

        return true;
    },
    DatePickerFormRender: function ({ handler, pristine, hasError, meta }, control) {
        const stateValue = this.parseDate(control.value)

        const cls = meta.className || ''
        const isRequired = meta.validator === 'required' ? true : false

        return (
            <div className={!pristine && hasError(meta.validator)
                ? `form-line has-danger ${cls}`
                : `form-line ${cls}`}>
                <label id={`label-${meta.name}`} className="form-control-label" htmlFor={`${meta.label}`}>
                    {meta.label}
                    {isRequired && meta.label
                        ? <span className="required">*</span>
                        : null}
                </label>
                <div className="form-group global-datepicker wizard-datepicker">
                    <DatePicker
                        calendarIcon={this.DatePickerIcon()}
                        clearIcon={this.ClearIcon()}
                        placeholder={meta.label}
                        onChange={handler().onChange}
                        onKeyPress={this.onKeyPress}
                        prev2Label={null}
                        next2Label={null}
                        locale="id-id"
                        autocomplete="off"
                        value={stateValue}
                        isOpen={false}
                        minDate={stateValue <= meta.minDate ? null : meta.minDate}
                        maxDate={meta.maxDate}
                        maxDetail="month"
                        minDetail="year"
                        disabled={meta.readonly}
                    />
                </div>
                <span className="error-info date-picker">
                    {
                        !pristine
                        && hasError(meta.validator)
                        && validateReturn(meta.validator)
                    }
                </span>
            </div>
        )
    }
}

export default DatePickerForm
