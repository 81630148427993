import React, { Component } from 'react'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from 'react-reactive-form'

import TextInputPasswordForm from '../../../core/utils/forminput/TextInputPasswordForm'
import Handler from '../handler/FormHandler'

class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            minPassValid: false,
            passNotMatch: false,
            Form: FormBuilder.group({
                password_old: ['', Validators.required],
                password_new: ['', Validators.required],
                password_new_confirm: ['', Validators.required],
            })
        }

        Object.assign(this, TextInputPasswordForm)
        this.TextInputPasswordFormView = this.TextInputPasswordFormView.bind(this)

        // assign to handler
        Object.assign(this, Handler)
        this.handleSubmit = this.handleSubmit.bind(this)

        this.buildForm()
    }

    render() {
        const { Form, loading, minPassValid, passNotMatch } = this.state
        const passNewError = Form.controls.password_new.status === 'INVALID'
        const confirmError = Form.controls.password_new_confirm.status === 'INVALID'

        return (
            <FieldGroup control={Form}
                strict={false}
                render={({ invalid }) => {
                    return (
                        <div
                            className="wizard-form custom-form"
                            style={{ marginTop: 16, marginLeft: 2, marginBottom: 24 }}
                        >
                            <form onSubmit={this.handleSubmit} autoComplete="off">
                                {this.passwordField}
                                {this.passwordNewField}
                                {(!passNewError && minPassValid)
                                    ? (
                                        <span className="custom-error control change-pass">
                                            Masukkan password baru minimal 6 karakter
                                        </span>
                                    )
                                    : null}
                                {this.passwordNewConfirmField}
                                {(!confirmError && passNotMatch)
                                    ? (
                                        <span className="custom-error control change-pass">
                                            Konfirmasi password tidak sama
                                        </span>
                                    )
                                    : null}
                                <button
                                    type="submit"
                                    className={
                                        `save itgbtn primary ${invalid || loading || minPassValid || passNotMatch
                                            ? 'disabled'
                                            : ''}`
                                    }
                                    disabled={invalid || loading || minPassValid || passNotMatch}
                                    onSubmit={this.handleSubmit}
                                >
                                    {loading ? 'Loading...' : 'Ubah'}
                                </button>
                            </form>
                        </div>
                    )
                }}
            />
        )
    }
}

export default Form
