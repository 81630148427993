export const SAVE_APPOINTMENT_RESULT = "SAVE_APPOINTMENT_RESULT"
export const CLEAR_APPOINTMENT_RESULT = "CLEAR_APPOINTMENT_RESULT"
export const SAVE_PATIENT = "SAVE_PATIENT"

export const initialState = {
    appointmentResult: null,
    chosenPatient: null
}


export const appointmentMapStateToProps = state => {
    return {
        appointmentResult: state.appointmentReducer.appointmentResult,
        chosenPatient: state.appointmentReducer.chosenPatient,
    }
}

export const appointmentMapDispatchToProps = dispatch => {
    return {
        onSavePatient: (result) => dispatch(
            {
                type: SAVE_PATIENT,
                chosenPatient: result
            }
        ),
        onSaveAppointment: (result) => dispatch(
            {
                type: SAVE_APPOINTMENT_RESULT,
                appointmentResult: result
            }
        ),
        onClearAppointment: () => dispatch(
            {
                type: CLEAR_APPOINTMENT_RESULT
            }
        )
    }
}