import React from 'react'
import Chance from 'chance'
import dateFns from 'date-fns'
import IndonesianLocale from 'date-fns/locale/id'

import DoctorInformationApi from '../../../../../services/apis/DoctorInformation'
import HospitalApi from '../../../../../services/apis/HospitalApi'
import HospitalListApi from '../../../../../services/apis/Hospital/List'
import DoctorApi from '../../../../../services/apis/doctor/DoctorApi'
import { handleToast, handleToastServiceError } from '../../../../../core/utils/UtilsToast'

const chance = new Chance()

const GridHandler = {
    onPanelClose() {
        this.setState({
            isPaneOpen: false,
            dataEdit: null,
            reasonSelected: '',
        })
    },
    onPanelOpen(id) {
        if (id) {
            this.getDetail(id)
        } else {
            this.getHospital().then((h) => {
                this.setState({
                    isPaneOpen: true,
                    dataEdit: null,
                    panelData: { headTitle: <div>Tambah User</div> },
                })
            })
        }
    },
    handleOpenModal(modalData) {
        this.setState({
            isModalOpen: true,
            modalData,
            forceActive: 'false',
        })
    },
    handleCloseModal(isSubmit) {
        this.setState({
            isModalOpen: false,
            modalData: {},
            forceActive: isSubmit === true
                ? 'false'
                : 'true',
        })
        this.state.Form.reset()
    },
    
    parseTime(row) {
        return (
            <div>
                {dateFns.format(new Date(row.book_date), 'dddd', { locale: IndonesianLocale })}, {dateFns.format(new Date(row.book_date), 'DD/MM/YYYY', { locale: IndonesianLocale })} <br />
                {row.arrival_time} WIB
            </div>)
    },

    gridColumn(ContextMenuProvider) {
        return [
            {
                Header: 'No. STR',
                accessor: 'str',
                sortable: true,
                maxWidth:260,
                headerClassName: '-sort',
                Cell: (row) => {
                    return (
                     <div>
                         <strong>{row.original.str}</strong>
                         <p>{row.original.str_no_expired ? `No.Str Expired: ${row.original.str_no_expired}` : '-' }</p>
                     </div>
                     )
                 }
            },
            {
                Header: 'Nama Dokter',
                accessor: 'display_name',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return (<div className="row-float">{row.original.display_name || ''}</div>)
                },
            },
            {
                Header: 'Spesialisasi',
                accessor: 'specialist_name',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return (<div className="row-float">{row.original.specialist_name || '-'}</div>)
                },
            },
            {
                Header: 'Email',
                accessor: 'email',
                sortable: true,
                headerClassName: '-sort',
            },
            {
                Header: 'Nama FasKes',
                accessor: 'hospital_name',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return (<div className="row-float">{row.original.hospital_name || '-'}</div>)
                },
            },
            {
                Header: 'Aktivasi',
                accessor: 'active',
                maxWidth: 100,
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    const label = row.original.active
                    const badgeStyle = row.original.active === true
                        ? 'badge-success'
                        : 'badge-danger'

                    return (
                        <div className="badge-label" >
                            <span className={`badge badge-pill ${badgeStyle}`}>
                            {label === false ? 'Belum' : 'Sudah'}
                            </span>
                        </div>
                    )
                },
            },
            {
                Header: 'Aplikasi',
                accessor: 'show_in_apps',
                maxWidth: 100,
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    const label = row.original.show_in_apps
                    const badgeStyle = row.original.show_in_apps === false
                        ? 'badge-danger'
                        : 'badge-success'

                    return (
                        <div className="badge-label" >
                            <span className={`badge badge-pill ${badgeStyle}`} >
                                {label === false ? 'Tidak' : 'Ya'}
                            </span>
                        </div>
                    )
                },
            },
            {
                Header: 'Booking',
                accessor: 'booking',
                maxWidth: 100 ,
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    const label = row.original.booking
                    const badgeStyle = row.original.booking === true
                        ? 'badge-success'
                        : 'badge-danger'

                    return (
                        <div className="badge-label" >
                            <span className={`badge badge-pill ${badgeStyle}`} >
                                {label === false ? 'Tidak' : 'Ya'}
                            </span>
                        </div>
                    )
                },
            },
        ]
    },
    fetchData: async function (state) {
        this.setState({ loading: true })
        this.page = state.page
        this.pageSize = state.pageSize

        let res = {}
        let fArray = {}
        let sort_property = ''
        let sort_value = ''

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'DESC' : 'ASC'
            this.sorted = state.sorted
        }

        if (state.filtered && state.filtered.length > 0) {
            state.filtered.map(val => {
                if (val.value !== '') {
                    fArray[val.id] = val.value
                }
                return true
            })
        }

        const filterData = JSON.stringify(fArray)

        try {
            res = await DoctorInformationApi.list(
                this.page + 1,
                this.pageSize,
                sort_property,
                sort_value,
                filterData,
            )
        } catch (e) {
            this.setState({ loading: false })

            handleToastServiceError(e)
            return
        }

        const {
            status,
            data: resData,
        } = res

        if (status === 200) {
            const newRows = resData.data.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })

            this.setState({
                loading: false,
                data: newRows,
                pages: resData.data.total_page,
                rows: resData.data.total_rows,
            })
        } else {
            this.setState({ loading: false })
        }
    },
    getFilter: async function () {
        try {
            const response = await DoctorInformationApi.getFilter()
            const { data } = response.data
            const filterList = data

            this.setState({ filterList })
        } catch (e) {
            handleToastServiceError(e)
        }
    },
    getDetail(id) {
        this.setState({ loading: true })
        this.getHospital().then(async () => {
            await DoctorInformationApi.detail(id).then((res) => {
                const { data } = res.data

                this.setState({ loading: false })
                if (res.status === 200) {
                    data.user_type = data.user_type && data.user_type.value
                    data.active = `${data.active}`
                    data.hospital_ids = data.hospitals.map(item => {
                        return {
                            label: item.name,
                            value: item.id,
                        }
                    })

                    this.setState({
                        isPaneOpen: true,
                        dataEdit: data,
                        panelData: {
                            headTitle: <div>Edit User</div>,
                        },
                    })
                }
            }).catch(error => {
                handleToastServiceError(error)
                this.setState({ loading: false })
            })
        })
    },
    getHospital: async function (keyword = '') {
        const { hospitalList } = this.state

        if (hospitalList.length > 0 && keyword === '') {
            return hospitalList
        }

        await HospitalApi.getHospital(keyword).then((res) => {
            const { data } = res.data
            if (res.status === 200) {
                this.setState({ hospitalList: data })
            }
        })
    },
    sendActivation: async function ({ hospital_id: hospitalId, user_type: userType, email }) {
        let response = {}
        let payload = userType === 'Dokter'
            ? { email }
            : {
                'id_hospital': hospitalId,
                'pic_email': email,
            }

        try {
            if (userType === 'Dokter') {
                response = await DoctorApi.reactivate(payload)
            } else {
                response = await HospitalListApi.reactivate(payload)
            }

            const { status, data } = response

            if (status === 200) {
                handleToast(data.message)
                this.handleCloseModal()
            }
        } catch (error) {
            handleToastServiceError(error)
            this.handleCloseModal()
        }
    },
}

export default GridHandler
