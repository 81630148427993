import React from 'react'
import icoCity from '../../../../../themes/v1/img/icon-city.png'
import icoElipsis from '../../../../../themes/v1/img/icon-elipsis.png'
import ReactTooltip from 'react-tooltip'
import { ContextMenuProvider } from 'react-contexify'

class CardList extends React.Component{
    displayLogo(obj){
        return(
            <div className="logo" 
                onClick={ e => this.props.handleClickHospital(e, obj)} 
            >
                {
                    obj.logo ? 
                    <div className="wrap-img">
                        <img src={ obj.logo } alt="" align="left" />
                    </div> :
                    <div className="empty-logo"></div>
                }
            </div>
        )
    }
    
    displayNeedVerify(obj){
        if(obj.verified){
            return false
        }
        return(
            <span 
                className="need-verify-sign"
                data-for="verify-info"
                data-tip={ 'Klinik Perlu diverifikasi' }
            >
                <ReactTooltip 
                    id='verify-info' 
                    place="top" 
                    className="cinnamon-tooltip"
                    delayShow={ 500 }
                />
            </span>
        )
    }

    displayContextMenu(obj){
        let contextId = 'menu_id'
        if(!obj.verified){
            contextId = 'menu_id_need_verify'
        }

        return(
            <ContextMenuProvider className="hospital_menu" id={ contextId } data={ obj } event="onClick">
                <div className="detail">
                    <img src={ icoElipsis } alt="" align="right" />
                </div>
            </ContextMenuProvider>
        )
    }

    render() {
        const option = this.props.option

        return (
            <div className="box-hospital">
                <div className="wrap-box-hospital">
                    {this.displayNeedVerify(option)}
                    {this.displayLogo(option)}
                    <div className="info-hospital">
                        <span
                            className="name"
                            onClick={e => this.props.handleClickHospital(e, option)}
                        >
                            {option.name}
                        </span>
                        {option.kota ? <span className="city">
                            <img src={icoCity} alt="" align="left" />
                            {option.kota}
                        </span> : null}
                    </div>
                    {this.displayContextMenu(option)}
                </div>
            </div>
        )
    }
}

export default CardList