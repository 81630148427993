import React, {Component} from 'react'
import Select from 'react-select'
import filterHandler from '../handler/FilterBar'
import IconAdvanced from '../../../themes/v1/img/dark-blue.png'
import SAConfig from '../config/superadmin'

class FilterBar extends Component
{
    constructor(props) {
        super(props)
        this.state = {
            selectedType: null,
            selectedStatus: '',
            selectedCity: null,
            typeOption: SAConfig.typeInsitution,
            statusOption: SAConfig.statusInsitution,
            toogleAdvance: false,
            filterName: null,
            filterCity: null,
            filterType: null,
        }
        Object.assign(this, filterHandler)
        this.handleTypeChange = this.handleTypeChange.bind(this)
        this.handleStatusChange = this.handleStatusChange.bind(this)
        this.handleAdvanceClick = this.handleAdvanceClick.bind(this)
        this.handleCityChange = this.handleCityChange.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handleChangeKeyword = this.handleChangeKeyword.bind(this)
    }

    handleChangeKeyword(e){
        this.props.setFilterName(e.target.value)
    }

    render() {
        return(
            <div className="row row-filterbar">
                <div className="filter-container">
                    <div className="filter">
                        <input 
                            type="text" 
                            className="dashboard-search-text" 
                            placeholder="Cari"
                            onChange={ this.handleChangeKeyword }
                            onKeyDown={e => this.handleFilter(e)} 
                            value={ this.props.filterSet.name }
                        />
                        <i className="fas fa-search"></i>
                    </div>
                    <div className="filter">
                        <Select
                            className={ `dropdown-filter` }
                            classNamePrefix={ 'react-select' }
                            name={ 'filter-city' }
                            placeholder={ 'Semua kota' }
                            options={ this.props.city }
                            onChange={ this.handleCityChange }
                            value={
                                this.props.filterSet.city ?
                                this.props.city.find(
                                    option => String(option.label) === String(this.props.filterSet.city)
                                ) : 
                                null
                            }
                        />
                    </div>
                    <div className="filter">
                        <Select
                            className={ `dropdown-filter` }
                            classNamePrefix={ 'react-select' }
                            name={ 'filter-type' }
                            placeholder={ 'Semua tipe' }
                            options={ this.state.typeOption }
                            onChange={ this.handleTypeChange }
                            value={
                                this.props.filterSet.institution ?
                                this.state.typeOption.find(
                                    option => String(option.value) === String(this.props.filterSet.institution)
                                ) :
                                null
                            }
                        />
                    </div>
                    <div className="filter">
                        <Select
                            className={ `dropdown-filter` }
                            classNamePrefix={ 'react-select' }
                            name={ 'filter-status' }
                            placeholder={ 'Semua status' }
                            options={ this.state.statusOption }
                            onChange={ this.handleStatusChange }
                            value={
                                this.props.filterSet.status ?
                                    this.state.statusOption.find(
                                        option => String(option.value) === String(this.props.filterSet.status)
                                    )
                                    : null
                            }
                        />
                    </div>
                    <div className="filter link">
                        <button
                            className="btn"
                            onClick={() => this.props.onFilter('reset')}
                        >
                            <span>Reset filter</span>
                        </button>
                    </div>
                </div>
                <div className="filter-container-advance">
                    <div className="filter-last">
                        <button className="btn" onClick={e => this.handleAdvanceClick(e)}>
                            <img src={IconAdvanced} alt="" />
                            <span>Advance filter</span>
                        </button>
                        
                    </div>
                </div>
            </div>
        )
    }
}
export default FilterBar