import React, { Component } from 'react'
import dateFns from 'date-fns'
import IndonesianLocale from 'date-fns/locale/id'

class DataPrescription extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { data, onPanelSecondOpen, prescription } = this.props
        let patientName = data && data.patient_name

        if (prescription && prescription.length > 0) {
            patientName = `${prescription[0].patient_firstname}${prescription[0].patient_lastname
                ? ` ${prescription[0].patient_lastname}`
                : ''}`
        }

        return (
            <div className="emr-data-prescription">
                <div className="field border-bottom">
                    <div className="label">Nama Pasien</div>
                    <div className="value">{patientName || '-'}</div>
                </div>
                {prescription && prescription.length > 0
                    ? prescription.map(item => (
                        <div
                            key={item.id}
                            onClick={() => onPanelSecondOpen(item.id, this.props)}
                            className="prescription"
                        >
                            <div>
                                <div className="type">{item.prescription_name}</div>
                                <div className="dr-name">
                                    {`${item.doctor_title
                                        ? `${item.doctor_title} `
                                        : ''}${item.doctor_name}`}
                                </div>
                                <div className="date">
                                    {dateFns.format(
                                        item.prescription_date,
                                        'DD MMMM YYYY',
                                        { locale: IndonesianLocale }
                                    )}
                                </div>
                            </div>
                            <button>
                                <i className="fas fa-chevron-right" />
                            </button>
                        </div>
                    ))
                    : <div className="empty">Belum ada riwayat resep sebelumnya</div>}
            </div>
        )
    }
}

export default DataPrescription
