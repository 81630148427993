import { combineReducers } from 'redux'

import { loginReducer } from './reducer/LoginReducer'
import { modalReducer } from './reducer/ModalReducer'
import { appointmentReducer } from './reducer/AppointmentReducer'

const rootReducer = combineReducers({
    loginReducer,
    modalReducer,
    appointmentReducer
});

export default rootReducer;