import React from 'react'
import ReactTable from 'react-table'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import { isEmpty } from 'lodash'
import { FieldGroup, FormBuilder, FieldControl, Validators } from 'react-reactive-form'
import { ContextMenuProvider} from 'react-contexify'

import MasterGrid from '../../../../../components/grid/MasterGrid'
import SliderPanel from '../../../../../components/wizard/SliderPanel'
import MasterModal from '../../../../../core/utils/MasterModal'
import GridHandler from '../../../handler/superadmin/doctorInformation/GridHandler'

const CheckboxTable = checkboxHOC(ReactTable)

class Grid extends MasterGrid {
    constructor(props) {
        super(props)

        Object.assign(this, GridHandler)

        this.clickable = false
        this.filterable = false
        this.selectedTableStatus = false
        this.gridColumn = this.gridColumn.bind(this)
        this.gridMapping = (this.selectedTableStatus) ? CheckboxTable : ReactTable
        this.hidePagination = false
        this.page = 0
        this.pageSize = 20
        this.selectedMax = 10
        this.selected = []
        this.filtered = []
        this.sorted = []
        this.defaultSorted = []
        this.keyTable = '_id'
        this.ignoreColumn = [
            '_id'
        ]

        /* own property */
        this.fetchData = this.fetchData.bind(this)
        this.extraProperties = {
            onFetchData: this.fetchData,
            filterAble: true
        }

        /* data */
        const loading = this.extraProperties.hasOwnProperty('onFetchData') ? true : false
        const data = this.dataFromRedux ? this.props.data : (loading ? [] : this.getData())
        const columns = this.getColumns(data)
        const finalColumn = this.gridColumn(ContextMenuProvider, columns)
        const pages = loading ? null : 1
        this.customFilter = ['DATE', 'DATE_RANGE']

        this.onPanelOpen = this.onPanelOpen.bind(this)
        this.onPanelClose = this.onPanelClose.bind(this)
        this.parseModal = this.parseModal.bind(this)
        this.radioInput = this.radioInput.bind(this)
        this.textareaInput = this.textareaInput.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.getFilter = this.getFilter.bind(this)
        this.getHospital = this.getHospital.bind(this)

        this.state = {
            loading: loading,
            data,
            columns: finalColumn,
            pages: pages,
            rows: 1,
            selection: [],
            deleted: [],
            filtered: [],
            disableHighlight: true,
            filterList: {},
            isPaneOpen: false,
            panelData: {},
            isModalOpen: false,
            rangeOpen: false,
            modalData: {},
            dataEdit: null,
            hospitalList: [],
            headerFilter: {},
            filterType: [
                {
                    label: 'Semua Jenis User',
                    value: '',
                },
                {
                    label: 'Admin Klinik',
                    value: 'a',
                },
                {
                    label: 'Dokter',
                    value: 'd',
                },
            ],
            Form: FormBuilder.group({
                reason: ['', Validators.required],
                others: '',
            }),
            forceActive: 'false',
            reasonSelected: '',
            reasonList: [
                {
                    id: 1,
                    text: 'Salah pengisian',
                },
                {
                    id: 2,
                    text: 'Atas request dokter/klinik',
                },
                {
                    id: 3,
                    text: 'Lainnya',
                },
            ],
        }
    }

    componentDidMount() {
        this.getFilter()
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { Form } = this.state
        const {
            reason,
            others,
        } = Form.value
        const data = {
            cancel_reason: !isEmpty(others) ? others : reason
        }

        this.modalHandleSubmit(data)

        setTimeout(() => {
            Form.reset();
        }, 1500);
    }

    parseModal(data) {
        const { Form } = this.state
        const { reasonList } = this.state

        return (
            <div className="master-modal-content">
                <div className="master-modal-head">
                    <span className="icon-exclamation">!</span>
                </div>
                <div className="master-modal-body" >
                    <div className="content" dangerouslySetInnerHTML={{ __html: data.content }} />
                    <FieldGroup
                        control={Form}
                        render={({ invalid }) => {
                            const setInvalid = Form.value.reason === 'Lainnya'
                                && isEmpty(Form.value.others)
                                ? true
                                : invalid;
                            return (
                                <form onSubmit={this.handleSubmit} className="form-hospital">
                                    <FieldControl
                                        name="reason"
                                        render={this.radioInput}
                                        meta={{ options: reasonList }}
                                    />
                                    {Form.value.reason === 'Lainnya' ?
                                        <FieldControl
                                            name="others"
                                            render={this.textareaInput}
                                            meta={{ label: "Others" }}
                                        />
                                        : null}

                                    <div className="master-modal-foot">
                                        <button
                                            className={`master-btn confirm ${setInvalid
                                                ? 'disabled'
                                                : ''}`}
                                            disabled={setInvalid}
                                        >
                                            {data.buttonConfirmText}
                                        </button>
                                        <button
                                            className="master-btn cancel"
                                            onClick={this.handleCloseModal}
                                        >
                                            {data.buttonCancelText}
                                        </button>
                                    </div>
                                </form>
                            )
                        }}
                    />
                </div>
            </div>
        );
    }

    radioInput = ({ handler, touched, hasError, meta }) => {
        return (
            <div className={touched && hasError(meta.validator)
                ? 'form-line has-danger'
                : 'form-line'}>
                <div className="wrap-options">
                    <div className="wrap-radio wrap-radio-reason">
                        {
                            meta.options.map((option, index) => {
                                return (
                                    <label key={index} className="container-radio">
                                        <span className="reason-opsi">{option.text}</span>
                                        <input
                                            id={option.id}
                                            {...handler("radio", option.text)}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

    textareaInput = ({ handler, touched, hasError, meta }) => {
        return (
            <div className={touched && hasError(meta.validator)
                ? 'form-line has-danger'
                : 'form-line'}>
                <textarea
                    className="form-control textarea-reason"
                    id={`${meta.label}`}
                    placeholder={meta.placeholder}
                    rows="5"
                    {...handler()} />
            </div>
        )
    }

    render() {
        const {
            loading,
            isPaneOpen,
            panelData,
            isModalOpen,
            modalData,
            headerFilter,
            filterList,
        } = this.state

        const filterListArr = Object.keys(filterList)

        this.leftAction = []
        if (filterListArr.length > 0) {
            filterListArr.forEach((key, index) => {
                let roundedPos = 'middle'
                const selectDefault = filterList[key].options.filter(s => {
                    return (s.value !== '') && (s.value === filterList[key].default)
                })

                if (index === 0) {
                    roundedPos = 'left'
                } else if ((filterListArr.length - 1) === index) {
                    roundedPos = 'right'
                }

                this.leftAction.push({
                    actionType: key,
                    type: filterList[key].type,
                    title: filterList[key].placeholder,
                    tooltip: filterList[key].tooltip,
                    filterKeyword: headerFilter[key] ? headerFilter[key].value : filterList[key].default,
                    handlerSubmit: this.handleFilter,
                    selectedOpt: headerFilter[key] || selectDefault,
                    filterOpt: filterList[key].options,
                    path: filterList[key].path,
                    handlerChangeOpt: this.handleFilter,
                    handlerChange: this.handleFilter,
                    className: `rounded ${roundedPos}`,
                })
            })

            this.leftAction.push({
                type: 'reset',
                loading: loading,
                tooltip: 'Reset Filter',
                handlerReset: this.handleFilterReset,
            })
        }
        return (
            <div>
                <div className="title-wrapper">
                    <h2 className="no-margin-top page-title">{this.props.title}</h2>
                </div>
                <section className="tables with-top-filter no-paging-top">
                    <div className="row">
                        <div className="col-lg-12 cn-table-container">
                            <div className="card">
                                {super.render()}
                            </div>
                        </div>
                    </div>
                </section>
                <SliderPanel
                    {...this.props}
                    open={isPaneOpen}
                    onPanelClose={this.onPanelClose}
                    panelData={panelData}>
                </SliderPanel>
                <MasterModal
                    className="master-modal"
                    isOpen={isModalOpen}
                    handleClose={this.handleCloseModal}
                    handleSubmit={this.modalHandleSubmit}
                    ModalData={modalData}>
                    {modalData && modalData.action === 'non_active' ? this.parseModal(modalData) : null}
                </MasterModal>
            </div>
        )
    }
}

export default Grid
