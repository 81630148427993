import React from 'react'
import { FieldControl } from "react-reactive-form";

const CheckBoxForm = {
    CheckBoxFormView: function({name, meta, control, stateName}) {
        return <FieldControl
            name = {name}
            strict = {false}
            meta = {{...meta}}
            render={(obj) => this.CheckBoxFormRender(obj, name, stateName, control)}
            control = {control}
        />
    },
    CheckBoxFormRender: function( {touched, hasError, meta}, name, stateName, control) {
        const options = meta.options.length > 0 ? meta.options : this.state[stateName]
        const currentVal = control.value
        return (
            <div className={touched && hasError(meta.validator) ? "form-line has-danger" : "form-line"}>
                <div className="wrap-options">
                    <span className="radio">{ meta.label }</span>
                    {
                        options.map((option, index) => {
                            return (
                                <div key={ index } className="wrap-checkbox">
                                    <label htmlFor={ `${name}_${index}_${option.value}` } className="container">
                                        { option.name }
                                        <input 
                                            name={name} 
                                            id={`${name}_${index}_${option.value}`}
                                            type="checkbox"
                                            onChange= {(event) => {
                                                const originalValue = option.value
                                                const checked = event.target.checked
                                                let currentValue = control.value
                                                if(checked && originalValue){
                                                    currentValue.push(originalValue)
                                                }else if(!checked && originalValue){
                                                    const index = currentValue.indexOf(originalValue)
                                                    if (index !== -1) {
                                                        currentValue.splice(index, 1);
                                                    }
                                                }

                                                const data = currentValue.filter(function (el) {
                                                    return el != null;
                                                });

                                                control.patchValue(data)
                                            }}
                                            checked={currentVal.includes(option.value) ? true : false}
                                        />
                                        <span className={`checkmark ${currentVal.includes(option.value) ? 'checked' : ''}`}></span>
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    },
}
export default CheckBoxForm