/* eslint-disable */

import React from 'react'
import Chance from 'chance'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'

import Api from '../../../../../../services/apis/TitleApi'
import {
    handleToast,
    handleToastServiceError
} from '../../../../../../core/utils/UtilsToast'

const chance = new Chance()

const GridHandler = {
    onPanelClose() {
        this.setState({
            isPaneOpen: false,
            dataEdit: [],
        })
    },
    onPanelOpen(data) {
        let headTitle = <div>Tambah Gelar</div>

        if (data) {
            headTitle = <div>Edit Gelar <strong>{data.name}</strong></div>
        }

        this.setState({
            isPaneOpen: true,
            dataEdit: data,
            panelData: { headTitle },
        })
    },
    handleOpenModal(ModalData) {
        this.setState({
            isModalOpen: true,
            ModalData: ModalData
        })
    },
    handleCloseModal() {
        this.setState({
            isModalOpen: false,
            ModalData: null
        })
    },
    modalHandleSubmit: async function () {
        const { ModalData } = this.state

        if (ModalData.action === 'delete') {
            try {
                const response = await Api.delete(ModalData.id)
                handleToast(response.data.message)

                this.loadDataAfterAction();
                this.handleCloseModal()
            } catch (e) {
                handleToastServiceError(e)
                this.handleCloseModal()
            }
        }
    },
    gridColumn() {
        return [
            {
                Header: "Label",
                accessor: "name",
            },
            {
                Header: "Value",
                accessor: "title",
            },
            {
                Header: "Sort",
                accessor: "sort",
            },
            {
                Header: "",
                width: 60,
                className: "grid-action",
                Cell: (row) => {
                    return (
                        <div className="grid-btn-wrapper">
                            <button onClick={() => this.onPanelOpen(row.original)}>
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                            <button onClick={() => this.handleDelete(row.original)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </div>
                    )
                }
            }
        ]
    },
    fetchData: async function (state) {
        let res = {}
        let searchKeyword = '';

        if ((state.filtered).length > 0) {
            searchKeyword = state.filtered[0].value
        }

        try {
            res = await Api.list(
                state.page + 1,
                state.pageSize,
                searchKeyword,
            )
        } catch (e) {
            this.setState({
                loading: false
            })

            handleToastServiceError(e)
            return
        }

        if (res.data.code === 200) {
            let newRows = res.data.data.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })

            if (this._isMounted) {
                this.setState({
                    data: newRows,
                    pages: res.data.data.total_page,
                    rows: res.data.data.total_rows,
                    loading: false,
                })
            }
        } else {
            this.setState({
                loading: false
            })
        }
    }
}

export default GridHandler
