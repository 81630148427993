import React, { Component } from 'react'
import RoleHelper from '../../../../../core/helper/RoleHelper'
import { NavLink } from 'react-router-dom'

class ButtonEditSchedule extends Component {
    render() {
        const {
            dataLogin,
            currentHospital,
            doctorId,
            isOwner,
        } = this.props

        const isAdminSPV = RoleHelper.isAdminSPV(dataLogin, isOwner(currentHospital))
        const isNurse = RoleHelper.isNurse(dataLogin)

        if (isAdminSPV && !isNurse) {
            return (
                <NavLink className="btn-edit-schedule" to={`/doctor/schedule/${doctorId}`}>
                    <span>Edit Jadwal Dokter</span>
                </NavLink>
            )
        } else {
            return (
                <div className="edit-not-allowed">
                    <i className="fas fa-info-circle" />
                    <span>Hubungi admin penanggung jawab untuk mengubah jadwal</span>
                </div>
            )
        }
    }
}

export default ButtonEditSchedule