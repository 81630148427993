import React, { Component } from 'react'

class FormError extends Component{
    render() {
        if(this.props.errorForm){
            return (
                <div className="wrap-error">
                    <p>{this.props.errorForm}</p>
                    <button type="button" className="clear_error" onClick={this.props.clearError} >x</button>
                </div>
            )
        }

        return null
    }
}

export default FormError