import React from 'react'
import ReactTable from 'react-table'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import { ContextMenuProvider } from 'react-contexify'

import MasterGrid from '../../../../../components/grid/MasterGrid'
import MasterModal from '../../../../../core/utils/MasterModal'
import GridHandler from '../../../handler/superadmin/medicalRecord/GridHandler'
import SliderPanel from '../../../../../components/wizard/SliderPanel'
import Upload from './Upload'
import Detail from './Detail'

const CheckboxTable = checkboxHOC(ReactTable)

class Grid extends MasterGrid {
    constructor(props) {
        super(props)

        Object.assign(this, GridHandler)

        this.clickable = false
        this.filterable = false
        this.selectedTableStatus = true
        this.gridColumn = this.gridColumn.bind(this)
        this.gridMapping = (this.selectedTableStatus) ? CheckboxTable : ReactTable
        this.hidePagination = false
        this.page = 0
        this.pageSize = 20
        this.selectedMax = 10
        this.selected = []
        this.filtered = []
        this.sorted = []
        this.defaultSorted = []
        this.keyTable = '_id'
        this.ignoreColumn = [
            '_id'
        ]

        /* own property */
        this.fetchData = this.fetchData.bind(this)
        this.extraProperties = {
            onFetchData: this.fetchData,
            filterAble: true
        }

        /* data */
        const loading = this.extraProperties.hasOwnProperty('onFetchData') ? true : false
        const data = this.dataFromRedux ? this.props.data : (loading ? [] : this.getData())
        const columns = this.getColumns(data)
        const finalColumn = this.gridColumn(ContextMenuProvider, columns)
        const pages = loading ? null : 1

        this.handleClickRow = this.handleClickRow.bind(this)
        this.onDetailClose = this.onDetailClose.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.getFilterList = this.getFilterList.bind(this)
        this.getHospitalList = this.getHospitalList.bind(this)
        this.uploadCsv = this.uploadCsv.bind(this)
        this.sendEmail = this.sendEmail.bind(this)
        this.parseModal = this.parseModal.bind(this)

        this.state = {
            loading: loading,
            loadingE: false,
            data,
            columns: finalColumn,
            pages: pages,
            rows: 1,
            selection: [],
            deleted: [],
            filtered: [],
            disableHighlight: true,
            headerFilter: {},
            filterList: {},
            rangeOpen: false,
            lastRounded: false,
            selectAll: false,
            isModalOpen: false,
            modalType: '',
            isDetailOpen: false,
            detailData: {},
            hospitalList: [],
            isSelectLoading: false,
            isUploadLoading: false,
            resultUpload: {},
        }
    }

    componentDidMount() {
        this.getFilterList();
    }

    parseModal() {
        const {
            modalType,
            hospitalList,
            isSelectLoading,
            isUploadLoading,
            resultUpload,
        } = this.state

        switch (modalType) {
            case 'bulk_upload':
                return <Upload
                    handleCloseModal={this.handleCloseModal}
                    hospitalList={hospitalList}
                    isSelectLoading={isSelectLoading}
                    isUploadLoading={isUploadLoading}
                    getHospitalList={this.getHospitalList}
                    uploadCsv={this.uploadCsv}
                    resultUpload={resultUpload}
                />
            default:
                return null
        }
    }

    render() {
        const {
            loading,
            headerFilter,
            filterList,
            filtered,
            rangeOpen,
            lastRounded,
            isModalOpen,
            isDetailOpen,
            detailData,
            selectAll,
            selection,
            loadingE,
        } = this.state
        let isMenuCollaped = true
        const filterListArr = Object.keys(filterList)
        try {
            isMenuCollaped = localStorage.getItem('isToggleButton')
        } catch (e) {
            console.log('Local Storage is disabled');
        }

        this.selected = selection
        this.leftAction = []
        if (filterListArr.length > 0) {
            filterListArr.forEach((key, index) => {
                let roundedPos = 'middle'
                const selectDefault = filterList[key].options.filter(s => {
                    return (s.value !== '') && (s.value === filterList[key].default)
                })

                if (filterListArr.length === 1) {
                    roundedPos = 'single'
                } else if (index === 0) {
                    roundedPos = 'left'
                } else if ((filterListArr.length - 1) === index) {
                    roundedPos = lastRounded ? 'middle' : 'right'
                }

                this.leftAction.push({
                    actionType: key,
                    type: filterList[key].type,
                    title: filterList[key].placeholder,
                    tooltip: filterList[key].tooltip,
                    filterKeyword: headerFilter[key] ? headerFilter[key].value : filterList[key].default,
                    handlerSubmit: this.handleFilter,
                    selectedOpt: headerFilter[key] || selectDefault,
                    filterOpt: filterList[key].options,
                    path: filterList[key].path,
                    handlerChangeOpt: this.handleFilter,
                    handlerChange: this.handleFilter,
                    className: `rounded ${roundedPos}`,
                })
            })
        }

        const getDateORRange = filtered.find(f => this.customFilter.includes(f.value))
        if (getDateORRange) {
            let roundedPos = 'middle'
            const indexDateORRange = filterListArr.indexOf(getDateORRange.id) + 1

            if (filterListArr.length === indexDateORRange) {
                roundedPos = 'right'
            }

            if (getDateORRange.value === 'DATE_RANGE') {
                this.leftAction.splice(indexDateORRange, 0, {
                    type: 'range',
                    title: 'Date',
                    isOpen: rangeOpen,
                    actionType: `${getDateORRange.id}_range`,
                    filterKeyword: headerFilter[`${getDateORRange.id}_range`],
                    handlerChange: this.handleFilter,
                    className: `rounded ${roundedPos}`,
                })
            } else {
                this.leftAction.splice(indexDateORRange, 0, {
                    type: 'date',
                    title: 'Date',
                    actionType: `${getDateORRange.id}_date`,
                    filterKeyword: headerFilter[`${getDateORRange.id}_date`],
                    handlerChange: this.handleFilter,
                    className: `rounded ${roundedPos}`,
                })
            }
        }

        this.leftAction.push({
            type: 'reset',
            loading: loading,
            tooltip: 'Reset Filter',
            handlerReset: this.handleFilterReset,
        })

        return (
            <div>
                <div className="title-wrapper">
                    <h2 className="no-margin-top page-title">{this.props.title}</h2>
                    <button
                        onClick={() => this.handleOpenModal('bulk_upload')}
                        className="add"
                    >
                        Tambah
                    </button>
                </div>
                <section className="tables with-top-filter no-paging-top">
                    <div className="row">
                        <div className="col-lg-12 cn-table-container wrap-text">
                            <div className="card">
                                {super.render()}
                            </div>
                        </div>
                    </div>
                </section>
                {selection.length > 0
                    ? (
                        <div
                            className={`action-bar-bottom${isMenuCollaped === 'true'
                                ? ''
                                : ' menu-expanded'}`
                            }
                        >
                            <div className={`text-wrapper${selectAll ? ' selected-all' : ''}`}>
                                <span onClick={() => this.toggleAll('deselect-all')} />
                                {`${selection.length} Pasien dipilih`}
                            </div>
                            <div className="btn-wrapper">
                                <button disabled={loadingE} onClick={() => this.sendEmail()}>
                                    {`Kirim Hasil${loadingE ? '...' : ''}`}
                                </button>
                                <button onClick={() => this.toggleAll('deselect-all')}>Batalkan</button>
                            </div>
                        </div>
                    )
                    : null}
                <MasterModal
                    className="master-modal"
                    isOpen={isModalOpen}
                    handleClose={this.handleCloseModal}>
                    {this.parseModal()}
                </MasterModal>
                <SliderPanel
                    open={isDetailOpen}
                    onPanelClose={this.onDetailClose}
                    panelData={detailData}>
                    <Detail data={detailData} sendEmail={this.sendEmail} loadingE={loadingE} />
                </SliderPanel>
            </div>
        )
    }
}

export default Grid
