import React, { Component } from 'react'
import WizardTemplate from './WizardTemplate'
import ContentTemplate from './ContentTemplate'
import HeaderTemplate from './HeadTemplate'
import AdminHeaderTemplate from './AdminHeadTemplate'
import { AclHandler } from '../core/const/AclConst'
import { WhiteList } from '../core/const/WhiteList'
import { ToastContainer } from 'react-toastify'
import dateFns from "date-fns"
import 'react-toastify/dist/ReactToastify.css'
import { connect } from 'react-redux'
import { loginMapStateToProps, loginMapDispatchToProps } from '../core/const/LoginConst'
import { Redirect } from 'react-router-dom'
import { handleToastServiceError } from '../core/utils/UtilsToast'

class MasterTemplate extends Component 
{
    constructor(props) {
        super(props)

        Object.assign(this, AclHandler)

        this.state = {
            listAccess: [],
            objectToChild: {},
            onLogoutTrigger: false,
            redirectTo : null
        }

        this.onCheckIsLoggedIn = this.onCheckIsLoggedIn.bind(this)
        this.onAccessThisPage = this.onAccessThisPage.bind(this)
        this.onHaveAccessDetailModule = this.onHaveAccessDetailModule.bind(this)
        this.onListAccesAll = this.onListAccesAll.bind(this)
        this.getIdentity = this.getIdentity.bind(this)
        this.checkLoginIsExpire = this.checkLoginIsExpire.bind(this)
        this.renderTemplate = this.renderTemplate.bind(this)

    }

    componentDidMount() {
        const dataLogin = this.props.dataLogin
        if (!this.onCheckIsLoggedIn()) {
            this.props.history.push('/')
            return
        }

        let moduleName = this.props.moduleName,
        haveAccess = true

        if (WhiteList.indexOf(moduleName) === -1) {
            haveAccess = this.onAccessThisPage(moduleName)
        }

        if (!haveAccess) {
            if(!dataLogin){
                this.setState({
                    redirectTo: '/'
                })
            }else{
                this.setState({
                    redirectTo: '/redirectunauthorize'
                })
            }
        }

        const identity = this.getIdentity()
        let auth = {
            email: null, name: null, id: null
        }
        if(identity){
            auth.email = identity.email;
            auth.name = identity.name;
            auth.id = identity.id;
        }

        this.setState({
            listAccess: this.onListAccesAll(moduleName),
            objectToChild: {
                listAccess: this.state.listAccess,
                onHaveAccessDetailModule: this.onHaveAccessDetailModule,
                onListAccesAll: this.onListAccesAll,
                auth: auth
            }
        })

    }

    componentWillReceiveProps(nextProps){
        if(this.props.dataLogin !== nextProps.dataLogin && !nextProps.dataLogin){
            this.setState({
                redirectTo: '/'
            })
        }
    }

    checkLoginIsExpire(){
        const state = this.props.store.getState()
        const currentDate = new Date()

        if(state && state.loginReducer){
            const expireCheck = dateFns.differenceInMilliseconds(currentDate, dateFns.parse(state.loginReducer.expire))
            if(expireCheck  >= 0){
                return true
            }
        }

        return false
    }

    onCheckIsLoggedIn() {
        if (this.props.store === undefined) {
            this.props.history.push('/')
            return false
        }
        let state = this.props.store.getState()
        let isLogin = state.loginReducer.isLoggin

        if(this.checkLoginIsExpire()){
            handleToastServiceError({
                message: 'Silahkan login kembali'
            })
            this.props.onRequestLogout()
            this.setState({
                onLogoutTrigger: true,
                redirectTo: '/'
            })
        }

        if (!isLogin) {
            this.setState({onLogoutTrigger: true})
        }

        return true
    }

    renderTemplate(){
        const { dataLogin } = this.props
        if(!dataLogin){
            return null
        }
        if(dataLogin.superadmin)
            return(
                <AdminHeaderTemplate {...this.props}>
                    <ContentTemplate
                        {...this.props}
                        objectPass={this.state.objectToChild}
                    />
                </AdminHeaderTemplate>
            )
        else
            return(
                <HeaderTemplate {...this.props}>
                    <ContentTemplate
                        {...this.props}
                        objectPass={this.state.objectToChild}
                    />
                </HeaderTemplate>
            )
    }

    render() {
        if(this.state.redirectTo){
            return(<Redirect to={ this.state.redirectTo } />)
        }

        if (this.state.onLogoutTrigger) {
            return <Redirect to="/"/>
        }
        if(this.props.wizard === true){
            return(
                <WizardTemplate {...this.props}>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnVisibilityChange
                    />
                   <ContentTemplate
                        {...this.props}
                        objectPass={this.state.objectToChild}
                    /> 
                </WizardTemplate>
            )
        }else{
            return (
                <div className="page">
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover
                    />
                    {this.renderTemplate()}
                </div>
            )
        }
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(MasterTemplate)

/**
 kalo mau otomatis
 //let history = this.props.history,
 //location = history.location.pathname,
 // moduleName = location.replace("/", "")moduleName
 */