import React from 'react'

/**
 * Not used for now
 * @param ContextMenuProvider
 * @param existingColumn
 * @returns {*[]}
 */
const gridColumn = (ContextMenuProvider, existingColumn) => {
    return [
        ...existingColumn,
        {
            Header: 'action',
            filterable: false,
            Cell: row => (
                <ContextMenuProvider id='menu_id' data={row} event='onClick'>
                    click
                </ContextMenuProvider>
            )
        }
    ]
}

export default gridColumn
/**
 *
 * @param selectAll
 * @param isSelected
 * @param toggleSelection
 * @param toggleAll
 * @param keyTable
 * @returns {{selectAll: *, isSelected: *, toggleSelection: *, toggleAll: *, selectType: string, previousText: string, nextText: string, loadingText: string, noDataText: string, pageText: string, ofText: string, rowsText: string, pageJumpText: string, rowsSelectorText: string, showPaginationTop: boolean, showPaginationBottom: boolean, getTrProps: getTrProps, getPaginationProps: (function(): {className: string})}}
 */
export const checkboxProps = (
    selectAll,
    isSelected,
    toggleSelection,
    toggleAll,
    gridType,
    keyTable,
    isDashboard,
    checkedMax,
    checked = [],
) => ({
    selectAll,
    isSelected,
    toggleSelection,
    toggleAll,
    selectType: 'checkbox',
    // Text
    previousText: '<',
    nextText: '>',
    loadingText: 'Loading...',
    noDataText: 'Tidak ada data',
    pageText: 'Page',
    ofText: 'dari',
    rowsText: '',

    // Accessibility Labels
    pageJumpText: 'jump to page',
    rowsSelectorText: '',
    // render
    showPaginationTop: true,
    showPaginationBottom: true,
    getTheadThProps: (s) => {
        let status = ''

        if (selectAll) {
            status = 'all'
        } else if (checked.length > 0) {
            status = 'partially'
        }

        return {
            className: status
        }
    },
    getTrProps: (s, r) => {
        if (typeof r === 'undefined') {
            return {
                style: {
                    backgroundColor: 'inherit'
                }
            };
        }

        const selected = isSelected(r.original[keyTable])

        if (s.disableHighlight) {
            let isDisabled = checkedMax > 0
                && (checked.length >= checkedMax)
                && !checked.includes(r.original._id)
            const selectedCls = selected ? 'selected ' : ''
            const preventDel = (isDashboard && !r.original.allow_delete) ? 'prevent-del' : ''

            if (gridType === 'Appointment') {
                isDisabled = !r.original.send_email
            }

            return {
                onClick: () => { },
                style: {
                    backgroundColor: selected
                        ? '#c5d3f9'
                        : ((!r.original.draft || isDashboard) ? 'inherit' : '#fff6f6')
                },
                className: `${selectedCls}${isDisabled ? 'disabled-check ' : ''}${preventDel}`
            };
        } else {
            return {
                onClick: () => { },
                style: {
                    backgroundColor: selected ? '#c5d3f9' : 'inherit'
                },
            };
        }
    },
    getPaginationProps: () => ({ className: 'dc-v-react-table-pagination--shadowed' }),
});