import React, { Component } from 'react'
import RoleHelper from '../../../../../core/helper/RoleHelper'

class ButtonConfirmSchedule extends Component {
    render() {
        const {
            dataLogin,
            currentHospital,
            patient,
            handleConfirm,
            handleCancel,
        } = this.props

        const isDoctor = RoleHelper.isDoctor(dataLogin, currentHospital)
        const isNurse = RoleHelper.isNurse(dataLogin)

        if (isNurse || isDoctor || patient.status === 1) {
            return null
        }

        return (
            <div className="wrap-buttons-appointment">
                <button
                    onClick={() => handleConfirm(patient.id)}
                    type="button"
                    className="regular-button"
                >
                    Konfirmasi Pasien
                </button>
                <button
                    onClick={() => handleCancel(patient.id)}
                    type="button"
                    className="regular-button invert"
                >
                    Batalkan Pasien
                </button>
            </div>
        )
    }
}

export default ButtonConfirmSchedule