import React, { Component } from 'react'

class ChosenPoly extends Component{
    render(){
        const poly = this.props.poly
        return(
            <ul className="chosen-poly-list">
                {
                    poly.map((row, key) => {
                        return(
                            <li key={ key }>{ row.name }</li>
                        )
                    })
                }
            </ul>
        )
    }
}

export default ChosenPoly