import { 
    SAVE_APPOINTMENT_RESULT, 
    CLEAR_APPOINTMENT_RESULT,
    SAVE_PATIENT,
    initialState
} from '../../../core/const/AppointmentConst'

export function appointmentReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_APPOINTMENT_RESULT :
            return {
                ...state,
                appointmentResult: action.appointmentResult
            }
        case CLEAR_APPOINTMENT_RESULT :
            return {
                ...state,
                appointmentResult: null,
                chosenPatient: null
            }
        case SAVE_PATIENT : 
            return {
                ...state,
                chosenPatient: action.chosenPatient
            }
        default:
            return state
    }
  }
  