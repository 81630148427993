import React, { Component } from 'react'
import Select from 'react-select'
import { debounce } from 'lodash'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from 'react-reactive-form'

import TextInputForm from '../../../../../core/utils/forminput/TextInputForm'
import TextInputEmailForm from '../../../../../core/utils/wizard/TextInputEmailForm'
import DropDownForm from '../../../../../core/utils/wizard/DropDownForm'
import RadioInputForm from '../../../../../core/utils/wizard/RadioInputForm'
import Handler from '../../../handler/superadmin/user/FormHandler'

class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            typeList: [],
            hospitalList: [],
            hospitalListState: [],
            selectedHospital: [],
            isSelectLoading: false,
            formApprFaskesTouched: false,
            activeInit: 'false',
            reason: '',
            Form: FormBuilder.group({
                name: ['', Validators.required],
                user_type: [''],
                hospital_ids: ['', Validators.required],
                email: ['', [Validators.required, Validators.email]],
                active: ['false'],
            })
        }

        Object.assign(this, TextInputForm)
        this.TextInputFormView = this.TextInputFormView.bind(this)
        Object.assign(this, TextInputEmailForm)
        this.TextInputEmailFormView = this.TextInputEmailFormView.bind(this)
        Object.assign(this, DropDownForm)
        this.DropDownFormView = this.DropDownFormView.bind(this)
        Object.assign(this, RadioInputForm)
        this.RadioInputFormView = this.RadioInputFormView.bind(this)

        // assign to handler
        Object.assign(this, Handler)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.buildValue = this.buildValue.bind(this)
        this.getHospital = this.getHospital.bind(this)
        this.onChangeInputDebounced = debounce(this.onChangeInputDebounced, 500)
        this.handleChangeDataSelect = this.handleChangeDataSelect.bind(this)
        this.handleChangeInput = this.handleChangeInput.bind(this)

        this.buildForm()
    }

    componentDidMount() {
        const { Form } = this.state
        const { dataEdit, type, hospitalList } = this.props

        if (dataEdit) {
            const { user_type: userType } = dataEdit

            Form.get('user_type').disable()
            if (userType === 'd') {
                Form.get('name').disable()
                Form.get('email').disable()
            }

            this.buildValue(dataEdit)
            this.setState({
                typeList: type,
                selectedHospital: dataEdit.hospital_ids,
                activeInit: dataEdit.active,
                reason: dataEdit.reason,
            })
        }

        this.setState({
            hospitalList: dataEdit && dataEdit.hospital_ids.length >= 3 ? [] : hospitalList,
            hospitalListState: hospitalList,
        })
    }

    componentWillReceiveProps(nextProps) {
        const { reason } = this.state
        const { forceActive, reason: reasonProps } = nextProps

        if (forceActive === 'true') {
            this.setState({ reason: '' })
            this.buildValue({
                active: forceActive
            })
        }

        if (reason !== reasonProps) {
            this.setState({ reason: reasonProps })
        }
    }

    handleChangeDataSelect(data) {
        const { Form, hospitalListState } = this.state

        if (data.length >= 3) {
            this.setState({ hospitalList: [] })
        } else {
            this.setState({ hospitalList: hospitalListState })
        }

        Form.patchValue({ hospital_ids: data })
        this.setState({
            selectedHospital: data,
            formApprFaskesTouched: true,
        })
    }

    handleChangeInput(keyword) {
        const { selectedHospital } = this.state
        if (keyword.length >= 3 && selectedHospital.length < 3) {
            this.onChangeInputDebounced(keyword)
        }
    }

    onChangeInputDebounced(data) {
        this.getHospital(data)
    }

    render() {
        const {
            loading,
            Form,
            isSelectLoading,
            formApprFaskesTouched,
            hospitalList,
            selectedHospital,
            reason,
        } = this.state
        const { dataEdit } = this.props

        return (
            <FieldGroup control={Form}
                strict={false}
                render={({ invalid }) => {
                    return (
                        <div
                            className="wizard-form custom-form"
                            style={{ display: 'flex', margin: '18px auto 70px' }}
                        >
                            <form onSubmit={this.handleSubmit} autoComplete="off">
                                {this.nameField}
                                {dataEdit && this.userTypeField}
                                <div className="form-line">
                                    <label htmlFor="feature" className="form-control-label">
                                        Tempat Praktek
                                        <span style={{ marginLeft: '1px', color: 'red' }}>*</span>
                                    </label>
                                    <div className={`form-group-wizard subscription${dataEdit ? ' disabled' : ''}`}>
                                        <Select
                                            className="dropdown-wizard"
                                            classNamePrefix="react-select"
                                            name="faskes"
                                            options={hospitalList}
                                            placeholder="Pilih Tempat Praktek"
                                            onChange={this.handleChangeDataSelect}
                                            onInputChange={this.handleChangeInput}
                                            value={selectedHospital}
                                            isLoading={isSelectLoading}
                                            isDisabled={dataEdit && dataEdit.user_type !== 'a'}
                                            isMulti={dataEdit && dataEdit.user_type !== 'a'}
                                        />
                                    </div>
                                    {(dataEdit && dataEdit.user_type !== 'a')
                                        && (
                                            <div className="max-faskes">
                                                Maksimal terdaftar di 3 tempat praktek
                                            </div>
                                        )}
                                    {formApprFaskesTouched && selectedHospital.length === 0
                                        ? <div className="custom-error control package user">
                                            Data Harus Diisi
                                        </div>
                                        : ''}
                                </div>
                                {this.emailField}
                                {dataEdit && this.activeField}
                                {dataEdit && (
                                    <div className="reason-non-active">
                                        <span>Alasan Non Aktif:</span> {reason || '-'}
                                    </div>
                                )}
                                <div className="button-action-slider">
                                    <div className="form-btn-action">
                                        <button
                                            type="submit"
                                            className={
                                                `save itgbtn primary${loading || invalid
                                                    ? " disabled"
                                                    : ""}`
                                            }
                                            disabled={loading || invalid}
                                            onSubmit={this.handleSubmit}
                                        >
                                            {loading
                                                ? 'Loading...'
                                                : dataEdit ? 'Update' : 'Simpan'}
                                        </button>
                                        <button
                                            type="button"
                                            className="itgbtn cancel"
                                            onClick={() => this.props.onPanelClose()}
                                        >
                                            Batal
                                    </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )
                }}
            />
        )
    }
}

export default Form
