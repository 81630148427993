import getMenuDoctor from './RoleMenu/Doctor'
import getMenuAdminSpv from './RoleMenu/AdminSPV'
import AdminReg from './RoleMenu/AdminReg'
import Nurse from './RoleMenu/Nurse'

const getMenuByRole = (role, dataLogin, newCurrentHosp) => {
    switch (role.role_name) {
        case 'admin_spv': {
            return getMenuAdminSpv(role, dataLogin)
        }
        case 'superadmin': {
            return getMenuAdminSpv(role, dataLogin)
        }
        case 'doctor': {
            return getMenuDoctor(role, dataLogin, newCurrentHosp)
        }
        case 'nurse': {
            return Nurse
        }
        case 'admin_reg': {
            return AdminReg
        }
        default: {
            return null
        }
    }
}

export { getMenuByRole }