import React, { Component } from 'react'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from "react-reactive-form"
import SubmitButtonForm from '../../../core/utils/SubmitButtonForm'
import TextInputForm from '../../../core/utils/wizard/TextInputForm'
import TextAreaForm from '../../../core/utils/wizard/TextAreaForm'
import DropDownForm from '../../../core/utils/wizard/DropDownForm'
import CheckBoxForm from '../../../core/utils/wizard/CheckBoxForm'
import RadioInputForm from '../../../core/utils/wizard/RadioInputForm'
import FormHandler from '../handler/FormHandler'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../../core/const/LoginConst'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Config from '../config/UrlConfig'
import Api from '../../../services/apis/Hospital/List'
import FacilityApi from '../../../services/apis/FacilityApi'

class Form extends Component
{
    constructor(props) {
        super(props)

        Object.assign(this, TextInputForm)
        Object.assign(this, TextAreaForm)
        Object.assign(this, DropDownForm)
        Object.assign(this, CheckBoxForm)
        Object.assign(this, RadioInputForm)

        this.state = {
            isEdit: false,
            postStatus: false,
            filledForm: [],
            Form: FormBuilder.group({
                id: [""],
                name: ["", Validators.required],
                email: ["", Validators.required],
                phone: ["", Validators.required],
                fax: ["", null],
                code: ["", Validators.required],
                photo: ["", null],
                website: ["", null],
                lat: ["", null],
                lng: ["", null],
                gov_type: ["", Validators.required],
                id_type: ["", Validators.required],
                facilities: [[], null],
                propinsi_id: ["", Validators.required],
                propinsi: [""],
                kota_id: ["", Validators.required],
                kota: [""],
                kecamatan_id: ["", Validators.required],
                kecamatan: [""],
                kelurahan_id: ["", Validators.required],
                kelurahan: [""],
                kodepos: ["", Validators.required],
                hour24: ["0", Validators.required],
                hour242: ["0", Validators.required],
                jam_besuk: ["", null],
                street: ["", null]
            }),
            JenisRsOpt: [
                {value: "S", label: "Swasta"},
                {value: "P", label: "Pemerintah"}
            ],
            tipeRsOpt: [],
            facilityOpt: [],
            propinsiOpt: [],
            kotaOpt: [],
            kecamatanOpt: [],
            kelurahanOpt: [],
        }

        this.TextInputFormView = this.TextInputFormView.bind(this)
        this.TextAreaFormView = this.TextAreaFormView.bind(this)
        this.DropDownFormView = this.DropDownFormView.bind(this)
        this.CheckBoxFormView = this.CheckBoxFormView.bind(this)
        this.RadioInputFormView = this.RadioInputFormView.bind(this)
        Object.assign(this, FormHandler)

        this.handleSubmit = this.handleSubmit.bind(this)
        this.valueBuilder = this.valueBuilder.bind(this)
        this.fillForm = this.fillForm.bind(this)

        this.buildForm()
    }

    componentDidMount() {
        this.valueBuilder()
        this.getHospitalType()
        this.getHospitalFacility()
    }

    getHospitalFacility() {
        FacilityApi.get().then((res) => {
            if (res.status === 200) {
                const { data } = res.data
                const facilityOpt = data.map((item) => {
                    return {
                        value: item.id,
                        name: item.name,
                    }
                })

                this.setState({
                    facilityOpt
                })
            }
        }).catch(error => {
            console.log('Something went wrong:', error)
        })
    }

    getHospitalType(){
        const fetchHospitalType = Api.listType
        fetchHospitalType().then(res => {
            const types = res.data.data.rows
            let opt = []
            types.map(value => 
                opt.push({
                    value: value.id,
                    label: value.name
                })
            )
            this.setState({
                tipeRsOpt: opt
            })
        })
    }

    render(){
        if (this.state.postStatus) {
            return <Redirect to={ Config.GridUrl } />
        }
        return(
            <div className="wizard-form">
                <h3>
                    {this.props.subtitle}
                </h3>

                {/** start form */}
                <FieldGroup
                control={this.state.Form}
                strict={false}
                render={({ get, invalid }) => {
                return(
                    <form onSubmit={() => this.handleSubmit} className="form-horizontal">
                        {this.TextName}
                        {this.DropDownTipeRs}
                        {this.DropDownJenisRs}
                        {this.TextHospitalCode}
                        {this.TextJamBesuk}
                        {this.TextEmail}
                        {this.TextPhone}
                        {this.TextFax}
                        {this.TextPhoto}
                        {this.TextWebsite}
                        {this.TextLat}
                        {this.TextLng}
                        {this.CheckBoxFacility}
                        {this.TextStreet}
                        {this.DropDownPropinsi}
                        {this.DropDownKota}
                        {this.DropDownKecamatan}
                        {this.DropDownKelurahan}
                        {this.Radio24}
                        {this.Radio242}
                        <SubmitButtonForm 
                            submitText={'Simpan Data'} 
                            invalid={invalid} 
                            onSubmit={(e) => this.handleSubmit(e)} 
                            onCancel={(e) => this.props.history.push( Config.GridUrl )}
                        />
                    </form>
                )}}/>
                {/** end of form */}
            </div>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Form)