const quarterHours = ["00", "30"];
let times = []
let index = 0
for (var i = 0; i < 24; i++) {
    for (var j = 0; j < quarterHours.length; j++) {
        const hour = i <= 9 ? '0' + i : i
        times.push({
            value: index,
            label: hour + ":" + quarterHours[j]
        })
        index++
    }
}

const Config = {
    listDay: [
        { value: 'mon', label: 'Senin' },
        { value: 'fri', label: 'Jumat' },
        { value: 'tue', label: 'Selasa' },
        { value: 'sat', label: 'Sabtu' },
        { value: 'wed', label: 'Rabu' },
        { value: 'sun', label: 'Minggu' },
        { value: 'thu', label: 'Kamis' },
    ],
    weekOpt: [
        { value: 1, label: '1 Minggu sekali' }
    ],
    listTime: times,
    limitOpt: [
        { value: false, label: 'Menggunakan jangka waktu' }
    ],
    limitDay: [
        { value: 1, label: 'H-1' },
        { value: 2, label: 'H-2' },
        { value: 3, label: 'H-3' },
        { value: 4, label: 'H-4' },
        { value: 5, label: 'H-5' },
        { value: 6, label: 'H-6' },
        { value: 7, label: 'H-7' },
    ]
};

export default Config