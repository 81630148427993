import Api from '../../../../../../services/apis/UserApi'
import {
    handleToast,
    handleToastServiceError
} from '../../../../../../core/utils/UtilsToast'

const FormHandler = {
    buildForm() {
        this.emailField = this.TextInputEmailFormView({
            label: 'Email',
            validator: 'required',
            name: 'email',
            placeholder: 'Email',
            maxLength: 50,
            control: this.state.Form.controls.email
        })
    },
    handleSubmit: async function (e) {
        e.preventDefault();

        const formValue = Object.assign({}, this.state.Form.value)

        await Api.testingEmail(formValue).then((res) => {
            const {
                data: {
                    status,
                    message,
                },
            } = res

            if (status) {
                handleToast(message)
            } else {
                handleToastServiceError({ message })
            }
        }).catch(e => {
            handleToastServiceError(e)
        })
    }
}

export default FormHandler
