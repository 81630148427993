import React from 'react'
import ReactTable from 'react-table'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import { ContextMenuProvider } from 'react-contexify'

import SliderPanel from '../../../../../../components/wizard/SliderPanel'
import MasterModal from '../../../../../../core/utils/MasterModal'
import MasterGrid from '../../../../../../components/grid/MasterGrid'
import Form from './Form'
import GridHandler from '../../../../handler/superadmin/setting/title/GridHandler'

const CheckboxTable = checkboxHOC(ReactTable)

class Grid extends MasterGrid {
    constructor(props) {
        super(props)

        Object.assign(this, GridHandler)

        this.onPanelOpen = this.onPanelOpen.bind(this)
        this.onPanelClose = this.onPanelClose.bind(this)
        this.filterable = false
        this.selectedTableStatus = false
        this.gridColumn = this.gridColumn.bind(this)
        this.gridMapping = (this.selectedTableStatus) ? CheckboxTable : ReactTable
        this.hidePagination = false
        this.page = 0
        this.pageSize = 20
        this.sorted = []

        /* own property */
        this.fetchData = this.fetchData.bind(this)
        this.extraProperties = {
            onFetchData: this.fetchData,
            filterAble: true
        }

        /* data */
        const loading = (this.extraProperties.hasOwnProperty('onFetchData')) ? true : false
        const data = (this.dataFromRedux) ? this.props.data : ((loading) ? [] : this.getData())
        const columns = this.getColumns(data)
        const finalColumn = this.gridColumn(ContextMenuProvider, columns)
        const pages = (loading) ? null : 1

        this.filterSubmit = this.filterSubmit.bind(this)

        /* modal */
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.modalHandleSubmit = this.modalHandleSubmit.bind(this)

        this.handleDelete = this.handleDelete.bind(this)
        this.loadDataAfterAction = this.loadDataAfterAction.bind(this)

        this.state = {
            isPaneOpen: false,
            panelData: {},
            data,
            dataEdit: {},
            columns: finalColumn,
            selection: [],
            deleted: [],
            selectAll: false,
            loading: loading,
            pages: pages,
            filtered: [],
            isModalOpen: false,
            ModalData: null,
            filterKeyword: '',
        }
    }

    handleDelete = (data) => {
        const ModalData = {
            id: data.id,
            action: 'delete',
            content: `Konfirmasi hapus gelar <strong>${data.name}</strong>. <br />Apakah anda yakin?`,
            buttonConfirmText: 'Konfirmasi',
            buttonCancelText: 'Kembali',
        }

        this.handleOpenModal(ModalData)
    }

    loadDataAfterAction = () => {
        const state = {
            pageSize: this.pageSize,
            page: this.page,
            sorted: this.sorted,
            filter: '',
            filtered: this.state.filtered,
        }

        this.fetchData(state)
    }

    filterSubmit(filterKeyword) {
        const filtered = [{ value: filterKeyword }]
        const state = {
            pageSize: this.pageSize,
            page: 0,
            sorted: this.sorted,
            filter: '',
            filtered,
        }

        this.setState({
            filterKeyword,
            filtered,
            loading: true,
        })

        this.fetchData(state)
    }

    render() {
        const {
            dataEdit,
            isModalOpen,
            filterKeyword,
        } = this.state

        this.leftAction = [
            {
                type: 'search',
                title: 'Cari',
                filterKeyword,
                handlerSubmit: this.filterSubmit,
            }
        ]

        return (
            <div>
                <div className="title-wrapper">
                    <h2 className="no-margin-top page-title">{this.props.title}</h2>
                    <button onClick={() => this.onPanelOpen()} className="add">Tambah Gelar</button>
                </div>
                <section className="tables">
                    <div className="row">
                        <div className="col-lg-12 cn-table-container">
                            <div className="card">
                                {super.render()}
                            </div>
                        </div>
                    </div>
                </section>
                <SliderPanel
                    {...this.props}
                    open={this.state.isPaneOpen}
                    onPanelClose={this.onPanelClose}
                    panelData={this.state.panelData}>
                    <Form
                        dataEdit={dataEdit}
                        onPanelClose={this.onPanelClose}
                        loadDataAfterAction={this.loadDataAfterAction} />
                </SliderPanel>
                <MasterModal
                    className="master-modal"
                    isOpen={isModalOpen}
                    handleClose={this.handleCloseModal}
                    handleSubmit={this.modalHandleSubmit}
                    ModalData={this.state.ModalData} />
            </div>
        )
    }
}

export default Grid
