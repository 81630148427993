import React, { Component } from 'react'
import editIcon from '../../../../themes/v1/img/layout/icon/pencil.png'
import deleteIcon from '../../../../themes/v1/img/layout/icon/trash.png'
import plusIcon from '../../../../themes/v1/img/layout/icon/plus.png'
import dateFns from "date-fns"
import Api from '../../../../services/apis/MedicalServices/ServiceSchedule'

export default class ScheduleList extends Component{
    constructor(props){
        super(props)
        this.state = {
            selectedDate: this.props.selectedDate,
            day: dateFns.format(this.props.selectedDate, 'ddd').toLowerCase(),
            serviceId: this.props.serviceId,
            hospitalId: this.props.hospitalId,
            loadingDay: true,
            scheduleList: [],
            isFetching: false
        }
        this.fetchScheduleList = this.fetchScheduleList.bind(this)
        this.renderList = this.renderList.bind(this)
    }

    fetchScheduleList(selectedDate){
        const serviceId = this.state.serviceId
        const day = this.state.day
        const year = dateFns.format(selectedDate, 'YYYY')
        const month = dateFns.format(selectedDate, 'M')
        const dayNum = dateFns.format(selectedDate, 'D')
        const Schedule = Api.get(serviceId, year, month, day)

        Schedule.then(response => {
            if(response && response.data){
                const data = response.data.data ? response.data.data: []
                this.setState({
                    scheduleList: Object.keys(data).length > 0 ?  data[year][month][dayNum][0] : [],
                    loadingDay: false,
                })
            }
        })
    }

    componentWillUnmount(){
        this.setState({
            scheduleList: []
        })
    }

    componentDidUpdate(nextProps){
        if(this.state.isFetching !== nextProps.isFetching && nextProps.isFetching){
            const selectedDate = this.state.selectedDate
            setTimeout(() => {
                this.fetchScheduleList(selectedDate)
            }, 700);
        }
    }

    openDeleteModal(schedule){
        const ModalData = {
            action: "delete_schedule",
            id: schedule["id"],
            content: "Anda akan menghapus data ini. <br />Apakah Anda Yakin?",
            buttonConfirmText: "Ya, Hapus Data",
            buttonCancelText: "Batalkan",
            ConfirmFunction: this.deleteSchedule
        }
        this.props.openModal(ModalData)
    }

    renderList() {
        const scheduleList = this.state.scheduleList
        const serviceData = this.props.serviceData
        const idToDelete = this.props.idToDelete
        const estimationTime = serviceData && serviceData["estimation_time"] >= 15
            ? serviceData["estimation_time"]
            : 15

        return (
            <div className="schedule-list">
                {
                    scheduleList.map((schedule, index) => (
                        <div
                            className={
                                `row-schedule ${schedule['id'] === idToDelete
                                    ? 'animated slideOutRight'
                                    : ''}`
                            }
                            key={index}
                        >
                            <div className="wrap-time">
                                <span className="text-title">Waktu</span>
                                <span>{schedule['time_start'].slice(0, -3)} - {schedule['time_end'].slice(0, -3)}</span>
                            </div>
                            <div className="wrap-info">
                                <div className="col-info">
                                    <span className="text-title">Estimasi Penanganan</span>
                                    <span>{estimationTime} menit / Pasien</span>
                                </div>
                                <div className="col-info">
                                    <span className="text-title">Kuota</span>
                                    <span>{schedule['max_patient_count']} Pasien</span>
                                </div>
                                <div className="col-info">
                                    <span className="text-title">Booking</span>
                                    <span>{schedule['video_call'] ? 'Video Call' : 'Offline'}</span>
                                </div>
                            </div>
                            <div className="wrap-action">
                                <button className="edit">
                                    <img src={editIcon} alt="edit" onClick={() => this.props.toggleForm(schedule["id"])} />
                                </button>
                                <button className="delete" onClick={() => this.openDeleteModal(schedule)}>
                                    <img src={deleteIcon} alt="delete" />
                                </button>
                            </div>
                        </div>
                    ))
                }

                <button className="add-schedule" onClick={ this.props.toggleForm }>
                    <img src={plusIcon} alt="add" />
                    <span>Tambah Jadwal</span>
                </button>
            </div>
        )
    }

    componentDidMount() {
        const selectedDate = this.state.selectedDate
        this.fetchScheduleList(selectedDate)
    }

    render() {
        return (
            this.renderList()
        )
    }
}