import React, { Component } from 'react'
import MasterTemplate from '../../../template/MasterTemplate'
import DoctorForm from './components/Form'

class Add extends Component
{
    constructor(props){
        super(props)
        this.wizard = true
        this.cancelUrl = '/doctor/list'
        this.backUrl = '/doctor/findstr'
        
        let breadcrumbData = [
            {
                no: '1',
                label: 'Informasi NaKes',
                finished: false,
                path: '/doctor/add',
                onClick: e => this.handleInactive(e)
            },
            {
                no: '2',
                label: 'Atur Jadwal',
                path: '/doctor/add/schedule',
                onClick: e => this.handleInactive(e)
            }
        ]

        this.breadcrumbData = breadcrumbData
    }

    handleInactive(event){
        event.preventDefault()
    }

    render() {
        return(
            <MasterTemplate {...this.props} 
                backUrl={this.backUrl} 
                wizard={this.wizard} 
                cancelUrl={this.cancelUrl} 
                breadcrumbData={this.breadcrumbData} 
            >
                <DoctorForm {...this.props} />
            </MasterTemplate>
        )
    }
}

export default Add