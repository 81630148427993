/* eslint-disable */

import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import connect from 'react-redux/es/connect/connect'
import { Navbar } from 'reactstrap'

import {
    loginMapDispatchToProps, 
    loginMapStateToProps
} from '../core/const/LoginConst'
import Breadcrumbs from './partials/Breadcrumbs'
import SideBarTemplate from './partials/SideBarTemplate'
import TitleTemplate from './partials/TitleTemplate'
import NavQuickMenu from './partials/NavQuickMenu'
import KlikDokterLogo from '../themes/v1/img/layout/logo/klikdokter.png'
import RoleHelper from '../core/helper/RoleHelper'
import HospitalFilter from '../components/grid/filter/HospitalFilter'

/** ----------------- Start --------------------- **/

class HeadTemplate extends Component
{
    constructor(props) {
        super(props)

        this.state = {
            isOpen: false,
            isToggleButton: false,
            visitedOverlay: false,
            isAdjustFooter: false,
        }

        this.handleToggleButton = this.handleToggleButton.bind(this)
        this.handleHoverMenu = this.handleHoverMenu.bind(this)
    }

    handleToggleButton(){
        this.setState(state => ({
            isToggleButton: !state.isToggleButton,
            isAdjustFooter: true
        }))

        try {
            localStorage.setItem('isToggleButton', !this.state.isToggleButton)
        } catch (e) {
            console.log('Local Storage is disabled');
        }
    }

    handleHoverMenu(visited){
        if(visited){
            this.setState({
                visitedOverlay: true
            })
        }else{
            this.setState({
                visitedOverlay: false
            })
        }

        try {
            localStorage.setItem('visitedOverlay', visited)
        } catch (e) {
            console.log('Local Storage is disabled');
        }
    }

    componentDidMount(){
        let isToggleButtonStorage = true
        let visited = false

        try {
            isToggleButtonStorage = localStorage.getItem('isToggleButton')
            visited = localStorage.getItem('visitedOverlay')
        } catch (e) {
            console.log('Local Storage is disabled');
        }

        if(isToggleButtonStorage !== 'false'){
            this.setState({
                isToggleButton: true
            })
        }

        if(visited === 'true'){
            this.setState({
                visitedOverlay: true
            })
        }
    }

    render() {
        const {
            dataLogin,
            currentHospitalLogo,
            currentHospital,
            detail,
            title,
        } = this.props

        const hospitals = dataLogin.hospitals
        const hospitalData = hospitals.find(host => host.id === currentHospital)
        const hospitalLogo = currentHospitalLogo
        const hospitalLName = hospitalData ? hospitalData.name : ''
        const visitedOverlay = this.state.visitedOverlay
        const isToggleButton = this.state.isToggleButton
        const isDoctor = RoleHelper.isDoctor(dataLogin, currentHospital)
        let auth = {
            id: null, email: null, name: null
        }
        let placeholder = null

        if (dataLogin) {
            const { email, name, id } = dataLogin
            auth = {
                id: id, email: email, name: name
            }
        }

        if (detail === true) {
            placeholder = <div style={{ height: 54 }} />
        } else {
            placeholder = <TitleTemplate title={title} />
        }

        let activeSeparator = visitedOverlay && isToggleButton ? 'active' : 'unactive'
        const shrinked = isToggleButton ? 'shrinked' : ''

        return (
            <div>
                <header className="header">
                    <Navbar>
                        <div className="container-fluid">
                            <div className="navbar-holder d-flex align-items-center justify-content-between">
                                <div className="navbar-header">
                                    {
                                        isDoctor && hospitals.length > 1 ? 
                                        <div>
                                            <a
                                                className={'menu-btn'}
                                                onClick={() => {
                                                    return this.handleToggleButton()
                                                }}>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </a>
                                            <HospitalFilter { ...this.props } />
                                        </div> :
                                        <div>
                                            <div className="navbar-brand d-sm-inline-block">
                                                <div className="brand-text d-lg-inline-block">
                                                    <div className="brand-link with-pic">
                                                        <div className="menu-center-wrapper">
                                                            <div className="menu-center">
                                                                <a
                                                                    className={'menu-btn'}
                                                                    onClick={() => {
                                                                        return this.handleToggleButton()
                                                                    }}>
                                                                    <span></span>
                                                                    <span></span>
                                                                    <span></span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <Link to="/dashboard">
                                                            <div className="img-wrapper">
                                                                <img
                                                                    src={hospitalLogo || KlikDokterLogo}
                                                                    alt="logo"
                                                                    className="Bitmap" />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <span>{hospitalLName}</span>
                                        </div>
                                    }
                                </div>

                                <NavQuickMenu { ...this.props } auth={auth} />
                            </div>
                        </div>
                    </Navbar>
                </header>
                <div className={ `page-content d-flex align-items-stretch` }>
                    <SideBarTemplate
                        { ...this.props }
                        history={this.props.history} 
                        isToggleButton={ this.state.isToggleButton } 
                        location={this.props.location} 
                        auth={auth}
                        handleHoverMenu={ this.handleHoverMenu }
                        visitedOverlay={ visitedOverlay }
                    />
                    <div className={ `separator-content ${ shrinked } ${ activeSeparator }` }></div>
                    <div className={this.state.isToggleButton ? 'content-inner active' : 'content-inner '}>
                        <Breadcrumbs {...this.props} className="spv" />
                        {placeholder}
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(HeadTemplate)