import React from 'react'
import ReactTable from "react-table"
import GridHandler from '../../handler/GridPatientHandler'
import MasterGrid from '../../../../components/grid/MasterGrid'
import { ContextMenuProvider } from 'react-contexify'
import MessageBox from '../../../../core/utils/UtilsStrapModal'
import { AclHandler } from '../../../../core/const/AclConst'

class GridPatient extends MasterGrid {
    constructor(props){
        super(props)

        Object.assign(this, MessageBox)
        Object.assign(this, GridHandler)
        Object.assign(this, AclHandler)

        this.aclModule = 'Appointment'
        this.createUrl = 'test'

        this.keyTable = '_id'
        this.ignoreColumn = [
            '_id'
        ]
        this.selectedTableStatus = false
        this.gridColumn = this.gridColumn.bind(this)
        this.pageSize = 5
        this.hidePagination = true
        this.page = 0
        this.sorted = []
        this.gridMapping = ReactTable
        /** own property */
        this.fetchData = this.fetchData.bind(this)
        this.refreshData = this.refreshData.bind(this)
        this.extraProperties = {
            onFetchData: this.fetchData,
            filterAble: false
        }
        this.can = this.can.bind(this)

        const loading = (this.extraProperties.hasOwnProperty('onFetchData')) ? true : false
        const data = (this.dataFromRedux) ? this.props.data : ((loading) ? [] : this.getData())
        const columns = this.getColumns(data)
        const finalColumn = this.gridColumn(ContextMenuProvider, columns)
        const pages = (loading) ? null : 1
        this.state = {
            data,
            columns: finalColumn,
            selection: [],
            loading: loading,
            pages: pages,
            filtered: [],
            dataProvider: null
        }
    }

    componentWillReceiveProps(nextProps) {
        let filterData = []
        if (this.props.patientFilter !== nextProps.patientFilter) {
            const patientFilter = nextProps.patientFilter
            for (let key in patientFilter) {
                if (patientFilter[key] !== null && patientFilter[key] !== '') {
                    filterData.push({
                        id: key,
                        value: key === 'dob'
                            ? this.dateToString(patientFilter[key])
                            : patientFilter[key]
                    })
                }
            }

            this.setState({
                filtered: filterData
            })

            this.fetchData({
                page: this.page,
                pageSize: this.pageSize,
                filtered: filterData,
                sorted: this.sorted,
            })
        }
    }

    render() {
        return (
            <div className="grid-result-search">
                <section className="tables">
                    <div className="container-fluid">
                        <div className="row">
                            {this.MessageBox}
                            <div className="col-lg-12 cn-table-container">
                                <div className="card">
                                    {super.render()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default GridPatient