/* eslint-disable */

import React, { Component } from 'react'
import connect from 'react-redux/es/connect/connect'
import PulseLoader from 'react-spinners/PulseLoader'
import moment from 'moment'
import dateFns from 'date-fns'
import IndonesianLocale from 'date-fns/locale/id'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import { loginMapDispatchToProps, loginMapStateToProps } from '../../core/const/LoginConst'
import ProfilePic from '../../components/lib/ProfilePic'
import RoleHelper from '../../core/helper/RoleHelper'
import { messaging } from '../../init-fcm'
import Api from '../../services/apis/Appointment/List'
import { handleToastServiceError } from '../../core/utils/UtilsToast'
import bellIcon from '../../themes/v1/img/bell.png'
import dotIcon from '../../themes/v1/img/dot.png'
import arrowDown from '../../themes/v1/img/layout/icon/ico-arrow-down.png'

/** ----------------- Start --------------------- **/

class NavQuickMenu extends Component {
    constructor(props) {
        super(props);

        this.date = new Date()
        this.isSuperadmin = RoleHelper.isSuperadmin(props.dataLogin)

        this.state = {
            toggleMoreMenu: false,
            toggleNotif: false,
            picLogo: true,
            logoutProcess: false,
        }

        this.toggle = this.toggle.bind(this)
        this.toggleNotif = this.toggleNotif.bind(this)
        this.onLogout = this.onLogout.bind(this)
        this.handleErrorPic = this.handleErrorPic.bind(this)
        this.dateToFromNowDaily = this.dateToFromNowDaily.bind(this)
        this.toAppointmentDetail = this.toAppointmentDetail.bind(this)
    }

    handleErrorPic() {
        this.setState({
            picLogo: false
        })
    }

    onLogout() {
        const me = this

        if (messaging) {
            messaging.requestPermission()
                .then(async function () {
                    me.setState({ logoutProcess: true })

                    await messaging.deleteToken()
                    const token = await messaging.getToken()

                    if (token) {
                        me.setState({ logoutProcess: false })
                        me.props.onRequestLogout()

                        try {
                            localStorage.setItem('fcmT', token)
                        } catch (e) {
                            console.log('Local Storage is disabled');
                        }
                    }
                })
                .catch(function (err) {
                    me.props.onRequestLogout()
                });
        } else {
            me.props.onRequestLogout()
        }
    }

    toggle(e) {
        this.setState({
            toggleMoreMenu: !this.state.toggleMoreMenu
        })
    }

    toggleNotif() {
        this.setState({ toggleNotif: !this.state.toggleNotif })
    }

    dateToFromNowDaily(myDate) {
        const fromNow = moment(myDate).fromNow();

        return moment(myDate).calendar(null, {
            sameDay: function () {
                return "[" + fromNow + "]";
            },
            lastDay: '[Kemarin]',
            sameElse: 'D MMMM YYYY',
        });
    }

    toAppointmentDetail(n) {
        const { history, currentHospital } = this.props

        Api.notificationRead(currentHospital, n.id)
            .then(({ data: dataRes }) => {
                const { status, message } = dataRes

                if (status) {
                    history.push({
                        pathname: '/appointment/list',
                        search: `?id=${n.book_id}`,
                    })
                } else {
                    handleToastServiceError({ message })
                }
            })
            .catch(err => {
                handleToastServiceError(err)
            });
    }

    render() {
        const { logoutProcess } = this.state
        const { notifAppointment } = this.props
        const isUnread = notifAppointment && notifAppointment.length > 0
            ? notifAppointment.filter(n => n.read === false).length > 0
            : false

        return (
            <div className="nav-left">
                {!this.isSuperadmin
                    ? (
                        <Dropdown isOpen={this.state.toggleNotif} toggle={this.toggleNotif}>
                            <DropdownToggle tag="a" onClick={this.toggleNotif}>
                                <img src={bellIcon} alt="Notification" className="bell" />
                                {isUnread && <img src={dotIcon} alt="Dot" className="dot" />}
                            </DropdownToggle>
                            <DropdownMenu tag="ul" className="list-notif">
                                {notifAppointment && notifAppointment.length > 0
                                    ? notifAppointment.map(notif => (
                                        <li key={notif.id} onClick={() => this.toAppointmentDetail(notif)}>
                                            <div className="date">
                                                {this.dateToFromNowDaily(notif.created_at)}
                                            </div>
                                            <div className={`title${notif.read ? ' read' : ''}`}>
                                                {notif.message}
                                            </div>
                                            <div className="content">
                                                {`Oleh ${notif.patient_name} untuk ${dateFns.format(
                                                    notif.book_date,
                                                    'D MMMM YYYY',
                                                    { locale: IndonesianLocale }
                                                )} dengan ${notif.doctor_title} ${notif.doctor_name}`}
                                            </div>
                                        </li>
                                    ))
                                    : <li className="notif-empty">Tidak ada notifikasi</li>
                                }
                            </DropdownMenu>
                        </Dropdown>
                    )
                    : null}
                <div className="quick-menu">
                    {
                        this.state.picLogo ?
                            <ProfilePic
                                className="logo-pp"
                                source={this.props.currentDoctorPic}
                                alt="profile pic"
                                onError={this.handleErrorPic} /> :
                            <div className="Oval"></div>
                    }
                    <div className="user-info-wrap unselectable">
                        <span className="user-info-name">{this.props.auth.email}</span>
                        <span className="user-info-label">{this.props.auth.name}</span>
                    </div>
                    <Dropdown
                        className="more-menu user-info-dropdown"
                        isOpen={this.state.toggleMoreMenu}
                        toggle={this.toggle}>
                        <DropdownToggle
                            tag="a"
                            onClick={this.toggle}
                            className={"more-button"}
                        >
                            <img src={arrowDown} alt="view more button" />
                        </DropdownToggle>
                        <DropdownMenu tag="ul" className="list-more">
                            <li>
                                <a onClick={this.onLogout} className="logout">
                                    Keluar
                                    <div className="loading">
                                        <PulseLoader
                                            sizeUnit={"px"}
                                            size={6}
                                            color={'#123abc'}
                                            loading={logoutProcess}
                                        />
                                    </div>
                                </a>
                            </li>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
        );
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(NavQuickMenu)
