import React from 'react'
import validateReturn from '../../ValidatorForm'

import {FieldControl} from "react-reactive-form"

const TextInput = {
    TextInputView: function({label, validator, name, meta, control, inputType, fieldControlAdditional}) {
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}

        Object.assign(control, additionalController)
        const callBack = meta ? meta.callBack : null

        return <FieldControl
            name={name}
            render={(obj) => this.TextInputRender(obj, name, callBack)}
            meta={{ label: label, name: name, validator: validator, inputType: inputType || 'text' }}
            control = { control }
        />
    },
    onChangeEvent: function (e, onChangeEvent, name, callBack) {
        onChangeEvent(e)
        if (callBack) {
            callBack(this, name)
        }
    },
    TextInputRender: function({handler, touched, hasError, meta}, name, callback) {
        return (
            <div className={touched && hasError(meta.validator) ? "form-line has-danger" : "form-line"}>
                <div className="form-group">
                    <input
                        min="1"
                        type={meta.inputType}
                        className="form-control form-control-success"
                        id={`${meta.label}`}
                        {...handler()}
                        onChange={(e) => this.onChangeEvent(e, handler().onChange, name, callback)}
                    />
                </div>
                <span className="error-info">
                    {
                        touched
                        && hasError(meta.validator)
                        && validateReturn(meta.validator)
                    }
                </span>
            </div>
        )
    }
}

export default TextInput
