import React from 'react'
import _ from 'lodash'
import validateReturn from '../ValidatorForm'

import { FieldControl } from "react-reactive-form";

const TextAreaForm = {
    TextAreaFormView: function ({
        label,
        placeholder,
        validator,
        name,
        readonly,
        control,
        fieldControlAdditional,
    }) {
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}
        Object.assign(control, additionalController);

        return <FieldControl
            name={name}
            render={(obj) => this.TextAreaForm(obj)}
            meta={{
                label: label,
                placeholder: placeholder,
                validator: validator,
                name: name,
                readonly: readonly,
            }}
            control={control}
        />
    },

    TextAreaForm: function ({ handler, touched, hasError, meta }) {
        const isRequired = meta.validator === 'required' ? true : false
        const showInfo = !_.isEmpty(this.state.Form.controls.show_in_apps)
            ? this.state.Form.controls.show_in_apps.value
            : false
        const value = _.isEmpty(this.state.Form.controls[meta.name].value)

        return (
            <div
                className={touched && hasError(meta.validator)
                    ? "form-line has-danger"
                    : "form-line"}
            >
                {meta.label ?
                    <label
                        id={`label-${meta.name}`}
                        className="form-control-label"
                        htmlFor={`${meta.name}`}
                    >
                        {meta.label}
                        {isRequired ? <span className="required">*</span> : null}
                    </label>
                    : null}
                <div className="form-group">
                    <textarea 
                        className="form-control"  
                        id={meta.name}
                        placeholder={ meta.placeholder }
                        readOnly={ meta.readonly }
                        {...handler() } />
                </div>
                {showInfo && value
                    ? <span className="error-info">Data harus diisi</span>
                    : <span className="error-info">
                        {touched
                            && hasError(meta.validator)
                            && validateReturn(meta.validator)
                        }
                    </span>
                }
            </div>
        )
    }
}

export default TextAreaForm
