import axios, { axiosBase, axiosMaster, axiosSuperadmin } from '../MasterConfig'

const axiosB = axiosBase()
const axiosM = axiosMaster()
const axiosS = axiosSuperadmin()

const groupUrl = 'doctor'

class DoctorApi {
    static get = (
        page,
        pageSize,
        sortProperties,
        sortValue,
        filterData,
        idHospital,
        isDashboard,
    ) => {
        let setSort = ''
        if (sortProperties) {
            setSort = `&sort=${sortProperties}&order=${sortValue}`
        }

        let setFilter = ''
        if (filterData !== '{}') {
            setFilter = `&filter=${filterData}`
        }

        if (!isDashboard) {
            return axiosB.get(`/hospital/${groupUrl}?id_hospital=${idHospital}&page=${page}${setSort}&limit=${pageSize}${setFilter}`)
        }

        return axiosM.get(`/doctor?page=${page}${setSort}&limit=${pageSize}${setFilter}`)
    }
    static detail = (id, isDashboard) => {
        if (!isDashboard) {
            return axiosB.get(`/hospital/${groupUrl}/${id}`)
        }

        return axiosS.get(`/${groupUrl}/${id}`)
    }
    static delete = (id, isDashboard) => {
        if (!isDashboard) {
            return axiosB.delete(`/hospital/${groupUrl}/${id}`)
        }

        return axiosS.delete(`/${groupUrl}/${id}`)
    }
    static bulkDelete = (data, isDashboard) => {
        if (!isDashboard) {
            return axios.delete(`/${groupUrl}/bulk`, { data })
        }

        return axiosS.delete(`/${groupUrl}/bulk`, { data })
    }
    static reject = (idDoctor, data) => axiosS.put(`/doctorreject/${idDoctor}`, data)
    static post = (data) => axiosB.post(`/hospital/${groupUrl}`, data)
    static put = (idDoctor, data) => axiosB.put(`/hospital/${groupUrl}/${idDoctor}`, data)
    static update = (id, data) => axios.put(`/${groupUrl}/update/${id}`, data)
    static updateMaster = (id, data) => axiosB.put(`/master/doctor/${id}`, data)
    static bulkDownload = (data) => axios.post(`/${groupUrl}/download`, data)
    static getSpecialist = () => axios.get(`/${groupUrl}/specialist/`)
    static checkstr = (strNo, hospitalId) => axios.get(`/${ groupUrl }/checkstr/${ hospitalId }/${ strNo }`)
    static reactivate = (data) => axios.post(`/${ groupUrl }/reactivate`, data)
    static reconfirm = (data) => axios.post(`/${ groupUrl }/reconfirm`, data)
    static listApotek = () => axiosB.get(`/lists/pharmacy`)
    // static listApotek = () => axiosB.get(`/m/lists/apotek`)
    static listApotekEdit = (code) => axiosB.get(`/lists/pharmacy?search=${code}`)
    static detailMaster = (id) => axiosB.get(`/master/doctor/${id}`)
    static doctorFilter = () => axiosM.get('/doctor/filter')
    static doctorFilterHospital = (hid) => axiosB.get(`/hospital/${groupUrl}/filter?hospital_id=${hid}`)
    static getFilterExport = () => axiosB.get(`/dashboard/${groupUrl}/filterexport`)
    static download = (filter) => axiosB.get(`/dashboard/${groupUrl}/export?filter=${filter || ''}`)
}
export default DoctorApi