import React, { Component } from 'react'
import Icons from '../../../../core/utils/Icons'
import { Button, Container } from 'reactstrap'
import { ToastContainer } from 'react-toastify'
import { connect } from 'react-redux'
import { loginMapDispatchToProps, loginMapStateToProps } from '../../../../core/const/LoginConst'
import { Redirect } from 'react-router-dom'

class DoctorConfirmSuccess extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            redirectTo: null
        }

        this.handleRedirectLogin = this.handleRedirectLogin.bind(this)
    }

    handleRedirectLogin(){
        this.setState({
            redirectTo: '/'
        })
    }

    render() {
        const { redirectTo } = this.state

        if(redirectTo){
            return(<Redirect to={ redirectTo } />)
        }
        return(
            <div className="login-page-cinnamon">
                <ToastContainer
                    position="top-center"
                    autoClose={ 5000 }
                    hideProgressBar={ false }
                    newestOnTop={ false }
                    closeOnClick
                    rtl={ false }
                    pauseOnVisibilityChange
                />
                <Container className="d-flex align-items-center">
                    <div className="form-login-cinnamon">
                        <div className="row">
                            <div className="wrapper-logo">
                                <img className="login-logo" src={ Icons.logoLogin } alt="Login" />
                            </div>
                            <div className="wrap-login-center wrap-confirmation">
                                <div className="login-note">
                                    <h3>Konfirmasi Berhasil</h3>
                                    <p>
                                        Akun dokter anda telah berhasil dikonfirmasi
                                        Silahkan klik tombol dibawah ini untuk login kembali
                                    </p>
                                </div>
                                <div className="wrap-button-auth"> 
                                    <Button
                                        type="submit"
                                        className="btn btn-primary login-button"
                                        onClick={ this.handleRedirectLogin }
                                    >
                                        Login
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(DoctorConfirmSuccess)