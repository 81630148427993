import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { loginMapDispatchToProps, loginMapStateToProps } from '../../core/const/LoginConst'
import { messaging } from '../../init-fcm'

class Logout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            redirect: false,
        }
    }

    componentDidMount() {
        const me = this

        if (messaging) {
            messaging.requestPermission()
                .then(async function () {
                    await messaging.deleteToken()
                    const token = await messaging.getToken()
    
                    if (token) {
                        me.setState({ redirect: true })
                        me.props.onRequestLogout()
                        
                        try {
                            localStorage.setItem('fcmT', token)
                        } catch (e) {
                            console.log('Local Storage is disabled');
                        }
                    }
                })
                .catch(function (err) {
                    me.setState({ redirect: true })
                    me.props.onRequestLogout()
                });
        } else {
            me.setState({ redirect: true })
            me.props.onRequestLogout()
        }
    }

    render() {
        const { redirect } = this.state

        if (redirect) {
            return <Redirect to="/" />
        }

        return null
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Logout)