import React, {Component} from 'react'
import DashboardTemplate from '../../template/DashboardTemplate'
import Grid from './components/Grid'
import MasterModal from '../../core/utils/MasterModal'
import Form from './components/Form'
import UploadHospitalForm from './components/UploadHospitalForm'
import mainHandler from './handler/Main'
import SummaryContainer from './components/superadmin/SummaryContainer'
import ButtonUpload from './components/superadmin/ButtonUpload'
import Filters from './components/superadmin/Filters'
import RoleHelper from '../../core/helper/RoleHelper'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../core/const/LoginConst'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

class DashboardMain extends Component
{
    constructor(props) {
        super(props)

        this.showDownloadMenu = this.showDownloadMenu.bind(this)
        this.toggleAdd = this.toggleAdd.bind(this)
        this.isSuperadmin = RoleHelper.isSuperadmin(props.dataLogin)

        //handler
        Object.assign(this, mainHandler)
        this.fetchDashboardData = this.fetchDashboardData.bind(this)
        this.fetchHospital = this.fetchHospital.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.modalHandleSubmit = this.modalHandleSubmit.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.parseModal = this.parseModal.bind(this)
        this.parseInstitution = this.parseInstitution.bind(this)
        this.updateFilterType = this.updateFilterType.bind(this)
        this.showLoading = this.showLoading.bind(this)
        this.finishLoading = this.finishLoading.bind(this)
        this.pushRedirectTo = this.pushRedirectTo.bind(this)
        this.afterRenderHospital = this.afterRenderHospital.bind(this)

        this.state = {
            dashboardDate: null,
            isFetching: true,
            count: {
                doctor: 0,
                clinic: 0,
                hospital: 0,
            },
            dataHospital: [],
            hospitalCopied: false,
            cityList: [],
            facilityList: [],
            typeList: [],
            toggleMenu: false,
            isModalOpen: false,
            modalType: null,
            redirectTo: null,
            allType: null,
            pageSize: 20,
            institutionList: []
        }
    }

    pushRedirectTo(pathUrl){
        this.setState({
            redirectTo: pathUrl
        })
    }

    componentDidMount() {
        const { pageSize } = this.state

        this.fetchDashboardData();
        this.fetchHospital(pageSize);
    }

    showLoading() {
        this.setState({
            isFetching: true
        })
    }

    finishLoading() {
        this.setState({
            isFetching: false
        })
    }

    showDownloadMenu(){
        if(!this.state.toggleMenu){
            return
        }

        return(
            <ButtonUpload handleOpenModal={ this.handleOpenModal }/>
        )
    }
    toggleAdd(event){
        event.preventDefault()
        this.setState({
            toggleMenu: !this.state.toggleMenu
        })
    }

    parseModal(type){
        switch(type){
            case 'add_hospital':
                return(
                    <Form onCancel={this.handleCloseModal} onSave={this.modalHandleSubmit} {...this.props} institutionList={this.state.institutionList}/>
                )
            case 'bulk_hospital':
                return(
                    <UploadHospitalForm onCancel={this.handleCloseModal} onSave={this.modalHandleSubmit} {...this.props} />
                )
            default:
                return(
                    <h1>Default modal type</h1>
                )
        }
    }

    afterRenderHospital(){
        this.setState({
            hospitalCopied: true,
            dataHospital: []
        })
    }

    render() {
        const {
            modalType,
            pageSize,
            dashboardDate,
            count,
            toggleMenu,
            redirectTo,
            dataHospital,
            isFetching,
            hospitalCopied,
            allType,
            facilityList,
            typeList,
            cityList,
        } = this.state

        if (!this.isSuperadmin) {
            return <Redirect to="/dashboardhospital" />
        } else if (redirectTo) {
            return <Redirect to={redirectTo} />
        }

        return (
            <DashboardTemplate {...this.props} >
                <div className="dashboard-container">
                    <SummaryContainer
                        dashboardDate={dashboardDate}
                        count={count} />
                    <div className="middle-row">
                        <div className="middle-left">
                            <span>Data Rumah Sakit</span>
                        </div>
                        <Filters
                            toggleAdd={this.toggleAdd}
                            toggleMenu={toggleMenu}
                            showDownloadMenu={this.showDownloadMenu}
                        />
                    </div>
                    <Grid
                        onTypeChange={this.updateFilterType}
                        showLoading={this.showLoading}
                        finishLoading={this.finishLoading}
                        pushRedirectTo={this.pushRedirectTo}
                        count={count}
                        dataHospital={dataHospital}
                        isFetching={isFetching}
                        afterRenderHospital={this.afterRenderHospital}
                        hospitalCopied={hospitalCopied}
                        allType={allType}
                        facilityList={facilityList}
                        typeList={typeList}
                        cityList={cityList}
                        pageSize={pageSize}
                        {...this.props}
                    />
                </div>
                <MasterModal
                    className="master-modal"
                    isOpen={this.state.isModalOpen}
                    handleClose={this.handleCloseModal}>
                    {this.parseModal(modalType)}
                </MasterModal>
            </DashboardTemplate>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(DashboardMain)
