import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FieldBox from './FieldBox'

class CustomFieldContainer extends React.Component {
  constructor(props){
    super(props)
    this.reorder = this.reorder.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
  
    return result
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.props.builderData,
      result.source.index,
      result.destination.index
    )

    this.props.mutateBuilderData(items)
  }

  render() {
    const title = this.props.formData.title
    return (
      <div className="form-custom-builder">
        <div className={(title === "") ? "row invalid" : "row" }>
          <label className="builder-label">Title</label>
          <input 
            className="field-title" 
            type="text" 
            name="form-name" 
            placeholder="Masukkan Title Disini" 
            maxLength={30} 
            onChange={this.props.handleChangeTitle}
            value={ title }
          />
          <span className={(title === "") ? "" : "no-display" }>Data harus diisi</span>
        </div>
        <div className="wrap-chosen-field">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                >
                  {this.props.builderData.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <FieldBox fields={item} {...this.props}/>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    )
  }
}

export default CustomFieldContainer