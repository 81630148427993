import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from 'react-reactive-form'

import SubmitButtonForm from '../../../../core/utils/SubmitButtonForm'
import TextInputForm from '../../../../core/utils/wizard/TextInputForm'
import TextInputEmailForm from '../../../../core/utils/forminput/TextInputEmailForm'
import TextInputPriceForm from '../../../../core/utils/forminput/TextInputPriceForm'
import TextAreaForm from '../../../../core/utils/wizard/TextAreaForm'
import DropDownForm from '../../../../core/utils/wizard/DropDownForm'
import DropDownFormApotek from '../../../../core/utils/wizard/DropDownFormApotek'
import ImageUploadForm from '../../../../core/utils/ImageUploadForm'
import ImageUploadFormWithCrop from '../../../../core/utils/ImageUploadFormWithCrop'
import RadioInputForm from '../../../../core/utils/wizard/RadioInputForm'
import DatePickerForm from '../../../../core/utils/wizard/DatePickerForm'
import StrNoInputForm from '../../../../core/utils/doctor/TextInputForm'
import SwitchForm from '../../../../core/utils/wizard/SwitchForm'
import AdditionalInputForm from '../../../../core/utils/doctor/AdditionalInputForm'
import InputBox from '../../../../core/utils/doctor/UtilsStrapInputModal'
import DoctorFormHandler from '../handler/FormHandler'
import iconPlus from '../../../../themes/v1/img/rectangle.png'
import ArrowDown from '../../../../themes/v1/img/layout/icon/ico-arrow-down.png'
import Config from '../config/DataConfig'
import MasterModal from '../../../../core/utils/MasterModal'
import ModalContent from './ModalContent'
import ModalNotifyContent from './ModalNotifyContent'
import RoleHelper from '../../../../core/helper/RoleHelper'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../../../core/const/LoginConst'
import RichEditorInputForm from '../../../../core/utils/RichEditorInputForm'
import SearchSelectFilterApotek from '../../../../components/grid/actions/SearchSelectFilterApotek'

const inputStyle = {
    height: '100px',
    width: '100%'
}

class Form extends Component
{
    constructor(props) {
        super(props)
        Object.assign(this, TextInputForm)
        Object.assign(this, TextInputEmailForm)
        Object.assign(this, TextAreaForm)
        Object.assign(this, DropDownForm)
        Object.assign(this, DropDownFormApotek)
        Object.assign(this, RadioInputForm)
        Object.assign(this, DatePickerForm)
        Object.assign(this, ImageUploadForm)
        Object.assign(this, InputBox)
        Object.assign(this, StrNoInputForm)
        Object.assign(this, SwitchForm)
        Object.assign(this, TextInputPriceForm)

        const isAdminSPV = RoleHelper.isAdminSPV(props.dataLogin, this.isOwner(props.currentHospital))
        this.isAdminSPV = isAdminSPV ? true : false
    
        this.state = {
            redirectTo: null,
            name: "",
            title: "dr.",
            telephone: "",
            id_doctor_specialist: "",
            educations: [],
            experiences: [],
            medical_actions: [],
            str_no: "",
            str_no_expired:"",
            sip_no_expired:"",
            estimation_time: 0,
            can_book: "",
            address: "",
            status: true,
            isEdit: false,
            postStatus: false,
            loading: false,
            prefixOptions: [],
            prefixValue: "",
            estimationOptions: Config.estimationOptions,
            specialistOption: [],
            foto:"",
            Form: FormBuilder.group({
                id: [""],
                name: ["", Validators.required],
                gender: ["M"],
                dob: [""],
                title: ["dr."],
                telephone: [""],
                email: ["", Validators.email],
                id_doctor_specialist: ["", Validators.required],
                educations: [""],
                experiences: [""],
                photo: [""],
                str_no: [""],
                sip_no: [""],
                estimation_time: [0],
                can_book: [false],
                order_from_chat: [false, Validators.required],
                address: [""],
                profile: [""],
                apotek: [""],
                propinsi: ["-"],
                kota: [""],
                kecamatan: [""],
                status: [0],
                chat_status: [false],
                show_in_apps: [false],
                video_call: [false],
                chat_type: [Config.chatType[0].value],
                chat_type_freelance: [Config.freelanceType[0].value],
                graduation_date: [""],
                str_no_expired: [""],
                sip_no_expired: [""],
                price: [""],
                special_price: [""]
            }),
            file: [],
            toggleMoreField: false,
            modalShow: false,
            confirmDelete: false,
            currentAdditionalValue: '',
            currentAdditionalIndex: 0,
            editEducation: false,
            editExperience: false,
            additionalMode: 'education',
            id: 0,
            isModalOpen: false,
            modalNotifyContent: null,
            modalNotifyMessage: null,
            universitas: '',
            jurusan: '',
            tahun_lulus: '',
            praktek: '',
            kota: '',
            bulan_tahun_mulai: '',
            bulan_tahun_selesai: '',
            expPresent: false,
            eduExpId: null,
            ModalActionSelected: null,
            isFetchingDoctorByStr: true,
            doctorEditId: null,
            listApotek: [],
            listProvinsi: [],
            listKota: [],
            listKec: [],
            listApotekPath: '',
            selectedApotek:'',
            idProvinsi: 0,
            namaProvinsi: "",
            idKota: 0,
            namaKota: "",
            idKecamatan: 0,
            namaKecamatan: "",
            idProvinsiTmp: 0,
            namaProvinsiTmp: "",
            idKotaTmp: 0,
            namaKotaTmp: "",
            idKecamatanTmp: 0,
            namaKecamatanTmp: "",
            medicalActionsTmpValue: ''
        }

        this.setStrNo = this.props.match.params.strno
    
        this.TextInputFormView = this.TextInputFormView.bind(this)
        this.TextInputEmailFormView = this.TextInputEmailFormView.bind(this)
        this.TextAreaFormView = this.TextAreaFormView.bind(this)
        this.DropDownFormView = this.DropDownFormView.bind(this)
        this.DropDownFormApotekView = this.DropDownFormApotekView.bind(this)
        this.TextInputFormDoctorView = this.TextInputFormDoctorView.bind(this)
        this.SwitchFormView = this.SwitchFormView.bind(this)
        this.RadioInputFormView = this.RadioInputFormView.bind(this)
        this.DatePickerFormView = this.DatePickerFormView.bind(this)
        Object.assign(this, DoctorFormHandler)
        Object.assign(this, RichEditorInputForm)
        this.RichEditorInputFormView = this.RichEditorInputFormView.bind(this)

        this.handleSubmit = this.handleSubmit.bind(this)
        this.valueBuilder = this.valueBuilder.bind(this)

        this.handleAdditional = this.handleAdditional.bind(this)
        this.handleAdditionalChange = this.handleAdditionalChange.bind(this)
        this.handleAdditionalEdit = this.handleAdditionalEdit.bind(this)
        this.handleAdditionalDelete = this.handleAdditionalDelete.bind(this)

        this.handleUpload = this.handleUpload.bind(this)
        this.handleShow = this.handleShow.bind(this)
        this.handleConfirmDeleteClose = this.handleConfirmDeleteClose.bind(this)
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this)
        this.fetchListApotek = this.fetchListApotek.bind(this)
        this.fetchListProvince = this.fetchListProvince.bind(this)
        this.fetchListKota = this.fetchListKota.bind(this)
        this.fetchListKec = this.fetchListKec.bind(this)
        this.fetchDataByStr = this.fetchDataByStr.bind(this)
        this.redirectTo = this.redirectTo.bind(this)
        this.fetchListTitle = this.fetchListTitle.bind(this)

        // global modal
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleSubmitAdditional = this.handleSubmitAdditional.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.disApotekSelect = this.disApotekSelect.bind(this)
        this.validationEnd = this.validationEnd.bind(this)

        this.buildForm()
    }

    isOwner = (newCurrentHosp) => {
        const { dataLogin } = this.props

        if (dataLogin) {
            const hospitalSelected = dataLogin.hospitals.length > 0
                ? dataLogin.hospitals.find(option => {
                    return String(option.id) === String(newCurrentHosp)
                }) : null

            return !_.isEmpty(hospitalSelected) ? hospitalSelected.owner : false
        }

        return false
    }

    useVideoCall = (newCurrentHosp) => {
        const { dataLogin } = this.props

        if (dataLogin) {
            if (dataLogin.superadmin) return true;

            const hospitalSelected = dataLogin.hospitals.length > 0
                ? dataLogin.hospitals.find(option => {
                    return String(option.id) === String(newCurrentHosp)
                }) : null

            return !_.isEmpty(hospitalSelected) ? hospitalSelected.video_call : false
        }

        return false
    }

    onToggleMore(){
        this.setState({
            toggleMoreField: !this.state.toggleMoreField
        })
    }
    handleApotek(value){
        this.setState({
            selectedApotek: value
        })

    }

    componentDidMount() {
        this.fetchDataByStr(this.setStrNo)
        this.fetchListApotek()
        this.fetchListProvince()
        this.fetchListTitle()
    }
    
    receiveImg = (val) => {
        this.state.Form.patchValue({ photo: val })
        this.setState({
            foto: val
        })
    }

    render(){
        const {
            redirectTo,
            id,
            postStatus,
            educations,
            experiences,
            medical_actions,
            currentAdditionalValue,
            Form,
            can_book,
            file,
            toggleMoreField,
            ModalActionSelected,
            idProvinsi,
            idProvinsiTmp,
            namaProvinsiTmp,
            idKota,
            idKotaTmp,
            namaKotaTmp,
            idKecamatan,
            idKecamatanTmp,
            namaKecamatanTmp,
            loading,
            universitas,
            jurusan,
            tahun_lulus: tahunLulus,
            str_no_expired,
            sip_no_expired,
            praktek,
            kota,
            bulan_tahun_mulai: bulanTahunMulai,
            bulan_tahun_selesai: bulanTahunSelesai,
            expPresent,
            medicalActionsTmpValue,
            listApotek,
            listApotekPath,
            keyword
        } = this.state

        const {
            dataLogin,
            currentHospital,
        } = this.props

        const idDoctor = id

        //doctor cannot add doctor
        const isDoctor = RoleHelper.isDoctor(dataLogin, currentHospital)
        if(isDoctor && !this.isOwner(currentHospital)){
            return(<Redirect to="/doctor/list" />)
        }

        if (redirectTo) {
            return <Redirect to={redirectTo} />
        }

        if (postStatus) {
            let url = "/doctor/schedule/" + idDoctor

            if (!can_book) {
                url = "/doctor/list"
            }

            return <Redirect to={url} />
        }

        this.InputBox = this.InputBoxShowHide({
            title: "Additional Information",
            body: <textarea 
                    style={inputStyle} 
                    onBlur={this.handleAdditional}
                    defaultValue={currentAdditionalValue}>
                </textarea>,
        })

        this.ConfirmBox = this.ConfirmDeleteShowHide({
            title: "Confirmation",
            body: "Are you sure want to remove this data? This data will be removed from the system",
        })

        if(this.state.isFetchingDoctorByStr){
            return(
                <div className="wizard-form doctor-form">
                    Loading...
                </div>
            )
        }
        
        return(
            <div className="wizard-form doctor-form">
                {this.InputBox}
                {this.ConfirmBox}
                {/** start form */}
                <FieldGroup
                control={Form}
                strict={false}
                render={({ get, invalid }) => {
                    const hideChatType = !Form.controls.chat_status.value
                    const hideCanBook = !Form.controls.show_in_apps.value
                    const orderChat = Form.controls.order_from_chat.value
                    const showInfo = Form.controls.show_in_apps.value
                    const canBook = Form.controls.can_book.value
                    const typeFreelace = typeof Form.controls.chat_type.value === 'object'
                        ? Form.controls.chat_type.value.value : Form.controls.chat_type.value
                    const showTypeFreelace = typeFreelace === 'Freelance'
                    const isAddInfoValid = (showInfo || !hideChatType)
                        ? (!_.isEmpty(Form.controls.profile.value)
                            && !_.isEmpty(educations)
                            && !_.isEmpty(experiences))
                        : true
                    const isCanBookValid = canBook
                        ? !_.isEmpty(Form.controls.estimation_time.value)
                        : true
                    const chatStatusValid = !hideChatType
                        ? (!_.isEmpty(Form.controls.email.value))
                        : true
                    const orderChatValid = !hideChatType && orderChat
                        ? (!_.isEmpty(Form.controls.sip_no.value))
                        : true
                    
                    const provinsiData = Form.controls.propinsi.value
                    const kotaData = Form.controls.kota.value
                    const kecamatanData = Form.controls.kecamatan.value

                    if (idProvinsi !== provinsiData.value) {
                        this.setState({
                            idProvinsi: provinsiData.value,
                            namaProvinsi: (provinsiData.label !== 'Pilih Provinsi')
                                ? provinsiData.label
                                : '',
                            idProvinsiTmp: typeof provinsiData !== 'number' ? 0 : idProvinsiTmp,
                            namaProvinsiTmp: typeof provinsiData !== 'number' ? '' : namaProvinsiTmp,
                            idKotaTmp: typeof kotaData !== 'number' ? 0 : idKotaTmp,
                            namaKotaTmp: typeof kotaData !== 'number' ? '' : namaKotaTmp,
                            idKecamatanTmp: typeof kecamatanData !== 'number' ? 0 : idKecamatanTmp,
                            namaKecamatanTmp: typeof kecamatanData !== 'number' ? '' : namaKecamatanTmp,
                        })
                        this.fetchListKota(provinsiData.value, typeof provinsiData !== 'number')
                    } else if (idKota !== kotaData.value) {
                        this.setState({
                            idKota: kotaData.value,
                            namaKota: (kotaData.label !== 'Pilih Kota') ? kotaData.label : '',
                            idKotaTmp: typeof kotaData !== 'number' ? 0 : idKotaTmp,
                            namaKotaTmp: typeof kotaData !== 'number' ? '' : namaKotaTmp,
                            idKecamatanTmp: typeof kecamatanData !== 'number' ? 0 : idKecamatanTmp,
                            namaKecamatanTmp: typeof kecamatanData !== 'number' ? '' : namaKecamatanTmp,
                        })

                        this.fetchListKec(kotaData.value, typeof kotaData !== 'number')
                    } else if (idKecamatan !== kecamatanData.value) {
                        this.setState({
                            idKecamatan: kecamatanData.value,
                            namaKecamatan: (kecamatanData.label !== 'Pilih Kecamatan')
                                ? kecamatanData.label
                                : '',
                            idKecamatanTmp: typeof kecamatanData !== 'number' ? 0 : idKecamatanTmp,
                            namaKecamatanTmp: typeof kecamatanData !== 'number' ? '' : namaKecamatanTmp,
                        })
                    }

                    return (
                    <form onSubmit={this.handleSubmit} className="form-horizontal">
                        <div className="form-line">
                             <ImageUploadFormWithCrop
                                onUpload={ this.handleUpload }
                                desc="Tambah foto Nakes"
                                icon={ iconPlus }
                                maxsize="2500000"
                                image={ file }
                                current={ this.state.Form.controls.photo }
                                receiveImg={this.receiveImg}
                            />
                        </div>
                        <div className="form-line input-str">
                            <label className="form-control-label">No Str NaKes</label>
                            <div>
                                <span className="input-value">{ this.setStrNo  }</span>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.redirectTo('/doctor/findstr')}
                                >
                                    Ubah
                                </button>
                            </div>
                        </div>
                        {this.TextSip}
                        {!hideChatType && orderChat && _.isEmpty(Form.controls.sip_no.value)
                            ? <span className="custom-error control">
                                Data harus diisi
                            </span>
                            : null}
                        {this.strNoExpiredField}
                        {/* {this.sipNoExpiredField} */}
                        <div className="form-line container-name-doctor">
                            <label className="custom-label">
                                Nama Lengkap NaKes
                                <span className="required">*</span>
                            </label>
                            <div className="field-name-doctor">
                                <div className="input-prefix">
                                    {this.PrefixDropdown}
                                </div>
                                <div className="input-name">
                                    {this.TextName}
                                </div>
                            </div>
                        </div>
                        {this.genderField}
                        {this.dobField}
                        {this.TextEmailDokter}
                        {!hideChatType && _.isEmpty(Form.controls.email.value)
                            ? <span className="custom-error control">
                                Data harus diisi
                            </span>
                            : null}
                        <div className="specialist">
                            {this.SpecialistDropdown}
                        </div>
                        { this.TextTelephone }
                        { this.TextAddress }
                        { this.ProvinsiDropdown }
                        { this.KotaDropdown }
                        { this.KecamatanDropdown }

                        { this.graduationDateField }

                        <div className="wrap-input-additional">
                            <AdditionalInputForm 
                                        icon={ iconPlus } 
                                        title="Medical Actions" 
                                        label="Medical Actions"
                                        mode="medical_actions"
                                        content={medical_actions} 
                                        onClick={ () => this.handleOpenModal('medical_actions') }
                                        onChange={this.handleAdditionalChange}
                                        onDelete={this.handleConfirmDelete}
                                        onEdit={this.handleAdditionalEdit}/>
                                    {showInfo && _.isEmpty(medical_actions) ?
                                        <span className="custom-error additional">Data harus diisi</span>
                                        : null}
                        </div>
                        { this.doctorPriceField }
                        { this.doctorSpecialPriceField }
                        {
                            this.isAdminSPV ? 
                            <div>
                                {
                                currentHospital === Number(process.env.REACT_APP_HOSPITAL_ID) &&
                                <div>
                                { this.SwitchChat }
                                <div
                                    className={hideChatType
                                        ? 'toggle-wrapper hide'
                                        : 'toggle-wrapper'
                                    }>
                                    {this.SwitchOrderChat}
                                    {this.ChatType}
                                    {showTypeFreelace ? this.FreelanceType : null}
                                    <div className="hor-line" />
                                </div>
                                </div>
                                }
                                {this.SwitchShowInApps}
                                <div
                                    className={hideCanBook
                                        ? 'toggle-wrapper hide'
                                        : 'toggle-wrapper'
                                    }>
                                    {this.useVideoCall(currentHospital)
                                        ? this.SwitchVideoCall
                                        : null}
                                    {this.SwitchCanBook}
                                    {this.EstimationDropdown}
                                    {canBook && _.isEmpty(Form.controls.estimation_time.value) ?
                                        <span className="custom-error estimate">
                                            Data harus diisi
                                        </span>
                                        : null}
                                </div>
                            </div> : 
                            null
                        }
                        {/* { this.ApotekDropdown } */}
                        <div className="form-line">
                            <label htmlFor="apotek" className="form-control-label">
                            Assign Apotek (Optional)
                            </label>
                            <div className="form-group-wizard subscription">
                                <SearchSelectFilterApotek
                                    filterOpt={listApotek} 
                                    path={listApotekPath}
                                    ref={listApotek}
                                    key={listApotek}
                                    value={keyword}
                                    handlerChangeOpt={(type, value) => this.handleApotek(value)}
                                    
                                    />
                            </div>
                        </div>   
                        <div className={
                            (toggleMoreField || (showInfo || !hideChatType))
                                ? "wrap-additional show-field"
                                : "wrap-additional add-doctor hide-field"
                        }>
                            <div id="toggle-button" onClick={ this.onToggleMore.bind(this) }>
                                Informasi Tambahan 
                                <img className="icon" src={ ArrowDown } alt="icon arrow down"/>
                            </div>
                            {(toggleMoreField || showInfo)
                                ? <div className="hor-line add-info" />
                                : null}
                            <div className="wrap-input-additional">
                                {this.TextProfile}
                                <AdditionalInputForm 
                                    icon={ iconPlus } 
                                    title="Pendidikan" 
                                    label="Tambah pendidikan"
                                    mode="education"
                                    content={educations} 
                                    onClick={ () => this.handleOpenModal('education') }
                                    onChange={this.handleAdditionalChange}
                                    onDelete={this.handleConfirmDelete}
                                    onEdit={this.handleAdditionalEdit}/>
                                {showInfo && _.isEmpty(this.state.educations) ?
                                    <span className="custom-error additional">Data harus diisi</span>
                                    : null}
                                <AdditionalInputForm 
                                    icon={ iconPlus } 
                                    title="Pengalaman" 
                                    label="Tambah pengalaman"
                                    mode="experience"
                                    content={experiences} 
                                    onClick={ () => this.handleOpenModal('experience') }
                                    onChange={this.handleAdditionalChange}
                                    onDelete={this.handleConfirmDelete}
                                    onEdit={this.handleAdditionalEdit}/>
                                {showInfo && _.isEmpty(this.state.experiences) ?
                                    <span className="custom-error additional">Data harus diisi</span>
                                    : null}
                            </div>
                        </div>
                        <SubmitButtonForm
                            invalid={
                                invalid ||
                                !isAddInfoValid ||
                                !isCanBookValid ||
                                !chatStatusValid ||
                                !orderChatValid ||
                                loading
                            }
                            submitText={
                                loading
                                    ? 'Loading...'
                                    : !canBook
                                        ? 'Simpan'
                                        : 'Selanjutnya'
                            }
                            onCancel={(e) => this.props.history.push('/doctor/list')}
                        />
                    </form>
                    )
                }}/>
                {/** end of form */}
                <MasterModal
                    className="master-modal"
                    isOpen={ this.state.isModalOpen }
                    handleClose={ this.handleCloseModal }
                    ModalData={ this.state.ModalData }
                >
                    {
                        !this.state.modalNotifyContent ?
                        <ModalContent
                            shouldCloseOnOverlayClick={false}
                            ModalActionSelected={ModalActionSelected}
                            handleInputChange={this.handleInputChange}
                            isModalOpen={this.state.isModalOpen}
                            universitas={universitas}
                            jurusan={jurusan}
                            medicalActions={medicalActionsTmpValue}
                            tahunLulus={tahunLulus}
                            str_no_expired={str_no_expired}
                            sip_no_expired={sip_no_expired}
                            praktek={praktek}
                            kota={kota}
                            bulanTahunMulai={bulanTahunMulai}
                            bulanTahunSelesai={bulanTahunSelesai}
                            expPresent={expPresent}
                            validationEnd={this.validationEnd}
                            onCheck={() => this.setState({ expPresent: true })}
                            onRemove={() => this.setState({
                                expPresent: false,
                                bulan_tahun_selesai: '',
                            })}
                            handleSubmitAdditional={this.handleSubmitAdditional}
                            handleCloseModal={this.handleCloseModal}
                        /> : 
                        <ModalNotifyContent
                            setStrNo={ this.setStrNo }
                            handleClose={ this.handleCloseModal }
                            doctorEditId={ this.state.doctorEditId }
                            modalNotifyContent={ this.state.modalNotifyContent }
                            modalNotifyMessage={ this.state.modalNotifyMessage }
                        />
                    }
                </MasterModal>
            </div>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Form)
