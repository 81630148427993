/* eslint-disable */

import React from 'react'
import MasterGrid from '../../../components/grid/MasterGrid'
import GridHandler from '../handler/UserGridHandler'
import {ContextMenuProvider} from 'react-contexify'
import ReactTable from "react-table"
import checkboxHOC from "react-table/lib/hoc/selectTable"
import MessageBox from '../../../core/utils/UtilsStrapModal'
import {AclHandler} from '../../../core/const/AclConst'
import InputTextFilter from '../../../components/grid/filter/InputTextFilter'
import MasterModal from '../../../core/utils/MasterModal'
import Icon from '../../../core/utils/Icons'

const CheckboxTable = checkboxHOC(ReactTable)

class Grid extends MasterGrid {
    constructor(props) {
        super(props)

        Object.assign(this, MessageBox)
        Object.assign(this, GridHandler)
        Object.assign(this, AclHandler)
        Object.assign(this, InputTextFilter)

        this.aclModule = 'User'

        this.keyTable = '_id'
        this.ignoreColumn = [
            '_id'
        ]
        this.selectedTableStatus = true
        this.gridColumn = this.gridColumn.bind(this)
        this.dataFromRedux = false
        this.pageSize = 20
        this.page = 0
        this.sorted = []
        this.gridMapping = (this.selectedTableStatus) ? CheckboxTable : ReactTable
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.filterable = false;
        /** own property */
        this.bulkDelete = this.bulkDelete.bind(this)
        this.fetchData = this.fetchData.bind(this)
        this.refreshData = this.refreshData.bind(this)
        this.InputTextFilter = this.InputTextFilter.bind(this)
        this.extraProperties = {
            onFetchData: this.fetchData,
            filterAble: true
        }
        this.can = this.can.bind(this)

        const loading = (this.extraProperties.hasOwnProperty('onFetchData')) ? true : false
        const data = (this.dataFromRedux) ? this.props.data : ((loading) ? [] : this.getData())
        const columns = this.getColumns(data)
        const finalColumn = this.gridColumn(ContextMenuProvider, columns)
        const pages = (loading) ? null : 1
        this.filterChangeOpt = this.filterChangeOpt.bind(this)
        this.filterSubmit = this.filterSubmit.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.modalHandleSubmit = this.modalHandleSubmit.bind(this)
        this.bulkDeleteHandler = this.bulkDeleteHandler.bind(this)
        this.state = {
            data,
            columns: finalColumn,
            selection: [],
            deleted: [],
            selectAll: false,
            loading: loading,
            pages: pages,
            filtered: [],
            onUpdate: false,
            modalShow: false,
            dataProvider: null,
            editTrigger: false,
            filterTrigger: false,
            filterKeys: [
                {label: "Nama Admin", value: "name"},
            ],
            filterKeySelected: {label: "Nama Admin", value: "name"},
            filterKeyword: "",
            isPaneOpen: false,
            isModalOpen: false,
            disableHighlight: false,
            ModalData: null,
            viewPatient: [],
            bulkBruteDownload: false,
        }

        this.currentHospital = this.props.currentHospital
    }

    componentWillReceiveProps(nextProps) {
        const { currentHospital } = this.props

        if (currentHospital !== nextProps.currentHospital) {
            this.currentHospital = nextProps.currentHospital

            this.setState({
                filterKeySelected: { label: "Nama Admin", value: "name" },
                filterKeyword: ''
            })

            this.fetchData({
                page: 0,
                pageSize: this.pageSize,
                filtered: [],
                sorted: []
            })
        }
    }

    filterSubmit(keyword){
        if(keyword){
            const accessor = this.state.filterKeySelected.value
            const filterData = [
                {
                    id: accessor,
                    value: keyword
                }
            ]
            this.fetchData({
                page: this.page,
                pageSize: this.pageSize,
                filtered: filterData,
                sorted: this.sorted,
            })
        }else{
            this.refreshData()
        }

        this.setState({
            filterKeyword: keyword
        })
    }

    filterChangeOpt(obj){
        this.setState({
            filterKeySelected: obj
        })
    }

    viewSlidePatient(obj){
        this.setState({
            isPaneOpen: true,
            viewPatient: obj
        })
    }

    onPanelClose(){
        this.setState({
            isPaneOpen: false,
            viewPatient: []
        })
    }
    
    tableHeader(isBruteDownload){
        return(
            <div className="tableHeader">
                <div className="wrapper-option">
                    <span className="action-header" title="Download data terpilih" onClick={ (e) => {
                        this.downloadHandler()
                    }}>
                        <img src={ Icon.down } alt="download"/>
                    </span>
                    <span className="action-header" title="Hapus data terpilih" onClick={ (e) => {
                        this.bulkDeleteHandler()
                    }}>
                        <img src={ Icon.TrashBin } alt="remove"/>
                    </span>
                    {
                        !isBruteDownload ? 
                        <div className="info-selected">
                            <span>{this.state.selection.length} Data dari halaman ini terpilih</span>
                            {/* <a onClick={this.bruteBulkDownloadTrigger}>
                                Pilih semua {this.state.rows} data
                            </a> */}
                        </div> :
                        <div className="info-selected">
                            <span>Semua { this.state.rows } Data  terpilih</span>
                            <a onClick={ this.clearBruteDownload }>Batalkan Pilihan</a>
                        </div>
                    }
                </div>
            </div>
        )
    }

    render() {
        const { selection } = this.state
        const tableTop = (selection.length > 0) ? this.tableHeader(this.state.bulkBruteDownload) : ''

        this.MessageBox = this.MessageBoxShowHide({
            title: "Delete Confirmation",
            body: "Are you sure want to delete this item ?",
        })

        //left menu on grid
        this.leftAction = [
            {
                type: 'searchByFilter',
                title: 'Cari',
                filterOpt: this.state.filterKeys,
                selectedOpt: this.state.filterKeySelected,
                handlerChangeOpt: this.filterChangeOpt,
                handlerSubmit: this.filterSubmit,
                filterKeyword: this.state.filterKeyword
            },
            {
                type: 'buttonLink',
                title: 'Tambah Admin',
                link: '/administration/user/add'
            }
        ]

        return (
            <section className="tables grid-setting">
                { tableTop }
                <div className="container-fluid">
                    <div className="row">
                        {this.MessageBox}
                        <div className="col-lg-12 cn-table-container">
                            <div className="card">
                                {super.render()}
                            </div>
                        </div>
                    </div>
                </div>
                <MasterModal
                    className="master-modal"
                    isOpen={this.state.isModalOpen}
                    handleClose={this.handleCloseModal}
                    handleSubmit={this.modalHandleSubmit}
                    ModalData={this.state.ModalData}
                />
            </section>
        )
    }
}

export default Grid