
import React, { Component } from 'react'
import Select from 'react-select'
import NumberFormat from 'react-number-format'
import dateFns from 'date-fns'
import IndonesianLocale from 'date-fns/locale/id'

class WidgetSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const {
            status,
            label,
            data,
            period,
            periodSelected,
            onChange,
            className,
        } = this.props

        return (
            <div className={`box-item ${className || ''}`}>
                <div className="box-container">
                    <div className="card-total">
                        <div className="box-top">
                            <div className="title-wrapper">
                                <span className="box-title">{label}</span>
                                {period && period.length > 0
                                    ? (
                                        <Select
                                            className="dropdown-filter select-period"
                                            classNamePrefix="react-select"
                                            options={period}
                                            value={period.filter(option => option.value === periodSelected)}
                                            onChange={value => onChange(value)}
                                        />
                                    ) : null}
                            </div>
                            {period && period.length > 0
                                ? <p>{period.find(option => option.value === periodSelected).label}</p>
                                : (
                                    <p style={{ marginTop: '6px' }}>
                                        Sejak {dateFns.format(data.since || new Date(), 'D MMMM YYYY', { locale: IndonesianLocale })}
                                    </p>
                                )}
                        </div>
                        <div className="box-bottom">
                            <div className="data-left">
                                {period && period.length > 0
                                    ? data.now
                                        ? (
                                            <NumberFormat
                                                value={data.now}
                                                displayType={'text'}
                                                decimalSeparator={','}
                                                thousandSeparator={'.'} />
                                        )
                                        : 0
                                    : data.total
                                        ? (
                                            <NumberFormat
                                                value={data.total}
                                                displayType={'text'}
                                                decimalSeparator={','}
                                                thousandSeparator={'.'} />
                                        )
                                        : 0
                                }
                            </div>
                            {period && period.length > 0
                                ? (
                                    <div className="data-right">
                                        <div className="top">
                                            <span
                                                className={`arrow-wrapper ${data.difference >= 0
                                                    ? status === 'reverse' ? ' decrease' : ''
                                                    : status === 'reverse' ? '' : ' decrease'}`
                                                }
                                            >
                                                <span className={`arrow ${data.difference >= 0
                                                    ? `${status === 'reverse' ? ' reverse' : ''} arrow-up`
                                                    : `${status === 'reverse' ? ' reverse' : ''} arrow-down`}`
                                                }></span>
                                            </span>
                                            <span
                                                className={`arrow-text${data.difference >= 0
                                                    ? status === 'reverse' ? ' decrease' : ''
                                                    : status === 'reverse' ? '' : ' decrease'}`
                                                }
                                            >
                                                {data.difference
                                                    ? (
                                                        <NumberFormat
                                                            value={(data.difference).toString().replace('-', '')}
                                                            displayType={'text'}
                                                            decimalSeparator={','}
                                                            thousandSeparator={'.'} />
                                                    ) : 0}
                                            </span>
                                        </div>
                                        <div className="bottom-text">
                                            {`Lebih ${data.difference >= 0 ? 'tinggi' : 'rendah'} dari sebelumnya`}
                                        </div>
                                    </div>
                                )
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WidgetSummary
