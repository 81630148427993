import dateFns from 'date-fns'
import IndonesianLocale from 'date-fns/locale/id'
import {
    FormControl,
    FormGroup,
    Validators,
} from 'react-reactive-form'

import { handleToast, handleToastServiceError } from '../../../core/utils/UtilsToast'
import ApiPatient from '../../../services/apis/Patient/Patient'
import ApiArea from '../../../services/apis/AreaApi'
import HospitalApi from '../../../services/apis/Hospital/List'

const FormHandler = {
    buildForm: function () {
        this.TextFirstName = this.TextInputFormView({
            label: "Nama Depan Pasien",
            validator: "required",
            name: "nama_depan",
            placeholder: "Isi nama depan pasien",
            control: this.state.Form.controls.nama_depan
        })

        this.TextLastName = this.TextInputFormView({
            label: "Nama Belakang Pasien",
            validator: "required",
            name: "nama_belakang",
            placeholder: "Isi nama belakang pasien",
            control: this.state.Form.controls.nama_belakang
        })

        this.TextPhone = this.TextInputFormView({
            label: "Telepon",
            validator: "required",
            name: "telepon",
            inputType: "tel",
            maxLength: 15,
            placeholder: "Isi telepon pasien",
            control: this.state.Form.controls.telepon
        })

        this.DatePickDob = this.DatePickerFormView({
            meta: {
                label: "Tanggal Lahir Pasien",
                validator: "required",
                maxDate: new Date()
            },
            name: "tanggal_lahir",
            control: this.state.Form.controls.tanggal_lahir,
            stateName: 'tanggal_lahir',
        })

        this.TextEmail = this.TextInputEmailFormView({
            label: "Email",
            placeholder: "Isi email pasien",
            name: "email",
            control: this.state.Form.controls.email
        })

        this.TextBirthPlace = this.TextInputFormView({
            label: "Tempat Lahir",
            placeholder: "Isi tempat lahir pasien",
            name: "tempat_lahir",
            control: this.state.Form.controls.tempat_lahir
        })

        this.RadioGender = this.RadioInputFormView({
            name: "jenis_kelamin",
            meta: {
                label: "Jenis Kelamin",
                options: [
                    {
                        value: "M",
                        name: "Laki-laki",
                    },
                    {
                        value: "F",
                        name: "Perempuan",
                    }
                ]
            },
            control: this.state.Form.controls.jenis_kelamin
        })

        this.TextHeight = this.TextInputFormView({
            label: "Tinggi Badan",
            placeholder: "Isi tinggi badan pasien (cm)",
            name: "tinggi_badan",
            inputType: "number",
            control: this.state.Form.controls.tinggi_badan
        })

        this.TextWeight = this.TextInputFormView({
            label: "Berat Badan",
            placeholder: "Isi berat badan pasien (kg)",
            name: "berat_badan",
            inputType: "number",
            control: this.state.Form.controls.berat_badan
        })

        this.DropDownBloodType = this.DropDownFormView({
            meta: {
                label: "Golongan Darah",
                placeholder: "Pilih golongan darah",
            },
            name: "gol_darah",
            control: this.state.Form.controls.gol_darah,
            stateName: "bloodTypeOpt",
        })

        this.DropDownTypeId = this.DropDownFormView({
            meta: {
                label: "Jenis Identitas",
                placeholder: "Pilih jenis identitas",
            },
            name: "tipe_identitas",
            control: this.state.Form.controls.tipe_identitas,
            stateName: 'idTypeOpt',
        })

        this.TextIdNo = this.TextInputFormView({
            label: "Nomor Identitas",
            placeholder: "Isi nomor identitas",
            maxLength: 16,
            name: "no_identitas",
            control: this.state.Form.controls.no_identitas
        })

        this.TextCondPatient = this.TextAreaFormView({
            label: "Kondisi Pasien",
            placeholder: "Isi kondisi pasien",
            name: "kondisi_pasien",
            control: this.state.Form.controls.kondisi_pasien
        })

        this.TextAddress = this.TextAreaFormView({
            label: "Alamat",
            placeholder: "Isi alamat pasien",
            name: "alamat",
            control: this.state.Form.controls.alamat
        })

        this.DropDownPropinsi = this.DropDownAreaFormView({
            meta: {
                label: "Propinsi",
                placeholder: "Pilih propinsi",
                callBack: this.getDropdownKota,
                EditshowOnlyText: false
            },
            name: "propinsi_id",
            nameText: "propinsi",
            control: this.state.Form.controls.propinsi_id,
            stateName: 'propinsiOpt',
        })

        this.DropDownKota = this.DropDownAreaFormView({
            meta: {
                label: "Kota",
                placeholder: "Pilih kota",
                callBack: this.getDropdownKecamatan,
            },
            name: "kota_id",
            nameText: "kota",
            control: this.state.Form.controls.kota_id,
            stateName: 'kotaOpt',
        })

        this.DropDownKecamatan = this.DropDownAreaFormView({
            meta: {
                label: "Kecamatan",
                placeholder: "Pilih kecamatan",
                callBack: this.getDropdownKelurahan,
            },
            name: "kecamatan_id",
            nameText: "kecamatan",
            control: this.state.Form.controls.kecamatan_id,
            stateName: 'kecamatanOpt',
        })

        this.DropDownKelurahan = this.DropDownAreaFormView({
            meta: {
                label: "Kelurahan",
                placeholder: "Pilih kelurahan",
                callBack: this.setKodePos,
            },
            name: "kelurahan_id",
            nameText: "kelurahan",
            control: this.state.Form.controls.kelurahan_id,
            stateName: 'kelurahanOpt',
        })

        this.TextPICName = this.TextInputFormView({
            label: "Nama Penanggung Jawab",
            placeholder: "Isi nama penanggung jawab",
            name: "nama_png_jawab",
            control: this.state.Form.controls.nama_png_jawab
        })

        this.TextPICPhone = this.TextInputFormView({
            label: "Telepon",
            placeholder: "Isi nomor telepon",
            name: "telepon_png_jawab",
            inputType: "tel",
            maxLength: 15,
            control: this.state.Form.controls.telepon_png_jawab
        })

        this.TextPICEmail = this.TextInputEmailFormView({
            label: "Email",
            placeholder: "Isi email",
            name: "email_png_jawab",
            control: this.state.Form.controls.email_png_jawab,
            inputType: "email"
        })

        this.RadioPICRelationship = this.RadioInputFormView({
            name: "relasi_png_jawab",
            meta: {
                label: "Hubungan dengan Pasien",
                options: [
                    {
                        value: "Orang Tua",
                        name: "Orang Tua",
                    },
                    {
                        value: "Suami",
                        name: "Suami",
                    },
                    {
                        value: "Istri",
                        name: "Istri",
                    },
                    {
                        value: "Anak",
                        name: "Anak",
                    },
                    {
                        value: "Saudara",
                        name: "Saudara",
                    },
                    {
                        value: "Lainnya",
                        name: "Lainnya",
                    },
                ]
            },
            control: this.state.Form.controls.relasi_png_jawab
        })
    },
    parseDateToString(dateObj) {
        const dateFormat = 'YYYY-MM-DD'
        return dateFns.format(dateObj, dateFormat, { locale: IndonesianLocale })
    },
    showSubmitLoader() {
        this.setState({
            submitingForm: true
        })
    },
    handleSubmit(e) {
        e.preventDefault()
        this.showSubmitLoader()

        let error = {
            message: 'Gagal tambah pasien, pasien sudah terdaftar'
        }
        let formValue = Object.assign({}, this.state.Form.value)
        formValue.nama_depan = formValue.nama_depan ? formValue.nama_depan.trim() : ''
        formValue.nama_belakang = formValue.nama_belakang ? formValue.nama_belakang.trim() : ''
        formValue['hospital_id'] = this.props.currentHospital
        formValue['tinggi_badan'] = formValue['tinggi_badan']
            ? parseInt(formValue['tinggi_badan'], 10) : 0
        formValue['berat_badan'] = formValue['berat_badan']
            ? parseInt(formValue['berat_badan'], 10) : 0
        formValue['tanggal_lahir'] = this.parseDateToString(formValue['tanggal_lahir'])

        if (formValue['tipe_identitas'] instanceof Object === true) {
            formValue['tipe_identitas'] = formValue['tipe_identitas'].value
        }

        if (formValue['gol_darah'] instanceof Object === true) {
            formValue['gol_darah'] = formValue['gol_darah'].value
        }

        if (formValue['propinsi_id'] instanceof Object === true) {
            formValue['propinsi'] = formValue['propinsi_id'].label
            formValue['propinsi_id'] = formValue['propinsi_id'].value
        } else {
            formValue['propinsi_id'] = formValue['propinsi_id'] || 0
        }

        if (formValue['kota_id'] instanceof Object === true) {
            formValue['kota'] = formValue['kota_id'].label
            formValue['kota_id'] = formValue['kota_id'].value
        } else {
            formValue['kota_id'] = formValue['kota_id'] || 0
        }

        if (formValue['kelurahan_id'] instanceof Object === true) {
            formValue['kelurahan'] = formValue['kelurahan_id'].label
            formValue['kelurahan_id'] = formValue['kelurahan_id'].value
        } else {
            formValue['kelurahan_id'] = formValue['kelurahan_id'] || 0
        }

        if (formValue['kecamatan_id'] instanceof Object === true) {
            formValue['kecamatan'] = formValue['kecamatan_id'].label
            formValue['kecamatan_id'] = formValue['kecamatan_id'].value
        } else {
            formValue['kecamatan_id'] = formValue['kecamatan_id'] || 0
        }

        formValue['kodepos'] = formValue['kodepos']
            ? parseInt(formValue['kodepos'], 10) : 0
        formValue['status'] = 1

        // custom field parse
        let customFields = formValue['custom_fields'][0]
        if (customFields) {
            for (var key in customFields) {
                if (customFields[key].value && customFields[key].label) {
                    // replace object select data to value
                    customFields[key] = customFields[key].value
                } else if (customFields[key] instanceof Date) {
                    // replace object date to string
                    customFields[key] = this.dateToString(customFields[key])
                }
            }
        }
        formValue['custom_fields'] = customFields

        ApiPatient.post(formValue).then((response) => {
            handleToast(response.data.message)
            this.setState({
                submitingForm: false
            })
            this.props.handleAssignPatient(response.data.data)
        }).catch(() => {
            this.setState({
                submitingForm: false
            })
            handleToastServiceError(error)
        })
    },
    loadCustomField() {
        const hospitalId = this.state.hospitalId

        HospitalApi.view(hospitalId).then(response => {
            if (response.data.code === 200) {
                if (response.data.data) {
                    const customField = JSON.parse(response.data.data.custom_patient_fields)
                    if (customField && customField.formBuilder) {
                        this.setState({
                            customField: customField.formBuilder,
                            customFieldTitle: customField.formTitle
                        })

                        let fieldInject = []
                        customField.formBuilder.map(obj => (
                            fieldInject[obj.fieldLabel] = new FormControl(
                                this.getDefaultValueCustomBuilder(obj.fieldKey, obj),
                                this.getDefaultValidatorCustomBuilder(obj)
                            )
                        ))

                        const fieldArray = this.state.Form.get('custom_fields')
                        const form = new FormGroup(fieldInject)
                        fieldArray.push(form)
                    }
                }
            }
        }).catch(() => {
            handleToastServiceError({ message: 'Something went wrong' })
        })
    },
    getDefaultValueCustomBuilder(key, obj) {
        switch (key) {
            case 'radio':
                return obj.option[0].value
            case 'checkbox':
                return []
            case 'dropdown':
                return []
            default:
                return ""
        }
    },
    getDefaultValidatorCustomBuilder(obj) {
        let validator = []
        if (!obj.fieldOptional) {
            validator.push(Validators.required)
        }

        if (obj.fieldSubType === 'email') {
            validator.push(Validators.email)
        }

        return validator
    },
    getDropdownPropinsi() {
        let me = this
        if (!this.props.previewMode) {
            let requestApi = ApiArea.provinsi()
            requestApi.then(response => {
                me.setState({
                    propinsiOpt: response.data.data,
                })
            }).catch(error => {
                console.log(error)
            })
        }
    },
    getDropdownKota(me, name) {
        const formMustBeCleared = [
            'kota_id', 'kecamatan_id', 'kelurahan_id'
        ]
        const optionMustBeCleared = [
            'kotaOpt', 'kecamatanOpt', 'kelurahanOpt'
        ]

        me.resetDependentSelect(me, formMustBeCleared, optionMustBeCleared)
        const idPropinsi = me.state.Form.value[name].value
        const requestApi = ApiArea.kota(idPropinsi)

        requestApi.then(response => {
            me.setState({
                kotaOpt: response.data.data,
                editChanged: true
            })
        }).catch(error => {
            console.log(error)
        })
    },
    getDropdownKecamatan(me, name) {
        const formMustBeCleared = [
            'kecamatan_id', 'kelurahan_id, kodepos_id'
        ]
        const optionMustBeCleared = [
            'kecamatanOpt', 'kelurahanOpt'
        ]
        me.resetDependentSelect(me, formMustBeCleared, optionMustBeCleared)

        const idKota = me.state.Form.value[name].value
        const requestApi = ApiArea.kecamatan(idKota)
        requestApi.then(response => {
            me.setState({
                kecamatanOpt: response.data.data
            })
        }).catch(error => {
            console.log(error)
        })
    },
    getDropdownKelurahan(me, name) {
        const formMustBeCleared = [
            'kelurahan_id, kodepos'
        ]
        const optionMustBeCleared = [
            'kelurahanOpt'
        ]
        me.resetDependentSelect(me, formMustBeCleared, optionMustBeCleared)

        const idKecamatan = me.state.Form.value[name].value
        const requestApi = ApiArea.kelurahan(idKecamatan)

        requestApi.then(response => {
            me.setState({
                kelurahanOpt: response.data.data
            })
        }).catch(error => {
            console.log(error)
        })
    },
    setKodePos: async function (me, name) {
        const idKelurahan = me.state.Form.value[name].value
        const findKodepos = me.state.kelurahanOpt.find((data) => data.value === idKelurahan);
        const kodepos = findKodepos.kodepos || ''
        me.state.Form.controls.kodepos.setValue(kodepos)
    },
    resetDependentSelect: function (me, mustBeCleared, optBeCleared) {
        mustBeCleared.map((value) => {
            if (me.state.Form.controls[value]) {
                me.state.Form.controls[value].reset()
            }
            return false
        })

        optBeCleared.map((value) => {
            me.setState({
                [value]: []
            })
            return true
        })
    },
}

export default FormHandler