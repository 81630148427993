import HospitalApi from '../../../services/apis/Hospital/List'
import AppointmentApi from '../../../services/apis/Appointment/List'
import { handleToast, handleToastServiceError } from '../../../core/utils/UtilsToast'

const hostDashHandler = {
    infoHospital: async function (id) {
        await HospitalApi.getInfoHospital(id).then((res) => {
            const {
                status,
                data: {
                    data,
                },
            } = res

            if (status !== 200) {
                handleToast('Something went wrong')
                return
            }

            this.setState({ infoHosp: data })
        }).catch(error => {
            console.log('Something went wrong:', error)
        })
    },
    appointmentSumm: async function (id, period) {
        await HospitalApi.getAppointmentSumm(id, period).then((res) => {
            const {
                status,
                data: {
                    data,
                },
            } = res

            if (status !== 200) {
                handleToast('Something went wrong')
                return
            }

            this.setState({ appSum: data })
        }).catch(error => {
            console.log('Something went wrong:', error)
        })
    },
    scheduleDoctor: async function (id, period) {
        await HospitalApi.getSchDoctor(id, period).then((res) => {
            const {
                status,
                data: {
                    data,
                },
            } = res

            if (status !== 200) {
                handleToast('Something went wrong')
                return
            }

            this.setState({ schDoctor: data })
        }).catch(error => {
            console.log('Something went wrong:', error)
        })
    },
    activeDoctor: async function (id) {
        await HospitalApi.getActiveDoctor(id).then((res) => {
            const {
                status,
                data: {
                    data,
                },
            } = res

            if (status !== 200) {
                handleToast('Something went wrong')
                return
            }

            this.setState({ activeDoctor: data })
        }).catch(error => {
            console.log('Something went wrong:', error)
        })
    },
    dataPatient: async function (patientState, id, type, period) {
        await HospitalApi.getPatient(id, type, period).then((res) => {
            const {
                status,
                data: {
                    data,
                },
            } = res

            if (status !== 200) {
                handleToast('Something went wrong')
                return
            }

            this.setState({ [patientState]: data })
        }).catch(error => {
            console.log('Something went wrong:', error)
        })
    },
    getNotifHandle: async function (hid) {
        let res = {}

        try {
            res = await AppointmentApi.notification(hid)
        } catch (error) {
            handleToastServiceError(error)
            return
        }

        const {
            status,
            message,
            data,
        } = res.data

        if (status) {
            this.props.setNotifAppointment(data)
        } else {
            handleToastServiceError({ message })
        }
    },
}

export default hostDashHandler
