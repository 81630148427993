import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import React from 'react';

const MessageBox = {
    MessageBoxShowHide : function({title, body}) {
      return (
        <Modal isOpen={this.state.modalShow} toggle={this.handleCloseModal}>
          <ModalHeader toggle={this.handleCloseModal}>{title}</ModalHeader>
          <ModalBody>
            {body}
          </ModalBody>
          <ModalFooter>
          <Button onClick={this.handleConfirmModal}>Hapus</Button>
              <Button onClick={this.handleCloseModal}>Tutup</Button>
          </ModalFooter>
        </Modal>
      )
    },
  
    MessageBoxAlert : function({title, body}) {
        return (
            <Modal isOpen={this.state.modalShow} toggle={this.handleCloseModal}>
              <ModalHeader toggle={this.handleCloseModal}>{title}</ModalHeader>
              <ModalBody>
                {body}
              </ModalBody>
              <ModalFooter>
                  <Button onClick={this.handleCloseModal}>Close</Button>
              </ModalFooter>
            </Modal>
        )
    }
  }
  
  export default MessageBox;