import React, { Component } from 'react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import formHandler from '../../../handler/superadmin/setting/FormRoleEditHandler'

class FormRoleEdit extends Component {
    constructor(props) {
        super(props)

        Object.assign(this, formHandler)

        this.state = {
            name: '',
            isSubmit: false,
            data: this.props.dataEdit,
        }

        this.onChange = this.onChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    render() {
        const { data } = this.state
        const { dataEdit, handleOpenModal } = this.props
        const isEqArr = _.isEqual(data.sort(), dataEdit.sort())

        return (
            <div className="edit-role">
                <div className="cn-table-container">
                    <div className="ReactTable -striped -highlight">
                        <div className="rt-table">
                            <div className="rt-thead -header">
                                <div className="rt-tr">
                                    <div className="rt-th">
                                        <div>Jenis</div>
                                    </div>
                                    <div className="rt-th permission-col">
                                        <div>View</div>
                                    </div>
                                    <div className="rt-th permission-col">
                                        <div>Edit</div>
                                    </div>
                                </div>
                            </div>
                            <div className="rt-body">
                                {data.map((v, i) =>
                                    <div className="rt-tr-group" key={i}>
                                        <div className="rt-tr">
                                            <div className="rt-td">{v.name}</div>
                                            <div className="rt-td permission-col">
                                                <div className="wrap-checkbox-left">
                                                    <label htmlFor={'chk_view_' + i} className="checkbox-label">
                                                        <input
                                                            name={'chk_view_' + i}
                                                            id={'chk_view_' + i}
                                                            type="checkbox"
                                                            value={!v.view}
                                                            onChange={(e) => this.onChange({ view: (e.target.value === 'true') }, v.name)}
                                                            checked={v.view}
                                                        />
                                                        <span className={`checkmark ${v.view ? 'checked' : ''}`}></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="rt-td permission-col">
                                                <div className="wrap-checkbox-left">
                                                    <label htmlFor={'chk_edit_' + i} className="checkbox-label">
                                                        <input
                                                            name={'chk_edit_' + i}
                                                            id={'chk_edit_' + i}
                                                            type="checkbox"
                                                            value={!v.edit}
                                                            onChange={(e) => this.onChange({ edit: (e.target.value === 'true') }, v.name)}
                                                            checked={v.edit}
                                                        />
                                                        <span className={`checkmark ${v.edit ? 'checked' : ''}`}></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-action-slider">
                    <button className={`save ${isEqArr ? 'disabled' : ''}`} onClick={this.handleSubmit} disabled={isEqArr ? 'disabled' : ''}>Simpan</button>
                    <button className="delete" onClick={handleOpenModal}><FontAwesomeIcon size="sm" icon={faTrash} />Hapus Peran</button>
                </div>
            </div>
        )
    }
}

export default FormRoleEdit