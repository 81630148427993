export default class FormHelper {
    static reset = (obj, objReset) =>  {
        obj.reset(); 
    };
    static fill = (me, dataProvider) => {
        Object.keys(dataProvider).map(function (key) {
            // Process Value Property
            if (me.hasOwnProperty('valueObject') && me.valueObject.hasOwnProperty(key)) {
                if (typeof me.valueObject[key] === 'boolean') {
                    me.valueObject[key] = !!dataProvider[key]
                } else {
                    me.valueObject[key] = dataProvider[key] ? dataProvider[key].toString() : ''
                }
            }

            if (me.hasOwnProperty('valueObjectCombo') && me.valueObjectCombo.hasOwnProperty(key)) {
                let obj = me.valueObjectCombo[key]
                if( me.valueObjectCombo[key].local === true ) {
                    let valueProvider = (dataProvider[obj.valueName] !== null) ? dataProvider[obj.valueName].toString() : null
                    let findData = me.state[obj.stateName].find(value => (value.value.toString()) === valueProvider)

                    if (findData) {
                        me.valueObjectCombo[key].value = dataProvider[obj.valueName]
                        me.valueObjectCombo[key].label = findData.label
                    }
                } else {

                    if(typeof obj.propsName !== undefined) {
                        if (typeof obj.propsName === 'string' && dataProvider[obj.propsName]) {
                            me.valueObjectCombo[key].value = dataProvider[key]
                            me.valueObjectCombo[key].label = dataProvider[obj.propsName]
                        }

                        if (typeof obj.propsName === 'object' && dataProvider[obj.propsName.name]) {
                            me.valueObjectCombo[key].value = dataProvider[obj.propsName.name][obj.propsName.value]
                            me.valueObjectCombo[key].label = dataProvider[obj.propsName.name][obj.propsName.label]
                        }
                    }
                }
            }

            return true
        })
    };
}