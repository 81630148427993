const SAconfig = {
    cancelUrl: "/dashboard",
    baseUrl: "/dashboard/verify",
    backUrl: "/dashboard",
    typeInsitution: [
        { value: 0, label: 'Semua Tipe' },
        { value: 1, label: 'Rumah Sakit' },
        { value: 2, label: 'Klinik' },
    ],
    statusInsitution: [
        { value: '', label: 'Semua Status' },
        { value: false, label: 'Belum Verifikasi' },
        { value: true, label: 'Sudah Verifikasi' },
    ],
}

export default SAconfig