import Api from '../../../../services/apis/doctor/DoctorSchedule'
import AppointmentApi from '../../../../services/apis/Appointment/List'
import dateFns from "date-fns"
import IndonesianLocale from 'date-fns/locale/id'
import { 
    handleToast, 
    handleToastServiceError
} from '../../../../core/utils/UtilsToast'

const ScheduleHandler = {
    handleOpenModal(ModalData) {
        this.setState({
            isModalOpen: true,
            ModalData: ModalData
        })
    },
    handleCloseModal(type){
        if(type === 'clear_all'){
            this.setState({
                isModalOpen: false,
                ModalData: null,
                chosenPatient: null,
                scheduleData: null,
                selectedDate: null,
                isPaneOpen: false,
            })
        }else{
            this.setState({
                isModalOpen: false,
                ModalData: null
            })
        }
    },
    nextMonth() {
        this.setState({
            currentMonth: dateFns.addMonths(this.state.currentMonth, 1),
            isFetchingSchedule: true,
            listSchedule: []
        })
    },
    prevMonth() {
        this.setState({
            currentMonth: dateFns.subMonths(this.state.currentMonth, 1),
            isFetchingSchedule: true,
            listSchedule: []
        })
    },
    fetchSchedule(selectedMonth){
        const doctorId = this.state.doctor.id
        const year = dateFns.format(selectedMonth, 'YYYY')
        const month = dateFns.format(selectedMonth, 'M')
        const Schedule = Api.get(doctorId, year, month)
                
        Schedule.then(response => {
            if (response && response.data) {
                const scheduleData = response.data.data.schedule_data
                    ? response.data.data.schedule_data
                    : []
                const doctorData = response.data.data.doctor_data
                let doctor = []

                if (doctorData) {
                    doctor = {
                        id: doctorData.id,
                        name: doctorData.name,
                        title: doctorData.title,
                    }
                }

                this.setState({
                    listSchedule: scheduleData,
                    isFetchingSchedule: false,
                    doctor: doctor
                })
            }
        })
    },

    onPanelClose(){
        this.setState({
            selectedDate: null,
            isPaneOpen: false,
        });
    },
    onPanelOpen(day, scheduleId){
        let selectedDayStr = null
        let fullDateStr = null
        const isLoading = this.state.isFetchingSchedule
        if(day){
            selectedDayStr = dateFns.format(day, 'dddd', {locale:IndonesianLocale})
            fullDateStr = dateFns.format(day, 'D MMMM YYYY', {locale:IndonesianLocale})
        }
    
        if(!isLoading){
            this.setState({
                selectedDate: day,
                isPaneOpen: true,
                selectedScheduleId: scheduleId,
                chosenPatient: null,
                scheduleData: null,
                panelData: {
                    headTitle: selectedDayStr,
                    subTitle: fullDateStr
                }
            })
        }
    },

    modalHandleSubmit(){
        const ModalData = this.state.ModalData
        const id = ModalData.id
        
        if(ModalData.action === 'confirm_appointment'){
            AppointmentApi.confirm(id).then(response => {
                if(response.data.code === 200){
                    handleToast(response.data.message)
                }else{
                    handleToastServiceError(response.data.message)
                }
                this.handleCloseModal('clear_all')
                return
            }).catch(response => {
                handleToastServiceError(response.data.message)
                return
            })
        }else if(ModalData.action === 'cancel_appointment'){
            AppointmentApi.cancel(id).then(response => {
                if(response.data.code === 200){
                    handleToast(response.data.message)
                }else{
                    handleToastServiceError(response.data.message)
                }
                this.handleCloseModal('clear_all')
                return
            }).catch(response => {
                handleToastServiceError(response.data.message)
                return
            })
        }else if(ModalData.action === 'cancel_schedule'){
            Api.delete(id).then(response => {
                if(response.data.code === 200){
                    handleToast(response.data.message)
                }else{
                    handleToastServiceError(response.data.message)
                }
                this.handleCloseModal('clear_all')
                this.triggerRefresh()
                return
            }).catch(response => {
                handleToastServiceError(response.data.message)
                return
            })
        }
    },

    triggerRefresh(){
        const selectedMonth = this.state.currentMonth
        this.setState({
            isFetchingSchedule: true,
            isModalFetching: true
        })
        this.fetchSchedule(selectedMonth)
    },
}

export default ScheduleHandler