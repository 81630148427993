import React, { Component } from 'react'
import QuickAction from '../modals/QuickAction'

class ModalContent extends Component 
{
    components = {
        QuickAction: QuickAction,
    };
    
    render() {
        const ModalComponent = this.components[this.props.ModalComponent || 'QuickAction'];
        return (
            <ModalComponent />
        )
    }
}

export default ModalContent