import { handleToast, handleToastServiceError } from '../../../../core/utils/UtilsToast'
import Api from '../../../../services/apis/Spg/Spg'
import DoctorApi from '../../../../services/apis/doctor/DoctorApi'
import Config from '../config'

const FormHandler = {
    buildForm: function () {
        this.TextName = this.TextInputFormView({
            label: 'Nama',
            validator: 'required',
            name: 'name',
            placeholder: 'Nama User',
            maxLength: 50,
            control: this.state.Form.controls.name
        })

        this.TextEmail = this.TextInputEmailFormView({
            label: 'Email',
            validator: 'required',
            name: 'email',
            placeholder: 'youremail@mail.com',
            maxLength: 50,
            control: this.state.Form.controls.email
        })

        this.TextEmailEdit = this.TextInputFormView({
            label: "Email",
            validator: "required",
            name: "email",
            placeholder: 'youremail@mail.com',
            readonly: true,
            control: this.state.Form.controls.email
        })


        this.TextTelephone = this.TextInputFormView({
            label: 'Nomor Telepon',
            validator: 'required',
            name: 'telephone',
            placeholder: '082399xxxxxx',
            inputType: "tel",
            maxLength: 15,
            control: this.state.Form.controls.telephone
        })

        this.ApotekDropdown = this.DropDownFormApotekView({
            meta: {
                label: "Assign Apotek",
                placeholder: "Hubungkan SPG dengan apotek",
                validator: 'required',
            },
            name: "apotek",
            stateName: "listApotek",
            control: this.state.Form.controls.apotek
        })
    },
    fetchListApotek(){
        DoctorApi.listApotek().then(result => {
            if(result.data.data){
                const listApotek = result.data.data.map((item) => {
                    return {
                        value: item.code,
                        label: `${item.name} (${item.code}) - ${item.type}`,
                    }
                })

                this.setState({ listApotek })
            }
        })
    },
    valueBuilder: function () {
        let me = this
        this.valueObject = {
            id: '',
            name: '',
            email: '',
            telephone: '',
            apotek: ''
        }
        
        if (this.props.history.location.pathname.includes(Config.editUrl)) {
            this.setState({
                isEdit: true
            })
            Api.view(this.props.match.params.id).then((response) => {
                me.fillForm(response.data.data)
            }, (e) => {
                handleToastServiceError(e)
                setTimeout(function(){ me.props.history.push(Config.baseUrl); }, 3000);
            })
        }
    },

    fillForm: function(dataProvider) {
        let me = this
        let poly = []
        Object.keys(dataProvider).map((key) => {
            if (me.valueObject.hasOwnProperty(key)) {
                if(key === 'access'){
                    dataProvider[key].map( data => {
                        poly.push(data.poly_id)
                        return poly
                    })
                }
                
                me.valueObject[key] = dataProvider[key] ? dataProvider[key].toString() : ''
            }
            return true
        })

        this.state.Form.patchValue(me.valueObject)
        this.setState({
            isEdit: true
        })
    },

    handleSubmit: async function (e) {
        e.preventDefault()
        let formValue = Object.assign({}, this.state.Form.value)
        let id = formValue['id']
        let me = this
        let response

        formValue.id_hospital = this.props.currentHospital
        if(formValue.apotek instanceof Object !== false){
            formValue.apotek = formValue.apotek.value
        }

        if (this.state.isEdit) {
            try {
                response = await Api.update(id, JSON.stringify(formValue))
            } catch (e) {
                handleToastServiceError(e)
                return
            }

            if (response.data.code !== 200) {
                return
            }

            me.setState({postStatus: true})
            handleToast(response.data.message)

            return
        }else{
            delete(formValue.id)
        }

        try {
            response = await Api.post(JSON.stringify(formValue))
        } catch (e) {
            handleToastServiceError(e)
            return
        }
    
        if (response.data.code === 200) {
            me.setState({postStatus: true})
            handleToast(response.data.message)
            return
        }

        handleToast(response.data.message)
    }
}

export default FormHandler