import React from 'react'
import Api from "../../../services/apis/Hospital/Facility"
import Chance from "chance";
import { handleToast, handleToastServiceError } from "../../../core/utils/UtilsToast"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash, faDownload } from '@fortawesome/free-solid-svg-icons'
const chance = new Chance();

const GridHandler = {
    gridColumn: function (ContextMenuProvider) {
        return [
            {
                Header: "Nama Tipe",
                accessor: "name",
                Filter: (config) => this.InputTextFilter(config)
            },
            {
                Header: "Deskripsi",
                accessor: "description",
                Filter: (config) => this.InputTextFilter(config)
            },
            {
                Header: () => (
                    <span>
                        <FontAwesomeIcon icon={faDownload}/>                      
                    </span>
                  ),
                filterable: false,
                sortable: false,
                width: 50,
                Cell: row => (
                    <button title="Ubah" className="action-row" onClick={() => this.handleClick('edit', row.original)}>
                        <FontAwesomeIcon icon={faPencilAlt}/>
                    </button>
                )
            },
            {
                Header: () => (
                    <span className="action-header" title="Hapus data terpilih" onClick={ (e) => {
                        this.bulkDeleteHandler()
                    }}>
                        <FontAwesomeIcon icon={faTrash}/>                      
                    </span>
                  ),
                filterable: false,
                sortable: false,
                width: 50,
                Cell: row => (
                    <button title="Hapus" className="action-row" onClick={() => this.handleClick('delete', row.original)}>
                        <FontAwesomeIcon icon={faTrash}/>
                    </button>
                )
            }
        ]
    },
    onClickContext: function ({event, ref, data, dataFromProvider}, action, key, callBack) {
        switch (action) {
            case 'delete':
                this.setState({
                    deletedId: dataFromProvider.original.id,
                    actionModal: 'single'
                })
                this.handleShow()
                break;
            case 'edit':
                this.setState({dataProvider: dataFromProvider.original, editTrigger: true})
                this.updateStatusState()
                break;
            default:
        }
    },

    bulkDelete: function () {
        let selection = this.state.selection
        let originData = this.state.data
        let deleted = []
        let me = this

        selection.forEach(value => {
            let foundData = originData.filter(obj => obj[this.keyTable] === value)
            deleted.push(foundData[0].id)
        })

        Api.bulkDelete(JSON.stringify(deleted)).then(function (response) {
            if ( response.status !== 200 ){
            	handleToast('Something went wrong');
            	return;
            }
            me.setState({
                // roleItem : [...originData],
                fetchingGrid: false
            })
            let state = {
                pageSize: me.pageSize,
                page: me.page,
                sorted: me.sorted,
                search: ''
            }
            me.fetchData(state, null)
            me.setState({data: originData, deleted: deleted, selection: []})
            handleToast(response.data.message);
            me.handleCloseModal()

        }, function (err) {
            me.handleCloseModal()
            handleToast('Something went wrong ' + err.message);
        });

    },

    fetchData: async function (state, instance) {
        if (state.filtered.length > 0 && !this.state.filterTrigger) {
            return;
        }

        // search keyword
        let fArray = {}
        state.filtered.map(val => 
            fArray[val.id] = val.value
        )
        const filterData = JSON.stringify(fArray)

        this.setState({filterTrigger: false})
        this.pageSize = state.pageSize
        this.page = state.page
        this.setState({loading: true})
        let sort_property = ''
        let sort_value = ''
        let res

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'DESC' : 'ASC'
            this.sorted = state.sorted
        }

        try {
            res = await Api.get(state.page + 1, state.pageSize, sort_property, sort_value, filterData)
        } catch (e) {
            handleToastServiceError(e)
            return
        }

        if (React.isValidElement(res)) {
            return
        }

        if (res.data.code === 200) {
            let newRows = res.data.data.rows.map(item => {
            	const _id = chance.guid()
            	return {
            		_id,
            		...item
            	}
            })

            if (this._isMounted) {
                this.setState({
                    data: newRows,
                    pages: res.data.data.total_page,
                    rows: res.data.data.total_rows,
                    loading: false,
                })
            }

        }
    },
    handleClick: function (action, theRow){
        switch (action) {
            case 'delete':
                this.setState({
                    deletedId: theRow.id,
                    actionModal: 'single'
                })
                this.handleShow()
                break
            case 'edit':
                this.setState({dataProvider: theRow, editTrigger: true})
                this.updateStatusState()
                break
            default:
        }
    },
    refreshData: function () {
        this.setState({filtered: []})
    },

    handleShow: function () {
        this.setState({modalShow: true});
    },

    handleCloseModal: function () {
        this.setState({modalShow: false});
    },

    handleConfirmModal: function () {
        let me = this
        if (this.state.actionModal === 'bulk') {
            this.bulkDelete();
            return;
        }
        Api.delete(this.state.deletedId).then(function (response) {
            let state = {
                pageSize: me.pageSize,
                page: me.page,
                sorted: me.sorted,
                search: ''
            }
            me.fetchData(state, null)
            handleToast(response.data.message);
        }, function(error){
            handleToast(error.message)
        });
        me.handleCloseModal()
    }
}

export default GridHandler