import React, { Component } from 'react'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from 'react-reactive-form'

import TextInputForm from '../../../core/utils/forminput/TextInputForm'
import TextInputPriceForm from '../../../core/utils/forminput/TextInputPriceForm'
import Handler from '../handler/FormHandler'

class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            Form: FormBuilder.group({
                name: ['', Validators.required],
                code: ['', Validators.required],
                price: ['', Validators.required],
            })
        }

        Object.assign(this, TextInputForm)
        this.TextInputFormView = this.TextInputFormView.bind(this)
        Object.assign(this, TextInputPriceForm)
        this.TextInputPriceFormView = this.TextInputPriceFormView.bind(this)

        // assign to handler
        Object.assign(this, Handler)
        this.getDetail = this.getDetail.bind(this)
        this.buildValue = this.buildValue.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

        this.buildForm()
        this.currentHospital = this.props.currentHospital
    }

    componentDidMount() {
        const { id } = this.props

        if (id) {
            this.getDetail(id)
        }
    }

    render() {
        const { Form, loading } = this.state
        const { id } = this.props

        return (
            <FieldGroup control={Form}
                strict={false}
                render={({ invalid }) => {
                    return (
                        <div
                            className="wizard-form custom-form"
                            style={{ marginTop: 16, marginLeft: 2, marginBottom: 24 }}
                        >
                            <form onSubmit={this.handleSubmit} autoComplete="off">
                                {this.nameField}
                                {this.codeField}
                                {this.priceField}
                                <button
                                    type="submit"
                                    className={
                                        `save itgbtn primary ${invalid || loading
                                            ? 'disabled'
                                            : ''}`
                                    }
                                    disabled={invalid || loading}
                                    onSubmit={this.handleSubmit}
                                >
                                    {id ? 'Update' : 'Simpan'}
                                </button>
                            </form>
                        </div>
                    )
                }}
            />
        )
    }
}

export default Form
