import React, { Component } from 'react'
import MasterTemplate from '../../../template/MasterTemplate'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
    loginMapStateToProps,
    loginMapDispatchToProps
} from '../../../core/const/LoginConst'
import AccessPermission from '../../../template/AccessPermission'
import Grid from './components/Grid'

class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataGrid: [],
            isEdit: false,
        }
        this.callBackState = this.callBackState.bind(this)
    }

    callBackState(state) {
        this.setState({
            dataGrid: state,
            isEdit: state.editTrigger
        })
    }

    render() {
        const { isEdit, dataGrid } = this.state
        const breadcrumbs = [
            {
                label: 'Dashboard',
                path: '/dashboardhospital',
            },
            {
                label: 'Pengaturan',
                path: '/administration/info-hospital',
            },
            {
                label: 'Pengaturan SPG',
                path: null,
            },
        ]

        if (isEdit) {
            return <Redirect to={{
                pathname: '/administration/spg/edit/' + dataGrid.dataProvider.id,
                state: { dataProvider: dataGrid.dataProvider }
            }} />
        }

        return (
            <AccessPermission {...this.props} module="setting_hospital">
                <MasterTemplate {...this.props} breadcrumbs={breadcrumbs}>
                    <Grid {...this.props} callBackState={this.callBackState} />
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Main)
