
import React from 'react';
import KlikDokterLogo from '../../themes/v1/img/layout/logo/klikdokter.svg'

/** ----------------- Start --------------------- **/

export default class SideBarFooter extends React.Component {
    constructor(props) {
        super(props);
        this.date = new Date()
    }

    render() {
        return (
            <div className="side-footer">
                <div className="logo">
                    <img src={KlikDokterLogo} alt="KlikDokter Logo" />
                    <span>© { this.date.getFullYear() }</span>
                </div>
            </div>
        );
    }
}