import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import { loginMapStateToProps, loginMapDispatchToProps } from '../../core/const/LoginConst'
import AccessPermission from '../../template/AccessPermission'
import MasterTemplate from '../../template/MasterTemplate'
import Form from './components/Form'

class Create extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { match } = this.props
        const id = !isEmpty(match.params) ? match.params.id : null
        const breadcrumbs = [
            {
                label: 'Dashboard',
                path: '/dashboardhospital',
            },
            {
                label: 'Pengaturan',
                path: '/administration/info-hospital',
            },
            {
                label: 'Biaya Layanan',
                path: '/administration/service-fee',
            },
            {
                label: `${id ? 'Edit' : 'Tambah'} Layanan`,
                path: null,
            },
        ]

        return (
            <AccessPermission {...this.props} module="setting_hospital">
                <MasterTemplate {...this.props} breadcrumbs={breadcrumbs}>
                    <div className="container-fluid">
                        <Form {...this.props} id={id} />
                    </div>
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Create)
