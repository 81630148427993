import React from 'react'
import DatePicker from "react-date-picker"
import Icon from '../../../core/utils/Icons'

class DatePickerFilter extends React.Component {
    constructor() {
        super()
        this.handleChange = this.handleChange.bind(this)
    }

    DatePickerIcon(){
        return(
            <img src={ Icon.calendar } className="icon" alt="calendar"/>
        )
    }

    ClearIcon(){
        return(
            <img src={ Icon.close } className="icon close" alt="clear"/>
        )
    }

    handleChange(newDate){
        const filterData = this.props.filterData
        filterData.handler(filterData.filterType, newDate)
    }

    render(){
        const filterData = this.props.filterData
        return(
            <div className="form-line-filter">
                <div className="form-group global-datepicker wizard-datepicker">
                    <DatePicker
                        calendarIcon={ this.DatePickerIcon() }
                        clearIcon={ filterData.value ? this.ClearIcon() : null }
                        placeholder={ 'Pilih Tanggal' }
                        onChange={ this.handleChange }
                        prev2Label={null}
                        next2Label={null}
                        locale="id-id"
                        autocomplete="off"
                        value={ filterData.value }
                        isOpen={ false }
                        maxDetail="month"
                        minDetail="year"
                    />
                </div>
            </div>
        )
    }
}

export default DatePickerFilter