import React, { Component } from 'react'
import dateFns from 'date-fns'
import IndonesianLocale from 'date-fns/locale/id'

import defaultPhoto from '../../../../../themes/v1/img/default-doctor.png'

class DetailFam extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        const { detailFamily: data } = this.props

        return (
            <div className="m-detail-family">
                <div className="detail-family">
                    <div className="img-wrapper">
                        <img src={defaultPhoto} alt="avatar" />
                    </div>
                    <div>
                        <div className="info-detail">
                            <div className="info-wrapper">
                                <div className="info-family">
                                    <div className="label">Nama Lengkap</div>
                                    <div className="content">{data.nama}</div>
                                </div>
                                <div className="info-family">
                                    <div className="label">Hubungan Dengan Pasien</div>
                                    <div className="content">{data.relasi || '-'}</div>
                                </div>
                            </div>
                            <div className="info-wrapper">
                                <div className="info-family">
                                    <div className="label">No. Telepon</div>
                                    <div className="content">{data.telepon}</div>
                                </div>
                                <div className="info-family">
                                    <div className="label">Email</div>
                                    <div className="content">{data.email || '-'}</div>
                                </div>
                            </div>
                            <div className="info-wrapper">
                                <div className="info-family">
                                    <div className="label">Jenis Kartu Identitas</div>
                                    <div className="content">{data.tipe_identitas || '-'}</div>
                                </div>
                                <div className="info-family">
                                    <div className="label">Nomor Kartu Identitas</div>
                                    <div className="content">{data.no_identitas || '-'}</div>
                                </div>
                            </div>
                            <div className="info-wrapper">
                                <div className="info-family">
                                    <div className="label">Tanggal Lahir</div>
                                    <div className="content">
                                        {data.tanggal_lahir
                                            ? dateFns.format(data.tanggal_lahir, 'DD MMMM YYYY', { locale: IndonesianLocale })
                                            : '-'
                                        }
                                    </div>
                                </div>
                                <div className="info-family">
                                    <div className="label">Alamat</div>
                                    <div className="content">{data.alamat || '-'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="info-detail">
                            <div className="info-wrapper">
                                <div className="info-family">
                                    <div className="label">Jenis Kelamin</div>
                                    <div className="content">
                                        {data.jenis_kelamin === 'F'
                                            ? 'Perempuan'
                                            : 'Laki-laki'
                                        }
                                    </div>
                                </div>
                                <div className="info-family">
                                    <div className="label">Golongan Darah</div>
                                    <div className="content">{data.gol_darah || '-'}</div>
                                </div>
                            </div>
                            <div className="info-wrapper">
                                <div className="info-family">
                                    <div className="label">Tinggi badan</div>
                                    <div className="content">
                                        {data.tinggi_badan
                                            ? `${data.tinggi_badan} CM`
                                            : '-'
                                        }
                                    </div>
                                </div>
                                <div className="info-family">
                                    <div className="label">Berat Badan</div>
                                    <div className="content">
                                        {data.berat_badan
                                            ? `${data.berat_badan} CM`
                                            : '-'
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DetailFam
