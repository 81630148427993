import React from 'react'
import DoctorSpecialistApi from "../../../../services/apis/doctor/DoctorSpecialistApi"
import Chance from "chance"
import { handleToast, handleToastServiceError } from "../../../../core/utils/UtilsToast"
const chance = new Chance()

const DoctorSpecialistGridHandler = {
    gridColumn: function (ContextMenuProvider) {
        return [
            {
                Header: "Name",
                accessor: "name"
            },
            {
                Header: "Description",
                accessor: "description"
            },
            {
                Header: "action",
                filterable: false,
                sortable: false,
                Cell: row => (
                    <ContextMenuProvider id="menu_id" data={row} event="onClick">
                        ...
                    </ContextMenuProvider>
                )
            }
        ]
    },
    onClickContext: function ({event, ref, data, dataFromProvider}, action, key, callBack) {
        switch (action) {
            case 'delete':
                this.setState({
                    deletedId: dataFromProvider.original.id,
                    actionModal: 'single'
                })
                this.handleShow()
                break
            case 'edit':
                this.setState({dataProvider: dataFromProvider.original, editTrigger: true})
                this.updateStatusState()
                break
            default:
        }
    },

    bulkDelete: function () {
        let selection = this.state.selection
        let originData = this.state.data
        let deleted = []
        let me = this

        selection.forEach(value => {
            let foundData = originData.filter(obj => obj[this.keyTable] === value)
            deleted.push(...foundData)
        })

        DoctorSpecialistApi.bulkDelete(JSON.stringify(deleted)).then(function (response) {
            if ( response.status !== 200 ){
            	handleToast('Something went wrong')
            	return
            }
            me.setState({
                fetchingGrid: false
            })
            let state = {
                pageSize: me.pageSize,
                page: me.page,
                sorted: me.sorted,
                search: ''
            }
            me.fetchData(state, null)
            me.setState({data: originData, deleted: deleted, selection: []})
            handleToast(response.data.message)
            me.handleCloseModal()

        }, function (err) {
            // handleToast('Something went wrong ' + err.message)
            handleToastServiceError(err, () => {
                me.handleCloseModal()
            })
        })

    },

    fetchData: async function (state, instance) {
        this.pageSize = state.pageSize
        this.page = state.page
        this.setState({loading: true})
        let sort_property = ''
        let sort_value = ''
        let res

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'DESC' : 'ASC'
            this.sorted = state.sorted
        }

        try {
            res = await DoctorSpecialistApi.get(state.page + 1, state.pageSize, sort_property, sort_value)
        } catch (e) {
            handleToastServiceError(e)
            return
        }

        if (React.isValidElement(res)) {
            return
        }

        if (res.data.code === 200) {
            let newRows = res.data.data.rows.map(item => {
            	const _id = chance.guid()
            	return {
            		_id,
            		...item
            	}
            })

            if (this._isMounted) {
                this.setState({
                    data: newRows,
                    pages: res.data.data.total_page,
                    loading: false,
                })
            }

        }
    },

    refreshData: function () {
        this.fetchData({
            page: this.page,
            pageSize: this.pageSize,
            search: '',
            sorted: this.sorted
        })
    },

    handleShow: function () {
        this.setState({modalShow: true})
    },

    handleCloseModal: function () {
        this.setState({modalShow: false})
    },

    handleConfirmModal: function () {
        let me = this
        if (this.state.actionModal === 'bulk') {
            this.bulkDelete()
            return
        }
        DoctorSpecialistApi.delete(this.state.deletedId).then(function (response) {
            let state = {
                pageSize: me.pageSize,
                page: me.page,
                sorted: me.sorted,
                search: ''
            }
            me.fetchData(state, null)
            handleToast(response.data.message)
            me.handleCloseModal()
        }, function (err) {
            handleToastServiceError(err, () => {
                me.handleCloseModal()
            })
        })
    }
}

export default DoctorSpecialistGridHandler