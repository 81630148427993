import React from 'react'
import ReactTable from 'react-table'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import { ContextMenuProvider } from 'react-contexify'
import dateFns from 'date-fns'

import MasterGrid from '../../../../../components/grid/MasterGrid'
import GridHandler from '../../../handler/superadmin/spesialities/GridHandler'
import SliderPanel from '../../../../../components/wizard/SliderPanel'
import MasterModal from '../../../../../core/utils/MasterModal'
import Checkbox from '../../../../dashboard/components/Checkbox'
import Form from './Form'
import Api from '../../../../../services/apis/SpecialitiesAPI'
import {
    handleToast,
    handleToastServiceError,
  } from "../../../../../core/utils/UtilsToast";

const CheckboxTable = checkboxHOC(ReactTable)

class Grid extends MasterGrid {
    constructor(props) {
        super(props)

        Object.assign(this, GridHandler)

        this.clickable = false
        this.filterable = false
        this.selectedTableStatus = false
        this.gridColumn = this.gridColumn.bind(this)
        this.gridMapping = (this.selectedTableStatus) ? CheckboxTable : ReactTable
        this.hidePagination = false
        this.page = 0
        this.pageSize = 20
        this.selectedMax = 10
        this.selected = []
        this.filtered = []
        this.sorted = []
        this.defaultSorted = []
        this.keyTable = '_id'
        this.ignoreColumn = [
            '_id'
        ]

        /* own property */
        this.fetchData = this.fetchData.bind(this)
        this.extraProperties = {
            onFetchData: this.fetchData,
            filterAble: true
        }

        /* data */
        const loading = this.extraProperties.hasOwnProperty('onFetchData') ? true : false
        const data = this.dataFromRedux ? this.props.data : (loading ? [] : this.getData())
        const columns = this.getColumns(data)
        const finalColumn = this.gridColumn(ContextMenuProvider, columns)
        const pages = loading ? null : 1

        this.onPanelOpen = this.onPanelOpen.bind(this)
        this.onPanelClose = this.onPanelClose.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.modalHandleSubmit = this.modalHandleSubmit.bind(this)
        this.loadDataAfterAction = this.loadDataAfterAction.bind(this)
        this.getFilter = this.getFilter.bind(this)
        this.getSpecialist = this.getSpecialist.bind(this)

        const initFilter = [
            {
                id: 'range',
                value: 'today',
            }
        ]
        this.state = {
            data,
            columns: finalColumn,
            pages: pages,
            rows: 1,
            selection: [],
            deleted: [],
            filtered: initFilter,
            disableHighlight: true,
            selectAll: false,
            isPanelOpen: false,
            panelData: {},
            isModalOpen: false,
            modalData: {},
            specialist: [],
            checkedConfirm: false,
            headerFilter: {},
            filterList:{},
            rangeOpen: false,
            loading: loading,
            loadingE: false,
        }
        this.gridType = 'Appointment'
        this.aclModule = this.gridType
        this.customFilter = ['DATE', 'DATE_RANGE']
        this.customHandleFilter = this.customHandleFilter.bind(this)

        this.currentHospital = this.props.currentHospital
    }

    componentDidMount(){
        this.getFilter();
    }
    componentWillReceiveProps(nextProps) {
        const { currentHospital } = this.props

        if (currentHospital !== nextProps.currentHospital) {
            this.currentHospital = nextProps.currentHospital

            this.fetchData({
                page: 0,
                pageSize: this.pageSize,
                filtered: [],
                sorted: []
            })
            this.customHandleFilterReset(nextProps)
        }
    }

    
    handleSync = async () => {
        try {
            
            await Api.getSync().then((res)=>{
                if(res.status === 200 ){
                    handleToast(res.data.message)
                }
            })
        } catch(error) {
            handleToastServiceError({message : 'Data Gagal di Sync'});
        }
    }

    customHandleFilter(action, value, type) {
        const { filtered, headerFilter } = this.state
        let setFiltered = filtered
        let rangeOpen = false
        let initRange = {}
        this.page = 0

        if (type === 'date') {
            const getExist = filtered.find(value => value.id === action)

            if (value !== null) {
                if (getExist) {
                    setFiltered = setFiltered.filter(value => value.id !== action)
                }

                setFiltered.push({
                    id: action,
                    value: dateFns.format(value, 'YYYY-MM-DD'),
                })
            } else {
                setFiltered = setFiltered.filter(value => value.id !== action)
            }

            this.filteredTemp = setFiltered
            this.setState({
                rangeOpen,
                filtered: setFiltered,
                headerFilter: {
                    ...headerFilter,
                    [action]: { value },
                }
            })

            this.fetchData({
                page: this.page,
                pageSize: this.pageSize,
                filtered: setFiltered,
                sorted: this.sorted,
            })
        } else if (action.indexOf('_range') > 0) {
            const actStart = action.replace('_range', '') + '_start_date'
            const actEnd = action.replace('_range', '') + '_end_date'
            const getExistStart = filtered.find(value => value.id === actStart)
            const getExistEnd = filtered.find(value => value.id === actEnd)

            if (value !== null) {
                if (getExistStart) {
                    setFiltered = setFiltered.filter(value => value.id !== actStart)
                }

                if (getExistEnd) {
                    setFiltered = setFiltered.filter(value => value.id !== actEnd)
                }

                setFiltered.push({
                    id: actStart,
                    value: dateFns.format(value[0], 'YYYY-MM-DD'),
                })

                setFiltered.push({
                    id: actEnd,
                    value: dateFns.format(value[1], 'YYYY-MM-DD'),
                })
            } else {
                rangeOpen = true
                setFiltered = setFiltered.filter(value => value.id.indexOf('_date') < 0)
            }

            this.filteredTemp = setFiltered
            this.setState({
                rangeOpen,
                filtered: setFiltered,
                headerFilter: {
                    ...headerFilter,
                    [action]: value,
                }
            })

            if (value !== null) {
                this.fetchData({
                    page: this.page,
                    pageSize: this.pageSize,
                    filtered: setFiltered,
                    sorted: this.sorted,
                })
            }
        } else {
            const getExist = filtered.find(value => value.id === action)

            if (getExist) {
                setFiltered = setFiltered.filter(value => value.id !== action)
            }

            // remove filter by custom (date range)
            const getDateRange = setFiltered.filter(d => d.value === 'DATE_RANGE')
            if (getDateRange.length === 0) {
                setFiltered = setFiltered.filter(value => value.id.indexOf('_date') < 0)
                delete headerFilter[`${action}_date`]
                delete headerFilter[`${action}_range`]
            }

            setFiltered.push({
                id: action,
                value: value.value,
            })

            if (value.value === '') {
                setFiltered = setFiltered.filter(value => value.id !== action)
            } else if (value.value === 'DATE_RANGE') {
                rangeOpen = true
            }

            this.filteredTemp = setFiltered
            this.setState({
                rangeOpen,
                filtered: setFiltered,
                headerFilter: {
                    ...headerFilter,
                    ...initRange,
                    [action]: value,
                }
            })

            if (!this.customFilter.includes(value.value)) {
                this.fetchData({
                    page: this.page,
                    pageSize: this.pageSize,
                    filtered: setFiltered,
                    sorted: this.sorted,
                })
            }
        }
    }

    render() {
        const {
            isPanelOpen,
            selection,
            panelData,
            isModalOpen,
            modalData,
            specialist,
            filterList,
            checkedConfirm,
            headerFilter,
            loading,
        } = this.state
        const filterListArr = Object.keys(filterList)
        this.selected = selection

        this.leftAction = []
        if (filterListArr.length > 0) {
            filterListArr.forEach((key, index) => {
                let roundedPos = 'middle'
                const selectDefault = filterList[key].options.filter(s => {
                    return (s.value !== '') && (s.value === filterList[key].default)
                })

                if (index === 0) {
                    roundedPos = 'left'
                } else if ((filterListArr.length - 1) === index) {
                    roundedPos = 'right'
                }

                this.leftAction.push({
                    actionType: key,
                    type: filterList[key].type,
                    title: filterList[key].placeholder,
                    tooltip: filterList[key].tooltip,
                    filterKeyword: headerFilter[key] ? headerFilter[key].value : filterList[key].default,
                    handlerSubmit: this.handleFilter,
                    selectedOpt: headerFilter[key] || selectDefault,
                    filterOpt: filterList[key].options,
                    path: filterList[key].path,
                    handlerChangeOpt: this.handleFilter,
                    handlerChange: this.handleFilter,
                    className: `rounded ${roundedPos}`,
                })
            })
        }
        this.leftAction.push({
            type: 'reset',
            loading: loading,
            tooltip: 'Reset Filter',
            handlerReset: this.handleFilterReset,
        })

        return (
            <div>
                <div className="title-wrapper">
                    <h2 className="no-margin-top page-title">{this.props.title}</h2>
                    <button onClick={() => this.handleSync()} className="sync">
                            Sync Ke Typesense
                        </button>
                    <button
                        className="add"
                        onClick={() => this.onPanelOpen()}
                    >
                        Tambah
                    </button>
                </div>
                <section className="tables with-top-filter no-paging-top">

                
                    <div className="row">
                        <div className="col-lg-12 cn-table-container">
                            <div className="card">
                                {super.render()}
                            </div>
                        </div>
                    </div>
                </section>
                <SliderPanel
                    open={isPanelOpen}
                    panelData={panelData}
                    onPanelClose={this.onPanelClose}
                >
                    <Form
                        {...this.props}
                        data={panelData}
                        specialist={specialist}
                        getSpecialist={this.getSpecialist}
                        onPanelClose={this.onPanelClose}
                        loadDataAfterAction={this.loadDataAfterAction}
                    />
                </SliderPanel>
                <MasterModal
                    className="master-modal"
                    isOpen={isModalOpen}
                    handleClose={this.handleCloseModal}
                    ModalData={modalData}
                >
                    <div className="master-modal-content emr">
                        <div className="master-modal-head">
                            Konfirmasi Penghapusan
                        </div>
                        <div className="master-modal-body">
                            <div className="content">
                                Poliklinik yang Anda pilih (<strong>{modalData ? modalData.name : ''}</strong>) akan dihapus.
                                Apakah Anda yakin?
                                <Checkbox
                                    options={[{
                                        value: true,
                                        label: 'Saya telah konfirmasi',
                                    }]}
                                    prefix="emrConfirm"
                                    onCheck={() => this.setState({ checkedConfirm: true })}
                                    onRemove={() => this.setState({ checkedConfirm: false })}
                                    items={[checkedConfirm]}
                                    class="wrap-checkbox-left emr-confirm"
                                />
                            </div>
                        </div>
                        <div className="master-modal-foot">
                            <button
                                className={`master-btn confirm${!checkedConfirm ? ' disabled' : ''}`}
                                disabled={!checkedConfirm}
                                onClick={() => this.modalHandleSubmit(modalData)}
                            >
                                Lanjutkan
                            </button>
                            <button
                                className="master-btn cancel"
                                onClick={this.handleCloseModal}
                            >
                                Batalkan
                            </button>
                        </div>
                    </div>
                </MasterModal>
            {/* </section> */}
            </div>
        )
    }
}

export default Grid