import Api from '../../../../services/apis/doctor/DoctorSchedule'
import dateFns from "date-fns";
import IndonesianLocale from 'date-fns/locale/id'
import UrlConfig from '../config/UrlConfig'
import { handleToast, handleToastServiceError } from '../../../../core/utils/UtilsToast'

const MainHandler = {
    handleOpenModal(ModalData) {
        this.setState({
            isModalOpen: true,
            ModalData: ModalData
        })
    },
    handleCloseModal(){
        this.setState({
            isModalOpen: false,
            ModalData: null
        })
    },
    onPanelClose(){
        this.setState({
            selectedDate: null,
            isPaneOpen: false
        });
    },
    onPanelOpen(day){
        let selectedDayStr = null
        let fullDateStr = null
        const isLoading = this.state.isFetchingSchedule
        if(day){
            selectedDayStr = dateFns.format(day, 'dddd', {locale:IndonesianLocale})
            fullDateStr = dateFns.format(day, 'D MMMM YYYY', {locale:IndonesianLocale})
        }
        
        if(!isLoading){
            this.setState({
                selectedDate: day,
                isPaneOpen: true,
                panelData: {
                    headTitle: selectedDayStr,
                    subTitle: fullDateStr
                }
            });
        }
    },

    nextMonth() {
        this.setState({
            currentMonth: dateFns.addMonths(this.state.currentMonth, 1),
            isFetchingSchedule: true,
            listSchedule: []
        })
    },

    prevMonth() {
        this.setState({
            currentMonth: dateFns.subMonths(this.state.currentMonth, 1),
            isFetchingSchedule: true,
            listSchedule: []
        })
    },
    fetchSchedule(selectedMonth) {
        const doctorId = this.state.doctorId
        const year = dateFns.format(selectedMonth, 'YYYY')
        const month = dateFns.format(selectedMonth, 'M')
        const Schedule = Api.get(doctorId, year, month)

        Schedule.then(response => {
            if(response && response.data){
                const scheduleData = response.data.data.schedule_data
                    ? response.data.data.schedule_data
                    : []
                const doctorData = response.data.data.doctor_data
                    ? response.data.data.doctor_data
                    : []
                const operational = response.data.data.operational
                    ? response.data.data.operational
                    : []

                const doctorDataFixed = {
                    ...doctorData,
                    status: doctorData.doctor_status
                }
                this.setState({
                    listSchedule: scheduleData,
                    disableFinish: scheduleData.length === 0 ? true : false,
                    doctorData: doctorDataFixed,
                    operational: operational,
                    isFetchingSchedule: false,
                    isModalFetching: false,
                    redirectTo: doctorData.length <= 0 ? UrlConfig.GridUrl : null,
                    customTitleWizard: doctorData.doctor_status === 1 ? doctorData.name : null,
                    backUrlToGrid: doctorData.doctor_status === 1 ? true : false
                })
            }
        })
    },
    modalHandleSubmit() {
        const ModalData = this.state.ModalData
        const hospitalId = this.state.hospitalId
        if (ModalData.action === 'delete_schedule') {
            const DeleteSchedule = Api.delete(ModalData.id)
            DeleteSchedule.then(response => {
                if (response && response.data.code === 200) {
                    this.triggerRefresh(ModalData.id)
                    this.handleCloseModal()
                }
            }).catch(e => {
                handleToastServiceError(e)
                this.handleCloseModal()
            });
        } else if (ModalData.action === 'delete_doctor') {
            const DeleteDraft = Api.deleteDraft(hospitalId, ModalData.id)
            DeleteDraft.then(response => {
                if (response && response.data.code === 200) {
                    this.redirectTo(UrlConfig.GridUrl)
                }
            })
        } else if (ModalData.action === 'finish_step_two') {
            const Finish = Api.finish(ModalData.id)
            Finish.then(response => {
                if (response && response.data.code === 200) {
                    this.redirectTo(UrlConfig.GridUrl)
                    handleToast(response.data.message)
                }
            })
        } else if (ModalData.action === 'cancel') {
            this.redirectTo('/doctor/list')
        }
    },
    triggerRefresh(id) {
        const selectedMonth = this.state.currentMonth
        this.setState({
            isFetchingSchedule: true,
            isModalFetching: true,
            idToDelete: id,
        })
        this.fetchSchedule(selectedMonth)
    },
    onBeforeCloseWizard() {
        const ModalData = {
            action: 'cancel',
            content: "Apakah anda yakin ingin keluar?",
            buttonConfirmText: "Ya, Keluar",
            buttonCancelText: "Tidak"
        }
        this.handleOpenModal(ModalData)
    },
    handleButtonFinish(){
        const ModalData = {
            action: "finish_step_two",
            id: this.state.doctorId,
            content: "Apakah anda sudah selesai melengkapi data informasi dan jadwal dokter?",
            buttonConfirmText: "Ya, Selesai",
            buttonCancelText: "Tidak, saya belum selesai"
        }
        this.handleOpenModal(ModalData)
    }
}

export default MainHandler