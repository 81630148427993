import React, { Component } from 'react'
import MasterTemplate from '../../template/MasterTemplate'
import UserFormComponent from './components/UserFormComponent'

export default class UserForm extends Component
{
    constructor(props) {
        super(props)
        this.detail = true
    
        let state = this.props.store.getState()
        this.state = {
            token: state.loginReducer.token,
            path: window.location.hash,
        }       
    }
    render() {
        const { match } = this.props
        const breadcrumbs = [
            {
                label: 'Dashboard',
                path: '/dashboardhospital',
            },
            {
                label: 'Pengaturan',
                path: '/administration/info-hospital',
            },
            {
                label: 'Pengaturan Admin',
                path: '/administration/user',
            },
            {
                label: match.path.search('/edit/') > -1 ? 'Edit Admin' : 'Tambah Admin',
                path: null,
            },
        ]

        return (
            <MasterTemplate {...this.props} breadcrumbs={breadcrumbs} detail={this.detail}>
                <UserFormComponent {...this.props} />
            </MasterTemplate>
        )
    }
}