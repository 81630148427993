import React, {Component} from 'react'

class Checkbox extends Component
{
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
    }

    onChange(e){
        const prefix = this.props.prefix
        const value = parseInt(e.target.value, 10)
        const action = e.target.checked
        
        if(action){
            this.props.onCheck(prefix, value)
        }else{
            this.props.onRemove(prefix, value)
        }
    }

    render() {
        const currentVal = this.props.items
        return(
            <div className="wrap-options">
                {
                    this.props.options.map((option, index) => {
                        return (
                            <div key={ index } className={this.props.class}>
                                <label htmlFor={ 'label_' + this.props.prefix + '_' + index } className="checkbox-label">
                                    <input 
                                        name={ 'chk_' + this.props.prefix }
                                        id={ 'label_' + this.props.prefix + '_' + index }
                                        type="checkbox"
                                        value={option.value}
                                        onChange= { this.onChange }
                                        checked={currentVal.includes(option.value) ? true : false}
                                    />
                                    { option.label }
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        )
                    })       
                }
            </div>
        )
    }
}
export default Checkbox