/* eslint-disable */

import React, { Component } from 'react'
import { DropTarget } from 'react-drag-drop-container'
import { connect } from 'react-redux'
import dateFns from "date-fns"
import IndonesianLocale from 'date-fns/locale/id'

import MasterTemplate from '../../template/MasterTemplate'
import AccessPermission from '../../template/AccessPermission'
import BuilderConfig from './config/FormBuilderConfig'
import CustomFieldContainer from './components/FormBuilder/CustomFieldContainer'
import Tool from './components/FormBuilder/Tool'
import MasterModal from '../../core/utils/MasterModal'
import PreviewModal from './components/FormBuilder/PreviewModal'
import { handleToast, handleToastServiceError } from '../../core/utils/UtilsToast'
import Api from '../../services/apis/Hospital/List'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../core/const/LoginConst'

class FormBuilder extends Component
{
    constructor(props){
        super(props)
        this.state = {
            hospitalId: this.props.currentHospital,
            lastUpdate: null,
            isModalOpen: false,
            ModalData: null,
            builderData: [],
            previewMode: false,
            formData: {
                title: "",
                enableToSubmit: false,
                isSubmitting: false
            },
        }
        this.handleItemDrop = this.handleItemDrop.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.mutateBuilderData = this.mutateBuilderData.bind(this)
        this.handleChangeTitle = this.handleChangeTitle.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.modalHandleSubmit = this.modalHandleSubmit.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleClearSubmit = this.handleClear.bind(this)
        this.togglePreview = this.togglePreview.bind(this)
        this.showLoading = this.showLoading.bind(this)
    }

    getDefaultFieldType(key){
        switch(key) {
            case 'input_text_free':
                return 'text'
            default:
                return null
        }
    }

    handleItemDrop(item){
        let willBuildItem = this.state.builderData
        willBuildItem.push({
            id: `fielditem-${willBuildItem.length + 1}`,
            fieldKey: item.dragData,
            fieldSubType: this.getDefaultFieldType(item.dragData),
            fieldLabel: "",
            fieldOptional: false,
            option: []
        })
        
        this.mutateBuilderData(willBuildItem)
    }

    handleAdd(option){
        let willBuildItem = this.state.builderData
        willBuildItem.push({
            id: `fielditem-${willBuildItem.length + 1}`,
            fieldKey: option,
            fieldSubType: this.getDefaultFieldType(option),
            fieldLabel: "",
            fieldOptional: false,
            option: []
        })
        
        this.mutateBuilderData(willBuildItem)
    }

    mutateBuilderData(newData){
        const status = this.isEnableToSubmit(newData)
        const lengthTitleForm = this.state.formData.title.length
        this.setState({
            builderData: newData,
            formData: {
                ...this.state.formData,
                enableToSubmit: lengthTitleForm <= 0 ? false : status
            }
        })
    }

    isEnableToSubmit(data){
        let status = this.state.formData.enableToSubmit;
        for(var i=0; i<data.length; i++){
            if(data[i].fieldLabel.length <= 0){
                status = false
                break
            }

            if(["dropdown", "radio", "checkbox"].indexOf(data[i].fieldKey) !== -1 && data[i].option.length <= 0){
                status = false
                break
            }
            
            status = true
        }

       return status
    }

    handleChangeTitle(ev){
        const status = this.isEnableToSubmit(this.state.builderData)
        this.setState({
            formData: {
                title: ev.target.value,
                enableToSubmit: ev.target.value.length > 0 ? status : false
            }
        })
    }

    handleOpenModal(ModalData) {
        this.setState({
            isModalOpen: true,
            ModalData: ModalData
        })
    }

    handleCloseModal(){
        this.setState({
            isModalOpen: false,
            ModalData: null
        })
    }

    modalHandleSubmit(){
        const ModalData = this.state.ModalData
        
        if(ModalData.action === 'delete_custom_field'){
            let newRebase = []
            const mutatedData = this.state.builderData.filter((el) => (
                el.id !== ModalData.id
            ))

            mutatedData.map((value, key) => (
                newRebase.push({
                    id: `fielditem-${key + 1}`,
                    fieldKey: value.fieldKey,
                    fieldSubType: value.fieldSubType,
                    fieldLabel: value.fieldLabel,
                    fieldOptional: value.fieldOptional,
                    required: value.required,
                    option: value.option
                })
            ))
            const status = mutatedData.length > 0 ? this.isEnableToSubmit(mutatedData) : false
            this.setState({
                builderData: newRebase,
                formData: {
                    ...this.state.formData,
                    enableToSubmit: this.state.formData.title.length > 0 ? status : false
                },
                isModalOpen: false,
                ModalData: null
            })
        }else{
            this.setState({
                builderData: [],
                isModalOpen: false,
                ModalData: null
            })
        }
    }

    showLoading(){
        this.setState({
            formData: {
                ...this.state.formData,
                isSubmitting: true
            }
        })  
    }

    handleClear(ev){
        ev.preventDefault()
        if(this.state.formData.enableToSubmit){
            const ModalData = {
                action: "clear_all",
                id: null,
                content: "Anda akan menghapus semua custom field pasien. <br />Apakah Anda Yakin?",
                buttonConfirmText: "Ya, Hapus Semua Custom Field",
                buttonCancelText: "Batalkan",
                ConfirmFunction: null
            }
            this.handleOpenModal(ModalData)
        }
    }

    handleSubmit(ev){
        ev.preventDefault()

        if(this.state.formData.enableToSubmit){
            this.showLoading()
            const formTitle = this.state.formData.title
            const storeToApi = {
                formTitle: formTitle,
                formBuilder: this.state.builderData
            }

            Api.updateFormPatient(this.props.currentHospital, JSON.stringify(storeToApi)).then(function(response){
                if (response.status !== 200) {
                    handleToastServiceError(response)
                }else{
                    handleToast('Formulir Pasien Berhasil Disimpan')
                }
                
                this.setState({
                    lastUpdate: response.data.data.field_patient_updated_at,
                    formData: {
                        ...this.state.formData,
                        isSubmitting: false
                    }
                }) 
            }.bind(this))
        }

        return false
    }

    togglePreview(){
        if(this.state.formData.enableToSubmit){
            this.setState({
                previewMode: !this.state.previewMode
            })
        }
    }

    componentDidMount() {
        const hospitalId = this.state.hospitalId

        Api.view(hospitalId).then(function (response) {
            if (response.data.code === 200) {
                if (response.data.data) {
                    const newState = JSON.parse(response.data.data.custom_patient_fields)
                    if (newState && newState.formBuilder) {
                        const status = this.isEnableToSubmit(newState.formBuilder)
                        this.setState({
                            lastUpdate: response.data.data.field_patient_updated_at,
                            builderData: newState.formBuilder,
                            formData: {
                                ...this.state.formData,
                                enableToSubmit: status,
                                title: newState.formTitle,

                            }
                        })
                    }
                }
            }
        }).catch(() => {
            handleToastServiceError({ message: 'Something went wrong' })
        })
    }
    
    render() {
        const builderData = this.state.builderData
        const isSubmitting = this.state.formData.isSubmitting
        const breadcrumbs = [
            {
                label: 'Dashboard',
                path: '/dashboardhospital',
            },
            {
                label: 'Pengaturan',
                path: '/administration/info-hospital',
            },
            {
                label: 'Formulir Pasien',
                path: null,
            },
        ]

        return (
            <AccessPermission {...this.props} module="setting_hospital">
                <MasterTemplate {...this.props} breadcrumbs={breadcrumbs}>
                    <div className="container-fluid wrap-form-builder">
                        <div className="wrap-form-basket">
                            <div className="last-update">
                                Terakhir disimpan
                            <span>
                                    {
                                        this.state.lastUpdate ?
                                            dateFns.format(this.state.lastUpdate, 'HH:mm, D MMMM YYYY', { locale: IndonesianLocale }) :
                                            null
                                    }
                                </span>
                            </div>
                            <form className="box-form" onSubmit={(ev) => this.handleSubmit(ev)}>
                                <CustomFieldContainer
                                    builderData={builderData}
                                    formData={this.state.formData}
                                    mutateBuilderData={this.mutateBuilderData}
                                    handleChangeTitle={this.handleChangeTitle}
                                    handleOpenModal={this.handleOpenModal}
                                    togglePreview={this.togglePreview}
                                />
                                <DropTarget
                                    targetKey={BuilderConfig.targetDrop}
                                    dropData={builderData}
                                >
                                    <span>Geser Kesini</span>
                                </DropTarget>

                                {
                                    !isSubmitting ?
                                        <div className="form-buttons">
                                            <a
                                                className={this.state.formData.enableToSubmit ? "clearall" : "clearall disabled"}
                                                onClick={(e) => this.handleClear(e)}
                                            >Clear All</a>
                                            <a
                                                className={this.state.formData.enableToSubmit ? "preview" : "preview disabled"}
                                                onClick={this.togglePreview}
                                            >Preview</a>
                                            <a
                                                disabled={!this.state.formData.enableToSubmit}
                                                className={this.state.formData.enableToSubmit ? "submit" : "submit disabled"}
                                                onClick={(e) => this.handleSubmit(e)}
                                            >Simpan</a>
                                        </div>
                                        : <div>Loading...</div>
                                }
                            </form>
                        </div>
                        <Tool handleItemDrop={this.handleItemDrop} handleAdd={this.handleAdd} />
                    </div>
                    <MasterModal
                        className="master-modal"
                        isOpen={this.state.isModalOpen}
                        handleClose={this.handleCloseModal}
                        handleSubmit={this.modalHandleSubmit}
                        ModalData={this.state.ModalData}
                        isModalFetching={this.state.isModalFetching}
                    />
                    <PreviewModal
                        {...this.props}
                        previewMode={this.state.previewMode}
                        togglePreview={this.togglePreview}
                        formData={this.state.formData}
                        builderData={this.state.builderData}
                    />
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(FormBuilder)
