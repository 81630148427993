import Api from '../../../../../../services/apis/TitleApi'
import {
    handleToast,
    handleToastServiceError
} from '../../../../../../core/utils/UtilsToast'

const FormHandler = {
    buildForm() {
        this.nameField = this.TextInputFormView({
            validator: "required",
            label: "Label",
            name: "name",
            placeholder: "Label",
            control: this.state.Form.controls.name
        })

        this.titleField = this.TextInputFormView({
            validator: "required",
            label: "Value",
            name: "title",
            placeholder: "Value",
            control: this.state.Form.controls.title
        })

        this.sortField = this.TextInputFormView({
            label: "Sort",
            name: "sort",
            placeholder: "Sort",
            inputType: "number",
            control: this.state.Form.controls.sort
        })
    },
    buildValue(data) {
        this.state.Form.patchValue(data)
    },
    handleSubmit: async function (e) {
        e.preventDefault();

        let response = {}
        const { dataEdit } = this.props
        const formValue = Object.assign({}, this.state.Form.value)

        try {
            if (dataEdit) {
                response = await Api.put(dataEdit.id, formValue)
            } else {
                response = await Api.post(formValue)
            }

            handleToast(response.data.message)

            this.props.loadDataAfterAction()
            this.props.onPanelClose()
        } catch (e) {
            handleToastServiceError(e)
        }
    }
}

export default FormHandler
