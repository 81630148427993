import React from 'react'

class TextFilter extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        const { filterData } = this.props

        this.setState((prevState) => ({
            value: filterData.value || prevState.value
        }))
    }

    handleChange(e) {
        this.setState({
            value: e.target.value,
        })
    }

    handleSubmit(data) {
        if (data.key === 'Enter') {
            const filterData = this.props.filterData
            filterData.handler(filterData.filterType, data.target.value)
        }
    }

    render() {
        const { value } = this.state
        const { filterData } = this.props
        return (
            <div className={`form-line-filter ${filterData.className}`}>
                <input
                    type={filterData.type}
                    name={filterData.title}
                    placeholder={filterData.placeholder}
                    onChange={this.handleChange}
                    onKeyDown={this.handleSubmit}
                    value={value}
                />
            </div>
        )
    }
}

export default TextFilter