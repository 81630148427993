
import ApiConfig from "./ApiConfig"
import ApiConfigWithBarear from "./ApiConfigWithBarear"
import axios from "axios"

// const groupUrl = "users/sign_in"
const loginUrl = "auth/login"
const forgotPassUrl = "account/forgotpassword"
const resetPassUrl = "account/changepassword"
const confirmDoctor = "account/confirm"
const refreshTokenUrl = "auth/refresh"
const restrictedUrl = "restricted/index"

class LoginApi {
    static login = json => axios.post(`/${ loginUrl }`, json, ApiConfig)
    static confirmDoctor = data => axios.post(`/${ confirmDoctor }`, data, ApiConfig)
    static forgotpass = data => axios.post(`/${ forgotPassUrl }`, data, ApiConfig)
    static resetpass = (data) => axios.post(`/${ resetPassUrl }`, data, ApiConfig)
    static check = (data) => axios.post(`/account/checkactivation`, data, ApiConfig)
    static refreshToken = (token, data) => axios.post(`/${refreshTokenUrl}`, data, ApiConfigWithBarear(token))
    static restricted = (token, data) => axios.post(`/${restrictedUrl}`, data, ApiConfigWithBarear(token))
}

export default LoginApi
//http://localhost:4000/api/users/sign_in