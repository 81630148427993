import React from 'react'
import Modal from 'react-modal'

class ModalWizard extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isSubmiting: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleAfterOpen = this.handleAfterOpen.bind(this)
    }

    handleSubmit(){
        this.setState({
            isSubmiting: true,
        })
        this.props.handleSubmit()
    }

    handleClose(){
        this.setState({
            isSubmiting: false,
        })
        this.props.handleClose()
    }

    handleAfterOpen(){
        this.setState({
            isSubmiting: false,
        })
    }

    render(){
        let content = ''
        let confirmText = ''
        let cancelText = ''
        let disableLoader = false
        if(this.props.ModalData){
            content = this.props.ModalData.content
            confirmText = this.props.ModalData.buttonConfirmText
            cancelText = this.props.ModalData.buttonCancelText
            disableLoader = this.props.ModalData.disableLoader
        }

        const ModalButton = () => {
            if(this.state.isSubmiting && !disableLoader){
                return(<div className="wizard-loading">Loading</div>)
            }

            return(
                <div className="wizard-modal-foot">
                    <button className="wizard-btn confirm" onClick={this.handleSubmit}>{confirmText}</button>
                    <button className="wizard-btn cancel" onClick={this.handleClose}>{cancelText}</button>
                </div>
            )
        }

        return(
            <Modal
                className={this.props.className}
                isOpen={this.props.isOpen}
                onAfterOpen={this.handleAfterOpen}
                onRequestClose={this.handleClose}
                shouldCloseOnOverlayClick={true}
            >
                <div className="wizard-modal-content">
                    <div className="wizard-modal-head">
                        <span className="icon-exclamation">!</span>
                    </div>
                    <div className="wizard-modal-body" >
                        <div className="content" dangerouslySetInnerHTML={{__html:content}} />
                    </div>
                    <ModalButton />
                </div>
            </Modal>
        )
    }
}

export default ModalWizard