import React from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import { loginMapStateToProps, loginMapDispatchToProps } from '../../core/const/LoginConst'
import RoleHelper from '../../core/helper/RoleHelper'
import Api from '../../services/apis/Appointment/List'
import ReasonApi from '../../services/apis/ReasonApi'
import MasterTemplate from '../../template/MasterTemplate'
import AccessPermission from '../../template/AccessPermission'
import Grid from './components/Grid'

class Main extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            reasonList: [],
            filterList: {},
        }

        this.currentHospital = props.currentHospital
        this.isDoctor = RoleHelper.isDoctor(props.dataLogin, this.currentHospital)
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.filterList();
    }

    componentWillReceiveProps(nextProps) {
        const { currentHospital } = this.props

        if (currentHospital !== nextProps.currentHospital) {
            this.currentHospital = nextProps.currentHospital
            this.filterList()
        }
    }

    loadNotes = async () => {
        let response;
        let reasonList = [];

        if (this.state.reasonList.length === 0) {
            try {
                response = await ReasonApi.get();
                reasonList = response.data.data.rows;
            } catch (e) {
                console.log(e)
            }
    
            this.setState({ reasonList });
        }
    }

    filterList = async () => {
        let response;
        let filterList = {};

        try {
            response = await Api.filterList(this.currentHospital, this.isDoctor);
        } catch (e) {
            console.log(e)
        }

        if (!isEmpty(response) && response.data.code === 200) {
            filterList = response.data.data
        }

        this.setState({ filterList });
    }

    render() {
        const { reasonList, filterList } = this.state;
        const { history, store } = this.props
        const breadcrumbs = [
            {
                label: 'Dashboard',
                path: '/dashboardhospital',
            },
            {
                label: 'Daftar Appointment',
                path: null,
            },
        ]

        return (
            <AccessPermission {...this.props} module="appointment">
                <MasterTemplate {...this.props} breadcrumbs={breadcrumbs}>
                    <Grid
                        {...this.props}
                        callBackState={this.callBackState}
                        history={history}
                        store={store}
                        loadNotes={this.loadNotes}
                        reasonList={reasonList}
                        isDoctor={this.isDoctor}
                        filterList={filterList}
                    />
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Main)
