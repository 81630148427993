import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import { loginMapStateToProps, loginMapDispatchToProps } from '../../core/const/LoginConst'
import AccessPermission from '../../template/AccessPermission'
import MasterTemplate from '../../template/MasterTemplate'
import RoleHelper from '../../core/helper/RoleHelper'
import Grid from './components/Grid'
import Api from '../../services/apis/SpecialitiesAPI'

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterList: {},
        }
        this.currentHospital = props.currentHospital
        this.isDoctor = RoleHelper.isDoctor(props.dataLogin, this.currentHospital)
    }

    filterList = async () => {
        let response;
        let filterList = {};

        try {
            response = await Api.filterList(this.currentHospital, this.isDoctor);
        } catch (e) {
            console.log(e)
        }

        if (!isEmpty(response) && response.data.code === 200) {
            filterList = response.data.data
        }

        this.setState({ filterList });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.filterList();
    }

    componentWillReceiveProps(nextProps) {
        const { currentHospital } = this.props

        if (currentHospital !== nextProps.currentHospital) {
            this.currentHospital = nextProps.currentHospital
            this.filterList()
        }
    }

    render() {
        const { filterList } = this.state;
        const { title } = this.props
        const breadcrumbs = [
            {
                label: 'Dashboard',
                path: '/dashboardhospital',
            },
            {
                label: 'Pengaturan',
                path: '/administration/info-hospital',
            },
            {
                label: `Daftar ${title}`,
                path: null,
            },
        ]

        return (
            <AccessPermission {...this.props} module="setting_hospital">
                <MasterTemplate {...this.props} breadcrumbs={breadcrumbs}>
                    <Grid {...this.props} filterList={filterList}/>
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(List)