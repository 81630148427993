import React from 'react'
import Chance from 'chance'
import { isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'

import Api from '../../../services/apis/SpecialitiesAPIFaskes'
import { handleToast, handleToastServiceError } from '../../../core/utils/UtilsToast'

const chance = new Chance();

const GridHandler = {
    onPanelOpen(data) {
        const { title: titleProps } = this.props

        let title = `Tambah Grup ${titleProps} Baru`
        const isEdit = !isEmpty(data)

        if (isEdit) title = `Edit Grup ${titleProps}`

        this.setState({
            isPanelOpen: true,
            panelData: {
                headTitle: <div>{title}</div>,
                isEdit,
                ...data
            },
        })
    },
    onPanelClose() {
        this.setState({
            isPanelOpen: false,
            panelData: {},
        })
    },
    handleOpenModal(modalData) {
        this.setState({
            isModalOpen: true,
            modalData,
        })
    },
    handleCloseModal() {
        this.setState({
            checkedConfirm: false,
            isModalOpen: false,
            modalData: null,
        })
    },
    handleDelete(data) {
        const modalData = {
            ...data,
            action: 'delete',
        }

        this.handleOpenModal(modalData)
    },
    getSpecialist: async function (keyword) {
        await Api.specialist(this.currentHospital, keyword).then(({ data }) => {
            const { status, data: dataRes } = data

            if (status) {
                this.setState({ specialist: dataRes.rows })
            }
        })
    },
    getDetail: async function (id) {
        this.setState({ loading: true })

        await Api.detail(this.currentHospital, id).then(({ data }) => {
            const { status, data: dataRes, message } = data

            this.setState({ loading: false })

            if (status) {
                this.onPanelOpen(dataRes)
            } else {
                handleToastServiceError({ message })
            }
        })
    },
    modalHandleSubmit: async function () {
        const { modalData } = this.state

        if (modalData.action === 'delete') {
            try {
                const response = await Api.delete(this.currentHospital, modalData.id)
                handleToast(response.data.message)

                this.loadDataAfterAction();
                this.handleCloseModal()
            } catch (e) {
                handleToastServiceError(e)
                this.handleCloseModal()
            }
        }
    },
    loadDataAfterAction() {
        const state = {
            pageSize: this.pageSize,
            page: this.page,
            sorted: this.sorted,
            filter: '',
            filtered: this.state.filtered,
        }

        this.fetchData(state)
    },
    gridColumn() {
        return [
            {
                className: "grid-icon",
                width: 38,
                Cell: (row) => {
                    if(!row.original.icon){
                        return ''
                    }
                    return <img src={row.original.icon} alt='speciality icon'/>
                }
            },
            {
                Header: 'Nama Spesialisasi Utama',
                accessor: 'name',
                headerClassName: '-sort',
                Cell: (row) => {
                    return row.original.name || '-'
                },
            },
            {
                Header: 'Daftar Spesialisasi',
                accessor: 'specialities',
                sortable: false,
                Cell: (row) => {
                    if (row.original.specialities.length === 0) {
                        return '-'
                    }

                    return (
                        <ul>
                            {row.original.specialities.map(item => (
                                <li key={item.value}>{item.label}</li>
                            ))}
                        </ul>
                    )
                },
            },
            {
                Header: 'Berbayar',
                accessor: 'is_paid',
                maxWidth: 180,
                sortable: false,
                Cell: ({ original }) => {
                    const label = original.is_paid
                        ? 'Ya'
                        : 'Tidak'
                    const badgeStyle = original.is_paid
                        ? 'badge-primary'
                        : 'badge-danger'

                    return (
                        <div className="badge-label">
                            <span className={`badge badge-pill ${badgeStyle}`}>
                                {label}
                            </span>
                        </div>
                    )
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                maxWidth: 180,
                headerClassName: '-sort',
                Cell: ({ original }) => {
                    const label = original.status
                        ? 'Aktif'
                        : 'Non Aktif'
                    const badgeStyle = original.status
                        ? 'badge-primary'
                        : 'badge-danger'

                    return (
                        <div className="badge-label">
                            <span className={`badge badge-pill ${badgeStyle}`}>
                                {label}
                            </span>
                        </div>
                    )
                },
            },
            {
                Header: 'Posisi No',
                accessor: 'sort_order',
                maxWidth: 180,
                sortable: false
            },
            {
                Header: 'Action',
                maxWidth: 60,
                sortable: false,
                className: 'grid-action',
                Cell: ({ original }) => {
                    return (
                        <div className="grid-btn-wrapper">
                            <button>
                                <FontAwesomeIcon
                                    icon={faPencilAlt}
                                    onClick={() => this.getDetail(original.id)}
                                />
                            </button>
                            <button>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    onClick={() => this.handleDelete(original)}
                                />
                            </button>
                        </div>
                    )
                },
            },
        ]
    },
    fetchData: async function (state) {
        this.setState({ loading: true })
        this.page = state.page
        this.pageSize = state.pageSize

        let res = {}
        let fArray = {}
        let sort_property = ''
        let sort_value = ''

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'DESC' : 'ASC'
            this.sorted = state.sorted
        }

        if (state.filtered && state.filtered.length > 0) {
            state.filtered.map(val => {
                if (val.value !== '') {
                    fArray[val.id] = val.value
                }
                return true
            })
        }

        const filterData = JSON.stringify(fArray)

        try {
            res = await Api.list(
                this.currentHospital,
                this.page + 1,
                this.pageSize,
                sort_property,
                sort_value,
                filterData,
            )
        } catch (error) {
            this.setState({ loading: false })

            let message = error.message
            let errData = error.response ? error.response.data : null

            if (!isEmpty(errData) && errData.data !== '') {
                errData = errData.data
                message = Object.keys(errData).map(k => errData[k]).join('\n')
            }

            handleToastServiceError({ message })
            return
        }

        const {
            status,
            data: resData,
        } = res

        if (status === 200) {
            const newRows = resData.data.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })

            this.setState({
                loading: false,
                data: newRows,
                pages: resData.data.total_page,
                rows: resData.data.total_rows,
            })
        } else {
            this.setState({ loading: false })
        }
    },
}

export default GridHandler