import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import dateFns from "date-fns";
import IndonesianLocale from 'date-fns/locale/id'

import { loginMapStateToProps, loginMapDispatchToProps } from '../../../../core/const/LoginConst'
import ScheduleHandler from '../handler/Schedule'
import icoLeft from '../../../../themes/v1/img/layout/icon/prev.png'
import icoRight from '../../../../themes/v1/img/layout/icon/next.png'
import SliderPanel from '../../../../components/wizard/SliderPanel'
import SlideContent from './../components/SlideContent'
import WizardModal from '../../../../core/utils/wizard/ModalWizard'
import ButtonEditSchedule from '../components/buttons/ButtonEditSchedule'

class Schedule extends Component
{
    constructor(props) {
        super(props)
        const HospitalId = props.currentHospital
        this.state = {
            currentMonth: new Date(),
            isFetchingSchedule: true,
            isModalFetching: false,
            doctor: {
                id: this.props.match.params.id,
                name: null,
                title: null,
            },
            hospitalId: HospitalId,
            listSchedule: [],
            isPaneOpen: false,
            panelData: null,
            selectedDate: null,
            selectedScheduleId: null,
            scheduleData: null,
            isModalOpen: false,
            ModalData: null,
            chosenPatient: null
        }

        Object.assign(this, ScheduleHandler)
        this.prevMonth = this.prevMonth.bind(this)
        this.nextMonth = this.nextMonth.bind(this)
        this.onPanelClose = this.onPanelClose.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.modalHandleSubmit = this.modalHandleSubmit.bind(this)
        this.triggerRefresh = this.triggerRefresh.bind(this)
        this.viewPatient = this.viewPatient.bind(this)
        this.clearViewPatient = this.clearViewPatient.bind(this)
        this.onHandleBackSlide = this.onHandleBackSlide.bind(this)
        this.saveScheduleOpen = this.saveScheduleOpen.bind(this)
        this.changeHospital = this.changeHospital.bind(this)
    }

    saveScheduleOpen(obj){
        this.setState({
            scheduleData: obj
        })
    }

    changeHospital(id){
        this.setState({
            hospitalId: id
        })
    }

    viewPatient(obj){
        this.setState({
            chosenPatient: obj,
            panelData: {
                ...this.state.panelData,
                headTitle: 'Daftar Antrian',
            }
        })
    }

    clearViewPatient(){
        this.setState({
            chosenPatient: null,
            panelData: {
                ...this.state.panelData,
                headTitle: dateFns.format(this.state.selectedDate, 'dddd', {locale:IndonesianLocale})
            }
        })
    }

    isOwner = (newCurrentHosp) => {
        const {
            dataLogin: {
                hospitals
            }
        } = this.props

        const hospitalSelected = hospitals.find(option => {
            return String(option.id) === String(newCurrentHosp)
        })

        return !isEmpty(hospitalSelected) ? hospitalSelected.owner : false
    }

    renderHeader() {
        const dateFormat = "MMMM YYYY";
        return (
            <div className="header header-information flex-middle">
                <center>
                    <button className="month-arrow" onClick={this.prevMonth}>
                        <img src={icoLeft} alt="prev" />
                    </button>
                    <span>
                        {dateFns.format(this.state.currentMonth, dateFormat, { locale: IndonesianLocale })}
                    </span>
                    <button className="month-arrow" onClick={this.nextMonth}>
                        <img src={icoRight} alt="next" />
                    </button>
                </center>
                <ButtonEditSchedule
                    {...this.props}
                    doctorId={this.props.idDoctor}
                    isOwner={this.isOwner} />
            </div>
        )
    }

    renderDays() {
        const dateFormat = "dddd";
        const days = [];
        let startDate = dateFns.startOfWeek(this.state.currentMonth);
        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="col col-center" key={i}>
                    {dateFns.format(dateFns.addDays(startDate, i), dateFormat, { locale: IndonesianLocale })}
                </div>
            );
        }
        return <div className="days row">{days}</div>
    }

    renderScheduleInDate(formatedDay, currentDate, checkIsThisMonth, day){
        const currentYear = currentDate.getFullYear()
        const currentMonth = currentDate.getMonth() + 1
        const scheduleDates = this.state.listSchedule


        if(scheduleDates && scheduleDates[currentYear] && checkIsThisMonth){
            const schedule = scheduleDates[currentYear][currentMonth]
            if(schedule && schedule[formatedDay]){
                const eventPatch = schedule[formatedDay][0]
                return(
                    <ul className="schedule-in-day">
                        {eventPatch.map((val, key) => {
                            if(key < 2){
                                return(
                                    <li key={key} onClick={() => this.onPanelOpen(day, val.id)}>
                                        {val['time_start'].slice(0, -3)} - {val['time_end'].slice(0, -3)}
                                    </li>
                                )
                            }

                            return null
                        })}
                    </ul>
                )
            }
        }

        return null
    }

    onHandleBackSlide(){
        this.setState({
            chosenPatient: null,
            panelData: {
                ...this.state.panelData,
                headTitle: dateFns.format(this.state.selectedDate, 'dddd', {locale:IndonesianLocale})
            }
        })
    }

    renderBody() {
        const { currentMonth, selectedDate } = this.state
        const monthStart = dateFns.startOfMonth(currentMonth)
        const monthEnd = dateFns.endOfMonth(monthStart)
        const startDate = dateFns.startOfWeek(monthStart)
        const endDate = dateFns.endOfWeek(monthEnd)
    
        const dateFormat = "D"
        const rows = []
    
        let days = []
        let day = startDate
        let formattedDate = ""

        const currentDate = new Date()
        let isPast = false
    
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = dateFns.format(day, dateFormat)
                const cloneDay = day
                const checkIsThisMonth = dateFns.isSameMonth(day, monthStart) ? true : false
                
                const isCurrentDay = dateFns.isSameDay(currentDate, cloneDay)
                isPast = !isCurrentDay && dateFns.isPast(cloneDay)

                const scheduleComponent = this.renderScheduleInDate(formattedDate, currentMonth, checkIsThisMonth, day)
                let noSchedule = true
                if(scheduleComponent){
                    noSchedule = false
                }

                days.push(
                    <div
                        key={day}
                        className={`col cell ${
                            !dateFns.isSameMonth(day, monthStart) || isPast || noSchedule
                            ? "disabled"
                            : dateFns.isSameDay(day, selectedDate) ? "selected" : ""
                        }`}
                        >
                        <span className="number">{formattedDate}</span>
                        <span className="bg">{formattedDate}</span>
                        { !isPast ? scheduleComponent : null }
                    </div>
                );
                day = dateFns.addDays(day, 1);
            }
            rows.push(
            <div className="row" key={day}>
                {days}
            </div>
            );
            days = [];
        }
        return(
            <div className="wrap-body-calendar tab-calendar">
                <div className="body-calendar">
                    {this.renderDays()}
                    {rows}
                </div>
            </div>
        )
    }

    componentDidMount(){
        const selectedMonth = this.state.currentMonth
        this.fetchSchedule(selectedMonth)
    }


    componentWillUpdate(nextProps, nextState){
        const selectedMonth = this.state.currentMonth
        if(this.state.currentMonth !== nextState.currentMonth){
            this.fetchSchedule(nextState.currentMonth)
        }
        
        if(this.state.hospitalId !== nextState.hospitalId){
            this.setState({
                isFetchingSchedule: true
            })
            this.fetchSchedule(selectedMonth)
        }
    }
    
    render(){
        const {
            chosenPatient,
            isPaneOpen,
            isFetchingSchedule,
            panelData,
            doctor,
            hospitalId,
            selectedDate,
            selectedScheduleId,
            ModalData,
            scheduleData,
            isModalOpen,
            isModalFetching,
        } = this.state

        return(
            <div className="calendar mini-calendar">
                { this.renderHeader() }
                { isFetchingSchedule ? <div className="loading">Loading...</div> : '' }
                { this.renderBody() }
                <SliderPanel 
                    open={ isPaneOpen } 
                    onPanelClose={ this.onPanelClose } 
                    panelData={ panelData }
                    onHandleBack={ chosenPatient ? this.onHandleBackSlide : null}
                >
                    <SlideContent {...this.props } 
                        doctorId={ doctor.id } 
                        doctorData={ doctor }
                        hospitalId={ hospitalId }
                        selectedDate={ selectedDate } 
                        selectedScheduleId={ selectedScheduleId }
                        triggerRefresh={ this.triggerRefresh }
                        openModal={ this.handleOpenModal }
                        ModalData={ this.ModalData }
                        isFetching={ isFetchingSchedule }
                        viewPatient={ this.viewPatient }
                        clearViewPatient={ this.clearViewPatient }
                        saveScheduleOpen={ this.saveScheduleOpen }
                        chosenPatient={ chosenPatient }
                        scheduleData={ scheduleData }
                    />
                </SliderPanel>
                <WizardModal
                    className="wizard-modal"
                    isOpen={isModalOpen}
                    handleClose={this.handleCloseModal}
                    handleSubmit={this.modalHandleSubmit}
                    ModalData={ModalData}
                    isModalFetching={isModalFetching}
                />
            </div>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Schedule)