import React, { Component } from 'react'
import MasterTemplate from '../../../template/MasterTemplate'
import DoctorForm from './components/EditForm'

class Edit extends Component {
    constructor(props) {
        super(props)
        this.wizard = true
        this.cancelUrl = '/doctor/list'
        this.backUrl = this.props.isDashboard ? '/doctor-list' : '/doctor/list'
        const location = props.location.pathname
        const isEdit = location.search('/edit')
        const params = this.props.match.params
        const willContinue = params.continue === 'continue' ? true : false
        let stringUrl = willContinue ? '/continue' : ''

        let breadcrumbData = [
            {
                no: '1',
                label: 'Informasi NaKes',
                finished: true,
                path: isEdit > -1 ? '/doctor/edit' + stringUrl : '/doctor/add',
                onClick: e => this.handleInactive(e)
            },
            {
                no: '2',
                label: 'Atur Jadwal',
                path: '/doctor/add/schedule',
                onClick: e => this.handleInactive(e)
            }
        ]

        this.breadcrumbData = isEdit && !willContinue ? '' : breadcrumbData
    }

    handleInactive(event) {
        event.preventDefault()
    }

    render() {
        return (
            <MasterTemplate {...this.props}
                backUrl={this.backUrl}
                wizard={this.wizard}
                cancelUrl={this.cancelUrl}
                breadcrumbData={this.breadcrumbData}
            >
                <DoctorForm {...this.props} />
            </MasterTemplate>
        )
    }
}

export default Edit