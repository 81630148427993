import React from 'react'
import validateReturn from '../../ValidatorForm'
import {FieldControl} from "react-reactive-form"
import DatePicker from "react-date-picker"
import Icon from '../../Icons'

const CustomDatePicker = {
    DatePickerIcon(){
        return(
            <img src={ Icon.calendar } className="icon" alt="calendar"/>
        )
    },
    ClearIcon(){
        return(
            <img src={ Icon.close } className="icon close" alt="clear"/>
        )
    },
    DatePickerView: function({name, stateName, stateCombo, meta, control, fieldControlAdditional}) {
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}
        Object.assign(control, additionalController);
        name = stateCombo ? stateCombo : name
        meta.name = name
        return <FieldControl
            name={name}
            strict = {false}
            meta = {{...meta}}
            render={(obj) => this.DatePickerRender(obj)}
            control = { control }
        />
    },
    DatePickerRender: function( {handler, pristine, hasError, meta}) {
        const stateValue = this.state.Form.controls[meta.name].value
        return (
            <div className={!pristine && stateValue !== '' && hasError(meta.validator)
                ? 'form-line has-danger'
                : 'form-line'}>
                <div className="form-group global-datepicker wizard-datepicker doctor-calendar">
                    <DatePicker
                        calendarIcon={this.DatePickerIcon()}
                        clearIcon={this.ClearIcon()}
                        placeholderText={meta.label}
                        onChange={handler().onChange}
                        prev2Label={null}
                        next2Label={null}
                        locale="id-id"
                        autocomplete="off"
                        value={stateValue}
                        isOpen={false}
                        minDate={stateValue || meta.minDate}
                        maxDate={meta.maxDate}
                        placeholder="Return flight date"
                        maxDetail="month"
                        minDetail="year"
                    />
                </div>
                <span className="error-info">
                    {
                        !pristine && stateValue !== ''
                        && hasError(meta.validator)
                        && validateReturn(meta.validator)
                    }
                </span>
            </div>
        )
    }
}

export default CustomDatePicker