import React from 'react'
import { FieldControl } from 'react-reactive-form';

const RadioInputForm = {
    RadioInputFormView: function ({ name, meta, control, callback, fieldControlAdditional }) {
        const callBack = callback || null
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}
        Object.assign(control, additionalController)

        return <FieldControl
            name = {name}
            strict = {false}
            meta = {{...meta}}
            render={(obj) => this.RadioInputFormRender(obj, name, callBack)}
            control = {control}
        />
    },
    validateCallback(name, value, callback) {
        if (typeof callback === 'function') {
            callback(this, value, name)
        }
    },
    RadioInputFormRender: function ({ handler, touched, hasError, meta }, name, callback) {
        const cls = meta.className || ''

        return (
            <div className={touched && hasError(meta.validator)
                ? 'form-line has-danger'
                : 'form-line'}
            >
                <div className={`wrap-options ${cls}`}>
                    <span className="radio">{ meta.label }</span>
                    {
                        meta.options.map((option, index) => {
                            return (
                                <div key={ index } className="wrap-radio">
                                    <label className="container-radio">
                                        {option.name}
                                        <input 
                                            id={ option.value }
                                            readOnly={ meta.readonly }
                                            {...handler("radio", option.value)}
                                            onClick={() => this.validateCallback(
                                                name,
                                                option.value,
                                                callback
                                            )}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    },
}
export default RadioInputForm