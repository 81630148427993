import React from 'react'

class PatientList extends React.Component{
    renderButton(patientObj){
        const patient = patientObj
        return(
            <div className={ `patient-item data can-click` }
                onClick={ () => this.handleClick(patient) }>{ patient.name }
            </div>
        )
    }

    handleClick(patient){
        this.props.viewPatient(patient)
    }

    render(){
        const Patients = this.props.patients
        return (
            <div className="patient-wrapper">
                <h5>Daftar Antrian</h5>
                <div className="patient-list">
                    <div className="patient-row">
                        <div className="patient-caption number">No. Urut</div>
                        <div className="patient-caption data">Pasien</div>
                    </div>
                    {
                        Patients.map((patient, idx) => (
                            <div className="patient-row" key={idx}>
                                <div className="patient-item number">{ Number(patient.book_no) < 10 ? '0' + patient.book_no : patient.book_no }</div>
                                { this.renderButton(patient) }
                                <div className="patient-item action">
                                    { patient.status !== 0 ? '' : 'Belum konfirmasi' }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }
}

export default PatientList