/* eslint-disable */

import React, {Component} from 'react'
import connect from 'react-redux/es/connect/connect'
import { Navbar } from 'reactstrap'

import {
    loginMapDispatchToProps, 
    loginMapStateToProps
} from '../core/const/LoginConst'
import AdminSideBarTemplate from './partials/AdminSideBarTemplate'
import Breadcrumbs from './partials/Breadcrumbs'
import TitleTemplate from './partials/TitleTemplate'
import NavQuickMenu from './partials/NavQuickMenu'
import AdminHospitalFilter from '../components/grid/filter/AdminHospitalFilter'

/** ----------------- Start --------------------- **/

class HeadTemplate extends Component
{
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            isToggleButton: false,
            visitedOverlay: false,
            isAdjustFooter: false,
        }

        this.handleToggleButton = this.handleToggleButton.bind(this)
        this.handleHoverMenu = this.handleHoverMenu.bind(this)
    }

    handleToggleButton(){
        this.setState(state => ({
            isToggleButton: !state.isToggleButton,
            isAdjustFooter: true
        }))

        try {
            localStorage.setItem('isToggleButton', !this.state.isToggleButton)
        } catch (e) {
            console.log('Local Storage is disabled');
        }
    }

    handleHoverMenu(visited){
        if(visited){
            this.setState({
                visitedOverlay: true
            })
        }else{
            this.setState({
                visitedOverlay: false
            })
        }

        try {
            localStorage.setItem('visitedOverlay', visited)
        } catch (e) {
            console.log('Local Storage is disabled');
        }
    }

    componentDidMount(){
        let isToggleButtonStorage = true
        let visited = false

        try {
            isToggleButtonStorage = localStorage.getItem('isToggleButton')
            visited = localStorage.getItem('visitedOverlay')
        } catch (e) {
            console.log('Local Storage is disabled');
        }

        if(isToggleButtonStorage !== 'false'){
            this.setState({
                isToggleButton: true
            })
        }

        if(visited === 'true'){
            this.setState({
                visitedOverlay: true
            })
        }
    }

    render() {
        const visitedOverlay = this.state.visitedOverlay
        const isToggleButton = this.state.isToggleButton
        let auth = {
            id: null, email: null, name: null
        }

        let placeholder = {}

        if(this.props.dataLogin) {
            const {email, name, id} = this.props.dataLogin
            auth = {
                id: id, email: email, name: name
            }
        }
        placeholder = <TitleTemplate {...this.props} />

        let activeSeparator = visitedOverlay && isToggleButton ? 'active' : 'unactive'
        const shrinked = isToggleButton ? 'shrinked' : ''

        return (
            <div>
                <header className="header">
                    <Navbar>
                        <div className="container-fluid admin-container-fluid">
                            <div className="navbar-holder admin-navbar d-flex align-items-center justify-content-between">
                            <a className={ 'menu-btn' } onClick={ () => this.handleToggleButton() }>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </a>
                                <AdminHospitalFilter { ...this.props } /> 
                                <NavQuickMenu { ...this.props } auth={auth} />
                            </div>
                        </div>
                    </Navbar>
                </header>
                <div className={ `page-content d-flex align-items-stretch` }>
                    <AdminSideBarTemplate
                        { ...this.props }
                        history={this.props.history} 
                        isToggleButton={ this.state.isToggleButton } 
                        location={this.props.location} 
                        auth={auth}
                        handleHoverMenu={ this.handleHoverMenu }
                        visitedOverlay={ visitedOverlay }
                        onClick={this.handleToggleButton}
                    />
                    <div className={ `separator-content ${ shrinked } ${ activeSeparator }` }></div>
                    <div className={this.state.isToggleButton ? 'content-inner active' : 'content-inner '}>
                        <Breadcrumbs {...this.props} />
                        {placeholder}
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(HeadTemplate)