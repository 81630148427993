import React, { Component } from 'react'
import MasterTemplate from '../../template/MasterTemplate';
import Form from './components/Form';
import Config from './config/UrlConfig'

class HospitalTypeForm extends Component
{
    constructor(props){
        super(props)
        this.wizard = true
        this.cancelUrl = Config.GridUrl
        this.breadcrumbData = []
    }

    render() {
        return(
            <MasterTemplate 
                {...this.props} 
                wizard={this.wizard} 
                backUrl={this.cancelUrl} 
                cancelUrl={this.cancelUrl} 
                breadcrumbData={this.breadcrumbData}>
                    <Form {...this.props} />
            </MasterTemplate>
        )
    }
}

export default HospitalTypeForm