import React, { Component } from 'react'
import {
    FormBuilder,
    FieldGroup
} from 'react-reactive-form'

import TextInputForm from '../../../../../core/utils/forminput/TextInputForm'
import TextAreaForm from '../../../../../core/utils/forminput/TextAreaForm'
import RadioInputForm from '../../../../../core/utils/wizard/RadioInputForm'
import Handler from '../../../handler/superadmin/reportLayanan/FormHandler'
import DropDownForm from '../../../../../core/utils/wizard/DropDownForm'

import SearchSelectFilterReport from '../../../../../components/grid/actions/SearchSelectFilterReport'

import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            file: [],
            filtered: [],
            dateRange: [],
            faskes: [],
            category: [],
            faskesAll: [],
            faskesPath: "",
            categoryAll: [],
            categoryPath: "",
            selectedFaskes: [],
            dataFaskes: [],
            dataKategori: [],
            keyword: '',
            startDate:'',
            endDate:'',
            errorDate: false,
            Form: FormBuilder.group({
                kategori_date: [''],
                faskes: [''],
                category_id:[''],
            })
        }
        this.handleChangeInput = this.handleChangeInput.bind(this)

        Object.assign(this, TextInputForm)
        this.TextInputFormView = this.TextInputFormView.bind(this)
        Object.assign(this, TextAreaForm)
        this.TextAreaFormView = this.TextAreaFormView.bind(this)
        Object.assign(this, RadioInputForm)
        this.RadioInputFormView = this.RadioInputFormView.bind(this)
        Object.assign(this, DropDownForm)
        this.DropDownFormView = this.DropDownFormView.bind(this)

        // assign to handler
        Object.assign(this, Handler)
        this.handleChangeDataSelect = this.handleChangeDataSelect.bind(this)
        this.handleDateRangePicker = this.handleDateRangePicker.bind(this)
        this.DateRange = this.DateRange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.buildValue = this.buildValue.bind(this)
        this.handleUpload = this.handleUpload.bind(this)
        this.filtered = []

        this.buildForm()
    }

    componentDidMount() {
        this.getCategoryList();
        this.getSpecialistList();
        this.getFilter();
    }
    
    handleApply = (event, picker) => {
        this.setState({startDate: picker.startDate.format('YYYY-MM-DD')})
        this.setState({endDate: picker.endDate.format('YYYY-MM-DD')})
        var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
        var firstDate = new Date(picker.startDate.format('YYYY/MM/DD'));
        var secondDate = new Date(picker.endDate.format('YYYY/MM/DD'));
        var diffDays = Math.round(Math.round((secondDate.getTime() - firstDate.getTime()) / (oneDay)));
        var dates = diffDays < 31 ? false : true;
        this.setState({errorDate: dates})
    };

    handleCancel = (event, picker) => {
        picker.element.val('');
    };

    handleChangeInput(e) {
        this.setState({ keyword: e.target.value })
    }

    handleFaskes(value) {
        this.setState({ dataFaskes: value })
    }
    
    handleKategori(value) {
        this.setState({ dataKategori: value })
    }

    render() {
        const {
            loading,
            Form,
            faskesAll,
            faskesPath, 
            faskes,
            category,
            categoryAll,
            categoryPath,
            keyword,
        } = this.state
        
        return (
            <FieldGroup control={Form}
                strict={false}
                render={({ invalid }) => {
                    return (
                        <div
                            className="wizard-form custom-form"
                            style={{ display: 'flex', margin: '18px auto 70px' }}
                        >
                            <form onSubmit={this.handleSubmit} autoComplete="off">
                                {/* {this.DropDownKategoriDate} */}
                                <div className="form-line">
                                    <label htmlFor="specialist_ids" className="form-control-label">
                                        Pilih Tanggal
                                    </label>
                                    <div className="form-group">
                                        <DateRangePicker initialSettings={{ showDropdowns: true }} onApply={this.handleApply} >
                                            <input type="text" className="form-control form-control-success datepickernew"/>
                                        </DateRangePicker>
                                    </div>
                                    {this.state.errorDate && (
										<div className='d-flex justify-content-start ml-2 mt-2'>
											<span style={{ color: 'red', fontSize: '10px' }}>Interval tanggal tidak boleh lebih dari 31 hari</span>
										</div>
									)}
                                </div>
                                <div className="form-line">
                                    <label htmlFor="specialist_ids" className="form-control-label">
                                        Daftar Faskes
                                    </label>
                                    <div className="form-group-wizard subscription">
                                        <SearchSelectFilterReport
                                            filterOpt={faskes} 
                                            path={faskesPath}
                                            ref={faskesAll}
                                            key={faskes}
                                            value={keyword}
                                            handlerChangeOpt={(type, value) => this.handleFaskes(value)}

                                         />
                                    </div>
                                </div>
                                <div className="form-line">
                                    <label htmlFor="specialist_ids" className="form-control-label">
                                        Daftar Kategori
                                    </label>
                                    <div className="form-group-wizard subscription">
                                        <SearchSelectFilterReport 
                                            ref={categoryAll} 
                                            filterOpt={category} 
                                            key={category} 
                                            path={categoryPath}
                                            value={keyword}
                                            handlerChangeOpt={(type, value) => this.handleKategori(value)}
                                        />
                                    </div>
                                </div>
                                    <div className="form-btn-action" style={{float:'right'}}>
                                        <button
                                            type="submit"
                                            className={
                                                `save itgbtn primary`
                                            }
                                            disabled={loading || invalid}
                                            onSubmit={this.handleSubmit}
                                        >
                                            {loading
                                                ? 'Loading...'
                                                : 'Download'}
                                        </button>
                                    </div>
                            </form>
                        </div>
                    )
                }}
            />
        )
    }
}

export default Form