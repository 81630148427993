import _ from 'lodash'

export const AclHandler = {
    /**
     *
     * @returns {*}
     */
    getIdentity: function(){
        const { dataLogin } = this.props.store.getState().loginReducer
        if(!dataLogin){
            return false
        }

        return dataLogin
    },
    /**
     *
     * @returns {Array}
     */
    onGetPermission: function () {
        const dataLogin = this.getIdentity()
        if(!dataLogin){
            return []
        }

        let setPermissions = []
        if (dataLogin.user_type === 'd') {
            if (this.isOwner()) {
                setPermissions = _.filter(dataLogin.permissions, function(currentObject) {
                    return currentObject.role_name === 'supervisor'
                })
            } else {
                setPermissions = _.filter(dataLogin.permissions, function(currentObject) {
                    return currentObject.role_name === 'doctor'
                })
            }

            return setPermissions
        }

        return dataLogin.permissions
    },
    /**
     *
     * @param pageName
     * @returns {boolean}
     */
    onAccessThisPage: function (pageName) {
        if(this.isSuperadmin()){
            return true
        }
        let havePermission = this.onGetAccessThisModule(pageName)

        if (havePermission.length > 0) return true;

        return false;
    },
    /**
     *
     * @returns {boolean}
     */
    isSuperadmin: function(){
        const identity = this.getIdentity()
        if(!identity) {
            return false
        }

        if(typeof identity.superadmin !== 'undefined'){
            return !!identity.superadmin
        }

        return false
    },
    /**
     *
     * @param pageName
     * @returns {T[]}
     */
    onGetAccessThisModule: function (pageName) {
        return this.onGetPermission().filter(data => data.module_name === pageName)
    },
    /**
     *
     * @param pageName
     * @param permission_name
     * @returns {Array}
     */
    onListAccessModule: function (pageName, permission_name) {
        let listAccess = this.onGetPermission().filter(data => data.module_name === pageName && data.permission_name === permission_name)
        let detail = []

        listAccess.forEach(data => {
            let dataMatch = data.permission_code.split(",")
            dataMatch.map(val => detail.push(val))
        })

        return detail;
    },
    /**
     *
     * @param pageName
     * @param permission_name
     * @param name
     * @returns {boolean}
     */
    onHaveAccessDetailModule: function (pageName, permission_name, name) {
        if(this.isSuperadmin()){
            return true
        }
        let detail = this.onListAccessModule(pageName, permission_name)

        return detail.indexOf(name) > -1

    },
    /**
     *
     * @param pageName
     * @returns {Array}
     */
    onListAccesAll: function (pageName) {
        let permission = this.onGetPermission()

        if (permission === null) {
            return []
        }

        let listAccess = permission.filter(data => data.module_name === pageName)
        let detail = []

        listAccess.forEach(data => {
            let dataMatch = data.permission_code.split(",")
            dataMatch.map(val => detail.push(val))
        })

        return detail;
    },
    /**
     *
     * @param module
     * @param access
     * @returns {boolean}
     */
    can: function(module, access) {
        if(this.isSuperadmin()){
            return true
        }
        return this.onListAccesAll(module).indexOf(access) !== -1 ? true : false
    },
    isOwner: function() {
        const {
            dataLogin: {
                hospitals
            },
            currentHospital
        } = this.props.store.getState().loginReducer

        const hospitalSelected = hospitals.find(option => {
            return String(option.id) === String(currentHospital)
        })

        return !_.isEmpty(hospitalSelected) ? hospitalSelected.owner : false
    }
}