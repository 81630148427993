export const MODAL_SHOW = "MODAL_SHOW";
export const MODAL_HIDE = "MODAL_HIDE";

export const initialState = {
    isShow: false,
    ModalComponent: null
};


export const modalMapStateToProps = state => {
    return {
        isShow: state.modalReducer.isShow,
        ModalComponent: state.modalReducer.ModalComponent
    };
};

export const modalMapDispatchToProps = dispatch => {
    return {
        onRequestShow: (ModalComponent) => dispatch(
            {
                type: MODAL_SHOW,
                ModalComponent: ModalComponent
            }
        ),
        onRequestHide: () => dispatch(
            {
                type: MODAL_HIDE,
                ModalComponent: null
            }
        )
    };
};