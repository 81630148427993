import React from 'react'
import ReactTable from 'react-table'
import { ContextMenuProvider } from 'react-contexify'

import SliderPanel from '../../../../../components/wizard/SliderPanel'
import MasterGrid from '../../../../../components/grid/MasterGrid'
import GridHandler from '../../../handler/superadmin/patient/GridHandler'
import DetailPatient from './Detail'
import DetailFamily from './DetailFam'

class Grid extends MasterGrid {
    constructor(props) {
        super(props)

        Object.assign(this, GridHandler)

        this.onPanelOpen = this.onPanelOpen.bind(this)
        this.onPanelClose = this.onPanelClose.bind(this)
        this.onPanelOpenFam = this.onPanelOpenFam.bind(this)
        this.onPanelCloseFam = this.onPanelCloseFam.bind(this)
        this.filterable = false
        this.selectedTableStatus = false
        this.gridColumn = this.gridColumn.bind(this)
        this.gridMapping = ReactTable
        this.hidePagination = false
        this.hidePaginationTop = false
        this.page = 0
        this.pageSize = 20
        this.sorted = []
        this.defaultSorted = []

        /* own property */
        this.fetchData = this.fetchData.bind(this)
        this.extraProperties = {
            onFetchData: this.fetchData,
            filterAble: true
        }

        /* data */
        const loading = (this.extraProperties.hasOwnProperty('onFetchData')) ? true : false
        const data = (this.dataFromRedux) ? this.props.data : ((loading) ? [] : this.getData())
        const columns = this.getColumns(data)
        const finalColumn = this.gridColumn(ContextMenuProvider, columns)
        const pages = (loading) ? null : 1
        this.customFilter = ['DATE', 'DATE_RANGE']

        this.state = {
            isPaneOpen: false,
            panelData: {},
            isPaneOpenFam: false,
            panelDataFam: {},
            detailPatient: null,
            detailFamily: null,
            data,
            columns: finalColumn,
            selection: [],
            deleted: [],
            selectAll: false,
            loading: loading,
            loadingFam: false,
            pages: pages,
            filtered: [],
            filterList: {},
            rangeOpen: false,
            lastRounded: false,
            headerFilter: {},
        }

        this.getFilterList = this.getFilterList.bind(this)
    }

    componentDidMount() {
        this.getFilterList()
    }

    render() {
        const {
            loading,
            isPaneOpen,
            panelData,
            isPaneOpenFam,
            panelDataFam,
            detailPatient,
            detailFamily,
            loadingFam,
            filterList,
            lastRounded,
            headerFilter,
        } = this.state
        const { title } = this.props
        const filterListArr = Object.keys(filterList)

        this.leftAction = []
        if (filterListArr.length > 0) {
            filterListArr.forEach((key, index) => {
                let roundedPos = 'middle'
                const selectDefault = filterList[key].options.filter(s => {
                    return (s.value !== '') && (s.value === filterList[key].default)
                })

                if (index === 0) {
                    roundedPos = 'left'
                } else if ((filterListArr.length - 1) === index) {
                    roundedPos = lastRounded ? 'middle' : 'right'
                }

                this.leftAction.push({
                    actionType: key,
                    type: filterList[key].type,
                    title: filterList[key].placeholder,
                    tooltip: filterList[key].tooltip,
                    filterKeyword: headerFilter[key] ? headerFilter[key].value : filterList[key].default,
                    handlerSubmit: this.handleFilter,
                    selectedOpt: headerFilter[key] || selectDefault,
                    filterOpt: filterList[key].options,
                    path: filterList[key].path,
                    handlerChangeOpt: this.handleFilter,
                    handlerChange: this.handleFilter,
                    className: `rounded ${roundedPos}`,
                })
            })
        }

        this.leftAction.push({
            type: 'reset',
            loading: loading,
            tooltip: 'Reset Filter',
            handlerReset: this.handleFilterReset,
        })

        return (
            <div>
                <div className="title-wrapper">
                    <h2 className="no-margin-top page-title">{title}</h2>
                </div>
                <section className="tables with-top-filter no-paging-top">
                    <div className="row">
                        <div className="col-lg-12 cn-table-container">
                            <div className="card">
                                {super.render()}
                            </div>
                        </div>
                    </div>
                </section>
                <SliderPanel
                    {...this.props}
                    open={isPaneOpen}
                    onPanelClose={this.onPanelClose}
                    panelData={panelData}>
                    <DetailPatient
                        loading={loadingFam}
                        detailPatient={detailPatient}
                        onPanelOpenFam={this.onPanelOpenFam}
                    />
                </SliderPanel>
                <SliderPanel
                    {...this.props}
                    open={isPaneOpenFam}
                    onPanelClose={this.onPanelCloseFam}
                    onHandleBack={this.onPanelCloseFam}
                    panelData={panelDataFam}
                    customOverlay="slider-cinnamon-overlay-two">
                    <DetailFamily detailFamily={detailFamily} />
                </SliderPanel>
            </div>
        )
    }
}

export default Grid
