import { isEmpty } from 'lodash'
import { axiosBase } from './MasterConfig'

const axiosB = axiosBase()
const groupUrl = 'hospital/specialities'

export default class SpecialitiesAPI {
    static list = (hId, page, pageSize, sortProperties, sortValue, filterData) => {
        let setSort = ''
        let setFilter = ''

        if (sortProperties) {
            setSort = `&sort=${sortProperties}&order=${sortValue}`
        }

        if (!isEmpty(JSON.parse(filterData))) {
            setFilter = `&filter=${filterData}`
        }

        return axiosB.get(`/${groupUrl}?hospital_id=${hId}&page=${page}&limit=${pageSize}${setFilter}${setSort}`)
    }
    static filterList = (hid, did) => {
        let setDid = ''
        if (did) {
            setDid = `&id_doctor=${did.doctor_id}`
        }

        return axiosB.get(`/${groupUrl}/filter?id_hospital=${hid}${setDid}`)
    }
    static detail = (hId, id) => axiosB.get(`/${groupUrl}/${id}?hospital_id=${hId}`)
    static create = (data) => axiosB.post(`/${groupUrl}`, data)
    static update = (id, data) => axiosB.put(`/${groupUrl}/${id}`, data)
    static delete = (hid, id) => axiosB.delete(`/${groupUrl}/${id}`, { data: { hospital_id: hid } })
    static specialist = (hId) => axiosB.get(`/lists/specialists`)
    static specialistFilter = (id) => axiosB.get(`/${groupUrl}/filter`)
}
