import React from 'react'
import validateReturn from '../ValidatorForm'

import { FieldControl } from "react-reactive-form";

const TextAreaForm = {
    TextAreaFormView: function ({ label,
        placeholder,
        validator,
        name,
        readonly,
        disabled,
        control,
        fieldControlAdditional }) {
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}
        Object.assign(control, additionalController);

        return <FieldControl
            name={name}
            render={(obj) => this.TextAreaForm(obj)}
            meta={{
                label: label,
                placeholder: placeholder,
                validator: validator,
                name: name,
                readonly: readonly,
                disabled: disabled === true,
            }}
            control={control}
        />
    },

    TextAreaForm: function ({ handler, touched, hasError, meta }) {
        const isRequired = meta.validator === 'required' ? true : false

        return (
            <div className={touched && hasError(meta.validator)
                ? "form-line has-danger"
                : "form-line"}
            >
                {meta.label ?
                    <label
                        id={`label-${meta.name}`}
                        className="form-control-label"
                        htmlFor={`${meta.name}`}
                    >
                        {meta.label}
                        {isRequired ? <span className="required">*</span> : null}
                    </label>
                    : null}
                <div className={`form-group${meta.disabled ? ' disabled' : ''}`}>
                    <textarea
                        className={`form-control${meta.disabled ? ' disabled' : ''}`}
                        id={meta.name}
                        placeholder={meta.placeholder}
                        readOnly={meta.readonly}
                        {...handler()} />
                </div>
                <span className="error-info">
                    {
                        touched
                        && (
                            hasError("required") ||
                            hasError("minLength")
                        )
                        && validateReturn('reqMinEMR')
                    }
                </span>
            </div>
        )
    }
}

export default TextAreaForm
