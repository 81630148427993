/* eslint-disable */

import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Config from './config/DataConfig'
import Checkbox from '../dashboard/components/Checkbox'
import { appointmentMapStateToProps, appointmentMapDispatchToProps } from '../../core/const/AppointmentConst'
import { handleToast } from '../../core/utils/UtilsToast'
import dateFns from "date-fns"
import IndonesianLocale from 'date-fns/locale/id'
import Icon from '../../core/utils/Icons'
import ProfilePic from '../../components/lib/ProfilePic'
import Api from '../../services/apis/Appointment/List'
import AccessPermission from '../../template/AccessPermission'

class BookingResult extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            imageLoaded: true,
            redirectTo: null,
            confirmArrived: 0,
        }

        this.handleConfirm = this.handleConfirm.bind(this)
        this.handleErrorImage = this.handleErrorImage.bind(this)
    }

    componentDidMount() {
        const { confirmArrived } = this.state
        const booking = this.props.appointmentResult
        const now = dateFns.format(new Date(), 'YYYY-MM-DD')

        if (booking) {
            const { doctor } = booking

            if ((doctor.booking_date === now) && confirmArrived === 0) {
                this.setState({ confirmArrived: 1 })
            }
        }
    }

    handleErrorImage() {
        this.setState({
            imageLoaded: false
        })
    }

    handleConfirm(e) {
        e.preventDefault()

        const { confirmArrived } = this.state
        const booking = this.props.appointmentResult
        const { id_booking: id } = booking

        this.props.onClearAppointment()
        handleToast(Config.successAppointmentMessage)
        this.setState({
            redirectTo: Config.finishAppointmentRedirect
        })

        if (confirmArrived === 1) {
            Api.confirm(id)
        }
    }

    render() {
        const { confirmArrived } = this.state
        const booking = this.props.appointmentResult
        const now = dateFns.format(new Date(), 'YYYY-MM-DD')

        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo} />)
        }

        if (!booking) {
            return (<Redirect to={Config.baseUrl} />)
        }

        const { doctor, schedule, patient } = booking
        const bookingNo = schedule.ref_no

        let addW = 0
        if (bookingNo.length === 10) addW = 48
        if (bookingNo.length === 11) addW = 48 * 2
        if (bookingNo.length === 12) addW = 48 * 3
        const wrapperWidth = 530 + addW

        return (
            <AccessPermission {...this.props} module="appointment">
                <div className="wrap-book-result">
                    <div className="booking-result" style={{ width: wrapperWidth }}>
                        <h3>Pasien berhasil didaftarkan</h3>
                        <div className="box-booking">
                            <div className="doctor-detail">
                                <div className="header">
                                    <div className="wrap-pp">
                                        {
                                            this.state.imageLoaded ?
                                                <ProfilePic source={doctor.photo} alt="doctor" onError={this.handleErrorImage} /> :
                                                <div className="image-placeholder"></div>
                                        }
                                    </div>
                                    <div className="doctor-info">
                                        <span className="fullname">{doctor.title + ' ' + doctor.name}</span>
                                        <span className="division">{doctor.spesialisasi}</span>
                                    </div>
                                </div>
                                <div className="body">
                                    <div className="date">
                                        <span className="title">Tanggal</span>
                                        <span className="content">{dateFns.format(doctor.booking_date, 'D MMMM YYYY', { locale: IndonesianLocale })}</span>
                                    </div>
                                    <div className="time">
                                        <span className="title">Waktu</span>
                                        <span className="content">
                                            {`${schedule.time_start} - ${schedule.time_end}`}
                                        </span>
                                        {doctor.booking_date === now
                                            ? (
                                                <div className="alert">
                                                    Pasien disarankan bersiap konsultasi saat ini juga
                                                </div>
                                            )
                                            : null}
                                    </div>
                                </div>
                            </div>
                            <div className="patient-detail">
                                <div className="info">
                                    <span className="title">Nama Pasien</span>
                                    <span className="content">{patient.name}</span>
                                </div>
                                <div className="info">
                                    <span className="title">Telepon</span>
                                    <span className="content">{patient.phone}</span>
                                </div>
                            </div>
                            <div className="booking-information">
                                <div className="info">
                                    <span className="title">No. Antrian</span>
                                    <span className="big-content">{parseInt(patient.book_no, 0) < 10 ? `0${patient.book_no}` : patient.book_no}</span>
                                </div>
                                <div className="info">
                                    <span className="title">No. Booking</span>
                                    <span className="big-content">{schedule.ref_no}</span>
                                </div>
                            </div>
                            <div className="booking-information">
                                <div className="info-estimation">
                                    <span className="title">Estimasi Kedatangan</span>
                                    <span className="content">
                                        <i className="far fa-clock" />
                                        {patient.estimation}
                                    </span>
                                </div>
                            </div>
                        </div>

                        {doctor.booking_date === now
                            ? (
                                <Checkbox
                                    options={[{ value: 1, label: 'Pasien sudah datang' }]}
                                    prefix="resultAppointment"
                                    onCheck={() => this.setState({ confirmArrived: 1 })}
                                    onRemove={() => this.setState({ confirmArrived: 0 })}
                                    items={[confirmArrived]}
                                    class="wrap-checkbox-left result-appointment"
                                />
                            )
                            : null}

                        <button
                            className={`btn-confirm ${doctor.booking_date === now ? '' : 'no-checkbox'}`}
                            onClick={this.handleConfirm}
                        >
                            OK
                    </button>
                    </div>
                    <a to={'/appointment/list'} className="close-button-result" onClick={this.handleConfirm}>
                        <img src={Icon.close} alt="Close Wizard" />
                    </a>
                </div>
            </AccessPermission>
        )
    }
}

export default connect(appointmentMapStateToProps, appointmentMapDispatchToProps)(BookingResult)
