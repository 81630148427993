import axios from '../MasterConfig'
const groupUrl = "doctor-specialist"

class DoctorSpecialistApi {
    static get = (page, pageSize, sortProperties, sortValue) => axios.get(`/${groupUrl}/list?page=${page}&sort=${sortProperties}&order=${sortValue}&limit=${pageSize}`)
    static view = (id) => axios.get(`/${groupUrl}/get/${id}`)
    static post = (data) => axios.post(`/${groupUrl}/create`, data)
    static delete = (id) => axios.delete(`/${groupUrl}/delete/${id}`)
    static update = (id, data) => axios.put(`/${groupUrl}/update/${id}`, data)
    static bulkDelete = (data) => axios.post(`/${groupUrl}/bulkdelete`, data)
}
export default DoctorSpecialistApi