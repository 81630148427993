/* eslint-disable */

import React from 'react'
import Chance from 'chance'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'

import Api from '../../../../../../services/apis/FacilityApi'
import {
    handleToast,
    handleToastServiceError
} from '../../../../../../core/utils/UtilsToast'

const chance = new Chance()

const GridHandler = {
    onPanelOpen(data) {
        let headTitle = <div>Tambah Fasilitas</div>

        if (data) {
            headTitle = <div>Edit Fasilitas <strong>{data.name}</strong></div>
        }

        this.setState({
            isPaneOpen: true,
            dataEdit: data,
            panelData: { headTitle },
        })
    },
    handleOpenModal(ModalData) {
        this.setState({
            isModalOpen: true,
            ModalData: ModalData
        })
    },
    handleCloseModal() {
        this.setState({
            isModalOpen: false,
            ModalData: null
        })
    },
    modalHandleSubmit: async function () {
        const { ModalData } = this.state

        if (ModalData.action === 'delete') {
            try {
                const response = await Api.delete(ModalData.id)
                handleToast(response.data.message)

                this.loadDataAfterAction();
                this.handleCloseModal()
            } catch (e) {
                handleToastServiceError(e)
                this.handleCloseModal()
            }
        }
    },
    gridColumn() {
        return [
            {
                className: "grid-icon",
                width: 38,
                Cell: (row) => {
                    return <img src={row.original.icon} />
                }
            },
            {
                Header: "Fasilitas",
                accessor: "name",
            },
            {
                Header: "Deskripsi",
                accessor: "description",
            },
            {
                Header: "Hanya Klinik",
                Cell: (row) => {
                    let badgeType = 'badge-danger'
                    let badgeLabel = 'Tidak'

                    if (row.original.only_clinic) {
                        badgeType = 'badge-success'
                        badgeLabel = 'Ya'
                    }

                    return (
                        <div className="badge-label">
                            <span className={`badge badge-pill ${badgeType}`}>
                                {badgeLabel}
                            </span>
                        </div>
                    )
                }
            },
            {
                Header: "",
                width: 60,
                className: "grid-action",
                Cell: (row) => {
                    return (
                        <div className="grid-btn-wrapper">
                            <button onClick={() => this.onPanelOpen(row.original)}>
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                            <button onClick={() => this.handleDelete(row.original)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </div>
                    )
                }
            }
        ]
    },
    fetchData: async function (state) {
        this.setState({ loading: true })
        this.pageSize = state.pageSize
        this.page = state.page

        let res = {}
        let fArray = {}
        let sort_property = ''
        let sort_value = ''

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'DESC' : 'ASC'
            this.sorted = state.sorted
        }

        if (state.filtered && state.filtered.length > 0) {
            state.filtered.map(val => {
                if (val.value !== '') {
                    fArray[val.id] = val.value
                }
                return true
            })
        }

        const filterData = JSON.stringify(fArray)

        try {
            res = await Api.list(
                state.page + 1,
                state.pageSize,
                sort_property,
                sort_value,
                filterData,
            )
        } catch (e) {
            this.setState({
                loading: false
            })

            handleToastServiceError(e)
            return
        }

        if (res.data.code === 200) {
            let newRows = res.data.data.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })

            this.setState({
                data: newRows,
                pages: res.data.data.total_page,
                rows: res.data.data.total_rows,
                loading: false,
            })
        } else {
            this.setState({
                loading: false
            })
        }
    },
    getFilterList: async function () {
        try {
            const response = await Api.filter()
            const { data } = response.data
            const filterList = data

            this.setState({ filterList })
        } catch (e) {
            handleToastServiceError(e)
        }
    },
}

export default GridHandler
