import React, { Component } from 'react'
import { Button, Container } from 'reactstrap'
import Icons from '../../../../core/utils/Icons'
import { Redirect } from 'react-router-dom'

class SuccessResetPass extends Component {
    constructor(){
        super()
        this.state = {
            redirectTo: null
        }

        this.handleBacktoLogin = this.handleBacktoLogin.bind(this)
    }

    handleBacktoLogin(){
        this.setState({
            redirectTo: '/'
        })
    }

    render() {
        const { redirectTo } = this.state
        if(redirectTo){
            return(<Redirect to={ redirectTo } />)
        }
        return (
            <div className="login-page-cinnamon">
                <Container className="d-flex align-items-center">
                    <div className="form-login-cinnamon">
                        <div className="row">
                            <div className="wrapper-logo">
                                <img className="login-logo" src={ Icons.logoLogin } alt="Login" />
                            </div>
                            <div className="wrap-login-center wrap-success">
                                <div className="login-note no-border">
                                    <h3>Password berhasil diupdate</h3>
                                    <p>Silahkan klik tombol dibawah ini untuk login dengan password baru anda</p>
                                </div>
                                <Button 
                                    className="btn btn-primary login-button button-success"
                                    onClick={ this.handleBacktoLogin }
                                >Kembali ke halaman Login</Button>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default SuccessResetPass