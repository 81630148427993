import { isEmpty } from 'lodash'
import { axiosBase } from '../MasterConfig'

const axiosB = axiosBase()
const groupUrl = 'medicalfacility/service';

class Api {
    static list = (mfId, page, pageSize, sortProperties, sortValue, filterData) => {
        let setSort = ''
        let setFilter = ''

        if (sortProperties) {
            setSort = `&sort=${sortProperties}&order=${sortValue}`
        }

        if (!isEmpty(JSON.parse(filterData))) {
            setFilter = `&filter=${filterData}`
        }

        return axiosB.get(`/${groupUrl}?hospital_id=${mfId}&page=${page}&limit=${pageSize}${setFilter}${setSort}`)
    }
    static listMaster = () => axiosB.get(`/lists/categoryservice`) 
    static listInstitution = () => axiosB.get(`/lists/institusi`) 
    static detail = (id) => axiosB.get(`/${groupUrl}/${id}`)
    static create = (data) => axiosB.post(`/${groupUrl}`, data)
    static update = (id, data) => axiosB.put(`/${groupUrl}/${id}`, data)
    static delete = (id) => axiosB.delete(`/${groupUrl}/${id}`)
    static serviceFilter = (id) => axiosB.get(`/${groupUrl}`)
    static serviceFilterHospital = (id) => axiosB.get(`/${groupUrl}/filter?hospital_id=${id}`)
}

export default Api
