import React, { Component } from 'react'
import {connect} from 'react-redux'
import {loginMapStateToProps, loginMapDispatchToProps} from '../core/const/LoginConst'

class UnauthorizeTemplate extends Component
{
    constructor(props) {
        super(props)

        this.state = {
            isMounted: false,
            time: 5,
            timer: null,
        }

        this.checkParamsId = this.checkParamsId.bind(this)
    }

    componentDidMount() {
        this.setState({timer: setInterval(
            () =>  
                this.setState({ time: this.state.time - 1 }), 1000
        ), isMounted: true});
    }

    componentWillUnmount(){
        this.setState({
            isMounted: false,
            timer: null
        })
    }

    checkParamsId() {
        let params = this.props
        return new Promise(function(resolve, reject) {
            if (params.match.params.id &&
                params.match.params.id === 'expired'
            ) {
                params.onRequestLogout()
                resolve(true)
            }
            reject(false)
        });
    }
    render() {
        
        if (this.state.time <= 0) {
            let me = this
            clearInterval(this.state.timer);
            
            this.checkParamsId().then(
                function() { 
                    me.props.history.push('/')
                },
                function() { 
                    me.props.history.push('/dashboard')
                }
            )
        }

        return(
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404">
                        <h1>401</h1>
                    </div>
                    <div>
                        Anda tidak memiliki akses untuk membuka halaman ini <br/>
                        Anda akan dialihkan setelah <br/>
                        { this.state.time } detik
                    </div>
                </div>
            </div>
            
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps) (UnauthorizeTemplate)