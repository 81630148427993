import React from 'react'
import validateReturn from '../ValidatorForm'

import {FieldControl} from "react-reactive-form"

const TextInputForm = {
    TextInputFormView: function ({
        label,
        validator,
        validatorCustom,
        name,
        maxLength,
        placeholder,
        control,
        readonly,
        inputType,
        fieldControlAdditional
    }) {
        const additionalController = (fieldControlAdditional)
            ? fieldControlAdditional
            : {}

        Object.assign(control, additionalController)

        return <FieldControl
            name={name}
            render={(obj) => this.TextInputFormRender(obj)}
            meta={{
                label: label,
                name: name,
                maxLength: maxLength,
                readonly: readonly,
                placeholder: placeholder,
                validator: validator,
                validatorCustom: validatorCustom,
                inputType: inputType || 'text'
            }}
            control={control}
        />
    },
    validateExtra(e, inputType) {
        if (inputType === 'tel') {
            e.target.value = e.target.value.replace(/[^0-9]/g, '')
        }
    },
    TextInputFormRender: function({handler, touched, hasError, meta}) {
        const isRequired = meta.validator === 'required' ? true : false

        return (
            <div
                className={touched && hasError(meta.validator)
                    ? "form-line has-danger"
                    : "form-line"}
            >
                <label
                    id={`label-${meta.name}`}
                    className="form-control-label"
                    htmlFor={`${meta.name}`}
                >
                    {meta.label}
                    {isRequired && meta.label
                        ? <span className="required">*</span>
                        : null}
                </label>
                <div className="form-group">
                    <input 
                        className="form-control form-control-success"
                        type={ meta.inputType } 
                        placeholder={meta.placeholder} 
                        id={meta.name}
                        {...handler()}
                        readOnly={meta.readonly}
                        maxLength={ meta.maxLength }
                        onKeyUp={ (e) => this.validateExtra(e, meta.inputType) }
                    />
                </div>
                <span className="error-info">
                    {
                        touched
                        && hasError(meta.validator)
                        && validateReturn(meta.validator)
                    }
                </span>
            </div>
        )
    }
}

export default TextInputForm
