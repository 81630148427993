import React, { Component } from 'react'
import connect from 'react-redux/es/connect/connect'

import DashboardTemplate from '../../template/DashboardTemplate'
import Grid from './components/superadmin/subscription/Grid'
import { loginMapStateToProps } from '../../core/const/LoginConst'

class DashboardSubscription extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        // let isAdmin = false
        // if (this.props.isLoggin === true) {
        //     isAdmin = this.props.dataLogin.superadmin
        // }

        // if (!isAdmin || process.env.REACT_APP_SUBSCRIPTION !== 'true') {
        //     return (
        //         <Redirect to="/" />
        //     )
        // }

        return (
            <DashboardTemplate {...this.props} >
                <div className="dashboard-container wrapper-facility specialist">
                    <div className="container-fluid">
                        <Grid {...this.props} />
                    </div>
                </div>
            </DashboardTemplate>
        )
    }
}

export default connect(loginMapStateToProps, null)(DashboardSubscription)
