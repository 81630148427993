import React, { Component } from 'react'
import MasterTemplate from '../../../template/MasterTemplate'
import DoctorSpecialistForm from './components/DoctorSpecialistForm'

class AddDoctorSpecialist extends Component
{
    componentDidUpdate() {

    }

    render() {
        return(
            <MasterTemplate {...this.props}>
                <DoctorSpecialistForm {...this.props} />
            </MasterTemplate>
        )
    }
}

export default AddDoctorSpecialist