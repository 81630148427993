/* eslint-disable */

import React from 'react'
import ReactTable from 'react-table'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import Select from 'react-select'
import MaskInput from 'react-maskinput'
import Dropzone from 'react-dropzone'
import moment from 'moment'
import Datetime from 'react-datetime'
import { FieldGroup, FormBuilder, FieldControl, Validators } from 'react-reactive-form'
import { debounce, isEmpty } from 'lodash'
import {
    Carousel,
    CarouselItem,
} from 'reactstrap';
import 'moment/locale/id'

import MasterGrid from '../../../../components/grid/MasterGrid'
import GridHandler from '../handler/GridHandler'
import MessageBox from '../../../../core/utils/UtilsStrapModal'
import TextInputForm from '../../../../core/utils/forminput/TextInputForm'
import TextInputEmailForm from '../../../../core/utils/forminput/TextInputEmailForm'
import TextInputPhoneForm from '../../../../core/utils/wizard/TextInputPhoneForm'
import DropDownForm from '../../../../core/utils/wizard/DropDownForm'
import RadioInputForm from '../../../../core/utils/wizard/RadioInputForm'
import DatePickerForm from '../../../../core/utils/wizard/DatePickerForm'
import AdditionalInputForm from '../../../../core/utils/doctor/AdditionalInputForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faPlusCircle,
    faRedo,
    faTrash,
    faDownload,
    faEye,
    faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { AclHandler } from '../../../../core/const/AclConst'
import { ContextMenuProvider, ContextMenu, Item, theme } from 'react-contexify'
import Icon from '../../../../core/utils/Icons'
import SliderPanel from '../../../../components/wizard/SliderPanel'
import MasterModal from '../../../../core/utils/MasterModal'
import RoleHelper from '../../../../core/helper/RoleHelper'
import Checkbox from '../../../dashboard/components/Checkbox'

import iconPlus from '../../../../themes/v1/img/rectangle.png'
import defaultPhoto from '../../../../themes/v1/img/default-doctor.png'

import 'react-contexify/dist/ReactContexify.min.css'

library.add(faTrash, faPlusCircle, faRedo)

const CheckboxTable = checkboxHOC(ReactTable)

class Grid extends MasterGrid {
    constructor(props) {
        super(props)
        const isNurse = RoleHelper.isNurse(props.dataLogin)
        this.isNurse = isNurse ? true : false

        Object.assign(this, MessageBox)
        Object.assign(this, GridHandler)
        Object.assign(this, AclHandler)

        Object.assign(this, TextInputForm)
        this.TextInputFormView = this.TextInputFormView.bind(this)

        Object.assign(this, TextInputEmailForm)
        this.TextInputEmailFormView = this.TextInputEmailFormView.bind(this)

        Object.assign(this, TextInputPhoneForm)
        this.TextInputPhoneFormView = this.TextInputPhoneFormView.bind(this)

        Object.assign(this, DropDownForm)
        this.DropDownFormView = this.DropDownFormView.bind(this)

        Object.assign(this, RadioInputForm)
        this.RadioInputFormView = this.RadioInputFormView.bind(this)

        Object.assign(this, DatePickerForm)
        this.DatePickerFormView = this.DatePickerFormView.bind(this)

        this.isDashboard = this.props.isDashboard
        this.customFilter = ['DATE', 'DATE_RANGE']

        this.keyTable = '_id'
        this.ignoreColumn = [
            '_id'
        ]
        this.selectedTableStatus = !this.isDashboard
        this.gridColumn = this.gridColumn.bind(this)
        this.onClickContext = this.onClickContext.bind(this)
        this.dataFromRedux = false
        this.pageSize = 20
        this.page = 0
        this.sorted = []
        this.gridMapping = (this.selectedTableStatus) ? CheckboxTable : ReactTable
        this.bulkDeleteHandler = this.bulkDeleteHandler.bind(this)
        this.downloadHandler = this.downloadHandler.bind(this)

        /** own property */
        this.bulkDelete = this.bulkDelete.bind(this)
        this.fetchData = this.fetchData.bind(this)

        this.refreshData = this.refreshData.bind(this)
        this.createNew = this.createNew.bind(this)

        this.extraProperties = {
            onFetchData: this.fetchData,
            filterAble: true
        }
        this.can = this.can.bind(this)

        const loading = this.extraProperties.hasOwnProperty('onFetchData') ? true : false
        const data = this.dataFromRedux ? props.data : []
        const columns = this.getColumns(data)
        const finalColumn = this.gridColumn(ContextMenuProvider, columns)
        const pages = loading ? null : 1

        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.modalHandleSubmit = this.modalHandleSubmit.bind(this)
        this.parseModal = this.parseModal.bind(this)
        this.bruteBulkDownloadTrigger = this.bruteBulkDownloadTrigger.bind(this)
        this.clearBruteDownload = this.clearBruteDownload.bind(this)

        this.onPanelOpen = this.onPanelOpen.bind(this)
        this.onPanelClose = this.onPanelClose.bind(this)
        this.getHospital = this.getHospital.bind(this)
        this.getFilter = this.getFilter.bind(this)
        this.doctorReject = this.doctorReject.bind(this)
        this.doctorUpdate = this.doctorUpdate.bind(this)
        this.handleChangeDataSelect = this.handleChangeDataSelect.bind(this)
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.onChangeInputDebounced = debounce(this.onChangeInputDebounced, 500)
        this.handleSubmitAdditional = this.handleSubmitAdditional.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleAdditionalEdit = this.handleAdditionalEdit.bind(this)
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this)
        this.downloadFile = this.downloadFile.bind(this)
        this.validationEnd = this.validationEnd.bind(this)

        this.state = {
            isPaneOpen: false,
            panelData: {},
            data,
            columns: finalColumn,
            selection: [],
            deleted: [],
            downloaded: [],
            selectAll: false,
            loading: loading,
            pages: pages,
            filtered: [],
            filterAll: '',
            onUpdate: false,
            modalShow: false,
            dataProvider: null,
            editTrigger: false,
            search: '',
            specialist: [],
            filterGeneral: '',
            filterSpecialist: '',
            disableHighlight: true,
            filterList: {},
            filterKeyword: "",
            rangeOpen: false,
            isModalOpen: false,
            ModalData: null,
            bulkBruteDownload: false,
            universitas: '',
            jurusan: '',
            tahun_lulus: '',
            praktek: '',
            kota: '',
            bulan_tahun_mulai: '',
            bulan_tahun_selesai: '',
            expPresent: false,
            eduExpId: null,
            eduExpSubmit: false,
            headerFilter: {
                id_specialist: null,
                active: null,
                booking: null,
                approval: null,
                channel: null,
            },
            hospitalList: [],
            hospitalListState: [],
            selectedHospital: [],
            isSelectLoading: false,
            formApprFaskesTouched: false,
            prefixOptions: [],
            specialistOption: [],
            bankOption: [],
            fileList: [],
            fileChanged: false,
            activeIndex: 0,
            animating: false,
            reasonList: [
                'Data tidak valid',
                'Nomor STR tidak terdaftar',
                'Spam',
                'Lainnya',
            ],
            Form: FormBuilder.group({
                reason: ["", Validators.required],
                others: "",
            }),
            FormEditDoctor: FormBuilder.group({
                title: [""],
                name: ["", Validators.required],
                gender: ["M"],
                dob: [""],
                id_doctor_specialist: ["", Validators.required],
                email: ["", [Validators.required, Validators.email]],
                telephone: ["", [Validators.minLength(10), Validators.maxLength(15)]],
                str_no: ["", Validators.required],
                sip_no: [""],
                npwp: [""],
                npwp_name: [""],
                rekening_name: [""],
                rekening_bank: [""],
                rekening_no: [""],
                selected_hospital: [[], Validators.required],
                educations: [[]],
                experiences: [[]],
                photo: [""],
                approval: [""],
            }),
        }

        this.buildForm()
    }

    /**
     * Set Bulk Handler
     */
    bulkDeleteHandler() {
        let doctorTitle = ''
        let doctorName = ''
        const {
            selection,
            data,
        } = this.state

        if (selection.length === 1) {
            selection.forEach(value => {
                let doctorData = data.find(obj => obj[this.keyTable] === value)
                doctorTitle = doctorData.title !== null ? doctorData.title : ''
                doctorName = doctorData.doctor_name
            })
        }

        let contentModal = `Hapus Dokter terpilih akan otomatis menghapus jadwal di RS/Klinik ini sekaligus.`

        if (doctorName) {
            contentModal = `Hapus Dokter <strong>${doctorTitle} ${doctorName}</strong> akan otomatis menghapus jadwal di RS/Klinik ini sekaligus.`
        }

        const ModalData = {
            action: "delete_doctor_bulk",
            id: null,
            content: contentModal,
            buttonConfirmText: "Ok",
            buttonCancelText: "Batalkan",
            ConfirmFunction: null
        }
        this.handleOpenModal(ModalData)
    }

    /**
     * Process
     */
    createNew() {
        const { history } = this.props
        history.push('/doctor/list/add')
    }

    tableHeader(isBruteDownload) {
        return (
            <div className="tableHeader">
                <div className="wrapper-option">
                    <span className="action-header" title="Download data terpilih" onClick={(e) => {
                        this.downloadHandler()
                    }}>
                        <img src={Icon.down} alt="download" />
                    </span>
                    {
                        !this.isNurse ?
                            !this.props.isDashboard
                                ? (
                                    <span
                                        className="action-header"
                                        title="Hapus data terpilih"
                                        onClick={(e) => {
                                            this.bulkDeleteHandler()
                                        }}
                                    >
                                        <img src={Icon.TrashBin} alt="remove" />
                                    </span>
                                )
                                : null
                            : null
                    }
                    {
                        !isBruteDownload ?
                            <div className="info-selected">
                                <span>{this.state.selection.length} Data dari halaman ini terpilih</span>
                                {/* <a onClick={this.bruteBulkDownloadTrigger}>
                                Pilih semua {this.state.rows} data
                            </a> */}
                            </div> :
                            <div className="info-selected">
                                <span>Semua {this.state.rows} Data  terpilih</span>
                                <a onClick={this.clearBruteDownload}>Batalkan Pilihan</a>
                            </div>
                    }
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.getFilter(this.isDashboard)
    }

    handleChangeDataSelect(data) {
        const { hospitalListState } = this.state

        if (data.length >= 3) {
            this.setState({ hospitalList: [] })
        } else {
            this.setState({ hospitalList: hospitalListState })
        }

        this.state.FormEditDoctor.patchValue({ selected_hospital: data })
        this.setState({
            selectedHospital: data,
            formApprFaskesTouched: true,
        })
    }

    handleChangeInput(keyword) {
        const { selectedHospital } = this.state

        if (keyword.length >= 3 && selectedHospital.length < 3) {
            this.onChangeInputDebounced(keyword)
        }
    }

    onChangeInputDebounced(data) {
        this.getHospital(data)
    }

    RadioInput = ({ handler, touched, hasError, meta }) => {
        return (
            <div className={touched && hasError(meta.validator)
                ? "form-line has-danger"
                : "form-line"}>
                <div className="wrap-options">
                    <div className="wrap-radio wrap-radio-reason">
                        {
                            meta.options.map((value, index) => {
                                return (
                                    <label key={index} className="container-radio">
                                        <span className="reason-opsi">{value}</span>
                                        <input
                                            id={value}
                                            {...handler("radio", value)}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

    TextareaInput = ({ handler, touched, hasError, meta }) => {
        return (
            <div className={touched && hasError(meta.validator)
                ? 'form-line has-danger'
                : 'form-line'}>
                <textarea
                    className="form-control textarea-reason"
                    id={`${meta.label}`}
                    placeholder={meta.placeholder}
                    rows="5"
                    {...handler()} />
            </div>
        )
    }

    indicators = () => {
        const { fileList, activeIndex } = this.state

        return fileList.map((item, i) => {
            return (
                <li
                    key={item.key}
                    className={activeIndex === i ? 'active' : ''}
                    onClick={() => this.goToIndex(i)}
                >
                    <div className="img-wrapper">
                        <img src={item.file} alt={item.name} />
                    </div>
                </li>
            );
        })
    }

    slides = () => {
        const { fileList } = this.state

        return fileList.map((item) => {
            return (
                <CarouselItem key={item.key}>
                    <div className="item-img d-flex justify-content-center">
                        <img src={item.file} alt={item.name} />
                    </div>
                </CarouselItem>
            );
        })
    }

    next = () => {
        const { animating, activeIndex, fileList } = this.state
        if (animating) return;
        const nextIndex = activeIndex === fileList.length - 1 ? 0 : activeIndex + 1;
        this.setState({ activeIndex: nextIndex })
    }

    previous = () => {
        const { animating, activeIndex, fileList } = this.state
        if (animating) return;
        const nextIndex = activeIndex === 0 ? fileList.length - 1 : activeIndex - 1;
        this.setState({ activeIndex: nextIndex })
    }

    goToIndex = (newIndex) => {
        const { animating } = this.state
        if (animating) return;
        this.setState({ activeIndex: newIndex })
    }

    onDrop = (files) => {
        const { FormEditDoctor } = this.state

        files.forEach(file => {
            const reader = new FileReader()
            reader.onload = () => {
                this.setState({ fileChanged: true })
                FormEditDoctor.patchValue({ photo: reader.result })
            }
            reader.readAsDataURL(file)
        });
    }

    parseModal(data) {
        const {
            Form,
            reasonList,
            universitas,
            jurusan,
            tahun_lulus: tahunLulus,
            praktek,
            kota,
            bulan_tahun_mulai: bulanTahunMulai,
            bulan_tahun_selesai: bulanTahunSelesai,
            expPresent,
            activeIndex,
            fileList,
            isEdit,
            eduExpSubmit,
        } = this.state

        let dateMulai = bulanTahunMulai
        let dateSelesai = bulanTahunSelesai
        if (typeof bulanTahunMulai === 'string') {
            const btm = bulanTahunMulai.split('-')
            dateMulai = `${btm[1]}-${btm[0]}`
        }
        if (typeof bulanTahunSelesai === 'string') {
            const bts = bulanTahunSelesai.split('-')
            dateSelesai = `${bts[1]}-${bts[0]}`
        }

        switch (data.action) {
            case 'reject':
                return (
                    <div className="master-modal-content">
                        <div className="master-modal-head">
                            <span className="icon-exclamation">!</span>
                        </div>
                        <div className="master-modal-body" >
                            <div
                                className="content"
                                dangerouslySetInnerHTML={{ __html: data.content }} />
                            <FieldGroup
                                control={Form}
                                render={({ get, invalid }) => {
                                    const setInvalid = Form.value.reason === 'Lainnya'
                                        && _.isEmpty(Form.value.others)
                                        ? true
                                        : invalid;

                                    return (
                                        <form
                                            onSubmit={this.doctorReject}
                                            autoComplete="off"
                                            className="form-hospital">
                                            <FieldControl
                                                name="reason"
                                                render={this.RadioInput}
                                                meta={{ options: reasonList }}
                                            />
                                            {Form.value.reason === 'Lainnya' ?
                                                <FieldControl
                                                    name="others"
                                                    render={this.TextareaInput}
                                                    meta={{ label: "Others" }}
                                                />
                                                : null}

                                            <div className="master-modal-foot">
                                                <button
                                                    className={`master-btn confirm ${setInvalid
                                                        ? 'disabled'
                                                        : ''}`}
                                                    disabled={setInvalid}
                                                >
                                                    {data.buttonConfirmText}
                                                </button>
                                                <button
                                                    className="master-btn cancel"
                                                    onClick={this.handleCloseModal}>
                                                    {data.buttonCancelText}
                                                </button>
                                            </div>
                                        </form>
                                    )
                                }}
                            />
                        </div>
                    </div>
                );
            case 'education':
            case 'experience':
                return (
                    <div className="master-modal-content custom edu-exp">
                        <div className="master-modal-body" style={{ margin: 0 }}>
                            <div className="content">
                                <h3>
                                    {
                                        data.action === 'education' ?
                                            'Tambah Pendidikan' :
                                            'Tambah Pengalaman'
                                    }
                                </h3>
                                {data.action === 'education'
                                    ? (
                                        <div>
                                            <input
                                                value={universitas || ''}
                                                onChange={e => this.handleInputChange('universitas', e.target.value)}
                                                placeholder="Nama Universitas"
                                                className="native-input" />
                                            {eduExpSubmit && universitas === ''
                                                ? <span className="error-info">Wajib diisi</span>
                                                : null}
                                            <input
                                                value={jurusan || ''}
                                                onChange={e => this.handleInputChange('jurusan', e.target.value)}
                                                placeholder="Nama Jurusan"
                                                className="native-input" />
                                            {eduExpSubmit && jurusan === ''
                                                ? <span className="error-info">Wajib diisi</span>
                                                : null}
                                            <input
                                                type="number"
                                                value={tahunLulus || ''}
                                                onChange={e => this.handleInputChange('tahun_lulus', e.target.value)}
                                                placeholder="Tahun Lulus"
                                                className="native-input" />
                                            {eduExpSubmit && tahunLulus === ''
                                                ? <span className="error-info">Wajib diisi</span>
                                                : null}
                                        </div>
                                    )
                                    : (
                                        <div>
                                            <input
                                                value={praktek || ''}
                                                onChange={e => this.handleInputChange('praktek', e.target.value)}
                                                placeholder="Nama Tempat Praktik"
                                                className="native-input" />
                                            {eduExpSubmit && praktek === ''
                                                ? <span className="error-info">Wajib diisi</span>
                                                : null}
                                            <input
                                                value={kota || ''}
                                                onChange={e => this.handleInputChange('kota', e.target.value)}
                                                placeholder="Kota"
                                                className="native-input" />
                                            {eduExpSubmit && kota === ''
                                                ? <span className="error-info">Wajib diisi</span>
                                                : null}
                                            <Datetime
                                                closeOnSelect
                                                timeFormat={false}
                                                dateFormat="MMMM YYYY"
                                                initialViewMode="years"
                                                className="month-year"
                                                value={bulanTahunMulai
                                                    ? moment(new Date(dateMulai))
                                                    : ''}
                                                isValidDate={currentDate => currentDate.isBefore(moment(new Date()))}
                                                inputProps={{
                                                    placeholder: "Tahun Mulai",
                                                    onKeyDown: (e) => e.preventDefault(),
                                                    className: '',
                                                }}
                                                onChange={(d) => this.handleInputChange('bulan_tahun_mulai', d.format('MM-YYYY'))}
                                            />
                                            {!expPresent && (
                                                <Datetime
                                                    closeOnSelect
                                                    timeFormat={false}
                                                    dateFormat="MMMM YYYY"
                                                    initialViewMode="years"
                                                    className="month-year"
                                                    value={bulanTahunSelesai
                                                        ? moment(new Date(dateSelesai))
                                                        : ''}
                                                    isValidDate={e => this.validationEnd(e, bulanTahunMulai)}
                                                    inputProps={{
                                                        placeholder: "Tahun Selesai",
                                                        onKeyDown: (e) => e.preventDefault(),
                                                        className: '',
                                                    }}
                                                    onChange={(d) => this.handleInputChange('bulan_tahun_selesai', d.format('MM-YYYY'))}
                                                />
                                            )}
                                            <Checkbox
                                                options={[{
                                                    value: true,
                                                    label: 'Masih bekerja disini',
                                                }]}
                                                onCheck={() => this.setState({ expPresent: true })}
                                                onRemove={() => this.setState({
                                                    expPresent: false,
                                                    bulan_tahun_selesai: '',
                                                })}
                                                items={[expPresent]}
                                                class="wrap-checkbox-left checkbox-with-checklist exp"
                                            />
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div className="master-modal-foot">
                            <button
                                className="master-btn confirm"
                                onClick={() => this.handleSubmitAdditional(data.action)}
                            >
                                {isEdit ? 'Ubah' : 'Simpan'}
                            </button>
                            <button
                                className="master-btn cancel"
                                onClick={this.handleCloseModal}
                            >
                                Batal
                            </button>
                        </div>
                    </div>
                )
            case 'preview': {
                const item = fileList[activeIndex]
                const fileArr = (item.file).split('.')
                const fileExt = fileArr[fileArr.length - 1]
                const fileName = `${item.name}.${fileExt}`

                return (
                    <div className="modal-preview">
                        <div className="info">
                            <div className="filename">{fileName}</div>
                            <div className="action">
                                <button onClick={() => this.downloadFile(item.file, fileName)} className="download">
                                    <FontAwesomeIcon icon={faDownload} />
                                </button>
                                <div onClick={() => this.handleCloseModal()} className="close-pre">
                                    <FontAwesomeIcon icon={faTimes} />
                                </div>
                            </div>
                            <div style={{ clear: 'both' }} />
                        </div>
                        <ol className="carousel-indicators-pre">
                            {this.indicators()}
                        </ol>
                        <Carousel
                            activeIndex={activeIndex}
                            next={this.next}
                            previous={this.previous}
                            interval={false}
                        >
                            {this.slides()}
                            <div>
                                <div
                                    className="carousel-control carousel-control-prev"
                                    onClick={this.previous}
                                >
                                    <div className="arrow">
                                        <i className="fas fa-chevron-left" />
                                    </div>
                                </div>
                                <div
                                    className="carousel-control carousel-control-next"
                                    onClick={this.next}
                                >
                                    <div className="arrow">
                                        <i className="fas fa-chevron-right" />
                                    </div>
                                </div>
                            </div>
                        </Carousel>
                    </div>
                )
            }
            default:
                return null
        }
    }

    handleStrChange = (e) => {
        const { FormEditDoctor } = this.state
        FormEditDoctor.patchValue({ str_no: e.target.value })
    }

    handleNpwpChange = (e) => {
        const { FormEditDoctor } = this.state
        const npwpNo = (e.target.value).replace(/[^0-9.]/g, '')
        FormEditDoctor.patchValue({ npwp: npwpNo })
    }

    render() {
        const {
            FormEditDoctor,
            isPaneOpen,
            panelData,
            isModalOpen,
            ModalData,
            loading,
            hospitalList,
            selectedHospital,
            isSelectLoading,
            formApprFaskesTouched,
            fileList,
            selection,
            headerFilter,
            filterList,
        } = this.state
        const {
            history,
            onAdminChangeHospital,
        } = this.props

        this.MessageBox = this.MessageBoxShowHide({
            title: "Konfirmasi Hapus Dokter",
            body: "Anda yakin ingin hapus? Data tidak akan tersimpan pada sistem?",
        })

        const divStyle = {
            minWidth: '150px',
        }

        const DefaultSnippetMenu = (data) => (
            <ContextMenu
                id='menu_id'
                className="menu-custom custom-snippet-menu"
                theme={theme.light}
                style={divStyle}
            >
                <Item onClick={(data) => this.handleClick('schedule', data.dataFromProvider)}>
                    Edit Jadwal
                </Item>
                <Item onClick={(data) => this.handleClick('edit', data.dataFromProvider)}>
                    Edit Informasi
                </Item>
            </ContextMenu>
        )

        const NotActivatedSnippetMenu = (data) => (
            <ContextMenu
                id='menu_activated_id'
                className="menu-custom custom-snippet-menu"
                theme={theme.light}
                style={divStyle}
            >
                <Item onClick={(data) => this.handleClick('schedule', data.dataFromProvider)}>
                    Edit Jadwal
                </Item>
                <Item onClick={(data) => this.handleClick('edit', data.dataFromProvider)}>
                    Edit Informasi
                </Item>
                <Item onClick={(data) => this.handleClick('activate', data.dataFromProvider)}>
                    Aktivasi Ulang
                </Item>
            </ContextMenu>
        )

        const DashboardPendSnippetMenu = () => (
            <ContextMenu
                id='menu_dashboard_pending_id'
                className="menu-custom custom-snippet-menu dashboard-snippet-menu"
                theme={theme.light}
                style={divStyle}
            >
                <Item onClick={(data) => this.onPanelOpen(data.dataFromProvider)}>
                    Proses Persetujuan
                </Item>
            </ContextMenu>
        )

        const DashboardDetailSnippetMenu = () => (
            <ContextMenu
                id='menu_dashboard_detail_id'
                className="menu-custom custom-snippet-menu dashboard-snippet-menu detail"
                theme={theme.light}
                style={divStyle}
            >
                <Item onClick={(data) => this.onPanelOpen(data.dataFromProvider)}>
                    Detail Dokter
                </Item>
            </ContextMenu>
        )

        const tableTop = (selection.length > 0) ? this.tableHeader(this.state.bulkBruteDownload) : ''
        const filterListArr = Object.keys(filterList)

        this.leftAction = []
        if (filterListArr.length > 0) {
            filterListArr.forEach((key, index) => {
                let roundedPos = 'middle'
                const selectDefault = filterList[key].options.filter(s => {
                    return (s.value !== '') && (s.value === filterList[key].default)
                })

                if (index === 0) {
                    roundedPos = 'left'
                } else if ((filterListArr.length - 1) === index) {
                    roundedPos = 'right'
                }

                this.leftAction.push({
                    actionType: key,
                    type: filterList[key].type,
                    title: filterList[key].placeholder,
                    tooltip: filterList[key].tooltip,
                    filterKeyword: headerFilter[key] ? headerFilter[key].value : filterList[key].default,
                    handlerSubmit: this.handleFilter,
                    selectedOpt: headerFilter[key] || selectDefault,
                    filterOpt: filterList[key].options,
                    path: filterList[key].path,
                    handlerChangeOpt: this.handleFilter,
                    handlerChange: this.handleFilter,
                    className: `rounded ${roundedPos}`,
                })
            })
        }

        this.leftAction.push({
            type: 'reset',
            loading: loading,
            tooltip: 'Reset Filter',
            handlerReset: this.handleFilterReset,
        })

        return (
            <section
                className="tables with-top-filter no-paging-top"
            >
                {tableTop}
                <div className="container-fluid container-appointment">
                    <div className="row">
                        {this.MessageBox}
                        <div className="col-lg-12 cn-table-container doctor-table">
                            <div className="card">
                                {super.render()}
                                <DefaultSnippetMenu />
                                <NotActivatedSnippetMenu />
                                <DashboardPendSnippetMenu />
                                <DashboardDetailSnippetMenu />
                            </div>
                        </div>
                    </div>
                </div>
                <SliderPanel
                    {...this.props}
                    open={isPaneOpen}
                    onPanelClose={this.onPanelClose}
                    panelData={panelData}
                    width="978px"
                >
                    <div className="doctor-wrapper">
                        <div className="doctor-left">
                            <div className="wizard-form form-approval">
                                <div className="form-title">Informasi Pribadi</div>
                                <div
                                    className="wizard-form custom-form form-approval"
                                    style={{ marginTop: 0 }}>
                                    <FieldGroup
                                        control={FormEditDoctor}
                                        render={({ invalid, value }) => {
                                            const approval = value.approval
                                            const npwpNo = !isEmpty(value.npwp)
                                                ? (value.npwp).replace(/[^0-9.]/g, '')
                                                : ""
                                            const educations = value.educations
                                            const experiences = value.experiences

                                            return (
                                                <form
                                                    onSubmit={this.doctorUpdate}
                                                    autoComplete="off"
                                                >
                                                    <div>
                                                        <img
                                                            src={value.photo || defaultPhoto}
                                                            className="profile"
                                                            // onClick={() => window.open(value.photo)}
                                                        />
                                                    </div>
                                                    <div className="form-line container-name-doctor">
                                                        <label className="custom-label">
                                                            Nama Lengkap NaKes
                                                            <span className="required">*</span>
                                                        </label>
                                                        <div className="field-name-doctor">
                                                            <div className="input-prefix">
                                                                {this.titleField}
                                                            </div>
                                                            <div className="input-name">
                                                                {this.nameField}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {this.genderField}
                                                    {this.dobField}
                                                    {this.specialistField}
                                                    {this.emailField}
                                                    {this.telField}

                                                    <div className="form-line">
                                                        <label className="custom-label">
                                                            Nomor STR Nakes
                                                            <span className="required">*</span>
                                                        </label>
                                                        <div className="form-group">
                                                            <MaskInput
                                                                alwaysShowMask={true}
                                                                className="form-control"
                                                                mask='000000000000000000000000000000'
                                                                value={value.str_no}
                                                                placeholder="Nomor STR Nakes"
                                                                onChange={this.handleStrChange}
                                                            />
                                                        </div>
                                                        {value.str_no && value.str_no.length === 0
                                                            ? <div className="custom-error control" style={{ top: '-4px' }}>
                                                                Data harus diisi
                                                            </div>
                                                            : ''}
                                                    </div>

                                                    {this.sipField}
                                                    {/* <div className="form-line">
                                                        <label className="custom-label">
                                                            Nomor NPWP <span style={{color: 'red'}}> *</span>
                                                        </label>
                                                        <div className="form-group">
                                                            <MaskInput
                                                                alwaysShowMask={false}
                                                                className="form-control"
                                                                mask='00.000.000.0-000.000'
                                                                value={npwpNo}
                                                                placeholder="Nomor NPWP"
                                                                onChange={this.handleNpwpChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    {this.npwpNameField}
                                                    {this.bankField}
                                                    {this.noRekField}
                                                    {this.rekeningNameField} */}

                                                    <div className="button-action-slider doctor">
                                                        <div className="form-btn-action">
                                                            <button
                                                                type="button"
                                                                className="itgbtn cancel"
                                                                onClick={() => {
                                                                    if (approval === 'pending') {
                                                                        return this.handleClick('doctor_reject', panelData)
                                                                    }

                                                                    return this.onPanelClose()
                                                                }}
                                                            >
                                                                {approval === 'pending' ? 'Tolak' : 'Batalkan'}
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                className={`save itgbtn primary ${invalid ?
                                                                    'disabled' : ''}`}
                                                                disabled={invalid}
                                                            >
                                                                {approval === 'pending' ? 'Setujui' : 'Simpan'}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="wrap-input-additional">
                                                        <AdditionalInputForm
                                                            icon={iconPlus}
                                                            title="Pendidikan"
                                                            label="Tambah pendidikan"
                                                            mode="education"
                                                            content={educations}
                                                            onClick={() => this.handleOpenModal({ action: 'education' })}
                                                            onDelete={this.handleConfirmDelete}
                                                            onEdit={this.handleAdditionalEdit} />
                                                        <AdditionalInputForm
                                                            icon={iconPlus}
                                                            title="Pengalaman"
                                                            label="Tambah pengalaman"
                                                            mode="experience"
                                                            content={experiences}
                                                            onClick={() => this.handleOpenModal({ action: 'experience' })}
                                                            onDelete={this.handleConfirmDelete}
                                                            onEdit={this.handleAdditionalEdit} />
                                                    </div>
                                                </form>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="doctor-right wizard-form form-approval">
                            <div className="wrapper" style={{ paddingBottom: '8px' }}>
                                <div className="form-title">Tempat Praktik</div>
                                {panelData.approval === 'pending'
                                    ? (
                                        <div>
                                            <div className="form-line">
                                                <label htmlFor="faskes" className="form-control-label">
                                                    Faskes
                                                    <span style={{ marginLeft: '1px', color: 'red' }}>*</span>
                                                </label>
                                                <div className="form-group-wizard">
                                                    <Select
                                                        className="dropdown-wizard"
                                                        classNamePrefix="react-select"
                                                        name="faskes"
                                                        options={hospitalList}
                                                        placeholder="Pilih RS / Klinik"
                                                        onChange={this.handleChangeDataSelect}
                                                        onInputChange={this.handleChangeInput}
                                                        value={selectedHospital}
                                                        isLoading={isSelectLoading}
                                                        isMulti
                                                        isDisabled={panelData.approval !== 'pending'}
                                                    />
                                                </div>
                                                <div className="max-faskes">
                                                    Maksimal terdaftar di 3 Faskes (Klinik / RS)
                                                </div>
                                                {formApprFaskesTouched && selectedHospital.length === 0
                                                    ? <div className="custom-error control">
                                                        Faskes Harus Diisi
                                                    </div>
                                                    : ''}
                                            </div>
                                            <div style={{ clear: 'both' }} />
                                        </div>
                                    )
                                    : panelData.hospitals && panelData.hospitals.length > 0
                                        ? (
                                            <ol>
                                                {panelData.hospitals.map(item => (
                                                    <li
                                                        key={item.id}
                                                        onClick={() => {
                                                            history.push('/dashboardhospital')
                                                            return onAdminChangeHospital(item.id, item.name, item.verified)
                                                        }}
                                                    >
                                                        {item.name}
                                                    </li>
                                                ))}
                                            </ol>
                                        )
                                        : <div className="nodata" style={{ marginBottom: '8px' }}>Tidak ada data</div>
                                }
                            </div>
                            <div className="wrapper">
                                <div className="form-title">File Pendukung</div>
                                {fileList.length > 0
                                    ? fileList.map((item, i) => {
                                        // const {
                                        //     panelData: {
                                        //         name,
                                        //     },
                                        // } = this.state

                                        const fileArr = (item.file).split('.')
                                        const fileExt = fileArr[fileArr.length - 1]
                                        const fileName = `${item.name}.${fileExt}`
                                        // const fileName = `${item.name} (${name}).${fileExt}`

                                        return (
                                            <div key={item.key} className="file-wrapper">
                                                <div className="title">{item.name}</div>
                                                <div className="preview">
                                                    <div className="img-wrapper">
                                                        <img src={item.file} alt={item.name} />
                                                    </div>
                                                    <div className="right">
                                                        <div className="name">{fileName}</div>
                                                        <div className="btn-wrapper">
                                                            <button
                                                                onClick={() => this.handleOpenModal({ action: 'preview', index: i })}
                                                            >
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </button>
                                                            <button
                                                                onClick={() => this.downloadFile(item.file, fileName)}
                                                            >
                                                                <FontAwesomeIcon icon={faDownload} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : <div className="nodata">Tidak ada data</div>}
                                <div style={{ clear: 'both' }} />
                            </div>
                        </div>
                    </div>
                </SliderPanel>
                <MasterModal
                    className={`master-modal ${ModalData && ModalData.action === 'reject'
                        ? 'modal-reject'
                        : ''}`}
                    isOpen={isModalOpen}
                    handleClose={() => this.handleCloseModal()}
                    handleSubmit={this.modalHandleSubmit}
                    ModalData={ModalData}
                >
                    {ModalData
                        ? this.parseModal(ModalData)
                        : null}
                </MasterModal>
            </section>
        )
    }
}

export default Grid