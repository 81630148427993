import Api from '../../../../../services/apis/Diagnosis'
import {
    handleToast,
    handleToastServiceError
} from '../../../../../core/utils/UtilsToast'

const FormHandler = {
    buildForm() {
        this.nameField = this.TextInputFormView({
            validator: "required",
            label: "Diagnosa",
            name: "name",
            placeholder: "Diagnosa",
            control: this.state.Form.controls.name
        })

        this.codeField = this.TextInputFormView({
            validator: "required",
            label: "Kode ICD  10",
            name: "code",
            placeholder: "Kode ICD  10",
            control: this.state.Form.controls.code
        })

        this.descField = this.TextInputFormView({
            label: "Deskripsi",
            name: "description",
            placeholder: "Deskripsi",
            control: this.state.Form.controls.description
        })
    },
    buildValue(data) {
        this.state.Form.patchValue(data)
    },
    handleSubmit: async function (e) {
        e.preventDefault();
        this.setState({ loading: true })

        let response = {}
        const { dataEdit } = this.props
        const formValue = Object.assign({}, this.state.Form.value)

        try {
            if (dataEdit) {
                response = await Api.put(dataEdit.id, formValue)
            } else {
                response = await Api.post(formValue)
            }

            this.setState({ loading: false })
            handleToast(response.data.message)

            this.props.loadDataAfterAction()
            this.props.onPanelClose()
        } catch (e) {
            this.setState({ loading: false })
            handleToastServiceError(e)
        }
    }
}

export default FormHandler
