import axios from '../MasterConfig'
const groupUrl = "doctor_schedule"

class DoctorScheduleApi {
    static get = (doctorId, year, month, day, date, scheduleId) => {
        const setDay = day ? `&day=${day}` : '';
        const setDate = date ? `&date=${date}` : '';

        if (scheduleId) {
            return axios.get(`/${groupUrl}/${scheduleId}?id_doctor_hospital=${doctorId}&year=${year}&month=${month}${setDay}${setDate}`)
        }
        
        return axios.get(`/${groupUrl}?id_doctor_hospital=${doctorId}&year=${year}&month=${month}${setDay}`)
    }
    static post = (data) => axios.post(`/${groupUrl}/create`, data)
    static view = (scheduleId) => axios.get(`/${groupUrl}/view/${scheduleId}`)
    static update = (id, data) => axios.put(`/${groupUrl}/update/${id}`, data)
    static delete = (id) => axios.delete(`/${groupUrl}/delete/${id}`)
    static deleteDraft = (hospitalId, doctorId) => axios.delete(`/${groupUrl}/deletedraft/${hospitalId}/${doctorId}`)
    static finish = (doctorId) => axios.put(`/${groupUrl}/activedoctor/${doctorId}`)
}
export default DoctorScheduleApi