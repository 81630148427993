import React, {Component} from 'react'
import { Button, Container, Form, FormFeedback, FormGroup, Input } from 'reactstrap'
import connect from 'react-redux/es/connect/connect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import { loginMapDispatchToProps, loginMapStateToProps } from '../../../../core/const/LoginConst'
import { handleToastServiceError } from '../../../../core/utils/UtilsToast'
import { NavLink, Redirect } from 'react-router-dom'
import Icons from '../../../../core/utils/Icons'

class BsLoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            isLoading: false,
            validate: {
                emailState: '',
            },
            redirectUrlAfterAuth: {
                user: '/appointment/list',
                spv: '/dashboardhospital',
                admin: '/dashboard'
            },
            show_pass: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.submitForm = this.submitForm.bind(this)
        this.showPass = this.showPass.bind(this)
    }

    validateEmail(e) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const {validate} = this.state
        if (emailRex.test(e.target.value)) {
            validate.emailState = 'has-success'
        } else {
            validate.emailState = 'has-danger'
        }
        this.setState({validate})
    }

    handleChange = async (event) => {
        const {target} = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const {name} = target;
        await this.setState({
            [name]: value,
        });
    }

    submitForm(e) {
        e.preventDefault()
        this.setState({ isLoading: true })
        let deviceId = null

        try {
            deviceId = localStorage.getItem('fcmT')
        } catch (e) {
            console.log('Local Storage is disabled');
        }

        let postValue = JSON.stringify({
            email: this.state.email,
            password: this.state.password,
            device_id: deviceId,
        })

        this.props.onRequestLogin(postValue)
    }

    showPass = () => {
        this.setState((prevState) => ({
            show_pass: !prevState.show_pass
        }))
    }

    componentWillReceiveProps(nextProps){
        if(this.props.error !== nextProps.error && nextProps.error){  
            handleToastServiceError({
                message: nextProps.error
            })
            this.setState({
                isLoading: false
            })    
        }
    }

    componentWillUnmount(){
        this.setState({
            isLoading: false
        })
    }

    render() {
        const {
            email,
            password,
            show_pass,
            redirectUrlAfterAuth,
        } = this.state

        if (this.props.isLoggin === true) {
            const { dataLogin } = this.props
            const isAdmin = dataLogin.superadmin
            const isSpv = dataLogin.user_type === 'a'
            const theUrl = isAdmin
                ? redirectUrlAfterAuth.admin
                : isSpv
                    ? redirectUrlAfterAuth.spv
                    : redirectUrlAfterAuth.user

            return <Redirect to={theUrl} />
        }

        return (
            <Container className="d-flex align-items-center">
                <div className="form-login-cinnamon">
                    <div className="row">
                        <div className="wrapper-logo">
                            <img className="login-logo" src={ Icons.logoLogin } alt="Login" />
                        </div>
                        <div className="wrap-login-center">
                            <div className="login-note">
                                <h3>Masuk Akun</h3>
                                <p>
                                    Gunakan email dan kata sandi anda yang sudah didaftarkan
                                </p>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="content">
                                    <Form className="login-form" onSubmit={(e) => this.submitForm(e)}>
                                        <FormGroup>
                                            <label className="login-label">Email</label>
                                            <div className="form-group-login">
                                                <Input
                                                    type="email"
                                                    name="email"
                                                    id="login-username"
                                                    placeholder="Isi dengan email anda"
                                                    data-msg="Please enter your username"
                                                    className="input-material"
                                                    value={email}
                                                    autoFocus
                                                    valid={this.state.validate.emailState === 'has-success'}
                                                    invalid={this.state.validate.emailState === 'has-danger'}
                                                    onChange={(e) => {
                                                        this.validateEmail(e)
                                                        this.handleChange(e)
                                                    }}
                                                />
                                                <FormFeedback>
                                                    Masukkan format email yang benar.
                                                </FormFeedback>
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <label className="login-label">Kata Sandi</label>
                                            <div className="form-group-login">
                                                <Input
                                                    type={show_pass ? "text" : "password"}
                                                    name="password"
                                                    id="login-password"
                                                    placeholder="Isi dengan kata sandi anda"
                                                    className="input-material"
                                                    value={password}
                                                    onChange={(e) => this.handleChange(e)}
                                                />
                                                <FontAwesomeIcon size="sm" className="show-pass" onClick={this.showPass} icon={show_pass ? faEyeSlash : faEye} />
                                            </div>
                                        </FormGroup>
                                        <NavLink className="link-forgot" to="/forgot-pass">Lupa kata sandi?</NavLink>
                                        {
                                            this.state.isLoading ? 
                                            <div className="loading-login">Loading</div> : 
                                            <Button id="login" className="btn btn-primary login-button">Sign in</Button>
                                        }
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(BsLoginForm)