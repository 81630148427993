import React from 'react'
import Chance from 'chance'
import { isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

import Api from '../../../services/apis/MedicalRecordHospital'
import ApiPatient from '../../../services/apis/Patient/Patient'
import { handleToastServiceError } from '../../../core/utils/UtilsToast'

const chance = new Chance();

const GridHandler = {
    handleOpenModal(action, data) {
        this.setState({
            isModalOpen: true,
            activeIndex: data.index || 0,
            modalData: {
                action,
                data
            },
        })
    },
    handleCloseModal() {
        this.setState({
            isModalOpen: false,
            activeIndex: 0,
            modalData: {},
            checkedConfirm: false,
        })
    },
    onPanelFirstClose() {
        this.setState({
            isPanelFirstOpen: false,
            panelDataFirst: {},
        })
    },
    onPanelFirstOpen(data) {
        if (data.action === 'chat') {
            this.getDataChat(data)
        } else {
            this.getDataPrescription(data)
        }
    },
    onPanelSecondClose() {
        this.setState({
            isPanelSecondOpen: false,
            panelDataSecond: {},
        })
    },
    onPanelSecondOpen(id, data) {
        this.getDataPrescriptionDetail(id, data)
    },
    gridColumn(ContextMenuProvider) {
        return [
            {
                Header: 'No Resume Medis',
                accessor: 'emr_no',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return (
                        <div
                            className="clickable-field"
                            onClick={() => this.getEMRDetail(row.original.id)}
                        >
                            {row.original.emr_no || '-'}
                        </div>
                    )
                },
            },
            {
                Header: 'Nama Pasien',
                accessor: 'patient_name',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return (
                        <div
                            className="clickable-field"
                            onClick={() => this.getEMRDetail(row.original.id)}
                        >
                            {row.original.patient_name || '-'}
                        </div>
                    )
                },
            },
            {
                Header: 'Nama Dokter',
                accessor: 'doctor_name',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return row.original.doctor_name
                        ? `${row.original.doctor_title} ${row.original.doctor_name}` : '-'
                },
            },
            {
                Header: 'Tanggal Periksa',
                accessor: 'book_date',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return row.original.book_date || '-'
                },
            },
            {
                Header: 'No. HP',
                accessor: 'patient_phone',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return row.original.patient_phone || '-'
                },
            },
            {
                Header: 'Metode Bayar',
                accessor: 'payment_method',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return row.original.payment_method || '-'
                },
            },
            {
                Header: 'Diagnosa',
                accessor: 'diagnosa_name',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return (
                        <ul>
                            {row.original.diagnosa.map((item, i) => (
                                <li key={i}>{item}</li>
                            ))}
                        </ul>
                    )
                },
            },
            {
                Header: 'Action',
                width: 60,
                sortable: false,
                filterType: 'reset',
                className: 'grid-action center',
                Cell: (row) => {
                    return (
                        <ContextMenuProvider
                            id="action_menu"
                            event="onClick"
                            data={row.original}
                        >
                            <button style={{ width: 21, marginLeft: '-12px' }}>
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </button>
                        </ContextMenuProvider>
                    )
                }
            }
        ]
    },
    fetchData: async function (state) {
        this.setState({ loading: true })
        this.page = state.page
        this.pageSize = state.pageSize

        let res = {}
        let fArray = {}
        let sort_property = ''
        let sort_value = ''

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'DESC' : 'ASC'
            this.sorted = state.sorted
        }

        if (state.filtered && state.filtered.length > 0) {
            state.filtered.map(val => {
                if (val.value !== '') {
                    fArray[val.id] = val.value
                }
                return true
            })
        }

        const filterData = JSON.stringify(fArray)

        try {
            res = await Api.list(
                this.currentHospital,
                this.page + 1,
                this.pageSize,
                sort_property,
                sort_value,
                filterData,
            )
        } catch (error) {
            this.setState({ loading: false })
            const errData = error.response.data.data
            const message = Object.keys(errData).map(k => errData[k]).join('\n')
            handleToastServiceError({ message })
            return
        }

        const {
            status,
            data: resData,
        } = res

        if (status === 200) {
            const newRows = resData.data.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })

            this.setState({
                loading: false,
                data: newRows,
                pages: resData.data.total_page,
                rows: resData.data.total_rows,
            })
        } else {
            this.setState({ loading: false })
        }
    },
    getFilterList: function () {
        Api.filter(this.currentHospital).then(({ data }) => {
            const {
                status,
                data: filterList,
            } = data

            if (status) {
                this.setState({ filterList })
            }
        }).catch(error => {
            console.log('Something went wrong:', error)
        })
    },
    getEMRDetail: async function (id) {
        this.setState({ loadingFirst: true })
        await Api.detail(this.currentHospital, id).then((res) => {
            const {
                status,
                data: resData,
            } = res

            this.setState({ loadingFirst: false })
            if (status === 200) {
                this.setState({
                    isPanelFirstOpen: true,
                    panelDataFirst: {
                        ...resData.data,
                        headTitle: <div>Detail Resume Medis</div>,
                        action: 'detail',
                    },
                })
            } else {
                handleToastServiceError({ message: resData.message || 'Network Error' })
            }
        }).catch(error => {
            let message = error.message
            const errData = error.response.data.data

            if (!isEmpty(errData) && errData.data !== '') {
                message = Object.keys(errData).map(k => errData[k]).join('\n')
            }
            this.setState({ loadingFirst: false })
            handleToastServiceError({ message })
        })
    },
    getDataChat: async function (patientData) {
        const { data } = patientData
        const userId = data && data.patient_user_id

        this.setState({ loadingFirst: true })
        await ApiPatient.chatList(this.currentHospital, userId).then((res) => {
            const {
                status,
                data: resData,
            } = res

            this.setState({ loadingFirst: false })
            if (status === 200) {
                this.handleCloseModal()
                this.setState({
                    isPanelFirstOpen: true,
                    panelDataFirst: {
                        headTitle: <div>Riwayat Catatan Dokter</div>,
                        dataChat: resData.data,
                        ...patientData,
                    },
                })
            } else {
                handleToastServiceError({ message: resData.message || 'Network Error' })
            }
        }).catch(error => {
            let message = error.message
            const errData = error.response.data.data

            if (!isEmpty(errData) && errData.data !== '') {
                message = Object.keys(errData).map(k => errData[k]).join('\n')
            }
            this.setState({ loadingFirst: false })
            handleToastServiceError({ message })
        })
    },
    getDataPrescription: async function (patientData) {
        const { data } = patientData
        const doctorId = data && data.doctor_id
        const patientPhone = data && data.patient_phone

        this.setState({ loadingFirst: true })
        await ApiPatient.prescList(this.currentHospital, doctorId, patientPhone).then((res) => {
            const {
                status,
                data: resData,
            } = res

            this.setState({ loadingFirst: false })
            if (status === 200) {
                this.handleCloseModal()
                this.setState({
                    isPanelFirstOpen: true,
                    panelDataFirst: {
                        headTitle: <div>Riwayat Resep</div>,
                        prescription: resData.data,
                        message: resData.message,
                        ...patientData,
                    },
                })
            } else {
                handleToastServiceError({ message: resData.message || 'Network Error' })
            }
        }).catch(error => {
            let message = error.message
            const errData = error.response.data.data

            if (!isEmpty(errData) && errData.data !== '') {
                message = Object.keys(errData).map(k => errData[k]).join('\n')
            }
            this.setState({ loadingFirst: false })
            handleToastServiceError({ message })
        })
    },
    getDataPrescriptionDetail: async function (id, patientData) {
        const { data } = patientData
        const doctorId = data && data.doctor_id

        this.setState({ loadingSecond: true })
        await ApiPatient.prescDetail(id, this.currentHospital, doctorId).then((res) => {
            const {
                status,
                data: resData,
            } = res

            this.setState({ loadingSecond: false })
            if (status === 200) {
                this.setState({
                    isPanelSecondOpen: true,
                    panelDataSecond: {
                        headTitle: <div>Detail Resep</div>,
                        prescription_detail: resData.data,
                        message_detail: resData.message,
                        ...patientData,
                    },
                })
            } else {
                handleToastServiceError({ message: resData.message || 'Network Error' })
            }
        }).catch(error => {
            let message = error.message
            const errData = error.response.data.data

            if (!isEmpty(errData) && errData.data !== '') {
                message = Object.keys(errData).map(k => errData[k]).join('\n')
            }
            this.setState({ loadingSecond: false })
            handleToastServiceError({ message })
        })
    },
    downloadEMR({ id, patient_name: patientName }) {
        const { token, currentHospitalName } = this.props
        const baseURL = process.env.REACT_APP_API_HOST.replace('admin', '')
        const link = `${baseURL}hospital/emr/download/${id}?hospital_id=${this.currentHospital}`

        fetch(link, {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
                'X-API-Auth': process.env.REACT_APP_X_API_AUTH,
            },
        })
            .then(response => {
                if (response.status === 200) {
                    const fileName = `Resume Medis - (${currentHospitalName}) - ${patientName}`
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob)
                        let a = document.createElement('a')
                        a.href = url
                        a.download = fileName
                        a.click()
                    })
                } else {
                    handleToastServiceError({ message: 'File not found.' })
                }
            })
    },
    downloadFile(link, fileName) {
        fetch(`https://cors-anywhere.herokuapp.com/${link}`)
            .then(response => {
                if (response.status === 200) {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob)
                        let a = document.createElement('a')
                        a.href = url
                        a.download = fileName
                        a.click()
                    })
                } else {
                    handleToastServiceError({
                        message: 'File not found.'
                    })
                }
            });
    },
    handleMenuClick: function (action, data) {
        const { history } = this.props
        if (action === 'edit') {
            history.push(`/emr/edit/${data.id}`)
        } else if (action === 'prescription' || action === 'chat') {
            this.handleOpenModal(action, data)
        } else if (action === 'download') {
            this.downloadEMR(data)
        }
    },
}

export default GridHandler
