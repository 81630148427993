// import DashboardApi from './../../../../../services/apis/Dashboard/Api'
// import { handleToast, handleToastServiceError } from '../../../../../core/utils/UtilsToast'

const formHandler = {
    onChange: function (v, name) {
        const data = this.state.data.map(value => {
            if (value.name === name) {
                return Object.assign({}, value, v)
            }
            return { ...value };
        });

        this.setState({ data })
    },
    handleSubmit: async function (e) {
        e.preventDefault()

        if (this.state.isSubmit) {
            return
        }

        // let response

        console.log(this.state.data)

        // try {
        //     this.setState({
        //         isSubmit: true,
        //     })
        //     response = await DashboardApi.post(JSON.stringify(formValue))
        // } catch (e) {
        //     handleToastServiceError(e)
        //     return
        // }

        // if (response.data.code === 200) {
        //     const { history } = this.props
        //     history.push('/dashboard')
        // }

        // handleToast(response.data.message)
    }
}

export default formHandler