import React, { Component } from 'react'
import { FieldGroup, FormBuilder } from "react-reactive-form"
import iconInfo from '../../../themes/v1/img/info-active.png'
import iconXls from '../../../themes/v1/img/icon-xls.png'
import iconClose from '../../../themes/v1/img/icon-close.png'
import loader from '../../../themes/v1/img/ajax-loader.gif'
import iconUpload from '../../../themes/v1/img/icon-upload.png'
import DashboardApi from './../../../services/apis/Dashboard/Api'
import CsvUpload from '../../../core/utils/DocumentUploadForm'
import { handleToast } from '../../../core/utils/UtilsToast'

const saveData = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (data, fileName) {
        const blob = new Blob([data], {type: "octet/stream"}),
            url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}())


class UploadHospitalForm extends Component{
    constructor(props) {
        super(props)

        this.state = {
            file: "",
            Form: FormBuilder.group({
                id: [""],
                photo: [""],
            }),
            isValid: true,
            mode: 'ready'
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.downloadFormat = this.downloadFormat.bind(this)
        this.handleUpload = this.handleUpload.bind(this)
        this.handleReset = this.handleReset.bind(this)
    }
    async handleSubmit(){
        let me = this
        me.setState({
            mode: 'loading',
        })
        let response
        let formValue = Object.assign({}, me.state.Form.value)
        formValue['hospital'] = me.state.file.original
        const formData = me.setFormData(formValue)

        delete(formValue[''])
        try {
            response = await DashboardApi.upload(formData)
        } catch (e) {
            me.setState({
                mode: 'error'
            })
            return
        }

        if (response.data.code === 200) {
            me.setState({
                mode: 'ready'
            })
            this.props.onCancel()
            const { history } = this.props
            history.push('/dashboard')
        }
        handleToast(response.data.message)
    }
    handleReset(){
        this.setState({
            mode: 'ready',
        })
    }
    handleClose(){
        this.props.onCancel()
    }
    downloadFormat(){
        DashboardApi.getFormat().then(function (response) {
            saveData(response.data,'FORMAT_DATA_RS.csv')
        })
    }
    handleUpload(fileContent, originalFile){
        const tempObj = {
            content: fileContent,
            name: originalFile.name,
            original: originalFile
        }
        this.setState({
            file: tempObj,
            mode: 'droped',
        })
    }
    setFormData(formJson){
        const form = new FormData()
        for ( const key in formJson ) {
            if(typeof formJson[key] !== 'undefined'){
                form.append(key, formJson[key])
            }
        }
        return form
    }
    render(){
        const { file, Form, mode } = this.state
        return(
                <div style={{ position: 'relative', width: 'fit-content', margin: '34px auto' }}>
                    <img className="form-close" alt="" src={iconClose} onClick={e => this.handleClose(e)} />
                    <div className="hospital-upload-form">
                        <p className="form-title">Unggah Data Rumah Sakit</p>
                        <p className="form-desc">
                            <img className="form-info" alt="" src={iconInfo} />
                            Hanya dapat mengunggah data satuan, maksimal 15 MB. Hanya diperbolehkan mengunggah data .csv sesuai format data yang sudah ditentukan
                        </p>
                        <div className="format">
                            <img className="form-format" alt="" src={iconXls} />
                            <span className="left">FORMAT_DATA_RS.xls</span>
                            <span 
                                className="right"
                                onClick={e => this.downloadFormat(e)}
                            >Unduh format data</span>
                        </div>
                        <FieldGroup
                            control={Form}
                            strict={false}
                            render={({get, invalid}) => {
                                return (
                                    <form onSubmit={() => this.handleSubmit} className="form-upload-hospital">
                                        <div className="hospital-uploader">
                                            <CsvUpload
                                                ref="child"
                                                onUpload={ this.handleUpload }
                                                onSubmit={ this.handleSubmit }
                                                desc="Geser data anda kesini, atau"
                                                icon={ iconUpload }
                                                maxsize="15000000"
                                                loader={ loader }
                                                image={ file }
                                                state={ mode }
                                                onDrop={ this.handleReset }
                                            />
                                        </div>
                                    </form>
                                )
                            }}
                        />
                    </div>
                </div>
        )
    }
}

export default UploadHospitalForm