import React from 'react'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import validateReturn from '../utils/ValidatorForm'
import 'react-dropdown/style.css'
import { FieldControl } from "react-reactive-form";

const DropDownForm = {
    DropDownFormView: function({name, stateName, stateCombo, meta, control, placeholder, fieldControlAdditional}) {
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}

        Object.assign(control, additionalController);
        name = stateCombo ? stateCombo : name
        meta.name = name
        meta.placeholder = placeholder
        return <FieldControl
            name = {name}
            strict = {false}
            meta = {{...meta}}
            render={(obj) => this.DropDownFormRender(obj, stateName, stateCombo)}
            control = {control}
        />
    },

    DropDownFormRender: function( {handler, touched, hasError, meta}, stateName, stateCombo) {
        return (
            <div className={touched && hasError(meta.validator) ? "form-line has-danger" : "form-line" }>
                <div className="form-group">
                <span className="radio">{ meta.label }</span>
                    <Dropdown
                        options={this.state[stateName]}
                        onChange={(e) => this.setState({[stateCombo] : e.value})}
                        placeholder={meta.placeholder}
                        {...handler()}
                    />
                    {meta.helpText && <small className="form-text">{meta.helpText}</small>}
                    {
                        touched
                        && hasError(meta.validator)
                        && validateReturn(meta.validator)
                    }

                </div>
            </div>
        )
    }
}
export default DropDownForm