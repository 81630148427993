import axios from '../MasterConfig'
const areaUrl = "area"

class Area {
    static propinsi = () => axios.get(`/${areaUrl}/propinsi`)
    static kota = (propinsiId) => axios.get(`/${areaUrl}/kota/${propinsiId}`)
    static kecamatan = (kotaId) => axios.get(`/${areaUrl}/kecamatan/${kotaId}`)
    static kelurahan = (kecamatanId) => axios.get(`/${areaUrl}/kelurahan/${kecamatanId}`)
    static kodepos = (kecamatanId, kelurahanId) => axios.get(`/${areaUrl}/kodepos/${kecamatanId}/${kelurahanId}`)
}
export default Area;