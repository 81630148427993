import React from 'react'
import IconTool from '../IconTool'

class FreeText extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            showOption: false
        }

        this.showOption = this.showOption.bind(this)
        this.hideOption = this.hideOption.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleChangeType = this.handleChangeType.bind(this)
        this.handleChangeLabel = this.handleChangeLabel.bind(this)
        this.handleCheckRequired = this.handleCheckRequired.bind(this)
    }

    showOption(){
        this.setState({
            showOption: true
        })
    }

    hideOption(){
        this.setState({
            showOption: false
        })
    }

    componentWillMount(){
        document.addEventListener('mousedown', this.handleClick, false)
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleClick, false)
    }

    handleClick = (e) => {
        if(e.target.className === 'preview'){
            this.props.togglePreview()
        }else if(!this.node.contains(e.target) && e.target.className !== 'submit'){
            this.hideOption()
        }
    }

    handleChangeType(ev){
        const currentField = this.props.fields
        let builderData = this.props.builderData
        const index = builderData.findIndex(data => data.id === currentField.id)
        if(index !== -1){
            builderData[index].fieldSubType = ev.target.value
        }

        this.props.mutateBuilderData(builderData)
    }

    handleChangeLabel(ev){
        const currentField = this.props.fields
        let builderData = this.props.builderData
        const index = builderData.findIndex(data => data.id === currentField.id)
        if(index !== -1){
            builderData[index].fieldLabel = ev.target.value
        }

        this.props.mutateBuilderData(builderData)
    }

    handleCheckRequired(ev){
        const currentField = this.props.fields
        let builderData = this.props.builderData
        const index = builderData.findIndex(data => data.id === currentField.id)
        if(index !== -1){
            builderData[index].fieldOptional = !builderData[index].fieldOptional
        }

        this.props.mutateBuilderData(builderData)
    }

    render(){
        const showOption = this.state.showOption
        const currentField = this.props.fields
        return(
            <div className="field-data" ref={node => { this.node = node }}>
                <div className="field-data-head">
                    <i className="fas fa-bars icon"></i>
                    <IconTool fieldKey={ currentField.fieldKey }/>
                    <input 
                        className="label-name" 
                        type="text" 
                        maxLength={30} 
                        placeholder="Masukkan Label Teks" 
                        onFocus={ this.showOption }
                        onChange={ this.handleChangeLabel }
                        value={ currentField.fieldLabel }
                    />
                    <div className="delete-button" onClick={this.props.handleDelete}>
                        <i className="fas fa-trash icon"></i>
                    </div>
                </div>
                <div className={ showOption ? "field-data-body show" : "field-data-body hide" }>
                    <div className="content-body">
                        <label>
                            <input 
                                type="checkbox" 
                                name="set_optional" 
                                className="setopt" 
                                defaultChecked={ currentField.fieldOptional }
                                onChange={ this.handleCheckRequired }
                            />
                            <span>Optional Field</span>
                        </label>
                        <select 
                            className="selType" 
                            name="type" 
                            onChange={ this.handleChangeType } 
                            value={ currentField.fieldSubType ? currentField.fieldSubType : "text" }
                        >
                            <option value="text">Teks</option>
                            <option value="email">Email</option>
                        </select>
                    </div>
                </div>
            </div>
        )
    }
}

export default FreeText