import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import DatePicker from "react-date-picker"
import "react-tabs/style/react-tabs.css"
import GridPatient from '../assign/GridPatient'
import Icon from '../../../../core/utils/Icons'

class PatientSearch extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            phone: '',
        }
    }

    handleChange = (field, value) => {
        this.setState({ [field]: value })
    }

    render() {
        const {
            name,
            phone,
        } = this.state
        const { patientFilter } = this.props

        return(
            <div className="grid-search-patient">
                <div className="filter-search">
                    <Tabs>
                        <TabList>
                            <Tab><span>Nama Pasien</span></Tab>
                            <Tab><span>Tanggal Lahir</span></Tab>
                            <Tab><span>No. Telepon</span></Tab>
                        </TabList>
                        <TabPanel>
                            <form className="input-type" onSubmit={ (e) => e.preventDefault() }>
                                <input 
                                    type="text"
                                    placeholder="Cari Pasien"
                                    value={name}
                                    onChange={(e) => this.handleChange('name', e.target.value)}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            this.props.handleChangeName(name)
                                        }
                                    }}
                                />
                                <button
                                    type="button"
                                    onClick={() => {
                                        if (name) {
                                            this.handleChange('name', '')

                                            if (patientFilter.fullname) {
                                                this.props.handleChangeName('')
                                            }
                                        } else {
                                            this.props.handleChangeName(name)
                                        }
                                    }}
                                >
                                    {name
                                        ? <i className="fas fa-times" />
                                        : <i className="fas fa-search" />}
                                </button>
                            </form>
                        </TabPanel>
                        <TabPanel>
                            <form className="input-type-patient form-line" onSubmit={ (e) => e.preventDefault() }>
                                <div className="wizard-datepicker">
                                    <DatePicker
                                        placeholder={ "Tanggal Lahir Pasien" }
                                        calendarIcon={!patientFilter.dob
                                            ? <img src={Icon.calendar} className="icon" alt="calendar" />
                                            : null }
                                        clearIcon={patientFilter.dob
                                            ? <img src={Icon.close} className="icon close" alt="clear" />
                                            : null }
                                        prev2Label={null}
                                        next2Label={null}
                                        locale="id-id"
                                        autocomplete="off"
                                        isOpen={false}
                                        value={ patientFilter.dob ? patientFilter.dob : ''}
                                        onChange={ this.props.handleChangeDob }
                                        maxDate={ new Date() }
                                        maxDetail="month"
                                        minDetail="year"
                                    />
                                </div>
                            </form>
                        </TabPanel>
                        <TabPanel>
                            <form className="input-type" onSubmit={ (e) => e.preventDefault() }>
                                <input 
                                    type="number"
                                    placeholder="Phone"
                                    value={phone}
                                    onChange={(e) => this.handleChange('phone', e.target.value)}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            this.props.handleChangePhone(phone)
                                        }
                                    }}
                                />
                                <button
                                    type="button"
                                    onClick={() => {
                                        if (phone) {
                                            this.handleChange('phone', '')

                                            if (patientFilter.phone) {
                                                this.props.handleChangePhone('')
                                            }
                                        } else {
                                            this.props.handleChangePhone(phone)
                                        }
                                    }}
                                >
                                    {phone
                                        ? <i className="fas fa-times" />
                                        : <i className="fas fa-search" />}
                                </button>
                            </form>
                        </TabPanel>
                    </Tabs>
                </div>
                <h3 className="search-patient-subtitle">5 Pasien Terakhir Ditambahkan</h3>
                <div className="wrap-button-add">
                    <button onClick={ this.props.handleShowAddPatient }>
                        <img src={ Icon.plus } alt="plus"/>
                        <span>Pasien Baru</span>
                    </button>
                </div>
                <GridPatient { ...this.props } />
            </div>
        )
    }
}

export default PatientSearch