import React from 'react'
import Chance from 'chance'
import { 
    handleToast, 
    handleToastServiceError 
} from '../../../../core/utils/UtilsToast'
import UserSpg from '../../../../services/apis/Spg/Spg'
import { 
    FontAwesomeIcon 
} from '@fortawesome/react-fontawesome'
import { 
    faPencilAlt, 
    faTrash 
} from '@fortawesome/free-solid-svg-icons'

const chance = new Chance()

const saveData = (function () {
    const a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"
    return function (data, fileName) {
        const blob = new Blob([data], {type: "octet/stream"}),
            url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
    }
}())

const GridHandler = {
    gridColumn: function (ContextMenuProvider) {
        return [
            {
                Header: "Nama SPG",
                accessor: "name",
                Cell: row => (
                    <div className="row-float">{ row.original.name }</div>
                )
            },
            {
                Header: "Email",
                accessor: "email",
                Cell: row => (
                    <div className="row-float">{ row.original.email }</div>
                )
            },
            {
                Header: "Telepon",
                accessor: "telephone",
                Cell: row => (
                    <div className="row-float">{ row.original.telephone }</div>
                )
            },
            {
                filterable: false,
                sortable: false,
                width: 50,
                Cell: row => (
                    <div>
                        <button className="action-row" onClick={() => this.handleRowClick('edit', row.original)}>
                            <FontAwesomeIcon icon={faPencilAlt}/>
                        </button>
                    </div>
                )
            },
            {
                filterable: false,
                sortable: false,
                width: 50,
                Cell: row => (
                    <div>
                        <button className="action-row" onClick={() => this.handleRowClick('delete', row.original)}>
                            <FontAwesomeIcon icon={faTrash}/>
                        </button>
                    </div>
                )
            }
        ]
    },
    handleRowClick: function (action, theRow) {
        switch (action) {
            case 'delete':
                const ModalData = {
                    action: "delete_user",
                    id: theRow.id,
                    content: "Anda yakin ingin hapus? <br />Data tidak tersimpan lagi pada sistem",
                    buttonConfirmText: "Ya, Hapus",
                    buttonCancelText: "Batalkan",
                    ConfirmFunction: null
                }
                this.handleOpenModal(ModalData)
                break
            case 'edit':
                this.setState({dataProvider: theRow, editTrigger: true})
                this.updateStatusState()
                break
            default:
        }
    },
    onClickContext: function ({event, ref, data, dataFromProvider}, action, key, callBack) {
        switch (action) {
            case 'delete':
                this.setState({
                    deletedId: dataFromProvider.original.id,
                    actionModal: 'single'
                })
                this.handleShow()
                break
            case 'edit':
                this.setState({dataProvider: dataFromProvider.original, editTrigger: true})
                this.updateStatusState()
                break
            default:
        }
    },

    bulkDelete: function () {
        let { selection, data, selectAll } = this.state
        let deleted = []
        let me = this
        let id = 0
        let theObject = []
        const HospitalId = this.props.currentHospital

        if(selectAll){
            theObject = {                                                                               
                ids: 'all',
                hospital_id: HospitalId,
            }
        }else{
            selection.forEach(value => {
                let foundData = data.filter(obj => obj[this.keyTable] === value)
                id = foundData[0].id
                deleted.push(id)
            })
            
            theObject = {
                hospital_id: HospitalId,
                ids: deleted.join()
            }
        }

        UserSpg.bulkDelete(JSON.stringify(theObject)).then(function (response) {
            if ( response.status !== 200 ){
            	handleToast('Something went wrong')
            	return
            }
            me.setState({
                fetchingGrid: false
            })
            let state = {
                pageSize: me.pageSize,
                page: me.page,
                sorted: me.sorted,
                filter: ''
            }
            me.fetchData(state, null)
            me.setState({data: data, deleted: deleted, selection: []})
            handleToast(response.data.message)
            me.handleCloseModal()

        }, function (err) {
            // handleToast('Something went wrong ' + err.message)
            handleToastServiceError(err, () => {
                me.handleCloseModal()
            })
        })

    },

    fetchData: async function (state, instance) {
        // search keyword
        let fArray = {}
        if(state.filtered)
            state.filtered.map(val => 
                fArray[val.id] = val.value
            )

        const filterData = (fArray.name === undefined) ? '' : fArray.name

        this.setState({filterTrigger: false})
        this.pageSize = state.pageSize;
        this.page = state.page;
        this.setState({loading: true});
        let sortFor = '';
        let sortDirection = '';
        let res;

        if (state.sorted !== null && state.sorted.length > 0) {
            sortFor = state.sorted[0].id;
            sortDirection = (state.sorted[0].desc) ? 'DESC' : 'ASC';
            this.sorted = state.sorted
        }
        try {
            const idHospital = this.currentHospital
            res = await UserSpg.get(idHospital, state.page + 1, state.pageSize, sortFor, sortDirection, filterData)
        } catch (e) {
            this.setState({
                loading: false
            })
            handleToastServiceError(e);
            return
        }

        if (res.data.code === 200) {
            let newRows = res.data.data.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })
    
            this.setState({
                data: newRows,
                pages: res.data.data.total_page,
                loading: false,
            });
    
        }else{
            this.setState({
                loading: false
            })
        }
    },

    refreshData: function () {
        this.fetchData({
            page: this.page,
            pageSize: this.pageSize,
            search: '',
            sorted: this.sorted
        });
    },

    handleConfirmModal: function () {
        let me = this
        if (this.state.actionModal === 'bulk') {
            this.bulkDelete()
            return
        }
        UserSpg.delete(this.state.deletedId).then(function (response) {
            let state = {
                pageSize: me.pageSize,
                page: me.page,
                sorted: me.sorted,
                search: ''
            }
            me.fetchData(state, null)
            handleToast(response.data.message)
            me.handleCloseModal()
        }, function (err) {
            handleToastServiceError(err, () => {
                me.handleCloseModal()
            })
        })
    },

    downloadHandler: function () {
        let { selection, data, selectAll } = this.state
        let downloaded = []
        let me = this
        let id = 0
        let theObject = {}
        
        if(selectAll){
            theObject = {                                                                               
                ids: 'all'
            }
        }else{
            selection.forEach(value => {
                let foundData = data.filter(obj => obj[this.keyTable] === value)
                id = foundData[0].id
                downloaded.push(id)
            })
            
            theObject = {                                                                               
                ids: downloaded.join(),
                id_hospital: this.props.currentHospital
            }
        }

        UserSpg.bulkDownload(JSON.stringify(theObject)).then(function (response) {
            if ( response.status !== 200 ){
            	handleToast('Something went wrong')
            	return
            }
            //now download the file
            saveData(response.data,'admin.csv')
            me.setState({
                fetchingGrid: false
            })
            let state = {
                pageSize: me.pageSize,
                page: me.page,
                sorted: me.sorted,
                filter: ''
            }
            me.fetchData(state, null)
            me.setState({data: data, downloaded: downloaded, selection: []})
            me.handleCloseModal()
    
        }, function (err) {
            // handleToast('Something went wrong ' + err.message)
            handleToastServiceError(err, () => {
                me.handleCloseModal()
            })
        })

    },

    selectHandler: function(e){
        e.preventDefault()
        let theInput = document.getElementsByTagName("INPUT")
        for(var i=0; i<theInput.length; i++){
            if(theInput[i].type === "checkbox"){
                let theParent = theInput[i].parentNode.parentNode
                if(theParent.className === "rt-th"){
                    theInput[i].checked = true
                    theInput[i].click()
                }
            }
        }
        this.setState({
            selectAll: true
        })
    },

    handleOpenModal(ModalData) {
        this.setState({
            isModalOpen: true,
            ModalData: ModalData
        })
    },
    handleCloseModal(){
        this.setState({
            isModalOpen: false,
            ModalData: null
        })
    },
    bulkDeleteHandler(){
        const ModalData = {
            action: "delete_user_bulk",
            id: null,
            content: "Anda akan menghapus Admin terpilih. <br />Apakah Anda Yakin?",
            buttonConfirmText: "Ya, Hapus Data",
            buttonCancelText: "Batalkan",
            ConfirmFunction: null
        }
        this.handleOpenModal(ModalData)
    },
    modalHandleSubmit(){
        let me = this
        const ModalData = me.state.ModalData
        
        if(ModalData.action === 'delete_user'){
            UserSpg.delete(ModalData.id).then(function (response) {
                let state = {
                    pageSize: me.pageSize,
                    page: me.page,
                    sorted: me.sorted,
                    filter: ''
                }
                me.fetchData(state, null)
                handleToast(response.data.message)
                me.handleCloseModal()
            }, function (err) {
                handleToastServiceError(err, () => {
                    me.handleCloseModal()
                })
            })
        }else if(ModalData.action === 'delete_user_bulk'){
            this.bulkDelete()
        }
    },

}

export default GridHandler