import React from 'react'
import validateReturn from './ValidatorForm'

import { FieldControl } from "react-reactive-form";

const TextAreaForm = {
    TextAreaFormView: function({label, validator, name, control, fieldControlAdditional}) {
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}

        Object.assign(control, additionalController);

        return <FieldControl
                  name={name}
                  render={(obj) => this.TextAreaForm(obj, this.handleFocus)}
                  meta={{ label: label, validator: validator, name: name }}
                  control = {control}
                />
    },

    TextAreaForm: function ({ handler, touched, hasError, meta }, handleFocus) {
        const newHandler = handler()
        Object.assign(newHandler, {
            onFocus: handleFocus,
        })
        return(
            <div onBlur={this.handleBlur} className={touched && hasError(meta.validator) ? "form-line has-danger" : "form-line"}>
                <div className="form-group">
                <textarea className="form-control"  id={`${meta.label}`} {...newHandler } />
                    <label id={`label-${meta.name}`} className="form-control-label" htmlFor={`${meta.label}`}>
                        {`${meta.label}`}
                        {/* {meta.validator && <span style={{color: 'red'}}>*</span>} */}
                    </label>
                </div>
                <span className="error-info">
                    {
                        touched
                        && hasError(meta.validator)
                        && validateReturn(meta.validator)
                    }
                </span>
            </div>
        )
    },

    handleFocus(event) {
        if(event.target.value === '' && !event.target.parentNode.classList.contains('focused')){
            event.target.parentNode.classList.toggle('focused')
        }
    },
    handleBlur(event){
        if(event.target.value === '' && event.target.parentNode.classList.contains('focused')){
            event.target.parentNode.classList.toggle('focused')
        }
    }
}

export default TextAreaForm
