import Icons from "../utils/Icons";

const icon = Icons.sAdmindashboardMenu;

export const MYVARIABLE = "TEST";
export const sAdminDashMenu = [
  {
    index: "dashboard",
    path: "/dashboard",
    icon: icon.dashboard.normal,
    iconHover: icon.dashboard.hover,
    title: "Dashboard",
    children:[
      {
        index: "dashboard",
        path: "/dashboard",
        icon: icon.dashboard.normal,
        iconHover: icon.dashboard.hover,
        title: "Utama",
      },
      {
        index: "daftar-appointment",
        path: "/daftar-appointment",
        icon: icon.services.normal,
        iconHover: icon.services.hover,
        title: "Appointment",
        // visible: process.env.REACT_APP_USER === "true",
      },
      {
        index: "daftar-layanan",
        path: "/daftar-layanan",
        icon: icon.services.normal,
        iconHover: icon.services.hover,
        title: "Layanan",
        // visible: process.env.REACT_APP_USER === "true",
      },
      {
        index: "list-faskes",
        path: "/list-faskes",
        icon: icon.services.normal,
        iconHover: icon.services.hover,
        title: "FasKes",
        // visible: process.env.REACT_APP_USER === "true",
      },
      {
        index: "doctor-information",
        path: "/doctor-information",
        icon: icon.doctor.normal,
        iconHover: icon.doctor.hover,
        title: "NaKes",
        // visible: process.env.REACT_APP_USER === "true",
      },
    ]
  },
  {
    index: "master",
    path: "/master",
    icon: icon.services.normal,
    iconHover: icon.services.hover,
    title: "Master",
    children:[
      {
        index: "doctor-list",
        path: "/doctor-list",
        icon: icon.doctor.normal,
        iconHover: icon.doctor.hover,
        title: "Dokter",
        visible: true,
      },
      {
        index: "patient-list",
        path: "/patient-list",
        icon: icon.patient.normal,
        iconHover: icon.patient.hover,
        title: "Pasien",
        visible: true,
      },
      {
        index: "diagnosis",
        path: "/diagnosis",
        icon: icon.diagnosis.normal,
        iconHover: icon.diagnosis.hover,
        title: "Diagnosis",
        visible: process.env.REACT_APP_DIAGNOSIS === "true",
      },
      {
        index: "user-list",
        path: "/user-list",
        icon: icon.user.normal,
        iconHover: icon.user.hover,
        title: "User",
        visible: process.env.REACT_APP_USER === "true",
      },
    ]
  },
  {
    index: "services",
    path: "/services",
    icon: icon.services.normal,
    iconHover: icon.services.hover,
    title: "Kategori Layanan",
    visible: true,
  },
  {
    index: "specialities-list",
    path: "/specialities-list",
    icon: icon.services.normal,
    iconHover: icon.services.hover,
    title: "Kategori Spesialisasi",
  },
  // {
  //   index: "rapid-test",
  //   path: "/rapid-test",
  //   icon: icon.rapid.normal,
  //   iconHover: icon.rapid.hover,
  //   title: "Rapid Test",
  //   visible: process.env.REACT_APP_RAPID_TEST === "true",
  // },
  {
    index: "subscription",
    path: "/subscription",
    icon: icon.subscription.normal,
    iconHover: icon.subscription.hover,
    title: "Subscription",
    // visible: process.env.REACT_APP_SUBSCRIPTION === "true",
    children: [
      {
        index: "spackages",
        path: "/subscription/packages",
        title: "Pengaturan Paket",
        children: null,
      },
      {
        index: "sfaskes",
        path: "/subscription/faskes",
        title: "Paket Faskes",
        children: null,
      },
    ],
  },
  {
    index: "report",
    path: "/report",
    icon: icon.dashboard.normal,
    iconHover: icon.dashboard.hover,
    title: "Report",
    children:[
      {
        index: "appointment",
        path: "/report-appointment",
        icon: icon.dashboard.normal,
        iconHover: icon.dashboard.hover,
        title: "Report Appointment",
      },
      {
        index: "layanan",
        path: "/report-layanan",
        icon: icon.dashboard.normal,
        iconHover: icon.dashboard.hover,
        title: "Report Layanan",
      },
      {
        index: "faskes",
        path: "/report-faskes",
        icon: icon.dashboard.normal,
        iconHover: icon.dashboard.hover,
        title: "Report Faskes",
      },
      {
        index: "nakes",
        path: "/report-nakes",
        icon: icon.dashboard.normal,
        iconHover: icon.dashboard.hover,
        title: "Report Nakes",
      },
    ]
  },
  {
    index: "setting",
    path: "/setting",
    icon: icon.setting.normal,
    iconHover: icon.setting.hover,
    title: "Pengaturan",
    children: [
      {
        index: "fix-account",
        path: "/fix-account",
        title: "Dokter App",
        children: null,
      },
      {
        index: "facility-list",
        path: "/facility-list",
        title: "Fasilitas",
        children: null,
      },
      {
        index: "title-list",
        path: "/title-list",
        title: "Gelar",
        children: null,
      },
      {
        index: "specialist-list",
        path: "/specialist-list",
        title: "Spesialisasi",
        children: null,
      },
      {
        index: "testing-email",
        path: "/testing-email",
        title: "Testing Email",
        children: null,
      },
      {
        index: "change-password",
        path: "/change-password",
        title: "Ubah Password",
        children: null,
      },
    ],
  },
];
