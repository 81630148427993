import React, { Component } from 'react'

class ChosenFacility extends Component{
    render(){
        const facility = this.props.facility
        return(
            <ul className="chosen-facility-list">
                {
                    facility.map((row, key) => {
                        return(
                            <li key={ key }>{ row.name }</li>
                        )
                    })
                }
            </ul>
        )
    }
}

export default ChosenFacility