import React, { Component } from 'react'
import Select from 'react-select'
import ReactTooltip from 'react-tooltip'

import RoleHelper from '../../../core/helper/RoleHelper'
import { getHospitalPackage } from '../../../core/utils/UtilsPermission'

class FilterHospital extends Component {
    constructor() {
        super()
        this.state = {
            options: []
        }

        this.handleOnChange = this.handleOnChange.bind(this)
        this.customOption = this.customOption.bind(this)
    }

    componentDidMount() {
        let options = []
        const ListHospital = this.props.dataLogin.hospitals

        ListHospital.map(hospital => {
            options.push({
                value: hospital.id,
                label: hospital.name
            })
            return options
        })

        this.setState({
            options: options
        })
    }

    handleOnChange(optSelected) {
        const { dataLogin } = this.props
        const faksesPackage = getHospitalPackage(optSelected.value, dataLogin)

        if (!faksesPackage.status) {
            return false
        }

        if (optSelected.value) {
            this.props.onChangeHospital(optSelected.value)
        }
    }

    customOption({ innerProps, isDisabled, data, isSelected }) {
        const { dataLogin } = this.props
        const faksesPackage = getHospitalPackage(data.value, dataLogin)

        if (!isDisabled) {
            return (
                <div
                    {...innerProps}
                    className={`doctor-navbar-option${isSelected ? ' active' : ''}`}
                    style={!faksesPackage.status ? {
                        cursor: 'not-allowed',
                        backgroundColor: '#eee',
                    } : {}}
                    data-tip={!faksesPackage.status ? faksesPackage.info : ''}
                    data-background-color="red"
                >
                    {data.label}
                    <ReactTooltip place="right" />
                </div>
            )
        }

        return null
    }

    render() {
        const { dataLogin, currentHospital } = this.props
        const isDoctor = RoleHelper.isDoctor(dataLogin, currentHospital)
        const options = this.state.options
        const hospitalIdSelected = currentHospital

        if (!isDoctor) {
            return null
        }

        return (
            <div className="wrap-filter-hospital global-filter">
                <Select
                    components={{ Option: this.customOption }}
                    className="dropdown-wizard filter-hospital"
                    classNamePrefix="react-select"
                    name={'filter_hospital'}
                    options={options}
                    placeholder={'Ganti Rumah Sakit'}
                    onChange={this.handleOnChange}
                    value={options ? options.find(option => {
                        return String(option.value) === String(hospitalIdSelected)
                    }) : ''}
                />
            </div>
        )
    }
}

export default FilterHospital
