import React, {Component} from 'react'
import DatePickerFilter from '../grid/filter/DatePickerFilter'
import DropDownFilter from '../grid/filter/DropDownFilter'
import TextFilter from '../grid/filter/TextFilter'

class FilterHeader extends Component {
    constructor(props){
        super(props)
        this.renderFilter = this.renderFilter.bind(this)
        this.getFilter = this.getFilter.bind(this)
        this.state = {
            filters: this.props.costumFilterHeader
        }
    }

    getFilter(filterData){
        switch(filterData.type) {
            case 'datetime':
                return <DatePickerFilter filterData={ filterData }/>
            case 'select' :
                return <DropDownFilter filterData={ filterData }/>
            case 'text' :
            case 'number' :
                return <TextFilter filterData={ filterData }/>
            default:
                return null      
        }
    }

    renderFilter(){
        const costumFilterHeader = this.props.costumFilterHeader
        return costumFilterHeader.map((filterData, key) => {
            return(
                <div key={ key } className="custom-filter-cols">
                    { this.getFilter(filterData) }
                </div>
            )
        })
    }

    render() {
        const {
            costumFilterHeader,
            resetFilterHeader,
        } = this.props

        if (!costumFilterHeader) {
            return null
        }

        return (
            <div className='custom-filter-header'>
                {this.renderFilter()}
                {
                    resetFilterHeader && resetFilterHeader.resetAble
                        ? (
                            <div className="custom-filter-cols">
                                <div className="filter link">
                                    <button
                                        className="btn"
                                        onClick={() => resetFilterHeader.handle()}
                                    >
                                        <span>Reset filter</span>
                                    </button>
                                </div>
                            </div>
                        )
                        : null
                }
            </div>
        )
    }
}

export default FilterHeader