import React, { Component } from 'react'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from "react-reactive-form"
import { connect } from 'react-redux'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../../core/const/LoginConst'
import SubmitButtonForm from '../../../core/utils/SubmitButtonForm'
import TextInputForm from '../../../core/utils/wizard/TextInputForm'
import TextInputEmailForm from '../../../core/utils/wizard/TextInputEmailForm'
import TextInputPhoneForm from '../../../core/utils/wizard/TextInputPhoneForm'
import TextAreaForm from '../../../core/utils/wizard/TextAreaForm'
import DropDownForm from '../../../core/utils/wizard/DropDownForm'
import DropDownAreaForm from '../../../core/utils/wizard/DropDownAreaForm'
import DatePickerForm from '../../../core/utils/wizard/DatePickerForm'
import RadioInputForm from '../../../core/utils/wizard/RadioInputForm'
import CheckBoxForm from '../../../core/utils/wizard/CheckBoxForm'
import FormHandler from '../handler/FormHandler'
import { Redirect } from 'react-router-dom'
import ArrowDown from '../../../themes/v1/img/layout/icon/ico-arrow-down.png'
import Config from '../config/UrlConfig'

class Form extends Component
{
    constructor(props) {
        super(props)

        Object.assign(this, TextInputForm)
        Object.assign(this, TextInputEmailForm)
        Object.assign(this, TextInputPhoneForm)
        Object.assign(this, TextAreaForm)
        Object.assign(this, DropDownForm)
        Object.assign(this, DropDownAreaForm)
        Object.assign(this, DatePickerForm)
        Object.assign(this, RadioInputForm)
        Object.assign(this, CheckBoxForm)

        this.redirectUrl = Config.GridUrl
    
        this.state = {
            hospitalId: this.props.currentHospital,
            toggleMoreField: false,
            toggleMoreAdmField: false,
            isEdit: false,
            editChanged: false,
            postStatus: false,
            Form: FormBuilder.group({
                id: [""],
                nama_depan: ["", Validators.required],
                nama_belakang: ["", Validators.required],
                jenis_kelamin: ["M"],
                email: ["", Validators.email],
                telepon: [
                    "", [Validators.required, Validators.minLength(10), Validators.maxLength(15)]
                ],
                tempat_lahir: [""],
                tipe_identitas: [""],
                no_identitas: [""],
                alamat: [""],
                tanggal_lahir: ["", Validators.required],
                tinggi_badan: [""],
                berat_badan: [""],
                gol_darah: [""],
                kondisi_pasien: [""],
                propinsi_id: [0],
                propinsi: [""],
                kota_id: [0],
                kota: [""],
                kecamatan_id: [0],
                kecamatan: [""],
                kelurahan_id: [0],
                kelurahan: [""],
                kodepos: [0],
                nama_png_jawab: [""],
                email_png_jawab: ["", Validators.email],
                telepon_png_jawab: [""],
                relasi_png_jawab: [""],
                custom_fields: FormBuilder.array([])
            }),
            customFieldTitle : null,
            customField: [],
            customFieldValue: [],
            idTypeOpt: [
                {value: "KTP", label: "KTP"},
                {value: "SIM", label: "SIM"},
                {value: "Passport", label: "Passport"}
            ],
            bloodTypeOpt: [
                { value: "A", label: "A" },
                { value: "B", label: "B" },
                { value: "AB", label: "AB" },
                { value: "O", label: "O" },
            ],
            propinsiOpt: [],
            kotaOpt: [],
            kecamatanOpt: [],
            kelurahanOpt: [],
        }

        this.TextInputFormView = this.TextInputFormView.bind(this)
        this.TextInputEmailFormView = this.TextInputEmailFormView.bind(this)
        this.TextAreaFormView = this.TextAreaFormView.bind(this)
        this.DropDownFormView = this.DropDownFormView.bind(this)
        this.DropDownAreaFormView = this.DropDownAreaFormView.bind(this)
        this.DatePickerFormView = this.DatePickerFormView.bind(this)
        this.RadioInputFormView = this.RadioInputFormView.bind(this)
        this.CheckBoxFormView = this.CheckBoxFormView.bind(this)
        Object.assign(this, FormHandler)

        this.handleSubmit = this.handleSubmit.bind(this)
        this.valueBuilder = this.valueBuilder.bind(this)
        this.fillForm = this.fillForm.bind(this)
        this.fillCustomField = this.fillCustomField.bind(this)
        this.loadCustomField = this.loadCustomField.bind(this)

        this.buildForm()
    }

    onToggleMore(){
        this.setState({
            toggleMoreField: !this.state.toggleMoreField
        })
    }

    onToggleMoreAdm() {
        this.setState({
            toggleMoreAdmField: !this.state.toggleMoreAdmField
        })
    }

    getCustomField(obj, controls){
        switch(obj.fieldKey){
            case 'input_text_free' :
                if(obj.fieldSubType === 'email'){
                    return (
                        this.TextInputEmailFormView({
                            label: obj.fieldLabel,
                            name: obj.fieldLabel,
                            control: controls,
                            validator: 'required'
                        })
                    )
                }else{
                    return (
                        this.TextInputFormView({
                            label: obj.fieldLabel,
                            name: obj.fieldLabel,
                            control: controls,
                            validator: 'required'
                        })
                    )
                }
            case 'text_area' : 
                return(
                    this.TextAreaFormView({
                        label: obj.fieldLabel,
                        name: obj.fieldLabel,
                        readonly: false,
                        control: controls,
                        validator: 'required'
                    })
                )
            case 'input_text_numeric' :
                return (
                    this.TextInputFormView({
                        label: obj.fieldLabel,
                        name: obj.fieldLabel,
                        readonly: false,
                        inputType: 'number',
                        control: controls,
                        validator: 'required'
                    })
                )
            case 'calendar' :
                return  (
                    this.DatePickerFormView({
                        meta: {
                            name: obj.fieldLabel,
                            label: obj.fieldLabel,
                            readonly: false,
                            validator: 'required',
                        },
                        name: obj.fieldLabel,
                        control: controls,
                    })
                )
            case 'radio' :
                return(
                    this.RadioInputFormView({
                        name: obj.fieldLabel,            
                        meta: {
                            label: obj.fieldLabel,
                            readonly:false,
                            options: obj.option
                        },
                        control: controls,
                        validator: 'required'
                    })
                )
            case 'checkbox' :
                return(
                    this.CheckBoxFormView({
                        name: obj.fieldLabel,            
                        meta: {
                            label: obj.fieldLabel,
                            validator: "",
                            options: obj.option
                        },
                        control: controls,
                        validator: 'required'
                    })
                )
            case 'dropdown' :
                return(
                    this.DropDownFormView({
                        meta: {
                            label: obj.fieldLabel,
                            validator: "required",
                            options: obj.option.map(opt => {
                                return {
                                    value: opt.value,
                                    label: opt.name
                                }
                            })
                        },
                        name: obj.fieldLabel,
                        nameText: obj.fieldLabel,
                        control: controls,
                    })
                )
            default : 
                return null
        }
    }

    renderCustomFieldContainer(){
        const customField = this.state.customField
        const customFieldTitle = this.state.customFieldTitle
        return(<div className="wizard-preview">
            <h3>{ customFieldTitle }</h3>
            {
                customField.map((obj, key) => {
                    const state = this.state.Form.controls.custom_fields
                    if(state.controls[0]){
                        return(
                            <div key={key}>
                                {
                                    state.controls[0].controls[obj.fieldLabel] ? this.getCustomField(obj, state.controls[0].controls[obj.fieldLabel]) : null
                                }
                            </div>
                        )
                    }
                    return null
                })
            }
        </div>)
    }

    componentDidMount() {
        this.loadCustomField()
    }

    render(){
        let buttonText = 'Simpan Data'
        const {
            Form,
            customFieldTitle,
            toggleMoreField,
            toggleMoreAdmField,
            postStatus,
            isEdit,
        } = this.state

        if (postStatus) {
            return <Redirect to={ this.redirectUrl } />
        }
        
        if(!isEdit){
            buttonText = 'Daftarkan'
        }

        return(
            <div className="wizard-form">
                <h3>
                    {this.props.subtitle}
                </h3>
                {/** start form */}
                <FieldGroup
                control={Form}
                strict={false}
                render={({ get, invalid }) => {
                return(
                    <form
                        onSubmit={() => this.handleSubmit}
                        className="form-horizontal"
                        autoComplete="off"
                    >
                        {this.TextFirstName}
                        {this.TextLastName}
                        {this.DatePickDob}
                        {this.TextPhone}
                        <div className="hor-line patient" />
                        <div className={`wrap-additional patient ${toggleMoreField
                            ? 'show-field'
                            : 'hide-field'}`}>
                            <div id="toggle-button" onClick={this.onToggleMore.bind(this)}>
                                Informasi Tambahan (Optional)
                                <img className="icon" src={ArrowDown} alt="icon arrow down" />
                            </div>
                            <div className="wrap-input-additional patient">
                                <h3>Data Diri</h3>
                                <div className="form-hide-wrapper">
                                    {this.TextEmail}
                                    {this.TextBirthPlace}
                                    <div className="radio-patient">
                                        {this.RadioGender}
                                    </div>
                                    {this.TextHeight}
                                    {this.TextWeight}
                                    {this.DropDownBloodType}
                                    {this.DropDownTypeId}
                                    {this.TextIdNo}
                                    {this.TextCondPatient}
                                </div>
                                <div className="hor-line patient" />
                                <h3>Kontak Alamat</h3>
                                <div className="form-hide-wrapper">
                                    {this.TextAddress}
                                    {this.DropDownPropinsi}
                                    {this.DropDownKota}
                                    {this.DropDownKecamatan}
                                    {this.DropDownKelurahan}
                                </div>
                            </div>
                        </div>
                        {
                            customFieldTitle !== null ?
                                this.renderCustomFieldContainer()
                                : null
                        }
                        <div className="hor-line patient" />
                        <div className={`wrap-additional patient ${toggleMoreAdmField
                            ? 'show-field'
                            : 'hide-field'}`}>
                            <div id="toggle-button" onClick={this.onToggleMoreAdm.bind(this)}>
                                Informasi Administrasi (Optional)
                                <img className="icon" src={ArrowDown} alt="icon arrow down" />
                            </div>
                            <div className="wrap-input-additional patient">
                                <h3>Informasi Penanggung Jawab</h3>
                                <div>
                                    {this.TextPICName}
                                    {this.TextPICPhone}
                                    {this.TextPICEmail}
                                    <div className="radio-patient">
                                        {this.RadioPICRelationship}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!toggleMoreAdmField
                            ? <div className="hor-line patient" />
                            : null}
                        {
                            !this.props.previewMode
                                ? <SubmitButtonForm
                                    submitText={buttonText}
                                    invalid={invalid}
                                    onSubmit={(e) => this.handleSubmit(e)}
                                    onCancel={(e) => this.props.history.push(this.redirectUrl)} />
                                : null
                        }
                    </form>
                )}}/>
                {/** end of form */}
            </div>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Form)