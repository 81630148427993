import { isArray } from 'lodash'

import Api from '../../../../../services/apis/UserMApi'
import HospitalApi from '../../../../../services/apis/HospitalApi'
import {
    handleToast,
    handleToastServiceError
} from '../../../../../core/utils/UtilsToast'

const FormHandler = {
    buildForm() {
        this.nameField = this.TextInputFormView({
            validator: 'required',
            label: 'Nama User',
            name: 'name',
            placeholder: 'Nama User',
            control: this.state.Form.controls.name
        })

        this.userTypeField = this.DropDownFormView({
            meta: {
                label: 'Jenis User ',
                EditshowOnlyText: false,
                placeholder: 'Jenis User '
            },
            name: 'user_type',
            stateName: 'typeList',
            placeholder: 'Jenis User ',
            control: this.state.Form.controls.user_type
        })

        this.emailField = this.TextInputEmailFormView({
            validator: 'required',
            label: 'Email',
            name: 'email',
            placeholder: 'Email',
            inputType: 'email',
            control: this.state.Form.controls.email
        })

        this.activeField = this.RadioInputFormView({
            name: 'active',
            meta: {
                label: 'Status',
                options: [
                    {
                        value: 'true',
                        name: 'Aktif',
                    },
                    {
                        value: 'false',
                        name: 'Non Aktif',
                    }
                ]
            },
            control: this.state.Form.controls.active,
            callback: this.activeCallback
        })
    },
    activeCallback(me, value) {
        const { activeInit } = me.state
        const { handleOpenModal } = me.props
        const active = value

        if (value === 'true') {
            me.setState({ reason: '' })
        }

        if (activeInit === 'true' && active === 'false') {
            const modalData = {
                action: 'non_active',
                content: 'Pilih Alasan Non Aktif',
                buttonConfirmText: 'Ya',
                buttonCancelText: 'Tidak',
            }
            handleOpenModal(modalData)
        }
    },
    buildValue(data) {
        this.state.Form.patchValue(data)
    },
    getHospital: async function (keyword) {
        this.setState({ isSelectLoading: true })

        await HospitalApi.getHospital(keyword).then((res) => {
            const { data } = res.data

            if (res.status === 200) {
                this.setState({
                    hospitalList: data,
                    hospitalListState: data,
                    isSelectLoading: false,
                })
            }
        })
    },
    handleSubmit: async function (e) {
        e.preventDefault();
        this.setState({ loading: true })

        let response = {}
        const { dataEdit, reason } = this.props
        const formValue = Object.assign({}, this.state.Form.value)

        if (isArray(formValue.hospital_ids)) {
            formValue.hospital_ids = formValue.hospital_ids.map(v => v.value)
        } else {
            formValue.hospital_ids = [formValue.hospital_ids.value]
        }

        try {
            if (dataEdit) {
                formValue.name = formValue.name || dataEdit.name
                formValue.email = formValue.email || dataEdit.email
                formValue.active = formValue.active === 'true'
                formValue.reason = formValue.active === 'true' ? '' : reason

                if (typeof formValue.user_type === 'object') {
                    formValue.user_type = formValue.user_type.value
                }

                delete formValue.user_type
                response = await Api.put(dataEdit.id, formValue)
            } else {
                delete formValue.active
                delete formValue.user_type
                response = await Api.post(formValue)
            }

            this.setState({ loading: false })
            handleToast(response.data.message)

            this.props.loadDataAfterAction()
            this.props.onPanelClose()
        } catch (e) {
            this.setState({ loading: false })
            handleToastServiceError(e)
        }
    }
}

export default FormHandler
