import React, { Component } from 'react'
import editIcon from '../../../../themes/v1/img/layout/icon/pencil.png'
import dateFns from "date-fns"
import PatientList from './appointment/PatientList'
import Bottom from './Bottom'
import Api from '../../../../services/apis/doctor/DoctorSchedule'
import RoleHelper from '../../../../core/helper/RoleHelper'

export default class ScheduleList extends Component{
    constructor(props){
        super(props)
        this.state = {
            selectedDate: this.props.selectedDate,
            day: dateFns.format(this.props.selectedDate, 'ddd').toLowerCase(),
            doctorId: this.props.doctorId,
            scheduleId: this.props.selectedScheduleId,
            hospitalId: this.props.hospitalId,
            loadingDay: true,
            scheduleList: [],
            patientList: [],
            isFetching: false
        }
        this.fetchScheduleList = this.fetchScheduleList.bind(this)
        this.renderList = this.renderList.bind(this)
    }

    fetchScheduleList(selectedDate, scheduleId){
        const doctorId = this.state.doctorId
        const day = this.state.day
        const year = dateFns.format(selectedDate, 'YYYY')
        const month = dateFns.format(selectedDate, 'M')
        const dayNum = dateFns.format(selectedDate, 'D')
        const Schedule = Api.get(doctorId, year, month, day, dayNum, scheduleId)

        Schedule.then(response => {
            if(response && response.data){
                const data = response.data.data ? response.data.data: []
                const dataSch = data.schedule || {}
                const dataPatient = data.patient || {}
                this.setState({
                    scheduleList: Object.keys(dataSch).length > 0 ? dataSch[year][month][dayNum][0] : [],
                    patientList: Object.keys(dataPatient).length > 0 ? dataPatient : [],
                    loadingDay: false,
                })

                if(dataSch){
                    this.props.saveScheduleOpen(Object.keys(dataSch).length > 0 ? dataSch[year][month][dayNum][0] : [])
                }
            }
        })
    }

    componentWillUnmount(){
        this.setState({
            scheduleList: []
        })
    }

    componentDidUpdate(nextProps){
        if(this.state.isFetching !== nextProps.isFetching && nextProps.isFetching){
            const selectedDate = this.state.selectedDate
            this.fetchScheduleList(selectedDate)
        }
    }

    handleEdit() {
        const { doctorData } = this.props
        this.props.history.push(`/doctor/schedule/${doctorData.id}`)
    }

    renderList(){
        const scheduleList = this.state.scheduleList
        const scheduleId = this.state.scheduleId
        const patientList = this.state.patientList
        const doctorData = this.props.doctorData
        const selectedDate = this.props.selectedDate
        const estimationTime = doctorData && doctorData["estimation_time"] >= 15 ? doctorData["estimation_time"] : 15
        const isDoctor = RoleHelper.isDoctor(this.props.dataLogin, this.props.currentHospital)
        const isNurse = RoleHelper.isNurse(this.props.dataLogin)

        return(
            <div className="schedule-list">
                {
                    scheduleList.map((schedule, index) => {
                        if(schedule.id !== scheduleId){
                            return null
                        }
                        return(
                            <div key={index}>
                                <div className="row-schedule">
                                    <div className="wrap-time">
                                        <span className="text-title">Waktu</span>
                                        <span className="cal-schedule-value">{schedule['time_start'].slice(0, -3)} - {schedule['time_end'].slice(0, -3)}</span>
                                    </div>
                                    <div className="wrap-info">
                                        <div className="col-info">
                                            <span className="text-title">Estimasi Penanganan</span>
                                            <span className="cal-schedule-value">{ estimationTime } menit / Pasien</span>
                                        </div>
                                        <div className="col-info">
                                            <span className="text-title">Kuota</span>
                                            <span className="cal-schedule-value">{schedule['max_patient_count']} Pasien</span>
                                        </div>
                                    </div>
                                    {
                                        isDoctor || isNurse ? null : 
                                        <div className="wrap-action-default">
                                            <button className="edit">
                                                <img src={editIcon} alt="edit" onClick={ e => this.handleEdit() }/>
                                            </button>
                                        </div>
                                    }
                                </div>
                                <Bottom {...this.props} data={schedule} selected={selectedDate} />
                            </div>
                        )
                    })
                }
                <PatientList { ...this.props } patients={ patientList } />
            </div>
        )
    }

    componentDidMount(){
        const { selectedDate, scheduleId } = this.state
        this.fetchScheduleList(selectedDate, scheduleId)
    }

    render() {
        const  loadingDay = this.state.loadingDay
        const { doctorData, specialist, isFetching, title } = this.props
        
        if(loadingDay || isFetching){
            return <div>Loading...</div>
        }
        return (
            <div>
                <div className="wrap-info-doctor">
                    <span className="cal-specialist">{ specialist }</span>
                    <span className="cal-doctorname">{ title + ' ' + doctorData.name }</span>
                </div>
                { this.renderList() }
            </div>
        )
    }
}