import React, { Component } from 'react'
import PatientDetail from './appointment/PatientDetail'

class ScheduleDetail extends Component{
    render() {
        const doctorData = this.props.doctorData
        const estimationTime = doctorData && doctorData.estimation_time >= 15 ? doctorData.estimation_time : 15
        return (
            <div className="detail-schedule">
                <div className="wrap-info-doctor">
                    <span className="cal-specialist">{ this.props.specialist }</span>
                    <span className="cal-doctorname"> { doctorData.name }</span>
                </div>
                <div className="schedule-list">
                    {
                        this.props.scheduleData.map((schedule, index) => (
                            <div className="row-schedule" key={ index }>
                                <div className="wrap-time">
                                    <span className="text-title">Waktu</span>
                                    <span className="cal-schedule-value">{ schedule.time_start.slice(0, -3) } - { schedule.time_end.slice(0, -3) }</span>
                                </div>
                                <div className="wrap-info">
                                    <div className="col-info">
                                        <span className="text-title">Estimasi Penanganan</span>
                                        <span className="cal-schedule-value">{ estimationTime } menit / Pasien</span>
                                    </div>
                                    <div className="col-info">
                                        <span className="text-title">Kuota</span>
                                        <span className="cal-schedule-value">{ schedule.max_patient_count ? schedule.max_patient_count : 1 } Pasien</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <PatientDetail { ...this.props }/>
            </div>
        )
    }
}

export default ScheduleDetail