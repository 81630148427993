import { isEmpty } from 'lodash'

import { axiosMaster } from './MasterConfig'

const axiosM = axiosMaster()
const groupUrl = 'specialist'

export default class FacilityApi {
    static list = (page, pageSize, sortProperties, sortValue, filterData) => {
        let setSort = ''
        let setFilter = ''

        if (sortProperties) {
            setSort = `&sort=${sortProperties}&order=${sortValue}`
        }

        if (!isEmpty(JSON.parse(filterData))) {
            setFilter = `&filter=${filterData}`
        }

        return axiosM.get(`/${groupUrl}?page=${page}&limit=${pageSize}${setFilter}${setSort}`)
    }
    static post = (data) => axiosM.post(`/${groupUrl}`, data)
    static put = (id, data) => axiosM.put(`/${groupUrl}/${id}`, data)
    static delete = (id) => axiosM.delete(`/${groupUrl}/${id}`)
    static filter = () => axiosM.get(`/${groupUrl}/filter`)
}
