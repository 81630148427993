const FormPreviewHandler = {
    buildForm: function () {
        this.TextFirstName = this.TextInputFormView({
            label: "Nama Depan",
            name: "firstname",
            readonly: true,
            control: this.state.Form.controls.firstname,
        })
        this.TextLastName = this.TextInputFormView({
            label: "Nama Belakang",
            name: "lastname",
            readonly: true,
            control: this.state.Form.controls.lastname
        })

        this.TextBirthPlace = this.TextInputFormView({
            label: "Tempat Lahir",
            name: "pob",
            readonly: true,
            control: this.state.Form.controls.pob
        })

        this.TextPhone = this.TextInputFormView({
            label: "Telepon",
            name: "phone",
            readonly: true,
            control: this.state.Form.controls.phone
        })

        this.TextEmail = this.TextInputEmailFormView({
            label: "Email",
            name: "email",
            readonly: true,
            control: this.state.Form.controls.email
        })

        this.RadioGender = this.RadioInputFormView({
            name: "gender",            
            meta: {
                label: "Jenis Kelamin",
                validator: "",
                readonly:true,
                options: [
                    {
                        value: "M",
                        name: "Laki-laki",
                    },
                    {
                        value: "F",
                        name: "Perempuan",
                    }
                ]
            },
            control: this.state.Form.controls.gender
        })

        // Start Address Form

        this.TextAddress = this.TextAreaFormView({
            label: "Alamat",
            name: "street",
            readonly:true,
            control: this.state.Form.controls.street
        })

        this.DropDownPropinsi = this.DropDownFormView({
            meta: {
                label: "Propinsi",
                readonly:true,
                validator: "",
                callBack: this.getDropdownKota,
                EditshowOnlyText: false
            },
            name: "province_id",
            nameText: "province",
            control: this.state.Form.controls.province_id,
            stateName: 'propinsiOpt',
        })

        this.DropDownKota = this.DropDownFormView({
            meta: {
                label: "Kota",
                validator: "",
                readonly:true,
                callBack: this.getDropdownKecamatan,
                EditshowOnlyText: true
            },
            name: "city_id",
            nameText: "city",
            control: this.state.Form.controls.city_id,
            stateName: 'kotaOpt',
        })

        this.DropDownKecamatan = this.DropDownFormView({
            meta: {
                label: "Kecamatan",
                validator: "",
                readonly:true,
                callBack: this.getDropdownKelurahan,
                EditshowOnlyText: true
            },
            name: "kecamatan_id",
            nameText: "kecamatan",
            control: this.state.Form.controls.kecamatan_id,
            stateName: 'kecamatanOpt',
        })

        this.DropDownKelurahan = this.DropDownFormView({
            meta: {
                label: "Kelurahan",
                validator: "",
                readonly:true,
                callBack: this.setKodePos,
                EditshowOnlyText: true
            },
            name: "kelurahan_id",
            nameText: "kelurahan",
            control: this.state.Form.controls.kelurahan_id,
            stateName: 'kelurahanOpt',
        })

        // End Address Form

        this.DropDownTypeId = this.DropDownFormView({
            meta: {
                label: "Identitas",
                readonly:true,
            },
            name: "identity_type",
            control: this.state.Form.controls.identity_type,
            stateName: 'idTypeOpt',
        })

        this.TextIdNo = this.TextInputFormView({
            label: "No Identitas",
            readonly:true,
            name: "identity_no",
            control: this.state.Form.controls.identity_no
        })

        this.DatePickDob = this.DatePickerFormView({
            meta: {
                label: "Tanggal Lahir",
                readonly: false,
                maxDate: new Date()
            },
            name: "dob",
            control: this.state.Form.controls.dob
        })

        this.TextPICName = this.TextInputFormView({
            label: "Nama",
            readonly:true,
            name: "pic_name",
            control: this.state.Form.controls.pic_name
        })

        this.TextPICPhone = this.TextInputFormView({
            label: "Telepon",
            readonly:true,
            name: "pic_phone",
            control: this.state.Form.controls.pic_phone
        })

        this.TextPICEmail = this.TextInputEmailFormView({
            label: "Email",
            readonly:true,
            name: "pic_email",                                                  
            control: this.state.Form.controls.pic_email,
            inputType: "email"
        })

        this.RadioPICRelationship = this.RadioInputFormView({
            name: "pic_relationship_desc",            
            meta: {
                label: "Hubungan dengan Pasien",
                readonly:true,
                options: [
                    {
                        value: "Orang Tua",
                        name: "Orang Tua",
                    },
                    {
                        value: "Suami",
                        name: "Suami",
                    },
                    {
                        value: "Istri",
                        name: "Istri",
                    },
                    {
                        value: "Anak",
                        name: "Anak",
                    },
                    {
                        value: "Saudara",
                        name: "Saudara",
                    },
                    {
                        value: "Lainnya",
                        name: "Lainnya",
                    },
                ]
            },
            control: this.state.Form.controls.pic_relationship_desc
        })
    },

    redirectToBase: function(e) {
        const me = this
        me.props.history.push('/')
    },

    valueBuilder: function () {
        this.valueObject = {
            id: "",
            firstname: "",
            lastname: "",
            pob: "",
            email: "",
            phone: "",
            identity_type: "",
            identity_no: "",
            dob: "",
            street: "",
            province: "",
            city: "",
            kecamatan: "",
            kelurahan: "",
            province_id: "",
            city_id: "",
            kecamatan_id: "",
            kelurahan_id: "",            
            kodepos: "",
            pic_name: "",
            pic_email: "",
            pic_phone: "",
            pic_relationship_desc: ""
        }
    },
    
    handleSubmit: async function (e) {
        return false //preview only
    }
}

export default FormPreviewHandler