import React from 'react'
import Select from 'react-select'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import iconPrev from '../../themes/v1/img/prev.png'
import iconNext from '../../themes/v1/img/next.png'
import CustomAction from '../grid/CustomAction'
import FilterHeader from '../grid/FilterHeader'
import { handleToastServiceError } from '../../core/utils/UtilsToast'

const defaultButton = props => <button {...props}>{props.children}</button>;

export default class CustomPagination extends React.Component {
    constructor(props) {
        super(props)
        this.changePage = this.changePage.bind(this)
        this.state = {
            paginationLocation: 'top',
            visiblePages: this.getVisiblePages(null, props.pages),
            jumpToPage: 0,
        }

        this.updateCurrentRows(props)
    }

    static propTypes = {
        pages: PropTypes.number,
        page: PropTypes.number,
        PageButtonComponent: PropTypes.any,
        onPageChange: PropTypes.func,
        previousText: PropTypes.string,
        nextText: PropTypes.string
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.pages !== nextProps.pages) {
            this.setState({
                visiblePages: this.getVisiblePages(null, nextProps.pages)
            })
        }

        this.changePage(nextProps.page + 1)
        this.updateCurrentRows(nextProps)

    }

    filterPages = (visiblePages, totalPages) => {
        return visiblePages.filter(page => page <= totalPages);
    }

    getVisiblePages = (page, total) => {
        if (total < 7) {
            return this.filterPages([1, 2, 3, 4, 5, 6], total)
        } else {
            if (page % 5 >= 0 && page > 4 && page + 2 < total) {
                return [1, page - 1, page, page + 1, total]
            } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
                return [1, total - 3, total - 2, total - 1, total]
            } else {
                return [1, 2, 3, 4, 5, total]
            }
        }
    };

    changePage(page) {
        window.scrollTo(0, 0);

        const activePage = this.props.page + 1

        if (page === activePage) {
            return;
        }

        const visiblePages = this.getVisiblePages(page, this.props.pages)

        this.setState({
            visiblePages: this.filterPages(visiblePages, this.props.pages),
            jumpToPage: page,
        });

        this.props.onPageChange(page - 1)
    }

    updateCurrentRows(props) {
        if (typeof props.page !== 'undefined' && typeof props.pageSize !== 'undefined') {
            this.rowMin = props.page * props.pageSize + 1
            this.rowMax = Math.min((props.page + 1) * props.pageSize, props.rows)
        }
    }

    onChangeJump = (value) => {
        this.setState({ jumpToPage: value })
    }

    componentDidMount() {
        const parentClass = ReactDOM.findDOMNode(this).parentNode.className
        let paginationLocation = 'top'
        if (parentClass === 'pagination-bottom') {
            paginationLocation = 'bottom'
        }

        this.setState({
            paginationLocation: paginationLocation,
            jumpToPage: this.props.page + 1,
        })
    }

    render() {
        const {
            visiblePages,
            jumpToPage,
            paginationLocation,
        } = this.state
        const {
            PageButtonComponent = defaultButton,
            pageSize,
            rows,
            page,
            pages,
            leftAction,
            ofText,
            onPageSizeChange,
            resetFilterHeader,
            costumFilterHeader,
        } = this.props
        const activePage = page + 1
        const endPage = Math.ceil(rows / pageSize) || 1
        const customPageSize = [
            {
                label: '20',
                value: 20,
            },
            {
                label: '30',
                value: 30,
            },
            {
                label: '40',
                value: 40,
            },
        ]

        return (
            <div>
                <div className="wrap-table-pagination">
                    {
                        paginationLocation === 'top' ?
                            <div className="wrap-left-action">
                                <CustomAction leftAction={leftAction} />
                            </div> :
                            null
                    }
                    <div className="Table__pagination">
                        <div className="Table_pagination_label">
                            Tampilkan Baris
                        </div>
                        <div className="Table_pagination_size">
                            <Select
                                className="pagesize page-num dropdown-filter"
                                classNamePrefix="react-select"
                                name="filter-type"
                                options={customPageSize}
                                onChange={e => onPageSizeChange(Number(e.value))}
                                value={
                                    customPageSize.find(
                                        option => option.value === pageSize
                                    )
                                }
                            />
                        </div>
                        <div className="Table_pagination_info">
                            {(typeof rows !== 'undefined') ? <span className="-rowInfo">
                                <span className="-rowMin">{this.rowMin}</span> {" - "}
                                <span className="-rowMax">{this.rowMax}</span> <span className="Table_pagination_from">{ofText}</span> <span className="rowCount">{rows}</span>
                            </span> : ''}
                        </div>
                        <div className="Table__prevPageWrapper">
                            <PageButtonComponent
                                className="Table__pageButton first-end left"
                                onClick={() => this.changePage(1)}
                                disabled={activePage === 1}
                            >
                                First
                            </PageButtonComponent>
                        </div>
                        <div className="Table__prevPageWrapper">
                            <PageButtonComponent
                                className="Table__pageButton right"
                                onClick={() => {
                                    if (activePage === 1) return
                                    this.changePage(activePage - 1)
                                }}
                                disabled={activePage === 1}
                            >
                                <img src={iconPrev} alt="" />
                            </PageButtonComponent>
                        </div>
                        <div className="Table__visiblePagesWrapper">
                            {visiblePages.map((page, index, array) => {
                                return (
                                    <PageButtonComponent
                                        key={page}
                                        className={
                                            activePage === page
                                                ? "Table__pageButton Table__pageButton--active"
                                                : "Table__pageButton"
                                        }
                                        onClick={this.changePage.bind(null, page)}
                                    >
                                        {array[index - 1] + 2 < page ? `...${page}` : page}
                                    </PageButtonComponent>
                                )
                            })}
                        </div>
                        <div className="jump-to">
                            <input
                                className="form-control"
                                type="number"
                                value={jumpToPage}
                                onChange={(e) => this.onChangeJump(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        let setPage = parseInt(jumpToPage, 10)

                                        if (!Number.isInteger(setPage) || setPage < 1) {
                                            handleToastServiceError({
                                                message: 'Masukan angka, minimal 1',
                                            })
                                            return;
                                        }

                                        if (parseInt(jumpToPage, 10) >= endPage) {
                                            setPage = endPage
                                        }

                                        this.setState({ jumpToPage: setPage })

                                        if (activePage === parseInt(jumpToPage, 10)) return

                                        this.changePage(setPage)
                                    }
                                }}
                            />
                        </div>
                        <div className="Table__nextPageWrapper">
                            <PageButtonComponent
                                className="Table__pageButton left"
                                onClick={() => {
                                    if (activePage === pages) return
                                    this.changePage(activePage + 1)
                                }}
                                disabled={activePage === endPage}
                            >
                                <img src={iconNext} alt="" />
                            </PageButtonComponent>
                        </div>
                        <div className="Table__nextPageWrapper">
                            <PageButtonComponent
                                className="Table__pageButton first-end right"
                                onClick={() => this.changePage(endPage)}
                                disabled={activePage === endPage}
                            >
                                Last
                            </PageButtonComponent>
                        </div>
                    </div>
                </div>
                {
                    paginationLocation === 'top'
                        ? (
                            <FilterHeader
                                {...this.props}
                                resetFilterHeader={resetFilterHeader}
                                costumFilterHeader={costumFilterHeader}
                            />
                        )
                        : null
                }
            </div>
        )
    }
}

