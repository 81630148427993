import dateFns from "date-fns"
import IndonesianLocale from 'date-fns/locale/id'

import { isEmpty } from 'lodash'
import Api from '../../../services/apis/Appointment/Main'
import { handleToastServiceError } from '../../../core/utils/UtilsToast'

const Handler = {
    onPanelClose(){
        this.setState({
            selectedDate: null,
            isPaneOpen: false
        });
    },
    onPanelOpen(day){
        let selectedDayStr = null
        let fullDateStr = null
        const isLoading = this.state.isFetchingSchedule
        if(day){
            selectedDayStr = dateFns.format(day, 'dddd', {locale:IndonesianLocale})
            fullDateStr = dateFns.format(day, 'D MMMM YYYY', {locale:IndonesianLocale})
        }
        
        if(!isLoading){
            this.setState({
                selectedDate: day,
                isPaneOpen: true,
                panelData: {
                    headTitle: selectedDayStr,
                    subTitle: fullDateStr
                }
            });
        }
    },
    nextMonth(){
        this.setState({
            currentMonth: dateFns.addMonths(this.state.currentMonth, 1),
        })
    },

    prevMonth() {
        this.setState({
            currentMonth: dateFns.subMonths(this.state.currentMonth, 1),
        })
    },
    fetchSchedule: async function (currentMonth, doctorId) {
        const { doctors } = this.state
        const { hospitalId } = this.props
        const year = dateFns.format(currentMonth, 'YYYY')
        const month = dateFns.format(currentMonth, 'M')

        try {
            const response = await Api.calendar(hospitalId, doctorId, year, month)
            const {
                status,
                data,
            } = response.data

            if (!isEmpty(data)) {
                const dataDoctors = !isEmpty(data.doctors)
                    ? (data.doctors).map((item) => {
                        return {
                            value: item.doctor_id,
                            label: item.name,
                        }
                    })
                    : []

                this.setState({
                    daysState: data.schedules,
                    doctors: doctors.length > 1
                        ? doctors
                        : doctors.concat(dataDoctors),
                })
            }

            if (!status) {
                handleToastServiceError(response.data)
            }
        } catch (e) {
            handleToastServiceError(e)
        }

        setTimeout(() => {
            this.setState({ isLoading: false })
        }, 500);
    },
}

export default Handler
