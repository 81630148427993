import React from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'
import SlidingPane from 'react-sliding-pane'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import Icon from '../../core/utils/Icons'

class SliderPanel extends React.Component {
    componentDidMount() {
        Modal.setAppElement(ReactDOM.findDOMNode(this).parentNode);
    }

    render(){
        const panelData = this.props.panelData
        const customOverlay = this.props.customOverlay
        let headTitle = panelData ? panelData.headTitle : ''
        let subTitle = panelData ? panelData.subTitle : ''
    
        return(
            <div className="wrap-slider-panel" ref={ ref => this.ref = ref}>
                <SlidingPane
                    className='slider-cinnamon'
                    overlayClassName={
                        `slider-cinnamon-overlay ${customOverlay ? customOverlay : ''}
                    `}
                    isOpen={ this.props.open }
                    width={ this.props.width ? this.props.width : '50%' }
                    onRequestClose={ () => this.props.onPanelClose() }>
                    <div className="slider-panel-container">
                        <div className="header">
                            <div className="wrap-title">
                                {
                                    this.props.onHandleBack ? 
                                    <div className="back-panel" onClick={ this.props.onHandleBack }>
                                        <img src={ Icon.prev } alt="back"/>
                                    </div> :
                                    null
                                }
                                <div className="text-wrapper">
                                    <h3 className={ !subTitle ? "no-subtitle" : "" }>{ headTitle }</h3>
                                    {
                                        subTitle ? 
                                        <span>{ subTitle }</span> :
                                        null
                                    }
                                </div>
                            </div>
                            {
                                customOverlay ? null : (
                                    <button
                                        className="close-slider"
                                        onClick={() => this.props.onPanelClose()}>
                                        <img src={Icon.close} alt={"Close"} />
                                    </button>
                                )
                            }
                        </div>
                        <div className="body">
                            {this.props.children}
                        </div>
                    </div>
                </SlidingPane>
            </div>
        )
    }
}

export default SliderPanel