import React, { Component } from 'react'
import { StickyContainer, Sticky } from 'react-sticky'
import { Link } from 'react-scroll'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { loginMapStateToProps, loginMapDispatchToProps } from '../../core/const/LoginConst'
import MasterTemplate from '../../template/MasterTemplate'
import RoleHelper from '../../core/helper/RoleHelper'
import AccessPermission from '../../template/AccessPermission'
import Form from './components/Form'

class Create extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSticky: false,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    setSticky = (isSticky) => {
        if (this.state.isSticky !== isSticky) {
            this.setState({ isSticky })
        }
    }

    isOwner = (newCurrentHosp) => {
        const { dataLogin } = this.props
        const hospitalSelected = dataLogin && dataLogin.hospitals.find(option => {
            return String(option.id) === String(newCurrentHosp)
        })

        return !isEmpty(hospitalSelected) ? hospitalSelected.owner : false
    }

    getRole = (newCurrentHosp) => {
        const { dataLogin } = this.props
        return RoleHelper.getRole(dataLogin, this.isOwner(newCurrentHosp))
    }

    render() {
        const { isSticky } = this.state
        const { isLoggin, currentHospital, match } = this.props
        const role = this.getRole(currentHospital)
        const isEdit = !isEmpty(match.params)
        const menu = [
            {
                label: 'Informasi Kunjungan',
                toSection: 'patient'
            },
            {
                label: 'Subjektif',
                toSection: 'subjectif'
            },
            {
                label: 'Objektif',
                toSection: 'objectif'
            },
            {
                label: 'Assessment',
                toSection: 'assessment'
            },
        ]
        const breadcrumbs = [
            {
                label: 'Dashboard',
                path: '/dashboardhospital',
            },
            {
                label: 'Daftar Resume Medis',
                path: '/emr/list',
            },
            {
                label: `${isEdit ? 'Edit' : 'Tambah'} Resume Medis`,
                path: null,
            },
        ]

        if (currentHospital === null
            || !isLoggin
            || process.env.REACT_APP_HOSPITAL_MEDICAL_RECORD !== 'true') {
            return <Redirect to="/" />
        }

        if (((role && role.role_name !== 'superadmin')
            && (role && role.role_name !== 'admin_spv')
            && (role && role.role_name !== 'doctor')
        )) {
            return <Redirect to="/" />
        }

        return (
            <AccessPermission {...this.props} module="medical_record">
                <MasterTemplate {...this.props} breadcrumbs={breadcrumbs}>
                    <StickyContainer className="emr-form-container">
                        <Sticky topOffset={-108}>
                            {
                                ({ isSticky }) => {
                                    this.setSticky(isSticky)
                                    return (
                                        <span>
                                            {this.state.isSticky ? <div className="space-overlay" /> : null}
                                            <ul className={`list-section${this.state.isSticky
                                                ? ' sticky'
                                                : ''}`}>
                                                {
                                                    menu.map((val, key) => (
                                                        <li className="list-nav" key={key}>
                                                            <Link
                                                                className={key === 0 && !this.state.isSticky
                                                                    ? 'active'
                                                                    : null}
                                                                activeClass="active"
                                                                to={val.toSection}
                                                                spy={true}
                                                                smooth={true}
                                                                offset={-190}
                                                            >
                                                                {val.label}
                                                            </Link>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </span>
                                    )
                                }
                            }
                        </Sticky>
                        <Form {...this.props} isSticky={isSticky} />
                    </StickyContainer>
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Create)
