const Config = {
    inputTypes: [
        {
            key: 'input_text_free',
            icon: null,
            label: 'Teks',
            desc: 'Teks Field bebas dengan tipe teks atau email'
        },
        {
            key: 'input_text_numeric',
            icon: null,
            label: 'Nomor',
            desc: 'Untuk menambah field dengan format nomor (cth : No. KTP, No. telp)'
        },
        {
            key: 'calendar',
            icon: null,
            label: 'Kalender',
            desc: 'Menampilkan pilihan kalender untuk memilih tanggal'
        },
        {
            key: 'radio',
            icon: null,
            label: 'Single Choice',
            desc: 'Setelan untuk pilih salah satu dari kumpulan pilihan. contoh : Jenis Kelamin'
        },
        {
            key: 'checkbox',
            icon: null,
            label: 'Multiple Choice',
            desc: 'Setelan untuk pilih lebih dari salah satu dari kumpulan pilihan. contoh : Hobi'
        },
        {
            key: 'text_area',
            icon: null,
            label: 'Teks Area',
            desc: 'Teks Field bebas untuk memuat kata yang panjang, contoh : Alamat'
        },
        {
            key: 'dropdown',
            icon: null,
            label: 'Dropdown',
            desc: 'Setelan untuk pilih salah satu dari kumpulan pilihan. contoh : Jenis Kelamin'
        },
    ],
    targetDrop: "form_builder"
}

export default Config