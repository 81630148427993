import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import Autocomplete from 'react-autocomplete'
import { Collapse } from 'reactstrap';
import { isEmpty } from 'lodash'
import { Carousel, CarouselItem } from 'reactstrap';
import {
    FormBuilder,
    Validators,
    FieldGroup
} from 'react-reactive-form'

import MasterModal from '../../../core/utils/MasterModal'
import RoleHelper from '../../../core/helper/RoleHelper'
import SliderPanel from '../../../components/wizard/SliderPanel'
import SectionTitle from '../../infoHospital/components/formPartial/SectionTitle.js'
import DropDownForm from '../../../core/utils/wizard/DropDownForm'
import TextAreaForm from '../../../core/utils/forminput/TextAreaMinForm'
import DetailPatient from './DetailPatient'
import DetailResumeMedis from './DetailResumeMedis'
import Handler from '../handler/FormHandler'
import iconPdf from '../../../themes/v1/img/icon-pdf.png'

const objVitalSign = [
    {
        value: '',
        key: 'sistole',
        label: 'Sistole',
        unit: 'mmHg',
    },
    {
        value: '',
        key: 'diastole',
        label: 'Diastole',
        unit: 'mmHg',
    },
    {
        value: '',
        key: 'nadi',
        label: 'Nadi',
        unit: '/ menit',
    },
    {
        value: '',
        key: 'frekuensi_pernapasan',
        label: 'Frekuensi Pernapasan',
        unit: '/ menit',
    },
    {
        value: '',
        key: 'suhu_tubuh',
        label: 'Suhu Tubuh',
        unit: 'celcius',
    },
]
const objPhysicalExam = [
    {
        value: '',
        key: 'tinggi_badan',
        label: 'Tinggi Badan',
        unit: 'cm',
    },
    {
        value: '',
        key: 'berat_badan',
        label: 'Berat Badan',
        unit: 'kg',
    },
    {
        value: '',
        key: 'lingkar_perut',
        label: 'Lingkar Perut',
        unit: 'cm',
    },
]
const objBodyExam = [
    {
        value: {
            normal: '',
            note: '',
        },
        key: 'mata',
        label: 'Mata',
    },
    {
        value: {
            normal: '',
            note: '',
        },
        key: 'hidung',
        label: 'Hidung',
    },
    {
        value: {
            normal: '',
            note: '',
        },
        key: 'telinga',
        label: 'Telinga',
    },
    {
        value: {
            normal: '',
            note: '',
        },
        key: 'orofaring',
        label: 'Orofaring',
    },
    {
        value: {
            normal: '',
            note: '',
        },
        key: 'leher',
        label: 'Leher',
    },
    {
        value: {
            normal: '',
            note: '',
        },
        key: 'abdomen',
        label: 'Abdomen',
    },
    {
        value: {
            normal: '',
            note: '',
        },
        key: 'paru',
        label: 'Paru',
    },
    {
        value: {
            normal: '',
            note: '',
        },
        key: 'jantung',
        label: 'Jantung',
    },
    {
        value: {
            normal: '',
            note: '',
        },
        key: 'ekstrimitas_atas',
        label: 'Ekstrimitas Atas',
    },
    {
        value: {
            normal: '',
            note: '',
        },
        key: 'ekstrimitas_bawah',
        label: 'Ekstrimitas Bawah',
    },
]
class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            doctorOption: [],
            patientOption: [],
            selectedPatient: {},
            patientRecord: [],
            files: [],
            files_add: [],
            showObjOptional: false,
            loading: false,
            loadingPatientD: false,
            loadingEMRD: false,
            obj_vital_sign: objVitalSign,
            obj_physical_exam: objPhysicalExam,
            obj_body_exam: objBodyExam,
            diagnosaOption: [],
            diagnosaInputVal: '',
            diagnosaTouched: false,
            diagnosa: [],
            isPanelPatientOpen: false,
            panelDataPatient: {},
            isPanelMROpen: false,
            panelDataMR: {},
            isModalOpen: false,
            modalData: {},
            activeIndex: 0,
            animating: false,
            Form: FormBuilder.group({
                doctor_select: ['', Validators.required],
                patient_select: ['', Validators.required],
                subj_keluhan: ['', [Validators.required, Validators.minLength(20)]],
                notes: [''],
            })
        }
        const { currentHospital, match, isDetail } = this.props


        Object.assign(this, DropDownForm)
        Object.assign(this, TextAreaForm)
        this.DropDownFormView = this.DropDownFormView.bind(this)
        this.TextAreaFormView = this.TextAreaFormView.bind(this)

        // assign to handler
        Object.assign(this, Handler)
        this.buildValue = this.buildValue.bind(this)
        this.getDoctorList = this.getDoctorList.bind(this)
        this.getEMRDetail = this.getEMRDetail.bind(this)
        this.getDiagnosa = this.getDiagnosa.bind(this)
        this.getPatientRecord = this.getPatientRecord.bind(this)
        this.setDiagnosa = this.setDiagnosa.bind(this)
        this.delDiagnosa = this.delDiagnosa.bind(this)
        this.handleUpload = this.handleUpload.bind(this)
        this.deleteFile = this.deleteFile.bind(this)
        this.toggleObjOptional = this.toggleObjOptional.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.onPanelPatientOpen = this.onPanelPatientOpen.bind(this)
        this.onPanelPatientClose = this.onPanelPatientClose.bind(this)
        this.onPanelMROpen = this.onPanelMROpen.bind(this)
        this.onPanelMRClose = this.onPanelMRClose.bind(this)
        this.replaceArrObj = this.replaceArrObj.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.downloadFile = this.downloadFile.bind(this)

        this.isEdit = !isEmpty(match.params)
        this.isDetail = isDetail === true
        this.id = !isEmpty(match.params) ? match.params.id : null
        this.currentHospital = currentHospital
        this.buildForm()
    }

    componentDidMount() {
        const { Form } = this.state
        const { currentHospital } = this.props

        if (this.isEdit) {
            this.getEMRDetail('edit', this.id)
        }
        if (this.isDetail) {
            this.toggleObjOptional()
            Form.get('subj_keluhan').disable()
            Form.get('notes').disable()
        }
        this.getDoctorNPatient(currentHospital, false)
    }

    componentWillReceiveProps(nextProps) {
        const { currentHospital } = this.props
        if (currentHospital !== nextProps.currentHospital) {
            this.currentHospital = nextProps.currentHospital
            this.getDoctorNPatient(this.currentHospital, true)
        }
    }

    componentWillUnmount() {
        this.props.setDoctorEMR({})
        this.props.setPatientEMR({})
    }

    isDoctor = (currentHospital) => {
        const role = this.getRole(currentHospital)
        return role.role_name === 'doctor'
    }

    getDoctorNPatient = (currentHospital, isHospChanged) => {
        let doctorId = null
        const { dataLogin, doctorEMR, patientEMR, history } = this.props
        if (!isEmpty(doctorEMR)) {
            doctorId = doctorEMR.doctor_id
            this.buildValue({ doctor_select: doctorId })
        }
        if (!isEmpty(patientEMR)) {
            this.buildValue({ patient_select: patientEMR.value })
        }
        if (this.isDoctor(currentHospital)) {
            const did = dataLogin.user_id
            const selectedDoctor = {
                value: did,
                label: `${dataLogin.title
                    ? `${dataLogin.title} `
                    : ''}${dataLogin.name}`,
            }
            this.setState({ doctorOption: [selectedDoctor] })
            this.buildValue({ doctor_select: did })
            this.state.Form.get('doctor_select').disable()

            if (!this.isEdit) {
                setTimeout(() => {
                    this.getPatientList(this, 'doctor_select', did)
                }, 0);
                this.setState({
                    selectedPatient: {},
                    patientRecord: [],
                    obj_vital_sign: objVitalSign,
                    obj_physical_exam: objPhysicalExam,
                    obj_body_exam: objBodyExam,
                    files: [],
                    diagnosa: [],
                })
                this.buildValue({
                    subj_keluhan: '',
                    notes: '',
                })
                this.props.setPatientEMR({})
            }

            if (isHospChanged) {
                if (this.isEdit) {
                    history.push('/emr/list')
                }
            }
        } else {
            if (doctorEMR && doctorEMR.source === 'appointment') {
                this.getPatientRecord(patientEMR.patient_id)
                this.setState({
                    selectedPatient: {
                        patient_id: patientEMR.patient_id,
                        patient_name: patientEMR.patient_name,
                        patient_medical_record_no: patientEMR.patient_medical_record_no,
                    },
                })
            }

            if (!this.isEdit && (doctorEMR && doctorEMR.source !== 'appointment')) {
                this.getDoctorList(doctorId)
            }

            if (isHospChanged) {
                if (this.isEdit) {
                    history.push('/emr/list')
                }

                const firstOpt = [{
                    value: '',
                    label: 'Pilih Pasien'
                }]
                this.getDoctorList(doctorId)
                this.setState({
                    patientOption: firstOpt,
                    selectedPatient: {},
                    obj_vital_sign: objVitalSign,
                    obj_physical_exam: objPhysicalExam,
                    obj_body_exam: objBodyExam,
                    files: [],
                    diagnosa: [],
                    patientRecord: [],
                })
                this.buildValue({
                    doctor_select: '',
                    patient_select: '',
                    subj_keluhan: '',
                    notes: '',
                })
                this.state.Form.get('doctor_select').enable()
                this.props.setDoctorEMR({})
                this.props.setPatientEMR({})
            }
        }
    }

    isOwner = (newCurrentHosp) => {
        const { dataLogin } = this.props
        const hospitalSelected = dataLogin && dataLogin.hospitals.find(option => {
            return String(option.id) === String(newCurrentHosp)
        })

        return !isEmpty(hospitalSelected) ? hospitalSelected.owner : false
    }

    getRole = (newCurrentHosp) => {
        const { dataLogin } = this.props
        return RoleHelper.getRole(dataLogin, this.isOwner(newCurrentHosp))
    }

    onChange = (state, data, key, e) => {
        const val = e.target.value
        const fieldUpdate = data.find(item => item.key === key)
        fieldUpdate.value = val !== '' ? parseInt(val, 10) : val

        this.setState({
            [state]: this.replaceArrObj(data, [fieldUpdate])
        })
    }

    onChangeRadio = (key, e) => {
        const val = e.target.value
        const { obj_body_exam: data } = this.state
        const fieldUpdate = data.find(item => item.key === key)
        fieldUpdate.value = {
            normal: val === 'true',
            note: '',
        }

        this.setState({
            obj_body_exam: this.replaceArrObj(data, [fieldUpdate])
        })
    }

    onChangeNote = (key, e) => {
        const val = e.target.value
        const { obj_body_exam: data } = this.state
        const fieldUpdate = data.find(item => item.key === key)
        fieldUpdate.value = {
            ...fieldUpdate.value,
            note: val,
        }

        this.setState({
            obj_body_exam: this.replaceArrObj(data, [fieldUpdate])
        })
    }

    parseModal() {
        const { panelDataMR, activeIndex } = this.state

        if (!isEmpty(panelDataMR)) {
            const { file_supports: fileSupports } = panelDataMR
            const item = fileSupports[activeIndex]
            const fileName = item && item.name

            return (
                <div className="modal-preview">
                    <div className="info">
                        <div className="filename">{fileName}</div>
                        <div className="action">
                            <button onClick={() => this.downloadFile(item.url, fileName)} className="download">
                                <i className="fas fa-download" />
                            </button>
                            <div onClick={() => this.handleCloseModal()} className="close-pre">
                                <i className="fas fa-times" />
                            </div>
                        </div>
                        <div style={{ clear: 'both' }} />
                    </div>
                    <ol className="carousel-indicators-pre">
                        {this.indicators()}
                    </ol>
                    <Carousel
                        activeIndex={activeIndex}
                        next={this.next}
                        previous={this.previous}
                        interval={false}
                    >
                        {this.slides()}
                        <div>
                            <div
                                className="carousel-control carousel-control-prev"
                                onClick={this.previous}
                            >
                                <div className="arrow">
                                    <i className="fas fa-chevron-left" />
                                </div>
                            </div>
                            <div
                                className="carousel-control carousel-control-next"
                                onClick={this.next}
                            >
                                <div className="arrow">
                                    <i className="fas fa-chevron-right" />
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
            )
        }

    }

    indicators = () => {
        const { panelDataMR, activeIndex } = this.state

        if (!isEmpty(panelDataMR)) {
            const { file_supports: fileSupports } = panelDataMR

            return fileSupports.map((item, i) => {
                const filename = item.name
                const nameArr = filename.split('.')
                const ext = nameArr[nameArr.length - 1]

                return (
                    <li
                        key={i}
                        className={activeIndex === i ? 'active' : ''}
                        onClick={() => this.goToIndex(i)}
                    >
                        <div className="img-wrapper">
                            <img
                                src={ext === 'pdf'
                                    ? iconPdf
                                    : item.url}
                                alt={filename}
                            />
                        </div>
                    </li>
                );
            })
        }

        return null
    }

    slides = () => {
        const { panelDataMR } = this.state

        if (!isEmpty(panelDataMR)) {
            const { file_supports: fileSupports } = panelDataMR

            return fileSupports.map((item, i) => {
                const filename = item.name
                const nameArr = filename.split('.')
                const ext = nameArr[nameArr.length - 1]

                return (
                    <CarouselItem key={i}>
                        <div className="item-img d-flex justify-content-center">
                            {ext === 'pdf'
                                ? <embed src={`${item.url}#toolbar=0&navpanes=0&scrollbar=0`} />
                                : <img src={item.url} alt={filename} />}
                        </div>
                    </CarouselItem>
                );
            })
        }

        return null
    }

    next = () => {
        const { animating, activeIndex, panelDataMR } = this.state

        if (!isEmpty(panelDataMR)) {
            const { file_supports: fileSupports } = panelDataMR
            if (animating) return;
            const nextIndex = activeIndex === fileSupports.length - 1 ? 0 : activeIndex + 1;
            this.setState({ activeIndex: nextIndex })
        }
    }

    previous = () => {
        const { animating, activeIndex, panelDataMR } = this.state

        if (!isEmpty(panelDataMR)) {
            const { file_supports: fileSupports } = panelDataMR
            if (animating) return;
            const nextIndex = activeIndex === 0 ? fileSupports.length - 1 : activeIndex - 1;
            this.setState({ activeIndex: nextIndex })
        }
    }

    goToIndex = (newIndex) => {
        const { animating } = this.state
        if (animating) return;
        this.setState({ activeIndex: newIndex })
    }

    render() {
        const {
            Form,
            selectedPatient,
            patientRecord,
            files,
            files_add: filesAdd,
            showObjOptional,
            loading,
            diagnosaOption,
            diagnosaInputVal,
            diagnosaTouched,
            diagnosa,
            obj_vital_sign,
            obj_physical_exam,
            obj_body_exam,
            isPanelPatientOpen,
            panelDataPatient,
            isPanelMROpen,
            panelDataMR,
            isModalOpen,
        } = this.state
        const {
            currentHospital,
            doctorEMR,
            patientEMR,
        } = this.props

        const setFiles = files.concat(filesAdd)
        const checkValueVS = obj_vital_sign.filter(item => item.value <= 0)
        const vitalSignValid = checkValueVS.length === 0
        const diagnosaValid = diagnosa.length > 0

        return (
            <div>
                <FieldGroup control={Form}
                    strict={false}
                    render={({ invalid }) => {
                        return (
                            <div
                                className={`wizard-form custom-form${this.props.isSticky
                                    ? ' sticky'
                                    : ''}`}
                                style={{ width: '100%' }}
                            >
                                <form onSubmit={this.handleSubmit} autoComplete="off">
                                    <section className="emr-container">
                                        <div className="row" id="patient">
                                            <div className="col-12 col-sm-12 col-md-12 left">
                                                <div className="box-form">
                                                    <SectionTitle
                                                        title="Informasi Kunjungan"
                                                        subtitle="Jadwal, Data, Riwayat Pasien"
                                                        className="emr" />
                                                    {this.isEdit
                                                        || this.isDoctor(currentHospital)
                                                        || doctorEMR && doctorEMR.source === 'appointment' // eslint-disable-line
                                                        ? (
                                                            <div className="form-line">
                                                                <label
                                                                    id="label-dokter-edit"
                                                                    className="form-control-label"
                                                                    htmlFor="dokter-edit"
                                                                >
                                                                    Dokter
                                                                <span className="required">*</span>
                                                                </label>
                                                                <div className="form-group disabled">
                                                                    <input
                                                                        id="dokter-edit"
                                                                        className="form-control"
                                                                        placeholder="Dokter"
                                                                        disabled
                                                                        value={doctorEMR && doctorEMR.label || ''} // eslint-disable-line
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                        : this.doctorField}
                                                    {this.isEdit
                                                        || doctorEMR && doctorEMR.source === 'appointment' // eslint-disable-line
                                                        ? (
                                                            <div className="form-line">
                                                                <label
                                                                    id="label-patient-edit"
                                                                    className="form-control-label"
                                                                    htmlFor="patient-edit"
                                                                >
                                                                    Jadwal Pasien
                                                                <span className="required">*</span>
                                                                </label>
                                                                <div className="form-group disabled">
                                                                    <input
                                                                        id="patient-edit"
                                                                        className="form-control"
                                                                        placeholder="Jadwal Pasien"
                                                                        disabled
                                                                        value={patientEMR && patientEMR.label || ''} // eslint-disable-line
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                        : this.patientField}
                                                    {!isEmpty(selectedPatient)
                                                        ? (
                                                            <div className="form-line">
                                                                <label className="form-control-label">
                                                                    Data Pasien
                                                                </label>
                                                                <div className="emr-patient-wrapper">
                                                                    <div>
                                                                        <div className="patient-label">
                                                                            Nama Pasien
                                                                        </div>
                                                                        <div className="patient-value">
                                                                            {selectedPatient.patient_name}
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className="patient-label">
                                                                            No. Resume Medis
                                                                    </div>
                                                                        <div className="patient-value">
                                                                            {selectedPatient.patient_medical_record_no || '-'}
                                                                        </div>
                                                                    </div>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => this.onPanelPatientOpen(selectedPatient)}
                                                                    >
                                                                        Lihat Detail
                                                                        <i className="fas fa-chevron-right" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" id="subjectif">
                                            <div className="col-12 col-sm-12 col-md-12 left">
                                                <div className="box-form">
                                                    <SectionTitle
                                                        title="Subjektif"
                                                        subtitle="Keluhan, Anamnesis"
                                                        className="emr" />
                                                    {this.complaintField}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" id="objectif">
                                            <div className="col-12 col-sm-12 col-md-12 left">
                                                <div className="box-form">
                                                    <SectionTitle
                                                        title="Objektif"
                                                        subtitle="Tanda - tanda vital, pemeriksaan fisik, tubuh"
                                                        className="emr" />
                                                    <div className="form-line">
                                                        <label className="form-control-label required">
                                                            Tanda - Tanda Vital
                                                            <span className="required">*</span>
                                                        </label>
                                                        {obj_vital_sign.map(item => (
                                                            <div
                                                                key={item.key}
                                                                className={`field ${item.key}`}
                                                            >
                                                                <div>{item.label}</div>
                                                                <div className="fInput">
                                                                    <input
                                                                        name={item.key}
                                                                        type="number"
                                                                        placeholder="0"
                                                                        disabled={this.isDetail}
                                                                        value={item.value}
                                                                        onChange={(e) => this.onChange(
                                                                            'obj_vital_sign',
                                                                            obj_vital_sign,
                                                                            item.key,
                                                                            e,
                                                                        )}
                                                                    />
                                                                    <div>{item.unit}</div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div style={{ clear: 'both' }} />
                                                    <div
                                                        onClick={() => this.toggleObjOptional()}
                                                        className="toggle-opt"
                                                    >
                                                        Pemeriksaan Fisik (opsional)
                                                        <i className={`fas fa-chevron-${showObjOptional
                                                            ? 'up' : 'down'}`} />
                                                    </div>
                                                    <Collapse isOpen={showObjOptional}>
                                                        <div className="form-line">
                                                            <label className="form-control-label">
                                                                Pemeriksaan Fisik
                                                        </label>
                                                            {obj_physical_exam.map(item => (
                                                                <div
                                                                    key={item.key}
                                                                    className={`field ${item.key}`}
                                                                >
                                                                    <div>{item.label}</div>
                                                                    <div className="fInput">
                                                                        <input
                                                                            name={item.key}
                                                                            type="number"
                                                                            placeholder="0"
                                                                            disabled={this.isDetail}
                                                                            value={item.value}
                                                                            onChange={(e) => this.onChange(
                                                                                'obj_physical_exam',
                                                                                obj_physical_exam,
                                                                                item.key,
                                                                                e,
                                                                            )}
                                                                        />
                                                                        <div>{item.unit}</div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="form-line">
                                                            <label className="form-control-label">
                                                                Pemeriksaan Tubuh
                                                        </label>
                                                            <div className="header">
                                                                <div className="title1">
                                                                    Bagian Tubuh
                                                            </div>
                                                                <div className="title2">
                                                                    Normal
                                                            </div>
                                                                <div className="title3">
                                                                    Catatan
                                                            </div>
                                                            </div>
                                                            {obj_body_exam.map(item => (
                                                                <div className={`body ${item.key}`} key={item.key}>
                                                                    <div className="label">
                                                                        {item.label}
                                                                    </div>
                                                                    <label className="container-radio normal">
                                                                        <input
                                                                            name={item.key}
                                                                            type="radio"
                                                                            value={true}
                                                                            disabled={this.isDetail}
                                                                            checked={item.value.normal === true}
                                                                            onChange={(e) => this.onChangeRadio(
                                                                                item.key,
                                                                                e,
                                                                            )}
                                                                        />
                                                                        <span className="checkmark" />
                                                                    </label>
                                                                    <div className="note">
                                                                        <label className="container-radio">
                                                                            <input
                                                                                name={item.key}
                                                                                type="radio"
                                                                                value={false}
                                                                                disabled={this.isDetail}
                                                                                checked={item.value.normal === false}
                                                                                onChange={(e) => this.onChangeRadio(
                                                                                    item.key,
                                                                                    e,
                                                                                )}
                                                                            />
                                                                            <span className="checkmark" />
                                                                        </label>
                                                                        {item.value.normal === false
                                                                            ? (
                                                                                <input
                                                                                    name={item.key}
                                                                                    type="text"
                                                                                    placeholder="Isi catatan"
                                                                                    disabled={this.isDetail}
                                                                                    value={item.value.note}
                                                                                    onChange={(e) => this.onChangeNote(
                                                                                        item.key,
                                                                                        e,
                                                                                    )}
                                                                                />
                                                                            ) : null}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Collapse>
                                                    {this.notesField}
                                                    <div className="form-line">
                                                        <label className="form-control-label">
                                                            Dokumen Pendukung
                                                        </label>
                                                        {setFiles.length > 0
                                                            ? setFiles.map((item, i) => (
                                                                <div
                                                                    key={item.id}
                                                                    className={`file-wrap${i === 4
                                                                        ? ' fifth' : ''}`}
                                                                >
                                                                    <div className="filename">
                                                                        <i className="far fa-file" />
                                                                        {item.name}
                                                                    </div>
                                                                    <i
                                                                        className={`fas fa-trash${this.isDetail
                                                                            ? ' hidden' : ''}`}
                                                                        onClick={() => this.deleteFile(item.id)}
                                                                    />
                                                                </div>
                                                            )) : null}
                                                        <Dropzone
                                                            accept="image/*,application/pdf"
                                                            onDrop={this.handleUpload}
                                                            maxSize={2500000}
                                                            disabled={setFiles.length >= 5 || this.isDetail}
                                                        >
                                                            {({ getRootProps, getInputProps }) => (
                                                                <div
                                                                    className={`upload${setFiles.length >= 5 || this.isDetail
                                                                        ? ' hidden' : ''}`}
                                                                    {...getRootProps()}
                                                                >
                                                                    <input {...getInputProps()} />
                                                                    <div style={{ textAlign: 'center' }}>
                                                                        <i className="fas fa-plus" />
                                                                        Unggah
                                                                        <small style={{ display: 'block' }}>
                                                                            Format : Image atau PDF
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Dropzone>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" id="assessment">
                                            <div className="col-12 col-sm-12 col-md-12 left">
                                                <div className="box-form">
                                                    <SectionTitle
                                                        title="Assessment"
                                                        subtitle="Diagnosa, ICD"
                                                        className="emr" />
                                                    <div className="form-line">
                                                        <label className="form-control-label">
                                                            Diagnosa
                                                            <span className="required">*</span>
                                                        </label>
                                                        <Autocomplete
                                                            inputProps={{
                                                                onBlur: () => this.setState({
                                                                    diagnosaTouched: true,
                                                                })
                                                            }}
                                                            wrapperStyle={{
                                                                display: this.isDetail
                                                                    ? 'none' : 'block',
                                                            }}
                                                            menuStyle={diagnosaOption.length > 0
                                                                ? {
                                                                    position: 'fixed',
                                                                    overflow: 'hidden',
                                                                    borderRadius: '5px',
                                                                    zIndex: 1,
                                                                    boxShadow: '0 6px 24px 0 rgba(220, 229, 240, 0.7)',
                                                                }
                                                                : { display: 'none' }}
                                                            getItemValue={(item) => item.name}
                                                            items={diagnosaOption}
                                                            renderItem={(item, isHighlighted) =>
                                                                <div
                                                                    key={item.id}
                                                                    className={`item-list item${item.id}${isHighlighted
                                                                        ? ' active' : ''}`}
                                                                >
                                                                    {`${item.name}${item.code
                                                                        ? ` - ${item.code}`
                                                                        : ''}`}
                                                                </div>
                                                            }
                                                            value={diagnosaInputVal}
                                                            onChange={(e) => this.getDiagnosa(e.target.value)}
                                                            onSelect={(val) => this.setDiagnosa(val)}
                                                        />
                                                        {diagnosaTouched && diagnosa.length === 0
                                                            ? (
                                                                <span className="error-info">
                                                                    Diagnosa harus di isi
                                                                </span>
                                                            ) : null}
                                                        {diagnosa.length > 0
                                                            ? (
                                                                <div className={`d-list${this.isDetail
                                                                    ? ' detail' : ''}`}>
                                                                    <div className="wrapper">
                                                                        <div className="header">
                                                                            <div className="label">Diagnosa</div>
                                                                            <div className="code">Kode</div>
                                                                        </div>
                                                                        {diagnosa.map(item => (
                                                                            <div
                                                                                key={item.code}
                                                                                className="body"
                                                                            >
                                                                                <div className="label">
                                                                                    {item.name}
                                                                                </div>
                                                                                <div className="code">
                                                                                    {item.code}
                                                                                    <i
                                                                                        className="fas fa-trash"
                                                                                        onClick={() => this.delDiagnosa(item.id)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            )
                                                            : (
                                                                <div className="empty">
                                                                    Belum ada diagnosa
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <button
                                        type="submit"
                                        className={
                                            `emr save itgbtn primary${this.isDetail
                                                ? ' hidden' : ''
                                            }${invalid || !vitalSignValid || !diagnosaValid || loading
                                                ? ' disabled'
                                                : ''}`
                                        }
                                        disabled={invalid || !vitalSignValid || !diagnosaValid || loading}
                                        onSubmit={this.handleSubmit}
                                    >
                                        {this.isEdit ? 'Update' : 'Simpan'}
                                    </button>
                                </form>
                            </div>
                        )
                    }}
                />
                <SliderPanel
                    {...this.props}
                    open={isPanelPatientOpen}
                    onPanelClose={this.onPanelPatientClose}
                    panelData={panelDataPatient}
                >
                    <DetailPatient
                        {...panelDataPatient}
                        patientRecord={patientRecord}
                        onPanelMROpen={this.onPanelMROpen}
                    />
                </SliderPanel>
                <SliderPanel
                    {...this.props}
                    width="75%"
                    open={isPanelMROpen}
                    onPanelClose={this.onPanelMRClose}
                    onHandleBack={panelDataMR.customCls
                        ? this.onPanelMRClose
                        : null}
                    panelData={panelDataMR}
                    customOverlay={panelDataMR.customCls
                        ? 'slider-cinnamon-overlay-two'
                        : ''}
                >
                    <DetailResumeMedis
                        {...panelDataMR}
                        handleOpenModal={this.handleOpenModal}
                    />
                </SliderPanel>
                <MasterModal
                    className="master-modal"
                    isOpen={isModalOpen}
                    handleClose={() => this.handleCloseModal()}
                >
                    {this.parseModal()}
                </MasterModal>
            </div>
        )
    }
}

export default Form
