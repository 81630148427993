import React, { Component } from 'react'
import WizardHeader from './partials/WizardHeader'
import WizardContent from './partials/WizardContent'

class WizardTemplate extends Component
{
    render() {
        return (
            <div className="wizard-template">
                <WizardHeader {...this.props} />
                <WizardContent {...this.props} />
            </div>
        )
    }
}

export default WizardTemplate