import axios, { axiosBase } from '../MasterConfig'

const axiosB = axiosBase()
const groupUrl = 'patient';

class Patient {
    static get = (page, pageSize, sortProperties, sortValue, filterData, currentHospital) => {
        let setSort = ''
        if (sortProperties) {
            setSort = `&sort=${sortProperties}&order=${sortValue}`
        }

        let setFilter = ''
        if (filterData !== '{}') {
            setFilter = `&filter=${filterData}`
        }

        return axiosB.get(`/hospital/${groupUrl}?hospital_id=${currentHospital}&page=${page}&limit=${pageSize}${setSort}${setFilter}`)
    }
    static post = (data) => axiosB.post(`/hospital/${groupUrl}`, data)
    static delete = (id) => axiosB.delete(`/hospital/${groupUrl}/${id}`)
    static update = (id, data) => axiosB.put(`/hospital/${groupUrl}/${id}`, data)
    static bulkDelete = (data) => axios.post(`/${groupUrl}/bulkdeleted`, data)
    static bulkDownload = (data) => axios.post(`/${groupUrl}/download`, data)
    static view = (id) => axiosB.get(`/hospital/${groupUrl}/${id}`)
    static prescList = (hid, did, phone) => axiosB.get(`/hospital/${groupUrl}/prescription?hospital_id=${hid}&doctor_id=${did}&phone=${phone}`)
    static prescDetail = (id, hid, did) => axiosB.get(`/hospital/${groupUrl}/prescription/${id}?hospital_id=${hid}&doctor_id=${did}`)
    static chatList = (hid, uid) => axiosB.get(`/hospital/${groupUrl}/chattag?hospital_id=${hid}&user_id=${uid}`)
    static filter = (hid) => axiosB.get(`/hospital/${groupUrl}/filter?hospital_id=${hid}`)
}

export default Patient;
