import React from 'react'
import Chance from 'chance'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'

import { handleToast, handleToastServiceError } from '../../../core/utils/UtilsToast'
import Api from '../../../services/apis/Patient/Patient'
import ApiEMR from '../../../services/apis/MedicalRecordHospital'

const chance = new Chance();

const saveData = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (data, fileName) {
        const blob = new Blob([data], {type: "octet/stream"}),
            url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}())

const GridHandler = {
    viewSlidePatient(obj) {
        this.getPatientRecord(obj)
    },
    onPanelClose() {
        this.setState({
            isPaneOpen: false,
            panelDataPatient: {},
        })
    },
    onPanelMRClose() {
        this.setState({ isPanelMROpen: false })
        setTimeout(() => {
            this.setState({ panelDataMR: {} })
        }, 300);
    },
    onPanelMROpen(id, accessBy) {
        this.getEMRDetail(id, accessBy)
    },
    gridColumn: function (ContextMenuProvider) {
        return [
            {
                Header: "Nama Pasien",
                accessor: "nama",
                minWidth: 108,
                Cell: row => {
                    if(row.original.status === 1){
                        return(
                            <div className="row-float">
                                <div className="grid-link" onClick={ () => this.viewSlidePatient(row.original) }>
                                    { row.original.nama }
                                </div>
                            </div>
                        )
                    }else{
                        return(
                            <div className="row-float is-draft">
                                <div className="grid-link" onClick={ () => this.viewSlidePatient(row.original) }>
                                    <strong>{ '[DRAFT] ' }</strong>{ row.original.nama }
                                </div>
                            </div>
                        )
                    }
                }
            },
            {
                Header: "Tempat Lahir",
                accessor: "tempat_lahir",
                minWidth: 105,
                Cell: cellInfo => cellInfo.original.tempat_lahir || '-',
            },
            {
                Header: "Tanggal Lahir",
                minWidth: 108,
                Cell: cellInfo => {
                    return this.parseDate(cellInfo.original.tanggal_lahir)
                }
            },
            {
                Header: "No. Resume Medis",
                accessor: "no_rekam_medis",
                minWidth: 140,
                Cell: cellInfo => cellInfo.original.no_rekam_medis || '-',
            },
            {
                Header: "No. Telepon",
                accessor: "telepon",
                filterType: "number",
            },
            {
                Header: "Alamat",
                accessor: "alamat",
                Cell: cellInfo => cellInfo.original.alamat || '-',
            },
            {
                sortable: false,
                filterType: "reset",
                width: 50,
                Cell: row => (
                    <div>
                        <button
                            title="Ubah"
                            className="action-row"
                            onClick={() => this.handleClick('edit', row.original)}
                        >
                            <FontAwesomeIcon icon={faPencilAlt} />
                        </button>
                    </div>
                )
            },
            {
                filterable: false,
                sortable: false,
                width: 50,
                Cell: row => (
                    <div>
                        <button title="Hapus" className="action-row" onClick={() => this.handleClick('delete', row.original)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                )
            }
        ]
    },
    downloadHandler() {
        let { selection, data, bulkBruteDownload } = this.state
        let downloaded = []
        let me = this
        let id = 0
        let theObject = {}
        
        if(bulkBruteDownload){
            theObject = {                                                                               
                ids: 'all',
                id_hospital: this.props.currentHospital
            }
        }else{
            selection.forEach(value => {
                let foundData = data.filter(obj => obj[this.keyTable] === value)
                id = foundData[0].id
                downloaded.push(id)
            })
            
            theObject = {                                                                               
                ids: downloaded.join(),
                id_hospital: this.props.currentHospital
            }
        }

        Api.bulkDownload(JSON.stringify(theObject)).then(function (response) {
            if ( response.status !== 200 ){
            	handleToast('Something went wrong')
            	return
            }
            //now download the file
            saveData(response.data,'Data_Pasien.csv')
            me.setState({
                fetchingGrid: false
            })
            let state = {
                pageSize: me.pageSize,
                page: me.page,
                sorted: me.sorted,
                filter: ''
            }
            me.fetchData(state, null)
            me.setState({data: data, downloaded: downloaded, selection: []})
            me.handleCloseModal()
    
        }, function (err) {
            // handleToast('Something went wrong ' + err.message)
            handleToastServiceError(err, () => {
                me.handleCloseModal()
            })
        })

    },
    bruteBulkDownloadTrigger(){
        const allSelected = this.checkboxTable.props.selectAll
        if(!allSelected){
            this.checkboxTable.props.toggleAll()
        }

        this.setState({
            bulkBruteDownload: true
        })
    },
    parseDate: function(strDate){
        var months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        const date = new Date(strDate)

        return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`
    },
    handleClick: function (action, theRow){
        switch (action) {
            case 'delete':
                const ModalData = {
                    action: "delete_patient",
                    id: theRow.id,
                    content: "Anda akan menghapus Pasien ini. <br />Apakah Anda Yakin?",
                    buttonConfirmText: "Ya, Hapus Data",
                    buttonCancelText: "Batalkan",
                    ConfirmFunction: null
                }
                this.handleOpenModal(ModalData)
                break
            case 'edit':
                this.setState({dataProvider: theRow, editTrigger: true})
                this.updateStatusState()
                break
            default:
                return null
        }
    },

    bulkDelete: function () {
        const HospitalId = this.props.currentHospital
        let selection = this.state.selection
        let originData = this.state.data
        let deleted = []
        let me = this
        let theObject = []

        if(this.state.bulkBruteDownload){
            theObject = {                                                                               
                id_hospital: HospitalId,
                ids: 'all'
            }
        }else{
            selection.forEach(value => {
                let foundData = originData.filter(obj => obj[this.keyTable] === value)
                deleted.push(foundData[0].id)
    
                theObject = {                                                                               
                    id_hospital: HospitalId,
                    ids: deleted.join()
                }
                
            })
        }

        Api.bulkDelete(JSON.stringify(theObject)).then(function (response) {
            if ( response.status !== 200 ){
            	handleToast('Something went wrong');
            	return;
            }
            me.setState({
                // roleItem : [...originData],
                fetchingGrid: false
            })
            let state = {
                pageSize: me.pageSize,
                page: me.page,
                sorted: me.sorted,
                search: ''
            }
            me.fetchData(state, null)
            me.setState({data: originData, deleted: deleted, selection: []})
            handleToast(response.data.message);
            me.handleCloseModal()

        }, function (err) {
            me.handleCloseModal()
            handleToast('Something went wrong ' + err.message);
        });

    },

    fetchData: async function (state) {
        // search keyword
        let fArray = {}

        state.filtered.map(val => 
            fArray[val.id] = val.value
        )
        const filterData = JSON.stringify(fArray)

        this.setState({filterTrigger: false})
        this.pageSize = state.pageSize
        this.page = state.page
        this.setState({loading: true})
        let sort_property = ''
        let sort_value = ''
        let res
        const IdHospital = this.currentHospital

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'DESC' : 'ASC'
            this.sorted = state.sorted
        }

        try {
            res = await Api.get(
                state.page + 1,
                state.pageSize,
                sort_property,
                sort_value,
                filterData,
                IdHospital,
            )
        } catch (e) {
            this.setState({
                loading: false
            })
            handleToastServiceError(e)
            return
        }

        if (React.isValidElement(res)) {
            return
        }

        const {
            code,
            data: resData,
        } = res.data

        if (code === 200) {
            let newRows = resData.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })

            this.setState({
                data: newRows,
                pages: resData.total_page,
                rows: resData.total_rows,
                loading: false,
                disableHighlight: this.state.disableHighlight
            })
        } else {
            this.setState({
                loading: false
            })
        }
    },

    refreshData(){
        this.fetchData({
            page: this.page,
            pageSize: this.pageSize,
            filtered: [],
            sorted: this.sorted,
        })
    },
    bulkDeleteHandler() {
        const ModalData = {
            action: "delete_patient_bulk",
            id: null,
            content: "Anda akan menghapus Pasien terpilih. <br />Apakah Anda Yakin?",
            buttonConfirmText: "Ya, Hapus Data",
            buttonCancelText: "Batalkan",
            ConfirmFunction: null
        }
        this.handleOpenModal(ModalData)
    },
    clearBruteDownload(){
        this.checkboxTable.props.toggleAll()
        this.setState({
            bulkBruteDownload: false,
            selection: []
        })
    },
    handleOpenModal(ModalData, data) {
        let mData = ModalData
        if (ModalData === 'preview') {
            mData = {
                action: ModalData,
                data,
            }
        }

        this.setState({
            isModalOpen: true,
            activeIndex: data ? data.index : 0,
            ModalData: mData
        })
    },
    handleCloseModal(){
        this.setState({
            isModalOpen: false,
            activeIndex: 0,
            ModalData: null
        })
    },
    modalHandleSubmit(){
        let me = this
        const ModalData = me.state.ModalData
        
        if(ModalData.action === 'delete_patient_slide'){
            Api.delete(ModalData.id).then(function (response) {
                let state = {
                    pageSize: me.pageSize,
                    page: me.page,
                    sorted: me.sorted,
                    filtered: []
                }
                me.fetchData(state, null)
                me.handleCloseModal()
                me.onPanelClose()
                handleToast(response.data.message)
            }, function(error){
                me.handleCloseModal()
                me.onPanelClose()
                handleToast(error.message)
            })
        }else if(ModalData.action === 'delete_patient'){
            Api.delete(ModalData.id).then(function (response) {
                let state = {
                    pageSize: me.pageSize,
                    page: me.page,
                    sorted: me.sorted,
                    filtered: []
                }
                me.fetchData(state, null)
                me.handleCloseModal()
                handleToast(response.data.message)
            }, function(error){
                me.handleCloseModal()
                handleToast(error.message)
            })
        }else if(ModalData.action === 'delete_patient_bulk'){
            let selection = this.state.selection
            let originData = this.state.data
            let deleted = []
            let me = this
            let objDel = null
           
            if(this.state.bulkBruteDownload){
                objDel = {                                                                               
                    ids: 'all',
                    id_hospital: this.props.currentHospital
                }
            }else{
                selection.forEach(value => {
                    let foundData = originData.filter(obj => obj[this.keyTable] === value)
                    if(foundData[0].id){
                        deleted.push(foundData[0].id)
                    }
                })

                objDel = {                                                                               
                    ids: deleted.join(),
                    id_hospital: this.props.currentHospital
                }
            }
    
            Api.bulkDelete(JSON.stringify(objDel)).then(function (response) {
                if ( response.status !== 200 ){
                    handleToast('Something went wrong');
                    return
                }

                let state = {
                    pageSize: me.pageSize,
                    page: me.page,
                    sorted: me.sorted,
                    filtered: []
                }
                me.fetchData(state, null)
                me.handleCloseModal()
                handleToast(response.data.message)
            }, function (error) {
                me.handleCloseModal()
                handleToast(error.message)
            });
        }
    },
    getPatientRecord: function (obj) {
        ApiEMR.patientRecord(this.currentHospital, obj.id).then((res) => {
            const {
                status,
                data: resData,
            } = res

            if (status === 200) {
                this.setState({
                    isPaneOpen: true,
                    panelDataPatient: {
                        ...obj,
                        headTitle: <div>Detail Data Pasien</div>,
                    },
                    patientRecord: resData.data,
                })
            }
        })
    },
    getEMRDetail: async function (id, accessBy) {
        this.setState({ loadingEMRD: true })
        await ApiEMR.detail(this.currentHospital, id).then((res) => {
            const {
                status,
                data: resData,
            } = res

            this.setState({ loadingEMRD: false })
            if (status === 200) {
                this.setState({
                    isPanelMROpen: true,
                    panelDataMR: {
                        ...resData.data,
                        headTitle: <div>Detail Resume Medis</div>,
                        customCls: accessBy !== 'form'
                    },
                })
            }
        })
    },
    downloadFile(link, fileName) {
        fetch(`https://cors-anywhere.herokuapp.com/${link}`)
            .then(response => {
                if (response.status === 200) {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob)
                        let a = document.createElement('a')
                        a.href = url
                        a.download = fileName
                        a.click()
                    })
                } else {
                    handleToastServiceError({
                        message: 'File not found.'
                    })
                }
            });
    },
    getFilterList: async function () {
        try {
            const response = await Api.filter(this.currentHospital)
            const { data } = response.data
            const filterList = data

            this.setState({ filterList })
        } catch (e) {
            handleToastServiceError(e)
        }
    },
}

export default GridHandler
