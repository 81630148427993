import React from 'react'
import iconFreeText from '../../../../themes/v1/img/svg/icon/formbuilder/textfree.svg'
import iconNumeric from '../../../../themes/v1/img/svg/icon/formbuilder/textnumeric.svg'
import iconTextarea from '../../../../themes/v1/img/svg/icon/formbuilder/textarea.svg'
import iconRadio from '../../../../themes/v1/img/svg/icon/formbuilder/radio.svg'
import iconCalendar from '../../../../themes/v1/img/svg/icon/formbuilder/calendar.svg'
import iconCheckbox from '../../../../themes/v1/img/svg/icon/formbuilder/radio.svg'
import iconDropdown from '../../../../themes/v1/img/svg/icon/formbuilder/dropdown.svg'

class IconTool extends React.Component
{
    renderIcon(key){
        switch(key){
          case 'input_text_free' :
            return <img className="icon-tool" src={ iconFreeText } alt="icon"/>
          case 'input_text_numeric' :
            return <img className="icon-tool" src={ iconNumeric } alt="icon"/>
          case 'calendar' :
            return <img className="icon-tool" src={ iconCalendar } alt="icon"/>
          case 'radio' :
            return <img className="icon-tool" src={ iconRadio } alt="icon"/>
          case 'checkbox' :
            return <img className="icon-tool" src={ iconCheckbox } alt="icon"/>
          case 'dropdown' :
            return <img className="icon-tool" src={ iconDropdown } alt="icon"/>
          case 'text_area' :
            return <img className="icon-tool" src={ iconTextarea } alt="icon"/>
          default:
            return <img className="icon-tool" src={ iconFreeText } alt="icon"/>
        }
    }

    render(){
        return this.renderIcon(this.props.fieldKey)
    }
}

export default IconTool