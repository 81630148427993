import React, { Component } from 'react'
import { Collapse } from 'reactstrap';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils'
import dateFns from 'date-fns'

import iconPdf from '../../../themes/v1/img/icon-pdf.png'

class DetailResumeMedis extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showObjVital: false,
            showObjPhysical: false,
            showObjBody: false,
        }

        this.getAge = this.getAge.bind(this)
        this.toggleObjVital = this.toggleObjVital.bind(this)
        this.toggleObjPhysical = this.toggleObjPhysical.bind(this)
        this.toggleObjBody = this.toggleObjBody.bind(this)
        this.downloadZip = this.downloadZip.bind(this)
    }

    getAge(dateString) {
        if (dateString) {
            var dob = dateFns.format(dateString, 'YYYY-MM-DD')
            var today = new Date();
            var birthDate = new Date(dob);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();

            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }

        return '-'
    }

    toggleObjVital() {
        this.setState(prevState => ({
            showObjVital: !prevState.showObjVital
        }))
    }

    toggleObjPhysical() {
        this.setState(prevState => ({
            showObjPhysical: !prevState.showObjPhysical
        }))
    }

    toggleObjBody() {
        this.setState(prevState => ({
            showObjBody: !prevState.showObjBody
        }))
    }

    downloadZip(patientName, links) {
        let count = 0;
        const zip = new JSZip();
        const zipFilename = `Resume Medis (${patientName}).zip`;

        links.forEach(item => {
            const filename = item.name;
            JSZipUtils.getBinaryContent(`https://cors-anywhere.herokuapp.com/${item.url}`, (err, data) => {
                if (err) {
                    throw err;
                }

                zip.file(filename, data, { binary: true });
                count++;
                if (count === links.length) {
                    zip.generateAsync({ type: 'blob' }).then(content => {
                        saveAs(content, zipFilename);
                    });
                }
            });
        });
    }

    render() {
        const { showObjVital, showObjPhysical, showObjBody } = this.state
        const {
            emr_no: emrNo,
            emr_date: emrDate,
            patient_name: patientName,
            patient_gender: patientGender,
            patient_dob: patientDOB,
            doctor_title: doctorTitle,
            doctor_name: doctorName,
            diagnosa,
            notes,
            subj_keluhan: subjKel,
            obj_vital_sign: objVitalSign,
            obj_physical_exam: objPhysicalExam,
            obj_body_exam: objBodyExam,
            file_supports: fileSupports,
            handleOpenModal,
        } = this.props

        return (
            <div className="emr-detail-record rm">
                <div className="title">Informasi Umum</div>
                <div className="emr-row patient">
                    <div className="field name">
                        <div className="label">Nama Pasien</div>
                        <div className="value">{patientName}</div>
                    </div>
                    <div className="field emr-no">
                        <div className="label">No. Resume Medis</div>
                        <div className="value">{emrNo || '-'}</div>
                    </div>
                    <div className="field gender">
                        <div className="label">Jenis Kelamin</div>
                        <div className="value">
                            {patientGender === 'F'
                                ? 'Perempuan'
                                : 'Laki-laki'}
                        </div>
                    </div>
                    <div className="field third">
                        <div className="label">Umur</div>
                        <div className="value">
                            {this.getAge(patientDOB)}
                        </div>
                    </div>
                    <div className="field date">
                        <div className="label">Tanggal Periksa</div>
                        <div className="value">{emrDate}</div>
                    </div>
                </div>
                <div className="title">Subjektif</div>
                <div className="emr-row physical">
                    <div className="field">
                        <div className="label">Keluhan Penyakit</div>
                        <div className="value" style={{ whiteSpace: 'pre-wrap' }}>
                            {subjKel}
                        </div>
                    </div>
                </div>
                <div className="title">Assessment</div>
                <div className="diagnosa">
                    <div className="emr-header">
                        <div className="label diagnosa">
                            Diagnosa
                        </div>
                        <div className="label code">
                            Kode
                        </div>
                        <div className="label code">
                            Nama Dokter
                        </div>
                    </div>
                    {diagnosa && diagnosa.length > 0
                        ? diagnosa.map((item, i) => (
                            <div className="emr-body" key={item.code}>
                                <div className="val diagnosa">{item.name}</div>
                                <div className="val code">{item.code}</div>
                                {i === 0
                                    ? (
                                        <div className="val doctor-name">
                                            {`${doctorTitle ? `${doctorTitle} ` : ''}${doctorName}`}
                                        </div>
                                    )
                                    : null}
                            </div>
                        ))
                        : (
                            <div className="emr-body">
                                <div className="val diagnosa">-</div>
                                <div className="val code">-</div>
                                <div className="val doctor-name">-</div>
                            </div>
                        )
                    }
                </div >
                <div className="title">Objektif</div>
                <div className="obj-wrapper">
                    <div className="info">
                        <div className="emr-row">
                            <div className="field note">
                                <div className="label">Catatan Dokter</div>
                                <div className="value">{notes}</div>
                            </div>
                        </div>
                        <div
                            onClick={() => this.toggleObjVital()}
                            className="toggle"
                        >
                            Tanda - Tanda Vital
                            <i className={`fas fa-chevron-${showObjVital
                                ? 'up' : 'down'}`} />
                        </div>
                        <Collapse isOpen={showObjVital}>
                            {objVitalSign && objVitalSign.length > 0
                                ? (
                                    <div className="val-wrapper">
                                        {objVitalSign.map(item => (
                                            <div className="emr-row" key={item.key}>
                                                <div className="label">{item.label}</div>
                                                <div className="value">
                                                    {item.value || '-'}
                                                    <div className="unit">{item.unit}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )
                                : null}
                        </Collapse>
                        <div
                            onClick={() => this.toggleObjPhysical()}
                            className="toggle"
                        >
                            Pemeriksaan Fisik
                            <i className={`fas fa-chevron-${showObjPhysical
                                ? 'up' : 'down'}`} />
                        </div>
                        <Collapse isOpen={showObjPhysical}>
                            {objPhysicalExam && objPhysicalExam.length > 0
                                ? (
                                    <div className="val-wrapper physical">
                                        {objPhysicalExam.map(item => (
                                            <div className="emr-row" key={item.key}>
                                                <div className="label">{item.label}</div>
                                                <div className="value">
                                                    {item.value || '-'}
                                                    <div className="unit">{item.unit}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )
                                : null}
                        </Collapse>
                        <div
                            onClick={() => this.toggleObjBody()}
                            className="toggle"
                        >
                            Pemeriksaan Tubuh
                            <i className={`fas fa-chevron-${showObjBody
                                ? 'up' : 'down'}`} />
                        </div>
                        <Collapse isOpen={showObjBody}>
                            {objBodyExam && objBodyExam.length > 0
                                ? (
                                    <div className="val-wrapper obj-body">
                                        {objBodyExam.map(item => (
                                            <div className="emr-row" key={item.key}>
                                                <div className="label">{item.label}</div>
                                                <div className="value">
                                                    {item.value.note !== ''
                                                        ? <div className="text">{item.value.note}</div>
                                                        : <div className="normal">Normal</div>}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )
                                : null}
                        </Collapse>
                    </div>
                    <div className="attachment">
                        <div className="emr-row">
                            <div className="field">
                                <div className="label">
                                    File Pendukung
                                    {fileSupports && fileSupports.length > 0
                                        ? (
                                            <div
                                                className="download"
                                                onClick={() => this.downloadZip(patientName, fileSupports)}
                                            >
                                                <i className="fas fa-download" />
                                                Download
                                            </div>
                                        )
                                        : null}
                                </div>
                                {fileSupports && fileSupports.length > 0
                                    ? fileSupports.map((item, i) => {
                                        const filename = item.name
                                        const nameArr = filename.split('.')
                                        const ext = nameArr[nameArr.length - 1]

                                        return (
                                            <div key={i} className="file-wrapper">
                                                <div className="title">{`File ${i + 1}`}</div>
                                                <div className="preview">
                                                    <div className="img-wrapper">
                                                        <img
                                                            src={ext === 'pdf'
                                                                ? iconPdf
                                                                : item.url}
                                                            alt={filename}
                                                        />
                                                    </div>
                                                    <div className="right">
                                                        <div className="name">{filename}</div>
                                                        <div className="btn-wrapper">
                                                            <button onClick={() => handleOpenModal('preview', { index: i })}>
                                                                Preview
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : <div className="value">Tidak ada data</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default DetailResumeMedis
