import Api from '../../../../../services/apis/ServicesM'
import CategoryListApi from '../../../../../services/apis/CategoryListApi'
import {
    handleToast,
    handleToastServiceError
} from '../../../../../core/utils/UtilsToast'

const FormHandler = {
    buildForm() {
        this.DropDownKategori = this.DropDownFormView({
            meta: {
                label: "Kategori",
                placeholder: "Pilih Kategori",
            },
            name: "parent_id",
            nameText: "parent_id",
            control: this.state.Form.controls.parent_id,
            stateName: 'categoryListOpt',
        })

        this.nameField = this.TextInputFormView({
            validator: 'required',
            label: 'Nama',
            name: 'name',
            placeholder: 'Nama Kategori Layanan',
            control: this.state.Form.controls.name
        })

        this.descField = this.TextAreaFormView({
            label: 'Deskripsi',
            name: 'description',
            placeholder: 'Deskripsi',
            control: this.state.Form.controls.description
        })

        this.activeField = this.RadioInputFormView({
            name: 'active',
            meta: {
                label: 'Status Layanan',
                options: [
                    {
                        value: 'true',
                        name: 'Aktif',
                    },
                    {
                        value: 'false',
                        name: 'Non Aktif',
                    }
                ]
            },
            control: this.state.Form.controls.active,
        })

        this.positionField = this.TextInputFormView({
            validator: 'required',
            label: `Posisi`,
            name: 'sort_order',
            placeholder: `Posisi`,
            inputType: 'number',
            maxLength: 2,
            max: 99,
            control: this.state.Form.controls.sort_order
        })
    },
    buildValue(data) {
        this.state.Form.patchValue(data)
    },
    handleUpload(file) {
        this.setState({ file })
        this.state.Form.patchValue({ photo: file })
    },
    handleSubmit: async function (e) {
        e.preventDefault();
        this.setState({ loading: true })
        let response = {}
        const { dataEdit } = this.props
        const formValue = Object.assign({}, this.state.Form.value)
        try {
            if (dataEdit) {
                const { file } = this.state
                let parent = formValue.parent_id.value === undefined ? dataEdit.parent_id : String(formValue.parent_id.value)
                const objEdit = {
                    parent_id: parent,
                    name: formValue.name,
                    is_active: formValue.active === 'true',
                    description: formValue.description,
                    photo: formValue.photo,
                    sort_order: formValue.sort_order
                }
                if(file.length <= 0){
                    delete objEdit.photo
                }

                response = await Api.put(dataEdit.id, objEdit)
            } else {
                let parent = formValue.parent_id.value === undefined ? String(0) : String(formValue.parent_id.value)
                response = await Api.post({
                    parent_id: parent,
                    name: formValue.name,
                    is_active: formValue.active === 'true',
                    description: formValue.description,
                    photo: formValue.photo,
                    sort_order: parseInt(formValue.sort_order)
                })
            }

            this.setState({ loading: false })
            handleToast(response.data.message)

            this.props.loadDataAfterAction()
            this.props.onPanelClose()
        } catch (e) {
            this.setState({ loading: false })
            handleToastServiceError(e)
        }
    },
    getCategoryList(){
        let me = this
        if(!this.props.previewMode){
            let requestApi = CategoryListApi.parentService()
            requestApi.then(response => {
                me.setState({
                    categoryListOpt: response.data.data.rows.map(el => {
                        return {
                            value: el.id,
                            label: el.name
                        }
                    }),
                })
            }).catch( error => {
                console.log(error)
            })
        }
    },
    
}

export default FormHandler