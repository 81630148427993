import React from 'react'
import Dropzone from 'react-dropzone'
import { Col, Row } from 'reactstrap';

  const thumb = {
    display: 'block',
    borderRadius: 2,
    margin: '0 auto',
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
    left: 0,
    top: 0,
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    width: 'fit-content',
    margin: '0 auto',
    overflow: 'hidden'
  }

  const img = {
    display: 'block',
    width: '80px',
    height: '80px',
    borderRadius: 40,
    objectFit: 'cover',
  };

export default class ImageUploadForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          files: [],
          placeholder: true,
          previewFromFecth: null,
          dropedImage: false,
        }
      }
    
      onDrop(files) {
        
        this.setState({
          files: files.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
          })),
          previewFromFecth: null,
          placeholder: false,
          dropedImage: true
        })

        files.forEach(file => {
            const reader = new FileReader()
            reader.onload = () => {
                this.props.onUpload(reader.result, file)
            }
            // reader.onabort = () => console.log('file reading was aborted')
            // reader.onerror = () => console.log('file reading has failed')
    
            reader.readAsDataURL(file)
        });
      }
      
    
      componentWillUnmount() {
        // Make sure to revoke the data uris to avoid memory leaks
        this.state.files.forEach(file => URL.revokeObjectURL(file.preview))
      }

      render() {
        const {
          files, 
          placeholder, 
          dropedImage,
        } = this.state

        const {
          icon,
          desc,
          image,
          current,
          label,
          maxSize,
          source,
        } = this.props

        let placeholder1 = <p><img src={icon} alt="" /></p>
        let placeholder2 = <p>{desc}</p>
        const length = image.length
        let fileHolder = []
        let preload = [{
          name: '',
          preview: image
        }]

        fileHolder = (length > 0) ? preload : files

        const thumbs = fileHolder.map(file => (
          <div style={thumb} key={file.name}>
            <div style={thumbInner}>
              <img
                src={file.preview}
                style={img}
                alt=""
              />
            </div>
          </div>
        ))

        
        if(!placeholder || (length > 0) ){
          placeholder1 = ''
          placeholder2 = `Ganti ${source ? source : 'foto NaKes'}`
        }
        
        let previewFromFecth = this.state.previewFromFecth

        if(!dropedImage && current.value){
          previewFromFecth = current.value
          placeholder1 = ''
          placeholder2 = `Ganti ${source ? source : 'foto NaKes'}`
        }
        return (
          <div className="form-upload">
            <div className="form-line">
              <label className="form-control-label" >{ label }</label>
              <div className="file-container">
                <Dropzone
                  accept="image/*"
                  onDrop={this.onDrop.bind(this)}
                  maxSize={maxSize}
                >
                  {({getRootProps, getInputProps}) => (
                    <div className="file-upload" {...getRootProps()}>
                      { 
                        !previewFromFecth ? 
                        thumbs : 
                        <div style={ thumb }>
                          <div style={ thumbInner }>
                            <Row>
                              <Col>
                            <img
                              src={ previewFromFecth }
                              style={img}
                              alt=""
                            />
                            </Col>
                              <Col></Col>
                            </Row>
                          </div>
                        </div>
                      }
                      <input {...getInputProps()} />
                      {placeholder1}
                      {placeholder2}
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
          </div>
        )
      }
}
