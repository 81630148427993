import React, {Component} from 'react'
import iconClose from '../../../themes/v1/img/icon-close.png'
import { FieldGroup, FormBuilder, Validators } from "react-reactive-form"
import FileInputForm from '../../../core/utils/FileInputForm'
import SubmitButtonForm from '../../../core/utils/SubmitButtonForm'
import formHandler from '../handler/BulkUpload'
import iconInfo from '../../../themes/v1/img/info-active.png'
import iconFile from '../../../themes/v1/img/file.png'
import {Progress} from 'reactstrap'

const saveData = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (fileName) {
        const url = 'http://cinnamon.karsalintasbuwana.com:84/documents/FORMAT.zip';
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}())

class BulkUpload extends Component
{
    constructor(props) {
        super(props)

        Object.assign(this, FileInputForm)
        Object.assign(this, formHandler)

        this.handleClose = this.handleClose.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.downloadFormat = this.downloadFormat.bind(this)
        this.handleProgress = this.handleProgress.bind(this)

        this.state = {
            hospitalId: this.props.hospital.id,
            hospitalName: this.props.hospital.name,
            Form: FormBuilder.group({
                id: [""],
                doctor: [""],
                patient: [""],
                doctor_file: [""],
                patient_file: [""],
                valid: ["", Validators.required],
                institution_id: [this.props.hospital.id],
                institution_name: [this.props.hospital.name],
                institution_type: [this.props.hospital.institution_type],
            }),
            labelUploader: 'Unggah',
            doctorLabel: 'Belum ada data',
            patientLabel: 'Belum ada data',
            iconFile: iconFile,
            showIcon: false,
            percentage: 0,
            files: [],
        }

        this.FileInputFormView = this.FileInputFormView.bind(this)        
        this.buildForm()

    }

    downloadFormat(){
        saveData('FORMAT.zip')
    }

    setFormData(formJson){
        const form = new FormData()
        for ( const key in formJson ) {
            if(typeof formJson[key] !== 'undefined'){
                form.append(key, formJson[key])
            }
        }
        return form
    }

    render() {
        const { hospital } = this.props
        const { percentage, files } = this.state
        let description = null
        let varPercentage = percentage
        
        if(percentage > 0){
            description = "Sedang menggunggah '" + files[0] + "' ... (" + percentage +  "%)" 
            if((files.length > 1) && (percentage > 50)){
                description = "Sedang menggunggah '" + files[1] + "' ... (" + percentage +  "%)" 
            }
        }
        
        return(
            <div>

                <img className="form-close" alt="" src={iconClose} onClick={e => this.handleClose(e)} />
                <div className="bulk-upload-form-container">
                    <span className="title">Unggah Data</span>
                    <span className="subtitle">{ hospital.name }</span>
                    <FieldGroup
                            control={this.state.Form}
                            strict={false}
                            render={({get, invalid}) => {
                                return (
                                    <form onSubmit={() => this.handleSubmit} className="form-upload-bulk">
                                        { this.FileDoctor }
                                        { this.FilePatient }
                                        <Progress value={varPercentage}/>
                                        <span className="description">
                                            { description }
                                        </span>
                                        <SubmitButtonForm invalid={invalid}
                                            showCancel={false}
                                            submitText={"Submit"}
                                            onSubmit={(e) => this.handleSubmit(e)}
                                        />
                                    </form>
                                )
                            }}
                        />
                </div>
                <div className="footer-upload">
                    <p className="form-desc">
                        <img className="form-info" alt="" src={iconInfo} />
                        Hanya diperbolehkan mengunggah data .csv sesuai format data yang sudah ditentukan
                        <br/>
                        <span 
                                className="download-format"
                                onClick={e => this.downloadFormat(e)}
                            >Unduh format disini</span>
                    </p>
                </div>
            </div>
        )
    }
}
export default BulkUpload