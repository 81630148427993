import React, { Component } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

class Detail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            idDetail: null,
        }
    }

    componentDidMount() {
        this.setState({ idDetail: null })
    }

    getDetail = (id) => {
        const { onPanelOpenFam } = this.props

        this.setState({ idDetail: id })
        onPanelOpenFam(id)
    }

    render() {
        const { idDetail } = this.state
        const {
            detailPatient: data,
            loading,
        } = this.props
        const { keluarga: dataFamily } = data

        return (
            <div className="m-detail-pasient">
                <h2>Data Diri Sendiri</h2>
                <div className="detail-pasient">
                    <table>
                        <thead>
                            <tr>
                                <th>Nama Lengkap</th>
                                <th>No. Telepon</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="name">{data.nama}</td>
                                <td className="telepon">{data.telepon}</td>
                                <td className="email">
                                    {data.email || '-'}
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        onClick={() => this.getDetail(null, 'pasien')}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <h2>Data Pasien</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Nama Lengkap</th>
                            <th>No. Telepon</th>
                            <th>Hubungan dengan Pasien</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataFamily.length > 0
                                ? dataFamily.map((fams) => {
                                    return (
                                        <React.Fragment key={fams.id}>
                                            <tr>
                                                <td className="name">{fams.nama}</td>
                                                <td className="telepon">{fams.telepon}</td>
                                                <td className="relation">
                                                    {fams.relasi}
                                                    {loading && (idDetail === fams.id)
                                                        ? (
                                                            <div className="loading-grid-row">
                                                                <PulseLoader
                                                                    sizeUnit={"px"}
                                                                    size={4}
                                                                    color={'#426ffe'}
                                                                    loading={loading}
                                                                />
                                                            </div>
                                                        )
                                                        : (
                                                            <FontAwesomeIcon
                                                                icon={faChevronRight}
                                                                onClick={() => this.getDetail(fams.id)}
                                                            />
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            <tr className="spacer" />
                                        </React.Fragment>
                                    )
                                })
                                : (
                                    <tr>
                                        <td colSpan={3} className="text-center">Belum ada data</td>
                                    </tr>
                                )
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Detail
