import React from 'react'
import MasterGrid from '../../../components/grid/MasterGrid'
import GridHandler from '../handler/GridHandler'
import {ContextMenuProvider} from 'react-contexify'
import ReactTable from "react-table"
import checkboxHOC from "react-table/lib/hoc/selectTable"
import MessageBox from '../../../core/utils/UtilsStrapModal'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faPlusCircle, faRedo, faTrash} from '@fortawesome/free-solid-svg-icons'
import {AclHandler} from '../../../core/const/AclConst'
import InputTextFilter from '../../../components/grid/filter/InputTextFilter'
import Config from '../config/UrlConfig'

library.add(faTrash, faPlusCircle, faRedo)

const CheckboxTable = checkboxHOC(ReactTable)

class Grid extends MasterGrid {
    constructor(props) {
        super(props)

        Object.assign(this, MessageBox)
        Object.assign(this, GridHandler)
        Object.assign(this, AclHandler)
        Object.assign(this, InputTextFilter)

        this.keyTable = '_id'
        this.ignoreColumn = [
            '_id'
        ]
        this.selectedTableStatus = true
        this.gridColumn = this.gridColumn.bind(this)
        this.onClickContext = this.onClickContext.bind(this)
        this.dataFromRedux = false
        this.pageSize = 10
        this.page = 0
        this.sorted = []
        this.gridMapping = (this.selectedTableStatus) ? CheckboxTable : ReactTable
        this.handleShow = this.handleShow.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleConfirmModal = this.handleConfirmModal.bind(this)
        this.bulkDeleteHandler = this.bulkDeleteHandler.bind(this)
        this.filterable = true;
        /** own property */
        this.bulkDelete = this.bulkDelete.bind(this)
        this.fetchData = this.fetchData.bind(this)
        this.refreshData = this.refreshData.bind(this)
        this.InputTextFilter = this.InputTextFilter.bind(this)
        this.extraProperties = {
            onFetchData: this.fetchData,
            filterAble: true
        }
        this.can = this.can.bind(this)

        const loading = (this.extraProperties.hasOwnProperty('onFetchData')) ? true : false
        const data = (this.dataFromRedux) ? this.props.data : ((loading) ? [] : this.getData())
        const columns = this.getColumns(data)
        const finalColumn = this.gridColumn(ContextMenuProvider, columns)
        const pages = (loading) ? null : 1
        this.state = {
            data,
            columns: finalColumn,
            selection: [],
            deleted: [],
            selectAll: false,
            loading: loading,
            pages: pages,
            filtered: [],
            onUpdate: false,
            modalShow: false,
            dataProvider: null,
            editTrigger: false,
            filterTrigger: false
        }
    }

    /**
     * Set Bulk Handler
     */
    bulkDeleteHandler() {
        this.setState({modalShow: true, actionModal: 'bulk'})
    }

    render() {
        this.MessageBox = this.MessageBoxShowHide({
            title: "Delete Confirmation",
            body: "Are you sure want to delete this item ?",
        })
        //left menu on grid
        this.leftAction = [
            {
                type: 'buttonLink',
                title: '( + ) Tambah Rumah Sakit', // label
                link: Config.AddUrl
            }
        ]

        return (
            <section className="tables">
                <div className="container-fluid">
                    <div className="row">
                        {this.MessageBox}
                        <div className="col-lg-12 cn-table-container">
                            <div className="card">
                                {super.render()}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Grid