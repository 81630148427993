import React from 'react'
import CountBox from './CountBox'

class SummaryContainer extends React.Component{
    render(){
        return(
            <div className="admin-row">
                <div className="grid-count-box">
                    <div className="box-item">
                        <CountBox 
                            title="Total RS" 
                            date={ this.props.dashboardDate }
                            stat={ this.props.count.hospital }
                        />
                    </div>
                    <div className="box-item">
                        <CountBox 
                            title="Total Klinik" 
                            stat={ this.props.count.clinic }
                            date={ this.props.dashboardDate } 
                        />
                    </div>
                    <div className="box-item">
                        <CountBox 
                            title="Total Dokter" 
                            stat={ this.props.count.doctor }
                            date={ this.props.dashboardDate } 
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default SummaryContainer