import React, { Component } from 'react'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from 'react-reactive-form'

import TextInputForm from '../../../../../core/utils/forminput/TextInputForm'
import Handler from '../../../handler/superadmin/diagnosis/FormHandler'

class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            file: [],
            Form: FormBuilder.group({
                name: ["", Validators.required],
                code: ["", Validators.required],
                description: [""],
            })
        }

        Object.assign(this, TextInputForm)
        this.TextInputFormView = this.TextInputFormView.bind(this)

        // assign to handler
        Object.assign(this, Handler)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.buildValue = this.buildValue.bind(this)

        this.buildForm()
    }

    componentDidMount() {
        const { dataEdit } = this.props

        if (dataEdit) {
            this.buildValue(dataEdit);
        }
    }

    render() {
        const { loading, Form } = this.state
        const { dataEdit } = this.props

        return (
            <FieldGroup control={Form}
                strict={false}
                render={({ invalid }) => {
                    return (
                        <div
                            className="wizard-form custom-form"
                            style={{ display: 'flex', margin: '18px auto 70px' }}
                        >
                            <form onSubmit={this.handleSubmit} autoComplete="off">
                                {this.nameField}
                                {this.codeField}
                                {this.descField}
                                <div className="button-action-slider">
                                    <div className="form-btn-action">
                                        <button
                                            type="submit"
                                            className={
                                                `save itgbtn primary${loading || invalid
                                                    ? " disabled"
                                                    : ""}`
                                            }
                                            disabled={loading || invalid}
                                            onSubmit={this.handleSubmit}
                                        >
                                            {loading
                                                ? 'Loading...'
                                                : dataEdit ? 'Update' : 'Simpan'}
                                        </button>
                                        <button
                                            type="button"
                                            className="itgbtn cancel"
                                            onClick={() => this.props.onPanelClose()}
                                        >
                                            Batal
                                    </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )
                }}
            />
        )
    }
}

export default Form
