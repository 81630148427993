import React from 'react'
import validateReturn from '../ValidatorForm'

import { FieldControl } from "react-reactive-form"

const TextInputPhoneForm = {
    TextInputPhoneFormView: function ({
        label,
        validator,
        name,
        placeholder,
        readonly,
        maxLength,
        control,
        inputType,
        fieldControlAdditional
    }) {
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}

        Object.assign(control, additionalController)

        return <FieldControl
            name={name}
            render={(obj) => this.TextInputPhoneFormRender(obj)}
            meta={
                {
                    label: label,
                    name: name,
                    readonly: readonly,
                    maxLength: maxLength,
                    placeholder: placeholder,
                    validator: validator,
                    inputType: inputType || 'text'
                }
            }
            control={control}
        />
    },
    validateExtra(e, inputType) {
        if (inputType === 'tel') {
            e.target.value = e.target.value.replace(/[^0-9]/g, '')
        }
    },
    TextInputPhoneFormRender: function ({ handler, touched, hasError, meta }) {
        return (
            <div className={
                touched &&
                    (hasError("required") || hasError("minLength") || hasError("maxLength"))
                    ? "form-line has-danger"
                    : "form-line"
            }>
                <label
                    id={`label-${meta.name}`}
                    className="form-control-label"
                    htmlFor={`${meta.label}`}
                >
                    {`${meta.label}`}
                </label>
                <div className="form-group">
                    <input
                        type={meta.inputType}
                        className="form-control form-control-success"
                        id={`${meta.label}`}
                        placeholder={meta.placeholder}
                        readOnly={meta.readonly}
                        maxLength={meta.maxLength}
                        onKeyUp={(e) => this.validateExtra(e, meta.inputType)}
                        {...handler()}
                    />
                </div>
                <span className="error-info">
                    {
                        touched
                        && (
                            hasError("required") ||
                            hasError("minLength") ||
                            hasError("maxLength")
                        )
                        && validateReturn("minMaxPhone")
                    }
                </span>
            </div>
        )
    }
}

export default TextInputPhoneForm
