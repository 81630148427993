import React, {Component} from 'react'
import NumberFormat from 'react-number-format'
import CountUp from 'react-countup'

class CountBox extends Component{
    constructor(props){
        super(props)
        this.displayCount = this.displayCount.bind(this)
    }
    showDelta(number){
        if(number == null){
            return(
                <div className="right">
                    <div className="top">
                        <span className="arrow-wrapper">
                            <span className="arrow-up"></span>
                        </span>
                        <span className="arrow-text">
                            ...
                        </span>
                    </div>
                    <div className="bottom-text">... dari bulan lalu</div>
                </div>
            )
        }
        if(number >= 0){
            return(
                <div className="right">
                    <div className="top">
                        <span className="arrow-wrapper">
                            <span className="arrow-up"></span>
                        </span>
                        <span className="arrow-text">
                            <NumberFormat value={number} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} />
                        </span>
                    </div>
                    <div className="bottom-text">lebih tinggi dari bulan lalu</div>
                </div>
            )
        }else{
            return(
                <div className="right">
                    <div className="top">
                        <span className="arrow-wrapper decrease">
                            <span className="arrow-down"></span>
                        </span>
                        <span className="arrow-text decrease">
                            <NumberFormat value={ Math.abs(number) } displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} />
                        </span>
                    </div>
                    <div className="bottom-text">lebih rendah dari bulan lalu</div>
                </div>
            )
        }
    }

    displayCount(){
        let total = 0
        if(this.props.stat !== null){
            total = this.props.stat.total
            if(total > 0){
                return(
                    <CountUp start={ 0 } end = { total } formattingFn={ (number) => {
                        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
                    }}/>
                )
            }else{
                return(<span>0</span>)
            }
        }

        return(
            <span>0</span>
        )
    }

    render() {
        let delta = null
        if(typeof this.props.stat.delta !== 'undefined'){
            delta = this.props.stat.delta
        }
        return(
            <div className={ 'box-container '}>
                <div className="card-total">
                    <span className="box-title">{this.props.title}</span>
                    <p>
                        {
                            this.props.date ? 
                            this.props.date : '...'
                        }
                    </p>
                    <div className="box-bottom">
                        <div className="left">
                            { this.displayCount() }
                        </div>
                        {this.showDelta(delta)}
                    </div>
                </div>
            </div>
        )
    }
}
export default CountBox