import Icons from '../../../../core/utils/Icons'

const icon = Icons.dashboardMenu

const getMenuDoctor = (role, dataLogin, newCurrentHosp) => {
    const dataHospital = dataLogin.hospitals.find((data) => data.id === newCurrentHosp)

    if (role.id) {
        return {
            quickButton: false,
            menu: [
                {
                    index: 'appointment',
                    path: '/appointment/list',
                    icon: icon.appointment.normal,
                    iconHover: icon.appointment.hover,
                    title: 'Appointment',
                },
                {
                    index: 'doctor',
                    path: `/doctor/detail/${dataHospital.id_doctor_hospital}`,
                    icon: icon.doctor.normal,
                    iconHover: icon.doctor.hover,
                    title: 'Dokter / Tenaga Medis',
                },
            ]
        }
    }
}

export default getMenuDoctor