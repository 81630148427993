import React from 'react'
import ReactTable from 'react-table'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import { ContextMenu, ContextMenuProvider, Item, theme } from 'react-contexify'

import MasterGrid from '../../../components/grid/MasterGrid'
import GridHandler from '../handler/GridHandler'
import SliderPanel from '../../../components/wizard/SliderPanel'
import MasterModal from '../../../core/utils/MasterModal'
import Checkbox from '../../dashboard/components/Checkbox'
import Form from './Form'

const CheckboxTable = checkboxHOC(ReactTable)

class Grid extends MasterGrid {
    constructor(props) {
        super(props)

        Object.assign(this, GridHandler)

        this.clickable = false
        this.filterable = false
        this.selectedTableStatus = false
        this.gridColumn = this.gridColumn.bind(this)
        this.gridMapping = (this.selectedTableStatus) ? CheckboxTable : ReactTable
        this.hidePagination = false
        this.page = 0
        this.pageSize = 20
        this.selectedMax = 10
        this.selected = []
        this.filtered = []
        this.sorted = []
        this.defaultSorted = []
        this.keyTable = '_id'
        this.ignoreColumn = [
            '_id'
        ]

        /* own property */
        this.fetchData = this.fetchData.bind(this)
        this.extraProperties = {
            onFetchData: this.fetchData,
            filterAble: true
        }

        /* data */
        const loading = this.extraProperties.hasOwnProperty('onFetchData') ? true : false
        const data = this.dataFromRedux ? this.props.data : (loading ? [] : this.getData())
        const columns = this.getColumns(data)
        const finalColumn = this.gridColumn(ContextMenuProvider, columns)
        const pages = loading ? null : 1

        this.onPanelOpen = this.onPanelOpen.bind(this)
        this.onPanelClose = this.onPanelClose.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.modalHandleSubmit = this.modalHandleSubmit.bind(this)
        this.loadDataAfterAction = this.loadDataAfterAction.bind(this)
        this.getFilter = this.getFilter.bind(this)
        this.handleClickMenu = this.handleClickMenu.bind(this)

        this.state = {
            loading: loading,
            data,
            columns: finalColumn,
            pages: pages,
            rows: 1,
            selection: [],
            deleted: [],
            filtered: [],
            headerFilter: {},
            filterList: {},
            rangeOpen: false,
            disableHighlight: true,
            selectAll: false,
            isPanelOpen: false,
            panelData: {},
            isModalOpen: false,
            modalData: {},
            servicesOpt: [],
            checkedConfirm: false,
        }

        this.currentHospital = this.props.currentHospital
    }

    componentWillReceiveProps(nextProps) {
        const { currentHospital } = this.props

        if (currentHospital !== nextProps.currentHospital) {
            this.currentHospital = nextProps.currentHospital

            this.fetchData({
                page: 0,
                pageSize: this.pageSize,
                filtered: [],
                sorted: []
            })
        }
    }

    componentDidMount() {
        this.getFilter()
    }

    render() {
        const {
            isPanelOpen,
            panelData,
            isModalOpen,
            modalData,
            checkedConfirm,
            headerFilter,
            filterList,
            filtered,
            selection,
            lastRounded,
            rangeOpen,
            loading,
        } = this.state

        const { dataLogin } = this.props
        const filterListArr = Object.keys(filterList)
        this.selected = selection
        this.leftAction = []
        if (filterListArr.length > 0) {
            filterListArr.forEach((key, index) => {
                let roundedPos = 'middle'
                const selectDefault = filterList[key].options.filter(s => {
                    return (s.value !== '') && (s.value === filterList[key].default)
                })

                if (filterListArr.length === 1) {
                    roundedPos = 'single'
                } else if (index === 0) {
                    roundedPos = 'left'
                } else if ((filterListArr.length - 1) === index) {
                    roundedPos = lastRounded ? 'middle' : 'right'
                }

                this.leftAction.push({
                    actionType: key,
                    type: filterList[key].type,
                    title: filterList[key].placeholder,
                    tooltip: filterList[key].tooltip,
                    filterKeyword: headerFilter[key] ? headerFilter[key].value : filterList[key].default,
                    handlerSubmit: this.handleFilter,
                    selectedOpt: headerFilter[key] || selectDefault,
                    filterOpt: filterList[key].options,
                    path: filterList[key].path,
                    handlerChangeOpt: this.handleFilter,
                    handlerChange: this.handleFilter,
                    className: `rounded ${roundedPos}`,
                })
            })
        }

        const getDateORRange = filtered.find(f => this.customFilter.includes(f.value))
        if (getDateORRange) {
            let roundedPos = 'middle'
            const indexDateORRange = filterListArr.indexOf(getDateORRange.id) + 1

            if (filterListArr.length === indexDateORRange) {
                roundedPos = 'right'
            }

            if (getDateORRange.value === 'DATE_RANGE') {
                this.leftAction.splice(indexDateORRange, 0, {
                    type: 'range',
                    title: 'Date',
                    isOpen: rangeOpen,
                    actionType: `${getDateORRange.id}_range`,
                    filterKeyword: headerFilter[`${getDateORRange.id}_range`],
                    handlerChange: this.handleFilter,
                    className: `rounded ${roundedPos}`,
                })
            } else {
                this.leftAction.splice(indexDateORRange, 0, {
                    type: 'date',
                    title: 'Date',
                    actionType: `${getDateORRange.id}_date`,
                    filterKeyword: headerFilter[`${getDateORRange.id}_date`],
                    handlerChange: this.handleFilter,
                    className: `rounded ${roundedPos}`,
                })
            }
        }

        this.leftAction.push({
            type: 'reset',
            loading: loading,
            tooltip: 'Reset Filter',
            handlerReset: this.handleFilterReset,
        })

        const DefaultSnippetMenu = (data) => (
            <ContextMenu
                id='menu-action'
                className="menu-custom custom-snippet-menu"
                theme={theme.light}
                style={{
                    minWidth: '150px',
                }}
            >
                <Item onClick={(data) => this.handleClickMenu('schedule', data.dataFromProvider)}>
                    Edit Jadwal
                </Item>
                <Item onClick={(data) => this.handleClickMenu('edit', data.dataFromProvider)}>
                    Edit Informasi
                </Item>
            </ContextMenu>
        )

        return (
            <section className="tables with-top-filter no-paging-top">
                {
                    dataLogin.user_type === 'a' 
                    ?
                     ''
                    :
                    <button
                        className="add"
                        onClick={() => this.onPanelOpen()}
                    >
                        Tambah
                    </button>
                }
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 cn-table-container wrap-text">
                            <div className="card">
                                {super.render()}
                            </div>
                        </div>
                    </div>
                </div>
                <SliderPanel
                    open={isPanelOpen}
                    panelData={panelData}
                    onPanelClose={this.onPanelClose}
                >
                    <Form
                        {...this.props}
                        data={panelData}
                        onPanelClose={this.onPanelClose}
                        loadDataAfterAction={this.loadDataAfterAction}
                    />
                </SliderPanel>
                <MasterModal
                    className="master-modal"
                    isOpen={isModalOpen}
                    handleClose={this.handleCloseModal}
                    ModalData={modalData}
                >
                    <div className="master-modal-content emr">
                        <div className="master-modal-head">
                            Konfirmasi Penghapusan
                        </div>
                        <div className="master-modal-body">
                            <div className="content">
                                Layanan yang Anda pilih (<strong>{modalData ? modalData.name : ''}</strong>) akan dihapus.
                                Apakah Anda yakin?
                                <Checkbox
                                    options={[{
                                        value: true,
                                        label: 'Saya telah konfirmasi',
                                    }]}
                                    prefix="emrConfirm"
                                    onCheck={() => this.setState({ checkedConfirm: true })}
                                    onRemove={() => this.setState({ checkedConfirm: false })}
                                    items={[checkedConfirm]}
                                    class="wrap-checkbox-left emr-confirm"
                                />
                            </div>
                        </div>
                        <div className="master-modal-foot">
                            <button
                                className={`master-btn confirm${!checkedConfirm ? ' disabled' : ''}`}
                                disabled={!checkedConfirm}
                                onClick={() => this.modalHandleSubmit(modalData)}
                            >
                                Lanjutkan
                            </button>
                            <button
                                className="master-btn cancel"
                                onClick={this.handleCloseModal}
                            >
                                Batalkan
                            </button>
                        </div>
                    </div>
                </MasterModal>
                <DefaultSnippetMenu />
            </section>
        )
    }
}

export default Grid