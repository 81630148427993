import axios from './MasterConfig'
const groupUrl = "doctorspecialistmap";

class DsmapApi {
    static get = (page, pageSize, sortProperties, sortValue) => axios.get(`/${groupUrl}/list?page=${page}&sort=${sortProperties}&order=${sortValue}&limit=${pageSize}`)
    static post = (data) => axios.post(`/${groupUrl}/create`, data)
    static delete = (id) => axios.delete(`/${groupUrl}/delete/${id}`)
    static update = (id, data) => axios.put(`/${groupUrl}/update/${id}`, data)
    static bulkDelete = (data) => axios.post(`/${groupUrl}/bulkdelete`, data)
    static listSpecialist = () => axios.get(`/${groupUrl}/specialist`)
    static listHospital = () => axios.get(`/${groupUrl}/hospital`)
    static listDoctor = () => axios.get(`/${groupUrl}/doctor`)
}
export default DsmapApi;