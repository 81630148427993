import React from 'react'
import Chance from 'chance'
import dateFns from 'date-fns'
import qs from 'query-string'
import IndonesianLocale from 'date-fns/locale/id'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import { isDenied } from '../../../core/utils/UtilsPermission'
import Api from '../../../services/apis/Appointment/List'
import {
    handleToast,
    handleToastServiceError
} from '../../../core/utils/UtilsToast'
import RoleHelper from '../../../core/helper/RoleHelper'

const chance = new Chance()
const GridHandler = {
    parseTimeNew(row) {
        let newData = row.order_date.split(",")
        let tanggalCustom = newData[1]
        let tanggal = tanggalCustom.split("/")
        let tgl = tanggal[0].replace (" ", "")
        let result = tanggal[2]+"-"+tanggal[1]+"-"+tgl
        let jam = newData[2].substring(0,6);
        return (
            <div>
                {dateFns.format(new Date(result), 'dddd', { locale: IndonesianLocale })}, {dateFns.format(new Date(result), 'DD/MM/YYYY', { locale: IndonesianLocale })} <br />
                {jam} WIB
            </div>)
    },
    gridColumn() {
        return [
            {
                Header: "Booking",
                accessor: "ref_no",
                filterable: false,
                minWidth: 170,
                Cell: (row) => {
                    let type = row.original.book_type_label
                    return (
                        <div
                            className="grid-link row-float"
                            onClick={() => this.handleOpenSlide(row.original)}
                        >
                            <strong>{row.original.ref_no}</strong>
                            <p>{type ? type : '-'}</p>
                        </div>
                    )
                }
            },
            {
                Header: () => {
                    return (
                        <div className="row-float">
                            {'Tanggal Transaksi'}
                        </div>
                    )
                },
                accessor: 'order_date',
                sortable: true,
                wiidth: 150,
                headerClassName: '-sort',
                Cell: (row) => {
                    return (<div className="row-book-date">{this.parseTimeNew(row.original)}</div>)
                },
            },
            {
                Header: () => {
                    return (
                        <div className="row-float">
                            {'Tanggal Booking'}
                        </div>
                    )
                },
                accessor: 'booking_schedule',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return (<div className="row-book-date">{this.parseTime(row.original)}</div>)
                },
            },
            {
                Header:() => {
                    return (
                        <div className="row-float">
                            {'Dokter / Layanan'}
                        </div>
                    )
                },
                accessor: "name",
                filterable: false,
                Cell: (row) => {
                    return (<div className="row-float">{row.original.name || '-'}</div>)
                }
            },
            {
                Header: "Nama Pasien",
                accessor: "patient_name",
                filterable: false,
                minWidth: 110,
                Cell: (row) => {
                    return (
                        <div className="row-float">
                            {row.original.patient_name || '-'}
                        </div>
                    )
                }
            },
            {
                Header:  () => {
                    return (
                        <div className="row-float">
                            {'Voucher / Diskon'}
                        </div>
                    )
                },
                accessor: 'voucher',
                maxWidth: 130,
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    let voucher = row.original.voucher
                    let diskon = this.toRp(row.original.discount)
                    return (
                        <div>
                            <strong>{voucher ? voucher : '-'}</strong>
                            <p>{diskon ? diskon : '-'}</p>
                        </div>
                    )
                },
            },
            {
                Header: 'Pembayaran',
                accessor: 'payment_method',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    let price = this.toRp(row.original.payment_price)
                    return (
                        <div>
                            <strong>{row.original.payment_method}</strong>
                            <p>{price ? price : '-'}</p>
                        </div>)
                },
            },
            {
                Header: 'Status',
                accessor: "status_code",
                Cell: (row) => {
                    let badgeStatus = 'badge-primary'

                    if (row.original.status_code === 'canceled') {
                        badgeStatus = 'badge-danger'
                    } else if (row.original.status_code === 'unconfirmed') {
                        badgeStatus = 'badge-success'
                    }

                    return (
                        <div className="badge-label">
                            <span className={`badge badge-pill ${badgeStatus}`}>
                                <div className="row-float">
                                {row.original.status_label}
                                </div>
                            </span>
                        </div>
                    )
                }
            },
            {
                Header: 'Action',
                sortable: false,
                width: 170,
                Cell: row => {
                    const { currentHospital, dataLogin } = this.props
                    const {
                        id,
                        status_code: statusCode,
                        book_next: bookNext,
                    } = row.original
                    let actEMR = null
                    let actConfirm = null
                    let actVaksin = null
                    let btnConfirm = null

                    if(row.original && row?.original?.action?.length !== 0 ){
                        let act = row.original;
                        if(act.video_call === true){
                            actConfirm = (null)
                        } else {
                            if(act.status_code === "cancel by doctor" || act.status_code === "cancel by operational" ){
                                actConfirm = (
                                    <button
                                        onClick={() => this.handleOpenSlide(row.original)}
                                        className={`appt-action`}
                                    >
                                        {act ? act.action.label : '-'}
                                    </button>
                                )
                            }else if( act.status_code === "cancel by thirdparty"){
                                actConfirm = (
                                    <button
                                        onClick={() => this.handleOpenSlide(row.original)}
                                        className={`appt-action`}
                                        disabled
                                        style={{display: 'none'}}
                                    >
                                        {act ? act.action.label : '-'}
                                    </button>
                                )

                            }else{
                                actConfirm = (
                                    <button
                                        onClick={() => this.modalHandleConfirm(act)}
                                        disabled={false}
                                        // className={`appt-action${!isHariH ? ' disabled' : ''}`}
                                        className={`appt-action`}
    
                                    >
                                        {act ? act.action.label : '-'}
                                    </button>
                                )
    
                            }
                        }
                        
                    }

                    if (process.env.REACT_APP_HOSPITAL_MEDICAL_RECORD === 'true' &&
                        statusCode === 'ended') {
                        if (!isDenied(currentHospital, dataLogin, 'medical_record')) {
                            actEMR = (
                                <button
                                    onClick={() => this.redirectEMR(row.original)}
                                    className="appt-action half emr"
                                >
                                    Buat EMR
                                </button>
                            )
                        }
                    } else if (statusCode === 'unconfirmed') {
                        actConfirm = (
                            <button
                                onClick={() => this.modalHandleConfirm(id)}
                                disabled={false}
                                // disabled={!isHariH}
                                // className={`appt-action${!isHariH ? ' disabled' : ''}`}
                                className={`appt-action`}

                            >
                                {btnConfirm ? btnConfirm.label : '-'}
                            </button>
                        )
                    }

                    if (this.isSuperadmin && bookNext) {
                        actVaksin = (
                            <button
                                className="appt-action half"
                                onClick={() => this.rebookEmail(id)}
                            >
                                Book Ulang
                            </button>
                        )
                    }

                    return (
                        <div className="action-wrapper">
                            {actEMR}
                            {actConfirm}
                            {actVaksin}
                        </div>
                    )
                }
            },
            {
                sortable: false,
                width: 50,
                className: 'text-center',
                filterable: false,
                filterType: "reset",
                Cell: row => (
                    <button
                        className="action-row"
                        onClick={() => this.handleOpenSlide(row.original)}
                    >
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </button>
                )
            },
        ]
    },
    isDoctor() {
        return RoleHelper.isDoctor(this.props.dataLogin, this.props.currentHospital)
    },
    parseDate: function (row) {
        const dateFormat = 'dddd, D MMM YYYY'
        let strDate = ''
        let timeStart = ''
        let timeEnd = ''
        if (row.book_date) {
            strDate = dateFns.format(row.book_date, dateFormat, { locale: IndonesianLocale })
        }

        if (row.schedule_time_start) {
            timeStart = `${row.schedule_time_start.slice(0, -3)}`
        }

        if (row.practice_schedule) {
            timeEnd = ` - ${row.practice_schedule.split(' - ')[1].slice(0, -3)} WIB`
        }

        return (
            <div>
                {strDate}
                <br />
                {timeStart} {timeEnd}
            </div>)
    },
    parseTime(row) {
        return (
            <div>
                {dateFns.format(new Date(row.book_date), 'dddd', { locale: IndonesianLocale })}, {dateFns.format(new Date(row.book_date), 'DD/MM/YYYY', { locale: IndonesianLocale })} <br />
                {row.arrival_time} WIB
            </div>)
    },
    handleOpenSlide(data) {
        Api.detail(data.id, data.id_hospital).then(res => {
            const {
                data: {
                    status,
                    data,
                    message,
                }
            } = res

            if (status) {
                this.setState({
                    isPaneOpen: true,
                    openedAppointment: data
                })
            } else {
                handleToastServiceError({ message })
            }
        }, err => {
            handleToastServiceError(err)
        })
    },
    onPanelClose() {
        const { history, location } = this.props
        const params = qs.parse(location.search)

        if (params.id) {
            setTimeout(() => {
                history.push('/appointment/list')
            }, 300);
        }

        this.showNotif = false
        this.setState({
            isPaneOpen: false,
            openedAppointment: null
        })
    },
    handleClick: function (action, theRow) {
        switch (action) {
            case 'delete':
                const ModalData = {
                    action: "delete_patient",
                    id: theRow.id,
                    content: "Anda akan menghapus Pasien ini. <br />Apakah Anda Yakin?",
                    buttonConfirmText: "Ya, Hapus Data",
                    buttonCancelText: "Batalkan",
                    ConfirmFunction: null
                }
                this.handleOpenModal(ModalData)
                break
            case 'edit':
                this.setState({ dataProvider: theRow, editTrigger: true })
                this.updateStatusState()
                break
            default:
                return null
        }
    },
    fetchData: async function (state) {
        let fArray = {}
        const { isDoctor } = this.props

        state.filtered.map(val =>
            fArray[val.id] = val.value
        )

        const filterData = JSON.stringify(fArray)

        this.setState({ filterTrigger: false })
        this.pageSize = state.pageSize
        this.page = state.page
        this.setState({ loading: true })
        let sort_property = ''
        let sort_value = ''
        let res
        const IdHospital = this.currentHospital

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'DESC' : 'ASC'
            this.sorted = state.sorted
        }

        try {
            res = await Api.list(
                state.page + 1,
                state.pageSize,
                sort_property,
                sort_value,
                filterData,
                IdHospital,
                isDoctor,
            )
        } catch (e) {
            this.setState({
                loading: false
            })
            handleToastServiceError(e)
            return
        }

        if (React.isValidElement(res)) {
            return
        }

        if (res.data.code === 200) {
            const newRows = res.data.data.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })

            this.setState({
                data: newRows,
                pages: res.data.data.total_page,
                rows: res.data.data.total_rows,
                disableHighlight: this.state.disableHighlight
            })
        }

        this.setState({
            loading: false
        })
    },

    refreshData() {
        this.fetchData({
            page: this.page,
            pageSize: this.pageSize,
            filtered: [],
            sorted: this.sorted,
        })
    },
    handleOpenModal(ModalData) {
        this.setState({
            isModalOpen: true,
            ModalData: ModalData,
        })
    },
    handleCloseModal() {
        this.setState({
            isModalOpen: false,
            ModalData: null
        })
    },
    modalHandleConfirm(data) {
        const labels = data?.action?.label.toLowerCase();
        const konfirmasiText = `Konfirmasi kedatangan pasien <b>${data.patient_name}</b>. <br />Apakah anda yakin?`;
        const sudahDatangText = `Mulai sesi dengan pasien <b>${data.patient_name}</b> ?`;
        const selesaiText = `Akhiri sesi dengan pasien <b>${data.patient_name}</b> ?`;
        const ModalData = {
            action: "confirm_booking",
            data:data,
            content: labels === 'konfirmasi' ? konfirmasiText : labels === 'sudah datang' ? sudahDatangText : selesaiText,
            buttonConfirmText: "Ya",
            buttonCancelText: "Tidak",
            ConfirmFunction: null
        }
        this.handleOpenModal(ModalData)
    },

    modalHandleSubmit(data) {
        let me = this
        const ModalData = me.state.ModalData

        if (ModalData.action === 'confirm_booking') {
            Api.confirmNew(ModalData.data.action.endpoint).then(function (response) {
                let state = {
                    pageSize: me.pageSize,
                    page: me.page,
                    sorted: me.sorted,
                    filtered: me.state.filtered,
                }
                me.fetchData(state, null)
                me.handleCloseModal()
                me.onPanelClose()
                handleToast(response.data.message)
            }, function (error) {
                me.handleCloseModal()
                me.onPanelClose()
                handleToast(error.message)
            })
        } else if (ModalData.action === 'cancel_booking') {
            Api.cancel(ModalData.id, data).then(function (response) {
                let state = {
                    pageSize: me.pageSize,
                    page: me.page,
                    sorted: me.sorted,
                    filtered: me.state.filtered
                }
                me.fetchData(state, null)
                me.handleCloseModal()
                me.onPanelClose()
                handleToast(response.data.message)
            }, function (error) {
                me.handleCloseModal()
                me.onPanelClose()
                handleToast(error.message)
            })
        }
    },
    redirectEMR: function (p) {
        const patientId = p.patient_id || ''
        const bookDate = dateFns.format(p.book_date, 'DD/MM/YYYY')
        const aTime = p.arrival_time.substring(0, 5)
        const patient = {
            arrival_time: aTime,
            book_id: p.id,
            book_code: p.ref_no,
            book_date: bookDate,
            label: `${p.patient_name} - ${bookDate} - ${aTime}`,
            patient_id: patientId,
            patient_medical_record_no: p.medical_record_no,
            patient_name: p.patient_name,
            value: `${patientId}_${bookDate}_${aTime}`,
        }
        const doctor = {
            source: 'appointment',
            doctor_id: p.id_doctor,
            doctor_name: `${p.doctor_title} ${p.doctor_name}`,
            label: `${p.doctor_title} ${p.doctor_name}`,
        }
        this.props.setPatientEMR(patient)
        this.props.setDoctorEMR(doctor)
        this.props.history.push('/emr/create')
    },
    sendEmail: async function () {
        let res = {}
        let id = ''
        const ids = []
        const { currentHospital } = this.props
        this.setState({ loadingE: true })

        const { selection, data } = this.state
        selection.forEach(item => {
            const getDdata = data.find(d => d._id === item)
            ids.push(getDdata.id)
        })
        id = ids.join(',')

        try {
            res = await Api.email(currentHospital, id)
            this.setState({ loadingE: false })
        } catch (error) {
            this.setState({ loadingE: false })

            const { data: dataRes } = error.response
            const message = dataRes
                ? dataRes.code === 500
                    ? 'Internal Server Error server error'
                    : Object.keys(dataRes.data).map(k => dataRes.data[k]).join('\n')
                : 'Network error'

            handleToastServiceError({ message })
            return
        }

        const {
            status,
            message,
        } = res.data

        if (status) {
            handleToast(message)
            this.toggleAll('deselect-all')

            this.fetchData({
                page: this.page,
                pageSize: this.pageSize,
                sorted: this.sorted,
                filtered: this.state.filtered,
            })
        } else {
            handleToastServiceError({ message })
        }
    },
    rebookEmail: async function (id) {
        let res = {}
        const { currentHospital } = this.props
        this.setState({ loading: true })

        try {
            res = await Api.emailRebook(currentHospital, id)
            this.setState({ loading: false })
        } catch (error) {
            this.setState({ loading: false })

            const { data: dataRes } = error.response
            const message = dataRes
                ? dataRes.code === 500
                    ? 'Internal Server Error server error'
                    : Object.keys(dataRes.data).map(k => dataRes.data[k]).join('\n')
                : 'Network error'

            handleToastServiceError({ message })
            return
        }

        const {
            status,
            message,
        } = res.data

        if (status) {
            handleToast(message)

            this.fetchData({
                page: this.page,
                pageSize: this.pageSize,
                sorted: this.sorted,
                filtered: this.state.filtered,
            })
        } else {
            handleToastServiceError({ message })
        }
    },
    getNotifHandle: async function () {
        let res = {}
        const { currentHospital } = this.props

        try {
            res = await Api.notification(currentHospital)
        } catch (error) {
            handleToastServiceError(error)
            return
        }

        const {
            status,
            message,
            data,
        } = res.data

        if (status) {
            this.props.setNotifAppointment(data)
        } else {
            handleToastServiceError({ message })
        }
    },
    ModalSearchBankAccount (dataSearchBankAccount){
        const me = this
        const payload = dataSearchBankAccount
        Api.searchBankAccount(payload).then(function(response){
            me.setState({
                nama_bankAccount : response.data.data.account_name
            })
        })
    },
    ModalHandleRefund(dataRefund){
        let me = this
        const ModalData = me.state.ModalData
        const payload = {
                refund_bank_id: dataRefund.selectedBank.value,
                refund_bank_name:  dataRefund.selectedBank.label,
                refund_account_no: dataRefund.no_rekening,
                refund_account_name: me.state.nama_bankAccount,
                refund_amount: dataRefund.price,
                refund_note: dataRefund.keterangan_refund
            }
        Api.confirmNew(ModalData.content.action.endpoint, payload).then(function (response) {
            let state = {
                pageSize: me.pageSize,
                page: me.page,
                sorted: me.sorted,
                filtered: me.state.filtered,
            }
            me.fetchData(state, null)
            me.handleCloseModal()
            me.onPanelClose()
            handleToast(response.data.message)
        }, function (error) {
            me.handleCloseModal()
            me.onPanelClose()
            handleToast(error.message)
        })
    },
}

export default GridHandler
