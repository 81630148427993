/* eslint-disable */

import React from 'react'
import ReactTable from 'react-table'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import { isEmpty } from 'lodash'
import { ContextMenuProvider } from 'react-contexify'
import { Carousel, CarouselItem } from 'reactstrap';

import MasterGrid from '../../../components/grid/MasterGrid'
import InputTextFilter from '../../../components/grid/filter/InputTextFilter'
import SliderPanel from '../../../components/wizard/SliderPanel'
import MessageBox from '../../../core/utils/UtilsStrapModal'
import { AclHandler } from '../../../core/const/AclConst'
import MasterModal from '../../../core/utils/MasterModal'
import Icon from '../../../core/utils/Icons'
import DetailPatient from '../../emr/components/DetailPatient'
import DetailResumeMedis from '../../emr/components/DetailResumeMedis'
import iconPdf from '../../../themes/v1/img/icon-pdf.png'
import Config from '../config/UrlConfig'
import GridHandler from '../handler/GridHandler'

const CheckboxTable = checkboxHOC(ReactTable)

class Grid extends MasterGrid {
    constructor(props) {
        super(props)

        Object.assign(this, MessageBox)
        Object.assign(this, GridHandler)
        Object.assign(this, AclHandler)
        Object.assign(this, InputTextFilter)

        this.aclModule = 'Patient'
        this.createUrl = Config.AddUrl

        this.keyTable = '_id'
        this.ignoreColumn = [
            '_id'
        ]
        this.selectedTableStatus = true
        this.gridColumn = this.gridColumn.bind(this)
        this.dataFromRedux = false
        this.pageSize = 20
        this.page = 0
        this.sorted = []
        this.gridMapping = (this.selectedTableStatus) ? CheckboxTable : ReactTable
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.bulkDeleteHandler = this.bulkDeleteHandler.bind(this)
        this.filterable = false;

        /** own property */
        this.bulkDelete = this.bulkDelete.bind(this)
        this.fetchData = this.fetchData.bind(this)
        this.refreshData = this.refreshData.bind(this)
        this.InputTextFilter = this.InputTextFilter.bind(this)
        this.extraProperties = {
            onFetchData: this.fetchData,
            filterAble: true
        }
        this.can = this.can.bind(this)

        const loading = this.extraProperties.hasOwnProperty('onFetchData') ? true : false
        const data = this.dataFromRedux ? this.props.data : (loading ? [] : this.getData())
        const columns = this.getColumns(data)
        const finalColumn = this.gridColumn(ContextMenuProvider, columns)
        const pages = loading ? null : 1
        this.customFilter = ['DATE', 'DATE_RANGE']

        this.viewSlidePatient = this.viewSlidePatient.bind(this)
        this.onPanelClose = this.onPanelClose.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.modalHandleSubmit = this.modalHandleSubmit.bind(this)
        this.bruteBulkDownloadTrigger = this.bruteBulkDownloadTrigger.bind(this)
        this.clearBruteDownload = this.clearBruteDownload.bind(this)
        this.onPanelMROpen = this.onPanelMROpen.bind(this)
        this.onPanelMRClose = this.onPanelMRClose.bind(this)
        this.downloadFile = this.downloadFile.bind(this)
        this.getFilterList = this.getFilterList.bind(this)

        this.state = {
            data,
            columns: finalColumn,
            selection: [],
            deleted: [],
            selectAll: false,
            loading: loading,
            pages: pages,
            filtered: [],
            onUpdate: false,
            modalShow: false,
            dataProvider: null,
            editTrigger: false,
            filterTrigger: false,
            isPaneOpen: false,
            isModalOpen: false,
            disableHighlight: true,
            ModalData: null,
            bulkBruteDownload: false,
            panelDataPatient: {},
            patientRecord: [],
            isPanelMROpen: false,
            panelDataMR: {},
            activeIndex: 0,
            animating: false,
            rangeOpen: false,
            filterList: {},
            headerFilter: {},
        }

        this.currentHospital = this.props.currentHospital
    }

    componentDidMount() {
        this.getFilterList()
    }

    componentWillReceiveProps(nextProps) {
        const { currentHospital } = this.props

        if (currentHospital !== nextProps.currentHospital) {
            this.currentHospital = nextProps.currentHospital

            this.fetchData({
                page: 0,
                pageSize: this.pageSize,
                filtered: [],
                sorted: []
            })
        }
    }

    tableHeader(isBruteDownload) {
        return (
            <div className="tableHeader">
                <div className="wrapper-option">
                    <span className="action-header" title="Download data terpilih" onClick={(e) => {
                        this.downloadHandler()
                    }}>
                        <img src={Icon.down} alt="download" />
                    </span>
                    <span className="action-header" title="Hapus data terpilih" onClick={(e) => {
                        this.bulkDeleteHandler()
                    }}>
                        <img src={Icon.TrashBin} alt="remove" />
                    </span>
                    {
                        !isBruteDownload ?
                            <div className="info-selected">
                                <span>{this.state.selection.length} Data dari halaman ini terpilih</span>
                                {/* <a onClick={this.bruteBulkDownloadTrigger}>
                                Pilih semua {this.state.rows} data
                            </a> */}
                            </div> :
                            <div className="info-selected">
                                <span>Semua {this.state.rows} Data  terpilih</span>
                                <a onClick={this.clearBruteDownload}>Batalkan Pilihan</a>
                            </div>
                    }
                </div>
            </div>
        )
    }

    parseModal() {
        const { panelDataMR, activeIndex } = this.state

        if (!isEmpty(panelDataMR)) {
            const { file_supports: fileSupports } = panelDataMR
            const item = fileSupports[activeIndex]
            const fileName = item.name

            return (
                <div className="modal-preview">
                    <div className="info">
                        <div className="filename">{fileName}</div>
                        <div className="action">
                            <button onClick={() => this.downloadFile(item.url, fileName)} className="download">
                                <i className="fas fa-download" />
                            </button>
                            <div onClick={() => this.handleCloseModal()} className="close-pre">
                                <i className="fas fa-times" />
                            </div>
                        </div>
                        <div style={{ clear: 'both' }} />
                    </div>
                    <ol className="carousel-indicators-pre">
                        {this.indicators()}
                    </ol>
                    <Carousel
                        activeIndex={activeIndex}
                        next={this.next}
                        previous={this.previous}
                        interval={false}
                    >
                        {this.slides()}
                        <div>
                            <div
                                className="carousel-control carousel-control-prev"
                                onClick={this.previous}
                            >
                                <div className="arrow">
                                    <i className="fas fa-chevron-left" />
                                </div>
                            </div>
                            <div
                                className="carousel-control carousel-control-next"
                                onClick={this.next}
                            >
                                <div className="arrow">
                                    <i className="fas fa-chevron-right" />
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
            )
        }

    }

    indicators = () => {
        const { panelDataMR, activeIndex } = this.state

        if (!isEmpty(panelDataMR)) {
            const { file_supports: fileSupports } = panelDataMR

            return fileSupports.map((item, i) => {
                const filename = item.name
                const nameArr = filename.split('.')
                const ext = nameArr[nameArr.length - 1]

                return (
                    <li
                        key={i}
                        className={activeIndex === i ? 'active' : ''}
                        onClick={() => this.goToIndex(i)}
                    >
                        <div className="img-wrapper">
                            <img
                                src={ext === 'pdf'
                                    ? iconPdf
                                    : item.url}
                                alt={filename}
                            />
                        </div>
                    </li>
                );
            })
        }

        return null
    }

    slides = () => {
        const { panelDataMR } = this.state

        if (!isEmpty(panelDataMR)) {
            const { file_supports: fileSupports } = panelDataMR

            return fileSupports.map((item, i) => {
                const filename = item.name
                const nameArr = filename.split('.')
                const ext = nameArr[nameArr.length - 1]

                return (
                    <CarouselItem key={i}>
                        <div className="item-img d-flex justify-content-center">
                            {ext === 'pdf'
                                ? <embed src={`${item.url}#toolbar=0&navpanes=0&scrollbar=0`} />
                                : <img src={item.url} alt={filename} />}
                        </div>
                    </CarouselItem>
                );
            })
        }

        return null
    }

    next = () => {
        const { animating, activeIndex, panelDataMR } = this.state

        if (!isEmpty(panelDataMR)) {
            const { file_supports: fileSupports } = panelDataMR
            if (animating) return;
            const nextIndex = activeIndex === fileSupports.length - 1 ? 0 : activeIndex + 1;
            this.setState({ activeIndex: nextIndex })
        }
    }

    previous = () => {
        const { animating, activeIndex, panelDataMR } = this.state

        if (!isEmpty(panelDataMR)) {
            const { file_supports: fileSupports } = panelDataMR
            if (animating) return;
            const nextIndex = activeIndex === 0 ? fileSupports.length - 1 : activeIndex - 1;
            this.setState({ activeIndex: nextIndex })
        }
    }

    goToIndex = (newIndex) => {
        const { animating } = this.state
        if (animating) return;
        this.setState({ activeIndex: newIndex })
    }

    render() {
        const {
            loading,
            selection,
            filterList,
            headerFilter,
            isPanelMROpen,
            panelDataPatient,
            patientRecord,
            panelDataMR,
            bulkBruteDownload,
            ModalData,
        } = this.state
        const tableTop = (selection.length > 0) ? this.tableHeader(bulkBruteDownload) : ''
        const filterListArr = Object.keys(filterList)

        this.MessageBox = this.MessageBoxShowHide({
            title: "Delete Confirmation",
            body: "Are you sure want to delete this item ?",
        })

        this.leftAction = []
        if (filterListArr.length > 0) {
            filterListArr.forEach((key, index) => {
                let roundedPos = 'middle'
                const selectDefault = filterList[key].options.filter(s => {
                    return (s.value !== '') && (s.value === filterList[key].default)
                })

                if (index === 0) {
                    roundedPos = 'left'
                } else if ((filterListArr.length - 1) === index) {
                    roundedPos = 'right'
                }

                this.leftAction.push({
                    actionType: key,
                    type: filterList[key].type,
                    title: filterList[key].placeholder,
                    tooltip: filterList[key].tooltip,
                    filterKeyword: headerFilter[key] ? headerFilter[key].value : filterList[key].default,
                    handlerSubmit: this.handleFilter,
                    selectedOpt: headerFilter[key] || selectDefault,
                    filterOpt: filterList[key].options,
                    path: filterList[key].path,
                    handlerChangeOpt: this.handleFilter,
                    handlerChange: this.handleFilter,
                    className: `rounded ${roundedPos}`,
                })
            })
        }

        this.leftAction.push({
            type: 'reset',
            loading: loading,
            tooltip: 'Reset Filter',
            handlerReset: this.handleFilterReset,
        })

        return (
            <section className="tables with-top-filter no-paging-top">
                {tableTop}
                <div className="container-fluid">
                    <div className="row">
                        {this.MessageBox}
                        <div className="col-lg-12 cn-table-container">
                            <div className="card">
                                {super.render()}
                            </div>
                        </div>
                    </div>
                </div>
                <SliderPanel
                    open={this.state.isPaneOpen}
                    onPanelClose={this.onPanelClose}
                    panelData={panelDataPatient}
                    {...this.props}
                >
                    <DetailPatient
                        {...panelDataPatient}
                        patientRecord={patientRecord}
                        onPanelMROpen={this.onPanelMROpen}
                    />
                </SliderPanel>
                <SliderPanel
                    {...this.props}
                    width="75%"
                    open={isPanelMROpen}
                    onPanelClose={this.onPanelMRClose}
                    onHandleBack={this.onPanelMRClose}
                    panelData={panelDataMR}
                    customOverlay="slider-cinnamon-overlay-two"
                >
                    <DetailResumeMedis
                        {...panelDataMR}
                        handleOpenModal={this.handleOpenModal}
                    />
                </SliderPanel>
                <MasterModal
                    className="master-modal"
                    isOpen={this.state.isModalOpen}
                    handleClose={this.handleCloseModal}
                    handleSubmit={this.modalHandleSubmit}
                    ModalData={ModalData && ModalData.action === 'preview'
                        ? null
                        : this.state.ModalData}
                >
                    {ModalData && ModalData.action === 'preview'
                        ? this.parseModal()
                        : null}
                </MasterModal>
            </section>
        )
    }
}

export default Grid