
import React, { Component } from 'react'
import CheckBoxForm from '../../../../core/utils/forminput/CheckBoxForm'

class PolySlider extends Component{
    constructor(props){
        super(props)

        Object.assign(this, CheckBoxForm)
        this.CheckBoxFormView = this.CheckBoxFormView.bind(this)
        this.state = {
            polyOpt: this.props.polyOpt
        }
    }

    render(){
        const { polyOpt } = this.props
        if(polyOpt.length <= 0){
            return <div>Loading</div>
        }
        return(
            <div className="poly-list">
                <div className="intro">
                    Pilih akses Poliklinik yang tersedia di klinik anda. Anda dapat memilih lebih dari satu. Pilih ‘Simpan’ setelah selesai menandai
                </div>
                <div className="checkboxes">
                    {
                       this.CheckBoxFormView({
                            name: "polyOpt",            
                            meta: {
                                validator: "",
                                options: this.props.polyOpt
                            },
                            control: this.props.control
                        })
                    }
                </div>
                <button className="btn regular-button" onClick={ this.props.onPanelClose }>Selesai</button>
            </div>
        )
    }
}

export default PolySlider