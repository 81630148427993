import Api from '../../../../../../services/apis/FacilityApi'
import {
    handleToast,
    handleToastServiceError
} from '../../../../../../core/utils/UtilsToast'

const FormHandler = {
    buildForm() {
        this.nameField = this.TextInputFormView({
            validator: "required",
            label: "Nama Fasilitas",
            name: "name",
            placeholder: "Nama Fasilitas",
            control: this.state.Form.controls.name
        })

        this.descField = this.TextAreaFormView({
            label: "Deskripsi",
            name: "description",
            placeholder: "Deskripsi",
            control: this.state.Form.controls.description
        })

        this.onlyClinic = this.SwitchFormView({
            meta: {
                label: "Hanya untuk Klinik",
            },
            name: "only_clinic",
            control: this.state.Form.controls.only_clinic
        })
    },
    buildValue(data) {
        this.state.Form.patchValue(data)
    },
    handleUpload(file) {
        this.setState({ file })
        this.state.Form.patchValue({ icon: file })
    },
    handleSubmit: async function (e) {
        e.preventDefault();

        let response = {}
        const { dataEdit } = this.props
        const formValue = Object.assign({}, this.state.Form.value)

        try {
            if (dataEdit) {
                response = await Api.put(dataEdit.id, formValue)
            } else {
                response = await Api.post(formValue)
            }

            handleToast(response.data.message)

            this.props.loadDataAfterAction()
            this.props.onPanelClose()
        } catch (e) {
            handleToastServiceError(e)
        }
    }
}

export default FormHandler
