import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

class WizardBreadCrumb extends Component
{
    render() {
        const breadcrumbData = this.props.breadcrumbData;
        if(breadcrumbData){
            return (
                <ul className="wizard-breadcrumb">
                   {
                       breadcrumbData.map((item, key) => (
                        <li key={ key } >
                            <NavLink onClick={ item.onClick } to={ item.path } className={ item.finished ? "finished" : "" }>
                                <span className="number">{ item.finished ? '✔' : item.no }</span>
                                <span className="step">{ item.label }</span>
                            </NavLink>
                        </li>  
                       ))
                   }
                </ul>
            )
        }else{
            return(<span>&nbsp;</span>)
        }
    }
}

export default WizardBreadCrumb