import React, { Component } from 'react'

class MedicalRecord extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { patientRecord, onPanelMROpen, accessBy } = this.props
        return (
            <div className="emr-medical-record">
                <div className="emr-header">
                    <div className="label date">
                        Tanggal
                    </div>
                    <div className="label diagnosa">
                        Diagnosa
                    </div>
                </div>
                {patientRecord && patientRecord.length > 0
                    ? patientRecord.map(item => (
                        <div
                            key={item.id}
                            className="emr-body"
                            onClick={() => onPanelMROpen(item.id, accessBy)}
                        >
                            <div className="val date">
                                {item.emr_date}
                            </div>
                            <div className="val diagnosa">
                                <div>{item.diagnosa.join(', ')}</div>
                                <button type="button">
                                    <i className="fas fa-chevron-right" />
                                </button>
                            </div>
                        </div>
                    )) : (
                        <div className="emr-body">
                            <div className="val">
                                Belum ada resume medis sebelumnya
                            </div>
                        </div>
                    )}
            </div>
        )
    }
}

export default MedicalRecord
