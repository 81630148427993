import React from 'react'
const InputTextFilter = {
    InputTextFilter: function({ filter, onChange}) {
        return (
            <input type="text"
                    onChange={event => {
                        onChange(event.target.value)
                    }}
                    onKeyDown={event => {
                        if (event.keyCode === 13) {
                            this.setState({filterTrigger: true})
                            onChange(event.target.value)
                        }
                    }}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                />
        )
    }
}

export default InputTextFilter