import React from 'react'
import ReactTooltip from 'react-tooltip'

class Search extends React.Component {
    constructor(props) {
        super(props)
        this.elementData = this.props.element
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.state = {
            keyword: this.elementData.filterKeyword
                ? this.elementData.filterKeyword
                : "",
        }
    }

    handleChangeInput(e) {
        this.setState({
            keyword: e.target.value
        })
    }

    handleSubmit(e) {
        e.preventDefault()
        const elementData = this.elementData
        const keyword = this.state.keyword
        elementData.handlerSubmit(keyword)
    }

    render() {
        const elementData = this.elementData
        const className = (this.props.hideField)
            ? "search-keyword"
            : "search-byfilter-keyword"
        return (
            <div className="wrap-search-filter">
                <ReactTooltip className="cinnamon-tooltip custom-action" effect="solid" />
                <form onSubmit={this.handleSubmit} autoComplete="off">
                    <input
                        name="filter-input-text"
                        className={`${className} ${elementData.className || ''}`}
                        onChange={this.handleChangeInput}
                        placeholder={elementData.title}
                        value={this.state.keyword}
                        type="text"
                        data-tip={elementData.tooltip}
                    />
                    <button className="button-search-byfilter">
                        <i className="fas fa-search" />
                    </button>
                </form>
            </div>
        )
    }
}

export default Search
