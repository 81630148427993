import React from 'react'
import validateReturn from '../../ValidatorForm'
import { FieldControl } from "react-reactive-form";
import Select from 'react-select';

const DropDown = {
    DropDownView: function({name, nameText, stateName, meta, control, fieldControlAdditional}) {
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}
        Object.assign(control, additionalController);
        meta.name = name
        meta.nameText = nameText
        const callBack = meta.callBack ? meta.callBack : null
        return <FieldControl
            name = {name}
            strict = {false}
            meta = {{...meta}}
            render={(obj) => this.DropDownRender(obj, stateName, name, nameText, callBack)}
            control = {control}
        />
    },
    onChangeEvent: function (e, onChangeEvent, name, callBack) {
        onChangeEvent(e)
        if(callBack){
            callBack(this, name)
        }
    },
    DropDownRender: function( {handler, touched, hasError, meta}, stateName, name, nameText, callback) {
        const SelectedVal = this.state.Form.controls[name] ? this.state.Form.controls[name].value : null
        const TextSelected = this.state.Form.controls[nameText] && this.state.Form.controls[nameText] !== '' ? this.state.Form.controls[nameText].value : null
        const options = this.state[stateName]
        const isEdit = this.state.isEdit
        const editChanged = this.state.editChanged
        
        if(meta.forceDisabled){
            const getVal = options.find(option => option.label === SelectedVal)
            const setVal = SelectedVal ? getVal : null
            return(
                <div className={touched && hasError(meta.validator) ? "form-line has-danger" : "form-line"}>
                    <div className="form-group-wizard">
                        <Select
                            className="dropdown-wizard"
                            classNamePrefix="react-select"
                            name={name}
                            options={options}
                            isDisabled={true}
                            placeholder={meta.label}
                            value={setVal}
                        />
                    </div>
                </div>
            )
        }else if(isEdit && !editChanged && meta.EditshowOnlyText){
            return(
                <div className={touched && hasError(meta.validator) ? "form-line has-danger" : "form-line"}>
                    <div className="form-group">
                        <input className="form-control dropdown-text" type={ "text" } readOnly defaultValue={ TextSelected }/>
                    </div>
                </div>
            )
        }else{
            const getVal = options.find(option => option.label === SelectedVal)
            const setVal = SelectedVal ? getVal : null
            return (
                <div className={touched && hasError(meta.validator) ? "form-line has-danger" : "form-line"}>
                    <div className="form-group-wizard">
                        <Select
                            className="dropdown-wizard"
                            classNamePrefix="react-select"
                            name={name}
                            options={options}
                            placeholder={meta.label}
                            {...handler()}
                            onChange={(e) => this.onChangeEvent(e, handler().onChange, name, callback)}
                            value={setVal}
                        />
                    </div>
                    <span className="error-info">
                        {
                            touched
                            && hasError(meta.validator)
                            && validateReturn(meta.validator)
                        }
                    </span>
                </div>
            )
        }
    },
    handleFocus(event) {
        if(event.target.value === '' && !event.target.parentNode.classList.contains('focused')){
            event.target.parentNode.classList.toggle('focused')
        }
    }
}
export default DropDown