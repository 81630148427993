import React from 'react'
import validateReturn from './ValidatorForm'
import ReactQuill from 'react-quill';

import {FieldControl} from "react-reactive-form"

const RichEditorInputForm = {
    RichEditorInputFormView: function({label, validator, name, control, inputType, fieldControlAdditional}) {
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}        
        
        Object.assign(control, additionalController)

        return <FieldControl
            name={name}
            render={(obj) => {
                return this.RichEditorInputFormRender(obj, this.handleFocus)
            }}
            meta={{ label: label, name: name, validator: validator, inputType: inputType || 'text' }}
            control = { control }
        />
    },
    RichEditorInputFormRender: function(props, handleFocus) {
        const { handler, touched, hasError, meta, value, setValue } = props
        const newHandler = handler()

        Object.assign(newHandler, {
            onFocus: handleFocus,
        })

        return (
            <div onBlur={this.handleBlur} className={touched && hasError(meta.validator) ? "form-line has-danger" : "form-line"}>
                <label id={`label-${meta.name}`} className="form-control-label" htmlFor={`${meta.label}`}>
                    {`${meta.label}`}
                </label>
                <div className="form-group editor-wrap">
                    <ReactQuill formats={[
                        'bold',
                        'code',
                        'font',
                        'link',
                        'code-block',
                        'italic', 
                        'underline', 
                        'list', 
                        'header', 
                        'text-alignment', 
                        'blockquote', 
                        'indent', 
                        'strike',
                        'script'
                    ]} modules={{
                        toolbar: [
                            [{ 'header': [1, 2, false] }],
                            ['bold', 'italic', 'underline','strike', 'blockquote'],
                            [{'list': 'ordered'}, {'list': 'bullet'}],
                          ]
                    }}
                    value={value}
                    onChange={html => {
                        setValue(html)
                    }}
                    />
                </div>
                <span className="error-info">
                    {
                        touched
                        && hasError(meta.validator)
                        && validateReturn(meta.validator)
                    }
                </span>
            </div>
        )
    },

    handleFocus(event) {
        if(event.target.value === '' && !event.target.parentNode.classList.contains('focused')){
            event.target.parentNode.classList.toggle('focused')
        }
    },
    handleBlur(event){
        if(event.target.value === '' && event.target.parentNode.classList.contains('focused')){
            event.target.parentNode.classList.toggle('focused')
        }
    }
}

export default RichEditorInputForm
