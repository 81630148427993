import React, { Component } from 'react'
import {
    FormBuilder,
    FormControl,
    Validators
} from "react-reactive-form"
import TextInputForm from '../../../../core/utils/wizard/TextInputForm'
import TextInputEmailForm from '../../../../core/utils/wizard/TextInputEmailForm'
import TextAreaForm from '../../../../core/utils/wizard/TextAreaForm'
import DropDownForm from '../../../../core/utils/wizard/DropDownForm'
import DatePickerForm from '../../../../core/utils/wizard/DatePickerForm'
import RadioInputForm from '../../../../core/utils/wizard/RadioInputForm'
import CheckBoxForm from '../../../../core/utils/wizard/CheckBoxForm'
import FormPreviewHandler from '../../handler/FormPreviewHandler'
import ArrowDown from '../../../../themes/v1/img/layout/icon/ico-arrow-down.png'
import Config from '../../config/UrlConfig'

class FormPreview extends Component
{
    constructor(props) {
        super(props)

        Object.assign(this, TextInputForm)
        Object.assign(this, TextInputEmailForm)
        Object.assign(this, TextAreaForm)
        Object.assign(this, DropDownForm)
        Object.assign(this, DatePickerForm)
        Object.assign(this, RadioInputForm)
        Object.assign(this, CheckBoxForm)

        this.redirectUrl = Config.GridUrl
    
        this.state = {
            toggleMoreField: false,
            isEdit: false,
            editChanged: false,
            postStatus: false,
            Form: FormBuilder.group({
                id: [""],
                firstname: ["", Validators.required],
                lastname: ["", Validators.required],
                gender: ["M", Validators.required],
                email: [
                    "", [Validators.required, Validators.email]
                ],
                phone: ["", Validators.required],
                pob: ["", Validators.required],
                identity_type: ["", Validators.required],
                identity_no: ["", Validators.required],
                street: ["", Validators.required],
                dob: ["", Validators.required],
                province_id: ["", Validators.required],
                province: [""],
                city_id: ["", Validators.required],
                city: [""],
                kecamatan_id: ["", Validators.required],
                kecamatan: [""],
                kelurahan_id: ["", Validators.required],
                kelurahan: [""],
                kodepos: ["", Validators.required],
                pic_name: ["", null],
                pic_email: ["", Validators.email],
                pic_phone: ["", null],
                pic_relationship_desc: ["", null]
            }),
            idTypeOpt: [
                {value: "KTP", label: "KTP"},
                {value: "SIM", label: "SIM"},
                {value: "Passport", label: "Passport"}
            ],
            propinsiOpt: [],
            kotaOpt: [],
            kecamatanOpt: [],
            kelurahanOpt: [],
        }

        this.TextInputFormView = this.TextInputFormView.bind(this)
        this.TextInputEmailFormView = this.TextInputEmailFormView.bind(this)
        this.TextAreaFormView = this.TextAreaFormView.bind(this)
        this.DropDownFormView = this.DropDownFormView.bind(this)
        this.DatePickerFormView = this.DatePickerFormView.bind(this)
        this.RadioInputFormView = this.RadioInputFormView.bind(this)
        this.CheckBoxFormView = this.CheckBoxFormView.bind(this)
        Object.assign(this, FormPreviewHandler)

        this.handleSubmit = this.handleSubmit.bind(this)
        this.valueBuilder = this.valueBuilder.bind(this)

        this.renderCustomFieldContainer = this.renderCustomFieldContainer.bind(this)
        this.getCustomField = this.getCustomField.bind(this)
        this.buildForm()
    }

    onToggleMore(){
        this.setState({
            toggleMoreField: !this.state.toggleMoreField
        })
    }

    getCustomField(obj){
        switch(obj.fieldKey){
            case 'input_text_free' : 
                this.state.Form.addControl(obj.fieldLabel, new FormControl('', false))
                return (
                    this.TextInputFormView({
                        label: obj.fieldLabel,
                        name: obj.fieldLabel,
                        readonly: true,
                        control: this.state.Form.controls[obj.fieldLabel],
                    })
                )
            case 'text_area' : 
                this.state.Form.addControl(obj.fieldLabel, new FormControl('', false))
                return(
                    this.TextAreaFormView({
                        label: obj.fieldLabel,
                        name: obj.fieldLabel,
                        readonly:true,
                        control: this.state.Form.controls[obj.fieldLabel]
                    })
                )
            case 'input_text_numeric' :
                this.state.Form.addControl(obj.fieldLabel, new FormControl('', false))
                return (
                    this.TextInputFormView({
                        label: obj.fieldLabel,
                        name: obj.fieldLabel,
                        readonly: true,
                        control: this.state.Form.controls[obj.fieldLabel],
                    })
                )
            case 'calendar' :
                this.state.Form.addControl(obj.fieldLabel, new FormControl('', false))
                return  (
                    this.DatePickerFormView({
                        meta: {
                            name: obj.fieldLabel,
                            label: obj.fieldLabel,
                            readonly: false,
                        },
                        name: obj.fieldLabel,
                        control: this.state.Form.controls[obj.fieldLabel]
                    })
                )
            case 'radio' :
                this.state.Form.addControl(obj.fieldLabel, new FormControl(obj.option[0].value, false))
                return(
                    this.RadioInputFormView({
                        name: obj.fieldLabel,            
                        meta: {
                            label: obj.fieldLabel,
                            readonly:false,
                            options: obj.option
                        },
                        control: this.state.Form.controls[obj.fieldLabel]
                    })
                )
            case 'checkbox' :
                this.state.Form.addControl(obj.fieldLabel, new FormControl([], false))
                return(
                    this.CheckBoxFormView({
                        name: obj.fieldLabel,            
                        meta: {
                            label: obj.fieldLabel,
                            validator: "",
                            options: obj.option
                        },
                        control: this.state.Form.controls[obj.fieldLabel]
                    })
                )
            case 'dropdown' :
                this.state.Form.addControl(obj.fieldLabel, new FormControl([], false))
                return(
                    this.DropDownFormView({
                        meta: {
                            label: obj.fieldLabel,
                            validator: "",
                            options: obj.option.map(opt => {
                                return {
                                    value: opt.value,
                                    label: opt.name
                                }
                            })
                        },
                        name: obj.fieldLabel,
                        nameText: obj.fieldLabel,
                        control: this.state.Form.controls[obj.fieldLabel],
                    })
                )
            default : 
                return null
        }
    }

    renderCustomFieldContainer(){
        const builderData = this.props.builderData
        const formData = this.props.formData
        return(<div className="wizard-preview">
            <h3>{ formData.title }</h3>
            {
                builderData.map((obj, key) => {
                    return(
                        <div key={key}>
                            { this.getCustomField(obj) }
                        </div>
                    )
                })
            }
        </div>)
    }

    render(){
        return(
            <div className="wizard-form">
                <h3>
                    Informasi Pasien
                </h3>
                {/** start form */}
                <form onSubmit={() => this.handleSubmit} className="form-horizontal">
                    {this.TextFirstName}
                    {this.TextLastName}
                    {this.TextBirthPlace}
                    {this.DatePickDob}
                    {this.RadioGender}
                    {this.TextPhone}
                    {this.TextEmail}
                    {this.TextAddress}
                    {this.DropDownPropinsi}
                    {this.DropDownKota}
                    {this.DropDownKecamatan}
                    {this.DropDownKelurahan}
                    {this.DropDownTypeId}
                    {this.TextIdNo}
                    {
                        this.renderCustomFieldContainer()
                    }
                    <div className={ this.state.toggleMoreField ? "wrap-additional show-field" : "wrap-additional hide-field"}>
                        <div id="toggle-button" onClick={ this.onToggleMore.bind(this) }>
                            Informasi Tambahan 
                            <img className="icon" src={ ArrowDown } alt="icon arrow down"/>
                        </div>
                        <div className="wrap-input-additional">
                            <h3>Informasi Penanggung Jawab (Optional)</h3>
                            <div>
                                { this.TextPICName }
                                { this.TextPICPhone }
                                { this.TextPICEmail }
                                { this.RadioPICRelationship }
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default FormPreview