import React from 'react'
import FreeText from './FieldTypes/FreeText'
import TextNumeric from './FieldTypes/TextNumeric'
import Calendar from './FieldTypes/Calendar'
import Radio from './FieldTypes/Radio'
import CheckBox from './FieldTypes/CheckBox'
import TextArea from './FieldTypes/TextArea'
import Dropdown from './FieldTypes/Dropdown'

class FieldBox extends React.Component {
    constructor(props){
        super(props)
        this.handleDelete = this.handleDelete.bind(this)
        this.renderComponentInput = this.renderComponentInput.bind(this)
    }
    handleDelete(ev){
        ev.preventDefault()
        const field = this.props.fields

        const ModalData = {
            action: "delete_custom_field",
            id: field.id,
            content: "Anda akan menghapus field ini. Apakah anda yakin?",
            buttonConfirmText: "Ya, Hapus Field",
            buttonCancelText: "Batalkan"
        }

        this.props.handleOpenModal(ModalData)
    }

    renderComponentInput(field){
        switch(field.fieldKey) {
            case 'input_text_free' :
                return <FreeText {...this.props} handleDelete={this.handleDelete}/>
            case 'input_text_numeric' :
                return <TextNumeric {...this.props} handleDelete={this.handleDelete}/>
            case 'calendar' :
                return <Calendar {...this.props} handleDelete={this.handleDelete}/>
            case 'radio' :
                return <Radio {...this.props} handleDelete={this.handleDelete}/>
            case 'checkbox' :
                return <CheckBox {...this.props} handleDelete={this.handleDelete}/>
            case 'dropdown' :
                return <Dropdown {...this.props} handleDelete={this.handleDelete}/>
            case 'text_area' :
                return <TextArea {...this.props} handleDelete={this.handleDelete}/>
            default:
                return <FreeText {...this.props} handleDelete={this.handleDelete}/>
        }
    }

    render(){
        const field = this.props.fields
        return(
            <div className="field-box">
                {
                    this.renderComponentInput(field)
                }
            </div>
        )
    }
}

export default FieldBox