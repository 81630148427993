const Config = {
    cancelUrl: "/dashboard",
    baseUrl: "/appointment",
    appointmentScheduleUrl: "/appointment/schedule",
    assignUrl: "/appointment/assign",
    resultUrl: "/appointment/result",
    finishAppointmentRedirect: "/appointment/list",
    successAppointmentMessage: "Antrian Berhasil Dibuat"
}

export default Config