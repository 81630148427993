import React from 'react'
import Chance from 'chance'
import dateFns from 'date-fns'
import IndonesianLocale from 'date-fns/locale/id'

import DaftarLayananApi from '../../../../../services/apis/DaftarLayanan'
import { handleToast, handleToastServiceError } from '../../../../../core/utils/UtilsToast'

const chance = new Chance()

const GridHandler = {
    onPanelClose() {
        this.setState({
            isPaneOpen: false,
            dataEdit: null,
            reasonSelected: '',
        })
    },
    onPanelOpen(id) {
        if (id) {
            this.getDetail(id)
        } else { }
    },
    handleOpenModal(modalData) {
        this.setState({
            isModalOpen: true,
            modalData,
            forceActive: 'false',
        })
    },
    handleCloseModal(isSubmit) {
        this.setState({
            isModalOpen: false,
            modalData: {},
            forceActive: isSubmit === true
                ? 'false'
                : 'true',
        })
        this.state.Form.reset()
    },

    parseTime(row) {
        return (
            <div>
                {dateFns.format(new Date(row.book_date), 'dddd', { locale: IndonesianLocale })}, {dateFns.format(new Date(row.book_date), 'DD/MM/YYYY', { locale: IndonesianLocale })} <br />
                {row.arrival_time} WIB
            </div>)
    },

    toRp(number) {
        if (number) {
            return `Rp ${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
        }

        return 'Rp 0 (Gratis)'
    },

    gridColumn(ContextMenuProvider) {
        return [
            {
                Header: 'Nama',
                accessor: 'name',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return <strong>{row.original.name || '-'}</strong>
                },
            },
            {
                Header: 'Kategori',
                accessor: 'master_service_name',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return (
                        <div className="custom-layanan">
                            <span className="font-size-11 color-gray">{row.original.master_service_name ? row.original.master_service_name.toUpperCase() : '-'}</span>
                            <p>
                                {row.original.category_service_name}
                            </p>
                        </div>
                    )
                },
            },
            {
                Header: 'FasKes',
                accessor: 'hospital_name',
                sortable: true,
                maxWidth: 240,
                headerClassName: '-sort',
                Cell: (row) => {
                    return (
                        <div>
                            {row.original.hospital_name || '-'}
                            <p>{row.original.hospital_type_name || '-'}</p>
                        </div>
                    )
                }
            },
            {
                Header: 'Kota',
                accessor: 'kota',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return row.original.kota || '-'
                },
            },
            {
                Header: 'Harga',
                accessor: 'price',
                sortable: true,
                headerClassName: '-sort right-th',
                className: "right-td",
                Cell: (row) => {
                    return (
                        <div>
                            {this.toRp(row.original.price) || '-'}
                        </div>
                    )
                },
            },
            {
                Header: 'Upload KTP',
                accessor: 'is_upload_idcard',
                maxWidth: 100,
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    const label = row.original.is_upload_idcard
                    const badgeStyle = row.original.is_upload_idcard === true
                        ? 'badge-success'
                        : 'badge-danger'

                    return (
                        <div className="badge-label" >
                            <span className={`badge badge-pill ${badgeStyle}`} >
                                {label === true ? 'Ya' : 'Tidak'}
                            </span>
                        </div>
                    )
                },
            },
            {
                Header: 'Status',
                accessor: 'is_active',
                maxWidth: 100,
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    const label = row.original.is_active
                    const badgeStyle = row.original.is_active === true
                        ? 'badge-primary'
                        : 'badge-danger'

                    return (
                        <div className="badge-label" >
                            <span className={`badge badge-pill ${badgeStyle}`} >
                                {label === true ? 'Aktif' : 'Non Aktif'}
                            </span>
                        </div>
                    )
                },
            },
        ]
    },
    fetchData: async function (state) {
        this.setState({ loading: true })
        this.page = state.page
        this.pageSize = state.pageSize

        let res = {}
        let fArray = {}
        let sort_property = ''
        let sort_value = ''

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'DESC' : 'ASC'
            this.sorted = state.sorted
        }

        if (state.filtered && state.filtered.length > 0) {
            state.filtered.map(val => {
                if (val.value !== '') {
                    fArray[val.id] = val.value
                }
                return true
            })
        }

        const filterData = JSON.stringify(fArray)

        try {
            res = await DaftarLayananApi.list(
                this.page + 1,
                this.pageSize,
                sort_property,
                sort_value,
                filterData,
            )
        } catch (e) {
            this.setState({ loading: false })

            handleToastServiceError(e)
            return
        }

        const {
            status,
            data: resData,
        } = res

        if (status === 200) {
            const newRows = resData.data.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })

            this.setState({
                loading: false,
                data: newRows,
                pages: resData.data.total_page,
                rows: resData.data.total_rows,
            })
        } else {
            this.setState({ loading: false })
        }
    },
    getFilter: async function () {
        try {
            const response = await DaftarLayananApi.getFilter()
            const { data } = response.data
            const filterList = data

            this.setState({ filterList })
        } catch (e) {
            handleToastServiceError(e)
        }
    },
    getDetail(id) {
        this.setState({ loading: true })
    },
    sendActivation: async function ({ hospital_id: hospitalId, user_type: userType, email }) {
        let response = {}

        try {
            const { status, data } = response

            if (status === 200) {
                handleToast(data.message)
                this.handleCloseModal()
            }
        } catch (error) {
            handleToastServiceError(error)
            this.handleCloseModal()
        }
    },
}

export default GridHandler
