import React, { Component } from 'react'
import ScheduleList from './ScheduleList'
import Form from './Form'

export default class SlideContent extends Component{
    constructor(props){
        super(props)
        this.state = {
            openForm: false,
            isEdit: false,
            editId: null,
            responseMessage: ""
        }
        this.toggleForm = this.toggleForm.bind(this)
    }

    renderSchedule(){
        if(this.state.openForm){
            return(<Form {...this.props} toggleForm={this.toggleForm} editId={this.state.editId} isEdit={this.state.isEdit}/>)
        }else{
            return(<ScheduleList {...this.props} openForm={this.state.openForm} toggleForm={this.toggleForm}/>)
        }
    }

    toggleForm(id){
        this.setState({
            openForm: !this.state.openForm,
            editId: !isNaN(id) ? id : null,
            isEdit: !isNaN(id) ? true : false
        })
    }

    render() {
        return (
            <div className="slider-form">
                {this.renderSchedule()}
            </div>
        )
    }
}