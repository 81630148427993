import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import connect from 'react-redux/es/connect/connect'

import DashboardTemplate from '../../template/DashboardTemplate'
import Form from './components/superadmin/setting/fix/Form'
import { loginMapStateToProps } from '../../core/const/LoginConst'

class DashboardFixAccount extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        let isAdmin = false
        const {
            isLoggin,
            dataLogin,
            title,
        } = this.props

        if (isLoggin === true) {
            isAdmin = dataLogin.superadmin
        }

        if (!isAdmin) {
            return (
                <Redirect to="/" />
            )
        }

        return (
            <DashboardTemplate {...this.props} >
                <div className="dashboard-container wrapper-facility">
                    <div className="container-fluid">
                        <div className="title-wrapper">
                            <h2 className="no-margin-top page-title" style={{ marginLeft: '-2px' }}>
                                {title}
                            </h2>
                        </div>
                        <Form />
                    </div>
                </div>
            </DashboardTemplate>
        )
    }
}

export default connect(loginMapStateToProps, null)(DashboardFixAccount)
