import React from 'react'
import {NavLink} from 'react-router-dom'

/** ----------------- Start --------------------- **/
export default class ActionGrid extends React.Component {

    constructor(props) {
        super(props)
        this.toggleTableAction = this.toggleTableAction.bind(this)
        this.state = {
            dropdownTableActionOpen: false,
        }
    }

    toggleTableAction() {
        this.setState(prevState => ({
            dropdownTableActionOpen: !prevState.dropdownTableActionOpen
        }))
    }

    render() {
        /** HANDLE LEFT MENU **/
        let leftAction = null;
        if(this.props.leftAction !== undefined){
            const button = this.props.leftAction[0]
            leftAction = (
                <div>
                    <NavLink to={button.link}>
                        {button.title}
                    </NavLink>
                </div>
            )
        } 
        return (
            <div className="left-action">
                {leftAction}
            </div>
        )
    }
}