import React from 'react'
import ButtonConfirmSchedule from '../../components/buttons/ButtonConfirmSchedule'

class PatientDetail extends React.Component{
    handleConfirm(id){
        const ModalData = {
            action: "confirm_appointment",
            id: id,
            content: "Konfirmasi kedatangan Pasien ini. <br /> Apakah Anda Yakin",
            buttonConfirmText: "Konfirmasi",
            buttonCancelText: "Kembali"
        }
        this.props.openModal(ModalData)
    }

    handleCancel(id){
        const ModalData = {
            action: "cancel_appointment",
            id: id,
            content: "Apakah anda yakin ingin membatalkan appointment pasien ? <br />Pasien akan terhapus dari daftar booking",
            buttonConfirmText: "Ya, Batalkan",
            buttonCancelText: "Tidak, jangan dibatalkan"
        }
        this.props.openModal(ModalData)
    }

    render(){
        const patient = this.props.chosenPatient
        return (
            <div className="patient-detail">
                <div className="patient-list">
                    <div className="patient-row big">
                        <div className="patient-caption number">No. Urut</div>
                        <div className="patient-caption data">Pasien</div>
                    </div>
                    <div className="patient-row big">
                        <div className="patient-item big-number">{ Number(patient.book_no) < 10 ? '0' + patient.book_no : patient.book_no }</div>
                        <div className="patient-item big-data">{ patient.name }</div>
                    </div>
                </div>
                <div className="patient-information">
                    <div className="row">
                        <span className="label-info">Telepon</span>
                        <span className="content-info">08129156236</span>
                    </div>
                    <div className="row">
                        <span className="label-info">Alamat</span>
                        <span className="content-info">Jl. Testing Doang no 25 Petukangan Selatan, Jakarta Selatan 12270</span>
                    </div>
                    <div className="row">
                        <span className="label-info">No. Pasien</span>
                        <span className="content-info">32221351</span>
                    </div>
                </div>
                <ButtonConfirmSchedule 
                    { ...this.props }
                    patient={ patient } 
                    handleConfirm={ this.handleConfirm } 
                    handleCancel={ this.handleCancel } 
                />
            </div>
        )
    }
}

export default PatientDetail