import React, { Component } from 'react'
import dateFns from "date-fns"
import IndonesianLocale from 'date-fns/locale/id'
import ProfilePic from '../../../../../components/lib/ProfilePic'

class Detail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            doctorImgNotFound: false,}
    }

    
    parseTime = (row) => {
        let estimateTime = '';
        const tempDate = new Date();
        const dateFormat = 'HH:mm'

        if (row.arrival_time) {
            const arrivalTime = `${row.arrival_time.slice(0, -3)}`
            const hours = `${arrivalTime.slice(0, -3)}`
            const minutes = `${arrivalTime.slice(3)}`

            estimateTime = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), hours, minutes, 0);
            estimateTime.setMinutes(estimateTime.getMinutes() + row.estimation_time)
            estimateTime = `${arrivalTime} - ${dateFns.format(estimateTime, dateFormat, { locale: IndonesianLocale })} WIB`
        }

        return (
            <div>
                {estimateTime}
            </div>)
    }
    
    toRp(number) {
        if (number) {
            return `Rp ${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
        }

        return 'Rp 0 (Gratis)'
    }

    parseDate = (row) =>{
        const dateFormat = 'dddd, D MMM YYYY'
        let strDate = ''
        let timeStart = ''
        let timeEnd = ''
        if (row.book_date) {
            strDate = dateFns.format(row.book_date, dateFormat, { locale: IndonesianLocale })
        }

        if (row.schedule_time_start) {
            timeStart = `${row.schedule_time_start.slice(0, -3)}`
        }

        if (row.practice_schedule) {
            timeEnd = ` - ${row.practice_schedule.split(' - ')[1].slice(0, -3)} WIB`
        }

        return (
            <div>
                {strDate}
                <br />
                {timeStart} {timeEnd}
            </div>)
    }

    render() {
        const { doctorImgNotFound } = this.state; 
        const {
            data,
        } = this.props
        const BookNo = data.book_no < 10 ? '0' + data.book_no : data.book_no
        const activity = data.history || []

        return (
            <div className="slide-appointment">
                <div className="row-content">
                    <h3>Pasien</h3>
                    <div className="row-info">
                        <div className="col-info">
                            <span className="title">Nama Pasien</span>
                            <span className="content">{data.patient_name}</span>
                        </div>
                        <div className="col-info">
                            <span className="title">Telepon Pasien</span>
                            <span className="content">{data.telephone || '-'}</span>
                        </div>
                        <div className="col-info">
                            <span className="title">Resume Medis Pasien</span>
                            <span className="content">
                                {data.medical_record_no ? data.medical_record_no : '-'}
                            </span>
                        </div>
                        <div className="col-info">
                            <span className="title">Estimasi Kedatangan</span>
                            <span className="content content-status">
                                {this.parseTime(data)}
                                {data.status_code === 'unconfirmed' ? (
                                    <span className="notice">
                                        Harap mengonfirmasi kedatangan Anda<br />
                                        15 menit sebelum penanganan
                                    </span>
                                ) : null}
                            </span>
                        </div>
                        
                        <div className="col-info">
                            <span className="title">No. Antrian</span>
                            <span className="content">{BookNo}</span>
                        </div>
                        <div className="col-info">
                            <span className="title">Kode Booking</span>
                            <span className="content">{data.ref_no}</span>
                        </div>
                        <div className="col-info">
                            <span className="title">Jenis Booking</span>
                            <span className="content">
                                {data.book_type_label || '-'}
                            </span>
                        </div>
                        <div className="col-info">
                            <span className="title">KTP</span>
                            <span className="content">
                                {
                                    data.patient_identity_photo ? 
                                    <a href={data.patient_identity_photo} target='blank'>
                                        <img src={data.patient_identity_photo} alt='scan ID' className='preview-ktp'/>
                                    </a> :
                                    '-'
                                }
                            </span>
                        </div>
                    </div>
                    <div className="row-info">
                        <div className="col-info">
                            <span className="title">Info Pembayaran</span>
                            <span className="content">
                                {data.payment_method || '-'}
                            </span>
                        </div>
                        <div className="col-info">
                            <span className="title">Status</span>
                            <span className="content content-status">
                                <div className={data.status_code}>{data.status_label}</div>
                                {data.cancel_reason || ''}
                            </span>
                        </div>
                    </div>
                    <div className="row-info">
                        <div className="col-info">
                            <span className="title">Harga Dokter / Layanan</span>
                            <span className="content">
                                {this.toRp(data.price) || '-'}
                            </span>
                        </div>
                        <div className="col-info">
                        </div>
                    </div>
                </div>
                <div className="row-content">
                    <h3>Dokter / Layanan</h3>
                    <div className="row-info">
                        <div className="col-info">
                            <div className={!doctorImgNotFound ? 'photo' : 'photo no-img'} >
                                <ProfilePic
                                    className="doctor-img"
                                    source={data.doctor_photo} alt="Dokter"
                                    onError={this.handleErrorImg} />
                            </div>
                            <div className="col-info-child">
                                <span className="title">Nama</span>
                                <span className="content">{data.name || '-'}</span>
                            </div>
                        </div>
                        <div className="col-info">
                            <span className="title">Jadwal</span>
                            <span className="content">{this.parseDate(data)}</span>
                        </div>
                    </div>
                </div>
                <div
                    className="row-content"
                    style={data.status_code !== 'paid' ? {} : { marginBottom: 50 }}
                >
                    <h3>Aktivitas</h3>
                    <div className="row-info" style={{ paddingBottom: 0 }}>
                        {activity.length > 0
                            ? activity.map((item, i) => (
                                <div className="act-list" key={i}>{item.text}</div>
                            ))
                            : <div className="act-empty">Tidak ada aktivitas</div>}
                    </div>
                </div>
            </div>
        )
    }
}

export default Detail
