import React, { Component } from 'react'
import BsLoginForm from './component/form/BsLoginForm'
import { ToastContainer } from 'react-toastify'

class Login extends Component {

    render() {
        return(
            <div className="login-page-cinnamon">
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                />
                <BsLoginForm/>
            </div>
        )
    }
}

export default Login