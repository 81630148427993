import React from 'react'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from 'react-reactive-form'

import TextAreaForm from '../../../../core/utils/wizard/TextAreaForm'
import TextInputForm from '../../../../core/utils/wizard/TextInputForm'
import TextInputEmailForm from '../../../../core/utils/wizard/TextInputEmailForm'
import DatePickerForm from '../../../../core/utils/wizard/DatePickerForm'
import DropDownAreaForm from '../../../../core/utils/wizard/DropDownAreaForm'
import DropDownForm from '../../../../core/utils/wizard/DropDownForm'
import RadioInputForm from '../../../../core/utils/wizard/RadioInputForm'
import FormHandler from '../../handler/FormAddPatientHandler'
import SubmitButtonForm from '../../../../core/utils/SubmitButtonForm'
import ArrowDown from '../../../../themes/v1/img/layout/icon/ico-arrow-down.png'

class FormAddPatient extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hospitalId: this.props.currentHospital,
            toggleMoreField: false,
            toggleMoreAdmField: false,
            submitingForm: false,
            customFieldTitle: null,
            customField: [],
            customFieldValue: [],
            idTypeOpt: [
                { value: "KTP", label: "KTP" },
                { value: "SIM", label: "SIM" },
                { value: "Passport", label: "Passport" }
            ],
            bloodTypeOpt: [
                { value: "A", label: "A" },
                { value: "B", label: "B" },
                { value: "AB", label: "AB" },
                { value: "O", label: "O" },
            ],
            propinsiOpt: [],
            kotaOpt: [],
            kecamatanOpt: [],
            kelurahanOpt: [],
            Form: FormBuilder.group({
                nama_depan: ["", Validators.required],
                nama_belakang: ["", Validators.required],
                telepon: [
                    "",
                    [Validators.required, Validators.minLength(10), Validators.maxLength(15)],
                ],
                tanggal_lahir: ["", Validators.required],
                email: ["", Validators.email],
                tempat_lahir: [""],
                jenis_kelamin: ["M"],
                tinggi_badan: [""],
                berat_badan: [""],
                gol_darah: [""],
                tipe_identitas: [""],
                no_identitas: [""],
                kondisi_pasien: [""],
                alamat: [""],
                propinsi_id: [0],
                propinsi: [""],
                kota_id: [0],
                kota: [""],
                kecamatan_id: [0],
                kecamatan: [""],
                kelurahan_id: [0],
                kelurahan: [""],
                kodepos: [0],
                nama_png_jawab: [""],
                email_png_jawab: ["", Validators.email],
                telepon_png_jawab: [""],
                relasi_png_jawab: [""],
                custom_fields: FormBuilder.array([]),
            })
        }

        Object.assign(this, TextAreaForm)
        Object.assign(this, TextInputForm)
        Object.assign(this, TextInputEmailForm)
        Object.assign(this, DatePickerForm)
        Object.assign(this, DropDownAreaForm)
        Object.assign(this, DropDownForm)
        Object.assign(this, RadioInputForm)
        this.TextAreaFormView = this.TextAreaFormView.bind(this)
        this.TextInputFormView = this.TextInputFormView.bind(this)
        this.TextInputEmailFormView = this.TextInputEmailFormView.bind(this)
        this.DatePickerFormView = this.DatePickerFormView.bind(this)
        this.DropDownAreaFormView = this.DropDownAreaFormView.bind(this)
        this.DropDownFormView = this.DropDownFormView.bind(this)
        this.RadioInputFormView = this.RadioInputFormView.bind(this)

        Object.assign(this, FormHandler)
        this.loadCustomField = this.loadCustomField.bind(this)
        this.getDropdownPropinsi = this.getDropdownPropinsi.bind(this)
        this.showSubmitLoader = this.showSubmitLoader.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.buildForm()
    }

    componentDidMount() {
        this.loadCustomField()
        this.getDropdownPropinsi()
    }

    renderCustomFieldContainer() {
        const customField = this.state.customField
        const customFieldTitle = this.state.customFieldTitle
        return (<div className="wizard-preview appointment-pasient">
            <h3>{customFieldTitle}</h3>
            {
                customField.map((obj, key) => {
                    const state = this.state.Form.controls.custom_fields
                    if (state.controls[0]) {
                        return (
                            <div key={key}>
                                {
                                    state.controls[0].controls[obj.fieldLabel] ? this.getCustomField(obj, state.controls[0].controls[obj.fieldLabel]) : null
                                }
                            </div>
                        )
                    }
                    return null
                })
            }
        </div>)
    }

    getCustomField(obj, controls) {
        switch (obj.fieldKey) {
            case 'input_text_free':
                if (obj.fieldSubType === 'email') {
                    return (
                        this.TextInputEmailFormView({
                            label: obj.fieldLabel,
                            name: obj.fieldLabel,
                            control: controls,
                            validator: 'required',
                            placeholder: `Isi ${obj.fieldLabel}`,
                        })
                    )
                } else {
                    return (
                        this.TextInputFormView({
                            label: obj.fieldLabel,
                            name: obj.fieldLabel,
                            control: controls,
                            validator: 'required',
                            placeholder: `Isi ${obj.fieldLabel}`,
                        })
                    )
                }
            case 'text_area':
                return (
                    this.TextAreaFormView({
                        label: obj.fieldLabel,
                        name: obj.fieldLabel,
                        readonly: false,
                        control: controls,
                        validator: 'required',
                        placeholder: `Isi ${obj.fieldLabel}`,
                    })
                )
            case 'input_text_numeric':
                return (
                    this.TextInputFormView({
                        label: obj.fieldLabel,
                        name: obj.fieldLabel,
                        readonly: false,
                        inputType: 'number',
                        control: controls,
                        validator: 'required',
                        placeholder: `Isi ${obj.fieldLabel}`,
                    })
                )
            case 'calendar':
                return (
                    this.DatePickerFormView({
                        meta: {
                            name: obj.fieldLabel,
                            label: obj.fieldLabel,
                            readonly: false,
                            validator: 'required',
                        },
                        name: obj.fieldLabel,
                        control: controls,
                    })
                )
            case 'radio':
                return (
                    this.RadioInputFormView({
                        name: obj.fieldLabel,
                        meta: {
                            label: obj.fieldLabel,
                            readonly: false,
                            options: obj.option
                        },
                        control: controls,
                        validator: 'required'
                    })
                )
            case 'checkbox':
                return (
                    this.CheckBoxFormView({
                        name: obj.fieldLabel,
                        meta: {
                            label: obj.fieldLabel,
                            validator: "",
                            options: obj.option
                        },
                        control: controls,
                        validator: 'required'
                    })
                )
            case 'dropdown':
                return (
                    this.DropDownFormView({
                        meta: {
                            label: obj.fieldLabel,
                            validator: "required",
                            options: obj.option.map(opt => {
                                return {
                                    value: opt.value,
                                    label: opt.name
                                }
                            })
                        },
                        name: obj.fieldLabel,
                        nameText: obj.fieldLabel,
                        control: controls,
                    })
                )
            default:
                return null
        }
    }

    onToggleMore() {
        this.setState({
            toggleMoreField: !this.state.toggleMoreField
        })
    }

    onToggleMoreAdm() {
        this.setState({
            toggleMoreAdmField: !this.state.toggleMoreAdmField
        })
    }

    render() {
        const {
            customFieldTitle,
            toggleMoreField,
            toggleMoreAdmField,
            Form,
        } = this.state

        return (
            <div className="panel-form wizard-form appointment-pasient">
                <h3>Tambah Pasien Baru</h3>
                <FieldGroup
                    control={Form}
                    strict={false}
                    render={({ invalid }) => {
                        return (
                            <form onSubmit={() => this.handleSubmit} autoComplete="off">
                                { this.TextFirstName}
                                { this.TextLastName}
                                { this.DatePickDob}
                                { this.TextPhone}
                                <div className="hor-line patient" />
                                <div className={`wrap-additional patient ${toggleMoreField
                                    ? 'show-field'
                                    : 'hide-field'}`}>
                                    <div id="toggle-button" onClick={this.onToggleMore.bind(this)}>
                                        Informasi Tambahan (Optional)
                                <img className="icon" src={ArrowDown} alt="icon arrow down" />
                                    </div>
                                    <div className="wrap-input-additional patient">
                                        <h3>Data Diri</h3>
                                        <div className="form-hide-wrapper">
                                            {this.TextEmail}
                                            {this.TextBirthPlace}
                                            <div className="radio-patient">
                                                {this.RadioGender}
                                            </div>
                                            {this.TextHeight}
                                            {this.TextWeight}
                                            {this.DropDownBloodType}
                                            {this.DropDownTypeId}
                                            {this.TextIdNo}
                                            {this.TextCondPatient}
                                        </div>
                                        <div className="hor-line patient" />
                                        <h3>Kontak Alamat</h3>
                                        <div className="form-hide-wrapper">
                                            {this.TextAddress}
                                            {this.DropDownPropinsi}
                                            {this.DropDownKota}
                                            {this.DropDownKecamatan}
                                            {this.DropDownKelurahan}
                                        </div>
                                    </div>
                                </div>
                                {
                                    customFieldTitle !== null ?
                                        this.renderCustomFieldContainer()
                                        : null
                                }
                                <div className="hor-line patient" />
                                <div className={`wrap-additional patient ${toggleMoreAdmField
                                    ? 'show-field'
                                    : 'hide-field'}`}>
                                    <div id="toggle-button" onClick={this.onToggleMoreAdm.bind(this)}>
                                        Informasi Administrasi (Optional)
                                <img className="icon" src={ArrowDown} alt="icon arrow down" />
                                    </div>
                                    <div className="wrap-input-additional patient">
                                        <h3>Informasi Penanggung Jawab</h3>
                                        <div>
                                            {this.TextPICName}
                                            {this.TextPICPhone}
                                            {this.TextPICEmail}
                                            <div className="radio-patient">
                                                {this.RadioPICRelationship}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!toggleMoreAdmField
                                    ? <div className="hor-line patient" />
                                    : null}
                                {
                                    !this.state.submitingForm ?
                                        <SubmitButtonForm
                                            submitText={'Daftarkan Pasien'}
                                            invalid={invalid}
                                            onSubmit={(e) => this.handleSubmit(e)}
                                        /> :
                                        <div>Loading</div>
                                }
                            </form>
                        )
                    }}
                >
                </FieldGroup>
            </div>
        )
    }
}

export default FormAddPatient
