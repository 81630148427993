import React, { Component } from 'react'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from 'react-reactive-form'

import TextInputForm from '../../../../../core/utils/forminput/TextInputForm'
import TextAreaForm from '../../../../../core/utils/forminput/TextAreaForm'
import RadioInputForm from '../../../../../core/utils/wizard/RadioInputForm'
import Handler from '../../../handler/superadmin/services/FormHandler'
import ImageUploadForm from '../../../../../core/utils/ImageUploadForm'
import iconPlus from '../../../../../themes/v1/img/rectangle.png'
import DropDownForm from '../../../../../core/utils/wizard/DropDownForm'

class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            file: [],
            categoryListOpt: [],
            Form: FormBuilder.group({
                parent_id: [''],
                name: ['', Validators.required],
                description: [''],
                photo: [''],
                active: ['true'],
                sort_order: ['99', Validators.required]
            })
        }

        Object.assign(this, TextInputForm)
        this.TextInputFormView = this.TextInputFormView.bind(this)
        Object.assign(this, TextAreaForm)
        this.TextAreaFormView = this.TextAreaFormView.bind(this)
        Object.assign(this, RadioInputForm)
        this.RadioInputFormView = this.RadioInputFormView.bind(this)
        Object.assign(this, DropDownForm)
        this.DropDownFormView = this.DropDownFormView.bind(this)

        // assign to handler
        Object.assign(this, Handler)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.buildValue = this.buildValue.bind(this)
        this.handleUpload = this.handleUpload.bind(this)

        this.buildForm()
    }

    componentDidMount() {
        const { dataEdit } = this.props
        if (dataEdit) {
            this.buildValue({
                photo: dataEdit.photo,
                parent_id: `${dataEdit.parent_id}`,
                name: dataEdit.name,
                description: dataEdit.description,
                active: `${dataEdit.is_active || 'false'}`,
                sort_order: dataEdit.sort_order
            });
        }
        this.getCategoryList();
    }

    render() {
        const {
            loading,
            Form,
            file,
        } = this.state
        const { dataEdit } = this.props

        return (
            <FieldGroup control={Form}
                strict={false}
                render={({ invalid }) => {
                    return (
                        <div
                            className="wizard-form custom-form"
                            style={{ display: 'flex', margin: '18px auto 70px' }}
                        >
                            <form onSubmit={this.handleSubmit} autoComplete="off">
                                <ImageUploadForm
                                    onUpload={this.handleUpload}
                                    desc="Tambah Foto"
                                    icon={iconPlus}
                                    maxsize="2500000"
                                    image={file}
                                    source="Foto"
                                    current={Form.controls.photo}
                                />
                                {this.DropDownKategori}
                                {this.nameField}
                                {this.descField}
                                {this.activeField}
                                {this.positionField}
                                <div className="button-action-slider">
                                    <div className="form-btn-action">
                                        <button
                                            type="submit"
                                            className={
                                                `save itgbtn primary${loading || invalid
                                                    ? " disabled"
                                                    : ""}`
                                            }
                                            disabled={loading || invalid}
                                            onSubmit={this.handleSubmit}
                                        >
                                            {loading
                                                ? 'Loading...'
                                                : dataEdit ? 'Update' : 'Simpan'}
                                        </button>
                                        <button
                                            type="button"
                                            className="itgbtn cancel"
                                            onClick={() => this.props.onPanelClose()}
                                        >
                                            Batal
                                    </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )
                }}
            />
        )
    }
}

export default Form