import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import DashboardTemplate from '../../template/DashboardTemplate'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../core/const/LoginConst'
import Grid from './components/superadmin/spesialities/Grid'

class DashboardSpesialities extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        let isAdmin = false
        if (this.props.isLoggin === true) {
            isAdmin = this.props.dataLogin.superadmin
        }

        if (!isAdmin || process.env.REACT_APP_USER !== 'true') {
            return (
                <Redirect to="/" />
            )
        }

        return (
            <DashboardTemplate {...this.props} >
                <div className="dashboard-container wrapper-facility specialist">
                    <div className="container-fluid">
                        <Grid {...this.props}/>
                    </div>
                </div>
            </DashboardTemplate>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(DashboardSpesialities)