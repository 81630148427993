import React, { Component } from 'react'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from 'react-reactive-form'

import ImageUploadForm from '../../../../../../core/utils/ImageUploadForm'
import TextInputForm from '../../../../../../core/utils/forminput/TextInputForm'
import TextAreaForm from '../../../../../../core/utils/forminput/TextAreaForm'
import SwitchForm from '../../../../../../core/utils/wizard/SwitchForm'
import Handler from '../../../../handler/superadmin/setting/facility/FormHandler'
import iconPlus from '../../../../../../themes/v1/img/rectangle.png'

class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            file: [],
            Form: FormBuilder.group({
                name: ["", Validators.required],
                description: [""],
                only_clinic: [false],
                icon: [""],
            })
        }

        Object.assign(this, ImageUploadForm)
        Object.assign(this, TextInputForm)
        this.TextInputFormView = this.TextInputFormView.bind(this)
        Object.assign(this, TextAreaForm)
        this.TextAreaFormView = this.TextAreaFormView.bind(this)
        Object.assign(this, SwitchForm)
        this.SwitchFormView = this.SwitchFormView.bind(this)

        // assign to handler
        Object.assign(this, Handler)
        this.handleUpload = this.handleUpload.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.buildValue = this.buildValue.bind(this)

        this.buildForm()
    }

    componentDidMount() {
        const { dataEdit } = this.props

        if (dataEdit) {
            this.buildValue(dataEdit);
        }
    }

    render() {
        const { file, Form } = this.state
        const { dataEdit } = this.props

        return (
            <FieldGroup control={Form}
                strict={false}
                render={({ invalid }) => {
                    return (
                        <div
                            className="wizard-form custom-form"
                            style={{ display: 'flex', margin: '18px auto 70px' }}
                        >
                            <form onSubmit={this.handleSubmit} autoComplete="off">
                                {this.nameField}
                                {this.descField}
                                {this.onlyClinic}
                                <ImageUploadForm
                                    onUpload={this.handleUpload}
                                    desc="Tambah Icon"
                                    icon={iconPlus}
                                    maxsize="2500000"
                                    image={file}
                                    source="Icon"
                                    current={this.state.Form.controls.icon}
                                />
                                <div className="button-action-slider">
                                    <div className="form-btn-action">
                                    <button
                                        type="submit"
                                        className={
                                            `save itgbtn primary ${invalid
                                                ? "disabled"
                                                : ""}`
                                        }
                                        disabled={invalid}
                                        onSubmit={this.handleSubmit}
                                    >
                                        {dataEdit ? 'Update' : 'Simpan'}
                                    </button>
                                    <button
                                        type="button"
                                        className="itgbtn cancel"
                                        onClick={() => this.props.onPanelClose()}
                                    >
                                        Batal
                                    </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )
                }}
            />
        )
    }
}

export default Form
