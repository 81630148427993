import React from 'react'
import Checkbox from '../Checkbox'
import Select from 'react-select'
import SAConfig from '../../config/superadmin'

class ModalFilterContent extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            preSelFilterFacility: this.props.facilities,
            preSelFilterType: this.props.types,
            preInputSearch: this.props.normalfilter.name,
            preSelCity: this.props.normalfilter.city,
            preSelInstitution: this.props.normalfilter.institution,
            preSelStatus: this.props.normalfilter.status,
            typeOption: SAConfig.typeInsitution,
            statusOption: SAConfig.statusInsitution,
        }
        this.handleCombine = this.handleCombine.bind(this)
        this.handleRemove = this.handleRemove.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.applyFilter = this.applyFilter.bind(this)
        this.handleChangeText = this.handleChangeText.bind(this)
        this.handleCityChange = this.handleCityChange.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    handleCombine(type, newData){
        if(type === 'facility'){
            let current = this.state.preSelFilterFacility
            current.push(newData)
            this.setState({
                preSelFilterFacility: current
            })
        }else if(type === 'type'){
            let currentType = this.state.preSelFilterType
            currentType.push(newData)
            this.setState({
                preSelFilterType: currentType
            })
        }
    }

    handleRemove(type, item){
        if(type === 'facility'){
            let current = this.state.preSelFilterFacility
            const index = current.indexOf(item)
            if(index > -1){
                current.splice(index, 1)
            }
            this.setState({
                preSelFilterFacility: current
            })
        }else if(type === 'type'){
            let current = this.state.preSelFilterType
            const index = current.indexOf(item)
            if(index > -1){
                current.splice(index, 1)
            }
            this.setState({
                preSelFilterType: current
            })
        }
    }

    resetFilter(){
        this.setState({
            preInputSearch: '',
            preSelFilterFacility: [],
            preSelFilterType: [],
            preSelCity: null,
            preSelInstitution: null,
            preSelStatus: null,
        })
    }

    handleChangeText(e){
        this.setState({
            preInputSearch: e.target.value
        })
    }

    handleCityChange(selectedOption){
        let newCity = selectedOption.label
        if(selectedOption.value === 0){
            newCity = ''
        }

        this.setState({ 
            preSelCity: newCity
        })
    }

    handleChange(field, selectedOption) {
        this.setState({
            [field]: String(selectedOption.value)
        })
    }

    applyFilter(){
        const filters = {
            facility: this.state.preSelFilterFacility,
            type: this.state.preSelFilterType,
            name: this.state.preInputSearch,
            city: this.state.preSelCity,
            institution: this.state.preSelInstitution,
            status: this.state.preSelStatus,
        }
        this.props.updateSelectedFilter(filters)
    }

    render() {
        return(
            <div className="wrap-filters-admin">
                <div className="row-filterbar-modal">
                    <div className="filter">
                        <input 
                            type="text" 
                            className="dashboard-search-text modal-search-text" 
                            placeholder="Cari"
                            value={ this.state.preInputSearch }
                            onChange={ this.handleChangeText }
                            />
                        <i className="fas fa-search"></i>
                    </div>
                    <div className="filter">
                        <Select
                            className={ `dropdown-filter` }
                            classNamePrefix={ 'react-select' }
                            name={ 'filter-city' }
                            placeholder={ 'Semua kota' }
                            onChange={ this.handleCityChange }
                            options={ this.props.cityList }
                            value={
                                this.state.preSelCity ? 
                                this.props.cityList.find(
                                    option => String(option.label) === String(this.state.preSelCity)
                                ) : 
                                null
                            }
                        />
                    </div>
                    <div className="filter">
                        <Select
                            className={ `dropdown-filter` }
                            classNamePrefix={ 'react-select' }
                            name={ 'filter-type' }
                            placeholder={ 'Semua tipe' }
                            options={ this.state.typeOption }
                            onChange={(e) => this.handleChange('preSelInstitution', e)}
                            value={
                                this.state.preSelInstitution ?
                                this.state.typeOption.find(
                                    option => String(option.value) === String(this.state.preSelInstitution)
                                ) :
                                null
                            }
                        />
                    </div>
                    <div className="filter">
                        <Select
                            className={ `dropdown-filter` }
                            classNamePrefix={ 'react-select' }
                            name={ 'filter-status' }
                            placeholder={ 'Semua status' }
                            options={ this.state.statusOption }
                            onChange={(e) => this.handleChange('preSelStatus', e)}
                            value={
                                this.state.preSelStatus ?
                                    this.state.statusOption.find(
                                        option => String(option.value) === String(this.state.preSelStatus)
                                    )
                                    : null
                            }
                        />
                    </div>
                </div>
                <div className="filter-context-container">
                    <div className="filter-left">
                        <span>Fasilitas</span>
                        <Checkbox 
                            options={ this.props.facilityList} 
                            prefix="facility"
                            onCheck={ this.handleCombine }
                            onRemove={ this.handleRemove }
                            items={ this.state.preSelFilterFacility }
                            class="wrap-checkbox-left" 
                        />
                        <button className="btn btn-reset-filter" onClick={ this.resetFilter }>
                            <span>Reset filter</span>
                        </button>
                    </div>
                    <div className="filter-right dashboard">
                        <span>Jenis</span>
                        <Checkbox 
                            options={ this.props.typeList } 
                            prefix="type"
                            onCheck={ this.handleCombine }
                            onRemove={ this.handleRemove }
                            items={ this.state.preSelFilterType }
                            class="wrap-checkbox-right" 
                        />
                        <button className="btn btn-apply-filter" onClick={ this.applyFilter }>
                            <span>Terapkan</span>
                        </button>
                        <button
                            className="btn btn-cancel-filter"
                            onClick={this.props.handleCloseModal}
                        >
                            <span>Batal</span>
                        </button>
                    </div>
                    <div className="clearfix" />
                </div>
            </div>
        )
    }
}

export default ModalFilterContent