import { isEmpty, isArray } from 'lodash'

import Api from '../../../services/apis/Hospital/HospitalServices'
import {
    handleToast,
    handleToastServiceError 
} from '../../../core/utils/UtilsToast'
import { getRawValueFromCurrency } from '../../../core/utils/Helper'

const FormHandler = {
    buildForm() {
        const { title } = this.props

        this.nameField = this.TextInputFormView({
            validator: 'required',
            label: `Nama`,
            name: 'name',
            placeholder: `Nama ${title} Utama`,
            control: this.state.Form.controls.name
        })

        this.descField = this.RichEditorInputFormView({
            label: 'Deskripsi Lengkap',
            name: 'description',
            placeholder: 'Deskripsi',
            control: this.state.Form.controls.description
        })

        this.shortDescField = this.TextInputFormView({
            label: `Deskripsi Singkat`,
            name: 'short_desc',
            placeholder: `Deskripsi Singkat`,
            control: this.state.Form.controls.short_desc
        })
        
        this.priceField = this.TextInputPriceFormView({
            validator: 'required',
            label: 'Harga Layanan',
            name: 'price',
            placeholder: 'Harga Layanan',
            inputType: 'price',
            maxLength: 11,
            control: this.state.Form.controls.price
        })
        
        this.priceSpecialField = this.TextInputPriceFormView({
            validator: 'required',
            label: 'Harga Spesial Layanan',
            name: 'price_special',
            placeholder: 'Harga Spesial Layanan',
            inputType: 'price',
            maxLength: 11,
            control: this.state.Form.controls.price_special
        })

        this.DropDownServices = this.DropDownView({
            meta: {
                label: "Sub Kategori",
                validator: "required"
            },
            name: "category_service",
            control: this.state.Form.controls.category_service,
            stateName: 'servicesOpt',
        })

        this.DropDownEstimation = this.DropDownView({
            meta: {
                label: "Estimasi Waktu",
                validator: "required"
            },
            name: "estimation_time",
            control: this.state.Form.controls.estimation_time,
            stateName: 'estimationTimeOpt',
        })

        this.statusField = this.RadioInputFormView({
            name: 'status',
            meta: {
                label: 'Status',
                options: [
                    {
                        value: 'true',
                        name: 'Aktif',
                    },
                    {
                        value: 'false',
                        name: 'Non Aktif',
                    }
                ]
            },
            control: this.state.Form.controls.status
        })

        this.uploadIdCardField = this.RadioInputFormView({
            name: 'is_upload_idcard',
            meta: {
                label: 'Upload KTP',
                options: [
                    {
                        value: 'true',
                        name: 'Ya',
                    },
                    {
                        value: 'false',
                        name: 'Tidak',
                    }
                ]
            },
            control: this.state.Form.controls.is_upload_idcard
        })
    },
    loadServiceOption: async function(){
        await Api.listMaster().then(({ data }) => {
            const { data: dataRes } = data
            this.setState({
                servicesOpt: dataRes
            })
        })
    },
    handleUpload(file) {
        this.setState({ file })
        this.state.Form.patchValue({ icon: file })
    },
    handleUploadMultiple(file) {
        const f = file.substring(11,14);
       if(f !== "png" && f !== "jpe" && f !== "jpg" && f !== "svg"){
            alert('Format Foto Tidak Sesuai')
        }else{
        let newPhoto = this.state.fileMultiple;
        let oldPhoto = this.state.Form.controls.photos.value;
        let currentPhoto = oldPhoto.length ? oldPhoto : newPhoto;
        let multi = currentPhoto;
        multi.push(file);

        this.state.photosAdded.push(file); //param handleSubmit

        this.setState({ multi })
        this.state.Form.patchValue({ photos: multi })
        }
    },
    removePhoto(index) {
        let newPhoto = this.state.fileMultiple;
        let oldPhoto = this.state.Form.controls.photos.value;
        let currentPhoto = newPhoto.length ? newPhoto : oldPhoto;
        if (/(jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg)$/i.test(currentPhoto[index])){
            this.state.photosRemove.push(currentPhoto[index]); //param handleSubmit
        } else {
            this.state.photosAdded.splice(index, 1);
        }
        
        currentPhoto.splice(index, 1);

        this.setState({ currentPhoto })
        this.state.Form.patchValue({ photos: currentPhoto })
    },
    buildValue(data) {
        this.state.Form.patchValue(data)
    },
    handleSubmit: async function (e) {
        e.preventDefault();
        let response = {}
        const { data } = this.props
        const formValue = Object.assign({}, this.state.Form.value)

        let photosAdded = this.state.photosAdded;
        let photosRemoved = this.state.photosRemove;
        let categoryServiceValue = formValue.category_service
        if (typeof formValue.category_service === 'object') {
            categoryServiceValue = formValue.category_service?.value ? formValue.category_service.value : null
        }

        let estimationTimeValue = formValue.estimation_time
        if (typeof formValue.estimation_time === 'object') {
            estimationTimeValue = formValue.estimation_time?.value ? formValue.estimation_time.value : null
        }

        const objData = {
            // photo: formValue.photo,
            photos: photosAdded,
            remove_photos: photosRemoved,
            icon: formValue.icon,
            hospital_id: this.props.currentHospital,
            name: formValue.name,
            info: formValue.description,
            short_desc: formValue.short_desc,
            is_active: formValue.status === 'true',
            is_upload_idcard: formValue.is_upload_idcard === 'true',
            price: getRawValueFromCurrency(formValue.price),
            price_special: getRawValueFromCurrency(formValue.price_special),
            estimation_time: estimationTimeValue,
            category_service_id: categoryServiceValue
        }
        if (data.isEdit) {
            if (this.state.file.length <= 0) {
                delete objData.icon
            }

            if (photosAdded.length <= 0) {
                delete objData.photos
            }

            if (photosRemoved.length <= 0) {
                delete objData.remove_photos
            }
        }

        try {
            if (data.isEdit) {
                response = await Api.update(data.id, objData)
            } else {
                response = await Api.create(objData)
            }

            handleToast(response.data.message)

            this.props.loadDataAfterAction()
            this.props.onPanelClose()
        } catch (e) {
            let message = e.message
            const errData = e.response

            if (!isEmpty(errData) && errData.data !== '') {
                message = errData.data.message
                if (!isArray(errData.data.data)) {
                    message = Object.keys(errData.data.data).map(k => errData.data.data[k]).join('\n')
                }
            }

            handleToastServiceError({ message })
        }
    }
}

export default FormHandler