import React from 'react'
import validateReturn from '../ValidatorForm'
import { FieldControl } from "react-reactive-form";
import Select from 'react-select';

const DropDownFormApotek = {
    DropDownFormApotekView: function({name, nameText, stateName, meta, control, fieldControlAdditional}) {
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}
        Object.assign(control, additionalController);
        meta.name = name
        meta.nameText = nameText
        const callBack = meta.callBack ? meta.callBack : null
        return <FieldControl
            name = {name}
            strict = {false}
            meta = {{...meta}}
            render={(obj) => this.DropDownFormApotekRender(obj, control, stateName, name, nameText, callBack)}
            control = {control}
        />
    },
    onChangeEvent: function (e, onChangeEvent, name, callBack) {
        onChangeEvent(e)
        if(callBack){
            callBack(this, name)
        }
    },
    DropDownFormApotekRender: function( {handler, touched, hasError, meta}, control, stateName, name, nameText, callback) {
        const SelectedVal = control ? control.value : null
        const isDisabled = control.status === 'DISABLED'
        const options = meta.options ? meta.options : this.state[stateName]
        const isEdit = this.state.isEdit
        const editChanged = this.state.editChanged

        const formatOptionLabel = ({value,label}) => {
            if(value !== ''){
                return(
                    <div>
                        <span>{ label }</span>
                        {/* <span style={{
                            paddingLeft: "10px",
                            fontSize: "14px",
                            color: "#334680"
                        }}>{ `(${ value })` }</span> */}
                    </div>
                )
            }else{
                return(
                    <div>
                        <span>{ label }</span>
                    </div>
                )
            }
        }


        if(isEdit && !editChanged && meta.EditshowOnlyText){
            return(
                <div className={touched && hasError(meta.validator) ? "form-line has-danger" : "form-line"}>
                    <label id={`label-${meta.name}`} className="form-control-label" htmlFor={`${meta.label}`}>
                        {`${meta.label}`}
                    </label>
                    <div className="form-group-wizard">
                        <Select
                            className="dropdown-wizard"
                            classNamePrefix="react-select"
                            name={name}
                            options={ [] }
                            placeholder={ meta.placeholder ? meta.placeholder : " " }
                            formatOptionLabel={ formatOptionLabel }
                            isDisabled={ true }
                        />
                    </div>
                </div>
            )
        }else{
            return (
                <div className={touched && hasError(meta.validator) ? "form-line has-danger" : "form-line"}>
                    <label id={`label-${meta.name}`} className="form-control-label" htmlFor={`${meta.label}`}>
                        {`${meta.label}`}
                    </label>
                    <div className="form-group-wizard">
                        <Select
                            className="dropdown-wizard"
                            classNamePrefix="react-select"
                            name={name}
                            options={options}
                            placeholder={meta.placeholder ? meta.placeholder : " "}
                            isDisabled={isDisabled}
                            {...handler()}
                            inputProps={{
                                readOnly: meta.readonly
                            }}
                            formatOptionLabel={formatOptionLabel}
                            onChange={(e) => this.onChangeEvent(e, handler().onChange, name, callback)}
                            onBlur={handler().onBlur}
                            value={options.find(option => String(option.value) === String(SelectedVal))}
                        />
                    </div>
                    <span className="error-info">
                        {
                            touched
                            && hasError(meta.validator)
                            && validateReturn(meta.validator)
                        }
                    </span>
                </div>
            )
        }
    },
    handleFocus(event) {
        if(event.target.value === '' && !event.target.parentNode.classList.contains('focused')){
            event.target.parentNode.classList.toggle('focused')
        }
    }
}
export default DropDownFormApotek