import React from 'react'
import Dropzone from 'react-dropzone'

export default class DocumentUploadForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      placeholder: true,
      droped: false,
      filename: null
    }

    this.stateDroped = this.stateDroped.bind(this)
    this.stateLoad = this.stateLoad.bind(this)
  }

  onDrop(files) {
    this.props.onDrop()
    this.setState({
      files: files.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })),
      placeholder: false,
      droped: true,
      filename: files[0].name,
    })

    files.forEach(file => {
      const reader = new FileReader()
      reader.onload = () => {
        this.props.onUpload(reader.result, file)
      }
      reader.readAsDataURL(file)
    });
  }


  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach(file => URL.revokeObjectURL(file.preview))
  }

  handleClick(e){
    e.preventDefault()
    this.props.onSubmit()
  }

  stateReady(){
    return(
      <div>
        <div className="image-placeholder"><img src={ this.props.icon } alt="" /></div>
        <div className="text-placeholder">{ this.props.desc }</div>
        <div className="button-placeholder"><span>Cari Data</span></div>
      </div>
    )
  }

  stateError(){
    return(
      <div>
        <div className="icon-invalid"><span>!</span></div>
        <div className="text-invalid">
          Kendala CSV <br />
          - Kode RS/Klinik tidak boleh melebihi 10 Karakter <br />
          - Pastikan field sesuai dengan contoh format data <br />
          Silahkan unduh format data yang tersedia sebagai panduan unggah
        </div>
      </div>
    )
  }

  stateDroped(filename){
    return(
      <div>
        <div className="image-placeholder">{ filename }</div>
        <div className="text-placeholder"></div>
        <div className="button-placeholder" onClick={e => this.handleClick(e)}><span>Unggah Data</span></div>
      </div>
    )
  }

  stateLoad(){
    return(
      <div>
        <span>'Silahkan tunggu...'</span>
        <div className="image-placeholder"><img src={ this.props.loader } alt="" /></div>
      </div>
    )
  }

  render() {
    let { filename } = this.state
    const { label, maxSize, state } = this.props
    let uploadState = null

    switch(state){
      case 'ready':
        uploadState = this.stateReady()
        break;
      case 'error':
        uploadState = this.stateError()
        break;
      case 'droped':
        uploadState = this.stateDroped(filename)
        break;
      default:
        uploadState = this.stateLoad()
    }

    return (
      <div className="form-upload-document">
        <div className="form-line">
          <label className="form-control-label" >{label}</label>
          <div className="file-container">
            <Dropzone
              accept="text/csv"
              onDrop={this.onDrop.bind(this)}
              maxSize={maxSize}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="file-upload" {...getRootProps()}>
                  <input {...getInputProps()} />
                  { uploadState }
                </div>
              )}
            </Dropzone>
          </div>
        </div>
      </div>
    )
  }
}
