import { handleToast, handleToastServiceError } from '../../../core/utils/UtilsToast'
import UserApi from '../../../services/apis/UserApi'
import { UserConfig, RoleOption } from '../Config'

const UserFormHandler = {
    onPanelClose(){
        this.setState({
            isPaneOpen: false
        })
    },
    onPanelOpen(){
        this.setState({
            isPaneOpen: true
        })
    },
    buildForm: function () {
        this.TextName = this.TextInputFormView({
            label: 'Nama',
            validator: 'required',
            name: 'name',
            placeholder: 'Nama User',
            maxLength: 50,
            control: this.state.Form.controls.name
        })
        
        this.TextEmail = this.TextInputEmailFormView({
            label: 'Email',
            validator: 'required',
            name: 'email',
            placeholder: 'youremail@mail.com',
            maxLength: 50,
            control: this.state.Form.controls.email
        })

        this.TextTelephone = this.TextInputFormView({
            label: 'Nomor Telepon',
            validator: 'required',
            name: 'telephone',
            placeholder: '+62812xxxxxxx',
            inputType: "tel",
            maxLength: 15,
            control: this.state.Form.controls.telephone
        })

        this.DropDownRole = this.DropDownFormView({
            meta: {
                label: "Posisi",
                placeholder: "Posisi Admin",
                validator: "required",
                options: RoleOption
            },
            name: "role",
            control: this.state.Form.controls.role,
        })
    },
    fetchPoly(){
        const fetchPoly = UserApi.polyclinic
        fetchPoly().then(res => {
            const poly = res.data.data
            let opt = []
            poly.map(value => 
                opt.push({
                    name: value.label,
                    value: value.value
                })
            )
            this.setState({
                polyOpt: opt
            })
        })
    },

    valueBuilder: function () {
        let me = this
        this.valueObject = {
            id: '',
            name: '',
            email: '',
            telephone: '',
            poly: [],
            role: '',
            access: ''
        }
        
         if (this.props.history.location.pathname.includes(UserConfig.editUrl)) {
            UserApi.show(this.props.match.params.id).then((response) => {
                this.fetchPoly()
                me.fillForm(response.data.data)
            }, (e) => {
                handleToastServiceError(e)
                setTimeout(function(){ me.props.history.push(UserConfig.baseUrl); }, 3000);
            })
        }else{
            this.fetchPoly()
        }
    },

    fillForm: function(dataProvider) {
        let me = this
        let poly = []
        Object.keys(dataProvider).map((key) => {
            if (me.valueObject.hasOwnProperty(key)) {
                if(key === 'access'){
                    dataProvider[key].map( data => {
                        poly.push(data.poly_id)
                        return poly
                    })
                    me.valueObject['poly'] = poly
                    me.valueObject['role'] = dataProvider[key][0].role_id
                }
                
                me.valueObject[key] = dataProvider[key] ? dataProvider[key].toString() : ''
            }
            return true
        })

        this.state.Form.patchValue(me.valueObject)
        this.setState({
            isEdit: true
        })
    },

    handleSubmit: async function (e) {
        e.preventDefault()
        let formValue = Object.assign({}, this.state.Form.value)
        let id = formValue['id']
        let me = this
        let response
        let access = []
        let stringAccess = ''

        formValue.hospital_id = this.props.currentHospital

        //set up some default field
        formValue.must_changed_password = false
        formValue.banned = false
        formValue.suspended = false
        formValue.deleted = false

        if(formValue.role instanceof Object === true){
            formValue.role = formValue.role.value
        }

        formValue.poly.map(value => {
            access.push(`${ value }:${ formValue.role }`)
            return access
        })

        if(access){
            stringAccess = access.join(",")
            formValue.access = stringAccess
            delete(formValue.poly)
            delete(formValue.role)
        }

        if (this.state.isEditUser) {
            try {
                response = await UserApi.update(id, JSON.stringify(formValue))
            } catch (e) {
                handleToastServiceError(e)
                return
            }

            if (response.data.code !== 201) {
                return
            }

            me.setState({postStatus: true})
            handleToast(response.data.message)

            return
        }

        try {
            response = await UserApi.post(JSON.stringify(formValue))
        } catch (e) {
            handleToastServiceError(e)
            return
        }
    
        if (response.data.code !== 201) {
            me.setState({postStatus: true})
            handleToast(response.data.message)
            return
        }

        handleToast(response.data.message)
    }
}

export default UserFormHandler