import React, { Component } from 'react'
import dateFns from 'date-fns'
import _ from 'lodash'
import moment from 'moment'
import Datetime from 'react-datetime'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from 'react-reactive-form'
import 'moment/locale/id'

import SubmitButtonForm from '../../../../core/utils/SubmitButtonForm'
import TextInputForm from '../../../../core/utils/wizard/TextInputForm'
import TextInputPriceForm from '../../../../core/utils/forminput/TextInputPriceForm'
import TextAreaForm from '../../../../core/utils/wizard/TextAreaForm'
import DropDownForm from '../../../../core/utils/wizard/DropDownForm'
import DropDownFormApotek from '../../../../core/utils/wizard/DropDownFormApotek'
import ImageUploadForm from '../../../../core/utils/ImageUploadForm'
import ImageUploadFormWithCrop from '../../../../core/utils/ImageUploadFormWithCrop'
import RadioInputForm from '../../../../core/utils/wizard/RadioInputForm'
import DatePickerForm from '../../../../core/utils/wizard/DatePickerForm'
import StrNoInputForm from '../../../../core/utils/doctor/TextInputForm'
import SwitchForm from '../../../../core/utils/wizard/SwitchForm'
import AdditionalInputForm from '../../../../core/utils/doctor/AdditionalInputForm'
import InputBox from '../../../../core/utils/doctor/UtilsStrapInputModal'
import DoctorFormHandler from '../handler/EditFormHandler'
import iconPlus from '../../../../themes/v1/img/rectangle.png'
import ArrowDown from '../../../../themes/v1/img/layout/icon/ico-arrow-down.png'
import Config from '../config/DataConfig'
import MasterModal from '../../../../core/utils/MasterModal'
import RoleHelper from '../../../../core/helper/RoleHelper'
import Checkbox from '../../../dashboard/components/Checkbox'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../../../core/const/LoginConst'
import RichEditorInputForm from '../../../../core/utils/RichEditorInputForm'
import DropDownAreaForm from '../../../../core/utils/wizard/DropDownAreaForm'
import SearchSelectFilterApotek from '../../../../components/grid/actions/SearchSelectFilterApotek'

import { Col, Row } from 'reactstrap'

import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

const inputStyle = {
    height: '100px',
    width: '100%'
}

class Form extends Component
{
    constructor(props) {
        super(props)
        Object.assign(this, TextInputForm)
        Object.assign(this, TextAreaForm)
        Object.assign(this, DropDownForm)
        Object.assign(this, DropDownFormApotek)
        Object.assign(this, RadioInputForm)
        Object.assign(this, DatePickerForm)
        Object.assign(this, ImageUploadForm)
        Object.assign(this, InputBox)
        Object.assign(this, StrNoInputForm)
        Object.assign(this, SwitchForm)
        Object.assign(this, TextInputPriceForm)

        const isSuperadmin = RoleHelper.isSuperadmin(props.dataLogin)
        const isAdminSPV = RoleHelper.isAdminSPV(props.dataLogin, this.isOwner(props.currentHospital))
        this.isAdminSPV = isAdminSPV ? true : false

        this.state = {
            activate: null,
            confirmation: null,
            name: "",
            title: "dr.",
            telephone: "",
            emailTemp: "",
            id_doctor_specialist: "",
            educations: [],
            experiences: [],
            medical_actions: [],
            datas: [],
            eduExpSubmit: false,
            str_no: "",
            str_no_expired: "",
            sip_no_expired: "",
            estimation_time: 0,
            can_book: "",
            address: "",
            status: true,
            isEdit: false,
            postStatus: false,
            loading: false,
            prefixOptions: [],
            prefixValue: "",
            estimationOptions: Config.estimationOptions,
            specialistOption: [],
            foto: "",
            startDate:'',
            endDate:'',
            startDateVC:'',
            endDateVC:'',
            price_chat: 0,
            percentage: 0,
            special_price: 0,
            chatPriceSpecial: 0, 
            price_chat_special: 0,
            custom_special_price: false,
            vcPriceBase: 0,
            vcPrice: 0,
            vcPriceSpecial: 0,
            vcPercentage: 0,
            vcPriceSpecialCustom: 0,
            vcCustomSpecialPrice: false,
            vcDateEdit: false,
            notifChat: true,
            notifVC: true,
            Form: FormBuilder.group({
                id: [""],
                name: ["", Validators.required],
                gender: ["M"],
                dob: [""],
                email: [""],
                title: [""],
                telephone: [""],
                id_doctor_specialist: ["", Validators.required],
                educations: [""],
                experiences: [""],
                photo: [""],
                str_no: ["", Validators.required],
                str_no_expired:[""],
                sip_no_expired:[""],
                sip_no: [""],
                estimation_time: [0],
                can_book: [false],
                order_from_chat: [false, Validators.required],
                address: [""],
                profile: [""],
                apotek: [""],
                propinsi: [""],
                kota: [""],
                kecamatan: [""],
                propinsi_id: [0],
                kota_id:[0],
                kecamatan_id:[0],
                status: [0],
                chat_status: [false],
                show_in_apps: [false],
                video_call: [false],
                chat_type: [Config.chatType[0].value],
                chat_type_freelance: [Config.freelanceType[0].value],
                graduation_date: [""],
                price: [""],
                priceNakes: [""],
                special_price: [""],
                price_base: [""],
                price_special: [""],
                percentage: [""],
                vc_price_base: [""],
                vc_price: [""],
                vc_price_special: [""],
                vc_percentage: [""],
                chat_prices:{
                    price: 0,
                    price_base: 0,
                    price_special: 0,
                    price_special_enddate: '',
                    price_special_startdate: ''
                },
                videocall_prices:{
                    price: 0,
                    price_base: 0,
                    price_special: 0,
                    price_special_enddate: '',
                    price_special_startdate: ''
                }
            }),
            file: [],

            propinsiOpt: [],
            kotaOpt: [],
            kecamatanOpt: [],

            toggleMoreField: false,
            modalShow: false,
            confirmDelete: false,
            currentAdditionalValue: '',
            currentAdditionalIndex: 0,
            editEducation: false,
            editExperience: false,
            additionalMode: 'education',
            id: 0,
            isModalOpen: false,
            isModalConfirmOpen: false,
            universitas: '',
            jurusan: '',
            tahun_lulus: '',
            praktek: '',
            kota: '',
            bulan_tahun_mulai: '',
            bulan_tahun_selesai: '',
            expPresent: false,
            eduExpId: null,
            ModalActionSelected: null,
            listApotek: [],
            listApotekPath: '',
            keyword: '',
            selectedApotek: '',
            openModalVerify: false,
            emailType: null,
            emailAddress : null,
            ModalData: null,
            ModalDataConfirm: null,
            medicalActionsTmpValue: '',
            isModalImageOpen: false,
        }
    
        this.TextInputFormView = this.TextInputFormView.bind(this)
        this.TextAreaFormView = this.TextAreaFormView.bind(this)
        this.DropDownFormView = this.DropDownFormView.bind(this)
        this.TextInputFormDoctorView = this.TextInputFormDoctorView.bind(this)
        this.SwitchFormView = this.SwitchFormView.bind(this)
        this.RadioInputFormView = this.RadioInputFormView.bind(this)
        this.DatePickerFormView = this.DatePickerFormView.bind(this)
        Object.assign(this, DoctorFormHandler)
        Object.assign(this, RichEditorInputForm)
        this.RichEditorInputFormView = this.RichEditorInputFormView.bind(this)

        this.handleSubmit = this.handleSubmit.bind(this)
        this.valueBuilder = this.valueBuilder.bind(this)
        this.handleDateRangePicker = this.handleDateRangePicker.bind(this)

        this.handleAdditional = this.handleAdditional.bind(this)
        this.handleAdditionalChange = this.handleAdditionalChange.bind(this)
        this.handleAdditionalEdit = this.handleAdditionalEdit.bind(this)
        this.handleAdditionalDelete = this.handleAdditionalDelete.bind(this)

        this.handleUpload = this.handleUpload.bind(this)
        this.handleShow = this.handleShow.bind(this)
        this.handleConfirmDeleteClose = this.handleConfirmDeleteClose.bind(this)
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this)
        this.fetchListApotek = this.fetchListApotek.bind(this)
        this.fetchListTitle = this.fetchListTitle.bind(this)

        // global modal
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleOpenModalConfirm = this.handleOpenModalConfirm.bind(this)
        this.handleCloseModalConfirm = this.handleCloseModalConfirm.bind(this)
        this.handleSubmitAdditional = this.handleSubmitAdditional.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleResendEmail = this.handleResendEmail.bind(this)
        this.handleSubmitModalEmail = this.handleSubmitModalEmail.bind(this)
        this.disApotekSelect = this.disApotekSelect.bind(this)
        this.validationEnd = this.validationEnd.bind(this)
        this.handleChangeChat = this.handleChangeChat.bind(this)
        this.customSpecialPrice = this.customSpecialPrice.bind(this)

        Object.assign(this, DropDownAreaForm)
        this.DropDownAreaFormView = this.DropDownAreaFormView.bind(this)

        this.isSuperadmin = isSuperadmin

        this.buildForm()

    }

    isOwner = (newCurrentHosp) => {
        const { dataLogin } = this.props

        if (dataLogin) {
            const hospitalSelected = dataLogin.hospitals.length > 0
                ? dataLogin.hospitals.find(option => {
                    return String(option.id) === String(newCurrentHosp)
                }) : null

            return !_.isEmpty(hospitalSelected) ? hospitalSelected.owner : false
        }

        return false
    }

    useVideoCall = (newCurrentHosp) => {
        const { dataLogin } = this.props

        if (dataLogin) {
            if (dataLogin.superadmin) return true;

            const hospitalSelected = dataLogin.hospitals.length > 0
                ? dataLogin.hospitals.find(option => {
                    return String(option.id) === String(newCurrentHosp)
                }) : null

            return !_.isEmpty(hospitalSelected) ? hospitalSelected.video_call : false
        }

        return false
    }

    onToggleMore(){
        this.setState({
            toggleMoreField: !this.state.toggleMoreField
        })
    }

    componentDidMount() {
        this.fetchListApotek()
        this.valueBuilder()
        this.fetchListTitle()
    }

    
    handleApply = (event, picker) => {
        this.setState({startDate: picker.startDate ? picker.startDate.format('YYYY-MM-DD'): this.state.datas.chat_prices.price_special_startdate })
        this.setState({endDate: picker.endDate ? picker.endDate.format('YYYY-MM-DD') : this.state.datas.chat_prices.price_special_enddate })
        var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
        var firstDate = new Date(picker.startDate.format('YYYY/MM/DD'));
        var secondDate = new Date(picker.endDate.format('YYYY/MM/DD'));
        var diffDays = Math.round(Math.round((secondDate.getTime() - firstDate.getTime()) / (oneDay)));
        var dates = diffDays < 31 ? false : true;
        this.setState({errorDate: dates})
        };

    handleApplyVC = (event, picker) => {
        this.setState({startDateVC: picker.startDate ? picker.startDate.format('YYYY-MM-DD') : this.state.datas.videocall_prices.price_special_startdate })
        this.setState({endDateVC: picker.endDate ? picker.endDate.format('YYYY-MM-DD') :  this.state.datas.videocall_prices.price_special_enddate })
        var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
        var firstDate = new Date(picker.startDate.format('YYYY/MM/DD'));
        var secondDate = new Date(picker.endDate.format('YYYY/MM/DD'));
        var diffDays = Math.round(Math.round((secondDate.getTime() - firstDate.getTime()) / (oneDay)));
        var dates = diffDays < 31 ? false : true;
        this.setState({errorDate: dates})
        };

    // componentWillUpdate(nextProps, nextState) {
    //     const { listApotek } = nextState

    //     if (listApotek.length > 0) {
    //         const chatStatus = this.state.Form.value.chat_status
    //         const orderChat = this.state.Form.value.order_from_chat

    //         if (chatStatus && orderChat) {
    //             this.state.Form.get('apotek').disable()
    //             this.state.Form.patchValue({ apotek: '' })
    //         }
    //     }
    // }

    renderActivationRow(active){
        if(active){
            return(
                <div className="row-status">
                    <span className="title">STATUS VERIFIKASI</span>
                    <div className="wrap-info">
                        <span className="info done">Email Sudah Terverifikasi</span>
                    </div>
                </div>
            )
        }
        
        return (
            <div className="row-status">
                <span className="title">STATUS VERIFIKASI</span>
                <div className="wrap-info">
                    <span className="info waiting">Email Belum Terverifikasi</span>
                    <button
                        type="button"
                        className="resend-email"
                        onClick={(e) => this.handleResendEmail(e, 'verify_email')}
                    >
                        Kirim Ulang
                        </button>
                </div>
            </div>
        )
    }

    submitConfirm = (e) => {
        e.preventDefault();
        this.handleOpenModalConfirm();
    }

    checkboxChat = (e) => {
        this.setState({
            custom_special_price: e.target.checked,
            price_chat_special: e.target.checked === false? 0 : this.price_chat_special
        })
    }
    
    checkboxVC = (e) => {
        this.setState({
            vcCustomSpecialPrice: e.target.checked,
            vc_price_special: e.target.checked === false? 0 : this.vcPriceSpecial
        })
    }

    checkboxDateVC = (e) => {
        this.setState({
            vcDateEdit: e.target.checked
        })
    }
    receiveImg = (val) => {
        this.state.Form.patchValue({ photo: val })
        this.setState({
            foto: val
        })
    }

    handleApotek(value) {
        this.setState({
            selectedApotek: value
        })
        this.state.Form.patchValue({ apotek: value.value })
    }

    render() {
        const {
            postStatus,
            educations,
            experiences,
            medical_actions,
            currentAdditionalValue,
            emailTemp,
            Form,
            can_book,
            file,
            toggleMoreField,
            ModalActionSelected,
            loading,
            universitas,
            jurusan,
            tahun_lulus: tahunLulus,
            praktek,
            kota,
            bulan_tahun_mulai: bulanTahunMulai,
            bulan_tahun_selesai: bulanTahunSelesai,
            eduExpSubmit,
            expPresent,
            medicalActionsTmpValue,
            eduExpId,
            listApotek,
            listApotekPath,
            listApotekOld,
            price_chat,
            percentage,
            special_price,
            custom_special_price,
            vcPriceBase,
            vcPrice,
            vcPercentage,
            vcPriceSpecial,
            vcCustomSpecialPrice,
            vcDateEdit,
            datas,
            notifChat,
            notifVC,
        } = this.state
        
        const {
            match,
            dataLogin,
            history,
            currentHospital,
        } = this.props
        
        const dataAll = this.state.datas

        const now = dateFns.format(new Date(), 'DD-MM-YYYY')
        const dataChat = dataAll?.chat_prices
        const cts3 = dataChat?.price_special_startdate.substring(0,4);
        const cts2 = dataChat?.price_special_startdate.substring(6,7);
        const cts1 = dataChat?.price_special_startdate.substring(8,10);

        const cte3 = dataChat?.price_special_enddate.substring(0,4);
        const cte2 = dataChat?.price_special_enddate.substring(6,7);
        const cte1 = dataChat?.price_special_enddate.substring(8,10);
        const chat_startdate = dataChat?.price_special_startdate ? cts1+'/'+cts2+'/'+cts3 : now
        const chat_enddate = dataChat?.price_special_enddate ? cte1+'/'+cte2+'/'+cte3 : now

        const dataVC = dataAll?.videocall_prices
        const vcts3 = dataVC?.price_special_startdate.substring(0,4);
        const vcts2 = dataVC?.price_special_startdate.substring(6,7);
        const vcts1 = dataVC?.price_special_startdate.substring(8,10);

        const vcte3 = dataVC?.price_special_enddate.substring(0,4);
        const vcte2 = dataVC?.price_special_enddate.substring(5,7);
        const vcte1 = dataVC?.price_special_enddate.substring(8,10);
        const vc_startdate = dataVC?.price_special_startdate ? vcts1+'/'+vcts2+'/'+vcts3 : now
        const vc_enddate = dataVC?.price_special_enddate ? vcte1+'/'+vcte2+'/'+vcte3 : now

        const specialistFaskesUser = this.state.specialistOption.filter((item) => item.value === dataAll.id_doctor_specialist);
        const specialistFaskesUserFix = specialistFaskesUser.map(i=> i.label)
        const params = match.params
        const willContinue = params.continue === 'continue' ? true : false
        const idDoctor = params.id ? params.id : null
        const url = idDoctor ? "/doctor/schedule/" + idDoctor : "/doctor/list"
        const isDoctor = RoleHelper.isDoctor(dataLogin, currentHospital)
        //doctor only can edit himself
        if (isDoctor && !this.isOwner(currentHospital)) {
            if (Number(idDoctor) !== Number(isDoctor.id_doctor_hospital)) {
                return (<Redirect to={"/doctor/list"} />)
            }
        }

        //after submit redirect
        if (postStatus) {
            if (!can_book) {
                return <Redirect to="/doctor/list" />
            } else if (willContinue) {
                return (<Redirect to={url} />)
            } else {
                return (<Redirect to="/doctor/list" />)
            }
        }

        let dateMulai = bulanTahunMulai
        let dateSelesai = bulanTahunSelesai
        if (typeof bulanTahunMulai === 'string') {
            const btm = bulanTahunMulai.split('-')
            dateMulai = `${btm[1]}-${btm[0]}`
        }
        if (typeof bulanTahunSelesai === 'string') {
            const bts = bulanTahunSelesai.split('-')
            dateSelesai = `${bts[1]}-${bts[0]}`
        }

        this.InputBox = this.InputBoxShowHide({
            title: "Additional Information",
            body: <textarea 
                    style={inputStyle} 
                    onBlur={this.handleAdditional}
                    defaultValue={currentAdditionalValue}>
                </textarea>,
        })

        this.ConfirmBox = this.ConfirmDeleteShowHide({
            title: "Confirmation",
            body: "Are you sure want to remove this data? This data will be removed from the system",
        })
        
        return(
            <div className={dataLogin.user_type === 'a' ? 'wizard-form-user-faskes doctor-form': 'wizard-form doctor-form'}>
                {this.InputBox}
                {this.ConfirmBox}
                {/** start form */}
                <FieldGroup
                control={Form}
                strict={false}
                render={({ get, invalid }) => {
                    const hideChatType = !Form.controls.chat_status.value
                    const hideCanBook = !Form.controls.show_in_apps.value
                    const hideCanVidioCall = !Form.controls.video_call.value
                    const orderChat = Form.controls.order_from_chat.value
                    const showInfo = Form.controls.show_in_apps.value
                    const canBook = Form.controls.can_book.value
                    const typeFreelace = typeof Form.controls.chat_type.value === 'object'
                        ? Form.controls.chat_type.value.value : Form.controls.chat_type.value
                    const showTypeFreelace = typeFreelace === 'Freelance'
                    const isAddInfoValid = showInfo
                        ? (!_.isEmpty(Form.controls.profile.value)
                            && !_.isEmpty(educations)
                            && !_.isEmpty(experiences))
                        : true
                    const isCanBookValid = canBook
                        ? !_.isEmpty(Form.controls.estimation_time.value)
                        : true
                    const chatStatusValid = !hideChatType
                        ? (!_.isEmpty(Form.controls.email.value))
                        : true
                    const orderChatValid = !hideChatType && orderChat
                        ? (!_.isEmpty(Form.controls.sip_no.value))
                        : true


                return(
                    <form onSubmit={() => this.handleSubmit} className="form-horizontal">
                        {
                            dataLogin.user_type === 'a' ?
                                <div className="row" style={{ paddingTop: '80px'}}>
                                    <div className="col-lg-6">
                                        <img src={dataAll.photo} alt={`${dataAll.name}`} width={135} height={135} style={{ borderRadius:'10px', paddingBottom: '5px' }}/>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div>
                                                    <label className="custom-label">Nama Lengkap</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-8">
                                                <div className="input-name">
                                                {dataAll.title +' '+ dataAll.name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <label className="custom-label">Jenis Kelamin</label>
                                            </div>
                                            <div className="col-lg-8">
                                            <div className="field-name-doctor">
                                                <div className="input-name">
                                                {dataAll.gender === 'M' ? 'Laki - laki' : 'Perempuan'}
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <label className="custom-label">Tanggal Lahir</label>
                                            </div>
                                            <div className="col-lg-8">
                                            <div className="field-name-doctor">
                                                <div className="input-name">
                                                {!dataAll.dob ? '-' : dataAll.dob}
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <label className="custom-label">Email NaKes</label>
                                            </div>
                                            <div className="col-lg-8">
                                            <div className="field-name-doctor">
                                                <div className="input-name">
                                                {dataAll.email}
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className={ willContinue ?  "form-line" : "form-line edit-form-doctor"} >
                                    <ImageUploadFormWithCrop
                                        onUpload={ this.handleUpload }
                                        desc="Tambah foto Nakes"
                                        icon={ iconPlus }
                                        maxsize="2500000"
                                        image={ file }
                                        current={ this.state.Form.controls.photo }
                                        receiveImg={this.receiveImg}
                                        dataLogin={dataLogin}
                                    />
                                </div>
                                
                        }
                        {
                            dataLogin.user_type === 'a' ?
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label className="custom-label">No STR Nakes
                                            <span className="required">*</span></label>
                                        </div>
                                        <div className="col-lg-8">
                                        <div className="field-name-doctor">
                                            <div className="input-name">
                                                {dataAll.str_no}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label className="custom-label">No Telepon</label>
                                        </div>
                                        <div className="col-lg-8">
                                        <div className="field-name-doctor">
                                            <div className="input-name">
                                            {dataAll.telephone}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                
                                :
                                this.TextStrNo 
                        }
                        {
                            dataLogin.user_type === 'a' ? 
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label className="custom-label">STR No Expired
                                            <span className="required">*</span></label>
                                        </div>
                                        <div className="col-lg-8">
                                        <div className="field-name-doctor">
                                            <div className="input-name">
                                            {dataAll.str_no_expired}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label className="custom-label">Alamat</label>
                                        </div>
                                        <div className="col-lg-8">
                                        <div className="field-name-doctor">
                                            <div className="input-name">
                                            {dataAll.address}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                :
                                this.strNoExpiredField 
                        }
                        {
                            dataLogin.user_type === 'a' ? 
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label className="custom-label">No SIP NaKes
                                            <span className="required">*</span></label>
                                        </div>
                                        <div className="col-lg-8">
                                        <div className="field-name-doctor">
                                            <div className="input-name">
                                            {dataAll.sip_no}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label className="custom-label">Propinsi</label>
                                        </div>
                                        <div className="col-lg-8">
                                        <div className="field-name-doctor">
                                            <div className="input-name">
                                            {this.state.propinsi}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                :
                                this.TextSip 
                        }
                        {
                            dataLogin.user_type === 'a' ? 
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label className="custom-label">SIP No Expired
                                            <span className="required">*</span></label>
                                        </div>
                                        <div className="col-lg-8">
                                        <div className="field-name-doctor">
                                            <div className="input-name">
                                            {dataAll.sip_no_expired}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label className="custom-label">Kota</label>
                                        </div>
                                        <div className="col-lg-8">
                                        <div className="field-name-doctor">
                                            <div className="input-name">
                                            {this.state.kota}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                :
                                this.sipNoExpiredField 
                        }
                        {!hideChatType && orderChat && _.isEmpty(Form.controls.sip_no.value)
                            ? <span className="custom-error control">
                                Data harus diisi
                            </span>
                            : null}
                            
                        {
                                dataLogin.user_type === 'a' ? 
                                ''
                                :
                                <div className="form-line container-name-doctor">
                                    <label className="custom-label">Nama Lengkap NaKes</label>
                                    <div className="field-name-doctor">
                                        <div className="input-prefix">
                                            {this.PrefixDropdown}
                                        </div>
                                        <div className="input-name">
                                            {this.TextName}
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                dataLogin.user_type === 'a' ? 
                                ''
                                    :
                                    this.genderField 
                            }
                        
                        {
                        dataLogin.user_type === 'a' ? 
                                ''
                                    :
                                this.dobField
                        }
                        {
                        dataLogin.user_type === 'a' ? 
                                ''
                                :
                                this.TextEmailDokter
                        }
                        {!hideChatType && _.isEmpty(Form.controls.email.value)
                            ? <span className="custom-error control">
                                Data harus diisi
                            </span>
                            : null}
                            
                        {
                        dataLogin.user_type === 'a' ? 
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label className="custom-label">Specialist <span className="required">*</span></label>
                                        </div>
                                        <div className="col-lg-8">
                                        <div className="field-name-doctor">
                                            <div className="input-name">
                                            {specialistFaskesUserFix}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label className="custom-label">Kecamatan</label>
                                        </div>
                                        <div className="col-lg-8">
                                        <div className="field-name-doctor">
                                            <div className="input-name">
                                            {this.state.kecamatan}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                :
                            <div className="specialist">
                                {this.SpecialistDropdown}
                            </div>        
                        }
                        
                        { 
                        dataLogin.user_type === 'a' ? 
                            ''
                            :
                            this.TextTelephone 
                        }
                        { 
                        dataLogin.user_type === 'a' ? 
                            ''
                            :
                            this.TextAddress }
                        { 
                        dataLogin.user_type === 'a' ? 
                            ''
                            :
                            this.DropDownPropinsi }
                        { 
                        dataLogin.user_type === 'a' ? 
                            ''
                            :
                            this.DropDownKota }
                        { 
                        dataLogin.user_type === 'a' ? 
                            ''
                            :
                            this.DropDownKecamatan }

                        { 
                        dataLogin.user_type === 'a' ? 
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label className="custom-label">Tanggal Kelulusan</label>
                                        </div>
                                        <div className="col-lg-8">
                                        <div className="field-name-doctor">
                                            <div className="input-name">
                                            {dataAll.graduation_date}
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <label className="custom-label">Medical Actions</label>
                                        </div>
                                        <div className="col-lg-8">
                                        <div className="field-name-doctor">
                                            <div className="input-name" style={{marginTop: '8px'}}>
                                            {medical_actions.map((item) => <li>{item}</li>)}
                                            </div>
                                        </div>
                                        </div>

                                        <div className="col-lg-12">
                                        <br/>
                                        <label className="custom-label">Pendidikan</label>
                                        {
                                        educations.map((i) =>
                                            <li>{i.universitas} - {i.jurusan} ({i.tahun_lulus}) </li>
                                        )}
                                        </div>
                                        
                                        <div className="col-lg-12">
                                        <br/>
                                        <label className="custom-label">Pengalaman</label>
                                        {
                                        experiences.map((i) =>
                                            <li>{i.praktek} - {i.kota}, {i.bulan_tahun_mulai} - {i.bulan_tahun_selesai}</li>
                                        )}
                                        </div>

                                    </div>
                                    <br />
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label className="custom-label">Profile</label>
                                        </div>
                                        <div className="col-lg-8">
                                        <div className="field-name-doctor">
                                            <div className="input-name" >
                                            <div
                                            dangerouslySetInnerHTML={{
                                                __html: dataAll.profile
                                            }}
                                            />
                                            </div>
                                        </div>
                                        </div>

                                            <div className="col-lg-4">
                                                <label className="custom-label">Harga Nakes</label>
                                            </div>
                                            <div className="col-lg-8">
                                            <div className="field-name-doctor">
                                                <div className="input-name" >
                                                {dataAll.priceNakes}
                                                </div>
                                            </div>
                                            </div>
                                            
                                            <div className="col-lg-4">
                                                <label className="custom-label">Harga Spesial Nakes</label>
                                            </div>
                                            <div className="col-lg-8">
                                            <div className="field-name-doctor">
                                                <div className="input-name" >
                                                {dataAll.special_price}
                                                </div>
                                            </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <label className="custom-label">Estimasi Penanganan</label>
                                            </div>
                                            <div className="col-lg-8">
                                                <div className="field-name-doctor">
                                                    <div className="input-name" >
                                                    30 Menit
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <label className="custom-label">Assign Apotek</label>
                                            </div>
                                            <div className="col-lg-8">
                                                <div className="field-name-doctor">
                                                    <div className="input-name" >
                                                    {this.state.apotek_name}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="wrap-doctor-status">
                                                {
                                                    this.state.activate !== null && this.state.confirmation !== null? 
                                                    <div className="status-block">
                                                        {emailTemp
                                                            ? this.renderActivationRow(this.state.activate)
                                                            : null}
                                                    </div> :
                                                    null
                                                }
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            :
                            this.graduationDateField }
                        { 
                        dataLogin.user_type === 'a' ? 
                            ''
                            :
                            <div className="wrap-input-additional">
                            <AdditionalInputForm 
                                        icon={ iconPlus } 
                                        title="Medical Actions" 
                                        label="Medical Actions"
                                        mode="medical_actions"
                                        content={medical_actions} 
                                        onClick={ () => this.handleOpenModal('medical_actions') }
                                        onChange={this.handleAdditionalChange}
                                        onDelete={this.handleConfirmDelete}
                                        onEdit={this.handleAdditionalEdit}/>
                                    {showInfo && _.isEmpty(medical_actions) ?
                                        <span className="custom-error additional">Data harus diisi</span>
                                        : null}
                        </div>
                         }
                        
                        { 
                        dataLogin.user_type === 'a' ? 
                            ''
                            :
                            this.doctorPriceField }
                        { 
                        dataLogin.user_type === 'a' ? 
                            ''
                            :
                            this.doctorSpecialPriceField }
                        {
                            this.isAdminSPV ? 
                            <div>
                                {
                                datas.id_hospital === Number(process.env.REACT_APP_HOSPITAL_ID) &&
                                <div>
                                { this.SwitchChat }
                                <div
                                    className={hideChatType
                                        ? 'toggle-wrapper hide'
                                        : 'toggle-wrapper'
                                    }>
                                    {this.SwitchOrderChat}
                                    {this.ChatType}
                                    {showTypeFreelace ? this.FreelanceType : null}
                                    <div className="form-line">
                                        <label
                                            id="price_base"
                                            className="form-control-label"
                                            htmlFor="dokter-edit"
                                        >
                                            Base Price Chat
                                        <span className="required">*</span>
                                        </label>
                                        <div className="form-group disabled">
                                            <input
                                                id="dokter-edit"
                                                className="form-control"
                                                placeholder="Base Price Chat"
                                                disabled
                                                value={dataAll.chat_prices.price_base}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-line">
                                        <label
                                            id="price_chat"
                                            className="form-control-label"
                                            htmlFor="dokter-edit"
                                        >
                                            Harga Chat
                                        <span className="required">*</span>
                                        </label>
                                        <div className="form-group">
                                            <input
                                                id="dokter-edit"
                                                type="number"
                                                className="form-control"
                                                placeholder="Harga Chat"
                                                value={price_chat}
                                                onChange={(e) => this.handleChangeChat('price_chat', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    {
                                        notifChat === false ?
                                        <div className="form-line">
                                        <span style={{color: 'red', fontSize: '12px'}}>
                                            Harga Chat Harus Lebih Besar Dari Harga Base Price Chat
                                            </span>
                                        </div>
                                        :
                                        ''
                                    }
                                    {
                                        this.state.custom_special_price === false ?
                                    <div className="form-line">
                                        <label
                                            id="percentagechat"
                                            className="form-control-label"
                                            htmlFor="dokter-edit"
                                        >
                                            Persentase Diskon Chat
                                        <span className="required">*</span>
                                        </label>
                                        <div className="form-group">
                                            <Row>
                                                <Col md={10}>
                                                    <input
                                                        id="percentagechat_"
                                                        className="form-control"
                                                        placeholder="Persentase Diskon Chat"
                                                        value={percentage}
                                                        type="number"
                                                        onChange={(e) => this.handleChangeChat('percentage', e.target.value)}
                                                    />
                                                </Col>
                                                <Col md={2}>
                                                    <div class="input-group-append" style={{ height: '55px', marginLeft: '15px'}}>
                                                        <span class="input-group-text" id="basic-addon2">%</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    : '' }
                                    <Row>
                                        <Col md={1}>
                                            <input type="checkbox"
                                                onChange={this.checkboxChat}
                                                value={custom_special_price === true ? 'checked' : ''} 
                                            />
                                        </Col>
                                        <Col md={11}>
                                            <label
                                                id="label-dokter-edit"
                                                className="form-control-label"
                                                htmlFor="dokter-edit"
                                            >
                                                Custom Harga Diskon
                                            </label>
                                        </Col>
                                    </Row>
                                    {
                                        this.state.custom_special_price === false ?
                                        <div className="form-line">
                                            <label
                                                id="label-dokter-edit"
                                                className="form-control-label"
                                                htmlFor="dokter-edit"
                                            >
                                                Harga Setelah Diskon Chat
                                            <span className="required">*</span>
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    id="special_price"
                                                    className="form-control"
                                                    placeholder="Harga Setelah Diskon"
                                                    value={special_price}
                                                    disabled
                                                    onChange={(e) => this.handleChangeChat('price_chat', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                     : 
                                    <div className="form-line">
                                        <label
                                            id="label-dokter-edit"
                                            className="form-control-label"
                                            htmlFor="dokter-edit"
                                        >
                                            Harga Setelah Diskon Chat
                                        <span className="required">*</span>
                                        </label>
                                        <div className="form-group">
                                            <input
                                                id="special_price"
                                                className="form-control"
                                                type="number"
                                                placeholder="Harga Setelah Diskon"
                                                onChange={(e) => this.handleChangeChat('price_chat_special', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    }
                                    
                                    
                                    <div className="form-line">
                                        <label
                                            id="label-dokter-edit"
                                            className="form-control-label"
                                            htmlFor="dokter-edit"
                                        >
                                            Tanggal Diskon Chat
                                        </label>
                                        <div className="form-group">
                                            <DateRangePicker initialSettings={{ showDropdowns: true, startDate: chat_startdate, endDate: chat_enddate }} onApply={this.handleApply} >
                                                <input type="text" className="form-control form-control-success datepickernew"/>
                                            </DateRangePicker>
                                        </div>
                                    </div>
                                    <div className="hor-line" />
                                </div>
                                </div>
                                }
                                
                                {this.SwitchShowInApps}
                                <div
                                    className={hideCanBook
                                        ? 'toggle-wrapper hide'
                                        : 'toggle-wrapper'
                                    }>
                                    {this.useVideoCall(currentHospital)
                                        ? this.SwitchVideoCall
                                        : null}
                                <div
                                    className={hideCanVidioCall
                                        ? 'toggle-wrapper hide'
                                        : 'toggle-wrapper'
                                    }>
                                    
                                    <div className="form-line">
                                        <label
                                            id="vc_price_base"
                                            className="form-control-label"
                                            htmlFor="dokter-edit"
                                        >
                                            Base Price Video Call
                                        <span className="required">*</span>
                                        </label>
                                        <div className="form-group disabled">
                                            <input
                                                id="dokter-edit"
                                                className="form-control"
                                                placeholder="Base Price Video Call"
                                                disabled
                                                value={vcPriceBase}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-line">
                                        <label
                                            id="price_chat"
                                            className="form-control-label"
                                            htmlFor="dokter-edit"
                                        >
                                            Harga Video Call
                                        <span className="required">*</span>
                                        </label>
                                        <div className="form-group">
                                            <input
                                                id="dokter-edit"
                                                type="number"
                                                className="form-control"
                                                placeholder="Harga Video Call"
                                                value={vcPrice}
                                                onChange={(e) => this.handleChangeVideoCall('vcPrice', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    
                                    {
                                        notifVC === false ?
                                        <div className="form-line">
                                        <span style={{color: 'red', fontSize: '11px'}}>
                                            Harga Video Call Harus Lebih Besar Dari Harga Base Price Video Call
                                            </span>
                                        </div>
                                        :
                                        ''
                                    }
                                    {
                                        this.state.vcCustomSpecialPrice === false ?
                                    <div className="form-line">
                                        <label
                                            id="percentagevc"
                                            className="form-control-label"
                                            htmlFor="dokter-edit"
                                        >
                                            Persentase Diskon Video Call
                                        <span className="required">*</span>
                                        </label>
                                        <div className="form-group">
                                            <Row>
                                                <Col md={10}>
                                                    <input
                                                        id="percentagevc_"
                                                        className="form-control"
                                                        placeholder="Persentase Diskon Video Call"
                                                        value={vcPercentage}
                                                        type="number"
                                                        onChange={(e) => this.handleChangeVideoCall('vcPercentage', e.target.value)}
                                                    />
                                                </Col>
                                                <Col md={2}>
                                                    <div class="input-group-append" style={{ height: '55px', marginLeft: '15px'}}>
                                                        <span class="input-group-text" id="basic-addon2">%</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    : ''}
                                    <Row>
                                        <Col md={1}>
                                            <input type="checkbox"
                                                onChange={this.checkboxVC}
                                                value={vcCustomSpecialPrice === true ? 'checked' : ''} 
                                            />
                                        </Col>
                                        <Col md={11}>
                                            <label
                                                id="label-dokter-edit"
                                                className="form-control-label"
                                                htmlFor="dokter-edit"
                                            >
                                                Custom Harga Diskon
                                            </label>
                                        </Col>
                                    </Row>
                                    {
                                        this.state.vcCustomSpecialPrice === false ?
                                        <div className="form-line">
                                            <label
                                                id="label-dokter-edit"
                                                className="form-control-label"
                                                htmlFor="dokter-edit"
                                            >
                                                Harga Setelah Diskon Video Call
                                            <span className="required">*</span>
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    id="vc_special_price"
                                                    className="form-control"
                                                    placeholder="Harga Setelah Diskon Video Call"
                                                    value={vcPriceSpecial}
                                                    disabled
                                                    onChange={(e) => this.handleChangeVideoCall('vcPriceSpecial', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                     : 
                                    <div className="form-line">
                                        <label
                                            id="label-dokter-edit"
                                            className="form-control-label"
                                            htmlFor="dokter-edit"
                                        >
                                            Harga Setelah Diskon Video Call
                                        <span className="required">*</span>
                                        </label>
                                        <div className="form-group">
                                            <input
                                                id="special_price"
                                                type="number"
                                                className="form-control"
                                                placeholder="Harga Setelah Diskon Video Call"
                                                onChange={(e) => this.handleChangeVideoCall('vcPriceSpecialCustom', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    }
                                    
                                    
                                    <div className="form-line">
                                        <Row>
                                            <Col md={1}>
                                                <input type="checkbox"
                                                    onChange={this.checkboxDateVC}
                                                    value={vcDateEdit === true ? 'checked' : ''} 
                                                />
                                            </Col>
                                            <Col md={11}>
                                                <label
                                                    id="label-dokter-edit"
                                                    className="form-control-label"
                                                    htmlFor="dokter-edit"
                                                >
                                                    VideoCall Date Edit
                                                </label>
                                            </Col>
                                        </Row>
                                        {
                                            this.state.vcDateEdit === false ?
                                        <div className="form-group">
                                                <input
                                                    id="vc_date"
                                                    className="form-control"
                                                    placeholder="VideoCall Date"
                                                    value={vc_startdate +" - "+ vc_enddate}
                                                    disabled
                                                />
                                            </div>
                                            :
                                        <div className="form-group">
                                            <DateRangePicker initialSettings={{ showDropdowns: true, startDate: vc_startdate, endDate: vc_enddate }} onApply={this.handleApplyVC} >
                                                <input type="text" className="form-control form-control-success datepickernew"/>
                                            </DateRangePicker>
                                        </div>
                                        
                                    }
                                    </div>
                                 </div>

                                    {this.SwitchCanBook}
                                    {
                                        dataLogin.user_type === 'a' ? '': 
                                        this.EstimationDropdown
                                    }
                                    {
                                        canBook && _.isEmpty(Form.controls.estimation_time.value) ?
                                            <span className="custom-error estimate">
                                                Data harus diisi
                                            </span>
                                            : null}
                                </div>
                            </div> : 
                            null
                        }
                        
                        {/* { 
                        dataLogin.user_type === 'a' ? 
                            <div className="row">
                                <div className="col-lg-4">
                                    <label className="custom-label">Estimasi Penanganan</label>
                                </div>
                                <div className="col-lg-8">
                                <div className="field-name-doctor">
                                    <div className="input-name" >
                                    30 Menit
                                    </div>
                                </div>
                                </div>
                            </div>
                            :
                            ''} */}
                        {/* {this.ApotekDropdown} */}
                        { 
                        dataLogin.user_type === 'a' ? 
                           ''
                            :
                            <div className="form-line">
                            <label htmlFor="apotek" className="form-control-label">
                            Assign Apotek (Optional)
                            </label>
                            <div className="form-group-wizard subscription">
                                <SearchSelectFilterApotek
                                    filterOpt={listApotek} 
                                    path={listApotekPath}
                                    ref={listApotek}
                                    key={listApotek}
                                    selectedOpt={!this.state.selectedApotek ? listApotekOld : this.state.selectedApotek}
                                    handlerChangeOpt={(type, value) => this.handleApotek(value)}
                                    />
                            </div>
                        </div> 
                        }
                          
                        <div
                            className={
                                (toggleMoreField || showInfo)
                                    ? "wrap-additional show-field"
                                    : "wrap-additional hide-field"
                            }
                        >
                            {
                        dataLogin.user_type === 'a' ? 
                            '':

                            <div id="toggle-button" onClick={this.onToggleMore.bind(this)}>
                                Informasi Tambahan
                                <img className="icon" src={ArrowDown} alt="icon arrow down" />
                            </div>
                            
                        }
                            {(toggleMoreField || showInfo)
                                ? <div className="hor-line add-info" />
                                : null}
                                
                                
                                {
                        dataLogin.user_type === 'a' ? 
                            ''
                            :
                            this.TextProfile}
                            <div className="wrap-input-additional">

                                {
                                    dataLogin?.user_type === 'a' ?
                                  ''
                                    :
                                    <AdditionalInputForm 
                                    icon={ iconPlus } 
                                    title="Pendidikan" 
                                    label="Tambah pendidikan"
                                    mode="education"
                                    content={educations} 
                                    onClick={ () => this.handleOpenModal('education') }
                                    onChange={this.handleAdditionalChange}
                                    onDelete={this.handleConfirmDelete}
                                    onEdit={this.handleAdditionalEdit}/>

                                }
                                
                                {showInfo && _.isEmpty(this.state.educations) ?
                                    <span className="custom-error additional">
                                        Data harus diisi
                                    </span>
                                    : null}
                                    {
                                        dataLogin?.user_type === 'a' ?
                                        ''
                                        :
                                        <AdditionalInputForm 
                                            icon={ iconPlus } 
                                            title="Pengalaman" 
                                            label="Tambah pengalaman"
                                            mode="experience"
                                            content={experiences} 
                                            onClick={ () => this.handleOpenModal('experience') }
                                            onChange={this.handleAdditionalChange}
                                            onDelete={this.handleConfirmDelete}
                                            onEdit={this.handleAdditionalEdit}/>
                                    }
                                {showInfo && _.isEmpty(this.state.experiences) ?
                                    <span className="custom-error additional">Data harus diisi</span>
                                    : null}
                            </div>
                            {(toggleMoreField || showInfo)
                                ? null
                                : <div className="hor-line doctor-status" />}
                            <div className="wrap-doctor-status">
                            {
                            dataLogin?.user_type === 'a' ?
                                        ''
                                        :
                                this.state.activate !== null && this.state.confirmation !== null? 
                                <div className="status-block">
                                    {emailTemp
                                        ? this.renderActivationRow(this.state.activate)
                                        : null}
                                </div> :
                                null
                            }
                            </div>
                        </div>
                       <SubmitButtonForm
                                invalid={
                                    invalid ||
                                    !isAddInfoValid ||
                                    !isCanBookValid ||
                                    !chatStatusValid ||
                                    !orderChatValid ||
                                    loading ||
                                    !notifChat ||
                                    !notifVC
                                }
                                submitText={
                                    loading
                                        ? 'Loading...'
                                        : (!willContinue || !canBook)
                                            ? 'Simpan'
                                            : 'Selanjutnya'
                                }
                                onSubmit={(e) => this.submitConfirm(e)}
                                onCancel={(e) => history.push('/doctor/list')}
                         />   
                    </form>
                )}}/>
                {/** end of form */}
                <MasterModal
                    className="master-modal"
                    isOpen={ this.state.isModalOpen }
                    handleClose={ this.handleCloseModal }
                    ModalData={ this.state.ModalData }
                    handleSubmit={ this.handleSubmitModalEmail }
                >
                    {
                        this.state.openModalVerify ? 
                        null :
                        <div className="master-modal-content custom edu-exp">
                            <div className="master-modal-body" style={{ margin: '0px' }}>
                                <div className="content">
                                    <h3>
                                        {
                                            ModalActionSelected === 'education' && `${ eduExpId !== null ? 'Ubah' : 'Tambah' } Pendidikan`
                                        }
                                        {
                                            ModalActionSelected === 'experience' && `${ eduExpId !== null ? 'Ubah' : 'Tambah' } Pengalaman`
                                        }
                                        {
                                            ModalActionSelected === 'medical_actions' && `${ eduExpId !== null ? 'Ubah' : 'Tambah' } Tindakan Medis`
                                        }
                                    </h3>
                                    {
                                      ModalActionSelected === 'education' &&
                                        <div>
                                            <input
                                                value={universitas || ''}
                                                onChange={e => this.handleInputChange('universitas', e.target.value)}
                                                placeholder="Nama Universitas"
                                                className="native-input" />
                                            {eduExpSubmit && universitas === ''
                                                ? <span className="error-info">Wajib diisi</span>
                                                : null}
                                            <input
                                                value={jurusan || ''}
                                                onChange={e => this.handleInputChange('jurusan', e.target.value)}
                                                placeholder="Nama Jurusan"
                                                className="native-input" />
                                            {eduExpSubmit && jurusan === ''
                                                ? <span className="error-info">Wajib diisi</span>
                                                : null}
                                            <input
                                                type="number"
                                                value={tahunLulus || ''}
                                                onChange={e => this.handleInputChange('tahun_lulus', e.target.value)}
                                                placeholder="Tahun Lulus"
                                                className="native-input" />
                                            {eduExpSubmit && tahunLulus === ''
                                                ? <span className="error-info">Wajib diisi</span>
                                                : null}
                                        </div>
                                    }
                                    {
                                        ModalActionSelected === 'experience' &&
                                            <div>
                                                <input
                                                    value={praktek || ''}
                                                    onChange={e => this.handleInputChange('praktek', e.target.value)}
                                                    placeholder="Nama Tempat Praktik"
                                                    className="native-input" />
                                                {eduExpSubmit && praktek === ''
                                                    ? <span className="error-info">Wajib diisi</span>
                                                    : null}
                                                <input
                                                    value={kota || ''}
                                                    onChange={e => this.handleInputChange('kota', e.target.value)}
                                                    placeholder="Kota"
                                                    className="native-input" />
                                                {eduExpSubmit && kota === ''
                                                    ? <span className="error-info">Wajib diisi</span>
                                                    : null}
                                                <Datetime
                                                    closeOnSelect
                                                    timeFormat={false}
                                                    dateFormat="MMMM YYYY"
                                                    initialViewMode="years"
                                                    className="month-year"
                                                    value={bulanTahunMulai
                                                        ? moment(new Date(dateMulai))
                                                        : ''}
                                                    isValidDate={currentDate => currentDate.isBefore(moment(new Date()))}
                                                    inputProps={{
                                                        placeholder: "Tahun Mulai",
                                                        onKeyDown: (e) => e.preventDefault(),
                                                        className: '',
                                                    }}
                                                    onChange={(d) => this.handleInputChange('bulan_tahun_mulai', d.format('MM-YYYY'))}
                                                />
                                                {!expPresent && (
                                                    <Datetime
                                                        closeOnSelect
                                                        timeFormat={false}
                                                        dateFormat="MMMM YYYY"
                                                        initialViewMode="years"
                                                        className="month-year"
                                                        value={bulanTahunSelesai
                                                            ? moment(new Date(dateSelesai))
                                                            : ''}
                                                        isValidDate={e => this.validationEnd(e, bulanTahunMulai)}
                                                        inputProps={{
                                                            placeholder: "Tahun Selesai",
                                                            onKeyDown: (e) => e.preventDefault(),
                                                            className: '',
                                                        }}
                                                        onChange={(d) => this.handleInputChange('bulan_tahun_selesai', d.format('MM-YYYY'))}
                                                    />
                                                )}
                                                <Checkbox
                                                    options={[{
                                                        value: true,
                                                        label: 'Masih bekerja disini',
                                                    }]}
                                                    onCheck={() => this.setState({ expPresent: true })}
                                                    onRemove={() => this.setState({
                                                        expPresent: false,
                                                        bulan_tahun_selesai: '',
                                                    })}
                                                    items={[expPresent]}
                                                    class="wrap-checkbox-left checkbox-with-checklist exp"
                                                />
                                            </div>
                                    }
                                    {
                                        ModalActionSelected === 'medical_actions' &&
                                            <div>
                                                <input
                                                    value={medicalActionsTmpValue || ''}
                                                    onChange={e => this.setState({
                                                        medicalActionsTmpValue: e.target.value
                                                    })}
                                                    placeholder="Nama Tindakan"
                                                    className="native-input" />
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className="master-modal-foot">
                                <button
                                    className="master-btn confirm"
                                    onClick={() => this.handleSubmitAdditional(ModalActionSelected)}
                                >
                                    Simpan
                                </button>
                                <button
                                    className="master-btn cancel"
                                    onClick={this.handleCloseModal}
                                >
                                    Batal
                                </button>
                            </div>
                        </div>
                    }
                </MasterModal>
                <MasterModal
                    className="master-modal"
                    isOpen={this.state.isModalConfirmOpen}
                    handleClose={this.handleCloseModalConfirm}
                    ModalData={this.state.ModalDataConfirm}
                    handleSubmit={this.handleSubmit}
                />
            </div>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Form)
