import React from 'react'
import Dropzone from 'react-dropzone'
import { Button, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import ReactCrop from "react-image-crop"; 
import "react-image-crop/dist/ReactCrop.css";
import Icon from '../utils/Icons'
  
  const thumbInner = {
    // display: 'flex',
    minWidth: 0,
    // width: 'fit-content',
    margin: '0 auto',
    overflow: 'hidden',
    paddingLeft: '20px'
  }

  const textAlert = {
    paddingTop: '25px',
    left: 0
  }

  const textAlert2 = {
    paddingTop: '25px',
    left: 0
  }

  const img = {
    display: 'block',
    width: '30px',
    height: '30px',
    borderRadius: 12,
    objectFit: 'cover',
    flex: 'none',
    margin: "28px",
    filter: "grayscale(100%) brightness(30%) sepia(100%) hue-rotate(-180deg) saturate(500%) contrast(0.8)"
  };

  const img2 = {
    display: 'block',
    width: '88px',
    height: '88px',
    borderRadius: 12,
    objectFit: 'cover',
    flex: 'none',
  };

  const btnFoto = {
    width: "100%",
    backgroundColor: "white",
    color: "#5986ED",
    border: "#5986ED 1px solid",
    marginLeft: "6px",
  }

  const btnFoto2 = {
    width: "100%",
    backgroundColor: "white",
    color: "#5986ED",
    border: "#5986ED 1px solid",
    marginLeft: "6px",
  }

  const forUL = {
    fontSize: "10px",
    paddingLeft: "15px",
    color: "black",
    marginBottom: "20px",
  }

  const forLI = {
    textAlign: "left"
  }

  const boxImage = {
    height: "88px",
    width: "88px",
    border: "1px #3a68b9 dashed", 
    borderRadius:"5px", 
    marginLeft: "10px",
  }

export default class ImageUploadFormWithCrop extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          files: [],
          placeholder: true,
          previewFromFecth: null,
          dropedImage: false,
          isOpen: false,
          src: null,
          crop: {
            unit: '%',
            width: 70,
            aspect: 1,
          }
        }
      }
      
      onDrop(files) {
        
        this.setState({
          files: files.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
          })),
          previewFromFecth: null,
          placeholder: false,
          dropedImage: true
        })

        files.forEach(file => {
            const reader = new FileReader()
            reader.onload = () => {
                this.props.onUpload(reader.result, file)
            }
            reader.readAsDataURL(file)
        });
      }
      
    
      componentWillUnmount() {
        this.state.files.forEach(file => URL.revokeObjectURL(file.preview))
      }

      handleClick = (e) => {
        this.setState({
          isOpen: true,
          src: ''
        })
      }
      
      handleCloseModal = (e) => {
        this.setState({
          isOpen: false
        })
      }

      handleSave = (e) => {
        this.setState({
          isOpen: false
        })
      }

      onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
          const reader = new FileReader();
          reader.addEventListener('load', () =>
            this.setState({ src: reader.result })
          );
          reader.readAsDataURL(e.target.files[0]);
        }
      };
    
      onImageLoaded = (image) => {
        this.imageRef = image;
      };
    
      onCropComplete = (crop) => {
        this.makeClientCrop(crop);
      };
    
      onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
      };
    
      async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
          );
          this.setState({ croppedImageUrl });
        }
      }
    
      getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
    
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        );
        
        const base64Image = canvas.toDataURL('image/jpeg');
        this.props.receiveImg(base64Image)
      }

      render() {
        const {
          placeholder, 
          dropedImage,
          crop,
          src
        } = this.state

        const {
          desc,
          image,
          current,
          label,
          maxSize,
          source
        } = this.props

        let placeholder2 = <p style={{marginTop: "-40px"}}>{desc}</p>
        let placeholder3 = <p style={{marginTop: "-40px"}}>Edit foto</p>
        const length = image.length
        if(!placeholder || (length > 0) ){
          placeholder2 = `Ganti ${source ? source : 'foto NaKes'}`
        }
        
        let previewFromFecth = this.state.previewFromFecth 

        if(!dropedImage && current.value){
          previewFromFecth = current.value
          placeholder2 = `Ganti ${source ? source : 'foto NaKes'}`
        }
        return (
          <div className="form-upload">
            <div className="form-line">
              <label className="form-control-label" >{ label }</label>
              <div className="file-container">
                <Dropzone
                  accept="image/*"
                  onDrop={this.onDrop.bind(this)}
                  maxSize={maxSize}
                >
                  {({getRootProps, getInputProps}) => (
                    <div className="file-upload" {...getRootProps()} >
                      { 
                        !previewFromFecth ? 
                        <div>
                            <Row xs="2">
                                <Col md="4">
                                <div style={boxImage}>
                                  <img
                                    src={ Icon.camera }
                                    style={img}
                                    alt=""
                                    onClick={()=>this.handleClick()}
                                  />
                                </div>
                              </Col>
                              <Col style={textAlert2}>
                                <div>
                                  <div className="custom-wrappers"><label style={{fontSize: "14px"}}>Pedoman Unggah Foto </label></div>
                                   <div className="custom-wrappers1">
                                      <ul style={forUL}>
                                        <li style={forLI}>Foto dalam bentuk kotak (1:1)</li>
                                        <li style={forLI}>Posisi wajah Nakes di tengah</li>
                                        <li style={forLI}>Maksimal ukuran file 3 MB</li>
                                        <li style={forLI}>Format file JPG, JPEG, PNG, BMP</li>
                                      </ul>
                                    </div>
                                </div>
                              </Col>
                            </Row>
                        </div> 
                        : 
                        <div>
                          <div style={ thumbInner }>
                            <Row xs="2">
                                <Col md="4">
                              <img
                                  src={ previewFromFecth }
                                  style={img2}
                                  alt=""
                                  onClick={()=>this.handleClick()}
                                />
                                
                              </Col>
                              <Col style={textAlert}>
                                <div>
                                <div className="custom-wrappers"><label style={{fontSize: "14px", marginTop: "-5px"}}>Pedoman Unggah Foto </label></div>
                                   <div className="custom-wrappers1">
                                      <ul style={forUL}>
                                        <li style={forLI}>Foto dalam bentuk kotak (1:1)</li>
                                        <li style={forLI}>Posisi wajah Nakes di tengah</li>
                                        <li style={forLI}>Maksimal ukuran file 3 MB</li>
                                        <li style={forLI}>Format file JPG, JPEG, PNG, BMP</li>
                                      </ul>
                                    </div>  
                                </div>
                              </Col>
                            </Row>
                            
  
                          </div>
                        </div>
                      }
                      {/* <input {...getInputProps()} /> */}
                      {
                        !previewFromFecth ?
                        <Button style={btnFoto2} onClick={()=>this.handleClick()} >
                        {placeholder2}
                        </Button>
                        :
                        <Button style={btnFoto} onClick={()=>this.handleClick()} >
                        {placeholder3}
                        </Button>
                      }
                    </div>
                  )}
                </Dropzone>
                <Modal className="modal-doctor" isOpen={this.state.isOpen} toggle={this.handleCloseModal}>
                  <ModalHeader toggle={this.handleCloseModal}>Tambah / Ganti Foto Nakes</ModalHeader>
                  <ModalBody>
                    { !src ?
                      <div for="img" class="upload-btn-wrapper input[type=file]" style={{ marginLeft: '33%' }}>
                        <button  className='btn' style={{width: '150px', height: '150px', border: "1px #3a68b9 dashed"}}>
                        <img className="icon" src={ Icon.camera } alt="icon"/>
                        </button>
                        <input ref="logo" type="file" accept="image/*" onChange={this.onSelectFile}/>
                      </div> 
                      :
                      ""
                    }
                  {src && (
                    <ReactCrop
                      src={src}
                      crop={crop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                      width="100%"
                    />
                  )}
                  </ModalBody>
                  <ModalFooter >
                      <div className="wrap-action-button" style={{marginTop: '0px'}}>
                        <button
                            type="button"
                            className="cancel"
                            onClick={this.handleCloseModal} >
                            Batal
                        </button>
                        <button
                            type="submit"
                            className={`add-schedule-btn`} onClick={this.handleSave}>
                            Simpan
                        </button>
                    </div>
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          </div>
        )
      }
}
