import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, ModalBody } from 'reactstrap';
import { modalMapStateToProps, modalMapDispatchToProps } from '../core/const/ModalConst'
import CloseButton from '../themes/v1/img/svg/close.svg'
import ModalContent from './partials/ModalContent'

class ModalTemplate extends Component {
  constructor(props){
    super(props)
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  handleCloseModal(){
    this.props.onRequestHide()
  }

  componentDidMount(){
    this.props.onRequestHide()
  }
  
  render(){
      const ModalComponent = this.props.ModalComponent
      return (
        <Modal isOpen={this.props.isShow} toggle={this.handleCloseModal}  className="modal-wrapper">
          <ModalBody>
            <button className="modal-close-btn" onClick={ this.handleCloseModal }>
              <img src={ CloseButton } alt="Close"/>
            </button>
            <div className="modal-main-content">
              <ModalContent ModalComponent={ModalComponent} />
            </div>
          </ModalBody>
        </Modal>
      );
  }
}

export default connect(modalMapStateToProps, modalMapDispatchToProps)(ModalTemplate)