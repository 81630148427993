import dateFns from 'date-fns'
import { isEmpty, isArray } from 'lodash'

import Api from '../../../../../../services/apis/Subscription'
import {
    handleToast,
    handleToastServiceError
} from '../../../../../../core/utils/UtilsToast'

const FormHandler = {
    getDetail: async function (id) {
        this.setState({ loading: true })

        await Api.detailFaskes(id).then((res) => {
            const { data } = res.data

            this.setState({ loading: false })
            if (res.status === 200) {
                this.buildValue({
                    hospital_id: data.id,
                    hospital_names: data.name,
                    package_id: data.package_id,
                    start_date: data.package_start_date ? new Date(data.package_start_date) : '',
                    end_date: data.package_end_date ? new Date(data.package_end_date) : '',
                    active: `${data.package_status}`,
                });
                this.setState({
                    selectedPackage: {
                        value: data.package_id,
                        label: data.package_name,
                    }
                })
            }
        }).catch(() => {
            this.setState({ loading: false })
        })
    },
    buildForm() {
        this.nameField = this.TextInputFormView({
            validator: 'required',
            label: 'Nama Faskes',
            name: 'hospital_names',
            placeholder: 'Nama Faskes',
            control: this.state.Form.controls.hospital_names
        })

        this.DateStart = this.DatePickerView({
            meta: {
                label: 'Tanggal Mulai',
                validator: 'required',
                minDate: new Date()
            },
            name: 'start_date',
            control: this.state.Form.controls.start_date
        })

        this.DateEnd = this.DatePickerView({
            meta: {
                label: 'Tanggal Selesai',
                validator: 'required',
                minDate: new Date()
            },
            name: 'end_date',
            control: this.state.Form.controls.end_date
        })

        this.activeField = this.RadioInputFormView({
            name: 'active',
            meta: {
                label: 'Status Pembayaran',
                options: [
                    {
                        value: 'true',
                        name: 'Dibayar',
                    },
                    {
                        value: 'false',
                        name: 'Belum Dibayar',
                    }
                ]
            },
            control: this.state.Form.controls.active
        })
    },
    buildValue(data) {
        this.state.Form.patchValue(data)
    },
    handleSubmit: async function (e) {
        e.preventDefault();
        this.setState({ loading: true })

        let response = {}
        const formValue = Object.assign({}, this.state.Form.value)
        formValue.package_id = formValue.package_id instanceof Object === true
            ? formValue.package_id.value
            : formValue.package_id
        formValue.active = formValue.active === 'true'
        formValue.start_date = formValue.start_date ? dateFns.format(formValue.start_date, 'YYYY-MM-DD') : '';
        formValue.end_date = formValue.end_date ? dateFns.format(formValue.end_date, 'YYYY-MM-DD') : '';

        const hId = formValue.hospital_id;
        delete formValue.hospital_id;

        try {
            response = await Api.assign(hId, formValue)
            handleToast(response.data.message)
            this.props.loadDataAfterAction()
            this.props.onPanelClose()
            this.setState({ loading: false })
        } catch (e) {
            let message = e.message
            const errData = e.response

            if (!isEmpty(errData) && errData.data !== '') {
                message = errData.data.message
                if (!isArray(errData.data.data)) {
                    message = Object.keys(errData.data.data).map(k => errData.data.data[k]).join('\n')
                }
            }

            this.setState({ loading: false })
            handleToastServiceError({ message })
        }
    }
}

export default FormHandler
