import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import { loginMapStateToProps, loginMapDispatchToProps } from '../../core/const/LoginConst'
import RoleHelper from '../../core/helper/RoleHelper'
import AccessPermission from '../../template/AccessPermission'
import MasterTemplate from '../../template/MasterTemplate'
import DetailComp from './components/Detail'

class Detail extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    isOwner = (newCurrentHosp) => {
        const { dataLogin } = this.props
        const hospitalSelected = dataLogin && dataLogin.hospitals.find(option => {
            return String(option.id) === String(newCurrentHosp)
        })

        return !isEmpty(hospitalSelected) ? hospitalSelected.owner : false
    }

    getRole = (newCurrentHosp) => {
        const { dataLogin } = this.props
        return RoleHelper.getRole(dataLogin, this.isOwner(newCurrentHosp))
    }

    render() {
        const { isLoggin, currentHospital } = this.props
        const role = this.getRole(currentHospital)
        const breadcrumbs = [
            {
                label: 'Dashboard',
                path: '/dashboardhospital',
            },
            {
                label: 'Daftar Tagihan',
                path: '/billing/list',
            },
            {
                label: 'Detail Tagihan',
                path: null,
            },
        ]

        if (currentHospital === null
            || !isLoggin
            || process.env.REACT_APP_HOSPITAL_BILLING !== 'true') {
            return <Redirect to="/" />
        }

        if (((role && role.role_name !== 'superadmin')
            && (role && role.role_name !== 'admin_spv')
            && (role && role.role_name !== 'doctor')
        )) {
            return <Redirect to="/" />
        }

        return (
            <AccessPermission {...this.props} module="billing">
                <MasterTemplate {...this.props} breadcrumbs={breadcrumbs}>
                    <DetailComp {...this.props} />
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Detail)
