import React, {Component} from 'react'
import {FieldGroup, FormBuilder, Validators} from 'react-reactive-form'
import SubmitButtonForm from '../../../../core/utils/SubmitButtonForm'
import TextInputForm from '../../../../core/utils/wizard/TextInputForm'
import DropDownFormApotek from '../../../../core/utils/wizard/DropDownFormApotek'
import TextInputEmailForm from '../../../../core/utils/wizard/TextInputEmailForm'
import FormHandler from '../handler/FormHandler'
import { 
    loginMapDispatchToProps, 
    loginMapStateToProps
} from '../../../../core/const/LoginConst'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Config from '../config'

class UserFormComponent extends Component {
    constructor(props) {
        super(props)

        Object.assign(this, TextInputForm)
        Object.assign(this, TextInputEmailForm)
        Object.assign(this, FormHandler)
        Object.assign(this, DropDownFormApotek)

        this.state = {
            Form: FormBuilder.group({
                id: [""],
                name: ["", Validators.required],
                email: [
                    "", [Validators.required, Validators.email]
                ],
                telephone: ["", Validators.required],
                apotek: [null, Validators.required],
            }),
            listApotek: [],
            isEdit: false
        }

        this.TextInputFormView = this.TextInputFormView.bind(this)
        this.TextInputEmailFormView = this.TextInputEmailFormView.bind(this)
        this.DropDownFormApotekView = this.DropDownFormApotekView.bind(this)

        this.handleSubmit = this.handleSubmit.bind(this)
        this.valueBuilder = this.valueBuilder.bind(this)
        this.fillForm = this.fillForm.bind(this)
        this.fetchListApotek = this.fetchListApotek.bind(this)
        this.renderStaticEmailText = this.renderStaticEmailText.bind(this)

        this.buildForm()

    }
    
    // end of example custom error
    componentDidMount() {
        this.fetchListApotek()
        this.valueBuilder()
    }

    renderStaticEmailText(){
        let email = ''
        if(this.state.Form.controls.email.value){
            email = this.state.Form.controls.email.value
        }
        return(
            <div className="form-line">
                <label className="form-control-label">
                    Email
                </label>
                <div className="form-group">
                    <input type="text" className="form-control" id={ 1 } readOnly={ true } defaultValue={ email }/>
                </div>
            </div>
        )
    }

    render() {
        const { postStatus, Form, isEdit } = this.state

        if (postStatus) {
            return <Redirect to={ Config.baseUrl }/>
        }

        let emailText = this.TextEmail
        if(isEdit){
            emailText = this.renderStaticEmailText()
        }

        return (
            <div className="wizard-form admin-form">
                {/** start form */}
                <FieldGroup
                    control={ Form }
                    strict={ false }
                    render={({ get, invalid }) => {
                        return (
                            <form onSubmit={() => this.handleSubmit} className="form-horizontal form-poly">
                                { emailText }
                                { this.TextName }
                                { this.ApotekDropdown }
                                { this.TextTelephone }
                                <SubmitButtonForm invalid={invalid}
                                    showCancel={ true }
                                    submitText={ "Simpan" }
                                    onSubmit={ (e) => this.handleSubmit(e) }
                                    onCancel={ (e) => this.props.history.push(Config.baseUrl) }
                                />
                            </form>
                        )
                    }}
                />
                {/** end of form */}
            </div>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(UserFormComponent)