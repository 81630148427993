import { isEmpty, isArray, orderBy, compact } from 'lodash'

import ApiBilling from '../../../services/apis/BillingHospital'
import ApiService from '../../../services/apis/ServiceHospital'
import {
    handleToast,
    handleToastServiceError
} from '../../../core/utils/UtilsToast'

const DetailHandler = {
    getPatientList: async function (search) {
        this.setState({ loading: true })

        await ApiBilling.patient(this.currentHospital, search).then((res) => {
            const { data } = res.data

            this.setState({ loading: false })

            if (res.status === 200) {
                this.setState({ patientList: data })
            }
        }).catch(error => {
            console.log('Something went wrong:', error)
        })
    },
    getData: async function (id) {
        const { used_services_temp: usedServiceTemp } = this.state

        ApiBilling.detail(id, this.currentHospital).then(result => {
            const { data } = result.data

            if (!isEmpty(data)) {
                let invoiceServices = []

                if (data.invoice_services && data.invoice_services.length > 0) {
                    invoiceServices = data.invoice_services.map(s => {
                        const randId = Math.random().toString(36).substring(7)
                        usedServiceTemp.push({ rId: randId, code: s.code })

                        return {
                            ...s,
                            randId,
                            invoice_selected: {
                                label: s.name,
                                value: s.code,
                                price: s.price,
                            }
                        }
                    })
                } else {
                    invoiceServices = [{
                        no: 1,
                        randId: Math.random().toString(36).substring(7),
                        code: '',
                        name: '',
                        qty: '',
                        price: '',
                        subtotal: '',
                        invoice_selected: null,
                    }]
                }

                setTimeout(() => {
                    this.getDropdown()
                }, 0);
                this.setState({
                    detail: data,
                    invoice_status: `${data.invoice_status}`,
                    invoice_services: orderBy(invoiceServices, ['no'], ['asc']),
                    used_services_temp: usedServiceTemp,
                })
            } else {
                const { history } = this.props
                history.push('/billing/list')
            }
        }).catch(() => {
            const { history } = this.props
            history.push('/billing/list')
        })
    },
    getDropdown: async function () {
        const { used_services_temp: usedServiceTemp } = this.state

        ApiService.dropdown(this.currentHospital).then(result => {
            const { data } = result.data

            if (!isEmpty(data)) {
                const ustStrArr = usedServiceTemp.map(item => item.code)
                const serviceOptions = data.filter(v => !ustStrArr.includes(v.value))

                this.setState({
                    invoice_options_ori: data,
                    invoice_options: serviceOptions,
                })
            }
        }).catch(error => {
            console.log('Something went wrong:', error)
        })
    },
    handleSubmit: async function () {
        let response
        const {
            detail,
            invoice_status: invoiceStatus,
            invoice_services: invoiceService,
        } = this.state
        const { history } = this.props
        const services = invoiceService.map(item => {
            return {
                code: item.code,
                name: item.name,
                price: item.price,
                qty: item.qty,
            }
        })
        const payload = {
            hospital_id: this.currentHospital,
            book_id: detail.id,
            status: invoiceStatus === 'true',
            services,
        }

        this.setState({ loading: true })

        try {
            if (this.id) {
                response = await ApiBilling.update(this.id, payload)
            } else {
                response = await ApiBilling.create(payload)
            }

            setTimeout(() => {
                handleToast(response.data.message)
                this.setState({ loading: false })
            }, 0);

            history.replace('/billing/list')
        } catch (e) {
            let message = e.message
            const errData = e.response

            if (!isEmpty(errData) && errData.data !== '') {
                message = errData.data.message
                if (!isArray(errData.data.data)) {
                    message = Object.keys(errData.data.data).map(k => errData.data.data[k]).join('\n')
                }
            }

            this.setState({ loading: false })
            handleToastServiceError({ message })
        }
    },
    toRp(number) {
        if (number) {
            return `Rp ${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
        }

        return 'Rp 0 (Gratis)'
    },
    handlePatientSelect(data) {
        this.setState({ detail: { ...data, id: data.value } })
    },
    handleInputChange(rId, field, val) {
        const { invoice_services: invoiceService } = this.state
        const dataEdit = invoiceService.find(item => item.randId === rId)

        if (!isEmpty(dataEdit)) {
            const gVal = val === '0' ? 1 : val
            let setVal = gVal
            if (val > 0 && ['qty', 'price', 'subtotal'].includes(field)) {
                setVal = val.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            }
            dataEdit[field] = setVal

            if (field === 'qty') {
                dataEdit.subtotal = dataEdit.price * gVal
            }
            const serviceNew = invoiceService.filter(item => item.randId !== rId)

            const result = orderBy(compact(serviceNew.concat(dataEdit)), ['no'], ['asc'])
            this.setState({ invoice_services: result })
        }
    },
    handleSelectChange(rId, val) {
        const {
            invoice_options_ori: invoiceOptionsOri,
            invoice_services: invoiceService,
            used_services_temp: usedServiceTemp,
        } = this.state
        const dataEdit = invoiceService.find(item => item.randId === rId)

        if (!isEmpty(dataEdit)) {
            const ust = usedServiceTemp.filter(v => v.rId !== rId)

            dataEdit.code = val.value
            dataEdit.name = val.label
            dataEdit.invoice_selected = val
            dataEdit.qty = 1
            dataEdit.price = val ? val.price : 0
            dataEdit.subtotal = val ? val.price * 1 : 0
            const serviceNew = invoiceService.filter(item => item.randId !== rId)
            const result = orderBy(compact(serviceNew.concat(dataEdit)), ['no'], ['asc'])

            ust.push({ rId, code: val.value })
            const ustStrArr = ust.map(item => item.code)
            const serviceOptions = invoiceOptionsOri.filter(v => !ustStrArr.includes(v.value))

            this.setState({
                invoice_services: result,
                used_services_temp: ust,
                invoice_options: serviceOptions,
            })
        }
    },
    handleAdd() {
        const { invoice_services: invoiceService } = this.state
        const dataEdit = invoiceService
        const result = dataEdit.concat({
            no: invoiceService.length + 1,
            randId: Math.random().toString(36).substring(7),
            code: '',
            name: '',
            qty: '',
            price: '',
            subtotal: '',
            invoice_selected: null,
        })

        this.setState({ invoice_services: result })
    },
    handleDelete(rId) {
        const {
            invoice_options_ori: invoiceOptionsOri,
            invoice_services: invoiceService,
            used_services_temp: usedServiceTemp,
        } = this.state
        const dataEdit = invoiceService.find(item => item.randId === rId)

        if (!isEmpty(dataEdit)) {
            const serviceNew = invoiceService.filter(item => item.randId !== rId)
            const ust = usedServiceTemp.filter(v => v.rId !== rId)
            const ustStrArr = ust.map(item => item.code)
            const serviceOptions = invoiceOptionsOri.filter(v => !ustStrArr.includes(v.value))
            const result = orderBy(compact(serviceNew), ['no'], ['asc'])

            this.setState({
                invoice_services: result,
                used_services_temp: ust,
                invoice_options: serviceOptions,
            })
        }
    },
    download({ id, patient_name: patientName }) {
        this.setState({ loading_download: true })

        const { token, currentHospitalName } = this.props

        const baseURL = process.env.REACT_APP_API_HOST.replace('admin', '')
        const link = `${baseURL}hospital/invoice/download/${id}?hospital_id=${this.currentHospital}`

        fetch(link, {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
                'X-API-Auth': process.env.REACT_APP_X_API_AUTH,
            },
        })
            .then(response => {
                if (response.status === 200) {
                    const fileName = `Tagihan - (${currentHospitalName}) - ${patientName}`
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob)
                        let a = document.createElement('a')
                        a.href = url
                        a.download = fileName
                        a.click()
                    })
                } else {
                    handleToastServiceError({ message: 'File not found.' })
                }

                this.setState({ loading_download: false })
            })
    },
}

export default DetailHandler
