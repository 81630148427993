import {handleToast, handleToastServiceError} from '../../../core/utils/UtilsToast'
import Api from '../../../services/apis/Hospital/List'
import ApiArea from '../../../services/apis/Area/Area'
import Config from '../config/UrlConfig'

const FormHandler = {
    buildForm: function () {
        this.TextName = this.TextInputFormView({
            label: "Nama",
            validator: "required",
            name: "name",
            control: this.state.Form.controls.name
        })
        this.TextEmail = this.TextInputFormView({
            label: "Email",
            validator: "required",
            name: "email",
            control: this.state.Form.controls.email
        })
        this.TextPhone = this.TextInputFormView({
            label: "Telepon",
            validator: "required",
            name: "phone",
            control: this.state.Form.controls.phone
        })
        this.TextFax = this.TextInputFormView({
            label: "Fax",
            validator: "required",
            name: "fax",
            control: this.state.Form.controls.fax
        })
        this.TextHospitalCode = this.TextInputFormView({
            label: "Kode Rumah Sakit",
            validator: "required",
            name: "code",
            control: this.state.Form.controls.code
        })
        this.TextPhoto = this.TextInputFormView({
            label: "URL Photo",
            validator: "",
            name: "photo",
            control: this.state.Form.controls.photo
        })
        this.TextJamBesuk = this.TextInputFormView({
            label: "Jam Besuk",
            validator: "",
            name: "jam_besuk",
            control: this.state.Form.controls.jam_besuk
        })

        this.TextWebsite = this.TextInputFormView({
            label: "Website",
            validator: "required",
            name: "website",
            control: this.state.Form.controls.website
        })
        this.TextLat = this.TextInputFormView({
            label: "LAT",
            validator: "required",
            name: "lat",
            control: this.state.Form.controls.lat
        })
        this.TextLng = this.TextInputFormView({
            label: "LNG",
            validator: "required",
            name: "lng",
            control: this.state.Form.controls.lng
        })
        this.DropDownJenisRs = this.DropDownFormView({
            meta: {
                label: "Jenis RS",
                validator: "required",
            },
            name: "gov_type",
            control: this.state.Form.controls.gov_type,
            stateName: 'JenisRsOpt',
        })
        this.DropDownTipeRs = this.DropDownFormView({
            meta: {
                label: "Tipe",
                validator: "required",
            },
            name: "id_type",
            control: this.state.Form.controls.id_type,
            stateName: 'tipeRsOpt',
        })
        this.CheckBoxFacility = this.CheckBoxFormView({
            name: "facilities",            
            meta: {
                label: "Fasilitas Rumah Sakit",
                validator: "",
                options: this.state.facilityOpt
            },
            control: this.state.Form.controls.facilities,
            stateName: 'facilityOpt'
        })
        this.DropDownPropinsi = this.DropDownFormView({
            meta: {
                label: "Propinsi",
                validator: "",
                callBack: this.getDropdownKota,
                EditshowOnlyText: false
            },
            name: "propinsi_id",
            nameText: "propinsi",
            control: this.state.Form.controls.propinsi_id,
            stateName: 'propinsiOpt',
        })
        this.DropDownKota = this.DropDownFormView({
            meta: {
                label: "Kota",
                validator: "",
                callBack: this.getDropdownKecamatan,
                EditshowOnlyText: true
            },
            name: "kota_id",
            nameText: "kota",
            control: this.state.Form.controls.kota_id,
            stateName: 'kotaOpt',
        })

        this.DropDownKecamatan = this.DropDownFormView({
            meta: {
                label: "Kecamatan",
                validator: "",
                callBack: this.getDropdownKelurahan,
                EditshowOnlyText: true
            },
            name: "kecamatan_id",
            nameText: "kecamatan",
            control: this.state.Form.controls.kecamatan_id,
            stateName: 'kecamatanOpt',
        })
        

        this.DropDownKelurahan = this.DropDownFormView({
            meta: {
                label: "Kelurahan",
                validator: "",
                callBack: this.setKodePos,
                EditshowOnlyText: true
            },
            name: "kelurahan_id",
            nameText: "kelurahan",
            control: this.state.Form.controls.kelurahan_id,
            stateName: 'kelurahanOpt',
        })
        this.Radio24 = this.RadioInputFormView({
            name: "hour24",            
            meta: {
                label: "Buka 24 Jam",
                validator: "",
                options: [
                    {
                        value: "0",
                        name: "Tidak",
                    },
                    {
                        value: "1",
                        name: "Iya",
                    }
                ]
            },
            control: this.state.Form.controls.hour24
        })
        this.TextStreet = this.TextAreaFormView({
            label: "Street",
            name: "street",
            control: this.state.Form.controls.street
        })
    },

    resetDependentSelect: function(me, mustBeCleared, optBeCleared){
        mustBeCleared.map((value) => {
            if(me.state.Form.controls[value]){
                me.state.Form.controls[value].reset()
            }
            return false
        })

        optBeCleared.map((value) => {
            me.setState({
                value: null
            })
            return false
        })
    },

    getDropdownKota: async function(me, name){
        const formMustBeCleared = [
            'kota_id', 'kecamatan_id', 'kelurahan_id'
        ]
        const optionMustBeCleared = [
            'kotaOpt', 'kecamatanOpt', 'kelurahanOpt'
        ]
        me.resetDependentSelect(me, formMustBeCleared, optionMustBeCleared)
        try{
            const idPropinsi = me.state.Form.value[name].value
            const response = await ApiArea.kota(idPropinsi)
            let kota = []
            Object.values(response.data.data).map(value => {
                kota.push({
                    value: value.kota_id,
                    label: value.kota_name
                })
                return false
            })
            me.setState({
                kotaOpt: kota,
                editChanged: true
            })
        } catch (e) {
            me.redirectToBase(e)
        }
    },

    getDropdownKecamatan: async function(me, name){
        const formMustBeCleared = [
            'kecamatan_id', 'kelurahan_id, kodepos_id'
        ]
        const optionMustBeCleared = [
            'kecamatanOpt', 'kelurahanOpt'
        ]
        me.resetDependentSelect(me, formMustBeCleared, optionMustBeCleared)
        try{
            const idKota = me.state.Form.value[name].value
            const response = await ApiArea.kecamatan(idKota)
            let kecamatan = []
            Object.values(response.data.data).map(value => {
                kecamatan.push({
                    value: value.kecamatan_id,
                    label: value.kecamatan_name
                })
                return false
            })
            me.setState({
                kecamatanOpt: kecamatan
            })
        } catch (e) {
            me.redirectToBase(e)
        }
    },

    getDropdownKelurahan: async function(me, name){
        const formMustBeCleared = [
            'kelurahan_id, kodepos'
        ]
        const optionMustBeCleared = [
            'kelurahanOpt'
        ]
        me.resetDependentSelect(me, formMustBeCleared, optionMustBeCleared)
        try{
            const idKecamatan = me.state.Form.value[name].value
            const response = await ApiArea.kelurahan(idKecamatan)
            let kelurahan = []
            Object.values(response.data.data).map(value => {
                kelurahan.push({
                    value: value.kelurahan_id,
                    label: value.kelurahan_name
                })
                return false
            })
            me.setState({
                kelurahanOpt: kelurahan
            })
        } catch (e) {
            me.redirectToBase(e)
        }
    },

    setKodePos: async function(me, name){
        try{
            const idKelurahan = me.state.Form.value[name].value
            const idKecamatan = me.state.Form.value['kecamatan_id'].value
            const response = await ApiArea.kodepos(idKecamatan, idKelurahan)
            me.state.Form.controls.kodepos.setValue(response.data.data)
        } catch (e) {
            me.redirectToBase(e)
        }
    },

    valueBuilder: async function () {
        this.valueObject = {
            id: "",
            name: "",
            code: "",
            email: "",
            website: "",
            lat: "",
            lng: "",
            gov_type: "",
            phone: "",
            fax: "",
            photo: "",
            id_type: "",
            propinsi: "",
            propinsi_id: "",
            kota: "",
            kota_id: "",
            kecamatan: "",
            kecamatan_id: "",
            kelurahan: "",
            kelurahan_id: "",            
            kodepos: "",
            facilities: [],
            hour24: "",
            jam_besuk: "",
            street: ""
        }

        await this.getDropdownPropinsi()

        if (this.props.location.state !== undefined) {
            let dataProvider = this.props.location.state.dataProvider
            this.fillForm(dataProvider)
            
        } else if (this.props.history.location.pathname.includes( Config.EditUrl )) {
            let me = this
            Api.view(this.props.match.params.id).then((response) => {
                me.fillForm(response.data.data)
            }, (e) => {
                handleToastServiceError(e)
                setTimeout(function(){ me.props.history.push( Config.GridUrl ); }, 3000);
            })
        }

    },

    getDropdownPropinsi: async function() {
        let me = this
        try {
            let response = await ApiArea.propinsi()
            let propinsi = []
            Object.values(response.data.data).map(value => {
                propinsi.push({
                    value: value.propinsi_id,
                    label: value.propinsi_name
                })
                return false
            })
            
            me.setState({
                propinsiOpt: propinsi,
            })
        } catch (e) {
            me.redirectToBase(e)
        }
    },

    fillForm: function(dataProvider) {
        let me = this
        Object.keys(dataProvider).map(function (key) {
            if (me.valueObject.hasOwnProperty(key)) {
                if(typeof me.valueObject[key] === 'boolean') {
                    me.valueObject[key] = !!dataProvider[key]
                } else if(key === 'facilities'){
                    const facilities = dataProvider[key]
                    let arr = []
                    facilities.map(value => 
                        arr.push(value.id)
                    )
                    me.valueObject[key] = arr 
                } else {
                    me.valueObject[key] = dataProvider[key] ? dataProvider[key].toString() : ''
                    /**
                     * UI update class input wrapper = focused, if filled
                     */
                    if(dataProvider[key] && key !== 'id'){
                        const element = document.getElementById('label-' + key)
                        if(element){
                            element.parentNode.classList.toggle('focused')
                        }
                    }
                }
            }
            return true
        })

        this.state.Form.patchValue(me.valueObject)
        me.setState({
            isEdit: true,
            filledForm: me.valueObject
        })
    },
    handleSubmit: async function (e) {
        e.preventDefault()
        let formValue = Object.assign({}, this.state.Form.value)
        let id = formValue['id']
        let me = this
        let response

        //custom form value
        formValue['gov_type'] = formValue.gov_type.value
        formValue['id_type'] = formValue.id_type.value
        const provinceData = formValue['propinsi_id']
        formValue['propinsi_id'] = provinceData.value
        formValue['propinsi'] = provinceData.label

        const cityData = formValue['kota_id']
        formValue['kota_id'] = cityData.value
        formValue['kota'] = cityData.label

        const kelurahanData = formValue['kelurahan_id']
        formValue['kelurahan_id'] = kelurahanData.value
        formValue['kelurahan'] = kelurahanData.label

        const kecamatanData = formValue['kecamatan_id']
        formValue['kecamatan_id'] = kecamatanData.value
        formValue['kecamatan'] = kecamatanData.label
        formValue['kodepos_id'] = formValue['kodepos'][0].kodepos_id
        formValue['kodepos'] = formValue['kodepos'][0].kodepos
        formValue['status'] = 1

        const final = []
        formValue['facilities'].map((value) => 
            final.push({
                id: value
            })
        )
        formValue['facilities'] = final

        if (this.state.isEdit) {
            try {
                response = await Api.update(id, JSON.stringify(formValue))
            } catch (e) {
                handleToastServiceError(e)
                return
            }

            me.setState({postStatus: true})
            if (response.data.code !== 200) {
                handleToast(response.data.message)
                return
            }

            handleToast(response.data.message)

            return
        }

        try {
            response = await Api.post(JSON.stringify(formValue))
        } catch (e) {
            handleToastServiceError(e)
            return
        }

        if (response.data.code !== 201) {
            handleToast(response.data.message)
            return
        }

        me.setState({postStatus: true})
        handleToast(response.data.message)
    }
}

export default FormHandler