import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'

import MasterTemplate from '../../../template/MasterTemplate'
import DoctorGrid from './components/Grid'
import RoleHelper from '../../../core/helper/RoleHelper'
import AccessPermission from '../../../template/AccessPermission'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../../core/const/LoginConst'

class DoctorMain extends Component
{
    constructor(props) {
        super(props)
        let state = this.props.store.getState()
        this.state = {
            dataGrid : [],
            isEdit: false,
            token: state.loginReducer.token,
            redirect: false
        }
        this.callBackState = this.callBackState.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillReceiveProps(nextProps) {
        const { currentHospital } = this.props

        if (currentHospital !== nextProps.currentHospital) {
            this.setState({ redirect: true })
        }
    }

    isOwner = () => {
        const {
            dataLogin,
            currentHospital
        } = this.props

        const hospitalSelected = dataLogin && dataLogin.hospitals.find(option => {
            return String(option.id) === String(currentHospital)
        })

        return !_.isEmpty(hospitalSelected) ? hospitalSelected.owner : false
    }

    callBackState(state) {
        this.setState({
            dataGrid: state,
            isEdit: state.editTrigger
        })
    }

    render() {
        const {
            dataLogin,
            currentHospital
        } = this.props

        const isDoctor = RoleHelper.isDoctor(dataLogin, currentHospital)
        const dataHospital = dataLogin && dataLogin.hospitals.find((data) => {
            return data.id === currentHospital
        })
        const { isEdit, token, dataGrid } = this.state
        const { history, store } = this.props

        if (this.state.redirect) {
            return (
                <Redirect to="/doctor/list" />
            )
        }

        if (isDoctor && isDoctor.doctor_id && !this.isOwner()) {
            return (
                <Redirect to={`/doctor/detail/${dataHospital.id_doctor_hospital}`} />
            )
        }

        if (isEdit) {
            return <Redirect to={{
                pathname: '/doctor/edit/' + dataGrid.dataProvider.id,
                state: { dataProvider: dataGrid.dataProvider }
            }} />
        }

        const breadcrumbs = [
            {
                label: 'Dashboard',
                path: '/dashboardhospital',
            },
            {
                label: 'Daftar Dokter / Tenaga Medis',
                path: null,
            },
        ]

        return (
            <AccessPermission {...this.props} module="manage_doctor">
                <MasterTemplate {...this.props} breadcrumbs={breadcrumbs}>
                    <DoctorGrid
                        {...this.props}
                        callBackState={this.callBackState}
                        token={token}
                        history={history}
                        store={store} />
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(DoctorMain)
