import React, { Component } from 'react'
import dateFns from "date-fns"
import {library} from '@fortawesome/fontawesome-svg-core'
import {faClock} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ButtonCancelSchedule from '../components/buttons/ButtonCancelSchedule'

library.add(faClock)

export default class Bottom extends Component{
    constructor(props){
        super(props)
        this.state = {
            estimation: ''
        }

        this.openCancelModal = this.openCancelModal.bind(this)
    }

    openCancelModal(schedule){
        const ModalData = {
            action: "cancel_schedule",
            id: schedule["id"],
            content: "Apakah anda yakin ingin menghapus jadwal?",
            buttonConfirmText: "Ya, hapus",
            buttonCancelText: "tidak, jangan di hapus",
            ConfirmFunction: this.deleteSchedule
        }
        this.props.openModal(ModalData)
    }

    render() {
        const selectedDate = String(this.props.selected)
        const schedule = this.props.data
        const timeStart = schedule['time_start']
        const timeEnd = schedule['time_end']
        const current = new Date()
        const scheduleStart = selectedDate.replace('00:00:00', timeStart)
        const scheduleEnd = selectedDate.replace('00:00:00', timeEnd)
        const result = dateFns.differenceInMinutes(
            current,
            new Date(scheduleStart),
          )
        let status = ''
        let diff = 0
        const inRange = dateFns.isWithinRange(
            current,
            new Date(scheduleStart),
            new Date(scheduleEnd),
        )
        if(inRange){
            status = 'Sedang Berlangsung'
        }else{
            if(result > 0 ){
                status = 'Sudah berakhir'
            }else if ((result < 0) && (Math.abs(result) < (24 * 60))){
                diff = Math.abs(result) / 60
                status = 'Mulai ' + Math.round(diff)  + ' Jam lagi'
            }else{
                status = 'Belum dimulai'
            }
        }
        
        return (
            <div className="information">
                <span className="transparent"><FontAwesomeIcon icon={faClock}/> {status}</span>
                <ButtonCancelSchedule
                    { ...this.props }
                    openCancelModal={ this.openCancelModal }
                    schedule={ schedule}
                />
            </div>
        )
    }
}