import React from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import dateFns from "date-fns"
import IndonesianLocale from 'date-fns/locale/id'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import MasterTemplate from '../../../template/MasterTemplate'
import Config from './config/UrlConfig'
import icoLeft from '../../../themes/v1/img/layout/icon/prev.png'
import icoRight from '../../../themes/v1/img/layout/icon/next.png'
import SliderPanel from '../../../components/wizard/SliderPanel'
import WizardModal from '../../../core/utils/wizard/ModalWizard'
import SlideContent from './components/SlideContent'
import MainHandler from './handler/MainHandler'
import AccessPermission from '../../../template/AccessPermission'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../../core/const/LoginConst'

class Main extends React.Component {
    constructor(props){
        super(props)
        const HospitalId = this.props.currentHospital

        this.state = {
            disableFinish: true,
            currentMonth: new Date(),
            selectedDate: null,
            parentNode: null,
            isPaneOpen: false,
            isFetchingSchedule: true,
            panelData: null,
            serviceId: this.props.match.params.serviceId,
            serviceData: [],
            hospitalId: HospitalId,
            listSchedule: [],
            operational: {},
            isModalOpen: false,
            ModalData: null,
            isModalFetching: false,
            customTitleWizard: null,
            redirectTo: null,
            backUrlToGrid: false,
            idToDelete : null,
        }

        this.breadcrumbData = [
            {
                no: '1',
                label: 'Informasi Layanan',
                finished: true,
                path: `/doctor/edit/${this.state.doctorId}/continue`
            },
            {
                no: '2',
                label: 'Atur Layanan',
                path: `/doctor/schedule/${this.state.serviceId}`
            }
        ]

        Object.assign(this, MainHandler)
        this.onPanelClose = this.onPanelClose.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.triggerRefresh = this.triggerRefresh.bind(this)
        this.modalHandleSubmit = this.modalHandleSubmit.bind(this)
        this.prevMonth = this.prevMonth.bind(this)
        this.nextMonth = this.nextMonth.bind(this)
        this.onBeforeCloseWizard = this.onBeforeCloseWizard.bind(this)
        this.redirectTo = this.redirectTo.bind(this)
        this.handleButtonFinish = this.handleButtonFinish.bind(this)
    }


    renderHeader() {
        const { isFetchingSchedule } = this.state
        const dateFormat = "MMMM YYYY"

        return (
            <div className="header row flex-middle">
                <div className="col col-center">
                    <button
                        className="month-arrow"
                        disabled={isFetchingSchedule}
                        onClick={this.prevMonth}
                    >
                        <img src={icoLeft} alt="prev" />
                    </button>
                    <span>
                        {
                            dateFns.format(
                                this.state.currentMonth,
                                dateFormat,
                                {
                                    locale: IndonesianLocale
                                }
                            )
                        }
                    </span>
                    <button
                        className="month-arrow"
                        disabled={isFetchingSchedule}
                        onClick={this.nextMonth}
                    >
                        <img src={icoRight} alt="next" />
                    </button>
                </div>
            </div>
        )
    }

    renderDays() {
        const dateFormat = "dddd"
        const days = []
        let startDate = dateFns.startOfWeek(this.state.currentMonth)
        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="col col-center" key={i}>
                    {dateFns.format(dateFns.addDays(startDate, i), dateFormat, {locale:IndonesianLocale})}
                </div>
            )
        }
        return <div className="days row">{days}</div>
    }

    renderScheduleInDate(formatedDay, currentDate, checkIsThisMonth){
        const currentYear = currentDate.getFullYear()
        const currentMonth = currentDate.getMonth() + 1
        const scheduleDates = this.state.listSchedule

        if(scheduleDates && scheduleDates[currentYear] && checkIsThisMonth){
            const schedule = scheduleDates[currentYear][currentMonth]
            if(schedule && schedule[formatedDay]){
                const eventPatch = schedule[formatedDay][0]
                return(
                    <ul className="schedule-in-day">
                        {eventPatch.map((val, key) => {
                            const expired = val.expired ? 'expired' : '';

                            if(key < 2){
                                return(<li key={key} className={expired}>{val['time_start'].slice(0, -3)} - {val['time_end'].slice(0, -3)}</li>)
                            } else if(key === 2) {
                                return <li key={key} className="more">Lebih lanjut..</li>
                            }

                            return null
                        })}
                    </ul>
                )
            }
        }

        return <span></span>
    }

    renderBody() {
        const {
            currentMonth,
            selectedDate,
            operational,
            isFetchingSchedule,
        } = this.state
        const currentDate = new Date()
        const monthStart = dateFns.startOfMonth(currentMonth)
        const monthEnd = dateFns.endOfMonth(monthStart)
        const startDate = dateFns.startOfWeek(monthStart)
        const endDate = dateFns.endOfWeek(monthEnd)
    
        const dateFormat = "D"
        const rows = []
    
        let days = []
        let day = startDate
        let formattedDate = ""
        let isPast = false
    
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = dateFns.format(day, dateFormat)
                const cloneDay = day
                const checkIsThisMonth = dateFns.isSameMonth(day, monthStart) ? true : false
                const isCurrentDay = dateFns.isSameDay(currentDate, cloneDay)
                isPast = !isCurrentDay && dateFns.isPast(cloneDay)

                let addClass = ''
                if (!dateFns.isSameMonth(day, monthStart) || isPast) {
                    addClass = 'disabled'
                } else if (dateFns.isSameDay(day, selectedDate)) {
                    addClass = 'selected'
                }

                // eslint-disable-next-line
                Object.keys(operational).map(dayOperate => {
                    if (((dateFns.format(day, 'ddd')).toLowerCase() === dayOperate)
                        && !operational[dayOperate].length
                        && dateFns.isSameMonth(day, monthStart)
                        && !isPast) {
                        addClass = 'disabled no-operate'
                    }
                    return true
                })

                days.push(
                    <div
                        key={day}
                        className={`col cell ${addClass}`}
                        onClick={() => this.onPanelOpen(dateFns.parse(cloneDay))}
                    >
                        <span className="number">{formattedDate}</span>
                        <span className="bg">{formattedDate}</span>
                        { !isPast ? this.renderScheduleInDate(formattedDate, currentMonth, checkIsThisMonth) : null }
                    </div>
                )
                day = dateFns.addDays(day, 1)
            }

            rows.push(
                <div className="row" key={day}>
                    {days}
                </div>
            )
            
            days = []
        }

        return (
            <div className="wrap-body-calendar">
                <div className="body-calendar">
                    {isFetchingSchedule ? (
                        <div className="loading-wrapper">
                            <BeatLoader
                                sizeUnit={"px"}
                                size={14}
                                color={'#123abc'}
                                loading={isFetchingSchedule}
                            />
                        </div>
                    ) : null}
                    {this.renderDays()}
                    {rows}
                </div>
            </div>
        )
    }

    redirectTo(path){
        this.setState({
            redirectTo: path
        })
    }

    componentDidMount(){
        const selectedMonth = this.state.currentMonth
        this.fetchSchedule(selectedMonth)
    }

    componentWillUpdate(nextProps, nextState){
        if(this.state.currentMonth !== nextState.currentMonth){
            this.fetchSchedule(nextState.currentMonth)
        }
    }
    
    componentWillUnmount(){
        this.setState({
            serviceData: []
        })
    }

    getTemplateClass(serviceStatus){
        if(typeof serviceStatus !== 'undefined'){
            if(serviceStatus === 1){
                return "template-schedule-edit"
            }else if(serviceStatus === 0){
                return "template-schedule-draft"
            }
        }

        return "template-schedule-load"
    }

    render() {
        const {
            customTitleWizard,
            disableFinish,
            serviceData,
            serviceId,
            hospitalId,
            isFetchingSchedule,
            isModalOpen,
            isModalFetching,
            isPaneOpen,
            ModalData,
            operational,
            panelData,
            redirectTo,
            selectedDate,
            idToDelete,
        } = this.state

        if(redirectTo){
            return(<Redirect to={redirectTo} />)
        }
        
        return(
            <AccessPermission {...this.props} module="manage_doctor">
                <MasterTemplate
                    {...this.props}
                    className={this.getTemplateClass(serviceData.status)}
                    wizard={true}
                    customTitleWizard={customTitleWizard}
                    cancelUrl={Config.GridUrl}
                    backUrl={Config.GridUrl}
                    breadcrumbData={this.breadcrumbData}
                    wizardShowCloseButton={false}
                    onBeforeClose={this.onBeforeCloseWizard}
                >
                    <div className="calendar">
                        <div className="operate-info-close">RS / Klinik tidak beroperasi</div>
                        {this.renderHeader()}
                        {this.renderBody()}
                        <SliderPanel
                            open={isPaneOpen}
                            onPanelClose={this.onPanelClose}
                            panelData={panelData}
                        >
                            <SlideContent {...this.props}
                                serviceId={serviceId}
                                serviceData={serviceData}
                                operational={operational}
                                hospitalId={hospitalId}
                                selectedDate={selectedDate}
                                triggerRefresh={this.triggerRefresh}
                                openModal={this.handleOpenModal}
                                ModalData={ModalData}
                                isFetching={isFetchingSchedule}
                                idToDelete={idToDelete}
                            />
                        </SliderPanel>
                        <button
                            className={
                                !disableFinish
                                    ? 'confirm-schedule'
                                    : 'confirm-schedule disabled'
                            }
                            onClick={this.handleButtonFinish}
                            disabled={disableFinish}>
                            Selesai Tambah Jadwal
                    </button>
                    </div>
                    <WizardModal
                        className="wizard-modal"
                        isOpen={isModalOpen}
                        handleClose={this.handleCloseModal}
                        handleSubmit={this.modalHandleSubmit}
                        ModalData={ModalData}
                        isModalFetching={isModalFetching}
                    />
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Main)
