import React from 'react'
import Chance from 'chance'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'

import Api from '../../../../../services/apis/Diagnosis'
import { handleToast, handleToastServiceError } from '../../../../../core/utils/UtilsToast'

const chance = new Chance()

const GridHandler = {
    onPanelClose() {
        this.setState({
            isPaneOpen: false,
            dataEdit: null,
        })
    },
    onPanelOpen(id) {
        const headTitle = <div>Tambah Diagnosa</div>

        if (id) {
            this.getDetail(id)
        } else {
            this.setState({
                isPaneOpen: true,
                dataEdit: null,
                panelData: { headTitle },
            })
        }
    },
    handleOpenModal(data) {
        this.setState({
            isModalOpen: true,
            modalData: data,
        })
    },
    handleCloseModal() {
        this.setState({
            isModalOpen: false,
            modalData: null,
        })
    },
    handleDelete(data) {
        const ModalData = {
            id: data.id,
            action: 'delete',
            content: `Konfirmasi hapus diagnosa <strong>${data.name}</strong>. <br />Apakah anda yakin?`,
            buttonConfirmText: 'Konfirmasi',
            buttonCancelText: 'Kembali',
        }

        this.handleOpenModal(ModalData)
    },
    loadDataAfterAction() {
        const state = {
            pageSize: this.pageSize,
            page: this.page,
            sorted: this.sorted,
            filter: '',
            filtered: this.state.filtered,
        }

        this.fetchData(state)
    },
    gridColumn() {
        return [
            {
                Header: 'Diagnosa',
                accessor: 'name',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return row.original.name || '-'
                },
            },
            {
                Header: 'Kode ICD 10',
                accessor: 'code',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return row.original.code || '-'
                },
            },
            {
                Header: '',
                width: 60,
                filterType: 'reset',
                className: 'grid-action',
                Cell: (row) => {
                    return (
                        <div className="grid-btn-wrapper">
                            <button onClick={() => this.onPanelOpen(row.original.id)}>
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                            <button onClick={() => this.handleDelete(row.original)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </div>
                    )
                },
            },
        ]
    },
    fetchData: async function (state) {
        this.setState({ loading: true })
        this.page = state.page
        this.pageSize = state.pageSize

        let res = {}
        let fArray = {}
        let sort_property = ''
        let sort_value = ''

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'DESC' : 'ASC'
            this.sorted = state.sorted
        }

        if (state.filtered && state.filtered.length > 0) {
            state.filtered.map(val => {
                if (val.value !== '') {
                    fArray[val.id] = val.value
                }
                return true
            })
        }

        const filterData = JSON.stringify(fArray)

        try {
            res = await Api.list(
                this.page + 1,
                this.pageSize,
                sort_property,
                sort_value,
                filterData,
            )
        } catch (e) {
            this.setState({ loading: false })

            handleToastServiceError(e)
            return
        }

        const {
            status,
            data: resData,
        } = res

        if (status === 200) {
            const newRows = resData.data.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })

            this.setState({
                loading: false,
                data: newRows,
                pages: resData.data.total_page,
                rows: resData.data.total_rows,
            })
        } else {
            this.setState({ loading: false })
        }
    },
    getFilterList: async function () {
        try {
            const response = await Api.getFilter()
            const { data } = response.data
            const filterList = data

            this.setState({ filterList })
        } catch (e) {
            handleToastServiceError(e)
        }
    },
    getDetail: async function (id) {
        this.setState({ loading: true })

        await Api.detail(id).then((res) => {
            const { data } = res.data

            this.setState({ loading: false })
            if (res.status === 200) {
                this.setState({
                    isPaneOpen: true,
                    dataEdit: data,
                    panelData: {
                        headTitle: <div>Edit Diagnosa <strong>{data.name}</strong></div>,
                    },
                })
            }
        })
    },
    modalHandleSubmit: async function () {
        const { modalData } = this.state

        if (modalData.action === 'delete') {
            try {
                const response = await Api.delete(modalData.id)
                handleToast(response.data.message)

                this.loadDataAfterAction();
                this.handleCloseModal()
            } catch (e) {
                handleToastServiceError(e)
                this.handleCloseModal()
            }
        }
    },
}

export default GridHandler
