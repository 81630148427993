import React, { Component } from 'react'

class SectionTitle extends Component {
    render() {
        const { className } = this.props
        return (
            <div className={`section-title${className !== '' ? ` ${className}` : ''}`}>
                <h3>{this.props.title}</h3>
                <div className="subtitle">{this.props.subtitle}</div>
            </div>
        )
    }
}

export default SectionTitle