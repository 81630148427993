/* eslint-disable */

import React, { Component } from 'react'
import BackButton from '../../themes/v1/img/svg/close.svg'
import ArrowPrev from '../../themes/v1/img/layout/icon/arrow-prev.png'
import WizardBreadCrumb from './WizardBreadCrumb'
import { NavLink, Redirect } from 'react-router-dom'

class WizardHeader extends Component
{
    constructor(){
        super()
        this.handleClose = this.handleClose.bind(this)
        this.state = {
            redirectTo: null
        }
    }
    renderButtonBack(){
        if(this.props.backUrl){
            return(
            <NavLink className="button-back-wizard" to={this.props.backUrl}>
                <img src={ArrowPrev} alt="Back" />
            </NavLink>
            )
        }else if(this.props.backByDefault){
            return(
            <a className="button-back-wizard" onClick={ () => this.props.history.goBack() }>
                <img src={ArrowPrev} alt="Back" />
            </a>)
        }
        return null
    }

    handleClose(){
        if(this.props.onBeforeClose){
            this.props.onBeforeClose()
        }else{
            this.setState({
                redirectTo:  this.props.cancelUrl ? this.props.cancelUrl : '/'
            })
        }
    }

    renderBreadCrumb(){
        if(this.props.breadcrumbData.length > 0){
            return(
                <div className="wizard-content-breadcrumb">
                    <WizardBreadCrumb {...this.props}/>
                </div>
            )
        }

        return null
    }

    renderCloseButton(){
        if(this.props.wizardShowCloseButton === true){
            return(
                <div className="back-button">
                    <a onClick={this.handleClose}>
                        <img src={BackButton} alt="Close Wizard"/>
                    </a>
                </div>
            )
        }
        
        return null
    }

    render() {
        const { redirectTo } = this.state
        const {
            breadcrumbData,
            className,
            customTitleWizard,
            currentHospitalName,
            title: titleMain,
            titledesc,
        } = this.props

        if (redirectTo) {
            return (<Redirect to={redirectTo} />)
        }

        const noBreadCrumbClass = breadcrumbData.length > 0 ? '' : ' no-bread-crumb'
        const title = customTitleWizard ? customTitleWizard : titleMain
        const titleDesc = titledesc ? titledesc : currentHospitalName

        return (
            <header className={`wizard-header ${className}`}>
                <div className={`wizard-header-content${noBreadCrumbClass}`}>
                    <div className="title">
                        {this.renderButtonBack()}
                        <div>
                            <span className={titleDesc ? "main" : "main no-subtitle"}>{title}</span>
                            <span className="sub">{titleDesc}</span>
                        </div>
                    </div>
                    {this.renderCloseButton()}
                </div>
                {this.renderBreadCrumb()}
            </header>
        )
    }
}

export default WizardHeader