import React, { Component } from 'react'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from "react-reactive-form"
import SubmitButtonForm from '../../../core/utils/SubmitButtonForm'
import DropDownForm from '../../../core/utils/DropDownForm'
import FormHandler from '../handler/FormHandler'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../../core/const/LoginConst'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

class Form extends Component
{
     constructor(props) {
        super(props)

        Object.assign(this, DropDownForm)
    
        this.state = {
            isEdit: false,
            postStatus: false,
            doctorOption: [],
            hospitalOption: [],
            specialistOption: [],
            form: FormBuilder.group({
                id: [""],
                id_doctor: ["", Validators.required],
                id_specialist: ["", Validators.required],
                id_hospital: ["", Validators.required],
            })
        }

        this.DropDownFormView = this.DropDownFormView.bind(this)
        Object.assign(this, FormHandler)

        this.handleSubmit = this.handleSubmit.bind(this)
        this.valueBuilder = this.valueBuilder.bind(this)

        this.buildForm()

    }

    componentDidMount() {
        this.valueBuilder()
    }

    render(){
        if (this.state.postStatus) {
            return <Redirect to="/hospital/dsmap" />
        }
        return(
            <section className="forms">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card">
                                <div className="card-header d-flex align-items-center">
                                    <h3 className="h4">{this.props.title}</h3>
                                </div>
                                <div className="card-body">
                                    {/** start form */}
                                    <FieldGroup
                                    control={this.state.form}
                                    strict={false}
                                    render={({ get, invalid }) => {
                                    return(
                                        <form onSubmit={() => this.handleSubmit} className="form-horizontal">
                                            {this.doctorDropdown}
                                            {this.hospitalDropdown}
                                            {this.specialistDropdown}
                                            <SubmitButtonForm invalid={invalid} onSubmit={(e) => this.handleSubmit(e)} onCancel={(e) => this.props.history.push('/hospital/dsmap')}/>
                                        </form>
                                    )}}/>
                                    {/** end of form */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Form)