import Icons from '../../../../core/utils/Icons'
const icon = Icons.dashboardMenu

const getMenuAdminSpv = (role, dataLogin) => {
    return {
        quickButton: true,
        menu: [
            {
                index: 'dashboardhospital',
                path: '/dashboardhospital',
                icon: icon.dashboard.normal,
                iconHover: icon.dashboard.hover,
                title: 'Dashboard',
            },
            {
                index: 'appointment',
                path: '/appointment/list',
                icon: icon.appointment.normal,
                iconHover: icon.appointment.hover,
                title: 'Appointment',
            },
            {
                index: 'doctor',
                path: '/doctor/list',
                icon: icon.doctor.normal,
                iconHover: icon.doctor.hover,
                title: 'Dokter / Tenaga Medis',
            },
            {
                index: 'patient',
                path: '/patient/list',
                icon: icon.patient.normal,
                iconHover: icon.patient.hover,
                title: 'Pasien',
            },
            {
                index: 'specialities',
                path: '/specialities',
                icon: icon.dashboard.normal,
                iconHover: icon.dashboard.hover,
                title: 'Spesialisasi',
            },
            {
                index: 'service',
                path: '/hospital/services',
                title: 'Layanan',
                icon: icon.patient.normal,
                iconHover: icon.patient.hover,
            },
            {
                index: 'administration',
                path: null,
                icon: icon.setting.normal,
                iconHover: icon.setting.hover,
                title: 'Pengaturan',
                children: [
                    {
                        index: 'hospital',
                        path: '/administration/info-hospital',
                        title: 'Pengaturan RS',
                        children: null
                    },
                    {
                        index: 'service-fee',
                        path: '/administration/service-fee',
                        title: 'Biaya Layanan',
                        beta: process.env.REACT_APP_HOSPITAL_SERVICE_BETA,
                        children: null
                    },
                    {
                        index: 'administration',
                        path: '/administration/form-patient',
                        title: 'Formulir Pasien',
                    },
                    {
                        index: 'user',
                        path: '/administration/user',
                        title: 'Pengaturan Admin',
                        children: null
                    },
                    // {
                    //     index: 'spg',
                    //     path: '/administration/spg',
                    //     title: 'Pengaturan SPG',
                    //     children: null
                    // },
                    {
                        index: 'change-password',
                        path: '/administration/change-password',
                        title: 'Ubah Password',
                        children: null
                    },
                ]
            },
        ]
    }
}

export default getMenuAdminSpv