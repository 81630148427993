import DashboardApi from './../../../services/apis/Dashboard/Api'
import HospitalApi from './../../../services/apis/Hospital/List'
import Axios from '../../../services/apis/MasterConfig'
import { handleToast, handleToastServiceError } from '../../../core/utils/UtilsToast'

const gridHandler = {
    handleClick(theAction, row){
        switch(theAction){
            case 'upload':
                this.setState({
                    selectedHospital: row,
                    modalType: theAction,
                    modalData: null,
                })
                this.handleOpenModal(null)
                break;
            case 'download':
                Axios({
                    url: '/dashboard/downloadhospitaldata/' + row.id,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'hospital.zip')
                    document.body.appendChild(link)
                    link.click()
                })
                break;
            case 'edit':
                this.props.onAdminChangeHospital(row.id, row.name, row.verified)
                window.location.href = '/administration/info-hospital'
                break;
            case 'verification':
                this.props.pushRedirectTo(`/dashboard/verify/${ row.id }`)
                break;
            default:
                const ModalData = {
                    id: row.id,
                    content: "Anda akan menghapus Institusi ini. <br />Apakah Anda Yakin?",
                    buttonConfirmText: "Ya, Hapus Institusi",
                    buttonCancelText: "Batalkan",
                    ConfirmFunction: null
                }
                this.setState({
                    modalType: theAction,
                })
                this.handleOpenModal(ModalData)
                break;
        }
    },
    handleFirstPage(event) {
        event.preventDefault()

        const {
            page,
            pageSize,
            sort,
            order,
            filters,
        } = this.state

        if (page === 1) {
            return
        }

        this.setState({ jumpToPage: 1 })
        this.fetchHospital(1, pageSize, sort, order, filters)
    },
    handleNextPage(event){
        event.preventDefault()

        const {
            page,
            pageSize,
            sort,
            order,
            filters,
        } = this.state
        const setPage = page + 1

        this.setState({ jumpToPage: setPage })
        this.fetchHospital(setPage, pageSize, sort, order, filters)
    },
    handlePrevPage(event) {
        event.preventDefault()

        const {
            page,
            pageSize,
            sort,
            order,
            filters,
        } = this.state

        if (page < 1) {
            return
        }
        const setPage = page - 1

        this.setState({ jumpToPage: setPage })
        this.fetchHospital(setPage, pageSize, sort, order, filters)
    },
    handleEndPage(event, endPage){
        event.preventDefault()

        const {
            pageSize,
            sort,
            order,
            filters,
        } = this.state

        this.setState({ jumpToPage: endPage })
        this.fetchHospital(endPage, pageSize, sort, order, filters)
    },
    handleJumpPage(event, page){
        event.preventDefault()

        const {
            pageSize,
            sort,
            order,
            filters,
        } = this.state

        this.setState({ jumpToPage: page })
        this.fetchHospital(page, pageSize, sort, order, filters)
    },
    handlePageChange(data) {
        const {
            sort,
            order,
            filters,
        } = this.state

        this.setState({
            pagesSelected: data.value,
            pageSize: data.value,
        })
        this.fetchHospital(1, data.value, sort, order, filters)
    },
    handleSort(data) {
        let {
            page,
            pageSize,
            sort,
            order,
            filters,
        } = this.state
        const theValue = parseInt(data.value, 10)

        this.setState({ sortOptionsSelected: theValue })

        switch (theValue) {
            case 0:
                sort = 'created_at'
                order = 'desc'
                break;
            case 1:
                sort = 'updated_at'
                order = 'desc'
                break;
            case 2:
                sort = 'created_at'
                order = 'asc'
                break;
            default:
                sort = 'name'
                order = 'asc'
                break;
        }

        this.fetchHospital(page, pageSize, sort, order, filters)
    },
    handleAdvancedFilter(){
        this.setState({
            modalType: 'facility_filter'
        })
        this.handleOpenModal()
    },
    handleCancelFilter(e){
        e.preventDefault()
        this.setState({
            toggleFilter: false,
            facilities: [],
            types: [],
        })
        this.handleCloseModal()
    },
    handleCheckedFilter(thePrefix, theValue){
        let { facilities, types } = this.state
        if(thePrefix === 'facility'){
            facilities.push(theValue)
            this.setState({
                facilities: facilities,
            })
        }else{
            types.push(theValue)
            this.setState({
                types: types,
            })
        }
    },
    handleUncheckedFilter(thePrefix, theValue){
        let { facilities, types } = this.state
        if(thePrefix === 'facility'){
            const idxFacility = facilities.indexOf(theValue)
            facilities.splice(idxFacility,1)
            this.setState({
                facilities: facilities,
            })
        }else{
            const idxType = types.indexOf(theValue)
            types.splice(idxType,1)
            this.setState({
                types: types,
            })
        }
    },
    handleResetFilter(e){
        this.setState({
            facilities: [],
            types: [],
        })
    },
    handleFilter(field, value){
        let {
            pageSize,
            sort,
            order,
            filterSet,
            facilities,
            types,
        } = this.state
        
        switch(field){
            case 'name':
                filterSet.name = value
                break;
            case 'city':
                filterSet.city = value
                break;
            case 'status':
                filterSet.status = value
                break;
            default:
                filterSet.institution = value
                break;
        }

        const arrFilter = [
            {
                field: 'name',
                value: filterSet.name,
            },
            {
                field: 'kota',
                value: filterSet.city,
            },
            {
                field: 'verified',
                value: filterSet.status
                    ? filterSet.status === 'true'
                    : '',
            },
            {
                field: 'institution_type',
                value: filterSet.institution,
            },
            {
                field: 'facility',
                value: facilities.length ? facilities : '',
            },
            {
                field: 'hospital_type',
                value: types.length ? types : '',
            },
        ]
        const setFilter = (field !== 'reset') ? arrFilter : []

        this.setState({
            filterSet: {
                name: (field !== 'reset') ? filterSet.name : '',
                city: (field !== 'reset') ? filterSet.city : null,
                status: (field !== 'reset') ? filterSet.status : null,
                institution: (field !== 'reset') ? filterSet.institution : '',
            },
            filters: setFilter,
            facilities: (field !== 'reset') ? facilities : [],
            types: (field !== 'reset') ? types : [],
            jumpToPage: 1,
        })
        
        this.props.onTypeChange(this.props.allType, filterSet.institution)
        this.fetchHospital(1, pageSize, sort, order, setFilter)
    },
    fetchHospital: async function (page, pageSize, sort, order, filters) {
        this.props.showLoading()

        const fArray = {}
        const filterArr = filters.filter(val => (val.value !== '' && val.value !== null))
        filterArr.map(val =>
            fArray[val.field] = val.value
        )

        const filterData = JSON.stringify(fArray)

        HospitalApi.getHospitalDashboard(
            page,
            pageSize,
            sort,
            order,
            filterData
        ).then((res) => {
            const {
                status,
                data: {
                    data,
                },
            } = res

            if (status !== 200) {
                handleToastServiceError({ message: 'Something went wrong' })
                return
            }

            this.setState({
                hospitals: data.rows || [],
                page: page,
                rowMin: ((page - 1) * pageSize) + 1,
                rowMax: Math.min(page * pageSize, data.total_rows),
                totalPage: data.total_page
            })
            this.props.finishLoading()
        }).catch((e) => {
            handleToastServiceError(e)
        })
    },
    handleClickHospital(e, obj) {
        this.props.onAdminChangeHospital(obj.id, obj.name, obj.verified)
    },
    handleCloseModal(){
        this.setState({
            isModalOpen: false,
            modalType: null
        })
    },
    modalHandleSubmit() {
        let me = this
        let {
            page,
            pageSize,
            sort,
            order,
        } = me.state
        const idHospital = me.state.ModalData.id

        DashboardApi.delete(idHospital).then(function (response) {
            handleToast(response.data.message)
            me.handleCloseModal()
            me.fetchHospital(page, pageSize, sort, order, [])
        }, function (err) {
            let error = err

            if (err.response.status === 500) {
                error = {
                    message: err.response.statusText
                }
            }

            handleToastServiceError(error)
            me.handleCloseModal()
        })
    },
    handleOpenModal(data){
        this.setState({
            isModalOpen: true,
            ModalData: data,
        })
    },
    updateSelectedFilter(obj) {
        let {
            pageSize,
            sort,
            order,
        } = this.state

        let facility = []
        let type = []
        let strFacility = ''
        let strHospitalType = ''
        let name = ''
        let city = null
        let status = null
        let institution = null

        if (obj) {
            facility = obj.facility
            strFacility = facility.length ? facility : ''
            type = obj.type
            strHospitalType = type.length ? type : ''
            name = obj.name
            city = obj.city
            status = obj.status
            institution = obj.institution
        }

        const arrFilter = [
            {
                field: 'facility',
                value: strFacility,
            },
            {
                field: 'hospital_type',
                value: strHospitalType,
            },
            {
                field: 'name',
                value: name,
            },
            {
                field: 'kota',
                value: city,
            },
            {
                field: 'verified',
                value: status
                    ? status === 'true'
                    : '',
            },
            {
                field: 'institution_type',
                value: institution
                    ? institution !== '0'
                        ? parseInt(institution)
                        : null
                    : null,
            },
        ]

        this.setState({
            toggleFilter: false,
            isModalOpen: false,
            filters: arrFilter,
            facilities: facility,
            types: type,
            filterSet: {
                ...this.state.filterSet,
                name,
                city,
                status,
                institution,
            },
            jumpToPage: 1,
        })

        this.fetchHospital(1, pageSize, sort, order, arrFilter)
    },
}
export default gridHandler