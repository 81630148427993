import React from 'react'
import Chance from 'chance'

import Api from '../../../../../services/apis/MedicalRecord'
import HospitalApi from '../../../../../services/apis/HospitalApi'
import { handleToast, handleToastServiceError } from '../../../../../core/utils/UtilsToast'

const chance = new Chance()

const GridHandler = {
    handleOpenModal(type) {
        const { hospitalList } = this.state

        if (hospitalList.length === 0) {
            this.getHospitalList()
        }

        this.setState({
            isModalOpen: true,
            modalType: type,
        })
    },
    handleCloseModal() {
        this.setState({
            isModalOpen: false,
            modalType: '',
        })
    },
    handleClickRow(row) {
        this.getDetail(row.id)
    },
    onDetailClose() {
        this.setState({
            isDetailOpen: false,
            detailData: {},
        })
    },
    gridColumn() {
        return [
            {
                Header: 'Nama',
                accessor: 'patient_name',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return (
                        <div
                            className="clickable-field"
                            onClick={() => this.handleClickRow(row.original)}
                        >
                            {row.original.patient_name || '-'}
                        </div>
                    )
                },
            },
            {
                Header: 'Email',
                accessor: 'patient_email',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return (
                        <div
                            className="clickable-field"
                            onClick={() => this.handleClickRow(row.original)}
                        >
                            {row.original.patient_email || '-'}
                        </div>
                    )
                },
            },
            {
                Header: 'Phone',
                accessor: 'patient_phone',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return row.original.patient_phone || '-'
                },
            },
            {
                Header: 'Kode Booking',
                accessor: 'book_code',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return (
                        <div
                            className="clickable-field"
                            onClick={() => this.handleClickRow(row.original)}
                        >
                            {row.original.book_code || '-'}
                        </div>
                    )
                },
            },
            {
                Header: 'Lokasi',
                accessor: 'hospital_name',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return row.original.hospital_name || '-'
                },
            },
            {
                Header: 'Hasil',
                accessor: 'result',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    if (row.original.result) {
                        const { result, result_status: resultStatus } = row.original
                        const cls = resultStatus === 'positive'
                            ? 'reactive'
                            : resultStatus === 'negative'
                                ? 'non-reactive'
                                : 'pending'
                        return (
                            <div className={cls}>
                                {result}
                            </div>
                        )
                    }

                    return '-'
                },
            },
            {
                Header: 'Tanggal',
                accessor: 'book_date',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return row.original.book_date || '-'
                },
            },
            {
                Header: 'Status',
                accessor: 'send_email',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    const label = row.original.send_email
                        ? 'Sudah Dikirim'
                        : 'Belum Dikirm'
                    const badgeStyle = row.original.send_email
                        ? 'badge-success'
                        : 'badge-danger'

                    return (
                        <div className="badge-label">
                            <span className={`badge badge-pill ${badgeStyle}`}>
                                {label}
                            </span>
                        </div>
                    )
                },
            },
            {
                Header: 'Action',
                maxWidth: 110,
                filterType: 'reset',
                Cell: (row) => {
                    const { id, send_email: sendEmail } = row.original
                    if (!sendEmail) {
                        return (
                            <button onClick={() => this.sendEmail(id)} className="send-email">
                                Kirim Hasil
                            </button>
                        )
                    }

                    return null
                },
            },
        ]
    },
    fetchData: async function (state) {
        this.setState({ loading: true })
        this.page = state.page
        this.pageSize = state.pageSize

        let res = {}
        let fArray = {}
        let sort_property = ''
        let sort_value = ''

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'DESC' : 'ASC'
            this.sorted = state.sorted
        }

        if (state.filtered && state.filtered.length > 0) {
            state.filtered.map(val => {
                if (val.value !== '') {
                    fArray[val.id] = val.value
                }
                return true
            })
        }

        const filterData = JSON.stringify(fArray)

        try {
            res = await Api.list(
                this.page + 1,
                this.pageSize,
                sort_property,
                sort_value,
                filterData,
            )
        } catch (e) {
            this.setState({ loading: false })

            handleToastServiceError(e)
            return
        }

        const {
            status,
            data: resData,
        } = res

        if (status === 200) {
            const newRows = resData.data.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })

            this.setState({
                loading: false,
                data: newRows,
                pages: resData.data.total_page,
                rows: resData.data.total_rows,
            })
        } else {
            this.setState({ loading: false })
        }
    },
    getFilterList: async function () {
        try {
            const response = await Api.filter()
            const { data } = response.data
            const filterList = data

            this.setState({ filterList })
        } catch (e) {
            handleToastServiceError(e)
        }
    },
    getHospitalList: async function (keyword) {
        this.setState({ isSelectLoading: true })

        await HospitalApi.getHospital(keyword).then((res) => {
            const { data } = res.data

            if (res.status === 200) {
                this.setState({
                    hospitalList: data,
                    isSelectLoading: false,
                })
            }
        })
    },
    uploadCsv: async function (data) {
        this.setState({ isUploadLoading: true })
        let res = {}

        try {
            res = await Api.upload(data)
            this.setState({ isUploadLoading: false })
        } catch (error) {
            handleToastServiceError(error)
            setTimeout(() => {
                this.setState({ isUploadLoading: false })
            }, 300);
            return
        }

        const {
            status,
            message,
            data: resData,
        } = res.data

        if (status) {
            handleToast(message)
            this.handleCloseModal()
            this.setState({ resultUpload: {} })

            setTimeout(() => {
                this.fetchData({
                    page: this.page,
                    pageSize: this.pageSize,
                    sorted: this.sorted,
                    filtered: this.state.filtered,
                })
            }, 300);
        } else {
            this.setState({ resultUpload: resData })
        }
    },
    sendEmail: async function (idSingle) {
        let res = {}
        let id = idSingle
        const ids = []

        if (idSingle) {
            this.setState({ loading: true })
        } else {
            this.setState({ loadingE: true })
        }

        if (!idSingle) {
            const { selection, data } = this.state
            selection.forEach(item => {
                const getDdata = data.find(d => d._id === item)
                ids.push(getDdata.id)
            })
            id = ids.join(',')
        }

        try {
            res = await Api.email(id)
            if (idSingle) {
                this.setState({ loading: false })
            } else {
                this.setState({ loadingE: false })
            }
        } catch (error) {
            if (idSingle) {
                this.setState({ loading: false })
            } else {
                this.setState({ loadingE: false })
            }

            const { data: dataRes } = error.response
            const message = dataRes
                ? dataRes.code === 500
                    ? 'Internal Server Error server error'
                    : Object.keys(dataRes.data).map(k => dataRes.data[k]).join('\n')
                : 'Network error'

            handleToastServiceError({ message })
            return
        }

        const {
            status,
            message,
        } = res.data

        if (status) {
            handleToast(message)
            this.toggleAll('deselect-all')

            this.fetchData({
                page: this.page,
                pageSize: this.pageSize,
                sorted: this.sorted,
                filtered: this.state.filtered,
            })
        } else {
            handleToastServiceError({ message })
        }
    },
    getDetail: async function (id) {
        this.setState({ loading: true })

        await Api.detail(id).then((res) => {
            const { data } = res.data

            this.setState({ loading: false })
            if (res.status === 200) {
                this.setState({
                    isDetailOpen: true,
                    detailData: {
                        headTitle: <div>Data Pasien</div>,
                        ...data
                    },
                })
            }
        })
    }
}

export default GridHandler
