import { isEmpty, isArray } from 'lodash'

import Api from '../../../services/apis/Hospital/ServiceFee'
import {
    handleToast,
    handleToastServiceError
} from '../../../core/utils/UtilsToast'

const FormHandler = {
    buildForm() {
        this.nameField = this.TextInputFormView({
            validator: 'required',
            label: 'Nama Layanan',
            name: 'name',
            placeholder: 'Nama Layanan',
            control: this.state.Form.controls.name
        })

        this.codeField = this.TextInputFormView({
            validator: 'required',
            label: 'Kode Layanan',
            name: 'code',
            placeholder: 'Kode Layanan',
            control: this.state.Form.controls.code
        })

        this.priceField = this.TextInputPriceFormView({
            validator: 'required',
            label: 'Harga',
            name: 'price',
            placeholder: 'Harga',
            inputType: 'price',
            maxLength: 11,
            control: this.state.Form.controls.price
        })
    },
    getDetail: async function (id) {
        this.setState({ loading: true })

        await Api.detail(id, this.currentHospital).then((res) => {
            const { data } = res.data
            const price = data.price ? data.price.replace('.00', '') : ''

            this.setState({ loading: false })
            this.buildValue({
                ...data,
                price: `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
            })
        }).catch(error => {
            console.log('Something went wrong:', error)
        })
    },
    buildValue(data) {
        this.state.Form.patchValue(data)
    },
    handleSubmit: async function (e) {
        e.preventDefault();

        this.setState({ loading: true })

        let response = {}
        const {
            id,
            currentHospital,
            history,
        } = this.props
        const formValue = Object.assign({}, this.state.Form.value)
        formValue.price = parseInt(formValue.price.replace(/\./g, '').substring(0, 9), 10)
        formValue.hospital_id = currentHospital

        try {
            if (id) {
                response = await Api.update(id, formValue)
            } else {
                response = await Api.create(formValue)
            }

            setTimeout(() => {
                handleToast(response.data.message)
                this.setState({ loading: false })
            }, 0);

            history.replace('/administration/service-fee')
        } catch (e) {
            let message = e.message
            const errData = e.response

            if (!isEmpty(errData) && errData.data !== '') {
                message = errData.data.message
                if (!isArray(errData.data.data)) {
                    message = Object.keys(errData.data.data).map(k => errData.data.data[k]).join('\n')
                }
            }

            this.setState({ loading: false })
            handleToastServiceError({ message })
        }
    }
}

export default FormHandler
