import React from 'react'
import validateReturn from '../ValidatorForm'

import { FieldControl } from "react-reactive-form"

const TextInputPriceForm = {
    TextInputPriceFormView: function ({ label,
        validator,
        name,
        placeholder,
        control,
        readonly,
        inputType,
        maxLength,
        fieldControlAdditional }) {
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}
        Object.assign(control, additionalController)

        return <FieldControl
            name={name}
            render={(obj) => this.TextInputPriceFormRender(obj)}
            meta={{
                label: label,
                name: name,
                maxLength: maxLength,
                readonly: readonly,
                placeholder: placeholder,
                validator: validator,
                inputType: inputType || 'text'
            }}
            control={control}
        />
    },
    validateExtra(e, inputType, maxLength) {
        if (inputType === 'price') {
            if (maxLength > 0) {
                let val = e.target.value.replace(/[^0-9\.]/g, '') // eslint-disable-line
                val = val.substring(0, maxLength)
                val = val.replace(/\./g, '')

                if (val.charAt(0) === '0' && val.length > 1) {
                    val = val.substring(1)
                }

                e.target.value = val.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            }
        }
    },
    TextInputPriceFormRender: function ({ handler, pristine, hasError, meta }) {
        const isRequired = meta.validator === 'required' ? true : false

        return (
            <div className={!pristine && hasError(meta.validator)
                ? "form-line has-danger"
                : "form-line"}
            >
                {
                    meta.label ?
                        <label
                            id={`label-${meta.name}`}
                            className="form-control-label"
                            htmlFor={`${meta.name}`}
                        >
                            {meta.label}
                            {isRequired ? <span className="required">*</span> : null}
                        </label> :
                        null
                }
                <div className="form-group">
                    <input
                        className="form-control form-control-success"
                        type={meta.inputType}
                        placeholder={meta.placeholder}
                        id={meta.name}
                        {...handler()}
                        readOnly={meta.readonly}
                        maxLength={meta.maxLength}
                        onKeyUp={(e) => this.validateExtra(e, meta.inputType, meta.maxLength)}
                        onBlur={(e) => this.validateExtra(e, meta.inputType, meta.maxLength)}
                        onFocus={(e) => this.validateExtra(e, meta.inputType, meta.maxLength)}
                    />
                </div>
                <span className="error-info">
                    {
                        !pristine
                        && hasError(meta.validator)
                        && validateReturn(meta.validator)
                    }
                </span>
            </div>
        )
    }
}

export default TextInputPriceForm
