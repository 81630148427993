import React from 'react'
import Chance from 'chance'
import { isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'

import Api from '../../../../../services/apis/Subscription'
import { handleToast, handleToastServiceError } from '../../../../../core/utils/UtilsToast'

const chance = new Chance()

const GridHandler = {
    onPanelOpen(data) {
        let title = 'Tambah Paket Baru'
        const isEdit = !isEmpty(data)
        const packageId = this.getId()

        if (isEdit) title = 'Edit Paket'
        if (packageId === `${data && data.id}`) title = 'Detail Paket'

        this.setState({
            isDetailOpen: true,
            detailData: {
                headTitle: <div>{title}</div>,
                isEdit,
                ...data
            },
        })
    },
    onPanelClose() {
        const packageId = this.getId()

        if (packageId) {
            setTimeout(() => {
                this.props.history.push('/subscription/packages')
            }, 500);
        }

        this.setState({
            isDetailOpen: false,
            detailData: {},
        })
    },
    handleOpenModal(modalData) {
        this.setState({
            isModalOpen: true,
            modalData,
        })
    },
    handleCloseModal() {
        this.setState({
            isModalOpen: false,
            modalData: null,
        })
    },
    handleDelete(data) {
        const modalData = {
            ...data,
            action: 'delete',
        }

        this.handleOpenModal(modalData)
    },
    loadDataAfterAction() {
        const state = {
            pageSize: this.pageSize,
            page: this.page,
            sorted: this.sorted,
            filter: '',
            filtered: this.state.filtered,
        }

        this.fetchData(state)
    },
    gridColumn() {
        return [
            {
                Header: 'Nama Paket',
                accessor: 'name',
                headerClassName: '-sort',
                Cell: (row) => {
                    return row.original.name || '-'
                },
            },
            {
                Header: 'List Fitur',
                accessor: 'patient_name',
                sortable: false,
                Cell: (row) => {
                    if (row.original.features.length === 0) {
                        return '-'
                    }

                    return (
                        <ul>
                            {row.original.features.map((item, i) => (
                                <li key={i}>{item}</li>
                            ))}
                        </ul>
                    )
                },
            },
            {
                Header: 'Harga',
                accessor: 'price',
                headerClassName: '-sort',
                Cell: (row) => {
                    return this.toRp(row.original.price)
                },
            },
            {
                Header: 'Status Paket',
                accessor: 'active',
                headerClassName: '-sort',
                Cell: (row) => {
                    const label = row.original.active
                        ? 'Aktif'
                        : 'Non Aktif'
                    const badgeStyle = row.original.active
                        ? 'badge-primary'
                        : 'badge-danger'

                    return (
                        <div className="badge-label">
                            <span className={`badge badge-pill ${badgeStyle}`}>
                                {label}
                            </span>
                        </div>
                    )
                },
            },
            {
                Header: 'Action',
                maxWidth: 60,
                sortable: false,
                className: "grid-action",
                Cell: (row) => {
                    const { id, default: defaultPackage } = row.original

                    return (
                        <div className="grid-btn-wrapper">
                            <button>
                                <FontAwesomeIcon
                                    icon={faPencilAlt}
                                    onClick={() => this.getDetail(id)}
                                />
                            </button>
                            {defaultPackage !== true
                                ? (
                                    <button>
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            onClick={() => this.handleDelete(row.original)}
                                        />
                                    </button>
                                ) : null}
                        </div>
                    )
                },
            },
        ]
    },
    fetchData: async function (state) {
        this.setState({ loading: true })
        this.page = state.page
        this.pageSize = state.pageSize

        let res = {}
        let fArray = {}
        let sort_property = ''
        let sort_value = ''

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'DESC' : 'ASC'
            this.sorted = state.sorted
        }

        if (state.filtered.length > 0) {
            state.filtered.map(val => {
                if (val.value !== '') {
                    fArray[val.id] = val.value
                }
                return true
            })
        }

        const filterData = JSON.stringify(fArray)

        try {
            res = await Api.list(
                this.page + 1,
                this.pageSize,
                sort_property,
                sort_value,
                filterData,
            )
        } catch (e) {
            this.setState({ loading: false })

            handleToastServiceError(e)
            return
        }

        const {
            status,
            data: resData,
        } = res

        if (status === 200) {
            const newRows = resData.data.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })

            this.setState({
                loading: false,
                data: newRows,
                pages: resData.data.total_page,
                rows: resData.data.total_rows,
            })
        } else {
            this.setState({ loading: false })
        }
    },
    getDetail: async function (id) {
        this.setState({ loading: true })

        await Api.detail(id).then((res) => {
            const { data } = res.data

            this.setState({ loading: false })
            if (res.status === 200) {
                this.onPanelOpen(data)
            }
        })
    },
    modalHandleSubmit: async function () {
        const { modalData } = this.state

        if (modalData.action === 'delete') {
            try {
                const response = await Api.delete(modalData.id)
                handleToast(response.data.message)

                this.loadDataAfterAction();
                this.handleCloseModal()
            } catch (e) {
                handleToastServiceError(e)
                this.handleCloseModal()
            }
        }
    },
    toRp(number) {
        if (number) {
            return `Rp ${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
        }

        return 'Rp 0 (Gratis)'
    },
    getId() {
        const params = new URLSearchParams(window.location.search)
        const packageId = params.get('id')
        return packageId
    }
}

export default GridHandler
