import React from 'react'
import { store } from 'react-notifications-component'

import history from '../../routes/History';

const handleNotif = (data) => {
    let setDuration = 5000
    const {
        title,
        subtitle,
        photo,
        linkaction
    } = data

    const matches = subtitle.match(/\b(\w)/g)
    const inital = matches.join('').substring(0, 2)
    const background = ['47d8d1', 'ee80e5', '478dd8', 'ff6b8f', 'ffd76b', '47d8b5']
    const randBackground = background[Math.floor(Math.random() * background.length)]

    if (process.env.REACT_APP_NOTIF_DURATION) {
        setDuration = parseInt(process.env.REACT_APP_NOTIF_DURATION, 10)
    }

    store.addNotification({
        content: (
            <div className="notification-content">
                <div className="notification-close" />
                <p className="notification-title">{title}</p>
                <div className="notification-custom-content">
                    <div
                        className="notification-icon"
                        style={photo !== '' ? {} : { backgroundColor: `#${randBackground}` }}
                    >
                        {photo !== ''
                            ? <img src={photo} alt={subtitle} />
                            : (
                                <div className="no-img">{inital}</div>
                            )}
                    </div>
                    <p
                        className="notification-message"
                        style={linkaction === '' ? { width: '260px' } : {}}
                    >
                        {subtitle}
                    </p>
                    {linkaction !== ''
                        ? <button onClick={() => history.push(linkaction)}>Lihat</button>
                        : null}
                </div>
            </div>
        ),
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
            duration: setDuration,
            pauseOnHover: true,
        },
    })
}

export default handleNotif
