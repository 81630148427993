import React from 'react'
import validateReturn from '../ValidatorForm'

import {FieldControl} from "react-reactive-form"

const TextInputForm = {
    TextInputFormDoctorView: function({label, validator, name, placeholder, readonly, control, inputType, fieldControlAdditional}) {
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}

        Object.assign(control, additionalController)

        return <FieldControl
            name={name}
            render={(obj) => this.TextInputFormDoctorRender(obj, this.handleFocus)}
            meta={{ label: label, name: name, placeholder:placeholder, readonly:readonly, validator: validator, inputType: inputType || 'text' }}
            control = { control }
        />
    },
    
    TextInputFormDoctorRender: function({handler, touched, hasError, meta}, handleFocus) {
        const newHandler = handler()
        Object.assign(newHandler, {
            onFocus: handleFocus,
        })
        return (
            <div onChange={this.handleChange()} onBlur={this.handleBlur} className={touched && hasError(meta.validator) ? "form-line has-danger" : "form-line"}>
                <label id={`label-${meta.name}`} className="form-control-label" htmlFor={`${meta.label}`}>
                    {`${meta.label}`}
                </label>
                <div className="form-group">
                    <input 
                        maxLength="19" 
                        onKeyUp={e => this.handleKeyUp(e)} 
                        type={meta.inputType} 
                        className="form-control form-control-success" 
                        id={`${meta.label}`} 
                        readOnly={ meta.readonly }
                        placeholder={ meta.placeholder }
                        {...newHandler}  
                    />
                </div>
                <span className="error-info">
                    {
                        touched
                        && hasError(meta.validator)
                        && validateReturn(meta.validator)
                    }
                </span>
            </div>
        )
    },

    handleFocus(event) {
        if(event.target.value === '' && !event.target.parentNode.classList.contains('focused')){
            event.target.parentNode.classList.toggle('focused')
        }
    },
    handleChange(){
        // console.log('handlechange')
        // console.log(this.state.filledForm)
    },
    handleBlur(event){
        if(event.target.value === '' && event.target.parentNode.classList.contains('focused')){
            event.target.parentNode.classList.toggle('focused')
        }
    },
    handleKeyUp(event){
        let theValue = event.target.value.split("-").join("")
        theValue = theValue.replace(/[^0-9]/g, '');
        if (theValue.length > 0) {
            theValue = theValue.match(new RegExp('.{1,4}', 'g')).join("-");
        }
        event.target.value = theValue
    }

}

export default TextInputForm
