import React, { Component } from 'react'

class TitleTemplate extends Component {
    render() {
        const {
            title,
            moduleName
        } = this.props

        let setCMargin = ''
        if ([
            'Dashboard', 'Appointment', 'Doctor', 'Patient', 'EMR', 'Billing', 'Setting', 'User'
        ].indexOf(moduleName) !== -1) {
            setCMargin = 'mt5'
        }

        if (title) {
            return (
                <header className="page-header">
                    <div className="container-fluid">
                        <h2 className={`no-margin-bottom page-title ${setCMargin}`}>{title}</h2>
                    </div>
                </header>

            )
        } else {
            return null
        }
    }
}

export default TitleTemplate