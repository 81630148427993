import React from 'react'
import Dropzone from 'react-dropzone'

const thumb = {
  display: 'block',
  borderRadius: 2,
  margin: '0 auto',
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
  left: 0,
  top: 0,
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  width: 'fit-content',
  margin: '0 auto',
  overflow: 'hidden'
}

const img = {
  display: 'block',
  width: '80px',
  height: '80px',
  borderRadius: 40,
  objectFit: 'cover',
};

const remove = {
  textAlign: 'center',
  height: '16px',
  fontSize: '14px',
  fontWeight: '500',
  color: '#ed8282',
  cursor: 'pointer'
};

export default class MultipleImageForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      placeholder: true,
      previewFromFecth: null,
      dropedImage: false,
    }
  }

  onDrop(files) {

    this.setState({
      files: files.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })),
      previewFromFecth: null,
      placeholder: false,
      dropedImage: true
    })

    files.forEach(file => {
      const reader = new FileReader()
      reader.onload = () => {
        this.props.onUpload(reader.result, file)
      }
      // reader.onabort = () => console.log('file reading was aborted')
      // reader.onerror = () => console.log('file reading has failed')

      reader.readAsDataURL(file)
    });
  }


  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach(file => URL.revokeObjectURL(file.preview))
  }

  render() {
    const {
      placeholder,
      dropedImage,
    } = this.state

    const {
      icon,
      desc,
      removeTxt,
      image,
      current,
      photo_url,
      label,
      maxSize,
      maxphoto,
    } = this.props

    let placeholder1 = <p><img src={icon} alt="" /></p>
    let placeholder2 = <p>{desc}</p>
    let placeholder3 = false
    const length = image.length

    if (!placeholder || (length > 0)) {
      placeholder1 = ''
      placeholder2 = ''
      placeholder3 = true
    }

    if (!dropedImage && current.value) {
      placeholder1 = ''
      placeholder2 = ''
      placeholder3 = true
    }

    let imageData = current.value ? current.value : image;
    return (
      <div className="form-upload">
        {imageData.length > 0 &&
          <>
            {imageData.map((file, index) => ((
              <div key={index} className="form-line" style={{ width: '30%', marginRight: '10px' }}>
                <label className="form-control-label" >{label}</label>
                <div className="file-container" style={{ height: '150px' }}>
                  <div style={{ width: '100%', height: 'auto' }}>
                    <div style={thumb} key={index}>
                      <div style={thumbInner}>
                        <img
                          src={/(jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg)$/i.test(file) ? photo_url.value + file : file}
                          style={img}
                          alt=""
                        />
                      </div>
                    </div>
                    {placeholder1}
                    {placeholder2}
                  </div>
                  {!placeholder || (length > 0)}
                  {placeholder3 &&
                    <div style={remove}>
                      <p onClick={() => { this.props.onClickRemove(index) }}>{removeTxt}</p>
                    </div>
                  }
                </div>
              </div>
            )
            ))}
          </>
        }


        {imageData.length < maxphoto &&
          <div className="form-line" style={{ width: '30%', marginRight: '10px' }}>
            <label className="form-control-label" >{label}</label>
            <div className="file-container" style={{ height: '150px' }}>
              <Dropzone
                accept="image/*"
                onDrop={this.onDrop.bind(this)}
                maxSize={maxSize}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="file-upload" style={{ width: '100%' }} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p><img src={icon} alt="" /></p>
                    <p>{desc}</p>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        }
      </div>
    )
  }
}
