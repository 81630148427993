import React from 'react'
import validateReturn from '../../utils/ValidatorForm'
import { FieldControl } from "react-reactive-form";
import Select from 'react-select';

const DropDownForm = {
    DropDownFormView: function({name, nameText, stateName, meta, control, fieldControlAdditional}) {
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}
        Object.assign(control, additionalController);
        meta.name = name
        meta.nameText = nameText
        const callBack = meta.callBack ? meta.callBack : null
        return <FieldControl
            name = {name}
            strict = {false}
            meta = {{...meta}}
            render={(obj) => this.DropDownFormRender(obj, control, stateName, name, nameText, callBack)}
            control = {control}
        />
    },
    onChangeEvent: function (e, onChangeEvent, name, callBack) {
        onChangeEvent(e)
        if(callBack){
            callBack(this, name)
        }
    },
    DropDownFormRender: function( {handler, touched, hasError, meta}, control, stateName, name, nameText, callback) {
        const SelectedVal = control ? control.value : null
        const options = meta.options ? meta.options : this.state[stateName]
        const isEdit = this.state.isEdit
        const editChanged = this.state.editChanged
        const isRequired = meta.validator === 'required' ? true : false

        if(isEdit && !editChanged && meta.EditshowOnlyText){
            return(
                <div
                    className={`form-line${touched && hasError(meta.validator) ? ' has-danger' : ''}${control.disabled ? ' disabled' : ''}`}
                >
                    {
                        meta.label ?
                            <label
                                id={`label-${meta.name}`}
                                className="form-control-label"
                                htmlFor={`${meta.label}`}
                            >
                                {`${meta.label}`}
                                {isRequired ? <span className="required">*</span> : null}
                            </label> :
                            null
                    }

                    <div className="form-group-wizard">
                        <Select
                            className="dropdown-wizard"
                            classNamePrefix="react-select"
                            name={name}
                            options={ [] }
                            placeholder={ meta.placeholder ? meta.placeholder : " " }
                            isDisabled={ true }
                        />
                    </div>
                </div>
            )
        }else{
            return (
                <div
                    className={`form-line${touched && hasError(meta.validator) ? ' has-danger' : ''}${control.disabled ? ' disabled' : ''}`}
                >
                    {
                        meta.label ?
                            <label
                                id={`label-${meta.name}`}
                                className="form-control-label"
                                htmlFor={`${meta.label}`}
                            >
                                {`${meta.label}`}
                                {isRequired ? <span className="required">*</span> : null}
                            </label> :
                            null
                    }

                    <div className="form-group-wizard">
                        <Select
                            className="dropdown-wizard"
                            classNamePrefix="react-select"
                            name={name}
                            options={options}
                            placeholder={ meta.placeholder ? meta.placeholder : " " }
                            {...handler()}
                            inputProps={{
                                readOnly:meta.readonly
                            }}
                            isDisabled={control.disabled}
                            onChange={(e) => this.onChangeEvent(e, handler().onChange, name, callback)}
                            onBlur={ handler().onBlur }
                            value={options.find(option => String(option.value) === String(SelectedVal))}
                        />
                    </div>
                    <span className="error-info">
                        {
                            touched
                            && hasError(meta.validator)
                            && validateReturn(meta.validator)
                        }
                    </span>
                </div>
            )
        }
    },
    handleFocus(event) {
        if(event.target.value === '' && !event.target.parentNode.classList.contains('focused')){
            event.target.parentNode.classList.toggle('focused')
        }
    }
}
export default DropDownForm