import React, { Component } from 'react'
import Icons from '../../core/utils/Icons'
import SuccessForgotPass from './component/info/SuccessForgotPass'
import { Button, Container, Form, FormFeedback, FormGroup, Input } from 'reactstrap'
import { NavLink, Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { handleToastServiceError } from '../../core/utils/UtilsToast'
import { connect } from 'react-redux'
import { loginMapDispatchToProps, loginMapStateToProps } from '../../core/const/LoginConst'
import Api from '../../services/apis/LoginApi'

class ForgotPass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            requestEmailSuccess: false,
            isLoading: false,
            isButtonEnable: false,
            redirectTo: null,
            validate: {
                emailState: '',
            },
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePaste = this.handlePaste.bind(this)
        this.validateEmail = this.validateEmail.bind(this)
        this.submitForm = this.submitForm.bind(this)
    }

    handleChange = async (event) => {
        const {target} = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const {name} = target;
        await this.setState({
            [name]: value,
        })
    }

    handlePaste(e){
        const emailPaste = e.clipboardData.getData('Text')
        this.setState({
            email: emailPaste
        })
    }

    validateEmail(e) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { email,validate } = this.state
        let isButtonEnable = false
        if (emailRex.test(e.target.value)) {
            validate.emailState = 'has-success'
        } else {
            validate.emailState = 'has-danger'
        }

        if(email.length > 0 && validate.emailState === 'has-success'){
            isButtonEnable =  true
        }else{
            isButtonEnable =  false
        }

        this.setState({
            validate: validate,
            isButtonEnable: isButtonEnable
        })
    }

    submitForm(e) {
        e.preventDefault()
        this.setState({
            isLoading: true
        })

        const jsonPost = {
            email: this.state.email
        }

        Api.forgotpass(JSON.stringify(jsonPost)).then(result => {
            this.setState({
                isLoading: false
            })

            if (result.data.code === 200) {
                this.setState({
                    requestEmailSuccess: true
                })
            } else if (result.data.code === 400) {
                const errData = result.data.data
                const message = Object.keys(errData).map(k => errData[k]).join('\n')

                handleToastServiceError({ message })
            }
        }).catch(error => {
            const errData = error.response.data.data
            const message = Object.keys(errData).map(k => errData[k]).join('\n')

            this.setState({
                isLoading: false
            })

            handleToastServiceError({ message })
        })
    }

    componentDidMount(){
        if(this.props.dataLogin){
            this.setState({
                redirectTo: "/"
            })
        }
    }

    render() {
        const { validate, requestEmailSuccess, isButtonEnable, redirectTo } = this.state
        const isDisabled = !isButtonEnable

        if(redirectTo){
            return(<Redirect to={ redirectTo } />)
        }

        if(requestEmailSuccess){
            return(
                <SuccessForgotPass { ...this.props }/>
            )
        }
        return(
            <div className="login-page-cinnamon">
                <ToastContainer
                    position="top-center"
                    autoClose={ 5000 }
                    hideProgressBar={ false }
                    newestOnTop={ false }
                    closeOnClick
                    rtl={ false }
                    pauseOnVisibilityChange
                />
                <Container className="d-flex align-items-center">
                    <div className="form-login-cinnamon">
                        <div className="row">
                            <div className="wrapper-logo">
                                <img className="login-logo" src={ Icons.logoLogin } alt="Login" />
                            </div>
                            <div className="wrap-login-center wrap-forgotpass">
                                <div className="login-note">
                                    <h3>Lupa Kata Sandi</h3>
                                    <p>
                                    Masukkan email yang terdaftar. Kami akan mengirimkan kode verifikasi untuk mengatur ulang kata sandi.
                                    </p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="content">
                                        <Form className="login-form" onSubmit={(e) => this.submitForm(e)}>
                                            <FormGroup>
                                                <label className="login-label">Email</label>
                                                <div className="form-group-login">
                                                    <Input
                                                        type="email"
                                                        name="email"
                                                        id="login-username"
                                                        placeholder="Isi dengan email anda"
                                                        data-msg="Please enter your username"
                                                        className="input-material"
                                                        autoFocus
                                                        valid={ validate.emailState === 'has-success' }
                                                        invalid={ validate.emailState === 'has-danger'}
                                                        onChange={(e) => {
                                                            this.validateEmail(e)
                                                            this.handleChange(e)
                                                        }}
                                                        onPaste={(e) => {
                                                            this.validateEmail(e)
                                                            this.handlePaste(e)
                                                        }}
                                                    />
                                                    <FormFeedback>
                                                        Masukkan format email yang benar.
                                                    </FormFeedback>
                                                </div>
                                            </FormGroup>
                                            {
                                                !this.state.isLoading ? 
                                                <div className="wrap-button-auth"> 
                                                    <Button 
                                                        type="submit"
                                                        className="btn btn-primary login-button"
                                                        disabled={ isDisabled }
                                                    >Lanjut</Button>
                                                    <NavLink className="link-normal" to="/">Kembali</NavLink>
                                                </div> : 
                                                <div className="loading">Loading...</div>
                                            }
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(ForgotPass)