import React, { Component } from 'react'
import { Sticky } from 'react-sticky'
import ScrollMenu from './Menu'

class StickyNav extends Component {
    render() {
        return (
            <div className="menu-sticky" id="wrapper">
                <Sticky topOffset={this.props.accessBy === 'hospital' ? -110 : -49} >
                    {
                        ({ isSticky }) => {
                            this.props.setSticky(isSticky)
                            return <ScrollMenu isSticky={isSticky} />
                        }
                    }
                </Sticky>
            </div>
        )
    }
}

export default StickyNav