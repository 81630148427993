import axios, { axiosBase } from '../MasterConfig'

const axiosB = axiosBase()
const ApiUrl = "appointment"

class Api {
    static list = (
        page,
        pageSize,
        sortProperties,
        sortValue,
        filterData,
        currentHospital,
        did,
    ) => {
        let setSort = ''
        if (sortProperties) {
            setSort = `&sort=${sortProperties}&order=${sortValue}`
        }

        let setDid = ''
        if (did) {
            setDid = `&id_doctor=${did.doctor_id}`
        }

        let setFilter = ''
        if (filterData !== '{}') {
            setFilter = `&filter=${filterData}`
        }

        return axiosB.get(`/hospital/${ApiUrl}?id_hospital=${currentHospital}&page=${page}&limit=${pageSize}${setSort}${setDid}${setFilter}`)
    }
    static detail = (appointmentId, hospitalId) => axiosB.get(`/hospital/${ApiUrl}/${appointmentId}?id_hospital=${hospitalId}`)
    static queueList = (hospitalId, scheduleId) => {
        return axiosB.get(`/hospital/${ApiUrl}/queue?hospital_id=${hospitalId}&schedule_id=${scheduleId}`)
    }
    static queueUpdateCall = (appointmentId, data) => {
        return axiosB.put(`/hospital/${ApiUrl}/queue/${appointmentId}`, data)
    }
    static email = (hospitalId, ids) => axiosB.post(`/hospital/${ApiUrl}/email`, { hospital_id: hospitalId, ids })
    static emailRebook = (hospitalId, id) => axiosB.post(`/hospital/${ApiUrl}/rebook`, { hospital_id: hospitalId, id })
    static confirm = (id) => axios.put(`/${ApiUrl}/confirm/${id}`)
    static cancel = (id, data) => axios.put(`/${ApiUrl}/cancel/${id}`, data)
    static confirmNew = (url, data) => axiosB.put(url, data)
    static filterList = (hid, did) => {
        let setDid = ''
        if (did) {
            setDid = `&id_doctor=${did.doctor_id}`
        }

        return axiosB.get(`/hospital/${ApiUrl}/filter?id_hospital=${hid}${setDid}`)
    }
    static notification = hid => axiosB.get(`/${ApiUrl}/notification?hospital_id=${hid}`)
    static notificationRead = (hid, nid) => axiosB.put(`/${ApiUrl}/notification/${nid}`, { 'hospital_id': hid })
    static searchBankAccount = (payload) => axiosB.post(`/hospital/bankaccount`, payload)
}

export default Api
