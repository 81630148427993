import axios from 'axios'
import { store } from '../flux/Store'

export function axiosSuperadmin() {
  let headers = { 'Content-Type': 'application/json' }

  if (process.env.REACT_APP_X_API_AUTH) {
    headers = Object.assign({}, headers, {
      'X-API-Auth': process.env.REACT_APP_X_API_AUTH,
    })
  }

  // Create instance
  let instance = axios.create({
    baseURL: process.env.REACT_APP_API_HOST.replace('admin', 'superadmin'),
    timeout: 30000,
    headers,
  })

  // Set the AUTH token for any request
  instance.interceptors.request.use(config => {
    const tokenFromRedux = store.getState().loginReducer.token
    const token = tokenFromRedux
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    return config
  })

  // redirect to unauthorize
  instance.interceptors.response.use(null, function (error) {
    const resp = error.response

    if (typeof resp === 'undefined') {
      return Promise.reject(error)
    }

    return Promise.reject(error)
  })

  return instance
}

export function axiosMaster() {
  let headers = { 'Content-Type': 'application/json' }

  if (process.env.REACT_APP_X_API_AUTH) {
    headers = Object.assign({}, headers, {
      'X-API-Auth': process.env.REACT_APP_X_API_AUTH,
    })
  }

  // Create instance
  let instance = axios.create({
    baseURL: process.env.REACT_APP_API_HOST.replace('admin', 'master'),
    timeout: 30000,
    validateStatus(status) {
      return status < 500 && status !== 401;
  },
    headers,
  })

  // Set the AUTH token for any request
  instance.interceptors.request.use(config => {
    const tokenFromRedux = store.getState().loginReducer.token
    const token = tokenFromRedux
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    return config
  })

  // redirect to unauthorize
  instance.interceptors.response.use(null, function (error) {
    const resp = error.response

    if (typeof resp === 'undefined') {
      return Promise.reject(error)
    }

    return Promise.reject(error)
  })

  return instance
}

export function axiosBase() {
  let headers = { 'Content-Type': 'application/json' }

  if (process.env.REACT_APP_X_API_AUTH) {
    headers = Object.assign({}, headers, {
      'X-API-Auth': process.env.REACT_APP_X_API_AUTH,
    })
  }

  // Create instance
  let instance = axios.create({
    baseURL: process.env.REACT_APP_API_HOST.replace('admin', ''),
    timeout: 30000,
    headers,
  })

  // Set the AUTH token for any request
  instance.interceptors.request.use(config => {
    const tokenFromRedux = store.getState().loginReducer.token
    const token = tokenFromRedux
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    return config
  })

  // redirect to unauthorize
  instance.interceptors.response.use(null, function (error) {
    const resp = error.response

    if (typeof resp === 'undefined') {
      return Promise.reject(error)
    }

    return Promise.reject(error)
  })

  return instance
}

const fetchClient = () => {
  let headers = { 'Content-Type': 'application/json' }

  if (process.env.REACT_APP_X_API_AUTH) {
    headers = Object.assign({}, headers, {
      'X-API-Auth': process.env.REACT_APP_X_API_AUTH,
    })
  }

  // Create instance
  let instance = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    timeout: 120000,
    headers,
  })

  // Set the AUTH token for any request
  instance.interceptors.request.use(config => {
    const tokenFromRedux = store.getState().loginReducer.token
    const token = tokenFromRedux
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    return config
  })

  // redirect to unauthorize
  instance.interceptors.response.use(null, function (error) {
    const resp = error.response

    if (typeof resp === 'undefined') {
      return Promise.reject(error)
    }

    return Promise.reject(error)
  })

  return instance
}

export default fetchClient()

