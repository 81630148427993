import React from 'react'
import {FieldControl} from "react-reactive-form"
import Icon from '../Icons'

class SmallImageForm extends React.Component
{
    constructor(props){
        super(props)
        this.preUploadImage = this.preUploadImage.bind(this)
        this.state = {
            previewImageUrl: null
        }
    }

    preUploadImage(e, control){
        const file = e.target.files[0]
        if(file){
            control.patchValue(file)
            this.setState({
                previewImageUrl: URL.createObjectURL(file)
            })
        }
    }

    render(){
        const previewImageUrl = this.state.previewImageUrl
        const { name, control, label, smallNote, className } = this.props
        return(
            <div className="wrap-upload-image">
                <FieldControl
                    name={ name }
                    render={() => {
                        let preview = previewImageUrl ? previewImageUrl : control.value
                        return(
                            <div className={ "form-line" }>
                                <label className="form-control-label">{ label }</label>
                                <div className={ `upload-btn-wrapper ${className}` }>
                                    <button className="btn">
                                        {
                                            preview ?
                                            <img className="preview" src={ preview } alt="preview"/> : 
                                            <img className="icon" src={ Icon.camera } alt="icon"/>
                                        }
                                    </button>
                                    <input 
                                        ref="logo" 
                                        type="file" 
                                        onChange={ (e) => this.preUploadImage(e, control) }
                                    />
                                </div>
                            </div>    
                            )
                        }}
                        meta={{ 
                            inputType: 'file' 
                        }}
                />
                <span className="child-note">{ smallNote }</span>
            </div>
        )
    } 
}
export default SmallImageForm