import React from 'react'
import Api from "../../../services/apis/Appointment/Main"
import Chance from "chance"
import dateFns from "date-fns"
import IndonesianLocale from 'date-fns/locale/id'
const chance = new Chance();

const GridHandler = {
    gridColumn: function (ContextMenuProvider) {
        return [
            {
                Header: "Nama Pasien",
                accessor: "fullname",
                Cell: cellInfo => {
                    return(
                    <div>
                        { cellInfo.original.firstname } { cellInfo.original.lastname ? cellInfo.original.lastname : '' }
                    </div>
                    )
                }
            },
            {
                Header: "Tanggal Lahir",
                accessor: "dob",
                Cell: cellInfo => {
                    return `${ this.parseDate(cellInfo.original.dob) }`
                }
            },
            {
                Header: "No. Telepon",
                accessor: "phone",
            },
            {
                Header: () => {
                    return null
                },
                filterable: false,
                sortable: false,
                Cell: row => (
                    <button type="button" className="pick-patient" onClick={() => this.handleClick('pick', row.original)}>
                        Pilih
                    </button>
                )
            },
        ]
    },
    parseDate: function(strDate){
        var months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        const date = new Date(strDate)

        return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`
    },
    handleClick: function (action, theRow){
        switch (action) {
            case 'pick':
                this.props.handleAssignPatient(theRow)
                break
            default:
                return null
        }
    },
    dateToString(Obj){
        const stringDate = dateFns.format(Obj, 'D MMMM YYYY', { locale:IndonesianLocale })
        return stringDate.toLowerCase()
    },
    fetchData: async function (state) {
        // search keyword
        let fArray = {}
        state.filtered.map(val => 
            fArray[val.id] = val.value
        )

        const filterData = JSON.stringify(fArray)
        const currentHospital = this.props.currentHospital
        this.setState({filterTrigger: false})
        this.pageSize = state.pageSize
        this.page = state.page
        this.setState({loading: true})
        let sort_property = ''
        let sort_value = ''
        let res

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'desc' : 'asc'
            this.sorted = state.sorted
        }else{
            sort_property = 'created_at'
            sort_value = 'DESC'
            this.sorted = [{
                id: 'created_at',
                desc: 'desc'
            }]
        }

        try {
            res = await Api.getPatient(currentHospital, state.page + 1, state.pageSize, sort_property, sort_value, filterData)
        } catch (e) {
            this.setState({
                data: [],
                rows: [],
                filtered: [],
                loading: false
            })
            return
        }

        if (React.isValidElement(res)) {
            return
        }

        if (res.data.code === 200) {
            let newRows = res.data.data.rows.map(item => {
            	const _id = chance.guid()
            	return {
            		_id,
            		...item
            	}
            })

            if (this._isMounted) {
                this.setState({
                    data: newRows,
                    pages: res.data.data.total_page,
                    rows: res.data.data.total_rows,
                    loading: false,
                })
            }

        }else{
            this.setState({
                data: [],
                rows: [],
                filtered: [],
                loading: false
            })
        }
    },

    refreshData(){
        this.fetchData({
            page: this.page,
            pageSize: this.pageSize,
            filtered: [],
            sorted: this.sorted,
        })
    }
}

export default GridHandler