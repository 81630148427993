import React, { Component } from 'react'
import dateFns from 'date-fns'
import { isEmpty } from 'lodash'

import MedicalRecord from './MedicalRecord'

class DetailPatient extends Component {
    constructor(props) {
        super(props)
        this.state = {}

        this.addressText = this.addressText.bind(this)
    }

    addressText(addr) {
        const propinsi = !isEmpty(addr.propinsi) ? `${addr.propinsi}, ` : ''
        const kota = !isEmpty(addr.kota) ? `${addr.kota}, ` : ''
        const kecamatan = !isEmpty(addr.kecamatan) ? `${addr.kecamatan}, ` : ''
        const kelurahan = !isEmpty(addr.kelurahan) ? `${addr.kelurahan}, ` : ''
        const kodepos = !isEmpty(addr.kodepos) ? addr.kodepos : ''

        if (isEmpty(addr.alamat) && isEmpty(propinsi) && isEmpty(kota) && isEmpty(kecamatan) && isEmpty(kelurahan) && isEmpty(kodepos)) {
            return '-'
        }

        return `${propinsi}${kota}${kecamatan}${kelurahan}${kodepos}`
    }

    render() {
        const {
            nama,
            no_rekam_medis: noRekamMedis,
            tempat_lahir: tmpLahir,
            tanggal_lahir: tglLahir,
            jenis_kelamin: jnsKelamin,
            telepon,
            email,
            alamat,
            patientRecord,
            onPanelMROpen,
        } = this.props

        return (
            <div className="emr-detail-patient">
                <div className="title">Informasi Pasien</div>
                <div className="field">
                    <div className="label">Nama Pasien</div>
                    <div className="value">{nama}</div>
                </div>
                <div className="field">
                    <div className="label">Nomor Resume Medis</div>
                    <div className="value">{noRekamMedis || '-'}</div>
                </div>
                <div className="field">
                    <div className="label">TTL</div>
                    <div className="value">
                        {`${tmpLahir
                            ? `${tmpLahir}, `
                            : ''}${dateFns.format(tglLahir, 'DD MMMM YYYY')}`}
                    </div>
                </div>
                <div className="field">
                    <div className="label">Jenis Kelamin</div>
                    <div className="value">
                        {jnsKelamin === 'F'
                            ? 'Perempuan'
                            : 'Laki-laki'}
                    </div>
                </div>
                <div className="field">
                    <div className="label">Telepon</div>
                    <div className="value">{telepon}</div>
                </div>
                <div className="field">
                    <div className="label">Email</div>
                    <div className="value">{email || '-'}</div>
                </div>
                <div className="field">
                    <div className="label">Alamat</div>
                    <div className="value address">
                        {`${alamat ? `${alamat}${this.addressText(this.props)
                            ? ', ' : ''}` : ''}${this.addressText(this.props)}`}
                    </div>
                </div>
                <div className="title">Resume Medis</div>
                <MedicalRecord
                    patientRecord={patientRecord}
                    onPanelMROpen={onPanelMROpen}
                    accessBy="patient_detail"
                />
            </div>
        )
    }
}

export default DetailPatient
