import React from 'react'
import dateFns from 'date-fns'
import ReactTable from 'react-table'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'

import CustomFilter from '../lib/CustomFilter'
import CustomPagination from '../lib/CustomPagination'
import MasterGridHandler from '../lib/MasterGridHandler'
import MasterGridColumn, { checkboxProps } from '../lib/MasterGridColumn'

import 'react-table/react-table.css'
import 'react-contexify/dist/ReactContexify.min.css'

library.add(faTrash, faPencilAlt)

const CheckboxTable = checkboxHOC(ReactTable);

export default class MasterGrid extends React.Component {
    constructor(props) {
        super(props);

        Object.assign(this, MasterGridHandler);

        this.customFilter = ['DATE', 'DATE_RANGE']
        this.extraProperties = {}
        this.checkboxProps = checkboxProps
        // this.MyAwesomeMenu = this.MyAwesomeMenu.bind(this);
        this.getColumns = this.getColumns.bind(this)
        this.filterable = false
        //do not override
        this.toggleSelection = this.toggleSelection.bind(this)
        this.toggleAll = this.toggleAll.bind(this)
        this.isSelected = this.isSelected.bind(this)
        this.gridOnPageChange = this.gridOnPageChange.bind(this)
        this.updateStatusState = this.updateStatusState.bind(this)
        this.updateStateFor = this.updateStateFor.bind(this)
        this.handleFilterReset = this.handleFilterReset.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.gridType = '';
        this.filteredTemp = [];
        this.selectedMax = 0;
        this.clickable = false
        // please set from children below, check file contoh

        this.state = {
            reset: false,
            rangeOpen: false,
            filtered: [],
            headerFilter: {},
        }
    }

    mapDefault() {
        this.keyTable = '_id';
        this.ignoreColumn = [
            '_id'
        ];
        this.selectedTableStatus = true;
        this.gridColumn = MasterGridColumn;
        this.onClickContext = this.onClickContext.bind(this)
        this.getData = this.getData.bind(this)
        this.dataFromRedux = false
        this.pageSize = 10
        this.defaultSorted = []
        this.gridMapping = (this.selectedTableStatus) ? CheckboxTable : ReactTable
    }

    updateStatusState() {
        this.setState({ onUpdate: true })
    }

    updateStateFor(state) {
        this.setState(state)
    }

    /** running when state or props update */
    componentDidUpdate(/*prevProps, prevState*/) {
        if (this.props.hasOwnProperty('callBackState')) {
            let updatedState = {};
            let i = 0
            const state = this.state;
            const triggers = [
                'onUpdate',
                'onPermission',
            ];
            triggers.forEach(v => {
                if (state[v] === true) {
                    updatedState[v] = false;
                    i++;
                }
            });
            if (i > 0) {
                this.setState(updatedState, () => {
                    this.props.callBackState(state)
                });
            }

        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setStateFilter = (id, value) => {
        const filtered = this.filteredTemp
        let setFiltered = filtered
        const getExist = filtered.find(value => value.id === id)

        if (value !== '') {
            if (getExist) {
                setFiltered = filtered.filter(value => value.id !== id)
            }

            setFiltered.push({ id, value })
        } else {
            setFiltered = filtered.filter(value => value.id !== id)
        }
        this.filteredTemp = setFiltered

        return setFiltered
    }

    submitFilter = (id, value) => {
        const setFiltered = this.setStateFilter(id, value)

        this.page = 0
        this.setState({
            filtered: setFiltered,
        })

        this.fetchData({
            page: this.page,
            pageSize: this.pageSize,
            filtered: setFiltered,
            sorted: this.sorted,
        })
    }

    resetFilter = () => {
        this.filteredTemp = []
        this.setState({
            reset: true,
            filtered: [],
            headerFilter: {},
        })

        setTimeout(() => {
            this.setState({ reset: false })
        }, 500)

        this.fetchData({
            page: this.page,
            pageSize: this.pageSize,
            filtered: [],
            sorted: this.sorted,
        })
    }

    getFilter = (props) => {
        const { reset, data } = this.state
        return (
            <CustomFilter
                {...props}
                data={data}
                reset={reset}
                setStateFilter={this.setStateFilter}
                submitFilter={this.submitFilter}
                resetFilter={this.resetFilter}
            />
        )
    }

    handleFilterReset() {
        this.page = 0

        const { filterList } = this.state
        const filtered = []
        const headerFilter = []
        const filterListArr = Object.keys(filterList)

        if (filterListArr.length > 0) {
            filterListArr.forEach(key => {
                if (filterList[key].default !== '') {
                    const defaultVal = filterList[key].options.find(opt => opt.value === filterList[key].default)

                    filtered.push({
                        id: key,
                        value: defaultVal.value,
                    })

                    headerFilter.push({ [key]: defaultVal })
                }
            })
        }

        const setHeaderFilter = Object.assign({}, ...headerFilter)
        this.setState({
            rangeOpen: false,
            lastRounded: false,
            filtered,
            headerFilter: setHeaderFilter,
        })

        this.filteredTemp = filtered
        this.fetchData({
            page: this.page,
            pageSize: this.pageSize,
            filtered,
            sorted: this.sorted,
        })
    }

    handleFilter(action, value, type) {
        const { filtered, filterList, headerFilter } = this.state
        const filterListArr = Object.keys(filterList)

        let setFiltered = filtered
        let rangeOpen = false
        let lastRounded = false
        let initRange = {}
        this.page = 0

        if (type === 'date') {
            const getExist = filtered.find(value => value.id === action)
            
            if (value !== null) {
                if (getExist) {
                    setFiltered = setFiltered.filter(value => value.id !== action)
                }

                setFiltered.push({
                    id: action,
                    value: dateFns.format(value, 'YYYY-MM-DD'),
                })
            } else {
                setFiltered = setFiltered.filter(value => value.id !== action)
            }
            this.filteredTemp = setFiltered
            this.setState({
                rangeOpen,
                filtered: setFiltered,
                headerFilter: {
                    ...headerFilter,
                    [action]: { value },
                }
            })
            this.fetchData({
                page: this.page,
                pageSize: this.pageSize,
                filtered: setFiltered,
                sorted: this.sorted,
            })
        } else if (action.indexOf('_range') > 0) {
            const actStart = action.replace('_range', '') + '_start_date'
            const actEnd = action.replace('_range', '') + '_end_date'
            const getExistStart = filtered.find(value => value.id === actStart)
            const getExistEnd = filtered.find(value => value.id === actEnd)
            if (value !== null) {
                if (getExistStart) {
                    setFiltered = setFiltered.filter(value => value.id !== actStart)
                }

                if (getExistEnd) {
                    setFiltered = setFiltered.filter(value => value.id !== actEnd)
                }

                setFiltered.push({
                    id: actStart,
                    value: dateFns.format(value[0], 'YYYY-MM-DD'),
                })

                setFiltered.push({
                    id: actEnd,
                    value: dateFns.format(value[1], 'YYYY-MM-DD'),
                })
            } else {
                rangeOpen = true
                setFiltered = setFiltered.filter(value => value.id.indexOf('_date') < 0)
            }

            this.filteredTemp = setFiltered
            this.setState({
                rangeOpen,
                filtered: setFiltered,
                headerFilter: {
                    ...headerFilter,
                    [action]: value,
                }
            })

            if (value !== null) {
                this.fetchData({
                    page: this.page,
                    pageSize: this.pageSize,
                    filtered: setFiltered,
                    sorted: this.sorted,
                })
            }
        } else if (action === 'order_date'){
            setFiltered.push({
                id:action,
                value: {
                    start: dateFns.format(value[0], 'YYYY-MM-DD'),
                    end: dateFns.format(value[1], 'YYYY-MM-DD'), 
                }
            })

            this.filteredTemp = setFiltered
            this.setState({
                rangeOpen,
                filtered: setFiltered,
                headerFilter: {
                    ...headerFilter,
                    [action]: value,
                }
            })
            if (value !== null) {
                this.fetchData({
                    page: this.page,
                    pageSize: this.pageSize,
                    filtered: setFiltered,
                    sorted: this.sorted,
                })
            }
        } else if (action === 'book_date'){
            setFiltered.push({
                id:action,
                value: {
                    start: dateFns.format(value[0], 'YYYY-MM-DD'),
                    end: dateFns.format(value[1], 'YYYY-MM-DD'), 
                }
            })

            this.filteredTemp = setFiltered
            this.setState({
                rangeOpen,
                filtered: setFiltered,
                headerFilter: {
                    ...headerFilter,
                    [action]: value,
                }
            })
            if (value !== null) {
                this.fetchData({
                    page: this.page,
                    pageSize: this.pageSize,
                    filtered: setFiltered,
                    sorted: this.sorted,
                })
            }
        } else {
            const getExist = filtered.find(value => value.id === action)

            if (getExist) {
                setFiltered = setFiltered.filter(value => value.id !== action)
            }
            
            // remove filter by custom (date or range) ---- // disable to fixed issue (15/08/23)
            // setFiltered = setFiltered.filter(value => value.id.indexOf('_date') < 0)
            // delete headerFilter[`${action}_date`]
            // delete headerFilter[`${action}_range`]

            setFiltered.push({
                id: action,
                value: value.value,
            })

            if (value.value === '') {
                setFiltered = setFiltered.filter(value => value.id !== action)
            } else if (value.value === 'DATE_RANGE') {
                rangeOpen = true
            }

            const getDateORRange = setFiltered.find(f => this.customFilter.includes(f.value))

            if (getDateORRange) {
                const indexDateORRange = filterListArr.indexOf(getDateORRange.id) + 1

                if (filterListArr.length === indexDateORRange) {
                    lastRounded = true
                }
            }

            this.filteredTemp = setFiltered
            this.setState({
                rangeOpen,
                lastRounded,
                filtered: setFiltered,
                headerFilter: {
                    ...headerFilter,
                    ...initRange,
                    [action]: value,
                }
            })

            if (!this.customFilter.includes(value.value)) {
                this.fetchData({
                    page: this.page,
                    pageSize: this.pageSize,
                    filtered: setFiltered,
                    sorted: this.sorted,
                })
            }
        }
    }

    render() {
        const { toggleSelection, toggleAll, isSelected } = this;
        const { data, columns, selectAll, pages, loading, rows, disableHighlight } = this.state

        const checkboxProps = this.checkboxProps(
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            this.gridType,
            this.keyTable,
            this.isDashboard,
            this.selectedMax,
            this.selected,
        );
        const newExtraParams = {
            ...this.extraProperties,
            onPageChange: this.gridOnPageChange,
            getTdProps: (state, rowInfo, column, instance) => {
                return {
                    onClick: () => {
                        if (this.clickable) {
                            this.handleClickRow(rowInfo.original)
                        }
                    },
                    className: this.clickable ? 'clickable' : null
                }
            }
        }

        if (this.extraProperties.hasOwnProperty('onFetchData')) {
            newExtraParams.pages = pages
            newExtraParams.rows = rows
            delete newExtraParams["onPageChange"]
        }

        const TopBottomGrid = (props) => {
            return <CustomPagination
                resetFilterHeader={this.resetFilterHeader}
                costumFilterHeader={this.costumFilterHeader}
                leftAction={this.leftAction}
                {...props}
            />
        }

        if (typeof disableHighlight !== "undefined") {
            newExtraParams.disableHighlight = disableHighlight
        }

        return (
            <div className="card-body">
                <this.gridMapping
                    ref={r => (this.checkboxTable = r)}
                    data={data}
                    columns={columns}
                    className="-striped -highlight"
                    {...checkboxProps}
                    {...newExtraParams}
                    loading={loading}
                    page={this.page}
                    defaultPageSize={this.pageSize}
                    multiSort={false}
                    defaultSorted={this.defaultSorted}
                    rows={rows}
                    minRows={1}
                    showPagination={this.hidePagination ? false : true}
                    showPaginationTop={this.hidePaginationTop ? false : true}
                    showPaginationBottom={this.hidePaginationBottom ? false : true}
                    manual={!!this.extraProperties.hasOwnProperty('onFetchData')}
                    filterable={this.filterable}
                    resizable={false}
                    filtered={(this.state.filtered) ? this.state.filtered : []}
                    onFilteredChange={filtered => { this.setState({ filtered }) }}
                    PaginationComponent={TopBottomGrid}
                    FilterComponent={this.getFilter}
                />
            </div>
        )
    }
}