import * as firebase from 'firebase/app';
import 'firebase/messaging';

import firebaseConfig from './core/const/FirebaseConfig'

let messaging = null

if (firebase.messaging.isSupported()) {
    const initializedFirebaseApp = firebase.initializeApp(firebaseConfig);

    messaging = initializedFirebaseApp.messaging();
    messaging.usePublicVapidKey(process.env.REACT_APP_FCM_USE_PUBLIC_VAPID_KEY);
}

export { messaging };
