import React from 'react'
import _ from 'lodash'
import dateFns from 'date-fns'
import qs from 'query-string'
import ReactTable from 'react-table'
import { Button, Col, Row } from 'reactstrap';
import { FieldGroup, FormBuilder, FieldControl, Validators } from 'react-reactive-form'
import { ContextMenuProvider} from 'react-contexify'

import MasterGrid from '../../../components/grid/MasterGrid'
import GridHandler from '../handler/GridHandler'
import MessageBox from '../../../core/utils/UtilsStrapModal'
import { AclHandler } from '../../../core/const/AclConst'
import RoleHelper from '../../../core/helper/RoleHelper'
import InputTextFilter from '../../../components/grid/filter/InputTextFilter'
import SliderPanel from '../../../components/wizard/SliderPanel'
import Config from '../config/Config'
import MasterModal from '../../../core/utils/MasterModal'
import AppointmentSlide from './AppointmentSlide'
import Select from 'react-select'
import ReactTooltip from 'react-tooltip'
import { handleToastServiceError } from '../../../core/utils/UtilsToast'
class Grid extends MasterGrid {
    constructor(props) {
        super(props)

        Object.assign(this, MessageBox)
        Object.assign(this, GridHandler)
        Object.assign(this, AclHandler)
        Object.assign(this, InputTextFilter)

        this.gridType = 'Appointment'
        this.aclModule = this.gridType
        const initFilter = [
            {
                id: 'range',
                value: 'today',
            }
        ]

        this.createUrl = Config.AddUrl
        this.isSuperadmin = RoleHelper.isSuperadmin(props.dataLogin)
        this.customFilter = ['DATE', 'DATE_RANGE']

        this.keyTable = '_id'
        this.ignoreColumn = [
            '_id'
        ]
        this.selectedTableStatus = this.isSuperadmin
        this.gridColumn = this.gridColumn.bind(this)
        this.dataFromRedux = false
        this.pageSize = 20
        this.page = 0
        this.sorted = []
        this.filterData = []
        this.gridMapping = ReactTable
        this.filterable = false;
        this.filteredTemp = initFilter
        /** own property */
        this.fetchData = this.fetchData.bind(this)
        this.refreshData = this.refreshData.bind(this)
        this.InputTextFilter = this.InputTextFilter.bind(this)
        this.extraProperties = {
            onFetchData: this.fetchData,
            filterAble: true
        }
        this.can = this.can.bind(this)

        const loading = this.extraProperties.hasOwnProperty('onFetchData') ? true : false
        const data = this.dataFromRedux ? props.data : []
        const columns = this.getColumns(data)
        const finalColumn = this.gridColumn(ContextMenuProvider, columns)
        const pages = loading ? null : 1
        this.handleOpenSlide = this.handleOpenSlide.bind(this)
        this.onPanelClose = this.onPanelClose.bind(this)
        this.modalHandleConfirm = this.modalHandleConfirm.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.modalHandleSubmit = this.modalHandleSubmit.bind(this)
        this.sendEmail = this.sendEmail.bind(this)
        this.parseModal = this.parseModal.bind(this)
        this.getNotifHandle = this.getNotifHandle.bind(this)
        this.ModalSearchBankAccount = this.ModalSearchBankAccount.bind(this)

        this.state = {
            data,
            columns: finalColumn,
            selection: [],
            deleted: [],
            selectAll: false,
            loading: loading,
            loadingE: false,
            pages: pages,
            filtered: [],
            onUpdate: false,
            modalShow: false,
            dataProvider: null,
            editTrigger: false,
            filterTrigger: false,
            disableHighlight: true,
            isModalOpen: false,
            ModalData: null,
            isPaneOpen: false,
            dataRefund:[],
            selectedBank:"",
            no_rekening:"",
            nama_rekening:"",
            keterangan_refund:"",
            nama_bankAccount: "",
            panelData: {
                headTitle: 'Data Appointment'
            },
            headerFilter: {},
            openedAppointment: null,
            Form: FormBuilder.group({
                reason: ["", Validators.required],
                others: "",
                keterangan_refund: "",
                payment_method: "",
                nama_rekening: "",
                no_rekening: "",
                dana: ""
            }),
            rangeOpen: false,
        }
        this.customHandleFilter = this.customHandleFilter.bind(this)
        this.customHandleFilterReset = this.customHandleFilterReset.bind(this)
        this.currentHospital = this.props.currentHospital
        this.showNotif = false
    }

    componentDidMount() {
        const { location } = this.props
        const params = qs.parse(location.search)

        if (params.id && !this.showNotif) {
            this.showNotif = true
            setTimeout(() => {
                this.handleOpenSlide({ id: params.id, id_hospital: this.currentHospital })
            }, 100);
        }

        if (!this.isSuperadmin) {
            this.getNotifHandle()
        }
    }

    componentWillReceiveProps(nextProps) {
        const { currentHospital } = this.props

        if (currentHospital !== nextProps.currentHospital) {
            this.currentHospital = nextProps.currentHospital
            this.customHandleFilterReset(nextProps)
        }
    }

    onPanelClose() {
        this.setState({
            isPaneOpen: false,
            viewPatient: []
        })
    }

    

    customHandleFilterReset(nextProps) {
        this.page = 0

        const filterList = nextProps.filterList || this.props.filterList
        const filtered = []
        const headerFilter = []
        const filterListArr = Object.keys(filterList)

        if (filterListArr.length > 0) {
            filterListArr.forEach(key => {
                if (filterList[key].default !== '') {
                    const defaultVal = filterList[key].options.find(opt => opt.value === filterList[key].default)

                    filtered.push({
                        id: key,
                        value: defaultVal.value,
                    })

                    headerFilter.push({ [key]: defaultVal })
                }
            })
        }

        const setHeaderFilter = Object.assign({}, ...headerFilter)
        this.setState({
            filtered,
            headerFilter: setHeaderFilter,
        })

        this.filteredTemp = filtered
        this.fetchData({
            page: this.page,
            pageSize: this.pageSize,
            filtered,
            sorted: this.sorted,
        })
    }

    customHandleFilter(action, value, type) {
        const { filtered, headerFilter } = this.state
        let setFiltered = filtered
        let rangeOpen = false
        let initRange = {}
        this.page = 0

        if (type === 'date') {
            const getExist = filtered.find(value => value.id === action)

            if (value !== null) {
                if (getExist) {
                    setFiltered = setFiltered.filter(value => value.id !== action)
                }

                setFiltered.push({
                    id: action,
                    value: dateFns.format(value, 'YYYY-MM-DD'),
                })
            } else {
                setFiltered = setFiltered.filter(value => value.id !== action)
            }

            this.filteredTemp = setFiltered
            this.setState({
                rangeOpen,
                filtered: setFiltered,
                headerFilter: {
                    ...headerFilter,
                    [action]: { value },
                }
            })

            this.fetchData({
                page: this.page,
                pageSize: this.pageSize,
                filtered: setFiltered,
                sorted: this.sorted,
            })
        } else if (action.indexOf('_range') > 0) {
            const actStart = action.replace('_range', '') + '_start_date'
            const actEnd = action.replace('_range', '') + '_end_date'
            const getExistStart = filtered.find(value => value.id === actStart)
            const getExistEnd = filtered.find(value => value.id === actEnd)

            if (value !== null) {
                if (getExistStart) {
                    setFiltered = setFiltered.filter(value => value.id !== actStart)
                }

                if (getExistEnd) {
                    setFiltered = setFiltered.filter(value => value.id !== actEnd)
                }

                setFiltered.push({
                    id: actStart,
                    value: dateFns.format(value[0], 'YYYY-MM-DD'),
                })

                setFiltered.push({
                    id: actEnd,
                    value: dateFns.format(value[1], 'YYYY-MM-DD'),
                })
            } else {
                rangeOpen = true
                setFiltered = setFiltered.filter(value => value.id.indexOf('_date') < 0)
            }

            this.filteredTemp = setFiltered
            this.setState({
                rangeOpen,
                filtered: setFiltered,
                headerFilter: {
                    ...headerFilter,
                    [action]: value,
                }
            })

            if (value !== null) {
                this.fetchData({
                    page: this.page,
                    pageSize: this.pageSize,
                    filtered: setFiltered,
                    sorted: this.sorted,
                })
            }
        } else if (action === 'order_date'){
            setFiltered.push({
                id:'order_date',
                value: {
                    start: dateFns.format(value[0], 'YYYY-MM-DD'),
                    end: dateFns.format(value[1], 'YYYY-MM-DD'), 
                }
            })

            this.filteredTemp = setFiltered
            this.setState({
                rangeOpen,
                filtered: setFiltered,
                headerFilter: {
                    ...headerFilter,
                    [action]: value,
                }
            })
            if (value !== null) {
                this.fetchData({
                    page: this.page,
                    pageSize: this.pageSize,
                    filtered: setFiltered,
                    sorted: this.sorted,
                })
            }
        } else if (action === 'book_date'){
            setFiltered.push({
                id:'book_date',
                value: {
                    start: dateFns.format(value[0], 'YYYY-MM-DD'),
                    end: dateFns.format(value[1], 'YYYY-MM-DD'), 
                }
            })

            this.filteredTemp = setFiltered
            this.setState({
                rangeOpen,
                filtered: setFiltered,
                headerFilter: {
                    ...headerFilter,
                    [action]: value,
                }
            })
            if (value !== null) {
                this.fetchData({
                    page: this.page,
                    pageSize: this.pageSize,
                    filtered: setFiltered,
                    sorted: this.sorted,
                })
            }
        } else {
            const getExist = filtered.find(value => value.id === action)

            if (getExist) {
                setFiltered = setFiltered.filter(value => value.id !== action)
            }

            // remove filter by custom (date range)
            const getDateRange = setFiltered.filter(d => d.value === 'DATE_RANGE')
            if (getDateRange.length === 0) {
                setFiltered = setFiltered.filter(value => value.id.indexOf('_date') < 0)
                delete headerFilter[`${action}_date`]
                delete headerFilter[`${action}_range`]
            }

            setFiltered.push({
                id: action,
                value: value.value,
            })

            if (value.value === '') {
                setFiltered = setFiltered.filter(value => value.id !== action)
            } else if (value.value === 'DATE_RANGE') {
                rangeOpen = true
            }

            this.filteredTemp = setFiltered
            this.setState({
                rangeOpen,
                filtered: setFiltered,
                headerFilter: {
                    ...headerFilter,
                    ...initRange,
                    [action]: value,
                }
            })

            if (!this.customFilter.includes(value.value)) {
                this.fetchData({
                    page: this.page,
                    pageSize: this.pageSize,
                    filtered: setFiltered,
                    sorted: this.sorted,
                })
            }
        }
    }

    RadioInput = ({ handler, touched, hasError, meta }) => {
        return (
            <div className={touched && hasError(meta.validator) ? "form-line has-danger" : "form-line"}>
                <div className="wrap-options">
                    <div className="wrap-radio wrap-radio-reason">
                        {
                            meta.options.map((option, index) => {
                                return (
                                    <label key={index} className="container-radio">
                                        <span className="reason-opsi">{option.text}</span>
                                        <input
                                            id={option.id}
                                            {...handler("radio", option.text)}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
    

    TextareaInput = ({ handler, touched, hasError, meta }) => {
        return (
            <div className={touched && hasError(meta.validator) ? "form-line has-danger" : "form-line"}>
                <textarea
                    className="form-control textarea-reason"
                    id={`${meta.label}`}
                    placeholder={meta.placeholder}
                    rows="5"
                    {...handler()} />
            </div>
        )
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {
            reason,
            others,
        } = this.state.Form.value
        const data = {
            cancel_reason: !_.isEmpty(others) ? others : reason
        }

        this.modalHandleSubmit(data)
        setTimeout(() => {
            this.state.Form.reset();
        }, 1500);
    }

    SearchBankAccount = () => {
        let banks = this.state.selectedBank
        const dataSearchBankAccount = {
            bank: banks.label,
            bank_account: `${this.state.no_rekening}`,
            amount: "10000"
        }
        this.ModalSearchBankAccount(dataSearchBankAccount)
    }

    handleSubmitRefund = (e) => {
        e.preventDefault();
        const dataRefund = {
            ...this.state.ModalData.content,
            selectedBank: this.state.selectedBank,
            no_rekening: this.state.no_rekening,
            nama_rekening: this.state.nama_rekening,
            keterangan_refund: this.state.keterangan_refund,

        };
        if(!dataRefund.keterangan_refund){
            handleToastServiceError({
                message: 'Keterangan Refund Belum Diisi',
            })
        }else{
            this.ModalHandleRefund(dataRefund)
        }
        setTimeout(() => {
            this.state.Form.reset();
            this.setState({
                nama_bankAccount: '',
                no_rekening: '',
                keterangan_refund: '',
            })
        }, 1500);
    }

    handleChangeData(field, value) {
        this.setState({[field]: value});
    }
    
    toRp(number) {
        if (number) {
            return `Rp ${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
        }

        return 'Rp 0 (Gratis)'
    }

    parseModal(data) {
        const listBank = data.content.payment_methods && data.content.payment_methods.map(Item => {
                return {
                    label: Item.label,
                    value: Item.value,
                }
            })
        
        switch (data.action) {
            case 'cancel_booking':
                return (
                    <div className="master-modal-content">
                        <div className="master-modal-head">
                            <span className="icon-exclamation">!</span>
                        </div>
                        <div className="master-modal-body" >
                            <div className="content" dangerouslySetInnerHTML={{ __html: data.content }} />
                                <FieldGroup
                                    control={this.state.Form}
                                    render={({ get, invalid }) => {
                                        const setInvalid = this.state.Form.value.reason === 'Lainnya' && _.isEmpty(this.state.Form.value.others) ? true : invalid;
                                        // const dataActions = data.dataAction.label === 'Selesai' ? true : false
                                        return (
                                            <form onSubmit={this.handleSubmit} className="form-hospital">
                                                <FieldControl
                                                    name="reason"
                                                    render={this.RadioInput}
                                                    meta={{ options: this.props.reasonList }}
                                                />
                                                {this.state.Form.value.reason === 'Lainnya' ?
                                                    <FieldControl
                                                        name="others"
                                                        render={this.TextareaInput}
                                                        meta={{ label: "Others" }}
                                                    />
                                                    : null}

                                                <div className="master-modal-foot">
                                                    <button className={`master-btn confirm ${setInvalid ? 'disabled' : ''}`} disabled={setInvalid}>{data.buttonConfirmText}</button>
                                                    {/* <button className={`master-btn confirm ${setInvalid ? 'disabled' : ''}`} disabled={setInvalid}>{data.buttonConfirmText}</button> */}
                                                    {/* <button className={`master-btn confirm ${dataActions ? 'disabled' : ''}`} disabled={dataActions} >{data.buttonConfirmText}</button> */}
                                                    <button className="master-btn cancel" onClick={this.handleCloseModal}>{data.buttonCancelText}</button>
                                                </div>
                                            </form>
                                        )
                                    }}
                                />
                        </div>
                    </div>
                );
            case 'refund':
                return (
                    <div className="master-modal-content">
                         <div className="slide-appointment">
                            <div className="row-content">
                                <h3>Pengembalian Dana</h3>
                                <div className="row-info">
                                    <div className="col-info-full">
                                        <span className="title">Alasan Pengembalian Dana</span>
                                        <span className="contents">{data.content.cancel_reason}</span>
                                    </div>
                                </div>
                            </div>
                            {
                                data.content.payment_methods.length > 0 ?
                                <div className="row-content">
                                <h3>Form Pengembalian Dana</h3>
                                <div className="row-info">
                                <form onSubmit={this.handleSubmitRefund} className="form-hospital">
                                    <div className="col-info-full">
                                        <span className="title">Pilih Bank</span>
                                        <Select
                                                className="dropdown-filter select-sch"
                                                classNamePrefix="react-select"
                                                id="payment_methods"
                                                options={listBank}
                                                onChange={(e) => this.handleChangeData('selectedBank', e)}
                                            />
                                    </div><br/>
                                    <div className="col-info-full">
                                        <span className="title">No. Rekening</span>
                                        <Row>
                                            <Col md={10}>
                                            <input 
                                                type='number'
                                                className="form-control form-control-success" 
                                                id='no_rekening'
                                                defaultValue={this.state.no_rekening}
                                                onChange={(e) => this.handleChangeData('no_rekening', e.target.value)}
                                                placeholder='No. Rekening'></input>
                                            </Col>
                                            <Col md={2}>
                                            <Button id="btn-loop" data-tip={'Klik untuk mencari nama pemilik rekening'} style={{backgroundColor: '#5983f0', width:'50px', height: '39px', borderRadius: '20px'}} onClick={this.SearchBankAccount}><i className="fas fa-search"/></Button>
                                            <ReactTooltip place="top" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="col-info-full">
                                        <span className="title">Nama Rekening</span>
                                         <input 
                                            type='text'
                                            className="form-control form-control-success" 
                                            id='nama_rekening'
                                            defaultValue={this.state.nama_bankAccount}
                                            value={this.state.nama_bankAccount}
                                            onChange={(e) => this.handleChangeData('nama_rekening', e.target.value)}
                                            placeholder='Nama Rekening'></input>
                                    </div>
                                    <div className="col-info-full">
                                        <span className="title">Keterangan</span><span style={{color:'red'}}>(*)</span>
                                        <textarea 
                                            className="form-control textarea-reason" 
                                            id='keterangan_refund'
                                            defaultValue={this.state.keterangan_refund}
                                            onChange={(e) => this.handleChangeData('keterangan_refund', e.target.value)}
                                            placeholder='Keterangan'></textarea>
                                    </div>
                                        {this.state.keterangan_refund.length < 1 ? <span style={{color:'red', fontSize: '10px', marginTop: '-10px'}}>keterangan refund harus diisi</span>: ''}
                                    <div className="col-info-full">
                                        <span className="title">Nominal Pengembalian Dana</span>
                                        <input 
                                            type='text'
                                            className="form-control form-control-success" 
                                            id='dana'
                                            value={this.toRp(data.content.price)}
                                            placeholder='Dana' readOnly></input>
                                    </div>
                                    <div className="col-info-full">
                                        <span className="title">Kode Voucher yang digunakan</span>
                                        <input 
                                            type='text'
                                            className="form-control form-control-success" 
                                            id='voucher'
                                            value={data.content.voucher_code}
                                            placeholder='Kode Voucher' readOnly></input>
                                    </div>
                                <div className="master-modal-foot">
                                    <button className="master-btn confirm" disabled={this.state.keterangan_refund.length < 1 ? true: false}>{data.buttonConfirmText}</button>
                                    <button className="master-btn cancelRefund" onClick={this.handleCloseModal}>{data.buttonCancelText}</button>
                                </div>
                                </form>
                                </div>
                                </div>
                                :
                                <form onSubmit={this.handleSubmitRefund} className="form-hospital">
                                    <div className="row-content">
                                        <h3>Form Pengembalian Dana</h3>
                                        <div className="row-info">
                                            <div className="col-info-full">
                                                <span className="title">Pengembalian Dana Ke</span><br/>
                                                <span className="contents" id="payment_methods" >{data.content.payment_method}</span>
                                            </div><br/>
                                            <div className="col-info-full">
                                                <span className="title">No Telepon</span><br/>
                                                <span className="contents" id="no_telepone">{data.content.telephone}</span>
                                            </div><br/>
                                            <div className="col-info-full">
                                                <span className="title">Nominal Pengembalian Dana</span><br/>
                                                <span className="contents" id="dana">{this.toRp(data.content.price)}</span>
                                            </div><br/>
                                            <div className="col-info-full">
                                                <span className="title">Kode Voucher yang digunakan</span><br/>
                                                <span className="contents" id="voucher">{data.content.voucher_code}</span>
                                            </div>
                                        </div>
                                        <div className="col-info-full">
                                            <span className="title">Keterangan</span><span style={{color:'red'}}>(*)</span>
                                            <textarea 
                                                className="form-control textarea-reason" 
                                                id='keterangan_refund'
                                                defaultValue={this.state.keterangan_refund}
                                                onChange={(e) => this.handleChangeData('keterangan_refund', e.target.value)}
                                                placeholder='Keterangan'></textarea>
                                        </div>
                                                {this.state.keterangan_refund.length < 1 ? <span style={{color:'red', fontSize: '10px', marginTop: '-10px'}}>keterangan refund harus diisi</span>: ''}
                                        <div className="master-modal-foot">
                                            <button className="master-btn confirm" disabled={this.state.keterangan_refund.length < 1 ? true: false}>{data.buttonConfirmText}</button>
                                            <button className="master-btn cancelRefund" onClick={this.handleCloseModal}>{data.buttonCancelText}</button>
                                        </div>
                                    </div>
                                </form>
                            }
                                
                        </div>
                    </div>
                );
            default:
                return (
                    <h1>Default modal type</h1>
                )
        }
    }

    render() {
        const {
            selectAll,
            selection,
            loading,
            loadingE,
            filtered,
            headerFilter,
            rangeOpen,
        } = this.state
        const { filterList } = this.props
        const filterListArr = Object.keys(filterList)
        this.selectedOrder = headerFilter.order_date
        this.selectedBook = headerFilter.book_date
        let isMenuCollaped = true

        try {
            isMenuCollaped = localStorage.getItem('isToggleButton')
        } catch (e) {
            console.log('Local Storage is disabled');
        }

        this.selected = selection
        this.MessageBox = this.MessageBoxShowHide({
            title: "Delete Confirmation",
            body: "Are you sure want to delete this item ?",
        })

        this.leftAction = []
        if (filterListArr.length > 0) {
            filterListArr.forEach((key, index) => {
                let roundedPos = 'middle'
                const selectDefault = filterList[key].options.filter(s => {
                    return (s.value !== '') && (s.value === filterList[key].default)
                })

                if (index === 0) {
                    roundedPos = 'left'
                } else if ((filterListArr.length - 1) === index) {
                    roundedPos = 'right'
                }

                this.leftAction.push({
                    actionType: key,
                    type: filterList[key].type,
                    title: filterList[key].placeholder,
                    tooltip: filterList[key].tooltip,
                    filterKeyword: headerFilter[key] ? headerFilter[key].value : filterList[key].default,
                    handlerSubmit: this.customHandleFilter,
                    selectedOpt: headerFilter[key] || selectDefault,
                    filterOpt: filterList[key].options,
                    path: filterList[key].path,
                    handlerChangeOpt: this.customHandleFilter,
                    handlerChange: this.customHandleFilter,
                    className: `rounded ${roundedPos}`,
                    dataSelectedOrder: this.selectedOrder,
                    dataSelectedBook: this.selectedBook
                })
            })
        }

        const getDateORRange = filtered.find(f => this.customFilter.includes(f.value))
        if (getDateORRange) {
            const indexDateORRange = filterListArr.indexOf(getDateORRange.id) + 1

            if (getDateORRange.value === 'DATE_RANGE') {
                this.leftAction.splice(indexDateORRange, 0, {
                    type: 'range',
                    title: 'Date',
                    isOpen: rangeOpen,
                    actionType: `${getDateORRange.id}_range`,
                    filterKeyword: headerFilter[`${getDateORRange.id}_range`],
                    handlerChange: this.customHandleFilter,
                    className: 'rounded middle',
                })
            } else {
                this.leftAction.splice(indexDateORRange, 0, {
                    type: 'date',
                    title: 'Date',
                    actionType: `${getDateORRange.id}_date`,
                    filterKeyword: headerFilter[`${getDateORRange.id}_date`],
                    handlerChange: this.customHandleFilter,
                    className: 'rounded middle',
                })
            }
        }


        this.leftAction.push({
            type: 'reset',
            loading: loading,
            tooltip: 'Reset Filter',
            handlerReset: this.customHandleFilterReset,
        })

        return (
            <section className="tables with-top-filter no-paging-top">
                <div className="container-fluid container-appointment">
                    <div className="row">
                        {this.MessageBox}
                        <div className="col-lg-12 cn-table-container">
                            <div className="card">
                                {super.render()}
                            </div>
                        </div>
                    </div>
                </div>
                {selection.length > 0
                    ? (
                        <div
                            className={`action-bar-bottom faskes${isMenuCollaped === 'true'
                                ? ''
                                : ' menu-expanded'}`
                            }
                        >
                            <div className={`text-wrapper${selectAll ? ' selected-all' : ''}`}>
                                <span onClick={() => this.toggleAll('deselect-all')} />
                                {`${selection.length} Pasien dipilih`}
                            </div>
                            <div className="btn-wrapper">
                                <button disabled={loadingE} onClick={() => this.sendEmail()}>
                                    {`Kirim Hasil${loadingE ? '...' : ''}`}
                                </button>
                                <button onClick={() => this.toggleAll('deselect-all')}>
                                    Batalkan
                                </button>
                            </div>
                        </div>
                    )
                    : null}
                <SliderPanel
                    {...this.props}
                    open={this.state.isPaneOpen}
                    onPanelClose={this.onPanelClose}
                    onHandleBack={null}
                    panelData={this.state.panelData}
                >
                    <AppointmentSlide
                        {...this.props}
                        openedAppointment={this.state.openedAppointment}
                        parseDate={this.parseDate}
                        modalHandleConfirm={this.modalHandleConfirm}
                        handleOpenModal={this.handleOpenModal}
                    />
                </SliderPanel>
                <MasterModal
                    className="master-modal"
                    isOpen={this.state.isModalOpen}
                    handleClose={this.handleCloseModal}
                    handleSubmit={this.modalHandleSubmit}
                    ModalData={this.state.ModalData}>
                    {this.state.ModalData && ['cancel_booking', 'refund'].includes(this.state.ModalData.action) ? this.parseModal(this.state.ModalData) : null}
                </MasterModal>
            </section>
        )
    }
}

export default Grid
