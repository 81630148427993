import React from 'react'
import { NavLink } from 'react-router-dom'
import Search from './actions/Search'
import SearchText from './actions/SearchText'
import SearchDate from './actions/SearchDate'
import SearchRange from './actions/SearchRange'
import SearchRangeBook from './actions/SearchRangeBook'
import SearchRangeOrder from './actions/SearchRangeOrder'
import SearchSelect from './actions/SearchSelect'
import SearchSelectFilter from './actions/SearchSelectFilter'
import SearchFilter from './actions/SearchFilter'
import SearchReset from './actions/SearchReset'
import SearchTreeSelect from './actions/SearchTreeSelect'

/** ----------------- Start --------------------- **/
export default class CustomAction extends React.Component {
    render() {
        const leftAction = this.props.leftAction
        let Actions = []

        if (leftAction !== undefined && leftAction.length > 0) {
            leftAction.map(function (element) {
                if (element.type === 'buttonLink') {
                    Actions.push(
                        <div>
                            <NavLink to={element.link} className="button-link">
                                {element.title}
                            </NavLink>
                        </div>
                    )
                } else if (element.type === 'searchByFilter') {
                    Actions.push(
                        <SearchFilter element={element} hideField={element.hideField} />
                    )
                } else if (element.type === 'search') {
                    Actions.push(
                        <Search element={element} hideField={element.hideField} />
                    )
                } else if (element.type === 'text') {
                    Actions.push(
                        <SearchText {...element} />
                    )
                } else if (element.type === 'date') {
                    Actions.push(
                        <SearchDate element={element} hideField={element.hideField} />
                    )
                } else if (element.type === 'range') {
                    Actions.push(
                        <SearchRange {...element} />
                    )
                } else if (element.actionType === 'book_date') {
                    Actions.push(
                        <SearchRangeBook {...element}/>
                    )
                } else if (element.actionType === 'order_date') {
                    Actions.push(
                        <SearchRangeOrder {...element}/>
                    )
                }
                 else if (element.type === 'select') {
                    Actions.push(
                        <SearchSelect element={element} hideField={element.hideField} />
                    )
                } else if (element.type === 'select_search') {
                    Actions.push(
                        <SearchSelectFilter {...element} />
                    )
                } else if (element.type === 'select_child') {
                    Actions.push(
                        <SearchTreeSelect element={element} />
                    )
                } else if (element.type === 'reset') {
                    Actions.push(<SearchReset {...element} />)
                }

                return true
            })

        }

        return (
            <div className="row">
                {Actions.map((item, key) => (
                    <div key={key}>{item}</div>
                ))}
            </div>
        )
    }
}