import React, { Component } from 'react'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from "react-reactive-form"
import SubmitButtonForm from '../../../core/utils/SubmitButtonForm'
import TextInputForm from '../../../core/utils/TextInputForm'
import TextAreaForm from '../../../core/utils/TextAreaForm'
import FormHandler from '../handler/FormHandler'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../../core/const/LoginConst'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Config from '../config/UrlConfig'

class Form extends Component
{
    constructor(props) {
        super(props)

        Object.assign(this, TextInputForm)
        Object.assign(this, TextAreaForm)

        this.state = {
            name: "",
            description: "",
            isEdit: false,
            postStatus: false,
            filledForm: [],
            Form: FormBuilder.group({
                id: [""],
                name: ["", Validators.required],
                description: ["", null]
            })
        }

        this.TextInputFormView = this.TextInputFormView.bind(this)
        this.TextAreaFormView = this.TextAreaFormView.bind(this)
        Object.assign(this, FormHandler)

        this.handleSubmit = this.handleSubmit.bind(this)
        this.valueBuilder = this.valueBuilder.bind(this)
        this.fillForm = this.fillForm.bind(this)

        this.buildForm()
    }

    componentDidMount() {
        this.valueBuilder()
    }

    render(){
        if (this.state.postStatus) {
            return <Redirect to={ Config.GridUrl } />
        }
        return(
            <div className="wizard-form">
                <h3>
                    {this.props.subtitle}
                </h3>

                {/** start form */}
                <FieldGroup
                control={this.state.Form}
                strict={false}
                render={({ get, invalid }) => {
                return(
                    <form onSubmit={() => this.handleSubmit} className="form-horizontal">
                        {this.TextName}
                        {this.TextDescription}
                        <SubmitButtonForm 
                            submitText={'Simpan Data'} 
                            invalid={invalid} 
                            onSubmit={(e) => this.handleSubmit(e)} 
                            onCancel={(e) => this.props.history.push( Config.GridUrl )}
                        />
                    </form>
                )}}/>
                {/** end of form */}
            </div>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Form)