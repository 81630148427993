import React from 'react'
import ReactTable from 'react-table'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import { ContextMenuProvider, ContextMenu, Item, theme } from 'react-contexify'

import MasterGrid from '../../../components/grid/MasterGrid'
import MasterModal from '../../../core/utils/MasterModal'
import GridHandler from '../handler/GridHandler'

const CheckboxTable = checkboxHOC(ReactTable)

class Grid extends MasterGrid {
    constructor(props) {
        super(props)

        Object.assign(this, GridHandler)

        this.clickable = false
        this.filterable = false
        this.selectedTableStatus = false
        this.gridColumn = this.gridColumn.bind(this)
        this.gridMapping = (this.selectedTableStatus) ? CheckboxTable : ReactTable
        this.hidePagination = false
        this.page = 0
        this.pageSize = 20
        this.selectedMax = 10
        this.selected = []
        this.filtered = []
        this.sorted = []
        this.defaultSorted = []
        this.keyTable = '_id'
        this.ignoreColumn = [
            '_id'
        ]

        /* own property */
        this.fetchData = this.fetchData.bind(this)
        this.extraProperties = {
            onFetchData: this.fetchData,
            filterAble: true
        }

        /* data */
        const loading = this.extraProperties.hasOwnProperty('onFetchData') ? true : false
        const data = this.dataFromRedux ? this.props.data : (loading ? [] : this.getData())
        const columns = this.getColumns(data)
        const finalColumn = this.gridColumn(ContextMenuProvider, columns)
        const pages = loading ? null : 1

        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleMenuClick = this.handleMenuClick.bind(this)
        this.modalHandleSubmit = this.modalHandleSubmit.bind(this)
        this.filterSubmit = this.filterSubmit.bind(this)

        this.state = {
            loading: loading,
            data,
            columns: finalColumn,
            pages: pages,
            rows: 1,
            selection: [],
            deleted: [],
            filtered: [],
            disableHighlight: true,
            selectAll: false,
            headerFilter: {
                name: null,
            },
            isModalOpen: false,
            modalData: {},
        }

        this.currentHospital = this.props.currentHospital
    }

    componentWillReceiveProps(nextProps) {
        const { currentHospital } = this.props

        if (currentHospital !== nextProps.currentHospital) {
            this.currentHospital = nextProps.currentHospital

            this.fetchData({
                page: 0,
                pageSize: this.pageSize,
                filtered: [],
                sorted: []
            })
        }
    }

    filterSubmit(keyword) {
        this.page = 0

        const {
            filtered,
            headerFilter,
        } = this.state
        let setFiltered = filtered
        const getExist = filtered.find(value => value.id === 'name')

        if (getExist) {
            setFiltered = filtered.filter(value => value.id !== 'name')
        }

        setFiltered.push({
            id: 'name',
            value: keyword,
        })

        this.setState({
            filtered: setFiltered,
            headerFilter: {
                ...headerFilter,
                name: keyword,
            }
        })

        this.filteredTemp = setFiltered
        this.fetchData({
            page: this.page,
            pageSize: this.pageSize,
            sorted: this.sorted,
            filtered: setFiltered,
        })
    }

    render() {
        const {
            selection,
            headerFilter,
            isModalOpen,
            modalData,
        } = this.state
        const { history } = this.props
        const menuStyle = { minWidth: '180px' }

        this.selected = selection
        this.leftAction = [
            {
                type: 'search',
                title: 'Nama Layanan',
                actionType: 'name',
                filterKeyword: headerFilter.name,
                handlerSubmit: this.filterSubmit,
                className: 'rounded single'
            },
        ]

        const ActionMenu = () => (
            <ContextMenu
                id="action_menu"
                className="menu-custom custom-snippet-menu left-action-snippet-menu fee"
                theme={theme.light}
                style={menuStyle}
            >
                <Item onClick={data => this.handleMenuClick('edit', data.dataFromProvider)}>
                    Edit
                </Item>
                <Item onClick={data => this.handleMenuClick('delete', data.dataFromProvider)}>
                    Hapus
                </Item>
            </ContextMenu>
        )

        return (
            <div>
                <section className="tables with-top-filter no-paging-top">
                    <button
                        className="add"
                        onClick={() => history.push('/administration/service-fee/create')}
                    >
                        Tambah Layanan
                    </button>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 cn-table-container">
                                <div className="card">
                                    {super.render()}
                                    <ActionMenu />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <MasterModal
                    className="master-modal"
                    isOpen={isModalOpen}
                    handleClose={this.handleCloseModal}
                    handleSubmit={this.modalHandleSubmit}
                    ModalData={modalData}
                />
            </div>

        )
    }
}

export default Grid
