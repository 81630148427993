import React from "react";
import Chance from "chance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import Api from "../../../../../services/apis/ServicesM";
import HospitalApi from "../../../../../services/apis/HospitalApi";
import HospitalListApi from "../../../../../services/apis/Hospital/List";
import DoctorApi from "../../../../../services/apis/doctor/DoctorApi";
import {
  handleToast,
  handleToastServiceError,
} from "../../../../../core/utils/UtilsToast";

const chance = new Chance();

const GridHandler = {
  onPanelClose() {
    this.setState({
      isPaneOpen: false,
      dataEdit: null,
    });
  },
  onPanelOpen(id) {
    if (id) {
      this.getDetail(id);
    } else {
      this.setState({
        isPaneOpen: true,
        dataEdit: null,
        panelData: { headTitle: <div>Tambah Kategori Layanan</div> },
      });
    }
  },
  handleOpenModal(modalData) {
    this.setState({
      isModalOpen: true,
      modalData,
      forceActive: "false",
    });
  },
  handleCloseModal(isSubmit) {
    this.setState({
      isModalOpen: false,
      checkedConfirm: false,
      modalData: {},
      forceActive: isSubmit === true ? "false" : "true",
    });
    this.state.Form.reset();
  },
  modalHandleSubmit: async function () {
    const { modalData } = this.state;

    if (modalData.action === "delete") {
      try {
        const response = await Api.delete(modalData.id);
        handleToast(response.data.message);

        this.loadDataAfterAction();
        this.handleCloseModal();
      } catch (e) {
        handleToastServiceError(e);
        this.handleCloseModal();
      }
    }
  },
  gridColumn(ContextMenuProvider) {
    return [
      {
        className: "grid-photo",
        width: 70,
        Cell: (row) => {
          if (!row.original.photo) {
            return ""
          }
          return (
            <img src={row.original.photo} alt={`service pic`} width={60} />
          )
        },
      },
      {
        className: "grid-cell-flex",
        Header: "Nama",
        accessor: "name",
        sortable: true,
        headerClassName: "-sort",
      },
      {
        className: "grid-cell-flex",
        Header: "Parent Kategori",
        accessor: "parent_name",
        sortable: true,
        headerClassName: "-sort",
      },

      {
        className: "grid-cell-flex",
        Header: "Posisi",
        accessor: "sort_order",
        sortable: true,
        headerClassName: "-sort",
        Cell: (row) => {
            if(!row.original?.sort_order){
                return '-'
            }

            return row.original?.sort_order
        }
      },
      {
        className: "grid-cell-flex",
        Header: "Status",
        accessor: "active",
        width: 100,
        sortable: true,
        Cell: (row) => {
          const label = row.original.is_active ? "Aktif" : "Non Aktif";
          const badgeStyle = row.original.is_active
            ? "badge-success"
            : "badge-danger"

          return (
            <div className="badge-label">
              <span className={`badge badge-pill ${badgeStyle}`}>{label}</span>
            </div>
          )
        },
      },
      {
        Header: "Action",
        width: 60,
        sortable: false,
        filterType: "reset",
        className: "grid-cell-flex grid-action center",
        Cell: (row) => {
          return (
            <ContextMenuProvider
              id={`action_menu_no_resend`}
              event="onClick"
              data={row.original}
            >
              <button style={{ width: 21, marginLeft: "-12px" }}>
                <FontAwesomeIcon icon={faEllipsisV} />
              </button>
            </ContextMenuProvider>
          );
        },
      },
    ];
  },
  fetchData: async function (state) {
    this.setState({ loading: true });
    this.page = state.page;
    this.pageSize = state.pageSize;

    let res = {};
    let fArray = {};
    let sort_property = "";
    let sort_value = "";

    if (state.sorted !== null && state.sorted.length > 0) {
      sort_property = state.sorted[0].id;
      sort_value = state.sorted[0].desc ? "DESC" : "ASC";
      this.sorted = state.sorted;
    }

    if (state.filtered && state.filtered.length > 0) {
      state.filtered.map((val) => {
        if (val.value !== "") {
          fArray[val.id] = val.value;
        }
        return true;
      });
    }

    const filterData = JSON.stringify(fArray);

    try {
      res = await Api.list(
        this.page + 1,
        this.pageSize,
        sort_property,
        sort_value,
        filterData
      );
    } catch (e) {
      this.setState({ loading: false });

      handleToastServiceError(e);
      return;
    }

    const { status, data: resData } = res;

    if (status === 200) {
      const newRows = resData.data.rows.map((item) => {
        const _id = chance.guid();
        return {
          _id,
          ...item,
        };
      });

      this.setState({
        loading: false,
        data: newRows,
        pages: resData.data.total_page,
        rows: resData.data.total_rows,
      });
    } else {
      this.setState({ loading: false });
    }
  },
  getFilter: async function () {
    try {
      const response = await Api.getFilter();
      const { data } = response.data;
      const filterList = data;

      this.setState({ filterList });
    } catch (e) {
      handleToastServiceError(e);
    }
  },
  loadDataAfterAction() {
    const state = {
      pageSize: this.pageSize,
      page: this.page,
      sorted: this.sorted,
      filter: "",
      filtered: this.state.filtered,
    };

    this.fetchData(state);
  },
  getDetail(id) {
    this.setState({ loading: true });
    Api.detail(id)
      .then((res) => {
        const { data } = res.data;
        this.setState({ loading: false });
        if (res.status === 200) {
          this.setState({
            isPaneOpen: true,
            dataEdit: data,
            panelData: {
              headTitle: <div>Edit Kategori Layanan</div>,
            },
          });
        }
      })
      .catch((error) => {
        handleToastServiceError(error);
        this.setState({ loading: false });
      });
  },
  getHospital: async function (keyword = "") {
    const { hospitalList } = this.state;

    if (hospitalList.length > 0 && keyword === "") {
      return hospitalList;
    }

    await HospitalApi.getHospital(keyword).then((res) => {
      const { data } = res.data;
      if (res.status === 200) {
        this.setState({ hospitalList: data });
      }
    });
  },
  handleDelete(data) {
    const modalData = {
      ...data,
      action: "delete",
    };

    this.handleOpenModal(modalData);
  },
  handleMenuClick: function (action, data) {
    if (action === "edit") {
      this.onPanelOpen(data.id);
    } else if (action === "delete") {
      this.handleDelete(data);
    }
    return false;
  },
  sendActivation: async function ({
    hospital_id: hospitalId,
    user_type: userType,
    email,
  }) {
    let response = {};
    let payload =
      userType === "Dokter"
        ? { email }
        : {
            id_hospital: hospitalId,
            pic_email: email,
          };

    try {
      if (userType === "Dokter") {
        response = await DoctorApi.reactivate(payload);
      } else {
        response = await HospitalListApi.reactivate(payload);
      }

      const { status, data } = response;

      if (status === 200) {
        handleToast(data.message);
        this.handleCloseModal();
      }
    } catch (error) {
      handleToastServiceError(error);
      this.handleCloseModal();
    }
  },
};

export default GridHandler;
