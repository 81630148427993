import React, { Component } from 'react'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../../../core/const/LoginConst'
import { connect } from 'react-redux'
import DoctorApi from "../../../../services/apis/doctor/DoctorApi"
import Schedule from "../tab/Schedule"
import Information from "../tab/Information"
import ButtonEditInformation from '../components/buttons/ButtonEditInformation'
import ProfilePic from '../../../../components/lib/ProfilePic'

class View extends Component
{
    constructor(props) {
        super(props)
        this.state = {
            image: {},
            name: '',
            polyclinic: '',
            specialist: '',
            activeTab: 'schedule',
            telephone: '',
            address: '',
            educations: [],
            experiences: [],
            loadingData: true,
            doctorData: null,
            imageLoaded: true,
            id: 0, 
            title: '',
        }
        this.fetchDoctor = this.fetchDoctor.bind(this)
        this.enableLoading = this.enableLoading.bind(this)
        this.handleImageError = this.handleImageError.bind(this)
    }

    componentDidMount() {
        this.fetchDoctor(this.props.id)
    }

    handleImageError() {
        this.setState({
            imageLoaded: false
        })
    }

    fetchDoctor(doctorId) {
        DoctorApi.detail(doctorId).then((res) => {
            const result = res.data.data
            this.setState({
                doctorData: res.data.data,
                image: result.photo ? result.photo : '',
                specialist: result.specialist,
                name: result.name,
                telephone: result.telephone,
                address: result.address,
                educations: result.educations || [],
                experiences: result.experiences || [],
                id: result.id,
                title: result.title,
                loadingData: false,
                imageLoaded: result.photo ? true : false,
            })
            this.props.onLoad(result.name)
        })
    }

    enableLoading(){
        this.setState({
            loadingData: true
        })
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.state.activeTab !== nextState.activeTab && nextState.activeTab !== 'schedule') {
            this.enableLoading()
            this.fetchDoctor(this.props.id)
        }
    }

    handleInfo(){
        if(!this.state.loadingData){
            this.setState({
                activeTab: 'info',
            })
        }
    }

    handleEdit(){
        this.props.history.push('/doctor/edit/' + this.state.id)
    }

    handleSchedule(){
        if(!this.state.loadingData){
            this.setState({
                activeTab: 'schedule',
            })
        }
    }

    handleLoad(){
        return this.state.specialist
    }

    render(){
        const { image, specialist, activeTab, id, imageLoaded, name, title } = this.state
        
        
        const brStyle = {
            clear: "both"
        }
        const classInfo = (activeTab === 'info') ? 'active' : ''
        const classSchedule = (activeTab === 'schedule') ? 'active' : ''
        return(
            <div className="wrap-doctor-header">
                <div className="doctor-header-detail">
                    {
                        imageLoaded ? 
                        <ProfilePic source={image} alt={name} onError={this.handleImageError}/> :
                        <div className="oval-image-placeholder"></div>
                    }
                    <h1>{ title + ' ' + name}</h1>
                    <h5>{specialist}</h5>
                    <br style={brStyle} />
                    <button 
                        onClick={e => this.handleSchedule()}
                        className={classSchedule === 'active' ? 'btn-schedule active' : 'btn-schedule'}>Jadwal</button>
                    <button 
                        onClick={e => this.handleInfo()}
                        className={classInfo === 'active' ? 'btn-information active' : 'btn-information'}>Informasi Umum
                    </button>
                    <ButtonEditInformation {...this.props} doctorId={id} className={ classInfo === 'active' ? 'btn-edit' : 'no-display' }/>
                </div>
                {classInfo === 'active' ? <Information {...this.state}  /> : <Schedule {...this.props} title={title} specialist={specialist} idDoctor={id} />}
            </div>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(View)