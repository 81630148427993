import React from 'react'
import { Router } from 'react-router-dom'
import MainRouterHandling from './MainRouterHandling'
import history from './History';

class MainRouter extends React.Component {
    render() {
        return(
            <Router history={history}>
                <MainRouterHandling store={this.props.store} />
            </Router>
        )
    }
}

export default MainRouter
