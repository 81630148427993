import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../core/const/LoginConst'
import MasterTemplate from '../../template/MasterTemplate'
import Config from './config/UrlConfig'
import Grid from './components/Grid'
import AccessPermission from '../../template/AccessPermission'

class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataGrid: [],
            isEdit: false,
        }
        this.callBackState = this.callBackState.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    callBackState(state) {
        this.setState({
            dataGrid: state,
            isEdit: state.editTrigger
        })
    }

    render() {
        const { isEdit, dataGrid } = this.state;
        const { history, store } = this.props;

        if (isEdit) {
            return <Redirect to={{
                pathname: Config.EditUrl + '/' + dataGrid.dataProvider.id
            }} />
        }

        const breadcrumbs = [
            {
                label: 'Dashboard',
                path: '/dashboardhospital',
            },
            {
                label: 'Daftar Pasien',
                path: null,
            },
        ]

        return (
            <AccessPermission {...this.props} module="manage_patient">
                <MasterTemplate {...this.props} breadcrumbs={breadcrumbs}>
                    <Grid callBackState={this.callBackState} history={history} store={store} {...this.props} />
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Main)
