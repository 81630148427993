import React, { Component } from 'react'
import { FieldGroup, FormBuilder, Validators } from 'react-reactive-form'

import WizardTemplate from '../../../../../template/WizardTemplate'
import SliderPanel from '../../../../../components/wizard/SliderPanel'
import SubmitButtonForm from '../../../../../core/utils/SubmitButtonForm'
import TextInputForm from '../../../../../core/utils/wizard/TextInputForm'
import CheckBoxForm from '../../../../../core/utils/forminput/CheckBoxForm'
import formHandler from '../../../handler/superadmin/setting/FormRoleAddHandler'

class FormRoleAdd extends Component {
    constructor(props) {
        super(props)

        Object.assign(this, TextInputForm)
        Object.assign(this, CheckBoxForm)
        Object.assign(this, formHandler)

        this.state = {
            isPaneOpen: false,
            panelData: {
                headTitle: 'Daftar Hak Akses'
            },
            Form: FormBuilder.group({
                role_name: ['', Validators.required],
                role_options: [[]],
            }),
            roleOptions: [
                {
                    "value": 1,
                    "name": "BPJS",
                },
                {
                    "value": 2,
                    "name": "Ambulance",
                },
                {
                    "value": 3,
                    "name": "Bank Darah",
                },
            ],
            isSubmit: false,
        }

        this.onPanelOpen = this.onPanelOpen.bind(this)
        this.onPanelClose = this.onPanelClose.bind(this)

        this.TextInputFormView = this.TextInputFormView.bind(this)
        this.CheckBoxFormView = this.CheckBoxFormView.bind(this)
        // this.handleSubmit = this.handleSubmit.bind(this)

        this.buildForm()
    }

    render() {
        return (
            <WizardTemplate
                {...this.props}
                breadcrumbData={[]}
                wizardShowCloseButton
                cancelUrl="/role-list">
                <div className="wizard-form role-form">
                    <FieldGroup
                        control={this.state.Form}
                        strict={false}
                        render={({ get, invalid }) => {
                            return (
                                <form
                                    onSubmit={() => this.handleSubmit}
                                    className="form-hospital"
                                    autoComplete="off">
                                    {this.TextName}

                                    <div className="form-line">
                                        <label>Hak Akses</label>
                                        <div className="form-line-role">
                                            <div>Belum ada Hak Akses</div>
                                            <button
                                                type="button"
                                                onClick={this.onPanelOpen}
                                            >
                                                Pilih Hak Akses
                                            </button>
                                        </div>
                                    </div>

                                    <SubmitButtonForm invalid={invalid}
                                        showCancel={false}
                                        submitText={"Tambah"}
                                        onSubmit={(e) => this.handleSubmit(e)}
                                    />
                                </form>
                            )
                        }}
                    />
                </div>
                <SliderPanel
                    {...this.props}
                    open={this.state.isPaneOpen}
                    onPanelClose={this.onPanelClose}
                    panelData={this.state.panelData}>
                    <div className="add-doctor-slider">
                        <p>Pilih  hak akses yang tersedia. Anda dapat memilih lebih dari satu.<br />
                            Pilih ‘Simpan’ setelah selesai menandai</p>
                        <div className="facility-list role-checbox">
                            <div className="checkboxes">
                                {this.CheckboxRoleOptions}
                            </div>
                        </div>
                    </div>
                </SliderPanel>
            </WizardTemplate>
        )
    }
}

export default FormRoleAdd