import React from 'react'
import { connect } from 'react-redux'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../core/const/LoginConst'
import { Redirect } from 'react-router-dom'
import dateFns from "date-fns"
import IndonesianLocale from 'date-fns/locale/id'
import Config from './config/DataConfig'
import MasterTemplate from '../../template/MasterTemplate'
import AssignPatient from './components/AssignPatient'
import MasterModal from '../../core/utils/MasterModal'
import Api from '../../services/apis/Appointment/Main'
import AccessPermission from '../../template/AccessPermission'

class Assign extends React.Component {
    constructor(props) {
        super(props)
        this.ScheduleId = this.props.match.params.scheduleid
        this.state = {
            isLoading: true,
            scheduleData: {
                doctorId: null,
                doctorName: '...',
                date: '...',
                time: '...'
            },
            isModalOpen: false,
            ModalData: null,
            redirectTo: null
        }

        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.modalHandleSubmit = this.modalHandleSubmit.bind(this)
    }

    isValidDate(dateStr) {
        let string = dateStr.split('-')
        const year = parseInt(string[0], 0)
        const month = parseInt(string[1], 0)
        const day = parseInt(string[2], 0)
        var d = new Date(year, month - 1, day)

        if (d.getFullYear() === year && (d.getMonth() + 1) === month && d.getDate() === day) {
            return true
        }
        return false
    }

    handleOpenModal() {
        const ModalData = {
            action: "exit_appointment",
            id: null,
            content: "Anda akan keluar dari halaman Tambah Antrian, apakah anda yakin?",
            buttonConfirmText: "Ya, Keluar",
            buttonCancelText: "Tidak",
            ConfirmFunction: null
        }
        this.setState({
            isModalOpen: true,
            ModalData: ModalData
        })
    }

    handleCloseModal() {
        this.setState({
            isModalOpen: false,
            ModalData: null
        })
    }

    modalHandleSubmit() {
        let me = this
        const ModalData = me.state.ModalData

        if (ModalData.action === 'exit_appointment') {
            this.setState({
                redirectTo: '/appointment/list'
            })
        }
    }

    componentDidMount() {
        const ScheduleId = this.ScheduleId
        const {
            match: {
                params: {
                    chosenDate,
                },
            },
        } = this.props
        const isValidDate = this.isValidDate(chosenDate)

        if (!isValidDate) {
            this.setState({
                redirectTo: Config.baseUrl
            })
            return
        }

        Api.view(ScheduleId, chosenDate).then((result) => {
            if (result.data) {
                const res = result.data.data

                if (res && res.doctor_data) {
                    const doctor_data = res.doctor_data
                    const schedule_data = res
                    this.setState({
                        isLoading: false,
                        scheduleData: {
                            ...this.state.scheduleData,
                            doctorId: doctor_data.id,
                            doctorName: doctor_data.name,
                            doctorTitle: doctor_data.title,
                            date: dateFns.format(
                                chosenDate,
                                'D MMMM YYYY',
                                {
                                    locale: IndonesianLocale
                                }
                            ),
                            time: `${schedule_data.time_start} - ${schedule_data.time_end}`,
                            is_custom_schedule: res.is_custom_schedule,
                            time_options: res.time_options,
                        },
                    })
                }
            }
        })
    }

    render() {
        const scheduleData = this.state.scheduleData
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} />
        }
        return (
            <AccessPermission {...this.props} module="appointment">
                <MasterTemplate
                    {...this.props}
                    wizard={true}
                    onBeforeClose={this.handleOpenModal}
                    backByDefault={true}
                    cancelUrl={Config.cancelUrl}
                    breadcrumbData={
                        [
                            {
                                no: '1',
                                label: 'Pilih Jadwal',
                                finished: true,
                                path: scheduleData.doctorId ? `/appointment/schedule/${scheduleData.doctorId}` : `/appointment`,
                                onClick: (e) => {
                                    if (!scheduleData.doctorId) {
                                        e.preventDefault()
                                        return false
                                    }
                                }
                            },
                            {
                                no: '2',
                                label: 'Pilih Pasien',
                                finished: false,
                                path: '/appointment/assign',
                                onClick: (e) => {
                                    e.preventDefault()
                                    return false
                                }
                            }
                        ]
                    }
                    wizardShowCloseButton={true}
                >
                    <AssignPatient {...this.props} scheduleData={this.state.scheduleData} isLoading={this.state.isLoading} />
                    <MasterModal
                        className="master-modal"
                        isOpen={this.state.isModalOpen}
                        handleClose={this.handleCloseModal}
                        handleSubmit={this.modalHandleSubmit}
                        ModalData={this.state.ModalData}
                    />
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Assign)
