import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import { loginMapStateToProps, loginMapDispatchToProps } from '../../core/const/LoginConst'
import { handleToast } from '../../core/utils/UtilsToast'
import RoleHelper from '../../core/helper/RoleHelper'
import AccessPermission from '../../template/AccessPermission'
import MasterTemplate from '../../template/MasterTemplate'
import Grid from './components/Grid'

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        const { msgEMR, successMsgEMR } = this.props
        if (msgEMR) {
            successMsgEMR('')
            handleToast(msgEMR)
        }
        window.scrollTo(0, 0);
    }

    isOwner = (newCurrentHosp) => {
        const { dataLogin } = this.props
        const hospitalSelected = dataLogin && dataLogin.hospitals.find(option => {
            return String(option.id) === String(newCurrentHosp)
        })

        return !isEmpty(hospitalSelected) ? hospitalSelected.owner : false
    }

    getRole = (newCurrentHosp) => {
        const { dataLogin } = this.props
        return RoleHelper.getRole(dataLogin, this.isOwner(newCurrentHosp))
    }

    render() {
        const { isLoggin, currentHospital } = this.props
        const role = this.getRole(currentHospital)

        if (currentHospital === null
            || !isLoggin
            || process.env.REACT_APP_HOSPITAL_MEDICAL_RECORD !== 'true') {
            return <Redirect to="/" />
        }

        if (((role && role.role_name !== 'superadmin')
            && (role && role.role_name !== 'admin_spv')
            && (role && role.role_name !== 'doctor')
        )) {
            return <Redirect to="/" />
        }

        const breadcrumbs = [
            {
                label: 'Dashboard',
                path: '/dashboardhospital',
            },
            {
                label: 'Daftar Resume Medis',
                path: null,
            },
        ]

        return (
            <AccessPermission {...this.props} module="medical_record">
                <MasterTemplate {...this.props} breadcrumbs={breadcrumbs}>
                    <Grid {...this.props} />
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(List)
