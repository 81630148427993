import axios from '../MasterConfig'
const groupUrl = "user_spg"

export default class UserApi {
    //list spg
    static get = (
        hospitalId, 
        page, 
        pageSize, 
        sortProperties, 
        sortValue, 
        filterData
    ) => axios.get(
        `/${ groupUrl }/list?id_hospital=${ hospitalId }&page=${ page }&sort=${ sortProperties }&order=${ sortValue }&limit=${ pageSize }&name=${ filterData}`
    )
    static post = (data) => axios.post(`/${groupUrl}/save`, data)
    static delete = (id) => axios.delete(`/${groupUrl}/delete/${id}`)
    static update = (id, data) => axios.put(`/${groupUrl}/update/${ id }`, data)
    static view = (id) => axios.get(`/${groupUrl}/get/${id}`)
    static bulkDelete = (data) => axios.post(`/${groupUrl}/bulkdeleted`, data)
    static bulkDownload = (data) => axios.post(`/${groupUrl}/download`, data)
}