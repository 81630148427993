import React from 'react'
import Chance from 'chance'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

import { handleToast, handleToastServiceError } from '../../../core/utils/UtilsToast'
import Api from '../../../services/apis/Hospital/ServiceFee'

const chance = new Chance()

const GridHandler = {
    handleOpenModal(data) {
        this.setState({
            isModalOpen: true,
            modalData: data,
        })
    },
    handleCloseModal() {
        this.setState({
            isModalOpen: false,
            modalData: null,
        })
    },
    handleDelete(data) {
        const ModalData = {
            id: data.id,
            action: 'delete',
            content: `Konfirmasi hapus layanan <strong>${data.name}</strong>. <br />Apakah anda yakin?`,
            buttonConfirmText: 'Konfirmasi',
            buttonCancelText: 'Kembali',
        }

        this.handleOpenModal(ModalData)
    },
    handleMenuClick(action, data) {
        if (action === 'edit') {
            const { history } = this.props
            history.push(`/administration/service-fee/edit/${data.id}`)
        } else if (action === 'delete') {
            this.handleDelete(data)
        }
    },
    loadDataAfterAction() {
        const state = {
            pageSize: this.pageSize,
            page: this.page,
            sorted: this.sorted,
            filter: '',
            filtered: this.state.filtered,
        }

        this.fetchData(state)
    },
    gridColumn(ContextMenuProvider) {
        return [
            {
                Header: 'Nama Layanan',
                accessor: 'name',
                headerClassName: '-sort',
                Cell: row => row.original.name || '-',
            },
            {
                Header: 'Kode Layanan',
                accessor: 'code',
                maxWidth: 300,
                headerClassName: '-sort',
                Cell: row => row.original.code || '-',
            },
            {
                Header: 'Harga',
                accessor: 'price',
                maxWidth: 250,
                headerClassName: '-sort',
                Cell: row => {
                    return this.toRp(row.original.price)
                },
            },
            {
                maxWidth: 40,
                sortable: false,
                className: 'grid-action text-center',
                Cell: row => {
                    return (
                        <ContextMenuProvider
                            id="action_menu"
                            event="onClick"
                            data={row.original}
                        >
                            <button style={{ width: 21 }}>
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </button>
                        </ContextMenuProvider>
                    )
                },
            },
        ]
    },
    fetchData: async function (state) {
        this.setState({ loading: true })
        this.page = state.page
        this.pageSize = state.pageSize

        let res = {}
        let fArray = {}
        let sort_property = ''
        let sort_value = ''

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'DESC' : 'ASC'
            this.sorted = state.sorted
        }

        if (state.filtered.length > 0) {
            state.filtered.map(val => {
                if (val.value !== '') {
                    fArray[val.id] = val.value
                }
                return true
            })
        }

        const filterData = JSON.stringify(fArray)
        try {
            res = await Api.list(
                this.currentHospital,
                this.page + 1,
                this.pageSize,
                sort_property,
                sort_value,
                filterData,
            )
        } catch (e) {
            this.setState({ loading: false })
            handleToastServiceError(e)
            return
        }

        const { status, data: resData } = res
        if (status === 200) {
            const newRows = resData.data.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })

            this.setState({
                loading: false,
                data: newRows,
                pages: resData.data.total_page,
                rows: resData.data.total_rows,
            })
        } else {
            this.setState({ loading: false })
        }
    },
    modalHandleSubmit: async function () {
        const { modalData } = this.state

        if (modalData.action === 'delete') {
            try {
                const response = await Api.delete(modalData.id)
                handleToast(response.data.message)

                this.loadDataAfterAction();
                this.handleCloseModal()
            } catch (e) {
                handleToastServiceError(e)
                this.handleCloseModal()
            }
        }
    },
    toRp(number) {
        if (number) {
            return `Rp ${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
        }

        return 'Rp 0 (Gratis)'
    },
}

export default GridHandler
