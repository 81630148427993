import React from 'react'

export default class SubmitButtonForm extends React.Component {
    showCancel(){
        return (
            <button type="button" onClick={ this.props.onCancel } className="btn btn-secondary btn-cancel">
                <span>Batal</span>
            </button>
        )
    }
    render(){
        const cancelButton = this.props.showCancel ? this.showCancel() : ''
        return (
            <div className="wrap-button">
                {cancelButton}
                <button type="submit" onClick={this.props.onSubmit} className={!this.props.invalid ? "btn btn-submit-wizard btn-primary": "btn btn-submit-wizard btn-secondary"} disabled={this.props.invalid}>
                    {this.props.submitText ? this.props.submitText : 'Save Changes'}
                </button>
            </div>
        )
    }
}
