import axios, { axiosBase } from '../MasterConfig'

const axiosB = axiosBase()
const ApiUrlSchedule = "doctor_schedule"
const ApiUrlPatient = "patient"
const ApiUrlAppointment = "appointment"

class Api {
    static getSchedule = (yearMonth, hospitalId, doctorId) => axios.get(`/${ApiUrlSchedule}/list/${yearMonth}/${hospitalId}/${doctorId}`)
    static getPoly = (hId, dId, date) => {
        let setDoctor = ''
        if (dId !== '') {
            setDoctor = `&id_doctor=${dId}`
        }

        return axios.get(`/${ApiUrlSchedule}/listschedule?id_hospital=${hId}&date=${date}${setDoctor}`)
    }
    static view = (scheduleId, date) => axios.get(`/${ApiUrlSchedule}/view/${scheduleId}?book_date=${date}`)
    static getPatient = (hospitalId, page, pageSize, sortProperties, sortValue, filterData) => axios.get(`/${ApiUrlPatient}/list?id_hospital=${hospitalId}&page=${page}&sort=${sortProperties}&order=${sortValue}&limit=${pageSize}&filter=${filterData}`)
    static createBooking = (data) => axiosB.post(`/hospital/${ApiUrlAppointment}`, data)
    static calendar = (hospitalId, doctorId, year, month) => {
        const setDoctorId = doctorId ? `&doctor_id=${doctorId}` : ''
        const queryString = `?hospital_id=${hospitalId}${setDoctorId}&year=${year}&month=${month}`

        return axiosB.get(`/${ApiUrlAppointment}/calendar${queryString}`)
    }
}

export default Api
