import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import SideBarChildTemplate from './SideBarChildTemplate'
import SideBarFooter from './SideBarFooter'
import { modalMapStateToProps, modalMapDispatchToProps } from '../../core/const/ModalConst'
import { sAdminDashMenu } from '../../core/const/Default'

/** ----------------- Start --------------------- **/

class SuperAdminSideBarTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeMenu: null,
            menus: []
        }
    }

    componentWillMount() {
        const mastermenu = [...sAdminDashMenu].filter(menu => menu.visible !== false);

        this.setState({
            menus: mastermenu
        })
    }

    render() {
        const { pathname } = this.props.location
        const menus = this.state.menus

        const renderMenu = menus.map((menu, index) => {
            let activeMenu = menu.path === pathname ||
                pathname.startsWith(`/${menu.index}`) !== false
            if (menu.children) {
                if (!activeMenu) {
                    for (let i = 0; i < menu.children.length; i++) {
                        if (menu.children[i].path === pathname ||
                            pathname.startsWith(menu.children[i].path + '/') !== false) {
                            activeMenu = true
                            break
                        }
                    }
                }
                return (
                    <li key={index} className={activeMenu ? 'active dashboard-admin' : 'dashboard-admin'} >
                        <SideBarChildTemplate
                            handleHoverMenu={this.props.handleHoverMenu}
                            menu={menu}
                            pathname={pathname}
                            history={this.props.history}
                            dropdownShown={activeMenu} />
                    </li>
                )
            } else {
                return (
                    <li key={index} className={activeMenu ? 'active dashboard-admin' : 'dashboard-admin'} >
                        <Link className="left-link" to={menu.path}>
                            <div
                                className="icon-menu"
                                onMouseOver={() => this.props.handleHoverMenu(true)}>
                                <img
                                    src={activeMenu ? menu.iconHover : menu.icon}
                                    alt="icon-menu" />
                            </div>
                            <span className="expand-will-hide">
                                <span>{menu.title}</span>
                            </span>
                        </Link>
                    </li>
                )
            }
        })

        const hoverClass = this.props.visitedOverlay ? ' visited-overlay' : ''
        return (
            <nav
                className={
                    this.props.isToggleButton ?
                        'side-navbar shrinked no-shadow-top' + hoverClass :
                        'side-navbar'
                }
                onMouseLeave={() => this.props.handleHoverMenu(false)}>
                <div className="separator-quick-button admin" />
                <ul className="list-unstyled scrollable dashboard">
                    {renderMenu}
                </ul>
                <SideBarFooter />
            </nav>
        )
    }
}

export default connect(modalMapStateToProps, modalMapDispatchToProps)(SuperAdminSideBarTemplate)