export const LOGIN_API_CALL_REQUEST = "LOGIN_API_CALL_REQUEST"
export const LOGIN_API_CALL_SUCCESS = "LOGIN_API_CALL_SUCCESS"
export const LOGIN_API_CALL_FAILURE = "LOGIN_API_CALL_FAILURE"
export const LOGIN_API_CALL_LOGOUT = "LOGIN_API_CALL_LOGOUT"
export const CHANGE_HOSPITAL = "CHANGE_HOSPITAL"
export const ADMIN_CHANGE_HOSPITAL = "ADMIN_CHANGE_HOSPITAL"
export const SET_PATIENT_EMR = "SET_PATIENT_EMR"
export const SET_DOCTOR_EMR = "SET_DOCTOR_EMR"
export const SET_SUCCESS_MSG = "SET_SUCCESS_MSG"
export const NOTIF_APPOINTMENT = "NOTIF_APPOINTMENT"

export const initialState = {
    isLoggin: false,
    token: null,
    error: null,
    fetching: false,
    dataLogin: null,
    dataPost: null,
    currentHospital: null,
    currentHospitalName: null,
    currentHospitalLogo: null,
    currentDoctorPic: null,
    verified: false,
    patientEMR: {},
    doctorEMR: {},
    msgEMR: '',
    notifAppointment: [],
};

export const loginMapStateToProps = state => {
    return {
        isLoggin: state.loginReducer.isLoggin,
        token: state.loginReducer.token,
        error: state.loginReducer.error,
        fetching: state.loginReducer.fetching,
        dataLogin: state.loginReducer.dataLogin,
        currentHospital: state.loginReducer.currentHospital,
        currentHospitalName: state.loginReducer.currentHospitalName,
        currentHospitalLogo: state.loginReducer.currentHospitalLogo,
        currentDoctorPic: state.loginReducer.currentDoctorPic,
        verified: state.loginReducer.verified,
        patientEMR: state.loginReducer.patientEMR,
        doctorEMR: state.loginReducer.doctorEMR,
        msgEMR: state.loginReducer.msgEMR,
        notifAppointment: state.loginReducer.notifAppointment,
    };
};

export const loginMapDispatchToProps = dispatch => {
    return {
        onRequestLogin: (params) => dispatch(
            {
                type: LOGIN_API_CALL_REQUEST,
                dataPost: params
            }
        ),
        onChangeHospital: (id) => dispatch(
            {
                type: CHANGE_HOSPITAL,
                hospitalId: id
            }
        ),
        onRequestLogout: () => dispatch(
            {
                type: LOGIN_API_CALL_LOGOUT
            }
        ),
        onAdminChangeHospital: (id, name, verified) => dispatch(
            {
                type: ADMIN_CHANGE_HOSPITAL,
                hospitalId: id,
                hospitalName: name,
                verified,
            }
        ),
        setPatientEMR: patient => dispatch(
            {
                type: SET_PATIENT_EMR,
                patient,
            }
        ),
        setDoctorEMR: doctor => dispatch(
            {
                type: SET_DOCTOR_EMR,
                doctor,
            }
        ),
        successMsgEMR: msg => dispatch(
            {
                type: SET_SUCCESS_MSG,
                msg,
            }
        ),
        setNotifAppointment: notif => dispatch(
            {
                type: NOTIF_APPOINTMENT,
                notif,
            }
        ),
    };
};
