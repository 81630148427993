import React, { Component } from 'react'
import DoctorList from './DoctorList'

class SpecialistList extends Component {
    render() {
        let finalSpecialist = []
        const { isLoading, isSingle, SpecialistSelected, listSpecialist } = this.props

        if (SpecialistSelected && SpecialistSelected.value !== 0) {
            listSpecialist.map(data => {
                if (data.id_doctor_specialist === SpecialistSelected.value) {
                    finalSpecialist.push(data)
                }
                return finalSpecialist
            })
        } else {
            finalSpecialist = listSpecialist
        }

        if (isLoading) {
            return (<div className="poly-group">Loading...</div>)
        }

        return (
            <div className="poly-group" style={!isSingle ? { marginTop: 0 } : {}}>
                {
                    finalSpecialist.map((data, key) => {
                        return (
                            <div className="list-poly" key={key}>
                                <span className="name">{data.specialist_name}</span>
                                <span className="count">
                                    {data.doctor ? data.doctor.length : 0} Dokter tersedia
                                </span>
                                <DoctorList {...this.props} doctors={data.doctor} />
                            </div>
                        )
                    })
                }
                {
                    finalSpecialist.length <= 0 ?
                        <div>Tidak ada data</div> :
                        null
                }
            </div>
        )
    }
}

export default SpecialistList