import Api from '../../../../../../services/apis/SpecialistApi'
import {
    handleToast,
    handleToastServiceError
} from '../../../../../../core/utils/UtilsToast'

const FormHandler = {
    buildForm() {
        this.nameField = this.TextInputFormView({
            validator: "required",
            label: "Nama",
            name: "nama",
            placeholder: "Nama",
            control: this.state.Form.controls.nama
        })

        this.codeField = this.TextInputFormView({
            validator: "required",
            label: "Kode",
            name: "kode",
            placeholder: "Kode",
            maxLength: 5,
            control: this.state.Form.controls.kode
        })

        this.descField = this.TextInputFormView({
            label: "Deskripsi",
            name: "deskripsi",
            placeholder: "Deskripsi",
            control: this.state.Form.controls.deskripsi
        })
        
        this.priceChatField = this.TextInputPriceFormView({
            label: 'Price Chat',
            name: 'price_chat',
            placeholder: 'Price Chat',
            inputType: 'price',
            maxLength: 11,
            control: this.state.Form.controls.price_chat
        })
        
        this.priceVideoCallField = this.TextInputPriceFormView({
            label: 'Price VideoCall',
            name: 'price_videocall',
            placeholder: 'Price VideoCall',
            inputType: 'price',
            maxLength: 11,
            control: this.state.Form.controls.price_videocall
        })
    },
    buildValue(data) {
        this.state.Form.patchValue(data)
    },
    handleUpload(file) {
        this.setState({ file })
        this.state.Form.patchValue({ icon: file })
    },
    handleSubmit: async function (e) {
        e.preventDefault();

        let response = {}
        const { dataEdit } = this.props
        const formValue = Object.assign({}, this.state.Form.value)
        formValue.price_chat = formValue.price_chat === dataEdit.price_chat ? formValue.price_chat : parseInt(formValue.price_chat.replace(/\./g, '').substring(0, 9), 10)
        formValue.price_videocall = formValue.price_videocall === dataEdit.price_videocall ? formValue.price_videocall :  parseInt(formValue.price_videocall.replace(/\./g, '').substring(0, 9), 10)
        try {
            if (dataEdit) {
                response = await Api.put(dataEdit.id, formValue)
            } else {
                response = await Api.post(formValue)
            }

            handleToast(response.data.message)

            this.props.loadDataAfterAction()
            this.props.onPanelClose()
        } catch (error) {
            const errData = error.response.data.data
            const message = Object.keys(errData).map(k => errData[k]).join('\n')

            handleToastServiceError({ message })
        }
    }
}

export default FormHandler
