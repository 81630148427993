import React from 'react'
import Chance from 'chance'
import { isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'

import Api from '../../../services/apis/Hospital/HospitalServices'
import { handleToast, handleToastServiceError } from '../../../core/utils/UtilsToast'
import { formatCurrency } from '../../../core/utils/Helper';

const chance = new Chance();

const GridHandler = {
    onPanelOpen(data) {
        const { title: titleProps } = this.props

        let title = `Tambah ${titleProps} Baru`
        const isEdit = !isEmpty(data)

        if (isEdit) title = `Edit Grup ${titleProps}`

        this.setState({
            isPanelOpen: true,
            panelData: {
                headTitle: <div>{title}</div>,
                isEdit,
                ...data
            },
        })
    },
    onPanelClose() {
        this.setState({
            isPanelOpen: false,
            panelData: {},
        })
    },
    handleOpenModal(modalData) {
        this.setState({
            isModalOpen: true,
            modalData,
        })
    },
    handleCloseModal() {
        this.setState({
            checkedConfirm: false,
            isModalOpen: false,
            modalData: null,
        })
    },
    handleDelete(data) {
        const modalData = {
            ...data,
            action: 'delete',
        }

        this.handleOpenModal(modalData)
    },
    getDetail: async function (id) {
        this.setState({ loading: true })

        await Api.detail(id).then(({ data }) => {
            const { status, data: dataRes, message } = data

            this.setState({ loading: false })

            if (status) {
                this.onPanelOpen(dataRes)
            } else {
                handleToastServiceError({ message })
            }
        })
    },
    modalHandleSubmit: async function () {
        const { modalData } = this.state

        if (modalData.action === 'delete') {
            try {
                const response = await Api.delete(modalData.id)
                handleToast(response.data.message)

                this.loadDataAfterAction();
                this.handleCloseModal()
            } catch (e) {
                handleToastServiceError(e)
                this.handleCloseModal()
            }
        }
    },
    loadDataAfterAction() {
        const state = {
            pageSize: this.pageSize,
            page: this.page,
            sorted: this.sorted,
            filter: '',
            filtered: this.state.filtered,
        }

        this.fetchData(state)
    },
    gridColumn(ContextMenuProvider) {
        const { dataLogin } = this.props
        return [
            {
                className: "grid-icon",
                width: 38,
                Cell: (row) => {
                    if(!row.original.photo){
                        return ''
                    }
                    return <img src={row.original.photo} alt='service icon' onError={({ currentTarget }) => {
                        currentTarget.src = '/img/empty/no-preview-available.png';
                        currentTarget.onerror = null;
                    }}/>
                }
            },
            {
                Header: 'Nama',
                accessor: 'name',
                headerClassName: '-sort',
                Cell: (row) => {
                    return row.original.name || '-'
                },
            },
            {
                Header: 'Kategori',
                accessor: 'master_service_name',
                Cell: (row) => {
                    const category = row.original?.master_service_name;
                    return (
                        <div className="custom-layanan">
                            <span className="font-size-11 color-gray">{category ? category.toUpperCase() : '-'}</span>
                            <p>
                                {row.original.category_service_name || '-'}
                            </p>
                        </div>
                    )
                },
            },
            {
                Header: 'Harga',
                accessor: 'price',
                sortable: false,
                Cell: ({ original }) => {
                    if(original.price === 0){
                        return 'Gratis'
                    }
                    return formatCurrency(original.price)
                },
            },
            {
                Header: 'Upload KTP',
                accessor: 'is_upload_idcard',
                Cell: ({ original }) => {
                    const label = original.is_upload_idcard === true
                        ? 'Ya'
                        : 'Tidak'
                    const badgeStyle = original.is_upload_idcard === true
                        ? 'badge-primary'
                        : 'badge-danger'

                    return (
                        <div className="badge-label">
                            <span className={`badge badge-pill ${badgeStyle}`}>
                                {label}
                            </span>
                        </div>
                    )
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                headerClassName: '-sort',
                Cell: ({ original }) => {
                    const label = original.is_active === true
                        ? 'Aktif'
                        : 'Non Aktif'
                    const badgeStyle = original.is_active === true
                        ? 'badge-primary'
                        : 'badge-danger'

                    return (
                        <div className="badge-label">
                            <span className={`badge badge-pill ${badgeStyle}`}>
                                {label}
                            </span>
                        </div>
                    )
                },
            },
            {
                Header: '',
                maxWidth: 60,
                sortable: false,
                className: 'grid-action',
                Cell: ({ original }) => {
                    return (
                        <ContextMenuProvider
                            id='menu-action'
                            data={original}
                            event="onClick"
                        >
                            <button className="action-row">
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                        </ContextMenuProvider>
                    )
                },
            },
            {
                Header: '',
                maxWidth: 60,
                sortable: false,
                className: 'grid-action',
                Cell: ({ original }) => {
                    return (
                        <div className="grid-btn-wrapper">
                            {
                                dataLogin.user_type === "a" 
                                ? 
                                ''
                                :
                                <button>
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        onClick={() => this.handleDelete(original)}
                                    />
                                </button>
                            }
                        </div>
                    )
                },
            },
        ]
    },
    handleClickMenu: function(action, data) {
        if(action === 'schedule'){
            return this.props.history.push(`/hospital/services/schedule/${data.id}`)
        }else if(action === 'edit'){
            return this.getDetail(data.id)
        }

        return false
    },
    fetchData: async function (state) {
        this.setState({ loading: true })
        this.page = state.page
        this.pageSize = state.pageSize

        let res = {}
        let fArray = {}
        let sort_property = ''
        let sort_value = ''
        const idHospital = this.props.currentHospital

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'DESC' : 'ASC'
            this.sorted = state.sorted
        }

        if (state.filtered && state.filtered.length > 0) {
            state.filtered.map(val => {
                if (val.value !== '') {
                    fArray[val.id] = val.value
                }
                return true
            })
        }

        const filterData = JSON.stringify(fArray)

        try {
            res = await Api.list(
                this.currentHospital,
                this.page + 1,
                this.pageSize,
                sort_property,
                sort_value,
                filterData,
                idHospital,
            )
        } catch (error) {
            this.setState({ loading: false })

            let message = error.message
            let errData = error.response ? error.response.data : null

            if (!isEmpty(errData) && errData.data !== '') {
                errData = errData.data
                message = Object.keys(errData).map(k => errData[k]).join('\n')
            }

            handleToastServiceError({ message })
            return
        }

        const {
            status,
            data: resData,
        } = res

        if (status === 200) {
            const newRows = resData.data.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })

            this.setState({
                loading: false,
                data: newRows,
                pages: resData.data.total_page,
                rows: resData.data.total_rows,
            })
        } else {
            this.setState({ loading: false })
        }
    },
    getFilter: async function (isDashboard) {
        try {
            let response = { data: {} }
            const idHospital = this.props.currentHospital

            if (isDashboard) {
                response = await Api.serviceFilter()
            } else {
                response = await Api.serviceFilterHospital(idHospital)
            }

            const { data } = response.data
            const filterList = data

            this.setState({ filterList })
        } catch (e) {
            handleToastServiceError(e)
        }
    },
}

export default GridHandler