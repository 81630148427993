import DashboardApi from './../../../services/apis/Dashboard/Api'
import HospitalApi from './../../../services/apis/Hospital/List'
import HospitalServicesApi from './../../../services/apis/Hospital/HospitalServices'
import FacilityApi from './../../../services/apis/FacilityApi'
import { handleToast } from '../../../core/utils/UtilsToast'

const mainHandler = {
    handleCloseModal(){
        this.setState({
            isModalOpen: false,
        })
    },
    modalHandleSubmit(theData){
    },
    handleOpenModal(e, type){
        e.preventDefault()
        this.setState({
            isModalOpen: true,
            toggleMenu: false,
            modalType: type,
        })
    },
    parseInstitution(collection, type){
        let arrReturn = []

        if (collection && collection.length > 0) {
            collection.map((option) => {
                switch (type) {
                    case 1:
                        if (option.type === 1) {
                            arrReturn.push({
                                label: option.name,
                                value: option.id,
                            })
                        }
                        break;
                    case 2:
                        if (option.type === 2)
                            arrReturn.push({
                                label: option.name,
                                value: option.id,
                            })
                        break;
                    default:
                        arrReturn.push({
                            label: option.name,
                            value: option.id,
                        })
                        break;
                }
                return true
            })
        }

        return arrReturn 
    },
    updateFilterType(collection, type){
        this.setState({
            typeList: this.parseInstitution(collection, type)
        })
    },
    fetchDashboardData() {
        DashboardApi.getAdditional().then((res) => {
            if (res.status !== 200) {
                handleToast('Something went wrong')
                return
            }

            this.fetchFacility()
            this.fetchInstitution()

            this.setState({
                dashboardDate: res.data.data.date,
                count: {
                    doctor: res.data.data.count.doctor,
                    clinic: res.data.data.count.clinic,
                    hospital: res.data.data.count.hospital,
                },
                cityList: res.data.data.filter.city,
                allType: res.data.data.filter.type,
                typeList: this.parseInstitution(res.data.data.filter.type, 0),
                // dataHospital: res.data.data.hospital,
                isFetching: false
            })
        }).catch(error => {
            console.log('Something went wrong:', error)
            return
        })
    },
    fetchHospital: async function (pageSize) {
        HospitalApi.getHospitalDashboard(
            1,
            pageSize,
            'created_at',
            'desc',
            JSON.stringify({})
        ).then((res) => {
            const {
                status,
                data: {
                    data,
                },
            } = res

            if (status !== 200) {
                handleToast('Something went wrong')
                return
            }

            this.setState({
                dataHospital: data.rows || [],
            })
            this.finishLoading()
        }).catch(error => {
            console.log('Something went wrong:', error)
            return
        })
    },
    fetchFacility: async function () {
        await FacilityApi.get().then((res) => {
            if (res.status === 200) {
                const { data } = res.data
                const facilityList = data.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    }
                })

                this.setState({
                    facilityList
                })
            }
        }).catch(error => {
            console.log('Something went wrong:', error)
            return
        })
    },
    fetchInstitution: async function () {
        await HospitalServicesApi.listInstitution().then((res) => {
            if (res.status === 200) {
                const { data: institutionList } = res.data

                this.setState({
                    institutionList: institutionList
                })
            }
        }).catch(error => {
            console.log('Something went wrong:', error)
            return
        })
    },
}

export default mainHandler