import React, {Component} from 'react'
import Select from 'react-select'
import connect from 'react-redux/es/connect/connect'
import { Redirect } from 'react-router-dom'
import { ContextMenu, Item, theme } from 'react-contexify'

import {
    loginMapDispatchToProps, 
    loginMapStateToProps
} from '../../../core/const/LoginConst'
import nextBtn from '../../../themes/v1/img/next.png'
import prevBtn from '../../../themes/v1/img/prev.png'
import FilterBar from '../components/FilterBar'
import BulkUpload from '../components/BulkUpload'
import gridHandler from '../handler/Grid'
import MasterModal from '../../../core/utils/MasterModal'
import ModalFilterContent from './superadmin/ModalFilterContent'
import HospitalCard from '../components/superadmin/hospital/CardList'
import { handleToastServiceError } from '../../../core/utils/UtilsToast'

import 'react-contexify/dist/ReactContexify.min.css'

class Grid extends Component
{
    constructor(props) {
        super(props)
        Object.assign(this, gridHandler)
        this.handleClick = this.handleClick.bind(this)
        this.handleClickHospital = this.handleClickHospital.bind(this)
        this.handleFirstPage = this.handleFirstPage.bind(this)
        this.handleNextPage = this.handleNextPage.bind(this)
        this.handlePrevPage = this.handlePrevPage.bind(this)
        this.handleEndPage = this.handleEndPage.bind(this)
        this.handleJumpPage = this.handleJumpPage.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.handleSort = this.handleSort.bind(this)
        this.handleAdvancedFilter = this.handleAdvancedFilter.bind(this)
        this.handleCancelFilter = this.handleCancelFilter.bind(this)
        this.handleResetFilter = this.handleResetFilter.bind(this)
        this.handleCheckedFilter = this.handleCheckedFilter.bind(this)
        this.handleUncheckedFilter = this.handleUncheckedFilter.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.modalHandleSubmit = this.modalHandleSubmit.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.parseModal = this.parseModal.bind(this)
        this.createDummyBox = this.createDummyBox.bind(this)
        this.updateSelectedFilter = this.updateSelectedFilter.bind(this)
        this.verifiedMenu = this.verifiedMenu.bind(this)
        this.showHospital = this.showHospital.bind(this)
        this.setFilterName = this.setFilterName.bind(this)
        
        this.state = {
            sortOptionsSelected: 0,
            sortOptions: [
                {
                    label: 'Terbaru',
                    value: 0,
                },
                {
                    label: 'Terakhir diubah',
                    value: 1,
                },
                {
                    label: 'Pertama dimasukkan',
                    value: 2,
                },
                {
                    label: 'Abjad',
                    value: 3,
                }
            ],
            filterSet: {
                name: '',
                city: null,
                institution: null,
                status: '',
            },
            pages: [
                {
                    label: '20',
                    value: 20,
                },
                {
                    label: '30',
                    value: 30,
                },
                {
                    label: '40',
                    value: 40,
                },
            ],
            pagesSelected: 20,
            pageSize: this.props.pageSize,
            rowMin: 1,
            rowMax: 20,
            page: 1,
            sort: "created_at",
            order: "desc",
            hospitals: [],
            totalPage: 0,
            totalRow: 0,
            toggleFilter: false,
            facilities: [],
            types: [],
            filters: [],
            isModalOpen: false,
            selectedHospital: {},
            modalType: null,
            ModalData: null,
            redirectDashboardHos: false,
            redirectDashboardHosInfo: false,
            jumpToPage: 1,
        }
    }

    setFilterName(value){
        this.setState({
            filterSet: {
                ...this.state.filterSet,
                name: value
            }
        })
    }

    componentWillReceiveProps(nextProps){
        if(!nextProps.hospitalCopied && this.state.hospitals !== nextProps.dataHospital){
            this.setState({
                hospitals: nextProps.dataHospital
            })
            this.props.afterRenderHospital()
        }
    }

    showSort() {
        const total = this.props.count.hospital.total
        if (parseInt(total, 10) > 0) {
            return (
                <div>
                    <span>Urutkan Berdasarkan</span>
                    <Select
                        className="pagesize dropdown-filter"
                        classNamePrefix="react-select"
                        name="filter-type"
                        options={this.state.sortOptions}
                        onChange={this.handleSort}
                        value={
                            this.state.sortOptions.find(
                                option => option.value === this.state.sortOptionsSelected
                            )
                        }
                    />
                </div>
            )
        }
    }

    showPagination() {
        let totalHospital = (this.props.count.hospital !== null)
            ? this.props.count.hospital.total
            : 0;
        let totalClinic = (this.props.count.clinic !== null)
            ? this.props.count.clinic.total
            : 0;
        let total = totalClinic + totalHospital
        let {
            hospitals,
            totalRow,
            pages,
            pagesSelected,
            rowMax,
            rowMin,
            page,
            totalPage,
            pageSize,
            jumpToPage,
        } = this.state

        if (totalRow > 0) {
            total = totalRow
        }
        
        const countPage = Math.ceil(total / pageSize) || 1
        const endPage = hospitals.length > 0 ?
            (totalPage || countPage)
            : 1

        if (total > pageSize)
            return (
                <div className="dashboard_pagination">
                    <span>Tampilkan Baris</span>
                    <Select
                        className="pagesize page-num dropdown-filter"
                        classNamePrefix="react-select"
                        name="filter-type"
                        options={pages}
                        onChange={this.handlePageChange}
                        value={
                            pages.find(
                                option => option.value === pagesSelected
                            )
                        }
                    />
                    <span>
                        {rowMin} - {rowMax} dari {total}
                    </span>
                    <div style={{ display: 'flex', float: 'right' }}>
                        <button
                            onClick={e => this.handleFirstPage(e)}
                            className={`first-end left ${(page === 1) ? ' disabled' : ''}`}
                            disabled={(page === 1) ? true : false}
                        >
                            First
                        </button>
                        <button
                            onClick={e => this.handlePrevPage(e)}
                            className={`right${(page === 1) ? ' disabled' : ''}`}
                            disabled={(page === 1) ? true : false}
                        >
                            <img src={prevBtn} alt="" />
                        </button>
                        <div className="jump-to">
                            <input
                                className="form-control"
                                type="number"
                                defaultValue={jumpToPage}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        let setPage = parseInt(e.target.value, 10)

                                        if (!Number.isInteger(setPage) || setPage < 1) {
                                            handleToastServiceError({
                                                message: 'Masukan angka, minimal 1',
                                            })
                                            return;
                                        }

                                        if (setPage >= endPage) {
                                            setPage = endPage
                                        }

                                        this.setState({ jumpToPage: setPage })

                                        if (page === setPage) return

                                        this.handleJumpPage(e, setPage)
                                    }
                                }}
                            />
                        </div>
                        <button
                            onClick={e => this.handleNextPage(e)}
                            className={(page === totalPage) ? "disabled" : ""}
                        >
                            <img src={nextBtn} alt="" />
                        </button>
                        <button
                            onClick={e => this.handleEndPage(e, endPage)}
                            className={`first-end left${(page === totalPage) ? ' disabled' : ''}`}
                        >
                            Last
                        </button>
                    </div>
                </div>
            )
    }

    createDummyBox(){
        let dummy = []
        for(let i = 1; i <= this.state.pageSize; i++){
            dummy.push(
                <div className="box-hospital" key={i}>
                    <div>
                        <div className="empty-logo"></div>
                    </div>
                </div>
            )
        }

        return dummy
    }

    clickHosspitalHandler = (e, obj) => {
        this.setState({ redirectDashboardHos: true })
        this.handleClickHospital(e, obj);
    }

    clickHosspitalInfoHandler = (e, obj) => {
        this.setState({ redirectDashboardHosInfo: true })
        this.handleClickHospital(e, obj);
    }

    showHospital(){
        const hospitals = this.state.hospitals
        const isFetching = this.props.isFetching

        if(isFetching){
            return ( 
                <div className="grid-row wrapper-list-hospital is-fetching">
                    {
                        this.createDummyBox()
                    }
                </div>
            )
        }else{
            return(
                <div className="grid-row wrapper-list-hospital">
                    {
                        hospitals.map((option, index) => {
                            return(
                                <HospitalCard 
                                    key={ index }
                                    handleClickHospital={ this.clickHosspitalHandler }
                                    option={ option }
                                />
                            )
                        })
                    }
                </div>
            )
        }
    }
    
    parseModal(type){
        switch(type){
            case 'upload':
                return(
                    <BulkUpload 
                        {...this.props}
                        hospital={ this.state.selectedHospital }
                        onCancel={this.handleCloseModal}
                    />
                )
            case 'delete':
                return null
            case 'facility_filter': 
                return(
                    <ModalFilterContent 
                        { ...this.props }
                        handleCheckedFilter={ this.handleCheckedFilter }
                        handleUncheckedFilter={ this.handleUncheckedFilter }
                        cityList={ this.props.cityList }
                        facilities={ this.state.facilities }
                        types={ this.state.types }
                        updateSelectedFilter={ this.updateSelectedFilter }
                        handleCancelFilter={ this.handleCancelFilter }
                        handleCloseModal={ this.handleCloseModal }
                        normalfilter={ this.state.filterSet }
                    />
                )
            default:
                return(
                    <div className="no-display">&nbsp;</div>
                )
        }
    }

    verifiedMenu = () => {
        const { history } = this.props

        return (
            <ContextMenu id='menu_id' className='menu-custom-admin' theme={theme.light}>
                <Item
                    onClick={data => {
                        return history.push(`/subscription/faskes?id=${data.dataFromProvider.id}`)
                    }}
                >
                    Assign Paket
                </Item>
                <Item
                    onClick={(data) => this.handleClick('upload', data.dataFromProvider)}
                >
                    Unggah Data
                </Item>
                <Item
                    onClick={(data) => this.handleClick('download', data.dataFromProvider)}
                >
                    Unduh Data
                </Item>
                <Item
                    onClick={(data) => {
                        return this.clickHosspitalInfoHandler(data.event, data.dataFromProvider)
                    }}
                >
                    Edit Data
                </Item>
                <Item
                    onClick={(data) => this.handleClick('delete', data.dataFromProvider)}
                >
                    Hapus
                </Item>
            </ContextMenu>
        )
    }

    unVerifiedMenu = () => {
        return (
            <ContextMenu id='menu_id_need_verify' className='menu-custom-admin' theme={theme.light}>
                <Item
                    onClick={(data) => this.handleClick('verification', data.dataFromProvider)}
                >
                    Verifikasi
                </Item>
                <Item
                    onClick={(data) => this.handleClick('delete', data.dataFromProvider)}
                >
                    Hapus
                </Item>
            </ContextMenu>
        )
    }

    render() {
        const { isModalOpen, ModalData, modalType, hospitals } = this.state
        let GridContainer = null

        if (this.state.redirectDashboardHos) {
            return <Redirect to="/dashboardhospital" />
        }

        if (this.state.redirectDashboardHosInfo) {
            return <Redirect to="/administration/info-hospital" />
        }

        if(!this.props.isFetching && hospitals && hospitals.length === 0){
            GridContainer = () => {
                return(
                    (
                        <div className="admin-row">
                            <div className="no-data">
                                <span>Belum ada data</span>
                            </div>
                        </div>
                    )
                )
            }
        }else{
            GridContainer = () => {
                return(
                    (
                        <div className="grid-container">
                            <div className="grid-row order-paging">
                                <div className="grid-top-left">
                                    { this.showSort() }
                                </div>
                            </div>
                            { this.showHospital() }
                            { this.verifiedMenu() }
                            { this.unVerifiedMenu() }
                            <div className="grid-row footer-row">
                                <div className="grid-top-left"></div>
                                <div className="grid-top-right">
                                    { this.showPagination() }
                                </div>
                            </div>
                        </div>
                    )
                )
            }
        }

        return(
            <div>
                <FilterBar 
                    city={this.props.cityList} 
                    onFilter={this.handleFilter}
                    onAdvancedClick={this.handleAdvancedFilter} 
                    setFilterName={ this.setFilterName }
                    filterSet={ this.state.filterSet }
                />
                <GridContainer />
                <MasterModal
                    className="master-modal modal-filter-dashboard"
                    overlayClassName="master-modal-admin-overlay"
                    isOpen={ isModalOpen }
                    handleSubmit={this.modalHandleSubmit}
                    ModalData={ ModalData }
                    handleClose={this.handleCloseModal}>
                        {this.parseModal(modalType)}
                </MasterModal>
            </div>
            
        )
    }
}
export default connect(loginMapStateToProps, loginMapDispatchToProps)(Grid)