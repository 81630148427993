import React from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'

export default class AdditionalInputForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            counter: 0,
            list: [],
        };
    }

    showModal() {
        this.props.onClick(true, this.props.mode)
    }

    handleEdit = (event, idx, mode) => {
        event.preventDefault()
        this.props.onEdit(idx, mode)
    }

    handleDelete = (event, idx, mode) => {
        event.preventDefault()
        this.props.onDelete(event, idx, mode)
    }

    render() {
        let contents = this.props.content ? this.props.content : []
        return (
            <div>
                <div className="additional-title">{this.props.title}</div>
                <div className="additional-content">
                    {
                        contents.map((data, key) => {
                            let desc = data
                            if (data.constructor.name === 'Object') {
                                let bulanTahunMulai = ''
                                let bulanTahunSelesai = ''

                                if (data.bulan_tahun_mulai) {
                                    let date = data.bulan_tahun_mulai
                                    if (typeof data.bulan_tahun_mulai === 'string') {
                                        const btm = data.bulan_tahun_mulai.split('-')
                                        date = `${btm[1]}-${btm[0]}`
                                    }
                                    bulanTahunMulai = moment(new Date(date)).format('MM-YYYY')
                                }

                                if (data.bulan_tahun_selesai) {
                                    let date = data.bulan_tahun_selesai

                                    if (typeof data.bulan_tahun_selesai === 'string') {
                                        if (data.bulan_tahun_selesai !== 'sekarang') {
                                            const bts = data.bulan_tahun_selesai.split('-')
                                            date = moment(new Date(`${bts[1]}-${bts[0]}`)).format('MM-YYYY')
                                        } else {
                                            date = 'Sekarang'
                                        }
                                    } else {
                                        date = moment(new Date(date)).format('MM-YYYY')
                                    }

                                    bulanTahunSelesai = date
                                }

                                desc = `${data.praktek}${data.kota
                                    ? ` - ${data.kota}` : ''}${data.bulan_tahun_mulai
                                        ? `, ${bulanTahunMulai}${bulanTahunSelesai
                                            ? ` - ${bulanTahunSelesai}` : ''}`
                                        : ''}`

                                if (this.props.mode === 'education') {
                                    desc = `${data.universitas}${data.jurusan
                                        ? ` - ${data.jurusan}` : ''}${data.tahun_lulus
                                            ? `, ${data.tahun_lulus}` : ''}`
                                }
                            }

                            return (
                                <div className="additional-row" key={key}>
                                    <div className="additional-no">{key + 1}</div>
                                    <div className="additional-desc">{desc}</div>
                                    <div className="additional-edit">
                                        <button
                                            type="button"
                                            className="btn btn-grid"
                                            value="value button"
                                            onClick={e => this.handleEdit(e, key, this.props.mode)}
                                        >
                                            <FontAwesomeIcon icon={faPencilAlt} />
                                        </button>
                                    </div>
                                    <div className="additional-delete">
                                        <button
                                            type="button"
                                            className="btn btn-grid"
                                            onClick={e => this.handleDelete(e, key, this.props.mode)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <button
                    type="button"
                    className="btn btn-additional"
                    onClick={this.showModal.bind(this)}
                >
                    <img src={this.props.icon} alt="" />
                    {this.props.label}
                </button>
            </div>
        )
    }
}
