import React, { Component } from 'react'
import Select from 'react-select'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from 'react-reactive-form'

import TextInputForm from '../../../../../core/utils/forminput/TextInputForm'
import TextAreaForm from '../../../../../core/utils/forminput/TextAreaForm'
import RadioInputForm from '../../../../../core/utils/wizard/RadioInputForm'
import ImageUploadForm from '../../../../../core/utils/ImageUploadForm'
import iconPlus from '../../../../../themes/v1/img/rectangle.png'
import Handler from '../../../handler/superadmin/spesialities/FormHandler'

class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            specialistSelected: [],
            specialistFieldTouched: false,
            file: [],
            Form: FormBuilder.group({
                name: ['', Validators.required],
                description: [''],
                specialist_ids: ['', Validators.required],
                status: ['true'],
                is_paid: ['true'],
                icon: [''],
                sort_order: ['99', Validators.required]
            })
        }

        Object.assign(this, TextInputForm)
        this.TextInputFormView = this.TextInputFormView.bind(this)
        Object.assign(this, TextAreaForm)
        this.TextAreaFormView = this.TextAreaFormView.bind(this)
        Object.assign(this, RadioInputForm)
        this.RadioInputFormView = this.RadioInputFormView.bind(this)

        Object.assign(this, Handler)
        this.handleChangeDataSelect = this.handleChangeDataSelect.bind(this)
        this.buildValue = this.buildValue.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleUpload = this.handleUpload.bind(this)

        this.currentHospital = this.props.currentHospital
        this.buildForm()
    }

    componentDidMount() {
        const { data, specialist, getSpecialist } = this.props

        if (specialist.length === 0) {
            getSpecialist()
        }

        if (data.isEdit) {
            this.setState({ specialistSelected: data.specialists })
            this.buildValue({
                ...data,
                specialist_ids: data.specialists,
                status: `${data.status || 'false'}`,
                is_paid: `${data.is_paid || 'false'}`,
            });
        }
    }

    render() {
        const {
            file,
            Form,
            specialistSelected,
            specialistFieldTouched,
        } = this.state
        const { data, specialist } = this.props

        return (
            <FieldGroup control={Form}
                strict={false}
                render={({ invalid }) => {
                    return (
                        <div
                            className="wizard-form custom-form"
                            style={{ display: 'flex', margin: '18px auto 70px' }}
                        >
                            <form onSubmit={this.handleSubmit} autoComplete="off">
                                <ImageUploadForm
                                    onUpload={this.handleUpload}
                                    desc="Tambah Icon"
                                    icon={iconPlus}
                                    maxsize="2500000"
                                    image={file}
                                    source="Icon"
                                    current={Form.controls.icon}
                                />
                                {this.nameField}
                                {this.positionField}
                                {this.descField}
                                <div className="form-line">
                                    <label htmlFor="specialist_ids" className="form-control-label">
                                        Daftar Spesialisasi
                                        <span style={{ marginLeft: '1px', color: 'red' }}>*</span>
                                    </label>
                                    <div className="form-group-wizard subscription">
                                        <Select
                                            className="dropdown-wizard"
                                            classNamePrefix="react-select"
                                            name="specialist_ids"
                                            options={specialist}
                                            placeholder="Pilih Spesialisasi"
                                            onChange={this.handleChangeDataSelect}
                                            value={specialistSelected}
                                            isMulti
                                        />
                                    </div>
                                    {specialistFieldTouched && specialistSelected.length === 0
                                        ? <div className="custom-error control package">
                                            Data Harus Diisi
                                        </div>
                                        : ''}
                                </div>
                                {this.statusField}
                                {this.paidField}
                                <div className="button-action-slider">
                                    <div className="form-btn-action">
                                        <button
                                            type="submit"
                                            className={
                                                `save itgbtn primary ${invalid
                                                    ? 'disabled'
                                                    : ''}`
                                            }
                                            disabled={invalid}
                                            onSubmit={this.handleSubmit}
                                        >
                                            {data && data.isEdit ? 'Update' : 'Simpan'}
                                        </button>
                                        <button
                                            type="button"
                                            className="itgbtn cancel"
                                            onClick={() => this.props.onPanelClose()}
                                        >
                                            Batalkan
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )
                }}
            />
        )
    }
}

export default Form