import React from 'react'
import { FieldControl } from "react-reactive-form"

const SwitchForm = {
    SwitchFormView: function ({ name, meta, control }) {
        const callBack = meta.callBack ? meta.callBack : null

        return <FieldControl
            name={name}
            strict={false}
            meta={{ ...meta }}
            render={(obj) => this.SwitchFormRender(obj, control, callBack)}
            control={control}
        />
    },
    onChangeSwitch: function (e, callBack) {
        if (callBack) {
            callBack(e.target.checked)
        }
    },
    SwitchFormRender: function ({ meta }, control, callBack) {
        const currentVal = control.value ? true : false
        return (
            <div className={`form-line form-switch${control.disabled ? ' disabled' : ''}`}>
                <div className="wrap-switch">
                    <label className="title-switch">{meta.label}</label>
                    <label className={currentVal ? 'checked switch' : 'switch'}>
                        <input type="checkbox"
                            onChange={event => {
                                if (!control.disabled) {
                                    const checked = event.target.checked
                                    control.patchValue(checked)
    
                                    this.onChangeSwitch(event, callBack)
                                }
                            }}
                            checked={currentVal}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>
        )
    },
}

export default SwitchForm
