/* eslint-disable */

import React, { Component } from 'react'
import _ from 'lodash';
import {
    FormBuilder,
    Validators,
    FieldGroup
} from 'react-reactive-form'
import NavigationPrompt from 'react-router-navigation-prompt'
import ClipLoader from 'react-spinners/ClipLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import SectionTitle from './formPartial/SectionTitle'
import SwitchForm from '../../../core/utils/wizard/SwitchForm'
import DropDownAreaForm from '../../../core/utils/wizard/DropDownAreaForm';
import TextInputForm from '../../../core/utils/forminput/TextInputForm'
import TextInputEmailForm from '../../../core/utils/forminput/TextInputEmailForm'
import DropDownForm from '../../../core/utils/forminput/DropDownForm'
import TextAreaForm from '../../../core/utils/forminput/TextAreaForm'
import SmallImageForm from '../../../core/utils/forminput/SmallImageForm'
import RadioInputForm from '../../../core/utils/forminput/RadioInputForm'
import MasterModal from '../../../core/utils/MasterModal'
import SliderPanel from '../../../components/wizard/SliderPanel'
import Handler from '../handler/FormHandler'
import Config from '../config/Config'
import FacilityList from './formPartial/FacilityList'
import ChosenFacility from './formPartial/ChosenFacility'
import CheckBoxForm from '../../../core/utils/forminput/CheckBoxForm'
import SubmitButtonForm from '../../../core/utils/SubmitButtonForm'
import RoleHelper from '../../../core/helper/RoleHelper'
import RichEditorInputForm from '../../../core/utils/RichEditorInputForm';
import DatePickerForm from '../../../core/utils/wizard/DatePickerForm'
import MaskInput from 'react-maskinput';

class Form extends Component{
    constructor(props){
        super(props)
        this.defaultLabel = "Rumah Sakit"
        this.state = {
            institutionLabel: this.defaultLabel,
            submitingForm: false,
            institutionList: props.institutionList,
            dayList: Config.listDay,
            categoryList: Config.CategoryOption,
            timeList: Config.listTime,
            timeListEnd: Config.listTime,
            certificate: null,
            verified: false,
            reactivate_user: false,
            cityList: [],
            clinicType: [],
            hospitalType: [],
            bankOption: [],
            isPaneOpen: false,
            isPaneOpenTwo: false,
            schPractice: false,
            institution: {},
            panelData: {
                headTitle: 'Pengaturan Fasilitas'
            },
            panelDataSch: {
                headTitle: 'Jadwal Praktik'
            },
            panelDataSchTwo: {
                headTitle: 'Buat Jadwal'
            },
            isEdit: false,
            isEditInCreate: false,
            facilitiesOpt: [],
            dayLabel: "",
            day: {},
            selectedDay: '',
            operationalTimeList: Config.listTime,
            dayTemp: [],
            toggleChecked: false,
            operational: {},
            isChangedOperate: false,
            FormTemp: {},
            sio_no_expired:"",
            FormUpdated: {},
            Form : FormBuilder.group({
                id: [""],
                open_days: [[]],
                institution_type: [this.defaultLabel, Validators.required],
                gov_type: ["", Validators.required],
                status: [false, Validators.required], 
                allow_booking: [false, Validators.required],
                is_custom_schedule: [true, Validators.required],
                id_type: [""], 
                name: ["", Validators.required],
                propinsi_id: [0],
                propinsi: [""],
                kota_id: [0],
                sio_no:[""],
                sio_no_expired:[""],
                kota: [""],
                kecamatan_id: [0],
                kecamatan: [""],
                kelurahan_id: [0],
                kelurahan: [""], 
                code: ["", Validators.required],
                logo: [""],
                clinic_type: [""],
                hospital_type: [""],
                photo: [""],
                description: [""],
                phone: ["", Validators.required],
                open_time: [""],
                close_time: [""],
                facilities: [[]],
                street: [""],
                lat: [""],
                lng: [""],
                pic_name: [""],
                pic_email: [""],
                pic_phone: [""],
                igd: ["1"],
                npwp: [""],
                npwp_name: [""],
                rekening_name: [""],
                rekening_bank: [""],
                rekening_no:[""],
            }),
            propinsiOpt: [],
            kotaOpt: [],
            kecamatanOpt: [],
            kelurahanOpt: [],
        }

        //assign this to input component
        Object.assign(this, TextInputForm)
        this.TextInputFormView = this.TextInputFormView.bind(this)
        Object.assign(this, DropDownForm)
        this.DropDownView = this.DropDownView.bind(this)
        Object.assign(this, TextAreaForm)
        this.TextAreaFormView = this.TextAreaFormView.bind(this)
        Object.assign(this, TextInputEmailForm)
        this.TextInputEmailFormView = this.TextInputEmailFormView.bind(this)
        Object.assign(this, RadioInputForm)
        this.RadioInputFormView = this.RadioInputFormView.bind(this)
        Object.assign(this, CheckBoxForm)
        this.CheckBoxFormView = this.CheckBoxFormView.bind(this)
        Object.assign(this, SwitchForm)
        this.SwitchFormView = this.SwitchFormView.bind(this)
        Object.assign(this, RichEditorInputForm)
        this.RichEditorInputFormView = this.RichEditorInputFormView.bind(this)
        Object.assign(this, DropDownAreaForm)
        this.DropDownAreaFormView = this.DropDownAreaFormView.bind(this)
        Object.assign(this, DatePickerForm)
        this.DatePickerFormView = this.DatePickerFormView.bind(this)
        
        //assign this to handler
        Object.assign(this, Handler)
        this.handleInstitutionChange = this.handleInstitutionChange.bind(this)
        this.fetchFacilities = this.fetchFacilities.bind(this)
        this.onPanelOpen = this.onPanelOpen.bind(this)
        this.onPanelClose = this.onPanelClose.bind(this)
        this.onPanelOpenTwo = this.onPanelOpenTwo.bind(this)
        this.onPanelCloseTwo = this.onPanelCloseTwo.bind(this)
        this.displayFacility = this.displayFacility.bind(this)
        this.preloadHospital = this.preloadHospital.bind(this)
        this.buildOptions = this.buildOptions.bind(this)
        this.downloadCert = this.downloadCert.bind(this)
        this.verifySPv = this.verifySPv.bind(this)
        this.loadBank = this.loadBank.bind(this)
        
        this.currentHospital = props.currentHospital
        this.picEmail = null
        this.isSuperadmin = RoleHelper.isSuperadmin(props.dataLogin)
        this.isAdminSpv = RoleHelper.isAdminSPV(props.dataLogin, this.isOwner(props.currentHospital))
        
        //build form obj
        this.buildForm()
    }

    componentWillReceiveProps(nextProps) {
        const { currentHospital } = this.props

        if (currentHospital !== nextProps.currentHospital) {
            this.currentHospital = nextProps.currentHospital
            this.preloadHospital()
        }
    }

    isOwner = (newCurrentHosp) => {
        const {
            dataLogin: {
                hospitals
            }
        } = this.props

        const hospitalSelected = hospitals.find(option => {
            return String(option.id) === String(newCurrentHosp)
        })

        return !_.isEmpty(hospitalSelected) ? hospitalSelected.owner : false
    }

    displayFacility(facilityKeys){
        const fetchedFacility = this.state.facilitiesOpt
        let displayData = []
        if(fetchedFacility){
            fetchedFacility.map((data) => {
                if(facilityKeys.indexOf(data.value) !== -1){
                    displayData.push(data)
                }

                return false
            })
        }

        return displayData
    }

    addScheduleSlide = () => {
        this.onPanelOpenTwo()
        this.setState({
            panelDataSchTwo: {
                headTitle: 'Buat Jadwal'
            },
            isEdit: false,
            isEditInCreate: false,
        })
    }

    addScheduleHandle = () => {
        const { operational, dayTemp, selectedDay } = this.state
        operational[selectedDay] = dayTemp

        this.setState({
            operational,
            isChangedOperate: true,
        })

        this.onPanelCloseTwoHandle()
    }

    onPanelCloseTwoHandle = () => {
        this.setState({ selectedDay: '', dayTemp: [] })
        this.onPanelCloseTwo();
    }

    addSession = () => {
        const { dayTemp } = this.state
        const setDayTemp = _.concat(dayTemp, [{ end: '', start: '' }]);

        this.setState({ dayTemp: setDayTemp });
    }

    deleteSession = (index) => {
        const { dayTemp } = this.state
        dayTemp.splice(index, 1);

        this.setState({ dayTemp });
    }

    deleteSchedule = (selectedDay) => {
        const { operational, toggleChecked } = this.state
        const isDelWeekday = _.includes(['mon', 'tue', 'wed', 'thu', 'fri'], selectedDay);
        operational[selectedDay] = []

        let setToggle = toggleChecked;
        if (isDelWeekday) {
            setToggle = false
        }
        
        this.setState({
            operational,
            toggleChecked: setToggle
        })
    }

    editSchedule = (day, data) => {
        this.onPanelOpenTwo()

        setTimeout(() => {
            this.setState({
                selectedDay: day,
                dayTemp: data,
                panelDataSchTwo: {
                    headTitle: 'Ubah Jadwal'
                },
                isEdit: true,
                isEditInCreate: false
            })
        }, 100)
    }

    startHandleChange = (selectedOption, data, type) => {
        const { dayTemp } = this.state

        const index = _.findIndex(dayTemp, { end: data.end, start: data.start })

        if (type === 'start') {
            dayTemp.splice(index, 1, { end: '', start: selectedOption.label })
        } else {
            dayTemp.splice(index, 1, { end: selectedOption.label, start: data.start })
        }

        this.setState({ dayTemp })
    };

    getArrEndTime = (selectedTimeStart) => {
        let EndTimeList = []
        const { operationalTimeList } = this.state

        if (!_.isEmpty(selectedTimeStart)) {
            const indexFromConfig = operationalTimeList.find(option => {
                return option.label === selectedTimeStart
            }).value

            operationalTimeList.map((value, index) => {
                if (index > indexFromConfig) {
                    EndTimeList.push(value)
                }

                return EndTimeList
            })

        }

        return EndTimeList;
    }

    toggleHandle = (e) => {
        let sch = []
        const { operational } = this.state
        const ischecked = e.target.checked

        Object.keys(operational).forEach(key => {
            if (!_.isEmpty(operational[key]) && (key !== 'sat' && key !== 'sun')) {
                sch = operational[key]
            }
        })

        if (ischecked) {
            operational.mon = sch
            operational.tue = sch
            operational.wed = sch
            operational.thu = sch
            operational.fri = sch
        } else {
            operational.mon = sch
            operational.tue = []
            operational.wed = []
            operational.thu = []
            operational.fri = []
        }
        
        this.setState({
            operational,
            isChangedOperate: true,
            toggleChecked: ischecked
        })
    }

    countScheduleList = (excludeWeekend) => {
        let count = 0
        const { operational } = this.state

        Object.keys(operational).forEach(key => {
            if (!_.isEmpty(operational[key]) && !excludeWeekend) {
                count++
            } else if (!_.isEmpty(operational[key]) && (key !== 'sat' && key !== 'sun') && excludeWeekend) {
                count++
            }
        })

        return count
    }

    getScheduleList = () => {
        let content = []
        const { operational, dayList, toggleChecked } = this.state
        const schWeekdayCount = this.countScheduleList(true);

        Object.keys(operational).forEach(key => {
            const dayLabel = key === 'toggle' ? '' : dayList.find(option => option.value === key).label
            const hours = operational[key].map(k => {
                return `${k.start}-${k.end}`
            }).join(', ')

            if (key === 'toggle' && (schWeekdayCount === 1 || schWeekdayCount === 5)) {
                content.push(
                    <section className="content-slide list-schedule toggle" key={key}>
                        <div className="form-line form-switch">
                            <div className="wrap-switch">
                                <label className="title-switch">Samakan Jadwal untuk Hari Senin - Jumat</label>
                                <label className={`switch ${toggleChecked ? 'checked' : ''}`}>
                                    <input type="checkbox"
                                        onChange={this.toggleHandle}
                                        checked={toggleChecked}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </section>
                )
            } else if (key !== 'toggle') {
                content.push(
                    <section className="content-slide list-schedule" key={key}>
                        <div className="box-form">
                            <label className="form-control-label">{dayLabel}</label>
                            <small>{hours || 'Tidak ada jadwal'}</small>
                            <div className="wrapper-btn-action">
                                <a
                                    className="btn-action"
                                    onClick={() => this.editSchedule(key, operational[key])}>
                                    <FontAwesomeIcon size="sm" icon={faPencilAlt} />
                                </a>
                                {/* {this.countScheduleList() > 1 ? (
                                    <a
                                        className="btn-action"
                                        onClick={() => this.deleteSchedule(key)}>
                                        <FontAwesomeIcon size="sm" icon={faTrash} />
                                    </a>
                                ) : null} */}
                            </div>
                        </div>
                    </section>
                )
            }
        })

        return content;
    }

    getScheduleListInForm = () => {
        let content = []
        const { operational, dayList } = this.state

        Object.keys(operational).forEach(key => {
            if (!_.isEmpty(operational[key]) || key === 'toggle') {
                const dayLabel = key === 'toggle' ? '' : dayList.find(option => option.value === key).label
                const hours = operational[key].map(k => {
                    return `${k.start}-${k.end}`
                }).join(', ')

                if (key !== 'toggle') {
                    content.push(
                        <div className="schedule-list-form" key={key}>
                            <label className="form-control-label">{dayLabel}</label>
                            <small>{hours}</small>
                        </div>
                    )
                }

            }
        })

        return content;
    }

    handleDayChange = (daySelected) => {
        let dayTemp = [];
        const { operational } = this.state

        _.find(operational, (item, day) => {
            if (day === daySelected.value) {
                dayTemp = item
            }
        });

        this.setState({
            dayLabel: daySelected.label,
            day: daySelected,
            selectedDay: daySelected.value,
            dayTemp,
            panelDataSchTwo: {
                headTitle: dayTemp.length > 0 ? 'Ubah Jadwal' : 'Buat Jadwal'
            },
            isEdit: false,
            isEditInCreate: dayTemp.length > 0
        })
    }

    timeOverlaps = (time) => {
        for (var i = 0; i < time.length; i++) {
            for (var j = i + 1; j < time.length; j++) {
                if ((time[i].start < time[j].end) && (time[j].start < time[i].end)) {
                    return true;
                }
            }
        }
        return false;
    }

    checkEmptyStartOrEnd = () => {
        const { dayTemp } = this.state

        return _.some(dayTemp, data => {
            return !data.start.length || !data.end.length
        })
    }

    layerList = () => {
        let content = []

        this.state.schPractice ? (
            content = (
                <div id="slider-sch">
                    {this.getScheduleList()}
                    {this.getScheduleList().length === 0 ? (
                        <div className="add-schedule-slide">
                            <span>Tidak Ada Jadwal</span>
                        </div>
                    ) : null}
                    {this.getScheduleList().length < 7 ? (
                        <div className="text-center">
                            <button
                                className={`
                                    add-schedule-btn
                                    ${this.getScheduleList().length > 0
                                        ? 'outline'
                                        : ''}
                                `}
                                onClick={() => this.addScheduleSlide()}>
                                <FontAwesomeIcon size="sm" icon={faPlus} />
                                <span>Jadwal</span>
                            </button>
                        </div>
                    ) : null}
                </div>
            )
        ) : (
            content = (
                <FacilityList
                    {...this.props}
                    onPanelClose={this.onPanelClose}
                    control={this.state.Form.controls.facilities}
                    input={this.facilityPick}
                    facilitiesOpt={this.state.facilitiesOpt}
                />
            )
        )

        return content
    }

    sessionSection = () => {
        let content = []
        const { selectedDay, dayTemp, operationalTimeList } = this.state

        if (selectedDay !== "") {
            content = (<section className="content-slide">
                {dayTemp.map((data, i) => (
                    <div className="box-form" key={i}>
                        <label className="form-control-label">
                            {`Sesi ${i + 1}`}
                        </label>
                        <a
                            className="btn-delete"
                            onClick={() => this.deleteSession(i)}>
                            <FontAwesomeIcon size="sm" icon={faTrash} />
                        </a>
                        <hr />
                        <div className="form-line timerange-input">
                            <span className="textLabel">Waktu</span>
                            <div className="wrap-timerange">
                                <div className="form-line">
                                    <div className="form-group-wizard">
                                        <Select
                                            className="dropdown-wizard"
                                            classNamePrefix="react-select"
                                            name="start"
                                            placeholder="Mulai"
                                            value={operationalTimeList
                                                ? operationalTimeList.find(option => {
                                                    return option.label === data.start
                                                })
                                                : ""}
                                            options={operationalTimeList}
                                            onChange={(e) => {
                                                this.startHandleChange(e, data, 'start')
                                            }}
                                        />
                                    </div>
                                </div>
                                <span className="separator"></span>
                                <div className="form-line">
                                    <div className="form-group-wizard">
                                        <Select
                                            className="dropdown-wizard"
                                            classNamePrefix="react-select"
                                            name="end"
                                            placeholder="Selesai"
                                            value={!_.isEmpty(data.end) ?
                                                operationalTimeList ?
                                                    operationalTimeList.find(option => {
                                                        return option.label === data.end
                                                    })
                                                    : ""
                                                : ""
                                            }
                                            options={this.getArrEndTime(data.start)}
                                            onChange={(e) => this.startHandleChange(e, data, 'end')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

                {this.timeOverlaps(dayTemp) ? (
                    <p className="alert-time-overlap">Jam mulai tidak boleh tumpang tindih dengan sesi lain</p>
                ) : null}

                {dayTemp.length < 3 && !_.isEmpty(selectedDay) && !this.checkEmptyStartOrEnd() && !this.timeOverlaps(dayTemp) ? (
                    <button className="add-schedule add-button" onClick={() => this.addSession()}>
                        <FontAwesomeIcon size="sm" icon={faPlus} />
                        <span>Tambah Sesi</span>
                    </button>
                ) : null}
            </section>)
        }

        return content
    }

    componentDidMount(){
        this.preloadHospital()
        this.loadBank()
    }

    isChangedForm = () => {
        const {
            FormTemp,
            FormUpdated,
        } = this.state

        delete FormTemp.clinic_type
        delete FormTemp.hospital_type
        delete FormTemp.igd
        delete FormTemp.jam_besuk
        delete FormTemp.dob
        const newData = Object.assign({}, FormUpdated)
        newData.institution_type = (typeof newData.institution_type === 'object')
            ? newData.institution_type.label
            : newData.institution_type
        newData.gov_type = (typeof newData.gov_type === 'object')
            ? newData.gov_type.label
            : newData.gov_type
        
        delete newData.clinic_type
        delete newData.hospital_type
        delete newData.igd

        return !_.isEqual(FormTemp, newData)
    }

    convertObj = (obj) => {
        return Object.keys(obj).map((key) => {
            return {
                day: key,
                operate: obj[key],
            };
        })
    }

    handleNpwpChange = (e) => {
        const { Form } = this.state
        const npwpNo = (e.target.value).replace(/[^0-9.]/g, '')
        Form.patchValue({ npwp: npwpNo })
    }

    render(){
        const {
            Form,
            institutionLabel,
            dayList,
            selectedDay,
            isEdit,
            isEditInCreate,
            dayTemp,
            certificate,
            verified,
            reactivate_user,
            isChangedOperate,
        } = this.state

        const pickedFacility = this.state.Form.controls.facilities.value
        const isHospital = institutionLabel === this.defaultLabel ? true : false

        const labelPractice = 'Jadwal Praktik'
        const operationalDesc = isHospital
            ? 'Untuk Rumah Sakit : Jadwal Praktik/Tutup'
            : 'Untuk klinik : Jadwal Praktik/Tutup'
        
        const facility = this.displayFacility(pickedFacility)        

        return(
            <div className={`wrap-sections wizard-form ${this.props.isSticky ? 'sticky' : ''}`}>
                <NavigationPrompt when={this.isChangedForm() || isChangedOperate}>
                    {({ onConfirm }) => (
                        <MasterModal
                            className="master-modal"
                            isOpen={true}
                            handleClose={onConfirm}
                            handleSubmit={() => {
                                this.handleSubmit();
                                onConfirm();
                            }}
                            ModalData={
                                {
                                    content: 'Apakah Anda ingin menyimpan\nperubahan ini?',
                                    buttonConfirmText: 'Simpan',
                                    buttonCancelText: 'Cancel',
                                }
                            }
                        />
                    )}
                </NavigationPrompt>
                <FieldGroup control={ Form }
                    strict={ false }
                    render={({ invalid, value }) => {
                        const isVerifyForm = this.props.verifyClinicId
                        const submitLabel = this.props.submitLabel
                        const npwpNo = value.npwp
                                ? (value.npwp).replace(/[^0-9.]/g, '')
                                : ""
                        this.setState((prevState) => {
                            if (prevState.FormUpdated !== Form.value) {
                                return {
                                    FormUpdated: Form.value
                                }
                            }
                        })

                        return(
                            <form onSubmit={() => this.handleSubmit}>
                                <section id="general">
                                    <div className="box-form">
                                        <SectionTitle
                                            title="Informasi Umum"
                                            subtitle="Logo, Nama Jenis, dan Foto RS/Klink"/>
                                        <div className="custom-wrapper">
                                            <label>Kode { institutionLabel }</label>
                                            { this.textInstitutionCode }
                                        </div>
                                        {/* <div className="custom-wrapper">
                                            {this.textSIO}
                                        </div>
                                        <div className="custom-wrapper">
                                            {this.sioNoExpiredField}
                                        </div> */}
                                        <div className="custom-wrapper">
                                            <label>Jenis Institusi Kesehatan</label>
                                            { this.DropDownInstitutionType }
                                        </div>
                                        <div className="custom-wrapper">
                                            <label>Nama { institutionLabel }</label>
                                            { this.textInstitutionName }
                                        </div>
                                        <div className="custom-wrapper">
                                            <label>Tipe Institusi Kesehatan</label>
                                            { this.DropDownInstitutionCategory }
                                        </div>
                                        <div className="custom-wrapper">
                                            <label>Jenis { institutionLabel }</label>
                                            { 
                                                (institutionLabel === 'Rumah Sakit') ? 
                                                    this.DropDownHospitalType : 
                                                    this.DropDownClinicType 
                                            }
                                        </div>
                                        <div className="toggle-wrapper toggle-wrapper-hospital">
                                            {this.SwitchStatus}
                                        </div>
                                        <div className={
                                            Form.value.status
                                                ? 'toggle-wrapper toggle-wrapper-hospital'
                                                : 'toggle-wrapper toggle-wrapper-hospital hide'
                                        }>
                                            {this.SwitchAllowBooking}
                                        </div>
                                        <div className="toggle-wrapper toggle-wrapper-hospital">
                                            {this.SwitchAllowSelSch}
                                        </div>
                                        {(!isHospital
                                            && !verified
                                            && certificate !== null
                                            && this.isAdminSpv) ? (
                                                <div className="custom-wrapper">
                                                    <label>Sertifikat</label>
                                                    <a
                                                        className="cert-wrapper"
                                                        onClick={() => this.downloadCert(certificate)}
                                                    >
                                                        <img src={certificate} alt={certificate} />
                                                    </a>
                                                </div>
                                            ) : null}
                                        <div className="custom-wrapper">
                                            <label>Logo { institutionLabel }</label>
                                            <SmallImageForm
                                                className="wrap-logo"
                                                name="logo"
                                                smallNote="Rekomendasi ukuran 70 x 70 px"
                                                control={ this.state.Form.controls.logo }
                                            />
                                        </div>
                                        <div className="custom-wrapper">
                                            <label>Foto { institutionLabel }</label>
                                            <SmallImageForm
                                                className="wrap-image"
                                                name="photo"
                                                smallNote={
                                                    `Anda dapat menggunggah foto depan ${institutionLabel}
                                                    atau situasi di dalam ${institutionLabel}.`
                                                }
                                                control={ this.state.Form.controls.photo }
                                            />
                                            {/* smallNote={
                                                    `Rekomendasi ukuran 600 x 700px dengan ukuran file maksimal 2 MB.
                                                    Anda dapat menggunggah foto depan ${institutionLabel}
                                                    atau situasi di dalam ${institutionLabel}.`
                                                } */}
                                        </div>
                                        { this.TextDesc }
                                        
                                        {/* <div className="form-line">
                                            <label className="form-control-label">
                                                NPWP <span style={{color:'red'}}> *</span>
                                            </label>
                                            <div className="form-group">
                                            <MaskInput
                                                alwaysShowMask={false}
                                                className="form-control"
                                                mask='00.000.000.0-000.000'
                                                value={npwpNo}
                                                placeholder="Nomor NPWP"
                                                onChange={this.handleNpwpChange}
                                            />
                                            </div>
                                        </div>
                                        {this.npwpNameField}
                                        <div className="form-line">
                                            <label className="form-control-label">
                                                Bank <span style={{color:'red'}}> *</span>
                                            </label>
                                        {this.bankField}
                                        </div>
                                        {this.noRekField}
                                        {this.rekeningNameField} */}
                                    </div>
                                </section>
                                <section id="contact">
                                    <div className="box-form">
                                        <SectionTitle title="Kontak" subtitle="Telepon, Alamat untuk dihubungi"/>
                                        { this.textPhone }
                                        { this.TextStreet }
                                        { this.DropDownPropinsi }
                                        { this.DropDownKota }
                                        { this.DropDownKecamatan }
                                         {this.DropDownKelurahan }
                                        { this.textLat }
                                        { this.textLng }
                                    </div>
                                </section>
                                <section id="operational">
                                    <div className="box-form">
                                        <SectionTitle
                                            title="Operasional"
                                            subtitle={ operationalDesc }/>
                                        <div className="form-line">
                                            <label className="form-control-label">
                                                { labelPractice }
                                            </label>
                                            {this.countScheduleList() === 0 ? (
                                                <a
                                                    onClick={() => this.onPanelOpen('sch-practice')}
                                                    className="add-shcedule">
                                                    <FontAwesomeIcon size="sm" icon={faPlus} />
                                                    <span>Tambah Jadwal Praktik</span>
                                                </a>
                                            ) : (
                                                <section className="content-slide list-schedule" style={{ margin: '0' }}>
                                                    <div className="box-form" style={{ marginTop: '10px' }}>
                                                        {this.getScheduleListInForm()}
                                                    </div>
                                                    <a
                                                        onClick={() => this.onPanelOpen('sch-practice')}
                                                        className="edit-shcedule">
                                                        <FontAwesomeIcon size="sm" icon={faPencilAlt} />
                                                        <span>Atur Jadwal Praktik</span>
                                                    </a>
                                                </section>
                                            )}
                                        </div>
                                    </div>
                                </section>
                                <section id="facility">
                                    <div className="box-form">
                                        <SectionTitle
                                            title="Fasilitas"
                                            subtitle="Pilih fasilitas yang tersedia untuk pasien"/>
                                        {
                                            facility.length <= 0 ? 
                                            <div className="facility-slide">
                                                <span>
                                                    Anda belum mengisi fasilitas yang tersedia
                                                </span>
                                                <button
                                                    type="button"
                                                    onClick={ () => this.onPanelOpen('facility') }>
                                                    Isi Fasilitas
                                                </button>
                                            </div> :
                                            <div>
                                                <ChosenFacility facility={ facility }/>
                                                <button 
                                                    type="button"
                                                    className="change-facility regular-button invert"
                                                    onClick={ () => this.onPanelOpen('facility') }
                                                >
                                                    Ubah
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </section>
                                <section id="pic">
                                    <div className="box-form pic-container">
                                        <SectionTitle
                                            title="Penanggung Jawab"
                                            subtitle="Admin yang bertanggung jawab"/>
                                        <div className="custom-wrapper">
                                            <label>
                                                Nama Penanggung Jawab
                                            </label>
                                            { this.PicName }
                                        </div>
                                        <div className="custom-wrapper">
                                            <label>
                                                Email Penanggung Jawab
                                                </label>
                                            {this.PicEmail}
                                        </div>
                                        { this.PicPhone }
                                    </div>
                                </section>
                                <div className={ isVerifyForm
                                    ? 'form-hospital verify-form'
                                    : 'form-hospital'}>
                                    {
                                        !this.state.submitingForm ? 
                                        <SubmitButtonForm 
                                            showCancel={ isVerifyForm ? true : false } 
                                            submitText={ submitLabel ? submitLabel : 'Simpan' } 
                                            invalid={ invalid } 
                                            onSubmit={ (e) => this.handleSubmit(e) } 
                                            onCancel={
                                                isVerifyForm && this.props.onCancel
                                                    ? this.props.onCancel
                                                    : null
                                            }
                                        /> 
                                        : 
                                        <button style={{ backgroundColor: '#5983f0', width:'130px', borderRadius:40}}>
                                            <span style={{margin:'10px'}}>Loading <ClipLoader color="#fff" size={15} style={{display:'flex', float:'right'}}/></span> 
                                        </button>
                                    }
                                </div>
                            </form>
                        )
                    }}
                />
                <SliderPanel
                    {...this.props}
                    open={this.state.isPaneOpen}
                    onPanelClose={this.onPanelClose}
                    panelData={
                        this.state.schPractice ? this.state.panelDataSch : this.state.panelData
                    }>
                    {this.layerList()}
                </SliderPanel>
                <SliderPanel
                    {...this.props}
                    open={this.state.isPaneOpenTwo}
                    onPanelClose={this.onPanelCloseTwoHandle}
                    onHandleBack={this.onPanelCloseTwoHandle}
                    panelData={this.state.panelDataSchTwo}
                    customOverlay="slider-cinnamon-overlay-two">
                    <div className="custom-wrapper" style={{ marginTop: 0 }}>
                        <label>Hari Buka</label>
                        <div
                            className="form-group-wizard thin"
                            style={isEdit && !isEditInCreate ? { cursor: 'not-allowed' } : {}}>
                            <Select
                                className="dropdown-wizard"
                                classNamePrefix="react-select"
                                name="operation_days"
                                placeholder="Pilih Hari"
                                value={dayList
                                    ? dayList.find(option => option.value === selectedDay)
                                    : ""
                                }
                                options={dayList}
                                onChange={this.handleDayChange}
                                isDisabled={isEdit && !isEditInCreate}
                            />
                        </div>
                    </div>
                    {this.sessionSection()}
                    <div className="wrap-action-button">
                        <button
                            type="button"
                            className="cancel"
                            onClick={this.onPanelCloseTwoHandle} >
                            Batal
                        </button>
                        <button
                            type="submit"
                            className={`
                                add-schedule-btn ${(this.checkEmptyStartOrEnd() || this.timeOverlaps(dayTemp)) ? "disabled" : ""}
                            `}
                            onClick={() => this.addScheduleHandle()}
                            disabled={(this.checkEmptyStartOrEnd() || this.timeOverlaps(dayTemp))}
                        >
                            {isEdit || isEditInCreate ? 'Ubah' : 'Simpan'}
                        </button>
                    </div>
                </SliderPanel>
            </div>
        )
    }
}

export default Form
