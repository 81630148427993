const Config = {
    GridUrl: '/appointment/list',
    AddUrl: '/appointment',
    filterStatus: [
        { label: "Semua", value: '' },
        { label: "Belum Dikonfirmasi", value: 'unconfirmed' },
        { label: "Sudah Dikonfirmasi", value: 'confirmed' },
        { label: "Dibatalkan", value: 'canceled' }
    ]
}

export default Config