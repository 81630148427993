import React, { Component } from 'react'
import Icons from '../../core/utils/Icons'
import SuccessActivation from './component/info/SuccessActivation'
import HasActivated from './component/info/HasActivated'
import CannotActivated from './component/info/CannotActivated'
import { Button, Container, Form, FormFeedback, FormGroup, Input } from 'reactstrap'
import { ToastContainer } from 'react-toastify'
import { handleToastServiceError } from '../../core/utils/UtilsToast'
import { connect } from 'react-redux'
import { loginMapDispatchToProps, loginMapStateToProps } from '../../core/const/LoginConst'
import { Redirect } from 'react-router-dom'
import Api from '../../services/apis/LoginApi'

class Activation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newPassword: '',
            newPasswordConfirm: '',
            successResetPass: false,
            isLoading: false,
            isButtonEnable: false,
            redirectTo: null,
            verificationCode: props.match.params.verification,
            validate: {
                newPassword: '',
                newPasswordConfirm: ''
            },
            alreadyActivated: false,
            notAbleToActivated: false,
            checkingAccount: true
        }

        this.handleChangeNewPassword = this.handleChangeNewPassword.bind(this)
        this.handleChangeConfirmPassword = this.handleChangeConfirmPassword.bind(this)
        this.handleBlurNewPassword = this.handleBlurNewPassword.bind(this)
        this.handleBlurConfirmPassword = this.handleBlurConfirmPassword.bind(this)
        this.submitForm = this.submitForm.bind(this)
    }

    submitForm(e){
        e.preventDefault()
        this.setState({
            isLoading: true
        })

        const jsonPost = {
            type: 'activation',
            token: this.state.verificationCode,
            password: this.state.newPassword
        }
       
        Api.resetpass(JSON.stringify(jsonPost)).then(result => {
            if(result.data.code === 200){
                this.props.onRequestLogout()
                this.setState({
                    isLoading: false,
                    successResetPass: true
                })
            }else{
                handleToastServiceError({
                    message: 'Kode Verifikasi tidak terdaftar'
                })
                this.setState({
                    isLoading: false,
                })
            }
        }).catch(error => {
            handleToastServiceError(error)
            this.setState({
                isLoading: false,
                notAbleToActivated: true
            })
        })
    }

    handleChangeNewPassword(e){
        this.setState({
            newPassword: e.target.value,
            validate: {
                ...this.state.validate,
                newPassword: e.target.value && e.target.value.length < 6 ? 'has-danger' : 'has-success'
            }
        })
    }

    handleChangeConfirmPassword(e){
        const newPassword = this.state.newPassword
        this.setState({
            newPasswordConfirm: e.target.value,
            validate: {
                ...this.state.validate,
                newPasswordConfirm: newPassword === e.target.value ? 'has-success' : 'has-danger'
            },
            isButtonEnable: newPassword.length >= 6 && newPassword === e.target.value ? true : false
        })
    }

    handleBlurNewPassword(){
        if(this.state.newPassword.length < 6){
            this.setState({
                validate: {
                    ...this.state.validate,
                    newPassword: 'has-danger'
                }
            })
        }else{
            this.setState({
                validate: {
                    ...this.state.validate,
                    newPassword: 'has-success'
                }
            })
        }
    }

    handleBlurConfirmPassword(){
        if(this.state.newPasswordConfirm.length < 6 || this.state.newPasswordConfirm !== this.state.newPassword){
            this.setState({
                validate: {
                    ...this.state.validate,
                    newPasswordConfirm: 'has-danger'
                }
            })
        }else{
            this.setState({
                isButtonEnable: true,
                validate: {
                    ...this.state.validate,
                    newPasswordConfirm: 'has-success'
                }
            })
        }
    }

    componentDidMount(){
        const jsonPost = {
            token: this.props.match.params.verification
        }
        Api.check(JSON.stringify(jsonPost)).then(response => {
            const data = response.data.data
            if(response.data.code === 200){
                this.setState({
                    notAbleToActivated: false,
                    checkingAccount: false,
                    alreadyActivated: data.active
                })
            }else{
                this.setState({
                    notAbleToActivated: true,
                    checkingAccount: false
                })
            }
        }).catch(err => {
            this.setState({
                notAbleToActivated: true,
                checkingAccount: false
            })
        })
    }

    render() {
        const { checkingAccount, alreadyActivated, notAbleToActivated, validate, successResetPass, isButtonEnable, redirectTo } = this.state
        const isDisabled = !isButtonEnable

        if(redirectTo){
            return(<Redirect to={ redirectTo } />)
        }

        if(checkingAccount === true){
            return(
                <div className="login-page-cinnamon">
                    <div className="loading-text">
                        Validasi Akun...
                    </div>
                </div>
            )
        }

        if(notAbleToActivated){
            return(<CannotActivated { ...this.props }/>)    
        }

        if(alreadyActivated){
            return(<HasActivated { ...this.props }/>)    
        }

        if(successResetPass){
            return(<SuccessActivation { ...this.props }/>)
        }
        return(
            <div className="login-page-cinnamon">
                <ToastContainer
                    position="top-center"
                    autoClose={ 5000 }
                    hideProgressBar={ false }
                    newestOnTop={ false }
                    closeOnClick
                    rtl={ false }
                    pauseOnVisibilityChange
                />
                <Container className="d-flex align-items-center">
                    <div className="form-login-cinnamon">
                        <div className="row">
                            <div className="wrapper-logo">
                                <img className="login-logo" src={ Icons.logoLogin } alt="Login" />
                            </div>
                            <div className="wrap-login-center wrap-forgotpass">
                                <div className="login-note">
                                    <h3>Verifikasi Email</h3>
                                    <p>
                                    Hanya satu langkah untuk verifikasi email anda. Silahkan isi kata sandi yang akan anda gunakan
                                    </p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="content">
                                        <Form className="login-form" onSubmit={(e) => this.submitForm(e)}>
                                            <FormGroup>
                                                <label className="login-label">Kata Sandi Baru</label>
                                                <div className="form-group-login">
                                                    <Input
                                                        type="password"
                                                        name="password"
                                                        placeholder="Isi dengan kata sandi baru anda"
                                                        className="input-material"
                                                        autoFocus
                                                        valid={ validate.newPassword === 'has-success' }
                                                        invalid={ validate.newPassword === 'has-danger'}
                                                        onChange={(e) => {
                                                            this.handleChangeNewPassword(e)
                                                        }}
                                                        onBlur={ this.handleBlurNewPassword }
                                                    />
                                                    <FormFeedback>
                                                        Masukkan kata sandi baru anda minimal 6 karakter
                                                    </FormFeedback>
                                                </div>
                                            </FormGroup>
                                            <FormGroup>
                                                <label className="login-label">Ketik Ulang Kata Sandi Baru</label>
                                                <div className="form-group-login">
                                                    <Input
                                                        type="password"
                                                        name="confirm_password"
                                                        placeholder="Ketik ulang kata sandi baru anda"
                                                        className="input-material"
                                                        valid={ validate.newPasswordConfirm === 'has-success' }
                                                        invalid={ validate.newPasswordConfirm === 'has-danger'}
                                                        onChange={(e) => {
                                                            this.handleChangeConfirmPassword(e)
                                                        }}
                                                        onBlur={ this.handleBlurConfirmPassword }
                                                    />
                                                    <FormFeedback>
                                                        Kata sandi tidak sama
                                                    </FormFeedback>
                                                </div>
                                            </FormGroup>
                                            {
                                                !this.state.isLoading ? 
                                                <div className="wrap-button-auth"> 
                                                    <Button
                                                        type="submit"
                                                        className="btn btn-primary login-button"
                                                        disabled={ isDisabled }
                                                    >Lanjut</Button>
                                                </div> : 
                                                <div className="loading">Loading...</div>
                                            }
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Activation)