const axiosSecure = (token) => ({
    baseURL: process.env.REACT_APP_API_HOST,
    validateStatus: function (status) {
        return status < 500 // pass all http code status and handling by self
    },
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        // 'X-API-Auth': process.env.REACT_APP_SECRET_API,
        'Authorization': "Bearer " + token
    },
})

export default axiosSecure