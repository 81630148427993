import React from 'react'
import Select from 'react-select'
import SpecialistList from './panel/schedule/SpecialistList'
import iconStet from '../../../themes/v1/img/layout/icon/stethoscope.png'
import Api from '../../../services/apis/Appointment/Main'
import dateFns from "date-fns"
import IndonesianLocale from 'date-fns/locale/id'

class SpecialistPanel extends React.Component {
    constructor(props) {
        super(props)
        this.defaultSelected = {
            value: 0,
            label: "Semua Spesialisasi"
        }
        this.state = {
            isLoading: true,
            listSpecialist: [],
            SpecialistOpt: [this.defaultSelected],
            SpecialistSelected: this.defaultSelected
        }
        this.filterSpecialist = this.filterSpecialist.bind(this)
    }

    filterSpecialist(selectedOption) {
        this.setState({
            SpecialistSelected: selectedOption
        })
    }

    componentDidMount() {
        const { SpecialistOpt } = this.state
        const { hospitalId, doctorId, selectedDate } = this.props
        const dateFormat = 'YYYY-MM-DD'
        const dateString = dateFns.format(selectedDate, dateFormat, { locale: IndonesianLocale })

        Api.getPoly(hospitalId, doctorId, dateString).then(res => {
            const { schedule, specialist } = res.data.data

            if (schedule) {
                this.setState({
                    isLoading: false,
                    listSpecialist: schedule,
                    SpecialistOpt: SpecialistOpt.concat(specialist ? specialist : [])
                })
            }
        }).catch(() => {
            this.setState({ isLoading: false })
        })
    }

    render() {
        const { doctorId } = this.props

        return (
            <div className="panel-result">
                {doctorId === '' && (
                    <div className="wrap-filter">
                        <img className="icon-filter" src={iconStet} alt="Filter Spesialisasi" />
                        <Select
                            className="filter-poly"
                            classNamePrefix="poly"
                            placeholder={this.defaultSelected.label}
                            options={this.state.SpecialistOpt}
                            value={this.state.SpecialistSelected}
                            onChange={this.filterSpecialist}
                        />
                    </div>
                )}
                <SpecialistList
                    {...this.props}
                    isLoading={this.state.isLoading}
                    isSingle={doctorId === ''}
                    listSpecialist={this.state.listSpecialist}
                    SpecialistSelected={this.state.SpecialistSelected}
                />
            </div>
        )
    }
}

export default SpecialistPanel
