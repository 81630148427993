import React, { Component } from 'react'
import MasterTemplate from '../../../template/MasterTemplate'
import FormComponent from './components/Form'

export default class Form extends Component {
    constructor(props) {
        super(props)
        this.detail = true

        let state = this.props.store.getState()
        const path = window.location.hash
        this.state = {
            token: state.loginReducer.token,
            path: path
        }
    }

    render() {
        const { match } = this.props
        const breadcrumbs = [
            {
                label: 'Dashboard',
                path: '/dashboardhospital',
            },
            {
                label: 'Pengaturan',
                path: '/administration/info-hospital',
            },
            {
                label: 'Pengaturan SPG',
                path: '/administration/spg',
            },
            {
                label: match.path.search('/edit/') > -1 ? 'Edit SPG' : 'Tambah SPG',
                path: null,
            },
        ]

        return (
            <MasterTemplate
                {...this.props}
                breadcrumbs={breadcrumbs}
                detail={this.detail}
            >
                <FormComponent {...this.props} />
            </MasterTemplate>
        )
    }
}
