import React, { Component } from 'react'
import ScheduleList from './ScheduleList'
import ScheduleDetail from './ScheduleDetail'

export default class SlideContent extends Component{
    render() {
        const chosenPatient = this.props.chosenPatient
        return (
            <div className="slider-form">
                {
                    !chosenPatient ? 
                    <ScheduleList 
                        { ...this.props }
                        selectedScheduleId={ this.props.selectedScheduleId }
                        selectedDate={ this.props.selectedDate }
                        viewPatient={ this.props.viewPatient }
                    /> : 
                    <ScheduleDetail 
                        { ...this.props }
                        chosenPatient={ chosenPatient }
                    />
                }
            </div>
        )
    }
}