import React, { Component } from 'react'
import Select from 'react-select'
import DatePicker from 'react-date-picker'
import dateFns from 'date-fns'

export default class CustomFilter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: '',
        }
    }

    componentWillReceiveProps(nextProps) {
        const { reset } = nextProps

        if (reset) {
            this.setState({ value: '' })
        }
    }

    maskInputSTR = (str) => {
        const chuncks = str
            .replace(/-/g, '')
            .replace(/\D/g, '') // only digit
            .match(/.{1,4}/g)

        if (chuncks) {
            return chuncks.join('-')
        }

        return ''
    }

    setVal = (id, value) => {
        let setValue = value
        const { setStateFilter } = this.props


        if (id === 'str') {
            setValue = this.maskInputSTR(setValue)
        }

        this.setState({ value: setValue })
        setStateFilter(id, value)
    }

    setValDate = (value) => {
        const {
            column: {
                id,
            },
            submitFilter,
        } = this.props

        this.setState({ value })

        setTimeout(() => {
            if (value) {
                submitFilter(id, dateFns.format(value, 'YYYY-MM-DD'))
            } else {
                submitFilter(id, '')
            }
        }, 0)
    }

    setValSelect = (value) => {
        const {
            column: {
                id,
            },
            submitFilter,
        } = this.props

        this.setState({ value })

        setTimeout(() => {
            submitFilter(id, value.value)
        }, 0);
    }

    clearFilter = () => {
        const {
            column: {
                id,
            },
            filter,
            submitFilter,
        } = this.props

        this.setState({ value: '' })

        if (filter && filter.value) {
            submitFilter(id, '')
        }
    }

    clearIcon = () => {
        return <i className="fas fa-times" onClick={() => this.clearFilter()} />
    }

    resetFilterHandle = () => {
        const { resetFilter } = this.props
        resetFilter()
    }

    render() {
        const { value } = this.state
        const {
            data,
            column: {
                id,
                Header,
                filterType,
                filterOptions,
            },
            submitFilter,
        } = this.props

        if (filterType === 'reset') {
            return (
                <span className="custom-filter-reset" onClick={this.resetFilterHandle}>
                    Reset
                </span>
            )
        }

        if (filterType === 'date') {
            return (
                <span className="custom-filter wizard-datepicker">
                    <DatePicker
                        disabled={data.length <= 8}
                        disableCalendar={data.length <= 8}
                        calendarIcon={value ? null : <i className="fas fa-calendar-alt" />}
                        clearIcon={null}
                        placeholder={'Pilih Tanggal'}
                        onChange={val => this.setValDate(val)}
                        prev2Label={null}
                        next2Label={null}
                        locale="id-id"
                        autocomplete="off"
                        value={value}
                        isOpen={false}
                        maxDetail="month"
                        minDetail="year"
                    />
                    {value ? this.clearIcon() : null}
                </span>
            )
        }

        if (filterType === 'select') {
            return (
                <span className="custom-filter">
                    <Select
                        isDisabled={data.length <= 8}
                        classNamePrefix="react-select"
                        className={value ? 'has-value' : ''}
                        placeholder={Header ? `Cari ${Header.toLowerCase()}` : ''}
                        onChange={e => this.setValSelect(e)}
                        options={filterOptions}
                        value={value}
                    />
                    {value ? this.clearIcon() : null}
                </span>
            )
        }

        if (filterType === 'str') {
            return (
                <span className="custom-filter">
                    <input
                        type='text'
                        value={value}
                        placeholder={Header ? `Cari ${Header.toLowerCase()}` : ''}
                        onChange={e => this.setVal(id, e.target.value)}
                        maxLength={19}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                submitFilter(id, e.target.value)
                            }
                        }}
                    />
                    {value ? this.clearIcon() : null}
                </span>
            )
        }

        return (
            <span className="custom-filter">
                <input
                    type={filterType || 'text'}
                    value={value}
                    placeholder={Header ? `Cari ${Header.toLowerCase()}` : ''}
                    onChange={e => this.setVal(id, e.target.value)}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            submitFilter(id, e.target.value)
                        }
                    }}
                />
                {value
                    ? this.clearIcon()
                    : <i className="fas fa-search" />}
            </span>
        )
    }
}

