import React from 'react'
import Datetime from 'react-datetime'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/id'

import Checkbox from '../../../dashboard/components/Checkbox'

class ModalContent extends React.Component{
    renderFieldsByType = (type) => {
        const {
            handleInputChange,
            onCheck,
            onRemove,
            universitas,
            jurusan,
            tahunLulus,
            praktek,
            kota,
            bulanTahunMulai,
            bulanTahunSelesai,
            expPresent,
            validationEnd,
            medicalActions
        } = this.props

        let dateMulai = bulanTahunMulai
        let dateSelesai = bulanTahunSelesai

        if (typeof bulanTahunMulai === 'string') {
            const btm = bulanTahunMulai.split('-')
            dateMulai = `${btm[1]}-${btm[0]}`
        }
        if (typeof bulanTahunSelesai === 'string') {
            const bts = bulanTahunSelesai.split('-')
            dateSelesai = `${bts[1]}-${bts[0]}`
        }

        if(type === 'education'){
            return(
                <div>
                    <input
                        value={universitas || ''}
                        onChange={e => handleInputChange('universitas', e.target.value)}
                        placeholder="Nama Universitas"
                        className="native-input" />
                    <input
                        value={jurusan || ''}
                        onChange={e => handleInputChange('jurusan', e.target.value)}
                        placeholder="Nama Jurusan"
                        className="native-input" />
                    <input
                        type="number"
                        value={tahunLulus || ''}
                        onChange={e => handleInputChange('tahun_lulus', e.target.value)}
                        placeholder="Tahun Lulus"
                        className="native-input" />
                </div>
            )
        }else if(type === 'experience'){
            return(
                <div>
                    <input
                        value={praktek || ''}
                        onChange={e => handleInputChange('praktek', e.target.value)}
                        placeholder="Nama Tempat Praktik"
                        className="native-input" />
                    <input
                        value={kota || ''}
                        onChange={e => handleInputChange('kota', e.target.value)}
                        placeholder="Kota"
                        className="native-input" />
                    <Datetime
                        closeOnSelect
                        timeFormat={false}
                        dateFormat="MMMM YYYY"
                        initialViewMode="years"
                        className="month-year"
                        value={bulanTahunMulai
                            ? moment(new Date(dateMulai))
                            : ''}
                        isValidDate={currentDate => currentDate.isBefore(moment(new Date()))}
                        inputProps={{
                            placeholder: "Tahun Mulai",
                            onKeyDown: (e) => e.preventDefault(),
                            className: '',
                        }}
                        onChange={(d) => handleInputChange('bulan_tahun_mulai', d.format('MM-YYYY'))}
                    />
                    {!expPresent && (
                        <Datetime
                            closeOnSelect
                            timeFormat={false}
                            dateFormat="MMMM YYYY"
                            initialViewMode="years"
                            className="month-year"
                            value={bulanTahunSelesai
                                ? moment(new Date(dateSelesai))
                                : ''}
                            isValidDate={e => validationEnd(e, bulanTahunMulai)}
                            inputProps={{
                                placeholder: "Tahun Selesai",
                                onKeyDown: (e) => e.preventDefault(),
                                className: '',
                            }}
                            onChange={(d) => handleInputChange('bulan_tahun_selesai', d.format('MM-YYYY'))}
                        />
                    )}
                    <Checkbox
                        options={[{
                            value: true,
                            label: 'Masih bekerja disini',
                        }]}
                        onCheck={onCheck}
                        onRemove={onRemove}
                        items={[expPresent]}
                        class="wrap-checkbox-left checkbox-with-checklist exp"
                    />
                </div>
            )
        }else if(type === 'medical_actions') {
            return(
                <div>
                    <input
                        value={medicalActions || ''}
                        onChange={e => handleInputChange('medicalActionsTmpValue', e.target.value)}
                        placeholder="Nama Tindakan"
                        className="native-input" />
                </div>
            )
        } else {
            return null;
        }
    }
    render() {
        const {
            handleSubmitAdditional,
            handleCloseModal,
            ModalActionSelected,
            universitas,
            praktek,
        } = this.props

        // title by mode
        let modalTitle = 'Tambah Pengalaman'
        let disableBtn = false
        if(ModalActionSelected === 'education'){
            modalTitle = 'Tambah Pendidikan'
            disableBtn = _.isEmpty(universitas && universitas.replace(/ /g, ''))
        } else if(ModalActionSelected === 'experience') {
            disableBtn = _.isEmpty(praktek && praktek.replace(/ /g, ''))
        } else if(ModalActionSelected === 'medical_actions') {
            modalTitle = 'Tambah Tindakan Medis'
            disableBtn = false
        }

        return(
            <div className="master-modal-content custom edu-exp">
                <div className="master-modal-body" style={{ margin: '0px' }}>
                    <div className="content">
                        <h3>
                            { modalTitle }
                        </h3>
                        {this.renderFieldsByType(ModalActionSelected)}
                    </div>
                </div>
                <div className="master-modal-foot">
                    <button
                        className={`master-btn confirm ${disableBtn ? 'disabled' : ''}`}
                        disabled={disableBtn}
                        onClick={() => handleSubmitAdditional(ModalActionSelected)}
                    >
                        Simpan
                    </button>
                    <button
                        className="master-btn cancel"
                        onClick={handleCloseModal}
                    >
                        Batal
                    </button>
                </div>
            </div>
        )
    }
}

export default ModalContent
