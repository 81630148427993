import React from 'react'
import NumberFormat from "react-number-format"

export const formatCurrency = number => {
    return(<NumberFormat displayType='text' thousandSeparator={'.'} decimalSeparator={','} value={number} prefix={'Rp '}/>)
}

export const getRawValueFromCurrency = stringText => {
    const targetString = `${stringText}`
    const finalResult =  parseInt(targetString.replaceAll(' ', '').replaceAll('Rp', '').replaceAll('.', '').trim());
    return finalResult;
}