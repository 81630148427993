import React from 'react'
import dateFns from "date-fns"
import IndonesianLocale from 'date-fns/locale/id'

import ProfilePic from '../../../components/lib/ProfilePic'

class AppointmentSlide extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            doctorImgNotFound: false,
            openModal: false,
        }
        this.handleErrorImg = this.handleErrorImg.bind(this)
    }

    componentDidMount() {
        const { openedAppointment, loadNotes } = this.props
         loadNotes()
        if (openedAppointment.status_code === 'unconfirmed') {
            loadNotes()
        }
    }

    handleCancel(data) {
        const ModalData = {
            action: "cancel_booking",
            id: data.id,
            content: "Apakah anda yakin ingin membatalkan appointment pasien ? <br />Pasien akan terhapus dari daftar booking",
            buttonConfirmText: "Ya, Batalkan",
            buttonCancelText: "Tidak, jangan dibatalkan",
            ConfirmFunction: null,
            dataAction: data.action
        }
        this.props.handleOpenModal(ModalData)
    }
    handleRefund(data) {
        const ModalData = {
            action: "refund",
            id: data.id,
            content: data,
            buttonConfirmText: "Kembalikan Dana",
            buttonCancelText: "Tutup",
            ConfirmFunction: null
        }
        this.props.handleOpenModal(ModalData)
    }

    handleErrorImg() {
        this.setState({
            doctorImgNotFound: true
        })
    }

    parseTime = (row) => {
        let estimateTime = '';
        const tempDate = new Date();
        const dateFormat = 'HH:mm'

        if (row.arrival_time) {
            const arrivalTime = `${row.arrival_time.slice(0, -3)}`
            const hours = `${arrivalTime.slice(0, -3)}`
            const minutes = `${arrivalTime.slice(3)}`

            estimateTime = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), hours, minutes, 0);
            estimateTime.setMinutes(estimateTime.getMinutes() + row.estimation_time)
            estimateTime = `${arrivalTime} - ${dateFns.format(estimateTime, dateFormat, { locale: IndonesianLocale })} WIB`
        }

        return (
            <div>
                {estimateTime}
            </div>)
    }

    render() {
        const { doctorImgNotFound } = this.state;
        const { openedAppointment } = this.props
        const data = openedAppointment

        if (!data) {
            return (<span>No data found</span>)
        }

        const BookNo = data.book_no < 10 ? '0' + data.book_no : data.book_no
        const isHariH = data.book_date === dateFns.format(new Date(), 'YYYY-MM-DD');
        const activity = data.history || []

        return (
            <div className="slide-appointment">
                <div className="row-content">
                    <h3>Pasien</h3>
                    <div className="row-info">
                        <div className="col-info">
                            <span className="title">Nama Pasien</span>
                            <span className="content">{data.patient_name}</span>
                        </div>
                        <div className="col-info">
                            <span className="title">Telepon Pasien</span>
                            <span className="content">{data.telephone || '-'}</span>
                        </div>
                        <div className="col-info">
                            <span className="title">Resume Medis Pasien</span>
                            <span className="content">
                                {data.medical_record_no ? data.medical_record_no : '-'}
                            </span>
                        </div>
                        <div className="col-info">
                            <span className="title">Estimasi Kedatangan</span>
                            <span className="content content-status">
                                {this.parseTime(data)}
                                {data.status_code === 'unconfirmed' ? (
                                    <span className="notice">
                                        Harap mengonfirmasi kedatangan Anda<br />
                                        15 menit sebelum penanganan
                                    </span>
                                ) : null}
                            </span>
                        </div>
                        
                        <div className="col-info">
                            <span className="title">No. Antrian</span>
                            <span className="content">{BookNo}</span>
                        </div>
                        <div className="col-info">
                            <span className="title">Kode Booking</span>
                            <span className="content">{data.ref_no}</span>
                        </div>
                        <div className="col-info">
                            <span className="title">Jenis Booking</span>
                            <span className="content">
                                {data.book_type_label || '-'}
                            </span>
                        </div>
                        <div className="col-info">
                            <span className="title">KTP</span>
                            <span className="content">
                                {
                                    data.patient_identity_photo ? 
                                    <a href={data.patient_identity_photo} target='blank'>
                                        <img src={data.patient_identity_photo} alt='scan ID' className='preview-ktp'/>
                                    </a> :
                                    '-'
                                }
                            </span>
                        </div>
                    </div>
                    <div className="row-info">
                        <div className="col-info">
                            <span className="title">Info Pembayaran</span>
                            <span className="content">
                                {data.payment_method || '-'}
                            </span>
                        </div>
                        <div className="col-info">
                            <span className="title">Status</span>
                            <span className="content content-status">
                                <div className={data.status_code}>{data.status_label}</div>
                                {data.cancel_reason || ''}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row-content">
                    <h3>Dokter / Layanan</h3>
                    <div className="row-info">
                        <div className="col-info">
                            <div className={!doctorImgNotFound ? 'photo' : 'photo no-img'} >
                                <ProfilePic
                                    className="doctor-img"
                                    source={data.doctor_photo} alt="Dokter"
                                    onError={this.handleErrorImg} />
                            </div>
                            <div className="col-info-child">
                                <span className="title">Nama</span>
                                <span className="content">{data.name || '-'}</span>
                            </div>
                        </div>
                        <div className="col-info">
                            <span className="title">Jadwal</span>
                            <span className="content">{this.props.parseDate(data)}</span>
                        </div>
                    </div>
                </div>
                <div
                    className="row-content"
                    style={data.status_code !== 'unconfirmed' ? {} : { marginBottom: 50 }}
                >
                    <h3>Aktivitas</h3>
                    <div className="row-info" style={{ paddingBottom: 40 }}>
                        {activity.length > 0
                            ? activity.map((item, i) => (
                                <div className="act-list" key={i}>{item.text}</div>
                            ))
                            : <div className="act-empty">Tidak ada aktivitas</div>}
                    </div>
                </div>
                {
                    
                    data.video_call === true ?
                    null
                    : 
                    data.action.label === 'Selesai' ? 
                    <div className="button-action-slider-refund appointment">
                                <div className="form-btn-action">
                                    <button
                                        type="button"
                                        // className={`save itgbtn primary ${!isHariH ? 'disabled' : ''}`}
                                        className={`save itgbtn primary`}
                                        disabled={!isHariH}
                                        onClick={() => this.props.modalHandleConfirm(data)}>
                                        {data.action.label}
                                    </button>
                                </div>
                            </div>
                    :
                    data.action.length !== 0?
                        <div className="button-action-slider appointment">
                                <div className="form-btn-action">
                                    <button
                                        type="button"
                                        // className={`save itgbtn primary ${!isHariH ? 'disabled' : ''}`}
                                        className={`save itgbtn primary`}
                                        disabled={false}
                                        onClick={() => this.props.modalHandleConfirm(data)}>
                                        {data.action.label}
                                    </button>
                                    <button
                                        type="button"
                                        className="cancel itgbtn"
                                        onClick={() => this.handleCancel(data)}>
                                        Batalkan
                                    </button>
                                </div>
                            </div>
                    :
                    null
                }
                {
                    data.status_code === "cancel by doctor"  || data.status_code === "cancel by operational" ?
                    data.action.length !== 0?
                            <div className="button-action-slider-refund appointment">
                                <div className="form-btn-action" style={{ display: 'flex', alignItems: 'center'}}>
                                    <button
                                        type="button"
                                        className="cancel itgbtn"
                                        onClick={() => this.handleRefund(data)}>
                                        {data.action.label}
                                    </button> 
                                </div>
                            </div>
                            : null
                    : data.status_code === "cancel by thirdparty" ?
                    <div className="button-action-slider-refund appointment">
                        <div className="form-btn-action" style={{ display: 'none', alignItems: 'center'}}>
                            <button
                                type="button"
                                className="cancel itgbtn"
                                onClick={() => this.handleRefund(data)}>
                                {data.action.label}
                            </button> 
                        </div>
                    </div>
                    :null
                }
            </div>
        )
    }
}

export default AppointmentSlide
