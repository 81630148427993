import React from 'react'
import Select from 'react-select'

/** ----------------- Start --------------------- **/
class SearchFilter extends React.Component {
    constructor(props){
        super(props)
        this.elementData = this.props.element
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.state = {
            keyword: this.elementData.filterKeyword ? this.elementData.filterKeyword : "",
        }
    }

    handleChangeInput(e){
        this.setState({
            keyword: e.target.value
        })
    }

    handleSubmit(e){
        e.preventDefault()
        const elementData = this.elementData
        const keyword = this.state.keyword
        elementData.handlerSubmit(keyword)
    }

    renderSelector(elementData){
        if(this.props.hideField === true)
            return

        return (
            <div className="search-byfilter-action">
                <Select
                    classNamePrefix="react-select"
                    name="filter-key"
                    onChange={elementData.handlerChangeOpt}
                    options={elementData.filterOpt}
                    value={elementData.selectedOpt}
                />
            </div>
        )
    }

    render() {
        const elementData = this.elementData
        const className = (this.props.hideField) ? "search-keyword" : "search-byfilter-keyword"
        return (
            <div className="wrap-search-filter">
                <form onSubmit={this.handleSubmit}>
                    {this.renderSelector(elementData)}        
                    <input 
                        name="filter-input-text"
                        className={className}
                        onChange={this.handleChangeInput}
                        placeholder={elementData.title}
                        value={ this.state.keyword }
                        type="text" 
                    />
                    <button className="button-search-byfilter"><i className="fas fa-search"></i></button>
                </form>
            </div>
        )
    }
}
    
export default SearchFilter