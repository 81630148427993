import SpecialistApi from '../../../../../services/apis/SpecialitiesAPI'
import CategoryListApi from '../../../../../services/apis/CategoryListApi'
import ApiAppointment from '../../../../../services/apis/DaftarAppointmentApi'
import {handleToastServiceError} from '../../../../../core/utils/UtilsToast'

const FormHandler = {
    buildForm() {
        this.DropDownStatus = this.DropDownFormView({
            meta: {
                label: "Status",
                placeholder: "Pilih Status",
            },
            name: "status",
            nameText: "status",
            control: this.state.Form.controls.status,
            stateName: 'statusData',
        })
        
        this.DropDownTransactionType = this.DropDownFormView({
            meta: {
                label: "Transaction Type",
                placeholder: "Pilih Transaction Type",
            },
            name: "Transaction Type",
            nameText: "Transaction Type",
            control: this.state.Form.controls.transactionType,
            stateName: 'transactionTypeData',
        })

        this.DropDownFaskes = this.DropDownFormView({
            meta: {
                label: "Faskes",
                placeholder: "Pilih Faskes",
            },
            name: "faskes",
            nameText: "faskes",
            control: this.state.Form.controls.faskes,
            stateName: 'faskes',
        })
        
        this.DropDownSpecialist = this.DropDownFormView({
            meta: {
                label: "Specialist",
                placeholder: "Pilih Specialist",
            },
            name: "specialist",
            nameText: "specialist",
            control: this.state.Form.controls.specialist,
            stateName: 'specialistOpt',
        })
    },
    buildValue(data) {
        this.state.Form.patchValue(data)
    },
    handleUpload(file) {
        this.setState({ file })
        this.state.Form.patchValue({ photo: file })
    },
    handleChangeDataSelect(data) {
        const { Form } = this.state

        Form.patchValue({ specialist_ids: data })
        this.setState({
            specialistSelected: data,
            specialistFieldTouched: true,
        })
    },
    handleDateRangePicker(data){
        console.log(data)
    },
    DateRange(data) {
        console.log(data)
    },
    handleSubmit: async function (e) {
        e.preventDefault();
        this.setState({ loading: true })
        const {startDate, endDate, dataFaskes, dataSpeciality, dataCategoryService, dataKategoriTanggal } = this.state
        const formValue = Object.assign({}, this.state.Form.value)
        const status = formValue.status.value === undefined ?  "": formValue.status.value 
        const transactionType =  formValue.transactionType.value === undefined ? "" : formValue.transactionType.value
        const specialisasi = dataSpeciality.length === 0 ? "" : dataSpeciality.value
        const faskes = dataFaskes.length === 0 ? "" : dataFaskes.value
        const category = dataCategoryService.length === 0 ? "" : dataCategoryService.value
        const kategoriTanggal = dataKategoriTanggal.length === 0 ? "created_date" : dataKategoriTanggal.value

        if(startDate.length === 0){
           alert("Silahkan isi Tanggal")
       }else{
        
        try {
            ApiAppointment.download(JSON.stringify({
                date:{type: kategoriTanggal, start: startDate, end: endDate},
                speciality: [specialisasi],
                medical_facilities: [faskes],
                category_service: [category],
                status_order: [status],
                transaction_type: [transactionType]
            })).then((response) =>
            {
                if(response){
                    this.setState({ loading: false })
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    const blob = new Blob([response.data], {type: "octet/stream"}),
                    url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = `Appointment-${startDate}-${endDate}.csv`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    // window.location = '/'
                }else{
                    this.setState({ loading: false })
                    handleToastServiceError({message: "Gagal Download Csv"})
                    alert("Gagal Download Csv")
                    // window.location = '/'
                }
            });
        } catch (response) {
            this.setState({ loading: false })
            handleToastServiceError(response)
            // window.location = '/'
        }
    }
    },
    getCategoryList(){
        let me = this
        if(!this.props.previewMode){
            let requestApi =  CategoryListApi.parentService()
            requestApi.then(response => {
                me.setState({
                    categoryListOpt: response.data.data.rows.map(el => {
                        return {
                            value: el.id,
                            label: el.name
                        }
                    }),
                })
            }).catch( error => {
                console.log(error)
            })
        }
    },
    getFilter(){
        let me = this
        if(!this.props.previewMode){
            let requestApi =  ApiAppointment.getFilterExport()
            requestApi.then(response => {
                let range = response.data.data.date.options.map(ranges => {return { value: ranges.value, label:ranges.label}})
                let status = response.data.data.order_status.options.map(s => {return { value: s.value, label:s.label}})
                let faskes = response.data.data.faskes.options.map(f => {return { value: f.value, label:f.label}})
                let speciality = response.data.data.speciality.options.map(sp => {return { value: sp.value, label:sp.label}})
                let categoryService = response.data.data.category_service.options.map(cs => {return { value: cs.value, label:cs.label}})
                let transactionType = response.data.data.transaction_type.options.map(t => {return { value: t.value, label:t.label}})
                me.setState({
                    kategoriTanggal: range,
                    statusData: status,
                    faskes:faskes,
                    transactionTypeData: transactionType,
                    faskesAll: response.data.data.faskes,
                    faskesPath: response.data.data.faskes.path,
                    speciality:speciality,
                    specialityAll: response.data.data.speciality,
                    specialityPath: response.data.data.speciality.path,
                    categoryService:categoryService,
                    categoryServiceAll: response.data.data.category_service,
                    categoryServicePath: response.data.data.category_service.path,
                })
            }).catch( error => {
                console.log(error)
            })
        }
    },
    getSpecialistList(){
        let me = this
        if(!this.props.previewMode){
            let requestApi =  SpecialistApi.specialist()
            requestApi.then(response => {
                me.setState({
                    specialistOpt: response.data.data.rows.map(el => {
                        return {
                            value: el.value,
                            label: el.label
                        }
                    }),
                })
            }).catch( error => {
                console.log(error)
            })
        }
    },
}

export default FormHandler