import { axiosBase, axiosSuperadmin } from './MasterConfig'

const axios = axiosBase()
const axiosS = axiosSuperadmin()

const groupListsUrl = 'lists'
const groupMgmtUrl = 'doctortitle'

export default class FacilityApi {
    static get = () => axios.get(`/${groupListsUrl}/gelar`)
    static list = (page, pageSize, search) => { // for management
        let setSearch = ''

        if (search) {
            setSearch = `&search=${search}`
        }

        return axiosS.get(`/${groupMgmtUrl}?page=${page}&limit=${pageSize}${setSearch}`)
    }
    static post = (data) => axiosS.post(`/${groupMgmtUrl}`, data)
    static put = (id, data) => axiosS.put(`/${groupMgmtUrl}/${id}`, data)
    static delete = (id) => axiosS.delete(`/${groupMgmtUrl}/${id}`)
}
