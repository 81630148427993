import React, { Component } from 'react'
import dateFns from 'date-fns'
import IndonesianLocale from 'date-fns/locale/id'

class DataPrescriptionDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { prescription_detail: prescriptionDetail } = this.props
        const prescName = prescriptionDetail ? prescriptionDetail.prescription_name : '-'
        const prescItems = prescriptionDetail ? prescriptionDetail.prescription_items : []
        const prescDate = prescriptionDetail
            ? dateFns.format(
                prescriptionDetail.prescription_date,
                'DD MMMM YYYY',
                { locale: IndonesianLocale }
            )
            : '-'
        const patientName = prescriptionDetail
            ? `${prescriptionDetail.patient_firstname}${prescriptionDetail.patient_lastname
                ? ` ${prescriptionDetail.patient_lastname}`
                : ''}`
            : '-'
        const doctorName = prescriptionDetail
            ? `${prescriptionDetail.doctor_title
                ? `${prescriptionDetail.doctor_title} `
                : ''}${prescriptionDetail.doctor_name}`
            : '-'
        return (
            <div className="emr-data-presc-detail">
                <div className="fields border-bottom">
                    <div className="field-group">
                        <div className="field">
                            <div className="label">Nama Resep</div>
                            <div className="value">{prescName}</div>
                        </div>
                        <div className="field">
                            <div className="label">Tanggal Peresepan</div>
                            <div className="value">{prescDate}</div>
                        </div>
                    </div>
                    <div className="field-group">
                        <div className="field">
                            <div className="label">Nama Pasien</div>
                            <div className="value">{patientName}</div>
                        </div>
                        <div className="field">
                            <div className="label">Nama Dokter</div>
                            <div className="value">{doctorName}</div>
                        </div>
                    </div>
                </div>
                {prescItems.map(item => (
                    <div key={item.id} className="prescription">
                        <div className="name">{item.product_name}</div>
                        <div className="pt">{item.principal_name}</div>
                        <div className={`unit${item.aturan_pakai === ''
                            ? ' norecipe' : ''}`}>{`${item.qty} ${item.uom}`}</div>
                        <div className={`rule${item.aturan_pakai === ''
                            ? ' hidden' : ''}`}>{item.aturan_pakai}</div>
                    </div>
                ))}
            </div>
        )
    }
}

export default DataPrescriptionDetail
