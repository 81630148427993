import { axiosBase } from './MasterConfig'

const axios = axiosBase()

class HospitalApi {
    static getHospital = (keyword) => {
        let search = ''

        if (keyword) {
            search = `?search=${keyword}`
        }

        return axios.get(`/lists/hospital${search}`)
    }
}

export default HospitalApi;