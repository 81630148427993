import ApiFaskes from '../../../../../services/apis/ListFaskes'
import SpecialistApi from '../../../../../services/apis/SpecialitiesAPI'
import HospitalApi from '../../../../../services/apis/HospitalApi'
import CategoryListApi from '../../../../../services/apis/CategoryListApi'
import {
    handleToastServiceError
} from '../../../../../core/utils/UtilsToast'

const FormHandler = {
    buildForm() {
        this.DropDownKategoriDate = this.DropDownFormView({
            meta: {
                label: "Kategori Tanggal",
                placeholder: "Pilih Kategori",
            },
            name: "kategori_date",
            nameText: "kategori_date",
            control: this.state.Form.controls.kategori_date,
            stateName: 'dateRange',
        })
        this.DropDownShowInApps = this.DropDownFormView({
            meta: {
                label: "Show Apps",
                placeholder: "Pilih Show Apps",
            },
            name: "show_in_apps",
            nameText: "show_in_apps",
            control: this.state.Form.controls.show_in_apps,
            stateName: 'showApps',
        })
        this.DropDownAllowBooking = this.DropDownFormView({
            meta: {
                label: "Allow Booking",
                placeholder: "Pilih Allow Booking",
            },
            name: "allow_booking",
            nameText: "allow_booking",
            control: this.state.Form.controls.allow_booking,
            stateName: 'allowBooking',
        })
    },
    buildValue(data) {
        this.state.Form.patchValue(data)
    },
    handleUpload(file) {
        this.setState({ file })
        this.state.Form.patchValue({ photo: file })
    },
    handleDateRangePicker(data){
        console.log(data)
    },
    DateRange(data) {
        console.log(data)
    },
    handleSubmit: async function (e) {
        e.preventDefault();
        this.setState({ loading: true })
        const {startDate, endDate, dataIT, dataCity, dataHospital } = this.state
        const formValue = Object.assign({}, this.state.Form.value)
        const show_in_apps = formValue.show_in_apps
        const allow_booking = formValue.allow_booking
         if(startDate.length === 0){
            alert("Silahkan isi Tanggal")
        }else{
        try {
            ApiFaskes.download(JSON.stringify({
                date:{type: "create_date", start: startDate, end: endDate},
                hospital_id: dataHospital[0].label  === "Semua Nama FasKes" ? [] : dataHospital.map((val)=> val.value),
                institution_type: dataIT[0].label === "Semua Jenis Institusi" ? [] : dataIT.map((val)=> val.value),
                show_in_apps: show_in_apps.value,
                allow_booking: allow_booking.value,
                city: dataCity.value,
            })).then((response) =>
            {
                if(response){
                    this.setState({ loading: false })
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    const blob = new Blob([response.data], {type: "octet/stream"}),
                    url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = `Faskes-${startDate}-${endDate}.csv`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    // window.location = '/'
                }else{
                    this.setState({ loading: false })
                    handleToastServiceError({message: "Gagal Download Csv"})
                    alert("Gagal Download Csv")
                    // window.location = '/'
                }
            });
        } catch (response) {
            this.setState({ loading: false })
            handleToastServiceError(response)
            // window.location = '/'
        }
    }
    },
    getCategoryList(){
        let me = this
        if(!this.props.previewMode){
            let requestApi =  CategoryListApi.parentService()
            requestApi.then(response => {
                me.setState({
                    categoryListOpt: response.data.data.rows.map(el => {
                        return {
                            value: el.id,
                            label: el.name
                        }
                    }),
                })
            }).catch( error => {
                console.log(error)
            })
        }
    },
    getFilter(){
        let me = this
        if(!this.props.previewMode){
            let requestApi =  ApiFaskes.getFilterExport()
            requestApi.then(response => {
                let range = response.data.data.date.options.map(el => {
                    return {
                        value: el.value,
                        label: el.label
                    }
                })
                let faskes = response.data.data.hospital_id.options.map(el => {
                    return {
                        value: el.value,
                        label: el.label
                    }
                })
                let city = response.data.data.city.options.map(el => {
                    return {
                        value: el.value,
                        label: el.label
                    }
                })
                let show_in_apps = response.data.data.show_in_apps.options.map(el => {
                    return {
                        value: el.value,
                        label: el.label
                    }
                })
                let institution_type = response.data.data.institution_type.options.map(el => {
                    return {
                        value: el.value,
                        label: el.label
                    }
                })
                let allow_booking = response.data.data.allow_booking.options.map(el => {
                    return {
                        value: el.value,
                        label: el.label
                    }
                })
                me.setState({
                    dateRange: range,
                    faskes: faskes,
                    hospitalList: faskes,
                    city: city,
                    institutionType: institution_type,
                    showApps: show_in_apps,
                    allowBooking: allow_booking,
                    FaskesAll: response.data.data.hospital_id,
                    faskesPath: response.data.data.hospital_id.path,
                    cityAll: response.data.data.city,
                    cityPath: response.data.data.city.path,
                })
            }).catch( error => {
                console.log(error)
            })
        }
    },
    getHospital(keyword) {
        this.setState({ isSelectLoading: true })
        let me = this
        let requestApi =  HospitalApi.getHospital(keyword)
        requestApi.then(response => {
            let faskes = response.data.data.map(el => {
                return {
                    value: el.value,
                    label: el.label
                }
            })
            me.setState({
                hospitalList: faskes,
                isSelectLoading: false
            })
        }).catch( error => {
            console.log(error)
        })
    },
    getSpecialistList(){
        let me = this
        if(!this.props.previewMode){
            let requestApi =  SpecialistApi.specialist()
            requestApi.then(response => {
                me.setState({
                    specialistOpt: response.data.data.rows.map(el => {
                        return {
                            value: el.value,
                            label: el.label
                        }
                    }),
                })
            }).catch( error => {
                console.log(error)
            })
        }
    },
}

export default FormHandler