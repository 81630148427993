import React, { Component } from 'react'
//import Select from 'react-select'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from 'react-reactive-form'

import TextInputForm from '../../../core/utils/forminput/TextInputForm'
import RadioInputForm from '../../../core/utils/wizard/RadioInputForm'
import ImageUploadForm from '../../../core/utils/ImageUploadForm'
import MultipleImageForm from '../../../core/utils/MultipleImageForm'
import SelectInputForm from '../../../core/utils/SelectInputForm'
import RichEditorInputForm from '../../../core/utils/RichEditorInputForm'
import iconPlus from '../../../themes/v1/img/rectangle.png'
import Handler from '../handler/FormHandler'
import TextInputPriceForm from '../../../core/utils/forminput/TextInputPriceForm'

class Form extends Component {
    constructor(props) {
        super(props)
        this.state = {
            file: [],
            fileMultiple: [],
            photosAdded: [],
            photosRemove: [],
            servicesOpt: [],
            estimationTimeOpt: [
                {
                    value: '30',
                    label: '30 Menit'
                }
            ],
            Form: FormBuilder.group({
                photo: [''],
                photos: [''],
                icon: [''],
                photo_url: [''],
                name: ['', Validators.required],
                description: [''],
                short_desc: [''],
                price: ['', Validators.required],
                price_special: [''],
                status: ['true'],
                category_service: ['', Validators.required],
                is_upload_idcard: ['false'],
                estimation_time: ['30', Validators.required]
            })
        }
        Object.assign(this, TextInputForm)
        this.TextInputFormView = this.TextInputFormView.bind(this)
        
        Object.assign(this, TextInputPriceForm)
        this.TextInputPriceFormView = this.TextInputPriceFormView.bind(this)

        Object.assign(this, RichEditorInputForm)
        this.RichEditorInputFormView = this.RichEditorInputFormView.bind(this)

        Object.assign(this, RadioInputForm)
        this.RadioInputFormView = this.RadioInputFormView.bind(this)

        Object.assign(this, SelectInputForm)
        this.DropDownView = this.DropDownView.bind(this)

        Object.assign(this, Handler)
        this.buildValue = this.buildValue.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleUpload = this.handleUpload.bind(this)
        this.removePhoto = this.removePhoto.bind(this)
        this.handleUploadMultiple = this.handleUploadMultiple.bind(this)
        this.loadServiceOption = this.loadServiceOption.bind(this)

        this.currentHospital = this.props.currentHospital
        this.buildForm()
    }

    componentDidMount() {
        const { data } = this.props
        this.loadServiceOption()
        if (data.isEdit) {
            this.buildValue({
                ...data,
                status: `${data.is_active || 'false'}`,
                is_upload_idcard: data.is_upload_idcard === true ? 'true' : 'false',
                description: data.info,
                category_service: data.category_service_id
            });
        }
    }

    render() {
        const {
            file,
            fileMultiple,
            Form
        } = this.state
        const { data, dataLogin } = this.props

        return (
            <FieldGroup control={Form}
                strict={false}
                render={({ invalid }) => {
                    return (
                        <div
                            className="wizard-form custom-form"
                            style={{ display: 'flex', margin: '18px auto 70px' }}
                        >
                            <form onSubmit={this.handleSubmit} autoComplete="off">
                                <div className="custom-wrapper">
                                    <label>Icon</label>
                                    <ImageUploadForm
                                        onUpload={this.handleUpload}
                                        desc="Tambah Icon"
                                        icon={iconPlus}
                                        maxsize="2500000"
                                        image={file}
                                        source="Foto"
                                        current={Form.controls.icon}
                                    />
                                </div>
                                <div className="custom-wrapper mbtm">
                                    <label>Foto</label>
                                    <MultipleImageForm
                                        onUpload={this.handleUploadMultiple}
                                        desc="Tambah Foto"
                                        removeTxt="Hapus Foto"
                                        icon={iconPlus}
                                        maxsize="5000000"
                                        image={fileMultiple}
                                        source="Foto"
                                        current={Form.controls.photos}
                                        photo_url={Form.controls.photo_url}
                                        onClickRemove={this.removePhoto}
                                        maxphoto={5}
                                    />
                                    <div className='card-info'>
                                        <div className="custom-wrappers"><label>Pedoman Unggah Foto Layanan</label></div>
                                        <div className="custom-wrappers1"><label>*Photo resolution 666 x 375</label></div>
                                        <div className="custom-wrappers1"><label>*Maksimal ukuran file 3 MB</label></div>
                                        <div className="custom-wrappers1"><label>*Format Foto hanya png, jpg, jpeg dan svg</label></div>
                                    </div>
                                </div>
                                {this.nameField}
                                {this.positionField}
                                {this.shortDescField}
                                {this.descField}
                                {this.priceField}
                                {/* {this.priceSpecialField} */}
                                <div className="custom-wrapper">
                                    <label>Sub Kategori</label>
                                    {this.DropDownServices}
                                </div>
                                <div className="custom-wrapper">
                                    <label>Estimasi Waktu</label>
                                    {this.DropDownEstimation}
                                </div>
                                {this.statusField}
                                {this.uploadIdCardField}
                                {
                                    dataLogin.user_type === "a"
                                    ?
                                    ""
                                    :
                                <div className="button-action-slider">
                                    <div className="form-btn-action">
                                        <button
                                            type="submit"
                                            className={
                                                `save itgbtn primary ${invalid
                                                    ? 'disabled'
                                                    : ''}`
                                            }
                                            disabled={invalid}
                                            onSubmit={this.handleSubmit}
                                        >
                                            {data && data.isEdit ? 'Update' : 'Simpan'}
                                        </button>
                                        <button
                                            type="button"
                                            className="itgbtn cancel"
                                            onClick={() => this.props.onPanelClose()}
                                        >
                                            Batalkan
                                        </button>
                                    </div>
                                </div>
                                }
                            </form>
                        </div>
                    )
                }}
            />
        )
    }
}

export default Form