import React from 'react'
import Chance from 'chance'
import { isEmpty } from 'lodash'

import Api from '../../../services/apis/MedicalRecordHospital'
import ApiDiagnosis from '../../../services/apis/Diagnosis'
import ApiPatient from '../../../services/apis/Patient/Patient'
import { handleToastServiceError } from '../../../core/utils/UtilsToast'

const chance = new Chance();
const FormHandler = {
    onPanelPatientClose() {
        this.setState({
            isPanelPatientOpen: false,
            panelDataPatient: {},
        })
    },
    onPanelPatientOpen(data) {
        this.getPatientDetail(data.patient_id)
    },
    onPanelMRClose() {
        this.setState({ isPanelMROpen: false })
        setTimeout(() => {
            this.setState({ panelDataMR: {} })
        }, 300);
    },
    onPanelMROpen(id, accessBy) {
        this.getEMRDetail('panel', id, accessBy)
    },
    handleOpenModal(action, data) {
        this.setState({
            isModalOpen: true,
            activeIndex: data.index || 0,
            modalData: {
                action,
                data
            },
        })
    },
    handleCloseModal() {
        this.setState({
            isModalOpen: false,
            activeIndex: 0,
            modalData: {},
        })
    },
    buildForm() {
        this.doctorField = this.DropDownFormView({
            meta: {
                label: 'Dokter',
                EditshowOnlyText: false,
                placeholder: 'Dokter',
                validator: 'required',
                callBack: this.getPatientList,
            },
            name: 'doctor_select',
            stateName: 'doctorOption',
            control: this.state.Form.controls.doctor_select
        })
        this.patientField = this.DropDownFormView({
            meta: {
                label: 'Jadwal Pasien',
                EditshowOnlyText: false,
                placeholder: 'Jadwal Pasien',
                validator: 'required',
                callBack: this.setPatient,
            },
            name: 'patient_select',
            stateName: 'patientOption',
            control: this.state.Form.controls.patient_select
        })
        this.complaintField = this.TextAreaFormView({
            label: 'Keluhan',
            name: 'subj_keluhan',
            placeholder: 'Isi keluhan pasien',
            validator: 'required',
            disabled: this.isDetail,
            control: this.state.Form.controls.subj_keluhan
        })
        this.notesField = this.TextAreaFormView({
            label: 'Catatan Dokter',
            name: 'notes',
            placeholder: 'Isi catatan',
            disabled: this.isDetail,
            control: this.state.Form.controls.notes
        })
    },
    buildValue(data) {
        this.state.Form.patchValue(data)
    },
    getDoctorList: function (id) {
        Api.doctor(this.currentHospital).then((res) => {
            const {
                status,
                data: resData,
            } = res

            if (status === 200) {
                const firstOpt = [{
                    value: '',
                    label: 'Pilih Dokter'
                }]
                const doctorOpt = resData.data.map((item) => {
                    return {
                        value: item.doctor_id,
                        label: `${item.doctor_title
                            ? `${item.doctor_title} `
                            : ''}${item.doctor_name}`,
                    }
                })
                this.setState({ doctorOption: firstOpt.concat(doctorOpt) })

                const isDoctorExist = doctorOpt.find(d => d.value === id)
                if (!isEmpty(isDoctorExist)) {
                    const { doctorEMR } = this.props
                    const did = doctorEMR ? doctorEMR.doctor_id : undefined
                    this.getPatientList(this, 'doctor_select', did)
                    this.state.Form.get('doctor_select').disable()
                }
            }
        })
    },
    getPatientList: function (me, name, did, dataEdit) {
        const { doctorEMR, patientEMR } = me.props
        const doctorId = did || me.state.Form.value[name].value
        const selectedDoctor = me.state.doctorOption.find(item => item.value === doctorId)

        if ((doctorEMR && doctorEMR.value) !== doctorId && doctorId !== '') {
            me.props.setDoctorEMR(selectedDoctor)
            Api.patient(me.currentHospital, doctorId).then((res) => {
                const {
                    status,
                    data: resData,
                } = res

                if (status === 200) {
                    const firstOpt = [{
                        value: '',
                        label: 'Pilih Pasien'
                    }]
                    const patientOpt = resData.data.map((item) => {
                        return {
                            value: `${item.patient_id}_${item.book_date}_${item.arrival_time}`,
                            label: `${item.patient_name} - ${item.book_date} - ${item.arrival_time}`,
                            ...item,
                        }
                    })
                    me.setState({ patientOption: firstOpt.concat(patientOpt) })

                    const isPatientExist = patientOpt.find(d => d.value === (patientEMR && patientEMR.value))
                    if (me.isEdit && dataEdit) {
                        me.buildValue({
                            patient_select: `${dataEdit.patient_id}_${dataEdit.book_date}_${dataEdit.arrival_time}`,
                        })
                    } else if (!isEmpty(isPatientExist) && did !== undefined) {
                        me.state.Form.get('patient_select').disable()
                    } else {
                        me.buildValue({ patient_select: '' })
                        me.setState({ selectedPatient: {} })
                        me.props.setPatientEMR({})
                    }
                }
            })
        } else {
            me.setState({
                patientOption: [{
                    value: '',
                    label: 'Pilih Pasien'
                }],
                selectedPatient: {},
            })
            me.buildValue({
                doctor_select: '',
                patient_select: '',
            })
            me.props.setDoctorEMR({})
            me.props.setPatientEMR({})
        }
    },
    getPatientDetail: async function (id) {
        this.setState({ loadingPatientD: true })
        await ApiPatient.view(id).then((res) => {
            const {
                status,
                data: resData,
            } = res

            this.setState({ loadingPatientD: false })
            if (status === 200 && !isEmpty(resData.data)) {
                this.setState({
                    isPanelPatientOpen: true,
                    panelDataPatient: {
                        headTitle: <div>Detail Data Pasien</div>,
                        ...resData.data,
                    },
                })
            } else {
                handleToastServiceError({ message: resData.message || 'Network Error' })
            }
        })
    },
    getEMRDetail: async function (type, id, accessBy) {
        this.setState({ loadingEMRD: true })
        await Api.detail(this.currentHospital, id).then((res) => {
            const {
                status,
                data: resData,
            } = res

            this.setState({ loadingEMRD: false })
            if (status === 200) {
                if (type === 'panel') {
                    this.setState({
                        isPanelMROpen: true,
                        panelDataMR: {
                            ...resData.data,
                            headTitle: <div>Detail Resume Medis</div>,
                            customCls: accessBy !== 'form'
                        },
                    })
                } else {
                    const {
                        obj_vital_sign: objVitalSign,
                        obj_body_exam: objBodyExam,
                        obj_physical_exam: objPhysicalExam,
                    } = this.state
                    const dataEdit = resData.data
                    const files = dataEdit.file_supports || []
                    const d = dataEdit.diagnosa || []
                    const fileSupports = files.map(item => {
                        return {
                            id: chance.guid(),
                            name: item.name,
                            file: item.file,
                        }
                    })
                    const diagnosa = d.map(item => {
                        return {
                            id: chance.guid(),
                            code: item.code,
                            name: item.name,
                        }
                    })

                    this.getPatientRecord(dataEdit.patient_id)
                    this.buildValue({
                        doctor_select: dataEdit.doctor_id,
                        patient_select: `${dataEdit.patient_id}_${dataEdit.book_date}_${dataEdit.arrival_time}`,
                        subj_keluhan: dataEdit.subj_keluhan,
                        notes: dataEdit.notes,
                    })
                    this.setState({
                        selectedPatient: {
                            patient_id: dataEdit.patient_id,
                            patient_name: dataEdit.patient_name,
                            patient_medical_record_no: dataEdit.patient_medical_record_no,
                        },
                        obj_vital_sign: this.replaceArrObj(objVitalSign, Array.isArray(dataEdit.obj_vital_sign)
                            ? dataEdit.obj_vital_sign : []),
                        obj_physical_exam: this.replaceArrObj(objPhysicalExam, Array.isArray(dataEdit.obj_physical_exam)
                            ? dataEdit.obj_physical_exam : []),
                        obj_body_exam: this.replaceArrObj(objBodyExam, Array.isArray(dataEdit.obj_body_exam)
                            ? dataEdit.obj_body_exam : []),
                        files: fileSupports,
                        diagnosa,
                    })
                    this.props.setDoctorEMR({
                        value: dataEdit.doctor_id,
                        label: `${dataEdit.doctor_title
                            ? `${dataEdit.doctor_title} `
                            : ''}${dataEdit.doctor_name}`,
                    })
                    this.props.setPatientEMR({
                        book_id: dataEdit.booking_id,
                        patient_id: dataEdit.patient_id,
                        label: `${dataEdit.patient_name} - ${dataEdit.book_date} - ${dataEdit.arrival_time}`,
                    })
                }
            }
        })
    },
    setPatient: function (me, name) {
        const patientId = me.state.Form.value[name].value
        if (patientId !== '') {
            const selectedPatient = me.state.patientOption.find(item => item.value === patientId)
            me.setState({ selectedPatient })
            me.props.setPatientEMR(selectedPatient)
            me.getPatientRecord(selectedPatient.patient_id)
        } else {
            me.buildValue({ patient_select: '' })
        }
    },
    getPatientRecord: function (id) {
        Api.patientRecord(this.currentHospital, id).then((res) => {
            const {
                status,
                data: resData,
            } = res

            if (status === 200) {
                this.setState({ patientRecord: resData.data })
            }
        })
    },
    getDiagnosa: function (value) {
        this.setState({ diagnosaInputVal: value })
        if (value.length >= 3) {
            const search = `?search=${value}`
            ApiDiagnosis.listForm(search).then((res) => {
                const {
                    status,
                    data: resData,
                } = res

                if (status === 200) {
                    let result = resData.data
                    if (result.length === 0) {
                        result = [{
                            id: 0,
                            code: '',
                            name: 'Data tidak ketemu',
                        }]
                    } else {
                        result = result.map(item => {
                            return {
                                id: item.value,
                                code: item.label,
                                name: item.label,
                            }
                        })
                    }

                    this.setState({ diagnosaOption: result })
                }
            }).catch((error) => {
                const message = error.response.data.message
                handleToastServiceError({ message })
            })
        }
    },
    setDiagnosa: function (value) {
        if (value !== 'Data tidak ketemu') {
            const { diagnosaOption, diagnosa } = this.state
            const diagnosaObj = diagnosaOption.find(item => item.name === value)
            const isExist = diagnosa.filter(item => item.name === value)

            this.setState({ diagnosaInputVal: '' })
            if (isExist.length === 0) {
                diagnosa.push(diagnosaObj)
                this.setState({ diagnosa })
            }
        }
    },
    delDiagnosa: function (id) {
        const { diagnosa } = this.state
        const result = diagnosa.filter(d => d.id !== id)
        this.setState({ diagnosa: result })
    },
    handleUpload: function (f) {
        const { files, files_add: filesAdd } = this.state
        const maxFiles = 5
        const setF = f.slice(0, maxFiles)
        setF.forEach(file => {
            const reader = new FileReader()
            reader.onload = () => {
                const fileID = chance.guid()
                if (this.isEdit) {
                    filesAdd.push({
                        id: fileID,
                        name: file.name,
                        file: reader.result,
                    })
                    this.setState({ files_add: filesAdd })
                } else {
                    files.push({
                        id: fileID,
                        name: file.name,
                        file: reader.result,
                    })
                    this.setState({ files })
                }
            }
            reader.readAsDataURL(file)
        });
    },
    deleteFile: function (id) {
        const { files, files_add: filesAdd } = this.state
        const result = files.filter(f => f.id !== id)
        this.setState({ files: result })

        if (this.isEdit) {
            const resultAdd = filesAdd.filter(f => f.id !== id)
            this.setState({ files_add: resultAdd })
        }
    },
    toggleObjOptional: function () {
        this.setState(prevState => ({
            showObjOptional: !prevState.showObjOptional
        }))
    },
    handleSubmit: async function (e) {
        e.preventDefault();

        let response = {}
        const {
            obj_vital_sign,
            obj_physical_exam,
            obj_body_exam,
            diagnosa,
            files,
            files_add: filesAdd,
        } = this.state
        const { history, doctorEMR, patientEMR, successMsgEMR } = this.props
        const diagnosaArr = diagnosa.map((item) => {
            return {
                code: item.code,
                name: item.name,
            }
        })
        const fileSupports = files.map(item => {
            return {
                name: item.name,
                file: item.file,
            }
        })
        const fileSupportsAdd = filesAdd.map(item => {
            return {
                name: item.name,
                file: item.file,
            }
        })
        const formValue = Object.assign({}, {
            booking_id: patientEMR.book_id,
            hospital_id: this.currentHospital,
            doctor_id: doctorEMR.value || doctorEMR.doctor_id,
            patient_id: patientEMR.patient_id,
            notes: this.state.Form.value.notes,
            file_supports: fileSupports,
            subj_keluhan: this.state.Form.value.subj_keluhan,
            obj_vital_sign,
            obj_physical_exam,
            obj_body_exam,
            diagnosa: diagnosaArr,
        })

        if (this.isEdit) {
            formValue.file_supports_add = fileSupportsAdd
        }

        this.setState({ loading: true })

        try {
            if (this.isEdit) {
                response = await Api.update(this.id, formValue)
            } else {
                response = await Api.create(formValue)
            }

            successMsgEMR(response.data.message)
            history.push('/emr/list')
        } catch (error) {
            this.setState({ loading: false })
            const {
                response: {
                    data,
                },
                message,
            } = error

            if (typeof data === 'string') {
                handleToastServiceError({ message })
            } else {
                const errData = data.data
                const errMsg = Object.keys(errData).map(k => errData[k]).join('\n')
                handleToastServiceError({ message: errMsg })
            }
        }
    },
    replaceArrObj: function (arrExist, arrNew) {
        return arrExist.map(obj => arrNew.find(o => o.key === obj.key) || obj)
    },
    downloadFile(link, fileName) {
        fetch(`https://cors-anywhere.herokuapp.com/${link}`)
            .then(response => {
                if (response.status === 200) {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob)
                        let a = document.createElement('a')
                        a.href = url
                        a.download = fileName
                        a.click()
                    })
                } else {
                    handleToastServiceError({
                        message: 'File not found.'
                    })
                }
            });
    },
}

export default FormHandler
