import React, { Component } from 'react'

class ProfilePic extends Component {
    constructor() {
        super()
        this.handleError = this.handleError.bind(this)
    }

    handleError() {
        if (this.props.onError) {
            this.props.onError()
        }
    }

    componentWillMount() {
        const source = this.props.source

        if (!source) {
            this.handleError()
        }
    }

    render() {
        const { source, alt, className } = this.props
        return (
            <img className={className} src={source} onError={this.handleError} alt={alt} />
        )
    }
}

export default ProfilePic