import { isEmpty, isArray } from 'lodash'

import Api from '../../../../../services/apis/Subscription'
import {
    handleToast,
    handleToastServiceError
} from '../../../../../core/utils/UtilsToast'

const FormHandler = {
    buildForm() {
        this.nameField = this.TextInputFormView({
            validator: 'required',
            label: 'Nama Paket',
            name: 'name',
            placeholder: 'Nama Paket',
            control: this.state.Form.controls.name
        })

        this.priceField = this.TextInputPriceFormView({
            validator: 'required',
            label: 'Harga',
            name: 'price',
            placeholder: 'Harga',
            inputType: 'price',
            maxLength: 11,
            control: this.state.Form.controls.price
        })

        this.activeField = this.RadioInputFormView({
            name: 'active',
            meta: {
                label: 'Status',
                options: [
                    {
                        value: 'true',
                        name: 'Aktif',
                    },
                    {
                        value: 'false',
                        name: 'Non Aktif',
                    }
                ]
            },
            control: this.state.Form.controls.active
        })
    },
    buildValue(data) {
        this.state.Form.patchValue(data)
    },
    getFeatures: async function (keyword) {
        this.setState({ isSelectLoading: true })

        await Api.feature(keyword).then((res) => {
            const { data } = res.data

            if (res.status === 200) {
                this.setState({
                    featureList: data,
                    featureListState: data,
                    isSelectLoading: false,
                })
            }
        })
    },
    handleSubmit: async function (e) {
        e.preventDefault();

        let response = {}
        const { data } = this.props
        const formValue = Object.assign({}, this.state.Form.value)
        formValue.price = parseInt(formValue.price.replace(/\./g, '').substring(0, 9), 10)
        formValue.active = formValue.active === 'true'

        try {
            if (data.isEdit) {
                response = await Api.update(data.id, formValue)
            } else {
                response = await Api.create(formValue)
            }

            handleToast(response.data.message)

            this.props.loadDataAfterAction()
            this.props.onPanelClose()
        } catch (e) {
            let message = e.message
            const errData = e.response

            if (!isEmpty(errData) && errData.data !== '') {
                message = errData.data.message
                if (!isArray(errData.data.data)) {
                    message = Object.keys(errData.data.data).map(k => errData.data.data[k]).join('\n')
                }
            }

            handleToastServiceError({ message })
        }
    }
}

export default FormHandler
