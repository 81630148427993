import React, { Component } from 'react'

class DataChat extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { data, dataChat } = this.props
        const patientName = data && data.patient_name

        return (
            <div className="emr-data-chat">
                <div className="field border-bottom">
                    <div className="label">Nama Pasien</div>
                    <div className="value">{patientName || '-'}</div>
                </div>
                {dataChat && dataChat.length > 0
                    ? dataChat.map(item => (
                        <div className="tagging" key={item.id}>
                            <div className="name">{item.doctor_name}</div>
                            <small>{item.created_at}</small>
                            <div className="suggestion">{item.message}</div>
                            <div className="title">Spesialisasi</div>
                            <div className="content">
                                {item.tag_specialists.length > 0
                                    ? item.tag_specialists.map(el => el.name).join(', ')
                                    : '-'}
                            </div>
                            <div className="title">Bagian Tubuh</div>
                            <div className="content">
                                {item.tag_parts.length > 0
                                    ? item.tag_parts.map(el => el.name).join(', ')
                                    : '-'}
                            </div>
                            <div className="title">Diagnosa</div>

                            {item.tag_diagnoses.length > 0
                                ? (
                                    <div className="tags">
                                        {item.tag_diagnoses.map(di => (
                                            <div key={di.id} className="tag">{di.name}</div>
                                        ))}
                                    </div>
                                )
                                : <div className="content">-</div>}
                        </div>
                    ))
                    : <div className="empty">Belum ada catatan dokter sebelumnya</div>}

            </div>
        )
    }
}

export default DataChat
