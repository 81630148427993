const filterHandler = {
    handleTypeChange(selectedOption){
        this.setState({ selectedType: selectedOption })
        this.props.onFilter('type', (selectedOption.value === 0) ? '' : selectedOption.value)
    },
    handleStatusChange(selectedOption){
        this.setState({ selectedStatus: selectedOption })
        this.props.onFilter('status', String(selectedOption.value))
    },
    handleCityChange(selectedOption){
        this.setState({ selectedCity: selectedOption })
        this.props.onFilter('city', (selectedOption.value === 0) ? '' : selectedOption.label)
    },
    handleAdvanceClick(e){
        e.preventDefault()
        this.props.onAdvancedClick()
    },
    handleFilter(e){
        if(e.keyCode === 13){
            this.props.onFilter('name', e.target.value)
        }
    },
}

export default filterHandler