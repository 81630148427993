import { isEmpty, isArray } from 'lodash'

import Api from '../../../../../services/apis/SpecialitiesAPI'
import {
    handleToast,
    handleToastServiceError
} from '../../../../../core/utils/UtilsToast'

const FormHandler = {
    buildForm() {
        const { title } = this.props

        this.nameField = this.TextInputFormView({
            validator: 'required',
            label: `Nama ${title} Utama`,
            name: 'name',
            placeholder: `Nama ${title} Utama`,
            control: this.state.Form.controls.name
        })

        this.descField = this.TextAreaFormView({
            label: 'Deskripsi',
            name: 'description',
            placeholder: 'Deskripsi',
            control: this.state.Form.controls.description
        })

        this.positionField = this.TextInputFormView({
            validator: 'required',
            label: `Posisi No`,
            name: 'sort_order',
            placeholder: `Posisi Spesialisasi`,
            control: this.state.Form.controls.sort_order
        })

        this.statusField = this.RadioInputFormView({
            name: 'status',
            meta: {
                label: 'Status',
                options: [
                    {
                        value: 'true',
                        name: 'Aktif',
                    },
                    {
                        value: 'false',
                        name: 'Non Aktif',
                    }
                ]
            },
            control: this.state.Form.controls.status
        })

        this.paidField = this.RadioInputFormView({
            name: 'is_paid',
            meta: {
                label: 'Berbayar',
                options: [
                    {
                        value: 'true',
                        name: 'Ya',
                    },
                    {
                        value: 'false',
                        name: 'Tidak',
                    }
                ]
            },
            control: this.state.Form.controls.is_paid
        })
    },
    handleUpload(file) {
        this.setState({ file })
        this.state.Form.patchValue({ icon: file })
    },
    handleChangeDataSelect(data) {
        const { Form } = this.state

        Form.patchValue({ specialist_ids: data })
        this.setState({
            specialistSelected: data,
            specialistFieldTouched: true,
        })
    },
    buildValue(data) {
        this.state.Form.patchValue(data)
    },
    handleSubmit: async function (e) {
        e.preventDefault();

        let response = {}
        const { data } = this.props
        const formValue = Object.assign({}, this.state.Form.value)
        formValue.specialist_ids = formValue.specialist_ids.map(obj => obj.value)
        formValue.status = formValue.status === 'true'
        formValue.is_paid = formValue.is_paid === 'true'

        try {
            if (data.isEdit) {
                response = await Api.update(data.id, formValue)
            } else {
                response = await Api.create(formValue)
            }

            handleToast(response.data.message)

            this.props.loadDataAfterAction()
            this.props.onPanelClose()
        } catch (e) {
            let message = e.message
            const errData = e.response

            if (!isEmpty(errData) && errData.data !== '') {
                message = errData.data.message
                if (!isArray(errData.data.data)) {
                    message = Object.keys(errData.data.data).map(k => errData.data.data[k]).join('\n')
                }
            }

            handleToastServiceError({ message })
        }
    }
}

export default FormHandler