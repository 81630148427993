import DoctorApi from '../../../../../services/apis/doctor/DoctorApi'
import {
    handleToastServiceError
} from '../../../../../core/utils/UtilsToast'

const FormHandler = {
    buildForm() {
        this.DropDownKonsultasiApp = this.DropDownFormView({
            meta: {
                label: "Konsultasi di Applikasi",
                placeholder: "Pilih Konsultasi di Applikasi",
            },
            name: "consultation_in_app",
            nameText: "consultation_in_app",
            control: this.state.Form.controls.consultation_in_app,
            stateName: 'consultation_in_app',
        })
        this.DropDownKonsultasiVC = this.DropDownFormView({
            meta: {
                label: "Konsultasi Video Call",
                placeholder: "Pilih Konsultasi Video Call",
            },
            name: "consultation_video_call",
            nameText: "consultation_video_call",
            control: this.state.Form.controls.consultation_video_call,
            stateName: 'consultation_video_call',
        })
        this.DropDownPeresepanChat = this.DropDownFormView({
            meta: {
                label: "Peresepan Melalui Chat",
                placeholder: "Pilih Peresepan Melalui Chat",
            },
            name: "resep_chat",
            nameText: "resep_chat",
            control: this.state.Form.controls.resep_chat,
            stateName: 'approval',
        })
        this.DropDownApproval = this.DropDownFormView({
            meta: {
                label: "Approval",
                placeholder: "Pilih Approval",
            },
            name: "approval",
            nameText: "approval",
            control: this.state.Form.controls.approval,
            stateName: 'approval',
        })
    },
    buildValue(data) {
        this.state.Form.patchValue(data)
    },
    handleUpload(file) {
        this.setState({ file })
        this.state.Form.patchValue({ photo: file })
    },
    handleDateRangePicker(data){
        console.log(data)
    },
    DateRange(data) {
        console.log(data)
    },
    handleSubmit: async function (e) {
        e.preventDefault();
        this.setState({ loading: true })
        const {startDate, endDate, startDateED, endDateED, dataCity, dataConsultation_type, dataDokter, dataFaskes } = this.state
        const formValue = Object.assign({}, this.state.Form.value)
        const consultation_in_app = formValue.consultation_in_app
        const consultation_video_call = formValue.consultation_video_call
        const resep_chat = formValue.resep_chat
        const approval = formValue.approval
        const dokter = !dataDokter ? "" : dataDokter.value === undefined ? ""  : dataDokter.value
        const faskes = !dataFaskes ? "" : dataFaskes.value === undefined ? ""  : dataFaskes.value
        const city = !dataCity ? "" : dataCity.value === undefined ? ""  : dataCity.value
        const consultation_type = !dataConsultation_type ? "" : dataConsultation_type.value === undefined ? "": dataConsultation_type.value
         if(startDate.length === 0){
            alert("Silahkan isi Tanggal")
        }else{
        try {
            DoctorApi.download(JSON.stringify({
                date:{start: startDate, end: endDate},
                str_expired_date : {start : startDateED, end : endDateED},
                id_doctor: [dokter],
                id_hospital: faskes,
                city: [city],
                type_consultation:consultation_type,
                consultation_in_app: consultation_in_app.value,
                video_call: consultation_video_call.value,
                chat_prescription: resep_chat.value,
                approval: approval.value === true ? "approved" : "",
            })).then((response) =>
            {
                if(response){
                    this.setState({ loading: false })
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    const blob = new Blob([response.data], {type: "octet/stream"}),
                    url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = `Nakes-${startDate}-${endDate}.csv`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    // window.location = '/'
                }else{
                    this.setState({ loading: false })
                    handleToastServiceError({message: "Gagal Download Csv"})
                    alert("Gagal Download Csv")
                    // window.location = '/'
                }
            });
        } catch (response) {
            this.setState({ loading: false })
            handleToastServiceError(response)
            // window.location = '/'
        }
    }
    },
    getFilter(){
        let me = this
        if(!this.props.previewMode){
            let requestApi =  DoctorApi.getFilterExport()
            requestApi.then(response => {
                me.setState({
                    dokter: response.data.data.doctor.options.map((dr) => {return{value: dr.value, label: dr.label}}),
                    dokterPath: response.data.data.doctor.path,
                    dokterAll: response.data.data.doctor,
                    faskes: response.data.data.faskes.options.map((dr) => {return{value: dr.value, label: dr.label}}),
                    faskesPath: response.data.data.faskes.path,
                    faskesAll: response.data.data.faskes,
                    speciality: response.data.data.speciality.options.map((dr) => {return{value: dr.value, label: dr.label}}),
                    specialityPath: response.data.data.speciality.path,
                    specialityAll: response.data.data.speciality,
                    city: response.data.data.city.options.map((dr) => {return{value: dr.value, label: dr.label}}),
                    cityPath: response.data.data.city.path,
                    cityAll: response.data.data.city,
                    consultation_type: response.data.data.consultation_type.options.map((dr) => {return{value: dr.value, label: dr.label}}),
                    consultation_typePath: response.data.data.consultation_type.path,
                    consultation_typeAll: response.data.data.consultation_type,
                    consultation_in_app: response.data.data.consultation_in_app.options.map((dr) => {return{value: dr.value, label: dr.label}}),
                    consultation_video_call: response.data.data.consultation_video_call.options.map((dr) => {return{value: dr.value, label: dr.label}}),
                    approval: response.data.data.approval.options.map((dr) => {return{value: dr.value, label: dr.label}})
                })
            }).catch( error => {
                console.log(error)
            })
        }
    },
}

export default FormHandler