const getHospitalPackage = (currentHospital, dataLogin) => {
    const hospital = dataLogin && dataLogin.hospitals.find((data) => data.id === currentHospital)
    const hPackage = hospital ? hospital.package : {}

    return hPackage
}

const getHospitalFeatures = (currentHospital, dataLogin) => {
    const hPackage = getHospitalPackage(currentHospital, dataLogin)
    const features = hPackage ? hPackage.features : []

    return features
}

const getMenus = (currentHospital, dataLogin, menus) => {
    const isSA = dataLogin && dataLogin.superadmin
    const features = getHospitalFeatures(currentHospital, dataLogin);

    if (isSA) return menus
    if (features) {
        return menus.filter(m => {
            return features.some(f => {
                let fCode = f.code.replace(/manage_/i, '')

                if (fCode === 'dashboard') fCode = 'dashboardhospital'
                if (fCode === 'setting_hospital') fCode = 'administration'
                if (fCode === 'medical_record') fCode = 'emr'
                return fCode === m.index
            })
        })
    }

    return []
}

const isDenied = (currentHospital, dataLogin, module) => {
    const isSA = dataLogin && dataLogin.superadmin
    const features = getHospitalFeatures(currentHospital, dataLogin)

    if (isSA) return false
    return features && !features.some(f => f.code.includes(module))
}

export {
    getHospitalPackage,
    getMenus,
    isDenied,
};
