import React, { Component } from 'react'
import RoleHelper from '../../../../../core/helper/RoleHelper'
import { NavLink } from 'react-router-dom'

class ButtonEditInformation extends Component{
    render(){
        const dataLogin = this.props.dataLogin
        const isNurse = RoleHelper.isNurse(dataLogin)
        if(isNurse){
            return null
        }
        
        return(
            <NavLink className={ this.props.className } to={ `/doctor/edit/${ this.props.doctorId }` }>
                <span>Edit Informasi</span>
            </NavLink>
        )
    }
}

export default ButtonEditInformation