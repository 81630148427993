import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import MasterTemplate from '../../template/MasterTemplate'
import AccessPermission from '../../template/AccessPermission'
import UserGridComponent from './components/UserGridComponent'
import {UserConfig} from './Config'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../core/const/LoginConst'

class UserMain extends Component
{
    constructor(props) {
        super(props)
        this.state = {
            dataGrid : [],
            isEdit: false,
            isPermission: false,
        }
        this.callBackState = this.callBackState.bind(this)
    }

    callBackState(state){
        this.setState({
            dataGrid : state,
            isEdit: state.editTrigger,
            isPermission: state.permissionTrigger,
        })
    }

    render() {
        const { isEdit, dataGrid, isPermission } = this.state
        const { history, store } = this.props
        const breadcrumbs = [
            {
                label: 'Dashboard',
                path: '/dashboardhospital',
            },
            {
                label: 'Pengaturan',
                path: '/administration/info-hospital',
            },
            {
                label: 'Pengaturan Admin',
                path: null,
            },
        ]

        if(isEdit){
            return <Redirect to={{
                pathname: UserConfig.editUrl + dataGrid.dataProvider.id,
                state: { dataProvider: dataGrid.dataProvider }
            }} />
        }

        if(isPermission){
            return <Redirect to={{
                pathname: UserConfig.permissionUrl + dataGrid.dataProvider.id,
                state: { dataProvider: dataGrid.dataProvider }
            }} />
        }

        return (
            <AccessPermission {...this.props} module="setting_hospital">
                <MasterTemplate {...this.props} breadcrumbs={breadcrumbs}>
                    <UserGridComponent
                        {...this.props}
                        callBackState={this.callBackState}
                        history={history}
                        store={store} />
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(UserMain)
