import axios, { axiosBase, axiosSuperadmin } from '../MasterConfig'

const axiosB = axiosBase()
const axiosS = axiosSuperadmin()

const groupUrl = "dashboard"
const headersPost = {
    'Content-Type': 'multipart/form-data' 
}

class DashboardApi {
    static get = (page, pageSize, sortProperties, sortValue, arrFilter) => {
        let nameQuery = ''
        let cityQuery = ''
        let typeQuery = ''
        let statusQuery = ''
        let hospitalTypeQuery = ''
        let facilityQuery = ''

        arrFilter.map((option, index) => {
            switch(option.field){
                case 'name':
                    nameQuery = (option.value) ? `&name=${option.value}` : ''
                    break;
                case 'city':
                    cityQuery = (option.value) ? `&city=${option.value}` : ''
                    break;
                case 'type':
                    typeQuery = (option.value) ? `&type=${option.value}` : ''
                    break;
                case 'status':
                    statusQuery = (option.value) ? `&verified=${option.value}` : ''
                    break;
                case 'hospital_type':
                    hospitalTypeQuery = (option.value) ? `&hospital_type=${option.value}` : ''
                    break;
                default:
                    facilityQuery = (option.value) ? `&facility=${option.value}` : ''
                    break;
            }

            return true
        })

        return axiosS.get(`/${groupUrl}?page=${page}&sort=${sortProperties}&order=${sortValue}&limit=${pageSize}${nameQuery}${cityQuery}${typeQuery}${statusQuery}${hospitalTypeQuery}${facilityQuery}`)
    }
    static getAdditional = () => axios.get(`/${groupUrl}/index`)
    static getFormat = () => axios.get(`/${groupUrl}/csvexample/`)
    static post = (data) => axios.post(`/${groupUrl}/addhospital`, data, {headers: headersPost})
    static upload = (data) => axios.post(`/${groupUrl}/bulkhospital`, data, {headers: headersPost})
    static search = (limit, filter) => {
        const filterQuery = (filter) ? `${filter}` : ''
        // const limitQuery = (limit) ? `/${limit}` : ''
        
        return axios.get(`/${groupUrl}/searchhospital?q=${filterQuery}`)
    }
    static delete = (id) => axiosB.delete(`/hospital/${id}`)
    static download = (id) => axios.get(`/${groupUrl}/downloadhospitaldata/${id}`)
}
export default DashboardApi