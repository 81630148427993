import React, { Component } from 'react'
import Icons from '../../core/utils/Icons'
import { Container } from 'reactstrap'
import { ToastContainer } from 'react-toastify'
import { connect } from 'react-redux'
import { loginMapDispatchToProps, loginMapStateToProps } from '../../core/const/LoginConst'
import { Redirect } from 'react-router-dom'
import Api from '../../services/apis/LoginApi'

class DoctorConfirm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            redirectTo: null
        }
    }

    componentDidMount(){
        const tokenConfirm = this.props.match.params.tokenId
        const hospitalId = this.props.match.params.HospitalId
        const jsonPost = {
            type:"confirmation",
            token: tokenConfirm,
            id_hospital: hospitalId
        }
        Api.confirmDoctor(JSON.stringify(jsonPost)).then(result => {
            if(result.data.code === 200){
                this.setState({
                    redirectTo: '/confirmation/success'
                })
            }else{
                this.setState({
                    redirectTo: '/confirmation/fail'
                })
            }
        }).catch(error => {
            this.setState({
                redirectTo: '/confirmation/fail'
            })
        })
    }

    render() {
        const { redirectTo } = this.state

        if(redirectTo){
            return(<Redirect to={ redirectTo } />)
        }
        return(
            <div className="login-page-cinnamon">
                <ToastContainer
                    position="top-center"
                    autoClose={ 5000 }
                    hideProgressBar={ false }
                    newestOnTop={ false }
                    closeOnClick
                    rtl={ false }
                    pauseOnVisibilityChange
                />
                <Container className="d-flex align-items-center">
                    <div className="form-login-cinnamon">
                        <div className="row">
                            <div className="wrapper-logo">
                                <img className="login-logo" src={ Icons.logoLogin } alt="Login" />
                            </div>
                            <span className="loading-confirm">
                                Mohon tunggu sebentar, <br />kami sedang mengkonfirmasi Akun Anda...
                            </span>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(DoctorConfirm)