import React from 'react'
import validateReturn from '../ValidatorForm'

import {FieldControl} from "react-reactive-form"

const TextInputEmailForm = {
    TextInputEmailFormView: function({label, validator, name, placeholder, readonly, control, inputType, fieldControlAdditional}) {
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}

        Object.assign(control, additionalController)

        return <FieldControl
            name={name}
            render={(obj) => this.TextInputEmailFormRender(obj)}
            meta={{ label: label, name: name, readonly: readonly, placeholder: placeholder, validator: validator, inputType: inputType || 'text' }}
            control = { control }
        />
    },

    TextInputEmailFormRender: function({handler, touched, hasError, meta}) {
        const isRequired = meta.validator === 'required' ? true : false

        return (
            <div className={touched && !meta.readonly && (hasError("email") || hasError("required")) ? "form-line has-danger" : "form-line"}>
                <label id={`label-${meta.name}`} className="form-control-label" htmlFor={`${meta.label}`}>
                        {`${meta.label}`}
                        {isRequired ? <span className="required">*</span> : null}
                    </label>
                <div className="form-group">
                    <input 
                        type={meta.inputType} 
                        className="form-control form-control-success" 
                        id={`${meta.label}`}
                        placeholder={meta.placeholder}
                        readOnly={ meta.readonly }
                        {...handler()}  />
                </div>
                {
                    !meta.readonly 
                    ?
                        <span className="error-info">
                            {
                                touched
                                && (hasError("required")
                                || hasError("email"))
                                && validateReturn("email")
                            }
                        </span>
                    : null
                }
            </div>
        )
    }
}

export default TextInputEmailForm
