/* eslint-disable */

import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import connect from 'react-redux/es/connect/connect'
import {
    loginMapDispatchToProps, 
    loginMapStateToProps
} from '../core/const/LoginConst'
import { Navbar } from 'reactstrap'
import KlikDokterLogo from '../themes/v1/img/layout/logo/klikdokter.svg'
import RoleHelper from '../core/helper/RoleHelper'
import HospitalFilter from '../components/grid/filter/HospitalFilter'
import SuperAdminSideBarTemplate from './partials/SuperAdminSideBarTemplate'
import NavQuickMenu from './partials/NavQuickMenu'

/** ----------------- Start --------------------- **/

class HeadTemplate extends Component
{
    constructor(props) {
        super(props)

        this.state = {
            isOpen: false,
            isToggleButton: false,
            visitedOverlay: false,
            isAdjustFooter: false,
        }

        this.handleToggleButton = this.handleToggleButton.bind(this)
        this.handleHoverMenu = this.handleHoverMenu.bind(this)
    }

    handleToggleButton(){
        this.setState(state => ({
            isToggleButton: !state.isToggleButton,
            isAdjustFooter: true
        }))

        try {
            localStorage.setItem('isToggleButton', !this.state.isToggleButton)
        } catch (e) {
            console.log('Local Storage is disabled');
        }
    }

    handleHoverMenu(visited){
        if(visited){
            this.setState({
                visitedOverlay: true
            })
        }else{
            this.setState({
                visitedOverlay: false
            })
        }

        try {
            localStorage.setItem('visitedOverlay', visited)
        } catch (e) {
            console.log('Local Storage is disabled');
        }
    }

    componentDidMount(){
        let isToggleButtonStorage = true
        let visited = false

        try {
            isToggleButtonStorage = localStorage.getItem('isToggleButton')
            visited = localStorage.getItem('visitedOverlay')
        } catch (e) {
            console.log('Local Storage is disabled');
        }

        if(isToggleButtonStorage !== 'false'){
            this.setState({
                isToggleButton: true
            })
        }

        if(visited === 'true'){
            this.setState({
                visitedOverlay: true
            })
        }
    }

    render() {
        const {
            visitedOverlay,
            isToggleButton
        } = this.state

        const {
            dataLogin,
            currentHospitalLogo,
            currentHospital,
        } = this.props

        const hospitalLogo = currentHospitalLogo
        const isDoctor = RoleHelper.isDoctor(dataLogin, currentHospital)

        let auth = {
            id: null, email: null, name: null
        }

        if (dataLogin) {
            const { email, name, id } = dataLogin
            auth = {
                id: id, email: email, name: name
            }
        }

        const activeSeparator = visitedOverlay && isToggleButton ? 'active' : 'unactive'
        const shrinked = isToggleButton ? 'shrinked' : ''

        return (
            <div>
                <header className="header dashboard-admin">
                    <Navbar>
                        <div className="container-fluid">
                            <div className="navbar-holder d-flex align-items-center justify-content-between">
                                <div className="navbar-header">
                                    {
                                        isDoctor ? 
                                        <HospitalFilter { ...this.props } /> : 
                                        <div className="navbar-brand d-sm-inline-block">
                                            <div className="brand-text d-lg-inline-block">
                                                {
                                                    hospitalLogo ?
                                                    <img
                                                        src={ hospitalLogo }
                                                        alt="logo" className="Bitmap"/> :
                                                    (
                                                        <div className="brand-link">
                                                            <a
                                                            className={'menu-btn'}
                                                            onClick={() => {
                                                                return this.handleToggleButton()
                                                            }}>
                                                                <span></span>
                                                                <span></span>
                                                                <span></span>
                                                            </a>
                                                            <Link to="/dashboard">
                                                                <img
                                                                    src={KlikDokterLogo}
                                                                    alt="logo"
                                                                    className="Bitmap" />
                                                            </Link>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                                <NavQuickMenu { ...this.props } auth={auth} />
                            </div>
                        </div>
                    </Navbar>
                </header>
                <div className="nav-dashboard page-content d-flex align-items-stretch">
                    <SuperAdminSideBarTemplate
                        {...this.props}
                        history={this.props.history}
                        isToggleButton={isToggleButton}
                        location={this.props.location}
                        auth={auth}
                        handleHoverMenu={this.handleHoverMenu}
                        visitedOverlay={visitedOverlay}
                        onClick={this.handleToggleButton}
                    />
                    <div className={`separator-content ${shrinked} ${activeSeparator}`}></div>
                    <div className={isToggleButton ? 'content-inner active' : 'content-inner '}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(HeadTemplate)