import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import React from 'react';

const InputBox = {
    ConfirmDeleteShowHide : function({title, body}) {
      return (
        <Modal className="modal-doctor" isOpen={this.state.confirmDelete} toggle={this.handleConfirmDeleteClose}>
          <ModalHeader toggle={this.handleConfirmDeleteClose}>{title}</ModalHeader>
          <ModalBody>
            {body}
          </ModalBody>
          <ModalFooter>
          <Button onClick={this.handleAdditionalDelete}>Delete</Button>
          <Button onClick={this.handleConfirmDeleteClose}>Close</Button>
          </ModalFooter>
        </Modal>
      )
    },
    InputBoxShowHide : function({title, body}) {
      return (
          <Modal className="modal-doctor" isOpen={this.state.modalShow} toggle={this.handleCloseModal}>
            <ModalHeader toggle={this.handleCloseModal}>{title}</ModalHeader>
            <ModalBody>
              {body}
            </ModalBody>
            <ModalFooter>
            <Button onClick={this.handleCloseModal}>Save</Button>
                <Button onClick={this.handleCloseModal}>Close</Button>
            </ModalFooter>
          </Modal>
      )
    },
    
  }
  export default InputBox;