import { isEmpty } from 'lodash'
import { axiosBase } from './MasterConfig'

const axiosM = axiosBase()


const groupUrl = 'dashboard/service'

export default class ListLayananApi {
    static list = (page, pageSize, sortProperties, sortValue, filterData) => {
        let setSort = ''
        let setFilter = ''

        if (sortProperties) {
            setSort = `&sort=${sortProperties}&order=${sortValue}`
        }

        if (!isEmpty(JSON.parse(filterData))) {
            setFilter = `&filter=${filterData}`
        }

        return axiosM.get(`/${groupUrl}?page=${page}&limit=${pageSize}${setFilter}${setSort}`)
    }
    static detail = (id) => axiosM.get(`/${groupUrl}/${id}`)
    static post = (data) => axiosM.post(`/${groupUrl}`, data)
    static put = (id, data) => axiosM.put(`/${groupUrl}/${id}`, data)
    static getFilter = () => axiosM.get(`/${groupUrl}/filter`)

}
