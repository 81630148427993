import React from 'react'
import { Link } from 'react-router-dom'

/** ----------------- Start --------------------- **/

export default class SideBarChildTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.onClickEvent = this.onClickEvent.bind(this);
        this.state = {
            dropdownOpen: this.props.dropdownShown,
        }
    }

    toggle(e) {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    onClickEvent(path) {
        this.props.history.push(path)
    }

    render() {
        const { menu, pathname, dataLogin } = this.props
        const { dropdownOpen } = this.state
        const parentActive = pathname.startsWith(`/${menu.index}`)

        let classParent = 'left-link-has-children'
        let icon = menu.icon

        if (parentActive) {
            classParent = 'left-link-has-children active'
            icon = menu.iconHover
        } else {
            if (dropdownOpen) {
                classParent = 'left-link-has-children active'
                icon = menu.iconHover
            } else {
                classParent = 'left-link-has-children'
                icon = menu.icon
            }
        }

        let setChildren = menu.children

        if (dataLogin && dataLogin.superadmin) {
            setChildren = menu.children.filter(item => item.index !== 'change-password')
        }

        return (
            <div className={classParent}>
                <div className="toggle-menu" onClick={this.toggle}>
                    <div className="icon-menu" onMouseOver={() => this.props.handleHoverMenu(true)}>
                        <img src={icon} alt="icon-menu"></img>
                    </div>
                    <span className="expand-will-hide title-parent">{menu.title}</span>
                </div>
                <ul className={dropdownOpen ? 'children-menu' : 'children-menu hide'} >
                    {
                        setChildren.map((menu, index) => {
                            const activeMenu = menu.path === pathname || pathname.startsWith(menu.path + '/') !== false
                            return (
                                <li key={index} className={activeMenu ? 'active' : ''}>
                                    <Link
                                        className="left-link-children"
                                        to={menu.path}
                                    >
                                        <span>{menu.title}</span>
                                        {menu.beta && <div className="beta">Baru</div>}
                                    </Link>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }
}