import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import Select from 'react-select'
import { isEmpty, debounce } from 'lodash'

import iconInfo from '../../../../../themes/v1/img/info-active.png'
import iconFile from '../../../../../themes/v1/img/icon-file.png'
import iconXls from '../../../../../themes/v1/img/icon-xls.png'
import iconClose from '../../../../../themes/v1/img/icon-close.png'
import loader from '../../../../../themes/v1/img/ajax-loader.gif'
import iconUpload from '../../../../../themes/v1/img/icon-upload.png'
import Api from '../../../../../services/apis/MedicalRecord'
import { handleToastServiceError } from '../../../../../core/utils/UtilsToast'

const saveData = (function () {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    return function (data, fileName) {
        const blob = new Blob([data], { type: 'octet/stream' }),
            url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}())

class Upload extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedHospital: null,
            file: {},
            mode: '', // '', loading, dropped, result
        }

        this.handleChangeDataSelect = this.handleChangeDataSelect.bind(this)
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.onChangeInputDebounced = debounce(this.onChangeInputDebounced, 500)
        this.onDrop = this.onDrop.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.downloadFormat = this.downloadFormat.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        const { mode } = this.state

        if (!isEmpty(nextProps.resultUpload) && mode !== 'result') {
            this.setState({ mode: 'result' })
        }
    }

    handleChangeDataSelect(data) {
        this.setState({ selectedHospital: data })
    }

    handleChangeInput(keyword) {
        if (keyword.length >= 3) {
            this.onChangeInputDebounced(keyword)
        }
    }

    onChangeInputDebounced(keyword) {
        const { getHospitalList } = this.props
        getHospitalList(keyword)
    }

    onDrop(files) {
        let fileResult = {}
        const file = files[0]
        const mimesCsv = [
            'text/plain',
            'text/x-csv',
            'application/vnd.ms-excel',
            'application/csv',
            'application/x-csv',
            'text/csv',
            'text/comma-separated-values',
            'text/x-comma-separated-values',
            'text/tab-separated-values',
        ]

        if (!mimesCsv.includes(file.type)) {
            handleToastServiceError({ message: 'Silahkan pilih .csv file' })
            return
        }

        const reader = new FileReader()
        reader.onload = () => {
            fileResult = {
                name: file.name,
                base64: reader.result,
            }
            this.setState({
                file: fileResult,
                mode: 'dropped',
            })
        }
        reader.readAsDataURL(file)
    }

    async handleSubmit() {
        const { selectedHospital, file } = this.state
        const { uploadCsv } = this.props
        const params = {
            hospital_id: selectedHospital.value,
            file_result: file.base64.split(';base64,')[1],
        }

        uploadCsv(params)
    }

    downloadFormat() {
        Api.getFormat().then(function (response) {
            saveData(response.data, 'FORMAT_DATA.csv')
        })
    }

    parseUploadContent() {
        const { mode, file } = this.state
        const { isUploadLoading, resultUpload } = this.props

        if (isUploadLoading) {
            return (
                <div className="loading">
                    <div>Data Pasien</div>
                    <img src={loader} alt="loading" />
                </div>
            )
        } else if (mode === 'dropped') {
            return (
                <div className="file-selected">
                    <div>
                        <img alt="icon-xls" src={iconXls} />
                        <span>{file.name}</span>
                    </div>
                    <button className="renew">
                        Perbarui
                    </button>
                </div>
            )
        } else if (mode === 'result') {
            return (
                <div className="file-result">
                    <div className="info">
                        <div className="icon-info">!</div>
                        <div className="text">
                            Format table di dalam data yang anda unggah tidak sesuai, terdapat kesalahan di dalam data.
                        </div>
                    </div>
                    <div className="detail">
                        <div className="d-row header">
                            <div className="label">Baris</div>
                            <div className="desc">Tipe Kesalahan</div>
                        </div>
                        <div className="d-wrapper">
                            {resultUpload.detail.map((item, i) => {
                                const row = item.split(' : ')
                                return (
                                    <div key={i} className="d-row">
                                        <div className="label">{row[0].split('-')[1]}</div>
                                        <div className="desc">{row[1]}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <button className="submit result">
                        Unggah Ulang
                    </button>
                </div>
            )
        } else {
            return (
                <div>
                    <div className="image-placeholder">
                        <img src={iconUpload} alt="" />
                    </div>
                    <div className="text-placeholder">
                        Geser data anda kesini, atau
                    </div>
                    <div className="button-placeholder">
                        <span>Cari Data</span>
                    </div>
                </div>
            )
        }
    }

    render() {
        const { mode, selectedHospital } = this.state
        const { handleCloseModal, hospitalList, isSelectLoading, isUploadLoading } = this.props

        return (
            <div style={{ position: 'relative', width: 'fit-content', margin: '34px auto' }}>
                <img className="form-close" alt="" src={iconClose} onClick={() => handleCloseModal()} />
                <div className="hospital-upload-form md">
                    <p className="form-title">Unggah Data Rapid Test</p>
                    <Select
                        className="dropdown-md"
                        classNamePrefix="react-select"
                        name="faskes"
                        options={hospitalList}
                        placeholder="Pilih RS / Klinik"
                        onChange={this.handleChangeDataSelect}
                        onInputChange={this.handleChangeInput}
                        value={selectedHospital}
                        isLoading={isSelectLoading}
                    />
                    <div
                        className={`hospital-uploader${mode === 'dropped' || isUploadLoading
                            ? ' dropped'
                            : ''}${mode === 'result'
                                ? ' result'
                                : ''}`}
                    >
                        <div className="form-upload-document">
                            <div className="file-container">
                                <Dropzone
                                    onDrop={this.onDrop.bind(this)}
                                    maxSize={15000000}
                                    multiple={false}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div className="file-upload" {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            {this.parseUploadContent()}
                                        </div>
                                    )}
                                </Dropzone>
                                {mode === 'dropped' || isUploadLoading
                                    ? (
                                        <button
                                            disabled={isUploadLoading || selectedHospital === null}
                                            className="submit"
                                            onClick={() => this.handleSubmit()}
                                        >
                                            Submit
                                        </button>
                                    )
                                    : null}
                            </div>
                        </div>
                    </div>
                    <p className="form-desc">
                        <img className="form-info" alt="icon-info" src={iconInfo} />
                        Hanya dapat mengunggah data satuan, maksimal 15 MB. Hanya diperbolehkan mengunggah data .csv sesuai format data yang sudah ditentukan
                    </p>
                    <div className="format">
                        <img className="form-format" alt="icon-file" src={iconFile} />
                        <span className="left">FORMAT_DATA.csv</span>
                        <span
                            className="right"
                            onClick={e => this.downloadFormat(e)}
                        >Unduh format data</span>
                    </div>
                </div>
            </div >
        )
    }
}

export default Upload
