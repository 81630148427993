import React from 'react'
import Select from 'react-select'

class DropDownFilter extends React.Component{
    constructor(props){
        super(props)
        this.handleChange = this.handleChange.bind(this)
    }
    handleChange(newValue){
        const filterData = this.props.filterData
        filterData.handler(filterData.filterType, newValue)
    }
    render(){
        const { filterData } = this.props
        return(
            <div className={ `form-line-filter form-group-wizard ${ filterData.className } ` }>
                <Select
                    className={ `dropdown-wizard` }
                    classNamePrefix="react-select"
                    name={ filterData.title }
                    placeholder={ filterData.placeholder }
                    options={ filterData.filterOpt }
                    onChange={ this.handleChange }
                    value={ filterData.value }
                />
            </div>
        )
    }
}

export default DropDownFilter