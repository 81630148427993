import React from 'react'
import { FieldControl } from "react-reactive-form";

const CheckBox = {
    CheckBoxView: function({name, meta, control, stateName}) {
        return <FieldControl
            name = {name}
            strict = {false}
            meta = {{...meta}}
            render={(obj) => this.CheckBoxRender(obj, name, stateName)}
            control = {control}
        />
    },
    CheckBoxRender: function( {touched, hasError, meta}, name, stateName) {
        const options = this.state[stateName]
        const currentVal = this.state.Form.value[name]
        
        return (
            <div className={touched && hasError(meta.validator) ? "form-line has-danger" : "form-line"}>
                <div className="wrap-options">
                    {
                        options.map((option, index) => {
                            const checkedData = option.checked
                            return (
                                <div key={ index } className="wrap-checkbox-wizard">
                                    <label className="container">
                                        <span className="labeltext">{option.label}</span>
                                        <input 
                                            name={name} 
                                            id={`${name}_${index}_${option.value}`}
                                            type="checkbox"
                                            onChange= {(event) => {
                                                const originalValue = option.value
                                                const checked = event.target.checked
                                                let currentValue = this.state.Form.value[name]
                                                if(checked && originalValue){
                                                    currentValue.push(originalValue)
                                                }else if(!checked && originalValue){
                                                    const index = currentValue.indexOf(originalValue)
                                                    if (index !== -1) {
                                                        currentValue.splice(index, 1);
                                                    }
                                                }

                                                const data = currentValue.filter(function (el) {
                                                    return el != null;
                                                });

                                                this.state.Form.patchValue({
                                                    [name]: data
                                                })
                                            }}
                                            checked={checkedData || currentVal.includes(option.value) ? true : false}
                                            readOnly={option.readOnly ? true : false}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    },
}
export default CheckBox