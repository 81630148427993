/* eslint-disable */

import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import _ from 'lodash'

import SideBarChildTemplate from './SideBarChildTemplate'
import SideBarFooter from './SideBarFooter'
import { modalMapStateToProps, modalMapDispatchToProps } from '../../core/const/ModalConst'
import Test from '../../package/hello/Test'
import ModalTemplate from '../ModalTemplate'
import Icons from '../../core/utils/Icons'
import RoleHelper from '../../core/helper/RoleHelper'
import * as MenuCMS from '../../core/const/Menus'

/** ----------------- Start --------------------- **/

class AdminSideBarTemplate extends Component {

    constructor(props){
        super(props)
        this.state = {
            activeMenu: null,
            showQuickButton: false,
            menus: null
        }
        this.setActiveMenu = this.setActiveMenu.bind(this)
        this.showModalTemplate = this.showModalTemplate.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        const { currentHospital } = this.props

        if (currentHospital !== nextProps.currentHospital) {
            this.getMenu(nextProps.currentHospital)
        }
    }

    setActiveMenu(index){
        this.setState({
            activeMenu: index
        })
    }

    showModalTemplate(){
        this.props.onRequestShow('QuickAction')
    }

    isOwner = (newCurrentHosp) => {
        const {
            dataLogin: {
                hospitals
            }
        } = this.props

        const hospitalSelected = hospitals.find(option => {
            return String(option.id) === String(newCurrentHosp)
        })

        return !_.isEmpty(hospitalSelected) ? hospitalSelected.owner : false
    }

    getRoleMenu(newCurrentHosp) {
        const {
            dataLogin
        } = this.props

        const role = this.getRole(newCurrentHosp)

        if (role) {
            return MenuCMS.getMenuByRole(role, dataLogin)
        }
    }
    
    getRole = (newCurrentHosp) => {
        const { dataLogin } = this.props
        return RoleHelper.getRole(dataLogin, this.isOwner(newCurrentHosp))
    }

    getMenu = (newCurrentHosp) => {
        const menu = this.getRoleMenu(newCurrentHosp)
        const role = this.getRole(newCurrentHosp)
        const icon = Icons.dashboardMenu
        const addMenu = [
            {
                env: 'BILLING',
                index: 'billing',
                title: 'Tagihan',
                icon: icon.bill.normal,
                icon_hover: icon.bill.hover,
            },
            {
                env: 'MEDICAL_RECORD',
                index: 'emr',
                title: 'Resume Medis',
                icon: icon.md.normal,
                icon_hover: icon.md.hover,
            },
        ]

        addMenu.forEach(m => {
            if (process.env[`REACT_APP_HOSPITAL_${m.env}`] === 'true') {
                const getExist = menu.menu.find(value => value.index === m.index)

                if (!getExist && ((role && role.role_name === 'superadmin')
                    || (role && role.role_name === 'admin_spv')
                    || (role && role.role_name === 'doctor'))
                ) {
                    menu.menu.splice(4, 0, {
                        index: m.index,
                        path: `/${m.index}/list`,
                        icon: m.icon,
                        iconHover: m.icon_hover,
                        title: m.title,
                        beta: process.env[`REACT_APP_HOSPITAL_${m.env}_BETA`] === 'true',
                    })
                }
            }
        })

        if(menu){
            this.setState({
                showQuickButton: menu.quickButton,
                menus: menu.menu
            })
        }
    }

    componentWillMount(){
        const { currentHospital } = this.props

        this.getMenu(currentHospital)
    }

    render() {
        const {
            location: {
                pathname,
            },
            verified,
        } = this.props
        const { menus, showQuickButton } = this.state

        const renderMenu = menus.map((menu, index) => {
            let activeMenu = menu.path === pathname || pathname.startsWith(`/${menu.index}`) !== false
            if(menu.children){
                if(!activeMenu) {
                    for (let i = 0; i < menu.children.length; i++){
                        if(menu.children[i].path === pathname || pathname.startsWith(menu.children[i].path + '/') !== false){
                            activeMenu = true
                            break
                        }
                    }
                }
                return (
                    <li key={index} className={activeMenu ? 'active': ''} >
                        <SideBarChildTemplate
                            {...this.props}
                            menu={menu}
                            pathname={pathname}
                            dropdownShown={activeMenu} />
                    </li>
                )
            } else {
                return (
                    <li key={index} className={activeMenu ? 'active' : ''} >
                        <Link className="left-link" to={menu.path}>
                            <div
                                className={`icon-menu${menu.beta ? ' beta-dot' : ''}`}
                                onMouseOver={() => this.props.handleHoverMenu(true)}
                            >
                                <img
                                    src={activeMenu ? menu.iconHover : menu.icon}
                                    alt="icon-menu" />
                            </div>
                            <span className="expand-will-hide">
                                <span>{menu.title}</span>
                                {menu.beta && <div className="beta">Baru</div>}
                            </span>
                        </Link>
                    </li>
                )
            }
        })

        const hoverClass = this.props.visitedOverlay ? ' visited-overlay' : ''
        return (
            <nav
                className={this.props.isToggleButton
                    ? 'side-navbar admin-side-navbar shrinked' + hoverClass
                    : 'admin-side-navbar side-navbar'}
                onMouseLeave={() => this.props.handleHoverMenu(false)}
            >
                {
                    showQuickButton ? 
                    <div className="sidebar-header d-flex align-items-center">
                        <button 
                            className={`quick-action admin-quick-action${!verified ? ' disabled' : ''}`} 
                            onClick={this.showModalTemplate}
                            onMouseOver={ () => this.props.handleHoverMenu(true) }
                            disabled={!verified}
                        >
                            <img src={ Icons.plus } alt="Add" />
                            <span className="expand-will-hide">Tambah</span>
                        </button>
                    </div> : 
                    <div className="separator-quick-button"></div>
                }
                <ul className="list-unstyled scrollable">
                    { renderMenu }
                </ul>
                <SideBarFooter />
                <ModalTemplate Component={ Test } />
            </nav>
        )
    }
}

export default connect(modalMapStateToProps, modalMapDispatchToProps)(AdminSideBarTemplate)