import DsmapApi from '../../../services/apis/DsmapApi'
import {handleToast, handleToastServiceError} from '../../../core/utils/UtilsToast'
import FormHelper from '../../../core/helper/FormHelper'

function getValue(theArray, theKey) {
    for (var i = 0; i < theArray.length; i++){
        var theObject = theArray[i]
        if(theKey === theObject.value)
            return theObject
    }
    return false
}

const FormHandler = {
    buildForm: function () {
            this.specialistDropdown = this.DropDownFormView({
                meta: {
                    label: "Specialist",
                    validator: "required",
                },
                name: "id_specialist",
                stateName: "specialistOption",
                control: this.state.form.controls.id_specialist
            })
            this.doctorDropdown = this.DropDownFormView({
                meta: {
                    label: "Doctor",
                    validator: "required",
                },
                name: "id_doctor",
                stateName: "doctorOption",
                control: this.state.form.controls.id_doctor
            })
            this.hospitalDropdown = this.DropDownFormView({
                meta: {
                    label: "Hospital",
                    validator: "required",
                },
                name: "id_hospital",
                stateName: "hospitalOption",
                control: this.state.form.controls.id_hospital
            })
    },

    valueBuilder: async function (theList) {
        this.valueObject = {
            id: "",
            id_doctor: "",
            id_specialist: "",
            id_hospital: ""
        }

        await this.getDropdownDoctor()
        await this.getDropdownHospital()
        await this.getDropdownSpecialist()

        if (this.props.location.state !== undefined) {
            let dataProvider = this.props.location.state.dataProvider
            let me = this
            me.fillForm(dataProvider)
        }
    },

    redirectToBase: function(e) {
        const me = this
        if(e){
            handleToastServiceError(e)
            setTimeout(() => {
                me.props.history.push('/hospital')
            }, 3000)
        } else {
            me.props.history.push('/hospital')
        }
    },

    fillForm: function (dataProvider) {
        let me = this
        FormHelper.fill(me, dataProvider)
        this.setState({
            isEdit: true
        })
        me.valueObject.id = dataProvider.id
        me.valueObject.id_doctor = getValue(me.state.doctorOption, dataProvider.id_doctor)
        me.valueObject.id_hospital = getValue(me.state.hospitalOption, dataProvider.id_hospital)
        me.valueObject.id_specialist = getValue(me.state.specialistOption, dataProvider.id_specialist)
        this.state.form.patchValue(me.valueObject)
    },

    getDropdownDoctor: async function() {
        let me = this
        try {
            let response = await DsmapApi.listDoctor()
            me.setState({doctorOption: response.data.data})
        } catch (e) {
            me.redirectToBase(e)
        }
    },

    getDropdownHospital: async function() {
        let me = this
        try {
            let response = await DsmapApi.listHospital()
            me.setState({hospitalOption: response.data.data})
        } catch (e) {
            me.redirectToBase(e)
        }
    },

    getDropdownSpecialist: async function() {
        let me = this
        try {
            let response = await DsmapApi.listSpecialist()
            me.setState({specialistOption: response.data.data})
        } catch (e) {
            me.redirectToBase(e)
        }
    },

    handleSubmit: async function (e) {
        e.preventDefault();

        let formValue = Object.assign({}, this.state.form.value)
        let formElement = ['id_doctor', 'id_hospital', 'id_specialist']

        formElement.map((v) => {
            if(formValue[v].value){
                formValue[v] = formValue[v].value
            }
            return true
        })
    
        let id = formValue['id']
        let me = this;

        if (this.state.isEdit) {
            // gw tambahin klo dia habis token redirect ke sono dari sana bakalan di oper ke dashboard,
            // klo token habis si dashboard bakalan ngelempar ke login
            const response = await DsmapApi.update(id, JSON.stringify(formValue))
            // if (response.data.code === 401) {
            //     this.props.history.push('/redirectunauthorize');
            // }

            if (response.data.code !== 200) {
                handleToast(response.data.message)
                return;
            }

            me.setState({postStatus: true})
            handleToast(response.data.message);

            return;
        }

        const response = await DsmapApi.post(JSON.stringify(formValue))

        // if (response.data.code === 401) {
        //     this.props.history.push('/unauthorized');
        // }

        if (response.data.code !== 201) {
            handleToast(response.data.message)
            return;
        }

        me.setState({postStatus: true})
        handleToast(response.data.message);


    }
}

export default FormHandler