import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import MasterTemplate from '../../../template/MasterTemplate'
import View from './components/View'
import AccessPermission from '../../../template/AccessPermission'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../../core/const/LoginConst'

class DoctorDetail extends Component {
    constructor(props) {
        super(props)
        this.detail = true

        let state = this.props.store.getState()
        this.state = {
            token: state.loginReducer.token,
            doctorName: '',
            redirect: false
        }
    }

    componentWillReceiveProps(nextProps) {
        const { currentHospital } = this.props

        if (currentHospital !== nextProps.currentHospital) {
            this.setState({ redirect: true })
        }
    }

    loadHandler(theName) {
        this.setState({
            doctorName: theName,
        })
    }

    render() {
        const { id, name } = this.props.match.params

        if (this.state.redirect) {
            return (
                <Redirect to="/doctor/list" />
            )
        }

        const breadcrumbs = [
            {
                label: 'Dashboard',
                path: '/dashboardhospital',
            },
            {
                label: 'Daftar Dokter / Tenaga Medis',
                path: '/doctor/list',
            },
            {
                label: 'Detail Dokter',
                path: null,
            },
        ]

        return (
            <AccessPermission {...this.props} module="manage_doctor">
                <MasterTemplate {...this.props} detail={this.detail} breadcrumbs={breadcrumbs}>
                    <View {...this.props} id={id} name={name} onLoad={this.loadHandler.bind(this)}></View>
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(DoctorDetail)
