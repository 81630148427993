import React from 'react'
import dateFns from 'date-fns'
import IndonesianLocale from 'date-fns/locale/id'
import BeatLoader from 'react-spinners/BeatLoader'
import Select from 'react-select'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import Handler from '../handler/CalendarHandler'
import Icon from '../../../core/utils/Icons'
import SliderPanel from '../../../components/wizard/SliderPanel'
import SpecialistPanel from './SpecialistPanel'
import {
    appointmentMapStateToProps,
    appointmentMapDispatchToProps
} from '../../../core/const/AppointmentConst'

class Calendar extends React.Component {
    constructor(props) {
        super(props)

        this.firstOpt = {
            value: '',
            label: 'Semua Dokter'
        }

        this.state = {
            currentMonth: new Date(),
            isLoading: false,
            filterDoctor: this.firstOpt,
            doctors: [this.firstOpt],
            doctorSearch: [],
            chosenDoctorId: null,
            isPaneOpen: false,
            panelData: null,
            redirectTo: null,
            selectedDate: null,
            daysState: {},
            showResult: false,
        }

        Object.assign(this, Handler)
        this.onPanelOpen = this.onPanelOpen.bind(this)
        this.onPanelClose = this.onPanelClose.bind(this)
        this.prevMonth = this.prevMonth.bind(this)
        this.nextMonth = this.nextMonth.bind(this)
    }

    renderHeader() {
        const { currentMonth, isLoading } = this.state

        let PrevDisabled = false
        let NextDisabled = true
        const dateFormat = "MMMM YYYY"
        const nowDate = new Date()
        const oneMonthFromNow = dateFns.addMonths(nowDate, 1)
        const allowThisMonth = (dateFns.format(currentMonth, 'YYYY-MM-DD') <
            dateFns.format(oneMonthFromNow, 'YYYY-MM-DD'))

        if (nowDate.getFullYear() === currentMonth.getFullYear()
            && nowDate.getMonth() === currentMonth.getMonth()) {
            PrevDisabled = true
        }

        if (!allowThisMonth) {
            NextDisabled = false
        }

        return (
            <div className="header row flex-middle">
                <div className="col col-center">
                    <button
                        className={`month-arrow ${PrevDisabled ? 'disabled' : ''}`}
                        onClick={!PrevDisabled ? this.prevMonth : null}
                        disabled={isLoading}
                    >
                        <img src={Icon.left} alt="prev" />
                    </button>
                    <span>
                        {dateFns.format(currentMonth, dateFormat, { locale: IndonesianLocale })}
                    </span>
                    <button
                        className={`month-arrow ${!NextDisabled ? 'disabled' : ''}`}
                        onClick={NextDisabled ? this.nextMonth : null}
                        disabled={isLoading}
                    >
                        <img src={Icon.right} alt="next" />
                    </button>
                </div>
            </div>
        )
    }

    renderDays() {
        const dateFormat = "dddd"
        const days = []
        let startDate = dateFns.startOfWeek(this.state.currentMonth)
        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="col col-center" key={i}>
                    {
                        dateFns.format(
                            dateFns.addDays(startDate, i),
                            dateFormat,
                            {
                                locale: IndonesianLocale
                            }
                        )
                    }
                </div>
            );
        }
        return <div className="days row">{days}</div>
    }

    renderBody() {
        const { currentMonth, selectedDate, daysState, isLoading } = this.state
        const monthStart = dateFns.startOfMonth(currentMonth)
        const monthEnd = dateFns.endOfMonth(monthStart)
        const startDate = dateFns.startOfWeek(monthStart)
        const endDate = dateFns.endOfWeek(monthEnd)
        const currentDate = new Date()
        const dateFormat = 'D'
        const oneMonthFromNow = dateFns.addMonths(currentDate, 1)
        const rows = []

        let days = []
        let day = startDate
        let formattedDate = ''

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = dateFns.format(day, dateFormat)

                const cloneDay = day
                const isCurrentDay = dateFns.isSameDay(currentDate, cloneDay)
                const isPast = !isCurrentDay && dateFns.isPast(cloneDay)
                const allowThisMonth = !isPast &&
                    (dateFns.format(cloneDay, 'YYYY-MM-DD') <
                        dateFns.format(oneMonthFromNow, 'YYYY-MM-DD'))

                let note = ''
                let addClass = 'disabled no-doctor-sch'
                if (!dateFns.isSameMonth(day, monthStart) || !allowThisMonth) {
                    addClass = 'disabled'
                } else if (dateFns.isSameDay(day, selectedDate)) {
                    addClass = 'selected'
                }

                if (!isEmpty(daysState) && (day <= monthEnd && day >= monthStart)) {
                    // eslint-disable-next-line
                    Object.keys(daysState).map(dayState => {
                        if (allowThisMonth && formattedDate === dayState && !daysState[dayState].available) {
                            addClass = 'disabled no-operate'
                        }

                        if (formattedDate === dayState && daysState[dayState].schedule) {
                            addClass = ''
                        }

                        if (formattedDate === dayState && !isEmpty(daysState[dayState].notes)) {
                            note = daysState[dayState].notes
                        }
                        return true
                    })
                }

                days.push(
                    <div
                        key={day}
                        className={`col cell ${addClass}`}
                        onClick={() => this.onPanelOpen(dateFns.parse(cloneDay))}
                    >
                        <span className="number">{formattedDate}</span>
                        <span className="bg">{formattedDate}</span>
                        {note ? <div className="note">{note}</div> : null}
                    </div>
                )

                day = dateFns.addDays(day, 1)
            }

            rows.push(
                <div className="row" key={day}>
                    {days}
                </div>
            )

            days = []
        }

        return (
            <div className="wrap-body-calendar">
                <div className="body-calendar">
                    {isLoading ? (
                        <div className="loading-wrapper">
                            <BeatLoader
                                sizeUnit={"px"}
                                size={14}
                                color={'#123abc'}
                                loading={isLoading}
                            />
                        </div>
                    ) : null}
                    {this.renderDays()}
                    {rows}
                </div>
            </div>
        )
    }

    toggleResult = (isShow) => {
        this.setState((state) => ({ showResult: isShow || !state.showResult }))
    }

    handleFilterDoc = (filterDoctor) => {
        const doctorId = filterDoctor.value || null
        this.setState({ filterDoctor })
        this.loadCalendarData(doctorId)
    }

    loadCalendarData = (doctorId) => {
        const { currentMonth } = this.state

        this.fetchSchedule(currentMonth, doctorId)
        this.setState({
            daysState: {},
            isLoading: true,
        })
    }

    componentDidMount() {
        this.isCalendarMounted = true
        this.props.onClearAppointment()
        this.loadCalendarData()
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.state.currentMonth !== nextState.currentMonth) {
            this.setState({
                daysState: {},
                isLoading: true,
            })

            this.fetchSchedule(nextState.currentMonth)
        }
    }

    componentWillUnmount() {
        this.isCalendarMounted = false
    }

    render() {
        const {
            filterDoctor,
            selectedDate,
            isPaneOpen,
            panelData,
            redirectTo,
        } = this.state

        if (redirectTo) {
            return <Redirect to={redirectTo} />
        }

        return (
            <div className="schedule-appointment">
                <div className="calendar">
                    <div className="operate-info-close appointment">
                        RS / Klinik tidak beroperasi
                    </div>
                    {this.renderHeader()}
                    <Select
                        className="dropdown-filter search-container"
                        classNamePrefix="react-select"
                        placeholder="Cari Dokter"
                        name="filter-key"
                        onChange={this.handleFilterDoc}
                        options={this.state.doctors}
                        value={filterDoctor}
                    />
                    {this.renderBody()}
                </div>
                <SliderPanel
                    {...this.props}
                    open={isPaneOpen}
                    onPanelClose={this.onPanelClose}
                    panelData={panelData}
                >
                    <SpecialistPanel
                        {...this.props}
                        doctorId={filterDoctor.value}
                        selectedDate={selectedDate}
                    />
                </SliderPanel>
            </div>
        )
    }
}

export default connect(appointmentMapStateToProps, appointmentMapDispatchToProps)(Calendar)
