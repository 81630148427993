import React, { Component } from 'react'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from "react-reactive-form"
import SubmitButtonForm from '../../../../core/utils/SubmitButtonForm'
import TextInputForm from '../../../../core/utils/TextInputForm'
import TextAreaForm from '../../../../core/utils/TextAreaForm'
import DoctorSpecialistFormHandler from '../handler/DoctorSpecialistFormHandler'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../../../core/const/LoginConst'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

class DoctorSpecialistForm extends Component
{
    constructor(props) {
        super(props)

        Object.assign(this, TextInputForm)
        Object.assign(this, TextAreaForm)
    
        this.state = {
            name: "",
            description: "",
            isEdit: false,
            postStatus: false,
            doctorSpecialistForm: FormBuilder.group({
                id: [""],
                name: ["", Validators.required],
                description: [""]
            })
        }

        this.TextInputFormView = this.TextInputFormView.bind(this)
        this.TextAreaFormView = this.TextAreaFormView.bind(this)
        Object.assign(this, DoctorSpecialistFormHandler)

        this.handleSubmit = this.handleSubmit.bind(this)
        this.valueBuilder = this.valueBuilder.bind(this)

        this.buildForm()

    }

    componentDidMount() {
        this.valueBuilder()
    }

    render(){
        if (this.state.postStatus) {
            return <Redirect to="/doctor/specialist" />
        }
        return(
            <section className="forms">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card">
                                <div className="card-header d-flex align-items-center">
                                    <h3 className="h4">{this.props.title}</h3>
                                </div>
                                <div className="card-body">
                                    {/** start form */}
                                    <FieldGroup
                                    control={this.state.doctorSpecialistForm}
                                    strict={false}
                                    render={({ get, invalid }) => {
                                    return(
                                        <form onSubmit={() => this.handleSubmit} className="form-horizontal">
                                            {this.TextName}
                                            {this.TextDescription}
                                            <SubmitButtonForm invalid={invalid} onSubmit={(e) => this.handleSubmit(e)} onCancel={(e) => this.props.history.push('/doctor/specialist')}/>
                                        </form>
                                    )}}/>
                                    {/** end of form */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(DoctorSpecialistForm)