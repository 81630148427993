const validatorObject = {
    required: 'Data harus diisi',
    email: 'Format email tidak valid',
    pwdConfMatch: 'Your confirmation password does not match with your password',
    minMaxPhone: 'Harap isi nomor telepon minimal 10 maksimal 15 angka',
    reqMinEMR: 'Data harus diisi dan minimal 20 karakter',
}

const validateReturn = type => validatorObject[type] 

export default validateReturn;