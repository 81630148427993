import React from 'react'
import ReactTable from 'react-table'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import { ContextMenuProvider } from 'react-contexify'
import { isEmpty } from 'lodash'

import MasterGrid from '../../../../../components/grid/MasterGrid'
import GridHandler from '../../../handler/superadmin/subscription/GridHandler'
import SliderPanel from '../../../../../components/wizard/SliderPanel'
import MasterModal from '../../../../../core/utils/MasterModal'
import Checkbox from '../../../../dashboard/components/Checkbox'
import Form from './Form'

const CheckboxTable = checkboxHOC(ReactTable)

class Grid extends MasterGrid {
    constructor(props) {
        super(props)

        Object.assign(this, GridHandler)

        this.clickable = false
        this.filterable = false
        this.selectedTableStatus = false
        this.gridColumn = this.gridColumn.bind(this)
        this.gridMapping = (this.selectedTableStatus) ? CheckboxTable : ReactTable
        this.hidePagination = false
        this.page = 0
        this.pageSize = 20
        this.selectedMax = 10
        this.selected = []
        this.filtered = []
        this.sorted = []
        this.defaultSorted = []
        this.keyTable = '_id'
        this.ignoreColumn = [
            '_id'
        ]

        /* own property */
        this.fetchData = this.fetchData.bind(this)
        this.extraProperties = {
            onFetchData: this.fetchData,
            filterAble: true
        }

        /* data */
        const loading = this.extraProperties.hasOwnProperty('onFetchData') ? true : false
        const data = this.dataFromRedux ? this.props.data : (loading ? [] : this.getData())
        const columns = this.getColumns(data)
        const finalColumn = this.gridColumn(ContextMenuProvider, columns)
        const pages = loading ? null : 1

        this.onPanelOpen = this.onPanelOpen.bind(this)
        this.onPanelClose = this.onPanelClose.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.modalHandleSubmit = this.modalHandleSubmit.bind(this)
        this.loadDataAfterAction = this.loadDataAfterAction.bind(this)
        this.getDetail = this.getDetail.bind(this)
        this.getId = this.getId.bind(this)

        this.state = {
            loading: loading,
            loadingE: false,
            data,
            columns: finalColumn,
            pages: pages,
            rows: 1,
            selection: [],
            deleted: [],
            filtered: [],
            disableHighlight: true,
            selectAll: false,
            isDetailOpen: false,
            detailData: {},
            isModalOpen: false,
            modalData: null,
            checkedConfirm: false,
        }
    }

    componentDidMount() {
        const packageId = this.getId()
        if (packageId) {
            this.getDetail(packageId)
        }
    }

    render() {
        const {
            isDetailOpen,
            detailData,
            isModalOpen,
            modalData,
            checkedConfirm,
        } = this.state
        const { title } = this.props

        return (
            <div>
                <div className="title-wrapper">
                    <h2 className="no-margin-top page-title">{title}</h2>
                    <button
                        className="add"
                        onClick={() => this.onPanelOpen()}
                    >
                        Tambah Paket
                    </button>
                </div>
                <section className="tables with-top-filter no-paging-top">
                    <div className="row">
                        <div className="col-lg-12 cn-table-container wrap-text">
                            <div className="card">
                                {super.render()}
                            </div>
                        </div>
                    </div>
                </section>
                <SliderPanel
                    open={isDetailOpen}
                    onPanelClose={this.onPanelClose}
                    panelData={detailData}>
                    <Form
                        data={detailData}
                        isView={!isEmpty(this.getId())}
                        onPanelClose={this.onPanelClose}
                        loadDataAfterAction={this.loadDataAfterAction} />
                </SliderPanel>
                <MasterModal
                    className="master-modal"
                    isOpen={isModalOpen}
                    handleClose={this.handleCloseModal}
                    ModalData={modalData}
                >
                    <div className="master-modal-content emr">
                        <div className="master-modal-head">
                            Konfirmasi Penghapusan
                        </div>
                        <div className="master-modal-body">
                            <div className="content">
                                Paket yang Anda pilih (<strong>{modalData ? modalData.name : ''}</strong>) akan dihapus dan paket yang sudah di assign
                                akan otomatis berubah menjadi paket default. Apakah Anda yakin?
                                <Checkbox
                                    options={[{
                                        value: true,
                                        label: 'Saya telah konfirmasi',
                                    }]}
                                    prefix="emrConfirm"
                                    onCheck={() => this.setState({ checkedConfirm: true })}
                                    onRemove={() => this.setState({ checkedConfirm: false })}
                                    items={[checkedConfirm]}
                                    class="wrap-checkbox-left emr-confirm"
                                />
                            </div>
                        </div>
                        <div className="master-modal-foot">
                            <button
                                className={`master-btn confirm${!checkedConfirm ? ' disabled' : ''}`}
                                disabled={!checkedConfirm}
                                onClick={() => this.modalHandleSubmit(modalData)}
                            >
                                Lanjutkan
                            </button>
                            <button
                                className="master-btn cancel"
                                onClick={this.handleCloseModal}
                            >
                                Batalkan
                            </button>
                        </div>
                    </div>
                </MasterModal>
            </div>
        )
    }
}

export default Grid
