const Config = {
    estimationOptions: [
        // {
        //     label: '1 menit',
        //     value: 1,
        // },
        // {
        //     label: '5 menit',
        //     value: 5,
        // },
        // {
        //     label: '10 menit',
        //     value: 10,
        // },
        // {
        //     label: '20 menit',
        //     value: 20,
        // },
        {
            label: '30 menit',
            value: 30,
        },
        // {
        //     label: '40 menit',
        //     value: 40,
        // },
        // {
        //     label: '50 menit',
        //     value: 50,
        // },
        // {
        //     label: '60 menit',
        //     value: '60'
        // },
        // {
        //     label: '90 menit',
        //     value: '90'
        // },
        // {
        //     label: '120 menit',
        //     value: '120'
        // },
        // {
        //     label: '150 menit',
        //     value: '150'
        // },
        // {
        //     label: '180 menit',
        //     value: '180'
        // },
    ],
    chatType:  [
        {
            label: "In House",
            value: 'In House',
        },
        {
            label: "Freelance",
            value: 'Freelance',
        }
    ],
    freelanceType: [
        {
            label: 'optima',
            value: 'optima',
        },
        {
            label: 'HJ',
            value: 'HJ',
        },
        {
            label: 'asterion',
            value: 'asterion',
        },
    ],
    filterBooking: [
        { label: "Semua Status", value: '' },
        { label: "Tidak", value: false },
        { label: "Ya", value: true }
    ],
    filterDoctorDashboard: [
        { label: "Nama NaKes", value: 'name' },
        { label: "Email NaKes", value: 'email' },
        { label: "STR", value: 'str_no' }
    ]
}

export default Config