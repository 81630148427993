
import { REHYDRATE } from 'redux-persist';
import { 
  LOGIN_API_CALL_REQUEST, 
  LOGIN_API_CALL_LOGOUT, 
  LOGIN_API_CALL_SUCCESS, 
  LOGIN_API_CALL_FAILURE,
  CHANGE_HOSPITAL,
  ADMIN_CHANGE_HOSPITAL,
  SET_PATIENT_EMR,
  SET_DOCTOR_EMR,
  SET_SUCCESS_MSG,
  NOTIF_APPOINTMENT,
  initialState,
} from '../../../core/const/LoginConst'

export function loginReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
        let isLoggin = false
        let token = null
        let dataLogin = null
        let expire = null
        let currentHospital = null
        let currentHospitalLogo = null
        let currentDoctorPic = null
        let currentHospitalName = null
        let verified = false
        
        if (action.payload !== undefined && action.payload.constructor === Object && action.payload.loginReducer !== undefined) {
            isLoggin = action.payload.loginReducer.isLoggin
            token = action.payload.loginReducer.token
            dataLogin = action.payload.loginReducer.dataLogin
            expire = action.payload.loginReducer.expire
            currentHospital = action.payload.loginReducer.currentHospital
            currentHospitalLogo = action.payload.loginReducer.currentHospitalLogo
            currentDoctorPic = action.payload.loginReducer.currentDoctorPic
            currentHospitalName = action.payload.loginReducer.currentHospitalName
            verified = action.payload.loginReducer.verified
        }

        return {
            ...state,
            isLoggin: isLoggin,
            dataLogin: dataLogin,
            token: token,
            expire: expire,
            currentHospital: currentHospital,
            currentHospitalName: currentHospitalName,
            currentHospitalLogo: currentHospitalLogo,
            currentDoctorPic: currentDoctorPic,
            verified,
            error: null,
            fetching: false,
            dataPost: null
        }
    case LOGIN_API_CALL_REQUEST:
      return { 
        ...state, 
        fetching: true, 
        error: null
      }
    case CHANGE_HOSPITAL :
      const hospitalList = state.dataLogin.hospitals
      const optHospitalSelected = hospitalList.find(hospital => hospital.id === action.hospitalId)
      
      return {
        ...state,
        currentHospital: action.hospitalId,
        currentHospitalLogo: optHospitalSelected && optHospitalSelected.logo ? optHospitalSelected.logo : null
      }
    case LOGIN_API_CALL_LOGOUT:
      return {
        isLoggin: false,
        token: null,
        error: null,
        fetching: false,
        dataLogin: null,
        dataPost: null,
        currentHospital: null,
        currentHospitalLogo: null,
        currentDoctorPic: null,
        expire: null
      }
    case LOGIN_API_CALL_SUCCESS:
      const hospitalData = action.dataLogin.data.profile.hospitals[0]
      return { 
        ...state, 
        fetching: false, 
        dataLogin: action.dataLogin.data.profile,
        currentHospital: hospitalData ? hospitalData.id : null,
        currentHospitalLogo: hospitalData && hospitalData.logo? hospitalData.logo : null,
        currentDoctorPic: hospitalData && hospitalData.doctor_photo ? hospitalData.doctor_photo : null,
        dataPost: null, 
        isLoggin: true, 
        token: action.dataLogin.data.access_token,
        expire: action.dataLogin.data.expires
      }
    case LOGIN_API_CALL_FAILURE:
      return { 
        ...state, 
        fetching: false, 
        dataLogin: null, 
        error: action.error, 
        currentHospital: null,
        currentHospitalLogo: null,
        dataPost: null,
        expire: null
      }
    case ADMIN_CHANGE_HOSPITAL :
      return {
        ...state,
        currentHospital: action.hospitalId,
        currentHospitalName: action.hospitalName,
        verified: action.verified,
      }
    case SET_PATIENT_EMR:
      return {
        ...state,
        patientEMR: action.patient,
      }
    case SET_DOCTOR_EMR:
      return {
        ...state,
        doctorEMR: action.doctor,
      }
    case SET_SUCCESS_MSG:
      return {
        ...state,
        msgEMR: action.msg,
      }
    case NOTIF_APPOINTMENT:
      return {
        ...state,
        notifAppointment: action.notif,
      }
    default:
      return state
  }
}
