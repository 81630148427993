import React, { Component } from 'react'
import MasterTemplate from '../../template/MasterTemplate';
import Form from './components/Form';
import Config from './config/UrlConfig'

class AddRole extends Component
{
    render() {
        return(
            <MasterTemplate 
                {...this.props} 
                wizard={ true } 
                cancelUrl={ Config.GridUrl } 
                backUrl={ Config.GridUrl }
                breadcrumbData={ [] }>
                <Form {...this.props} />
            </MasterTemplate>
        )
    }
}

export default AddRole