import { isEmpty } from 'lodash'
import { axiosMaster } from './MasterConfig'

const axiosM = axiosMaster()

const groupUrl = 'mdr'

export default class MedicalRApi {
    static list = (page, pageSize, sortProperties, sortValue, filterData) => {
        let setSort = ''
        let setFilter = ''

        if (sortProperties) {
            setSort = `&sort=${sortProperties}&order=${sortValue}`
        }

        if (!isEmpty(JSON.parse(filterData))) {
            setFilter = `&filter=${filterData}`
        }

        return axiosM.get(`/${groupUrl}/rapid?page=${page}&limit=${pageSize}${setFilter}${setSort}`)
    }
    static detail = (id) => axiosM.get(`/${groupUrl}/rapid/${id}`)
    static filter = () => axiosM.get(`/${groupUrl}/rapidfilter`)
    static upload = (data) => axiosM.post(`/${groupUrl}/rapidupload`, data)
    static email = (ids) => axiosM.post(`/${groupUrl}/rapidsend`, { ids })
    static getFormat = () => axiosM.get(`/${groupUrl}/rapidsample`)
}
