import React from 'react'
import ReactTable from 'react-table'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import { ContextMenuProvider } from 'react-contexify'

import MasterGrid from '../../../../../../components/grid/MasterGrid'
import GridHandler from '../../../../handler/superadmin/subscription/faskes/GridHandler'
import SliderPanel from '../../../../../../components/wizard/SliderPanel'
import Form from './Form'

const CheckboxTable = checkboxHOC(ReactTable)

class Grid extends MasterGrid {
    constructor(props) {
        super(props)

        Object.assign(this, GridHandler)

        this.clickable = false
        this.filterable = false
        this.selectedTableStatus = false
        this.gridColumn = this.gridColumn.bind(this)
        this.gridMapping = (this.selectedTableStatus) ? CheckboxTable : ReactTable
        this.hidePagination = false
        this.page = 0
        this.pageSize = 20
        this.selectedMax = 10
        this.selected = []
        this.filtered = []
        this.sorted = []
        this.defaultSorted = []
        this.keyTable = '_id'
        this.ignoreColumn = [
            '_id'
        ]

        /* own property */
        this.fetchData = this.fetchData.bind(this)
        this.extraProperties = {
            onFetchData: this.fetchData,
            filterAble: true
        }

        /* data */
        const loading = this.extraProperties.hasOwnProperty('onFetchData') ? true : false
        const data = this.dataFromRedux ? this.props.data : (loading ? [] : this.getData())
        const columns = this.getColumns(data)
        const finalColumn = this.gridColumn(ContextMenuProvider, columns)
        const pages = loading ? null : 1

        this.onPanelClose = this.onPanelClose.bind(this)
        this.getFilterList = this.getFilterList.bind(this)
        this.getPackages = this.getPackages.bind(this)
        this.loadDataAfterAction = this.loadDataAfterAction.bind(this)
        this.onPanelOpen = this.onPanelOpen.bind(this)
        this.getId = this.getId.bind(this)

        this.state = {
            loading: loading,
            loadingE: false,
            data,
            columns: finalColumn,
            pages: pages,
            rows: 1,
            selection: [],
            deleted: [],
            filtered: [],
            disableHighlight: true,
            selectAll: false,
            isPanelOpen: false,
            panelData: {},
            headerFilter: {},
            filterList: {},
            packageList: [],
            packageListState: [],
            rangeOpen: false,
            lastRounded: false,
            isSelectLoading: false,
        }
    }

    componentDidMount() {
        this.getFilterList();
        this.getPackages();

        const hId = this.getId()
        if (hId) {
            this.onPanelOpen({ id: hId, access: 'dashboard' })
        }
    }

    loadDataAfterAction = () => {
        const state = {
            pageSize: this.pageSize,
            page: this.page,
            sorted: this.sorted,
            filter: '',
            filtered: this.state.filtered,
        }

        this.fetchData(state)
    }

    render() {
        const {
            loading,
            selection,
            isPanelOpen,
            panelData,
            headerFilter,
            filterList,
            packageList,
            packageListState,
            lastRounded,
            isSelectLoading,
        } = this.state
        const { title } = this.props
        const filterListArr = Object.keys(filterList)

        this.selected = selection
        this.leftAction = []
        if (filterListArr.length > 0) {
            filterListArr.forEach((key, index) => {
                let roundedPos = 'middle'
                const selectDefault = filterList[key].options.filter(s => {
                    return (s.value !== '') && (s.value === filterList[key].default)
                })

                if (filterListArr.length === 1) {
                    roundedPos = 'single'
                } else if (index === 0) {
                    roundedPos = 'left'
                } else if ((filterListArr.length - 1) === index) {
                    roundedPos = lastRounded ? 'middle' : 'right'
                }

                this.leftAction.push({
                    actionType: key,
                    type: filterList[key].type,
                    title: filterList[key].placeholder,
                    tooltip: filterList[key].tooltip,
                    filterKeyword: headerFilter[key] ? headerFilter[key].value : filterList[key].default,
                    handlerSubmit: this.handleFilter,
                    selectedOpt: headerFilter[key] || selectDefault,
                    filterOpt: filterList[key].options,
                    path: filterList[key].path,
                    handlerChangeOpt: this.handleFilter,
                    handlerChange: this.handleFilter,
                    className: `rounded ${roundedPos}`,
                })
            })
        }

        this.leftAction.push({
            type: 'reset',
            loading: loading,
            tooltip: 'Reset Filter',
            handlerReset: this.handleFilterReset,
        })

        return (
            <div>
                <div className="title-wrapper">
                    <h2 className="no-margin-top page-title">{title}</h2>
                </div>
                <section className="tables with-top-filter no-paging-top">
                    <div className="row">
                        <div className="col-lg-12 cn-table-container wrap-text">
                            <div className="card">
                                {super.render()}
                            </div>
                        </div>
                    </div>
                </section>
                <SliderPanel
                    open={isPanelOpen}
                    onPanelClose={this.onPanelClose}
                    panelData={panelData}>
                    <Form
                        data={panelData}
                        packageList={packageList}
                        packageListState={packageListState}
                        isSelectLoading={isSelectLoading}
                        getPackages={this.getPackages}
                        onPanelClose={this.onPanelClose}
                        loadDataAfterAction={this.loadDataAfterAction} />
                </SliderPanel>
            </div>
        )
    }
}

export default Grid
