import React, { Component } from 'react'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from 'react-reactive-form'
import { Button } from 'reactstrap'
import dateFns from 'date-fns'

import DataConfig from '../config/DataConfig.js'
import RoleHelper from '../../../../core/helper/RoleHelper'
import DropDown from '../../../../core/utils/wizard/schedule/DropDown'
import CheckBox from '../../../../core/utils/wizard/schedule/CheckBox'
import TextInput from '../../../../core/utils/wizard/schedule/TextInput'
import DatePicker from '../../../../core/utils/wizard/schedule/DatePicker'
import FormHandler from '../handler/FormHandler'
import FormButtons from './FormButtons'
import FormError from './FormError'

class Form extends Component{
    constructor(props){
        super(props)

        // assign this to object input element
        Object.assign(this, DropDown)
        Object.assign(this, CheckBox)
        Object.assign(this, TextInput)
        Object.assign(this, DatePicker)

        const idDoctor = this.props.doctorData ? this.props.doctorData.id_doctor : 0
        const idHospital = this.props.doctorData ? this.props.doctorData.id_hospital : 0
        const idHospitalDoctor = this.props.doctorData ? this.props.doctorData.id : 0

        this.state = {
            selectedDate: this.props.selectedDate,
            errorForm: null,
            isEdit: this.props.isEdit,
            editId: this.props.editId,
            submitingForm: false,
            postStatus: false,
            dayLimitList: DataConfig.limitDay,
            dayList: DataConfig.listDay,
            timeList: DataConfig.listTime,
            timeListEnd: [],
            checkLimitOpt: DataConfig.limitOpt,
            dayOpt: [],
            weekOpt: [],
            displayLimitForm: false,
            displayQuotaForm: false,
            displayVideoForm: false,
            Form: FormBuilder.group({
                id: [0],
                time_start: ["", Validators.required],
                time_end: ["", Validators.required],
                day: [[]],
                max_patient_count: [0, Validators.required],
                start_date :[""],
                end_date :[""],
                limit_dday :[0],
                limit_dtime :[""],
                id_doctor:[idDoctor],
                id_hospital:[idHospital],
                id_hospital_doctor:[idHospitalDoctor],
                recurring_weeks: [this.getLabelFromConfig("weekOpt", 1), Validators.required],
                video_call: [false],
            })
        }

        // assign this to view render
        this.DropDownView = this.DropDownView.bind(this)
        this.CheckBoxView = this.CheckBoxView.bind(this)
        this.TextInputView = this.TextInputView.bind(this)
        this.DatePickerView = this.DatePickerView.bind(this)

        //assign this to form handler
        Object.assign(this, FormHandler)
        this.resetLimit = this.resetLimit.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.valueBuilder = this.valueBuilder.bind(this)
        this.fillForm = this.fillForm.bind(this)
        this.onChangeLimitForm = this.onChangeLimitForm.bind(this)
        this.onChangeQuotaForm = this.onChangeQuotaForm.bind(this)
        this.onChangeVideoForm = this.onChangeVideoForm.bind(this)
        this.clearError = this.clearError.bind(this)
        this.buildForm()

        this.isSuperadmin = RoleHelper.isSuperadmin(props.dataLogin)
    }

    getLabelFromConfig(name, index){
        return DataConfig[name].find(option => option.value === index).label
    }

    componentDidMount(){
        const listDayConfig = Object.assign([], this.state.dayList);
        const listRecurringWeek = Object.assign([], DataConfig.weekOpt);
        const selectedDate = this.props.selectedDate
        const dayString = dateFns.format(selectedDate, 'ddd').toLowerCase()

        listDayConfig.map((data) => {
            if(data.value === dayString){
                data.checked = true
                data.readOnly = true
            }
            return data
        })

        this.setState({
            dayOpt: listDayConfig,
            weekOpt: listRecurringWeek
        })

        this.valueBuilder()
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.postStatus !== this.state.postStatus){
            this.props.toggleForm()
            this.props.triggerRefresh()
        }
    }

    componentWillUnmount(){
        const Config = DataConfig.listDay
        Config.map((data) => {
            delete data.checked
            delete data.readOnly
            return data
        })
        this.clearError()
    }

    renderError(){
        if(this.state.errorForm){
            return(<FormError errorForm={this.state.errorForm} clearError={this.clearError}/>)
        }
        return null
    }

    clearError(){
        this.setState({
            errorForm: null,
            editId: null
        })
    }

    getFormTitle(){
        if(!this.state.isEdit){
            return(<h3>Tambah Jadwal</h3>)
        }

        return(<h3>Ubah Jadwal</h3>)
    }

    validateDayOpt(){
        if(!this.state.isEdit){
            return(
                <div className="wrap-recurring-day">
                    <span className="textLabel">Ulang di hari</span>
                    <div className="wrap-group">
                        {this.CheckBoxDay}
                    </div>
                </div>
            )
        }

        return null
    }

    operateRender = (Form) => {
        const { dayOpt } = this.state 
        const {
            operational,
            selectedDate
        } = this.props

        const dayString = dateFns.format(selectedDate, 'ddd').toLowerCase()
        const formDataDay = Form.value.day

        if (!formDataDay.length || formDataDay.includes(dayString)) {
            formDataDay.push(dayString)
        }

        return (
            <div className="operate-info">
                <span className="textLabel">Jadwal Operasional Klinik</span>

                <div className="time-wrapper">
                    {Object.keys(operational).map(day => {
                        if (formDataDay.includes(day)) {
                            let dayTemp = ''
                            let dayExist = false

                            const dayLabel = dayOpt.find(data => {
                                return data.value === day;
                            })

                            if (!operational[day].length) {
                                return (
                                    <div key={day} className="time">
                                        <div className="operateDay">
                                            {dayLabel && dayLabel.label}
                                        </div>
                                        <div>Klinik / RS tidak beroperasi</div>
                                    </div>
                                )
                            }

                            return operational[day].map((v, i) => {
                                if (dayTemp === day) {
                                    dayExist = true
                                }

                                dayTemp = day

                                return (
                                    <div key={i} className="time">
                                        {
                                            !dayExist
                                                ? (
                                                    <div className="operateDay">
                                                        {dayLabel && dayLabel.label}
                                                    </div>
                                                )
                                                : ''
                                        }
                                        <div>{`${v.start} - ${v.end} WIB`}</div>
                                    </div>
                                )
                            })
                        }

                        return null
                    })}
                </div>
            </div>
        )
    }
    
    render() {
        const {
            displayLimitForm,
            displayQuotaForm,
            displayVideoForm,
            Form,
        } = this.state

        const useLimitDate = displayLimitForm
        const useVideoCall = displayVideoForm

        return (
            <div className="schedule-form">
                {this.getFormTitle()}
                {this.renderError()}
                <FieldGroup
                control={Form}
                strict={false}
                render={({ get, invalid }) => {
                const isDisabled = Form.value.limit_dday === 0
                    && Form.value.limit_dtime === ''
                return(
                    <form className="schedule-form-inner" onSubmit={() => this.handleSubmit}>
                        {this.operateRender(Form)}
                        <span className="textLabel" style={{ marginTop: 0 }}>Waktu</span>
                        <div className="wrap-timerange">
                            {this.DropDownStartTime}
                            <span className="separator"></span>
                            {this.DropDownEndTime}
                        </div>
                        {this.validateDayOpt()}
                        <span className="textLabel quota">Kuota Pasien (Otomatis dari sistem)</span>
                        <div className="wrap-group quota">
                            <div className="form-line">
                                <div className="wrap-checkbox-wizard range">
                                    <label className="container">
                                        <span className="labeltext">Ubah</span>
                                        <input
                                            type="checkbox"
                                            onChange={this.onChangeQuotaForm}
                                            checked={displayQuotaForm} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={`wrap-group${!displayQuotaForm ? ' hidden' : ''}`}>
                            {this.TextKuota}
                        </div>
                        <span className="textLabel" style={{ marginTop: '4px' }}>
                            Batas Tutup Pendaftaran (Optional)
                        </span>
                        <div className="wrap-timerange limit">
                            {this.DropDownLimitDay}
                            {this.DropDownLimitTime}
                            <Button
                                type="button"
                                color="link"
                                className="reload"
                                disabled={isDisabled}
                                onClick={() => this.resetLimit()}
                            >
                                <i className="fas fa-sync" />
                            </Button>
                        </div>
                        <span className="textLabel">Periode Praktek</span>
                        <div className="wrap-group wrap-recurring-week">
                            {this.DropDownPeriode}
                        </div>
                        <div className="wrap-group wrap-time-limit">
                            <div className="form-line">
                                <div className="wrap-checkbox-wizard range">
                                    <label className="container">
                                        <span className="labeltext">Menggunakan Jangka Waktu</span>
                                        <input
                                            type="checkbox"
                                            onChange={this.onChangeLimitForm}
                                            checked={useLimitDate ? true : false} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div
                            className={
                                displayLimitForm
                                    ? "wrap-group wrap-date"
                                    : "wrap-group wrap-date hide"
                            }>
                            {this.DateStart}
                            {this.DateEnd}
                        </div>
                        {this.isSuperadmin && (
                            <div className="wrap-group wrap-video">
                                <div className="form-line">
                                    <div className="wrap-checkbox-wizard range">
                                        <label className="container">
                                            <span className="labeltext">Konsultasi Video Call</span>
                                            <input
                                                type="checkbox"
                                                onChange={this.onChangeVideoForm}
                                                checked={useVideoCall ? true : false} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )}
                        <FormButtons
                            {...this.props}
                            handleSubmit={this.handleSubmit}
                            invalid={invalid}
                            toggleForm={this.props.toggleForm}
                            submitingForm={this.state.submitingForm} />
                    </form>
                )}}/>
            </div>
        )
    }
}

export default Form