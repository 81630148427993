export default class RoleHelper{
    static getRole = (dataLogin, isOwner) => {
        if (dataLogin) {
            const userType = dataLogin.user_type

            if (userType === 'd' && isOwner) {
                return {
                    role_name: 'admin_spv'
                }
            } else if (userType === 'd') {
                return {
                    role_name: 'doctor',
                    id: dataLogin.user_id
                }
            } else if (userType === 'p') {
                return {
                    role_name: 'nurse',
                    id: dataLogin.user_id
                }
            } else if (userType === 's') {
                return {
                    role_name: 'superadmin'
                }
            } else if (userType === 'a') {
                return {
                    role_name: 'admin_spv'
                }
            } else if (userType === 'r') {
                return {
                    role_name: 'admin_reg'
                }
            } else {
                return {
                    role_name: 'admin_spv'
                }
            }
        } else {
            return null
        }
    }

    static isDoctor = (dataLogin, currentHospital) => {
        if (dataLogin) {
            if (!dataLogin.superadmin && dataLogin.user_type === 'd') {
                const dataHospital = dataLogin.hospitals.find((data) => data.id === currentHospital)

                return {
                    doctor_id: dataLogin.user_id,
                    id_doctor_hospital: dataHospital.id_doctor_hospital || dataLogin.user_id,
                }
            } else {
                return false
            }
        } else {
            return false
        }
    }

    static isNurse = (dataLogin) => {
        if(dataLogin){
            if(!dataLogin.superadmin && dataLogin.user_type === 'p'){
                return {
                    user_id: dataLogin.user_id,
                }
            }else{
                return false
            }
        }else{
            return false
        }
    }

    static isSuperadmin = (dataLogin) => {
        if(dataLogin){
            if(dataLogin.superadmin){
                return true
            }else{
                return false
            }
        }else{
            return false
        }
    }

    static isAdminSPV = (dataLogin, isOwner) => {
        if(dataLogin){
            if(dataLogin.superadmin || (dataLogin.user_type === 'a' || (dataLogin.user_type === 'd' && isOwner))){
                return true
            }else{
                return false
            }
        }else{
            return false
        }
    }

    static isAdminSPVChat = (dataLogin, isOwner) => {
        if(dataLogin){
            if(dataLogin.superadmin || ((dataLogin.user_type === 'a' || (dataLogin.user_type === 'd' && isOwner)) && dataLogin.chat_status === true)){
                return {
                    user_id: dataLogin.user_id,
                }
            }else{
                return false
            }
        }else{
            return false
        }
    }
}
