import React, { Component } from 'react'
import Select from 'react-select'
import { debounce } from 'lodash'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from 'react-reactive-form'

import TextInputForm from '../../../../../../core/utils/forminput/TextInputForm'
import DatePicker from '../../../../../../core/utils/wizard/schedule/DatePicker'
import RadioInputForm from '../../../../../../core/utils/wizard/RadioInputForm'
import Handler from '../../../../handler/superadmin/subscription/faskes/FormHandler'

class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            selectedPackage: [],
            formApprPackageTouched: false,
            Form: FormBuilder.group({
                hospital_id: ['', Validators.required],
                hospital_names: ['', Validators.required],
                package_id: ['', Validators.required],
                start_date: ['', Validators.required],
                end_date: ['', Validators.required],
                active: ['true'],
            })
        }

        Object.assign(this, TextInputForm)
        this.TextInputFormView = this.TextInputFormView.bind(this)
        Object.assign(this, RadioInputForm)
        this.RadioInputFormView = this.RadioInputFormView.bind(this)
        Object.assign(this, DatePicker)
        this.DatePickerView = this.DatePickerView.bind(this)

        // assign to handler
        Object.assign(this, Handler)
        this.getDetail = this.getDetail.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.buildValue = this.buildValue.bind(this)
        this.handleChangeDataSelect = this.handleChangeDataSelect.bind(this)
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.onChangeInputDebounced = debounce(this.onChangeInputDebounced, 500)

        this.buildForm()
    }

    componentDidMount() {
        const { Form } = this.state
        const { data } = this.props

        if (data) {
            Form.get('hospital_names').disable()

            if (data.access === 'dashboard') {
                this.getDetail(data.id)
            } else {
                this.buildValue({
                    hospital_id: data.id,
                    hospital_names: data.name,
                    package_id: data.package_id,
                    start_date: data.package_start_date ? new Date(data.package_start_date) : '',
                    end_date: data.package_end_date ? new Date(data.package_end_date) : '',
                    active: `${data.package_active}`,
                });
                this.setState({
                    selectedPackage: {
                        value: data.package_id,
                        label: data.package_name,
                    }
                })
            }
        }
    }

    handleChangeDataSelect(data) {
        const { Form } = this.state
        const { packageListState } = this.props

        Form.patchValue({ package_id: data })
        this.setState({
            packageList: packageListState,
            selectedPackage: data,
            formApprPackageTouched: true,
        })
    }

    handleChangeInput(keyword) {
        if (keyword.length >= 3) {
            this.onChangeInputDebounced(keyword)
        }
    }

    onChangeInputDebounced(data) {
        this.props.getPackages(data)
    }

    render() {
        const {
            loading,
            Form,
            selectedPackage,
            formApprPackageTouched,
        } = this.state
        const { data, packageList, isSelectLoading } = this.props

        return (
            <FieldGroup control={Form}
                strict={false}
                render={({ invalid }) => {
                    const packageId = Form.value.package_id
                    return (
                        <div
                            className="wizard-form custom-form"
                            style={{ display: 'flex', margin: '18px auto 70px' }}
                        >
                            <form onSubmit={this.handleSubmit} autoComplete="off" className="schedule-form-inner packages">
                                {this.nameField}
                                <div className="form-line">
                                    <label htmlFor="package" className="form-control-label">
                                        Pilih Paket
                                        <span style={{ marginLeft: '2px', color: 'red' }}>*</span>
                                    </label>
                                    <div className="form-group-wizard subscription">
                                        <Select
                                            className="dropdown-wizard"
                                            classNamePrefix="react-select"
                                            name="package"
                                            options={packageList}
                                            placeholder="Pilih Paket"
                                            onChange={this.handleChangeDataSelect}
                                            onInputChange={this.handleChangeInput}
                                            value={selectedPackage}
                                            isLoading={isSelectLoading}
                                            isMulti={false}
                                        />
                                    </div>
                                    {formApprPackageTouched && selectedPackage.length === 0
                                        ? <div className="custom-error control">
                                            Data Harus Diisi
                                            </div>
                                        : ''}
                                </div>
                                <div className="form-line until">
                                    <label htmlFor="start_date" className="form-control-label">
                                        Periode Aktif
                                        <span style={{ marginLeft: '2px', color: 'red' }}>*</span>
                                    </label>
                                </div>
                                <div className="wrap-group wrap-date">
                                    {this.DateStart}
                                    {this.DateEnd}
                                </div>
                                {this.activeField}
                                <div className="button-action-slider">
                                    <div className="form-btn-action">
                                        <button
                                            type="submit"
                                            className={
                                                `save itgbtn primary ${invalid || packageId < 1 || loading
                                                    ? "disabled"
                                                    : ""}`
                                            }
                                            disabled={invalid || packageId < 1 || loading}
                                            onSubmit={this.handleSubmit}
                                        >
                                            {data && data.isEdit ? 'Update' : 'Simpan'}
                                        </button>
                                        <button
                                            type="button"
                                            className="itgbtn cancel"
                                            onClick={() => this.props.onPanelClose()}
                                        >
                                            Batalkan
                                    </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )
                }}
            />
        )
    }
}

export default Form
