
import React, { Component } from 'react'
import defaultPhoto from '../../../themes/v1/img/default-doctor.png'

class WidgetActiveDoctor extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const {
            id,
            doctor_title,
            doctor_name,
            doctor_photo,
            specialist_name,
            time_start,
            time_end,
            history,
        } = this.props

        return (
            <div className="box-doctor">
                <div className="logo">
                    <div className="wrap-img">
                        <img src={doctor_photo || defaultPhoto} alt={doctor_name || 'no-photo'} />
                    </div>
                </div>
                <div className="info-doctor">
                    <div className="name" onClick={() => history.push(`/doctor/queue/${id}`)}>
                        {`${doctor_title || ''} `}
                        {`${doctor_name || ''}`}
                    </div>
                    <div className="specialist">{specialist_name}</div>
                    <div className="schedule">
                        {`${time_start} - ${time_end} WIB`}
                    </div>
                </div>
                <i className="fas fa-chevron-right" onClick={() => history.push(`/doctor/queue/${id}`)} />
            </div>
        )
    }
}

export default WidgetActiveDoctor
