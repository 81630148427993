import { isEmpty } from 'lodash'
import { axiosBase, axiosMaster } from './MasterConfig'

const axiosB = axiosBase()
const axiosM = axiosMaster()

const groupUrl = 'subscription'

export default class SubscriptionApi {
    static list = (page, pageSize, sortProperties, sortValue, filterData) => {
        let setSort = ''
        let setFilter = ''

        if (sortProperties) {
            setSort = `&sort=${sortProperties}&order=${sortValue}`
        }

        if (!isEmpty(JSON.parse(filterData))) {
            setFilter = `&filter=${filterData}`
        }

        return axiosM.get(`/${groupUrl}/package?page=${page}&limit=${pageSize}${setFilter}${setSort}`)
    }
    static detail = (id) => axiosM.get(`/${groupUrl}/package/${id}`)
    static create = (data) => axiosM.post(`/${groupUrl}/package`, data)
    static update = (id, data) => axiosM.put(`/${groupUrl}/package/${id}`, data)
    static delete = (id) => axiosM.delete(`/${groupUrl}/package/${id}`)
    static feature = () => axiosM.get(`/${groupUrl}/feature`)
    static package = (search) => axiosB.get(`/m/lists/subspackage${search}`)
    static listFaskes = (page, pageSize, sortProperties, sortValue, filterData) => {
        let setSort = ''
        let setFilter = ''

        if (sortProperties) {
            setSort = `&sort=${sortProperties}&order=${sortValue}`
        }

        if (!isEmpty(JSON.parse(filterData))) {
            setFilter = `&filter=${filterData}`
        }

        return axiosM.get(`/${groupUrl}/hospital?page=${page}&limit=${pageSize}${setFilter}${setSort}`)
    }
    static filterFaskes = () => axiosM.get(`/${groupUrl}/hospitalfilter`)
    static detailFaskes = (hId) => axiosM.get(`/${groupUrl}/hospital/${hId}`)
    static assign = (hId, data) => axiosM.put(`/${groupUrl}/hospital/${hId}`, data)
    static listFaskesHistory = (hId, page, pageSize, sortProperties, sortValue, filterData) => {
        let setSort = ''
        let setFilter = ''

        if (sortProperties) {
            setSort = `&sort=${sortProperties}&order=${sortValue}`
        }

        if (!isEmpty(JSON.parse(filterData))) {
            setFilter = `&filter=${filterData}`
        }

        return axiosM.get(`/${groupUrl}/hospitalhistory?hospital_id=${hId}&page=${page}&limit=${pageSize}${setFilter}${setSort}`)
    }
}
