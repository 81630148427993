import React from 'react'
import dateFns from "date-fns"
import IndonesianLocale from 'date-fns/locale/id'

class PatientResult extends React.Component {
    render(){
        const isLoading = this.props.isLoading
        const chosenPatient = this.props.chosenPatient
        
        if(chosenPatient){
            const dob = dateFns.format(
                chosenPatient.dob,
                'D MMMM YYYY',
                {
                    locale: IndonesianLocale
                }
            )

            return(
                <div className="result-patient">
                    <div className="box-assign-patient result-fetched">
                        <span>{chosenPatient.firstname} {chosenPatient.lastname}</span>
                        <span>{chosenPatient.pob ? chosenPatient.pob + ', ' : null}{dob}</span>
                        <span>
                            {
                                chosenPatient.medical_record_no
                                    ? chosenPatient.medical_record_no
                                    : '-'
                            }
                        </span>
                        <span>{chosenPatient.phone ? chosenPatient.phone : '-'}</span>
                        <button className="btn-cnm-standard" onClick={this.props.onPanelOpen}>
                            Ubah Pasien
                        </button>
                    </div>
                </div>
            )
        }
        return(
            <div className="box-assign-patient">
                {
                    !isLoading ?
                        <button
                            className="search-patient"
                            type="button"
                            onClick={this.props.onPanelOpen}
                        >
                            Cari Pasien
                        </button> :
                        '...'
                }
            </div>
        )
    }
}

export default PatientResult