import React, { Component } from 'react'
import { connect } from 'react-redux'

import { loginMapStateToProps, loginMapDispatchToProps } from '../../core/const/LoginConst'
import AccessPermission from '../../template/AccessPermission'
import MasterTemplate from '../../template/MasterTemplate'
import Grid from './components/Grid'

class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const breadcrumbs = [
            {
                label: 'Dashboard',
                path: '/dashboardhospital',
            },
            {
                label: 'Pengaturan',
                path: '/administration/info-hospital',
            },
            {
                label: 'Biaya Layanan',
                path: null,
            },
        ]

        return (
            <AccessPermission {...this.props} module="setting_hospital">
                <MasterTemplate {...this.props} breadcrumbs={breadcrumbs}>
                    <Grid {...this.props} />
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Main)
