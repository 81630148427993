import {handleToast, handleToastServiceError} from '../../../../core/utils/UtilsToast'
import DoctorSpecialistApi from '../../../../services/apis/doctor/DoctorSpecialistApi'

const DoctorSpecialistFormHandler = {
    buildForm: function () {
        this.TextName = this.TextInputFormView({
            label: "Name",
            validator: "required",
            name: "name",
            control: this.state.doctorSpecialistForm.controls.name
        })
        this.TextDescription = this.TextAreaFormView({
            label: "Description",
            name: "description",
            control: this.state.doctorSpecialistForm.controls.description
        })
    },

    valueBuilder: function () {
        this.valueObject = {
            id: "",
            name: "",
            description: ""
        }
        if (this.props.location.state !== undefined) {
            let dataProvider = this.props.location.state.dataProvider
            this.fillForm(dataProvider)
        } else if (this.props.history.location.pathname.match(/.+\/(edit)\/.+/i)) {
            let me = this
            DoctorSpecialistApi.view(this.props.match.params.id).then((response) => {
                me.fillForm(response.data.data)
            }, (e) => {
                handleToastServiceError(e)
                setTimeout(function(){ me.props.history.push('/doctor/specialist') }, 3000)
            })
        }
    },

    fillForm: function(dataProvider) {
        let me = this
        Object.keys(dataProvider).map(function (key) {
            if (me.valueObject.hasOwnProperty(key)) {
                if(typeof me.valueObject[key] === 'boolean') {
                    me.valueObject[key] = !!dataProvider[key]
                } else {
                    me.valueObject[key] = dataProvider[key] ? dataProvider[key].toString() : ''
                }
            }
            return true
        })
        this.state.doctorSpecialistForm.patchValue(me.valueObject)
        me.setState({isEdit: true})
    },

    handleSubmit: async function (e) {
        e.preventDefault()
        let formValue = Object.assign({}, this.state.doctorSpecialistForm.value)
        let id = formValue['id']
        let me = this
        let response

        if (this.state.isEdit) {
            // gw tambahin klo dia habis token redirect ke sono dari sana bakalan di oper ke dashboard,
            // klo token habis si dashboard bakalan ngelempar ke login
            try {
                response = await DoctorSpecialistApi.update(id, JSON.stringify(formValue))
            } catch (e) {
                handleToastServiceError(e)
                return
            }

            if (response.data.code !== 200) {
                handleToast(response.data.message)
                return
            }

            me.setState({postStatus: true})
            handleToast(response.data.message)

            return
        }

        try {
            response = await DoctorSpecialistApi.post(JSON.stringify(formValue))
        } catch (e) {
            handleToastServiceError(e)
            return
        }

        if (response.data.code !== 201) {
            handleToast(response.data.message)
            return
        }

        me.setState({postStatus: true})
        handleToast(response.data.message)


    }
}

export default DoctorSpecialistFormHandler