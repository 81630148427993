const quarterHours = ["00", "30"]
let times = []
let index = 0
for(var i = 0; i <= 24; i++){
    for(var j = 0; j < quarterHours.length; j++){
        const hour = i <= 9 ? '0' + i : i
        times.push({
            value: index,
            label: hour + ":" + quarterHours[j]
        })
        index++
    }
}

const Config = {
    ScrollMenu: [
        {
            label: 'Informasi Umum',
            toSection: 'general'
        },
        {
            label: 'Kontak',
            toSection: 'contact'
        },
        {
            label: 'Operasional',
            toSection: 'operational'
        },
        {
            label: 'Fasilitas',
            toSection: 'facility'
        },
        {
            label: 'Penanggung Jawab',
            toSection: 'pic'
        }
    ],
    igdMenu: [
        {
            value: "1",
            name: "Tersedia",
        },
        {
            value: "0",
            name: "Tidak",
        },
    ],
    listTime: times.splice(0, times.length - 1),
    listDay: [
        { value: 'mon', label: 'Senin' },
        { value: 'tue', label: 'Selasa' },
        { value: 'wed', label: 'Rabu' },
        { value: 'thu', label: 'Kamis' },
        { value: 'fri', label: 'Jumat' },
        { value: 'sat', label: 'Sabtu' },
        { value: 'sun', label: 'Minggu' },
    ],
    InstitutionOption: [
        { value: '1', label: 'Rumah Sakit' },
        { value: '2', label: 'Klinik Pribadi' }
    ],
    CategoryOption: [
        { value: 'S', label: 'Swasta' },
        { value: 'P', label: 'Pemerintah' }
    ],
    ActiveOption: [
        { value: '1', name: 'Ya' },
        { value: '0', name: 'Tidak' }
    ],
}

export default Config