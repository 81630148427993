import React, { Component } from 'react'
import Select from 'react-select'
import { isEmpty, debounce } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

import RoleHelper from '../../../core/helper/RoleHelper'
import Checkbox from '../../dashboard/components/Checkbox'
import DetailHandler from '../handler/DetailHandler'

class Grid extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            loading_download: false,
            patientList: [],
            detail: {},
            invoice_status: 'false',
            invoice_services: [],
            invoice_options_ori: [],
            invoice_options: [],
            used_services_temp: [],
        }

        Object.assign(this, DetailHandler)

        this.getPatientList = this.getPatientList.bind(this)
        this.getData = this.getData.bind(this)
        this.getDropdown = this.getDropdown.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.toRp = this.toRp.bind(this)
        this.handlePatientSelect = this.handlePatientSelect.bind(this)
        this.handleInputPatient = this.handleInputPatient.bind(this)
        this.handleInputPatientDebounced = debounce(this.handleInputPatientDebounced, 500)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.download = this.download.bind(this)

        const { match } = this.props

        this.id = !isEmpty(match.params) ? match.params.id : null
        this.currentHospital = this.props.currentHospital
        this.isSuperadmin = RoleHelper.isSuperadmin(props.dataLogin)
    }

    componentDidMount() {
        if (this.id) {
            this.getData(this.id)
        } else {
            this.getPatientList('')
            this.getDropdown()
            this.setState({
                invoice_services: [{
                    no: 1,
                    randId: Math.random().toString(36).substring(7),
                    code: '',
                    name: '',
                    qty: '',
                    price: '',
                    subtotal: '',
                    invoice_selected: null,
                }]
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        const { currentHospital } = this.props

        if (currentHospital !== nextProps.currentHospital) {
            this.currentHospital = nextProps.currentHospital
            this.getPatientList('')
            this.setState({
                invoice_services: [{
                    no: 1,
                    randId: Math.random().toString(36).substring(7),
                    code: '',
                    name: '',
                    qty: '',
                    price: '',
                    subtotal: '',
                    invoice_selected: null,
                }]
            })
        }
    }

    handleInputPatient(keyword) {
        if (keyword.length >= 3) {
            this.handleInputPatientDebounced(keyword)
        }
    }

    handleInputPatientDebounced(data) {
        this.getPatientList(data)
    }

    render() {
        const {
            loading,
            loading_download: loadingDownload,
            patientList,
            detail,
            invoice_status: invoiceStatus,
            invoice_services: invoiceService,
            invoice_options: invoiceOptions,
        } = this.state

        const {
            book_code: bookCode,
            patient_name: patientName,
            emr_no: emrNo,
            doctor_title,
            doctor_name,
            book_date: bookDate,
        } = detail
        const doctorName = `${doctor_title ? `${doctor_title} ` : ''}${doctor_name || ''}`
        let sum = 0

        return (
            <div className="billing">
                <div className="sub-title">Jadwal Pasien</div>
                <div className="billing-top">
                    {this.id ?
                        (
                            <div className="billing-row">
                                <input
                                    value={patientName
                                        ? `${bookCode ? `${bookCode} - ` : ''}${patientName}`
                                        : '-'}
                                    className="input-pasien"
                                    disabled
                                />
                            </div>
                        )
                        : (
                            <div className="billing-row select-patient" style={{ maxWidth: 390 }}>
                                <Select
                                    className="billing-select"
                                    classNamePrefix="react-select"
                                    options={patientList}
                                    placeholder="Pilih Pasien"
                                    onChange={this.handlePatientSelect}
                                    onInputChange={this.handleInputPatient}
                                    isLoading={loading}
                                />
                            </div>
                        )}
                    <div className="billing-row">
                        <div className="label">No Resume Medis</div>
                        <div className="value">{emrNo || '-'}</div>
                    </div>
                    <div className="billing-row">
                        <div className="label">Nama Dokter</div>
                        <div className="value">{doctorName || '-'}</div>
                    </div>
                    <div className="billing-row">
                        <div className="label">Tanggal Periksa</div>
                        <div className="value">{bookDate || '-'}</div>
                    </div>
                </div>
                <div className="billing-table">
                    <table width="100%">
                        <thead>
                            <tr>
                                <th width="310">Pilih Layanan</th>
                                <th width="70">QTY</th>
                                <th width="190">Harga</th>
                                <th width="190">Subtotal</th>
                                <th width="16" />
                            </tr>
                        </thead>
                        <tbody>
                            {
                                invoiceService.length > 0
                                    ? invoiceService.map((inv, i) => {
                                        let num = inv.subtotal
                                        if (typeof inv.subtotal === 'string') {
                                            num = inv.subtotal > 0
                                                ? parseInt(inv.subtotal.replace(/\./g, ''), 10)
                                                : 0
                                        }
                                        sum += num

                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <Select
                                                        placeholder="Pilih Layanan"
                                                        className="billing-select"
                                                        classNamePrefix="react-select"
                                                        onChange={val => this.handleSelectChange(inv.randId, val)}
                                                        options={invoiceOptions}
                                                        value={inv.invoice_selected}
                                                    />
                                                </td>
                                                <td>
                                                    <div className="input-group">
                                                        <input
                                                            value={`${inv.qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                                            onChange={e => this.handleInputChange(inv.randId, 'qty', e.target.value.replace(/\./g, ''))}
                                                            placeholder="0"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-group disabled">
                                                        <span className="input-group-addon">Rp</span>
                                                        <input
                                                            value={`${inv.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                                            onChange={e => this.handleInputChange(inv.randId, 'price', e.target.value.replace(/\./g, ''))}
                                                            placeholder="0"
                                                            className="price"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-group disabled">
                                                        <span className="input-group-addon">Rp</span>
                                                        <input
                                                            value={`${inv.subtotal}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                                            onChange={e => this.handleInputChange(inv.randId, 'subtotal', e.target.value.replace(/\./g, ''))}
                                                            placeholder="0"
                                                            className="price subtotal"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    {invoiceService.length > 1
                                                        ? (
                                                            <button className="action-del" onClick={() => this.handleDelete(inv.randId)}>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        )
                                                        : null}
                                                </td>
                                            </tr>
                                        )
                                    })
                                    : <tr><td colSpan="5" align="center">Tidak ada data</td></tr>
                            }
                        </tbody>
                    </table>
                    <button className="action-add" onClick={() => this.handleAdd()}>
                        <FontAwesomeIcon icon={faPlus} />
                        Tambah Layanan
                    </button>
                </div>
                <div className="sum">
                    <div className="label">Total Tagihan</div>
                    <div className="value">{this.toRp(sum)}</div>
                </div>
                <div className="action">
                    <Checkbox
                        options={[{ value: 'true', label: 'Sudah dibayar' }]}
                        prefix="invStatus"
                        onCheck={() => this.setState({ invoice_status: 'true' })}
                        onRemove={() => this.setState({ invoice_status: 'false' })}
                        items={[invoiceStatus]}
                        class="wrap-checkbox-left checkbox-with-checklist"
                    />
                    <div className="form-btn-action">
                        {this.id
                            ? (
                                <button
                                    type="button"
                                    className="itgbtn cancel"
                                    onClick={() => this.download(detail)}
                                    disabled={loadingDownload}
                                >
                                    {!loadingDownload ? 'Cetak Tagihan' : 'Loading...'}
                                </button>
                            ) : null}
                        <button
                            className={`save itgbtn primary${isEmpty(detail) || loading ? ' disabled' : ''}`}
                            onClick={() => this.handleSubmit()}
                            disabled={isEmpty(detail) || loading}
                        >
                            Simpan Tagihan
                        </button>
                    </div>
                </div>
            </div >
        )
    }
}

export default Grid
