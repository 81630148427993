import React, { Component } from 'react'
import { Link } from "react-scroll"
import Config from '../../config/Config'

class MenuItem extends Component {
    render() {
        const ScrollMenu = Config.ScrollMenu
        const isSticky = this.props.isSticky
        return (
            <div>
                {isSticky ? <div className="space-overlay" /> : null}
                <ul className={isSticky ? "list-section sticky" : "list-section"}>
                    {
                        ScrollMenu.map((val, key) => (
                            <li className="list-nav" key={key}>
                                <Link
                                    className={key === 0 && !isSticky ? "active" : null}
                                    activeClass="active"
                                    to={val.toSection}
                                    spy={true}
                                    smooth={true}
                                    offset={-190}
                                >
                                    {val.label}
                                </Link>
                            </li>
                        ))
                    }
                </ul>
            </div>
        )
    }
}

export default MenuItem