import React, { Component } from 'react'
import Select from 'react-select'
import { debounce } from 'lodash'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from 'react-reactive-form'

import TextInputForm from '../../../../../core/utils/forminput/TextInputForm'
import TextInputPriceForm from '../../../../../core/utils/forminput/TextInputPriceForm'
import RadioInputForm from '../../../../../core/utils/wizard/RadioInputForm'
import Handler from '../../../handler/superadmin/subscription/FormHandler'

class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            featureList: [],
            featureListState: [],
            selectedFeature: [],
            disableFeature: false,
            isSelectLoading: false,
            formApprFeatureTouched: false,
            Form: FormBuilder.group({
                name: ['', Validators.required],
                features: ['', Validators.required],
                price: ['', Validators.required],
                active: ['true'],
            })
        }

        Object.assign(this, TextInputForm)
        this.TextInputFormView = this.TextInputFormView.bind(this)
        Object.assign(this, TextInputPriceForm)
        this.TextInputPriceFormView = this.TextInputPriceFormView.bind(this)
        Object.assign(this, RadioInputForm)
        this.RadioInputFormView = this.RadioInputFormView.bind(this)

        // assign to handler
        Object.assign(this, Handler)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.buildValue = this.buildValue.bind(this)
        this.handleChangeDataSelect = this.handleChangeDataSelect.bind(this)
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.getFeatures = this.getFeatures.bind(this)
        this.onChangeInputDebounced = debounce(this.onChangeInputDebounced, 500)

        this.buildForm()
    }

    componentDidMount() {
        const { Form } = this.state
        const { data, isView } = this.props

        if (data) {
            this.setState({ selectedFeature: data.features })
            this.buildValue({
                ...data,
                price: data.price ? `${data.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0',
                active: `${data.active || 'false'}`,
            });

            if (isView) {
                this.setState({ disableFeature: true })

                Form.get('name').disable()
                Form.get('features').disable()
                Form.get('price').disable()
                Form.get('active').disable()
            }
        }

        this.getFeatures()
    }

    handleChangeDataSelect(data) {
        const { Form, featureListState } = this.state

        Form.patchValue({ features: data })
        this.setState({
            featureList: featureListState,
            selectedFeature: data,
            formApprFeatureTouched: true,
        })
    }

    handleChangeInput(keyword) {
        // const { selectedFeature } = this.state
        // if (keyword.length >= 3 && selectedFeature.length < 3) {
        //     this.onChangeInputDebounced(keyword)
        // }
    }

    onChangeInputDebounced(data) {
        this.getFeatures(data)
    }

    render() {
        const {
            Form,
            selectedFeature,
            isSelectLoading,
            formApprFeatureTouched,
            featureList,
            disableFeature,
        } = this.state
        const { data, isView } = this.props

        return (
            <FieldGroup control={Form}
                strict={false}
                render={({ invalid }) => {
                    return (
                        <div
                            className="wizard-form custom-form"
                            style={{ display: 'flex', margin: '18px auto 70px' }}
                        >
                            <form onSubmit={this.handleSubmit} autoComplete="off">
                                {this.nameField}
                                <div className="form-line">
                                    <label htmlFor="feature" className="form-control-label">
                                        Fitur
                                        <span style={{ marginLeft: '1px', color: 'red' }}>*</span>
                                    </label>
                                    <div className="form-group-wizard subscription">
                                        <Select
                                            className="dropdown-wizard"
                                            classNamePrefix="react-select"
                                            name="feature"
                                            options={featureList}
                                            placeholder="Pilih RS / Klinik"
                                            onChange={this.handleChangeDataSelect}
                                            onInputChange={this.handleChangeInput}
                                            value={selectedFeature}
                                            isLoading={isSelectLoading}
                                            isMulti
                                            isDisabled={disableFeature}
                                        />
                                    </div>
                                    {formApprFeatureTouched && selectedFeature.length === 0
                                        ? <div className="custom-error control package">
                                            Data Harus Diisi
                                            </div>
                                        : ''}
                                </div>
                                {this.priceField}
                                {this.activeField}
                                <div className="button-action-slider">
                                    <div className="form-btn-action">
                                        <button
                                            type="submit"
                                            className={
                                                `save itgbtn primary ${invalid || isView
                                                    ? "disabled"
                                                    : ""}`
                                            }
                                            disabled={invalid || isView}
                                            onSubmit={this.handleSubmit}
                                        >
                                            {data && data.isEdit ? 'Update' : 'Simpan'}
                                        </button>
                                        <button
                                            type="button"
                                            className="itgbtn cancel"
                                            onClick={() => this.props.onPanelClose()}
                                        >
                                            Batalkan
                                    </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )
                }}
            />
        )
    }
}

export default Form
