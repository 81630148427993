import axios from '../MasterConfig'
const groupUrl = "hospital_facility";

class HospitalFacility {
    static get = (page, pageSize, sortProperties, sortValue, filterData) => axios.get(`/${groupUrl}/list?page=${page}&sort=${sortProperties}&order=${sortValue}&limit=${pageSize}&filter=${filterData}`)
    static post = (data) => axios.post(`/${groupUrl}/create`, data)
    static delete = (id) => axios.delete(`/${groupUrl}/delete/${id}`)
    static update = (id, data) => axios.put(`/${groupUrl}/update/${id}`, data)
    static bulkDelete = (data) => axios.post(`/${groupUrl}/bulkdelete`, data)
    static view = (id) => axios.get(`/${groupUrl}/get/${id}`)
}
export default HospitalFacility;