import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { isEmpty } from 'lodash'

import MasterTemplate from '../../../template/MasterTemplate'
import MaskInput from 'react-maskinput'
import RoleHelper from '../../../core/helper/RoleHelper'
import AccessPermission from '../../../template/AccessPermission'
import { loginMapStateToProps, loginMapDispatchToProps } from '../../../core/const/LoginConst'

class PreAdd extends Component
{
    constructor(props){
        super(props)
        this.wizard = true
        this.cancelUrl = '/doctor/list'
        this.backUrl = '/doctor/list'
        
        let breadcrumbData = [
            {
                no: '1',
                label: 'Informasi NaKes',
                finished: false,
                path: '/doctor/findstr',
                onClick: e => this.handleInactive(e)
            },
            {
                no: '2',
                label: 'Atur Jadwal',
                path: '/doctor/add/schedule',
                onClick: e => this.handleInactive(e)
            }
        ]

        this.state = {
            disabledButton: true,
            strNoSubmit: null,
            redirectTo: null
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.breadcrumbData = breadcrumbData
    }

    handleInactive(event){
        event.preventDefault()
    }

    handleChange(e){
        if(e.target.value && e.target.value.length >= 6){
            this.setState({
                disabledButton: false
            })
        }else{
            this.setState({
                disabledButton: true
            })
        }
    }

    handleSubmit(e){
        e.preventDefault()
        const strNo = this.strInput.refs.input.value
        this.setState({
            redirectTo: `/doctor/add/${ strNo }`
        })
    }

    isOwner = () => {
        const {
            dataLogin,
            currentHospital
        } = this.props

        const hospitalSelected = dataLogin && dataLogin.hospitals.find(option => {
            return String(option.id) === String(currentHospital)
        })

        return !isEmpty(hospitalSelected) ? hospitalSelected.owner : false
    }

    render() {
        const {
            dataLogin,
            currentHospital
        } = this.props

        //doctor cannot add doctor
        const isDoctor = RoleHelper.isDoctor(dataLogin, currentHospital)

        if (isDoctor && !this.isOwner()) {
            return (<Redirect to={"/doctor/list"} />)
        }

        if(this.state.redirectTo){
            return(
                <Redirect to={ this.state.redirectTo } />
            )
        }
        const disabledButton = this.state.disabledButton
        return (
            <AccessPermission {...this.props} module="manage_doctor">
                <MasterTemplate {...this.props}
                    backUrl={this.backUrl}
                    wizard={this.wizard}
                    cancelUrl={this.cancelUrl}
                    breadcrumbData={this.breadcrumbData}
                >
                    <div className="wizard-form doctor-form doctor-preadd">
                        <h3>Cari NaKes dengan No. Str</h3>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-line">
                                <label className="form-control-label">No Str Nakes</label>
                                <div className="form-group">
                                    <MaskInput
                                        autoFocus
                                        alwaysShowMask={false}
                                        className="form-control"
                                        mask='000000000000000000000000000000'
                                        onChange={this.handleChange}
                                        ref={(input) => { this.strInput = input }}
                                    />
                                </div>
                            </div>
                            <div className="wrap-button">
                                <button onSubmit={this.handleSubmit} type="submit" disabled={disabledButton} className={`btn btn-submit-wizard ${disabledButton ? 'btn-secondary' : 'btn-primary'}`}>Cari</button>
                            </div>
                        </form>
                    </div>
                </MasterTemplate>
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(PreAdd)
