import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import BeatLoader from 'react-spinners/BeatLoader'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import {
    Container,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    Button,
    TabContent,
    TabPane,
    Carousel,
    CarouselItem,
} from 'reactstrap';
import { loginMapStateToProps, loginMapDispatchToProps } from '../../../core/const/LoginConst'

import MasterTemplate from '../../../template/MasterTemplate'
import MasterModal from '../../../core/utils/MasterModal'
import QueueHandler from './handler/QueueHandler'
import AccessPermission from '../../../template/AccessPermission'

class Queue extends Component {
    constructor(props) {
        super(props)
        this.state = {
            info: {},
            queue: {},
            queuePopupList: [],
            activeTab: '',
            isModalOpen: false,
            modalData: null,
            activeIndex: 0,
            animating: false,
            loading: false,
            loadingCall: false,
            tabs: [
                {
                    value: 'unconfirmed',
                    label: 'Belum Daftar Ulang',
                },
                {
                    value: 'confirmed',
                    label: 'Antrian',
                },
                {
                    value: 'completed',
                    label: 'Selesai',
                },
            ],
        }

        Object.assign(this, QueueHandler)
        this.loadQueue = this.loadQueue.bind(this)
        this.loadDataConfirm = this.loadDataConfirm.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleSubmitModal = this.handleSubmitModal.bind(this)
        this.toggleTab = this.toggleTab.bind(this)
        this.next = this.next.bind(this)
        this.previous = this.previous.bind(this)
        this.handleCall = this.handleCall.bind(this)
    }

    componentDidMount() {
        this.loadQueue()
    }

    slides = () => {
        const { queuePopupList } = this.state

        if (queuePopupList.length > 0) {
            return queuePopupList.map((item) => {
                return (
                    <CarouselItem
                        onExiting={() => this.setState({ animating: true })}
                        onExited={() => this.setState({ animating: false })}
                        key={item.id}
                    >
                        <div className="wrap-content">
                            <div className="content">
                                <div className="label">No. Antrian</div>
                                <div className="no-wait">{item.book_no}</div>
                                <div className="label">Nama Pasien</div>
                                <div className="value">{item.patient_name}</div>
                                <div className="label">No. Telepon Pasien</div>
                                <div className="value">{item.patient_phone || '-'}</div>
                                <div className="label">Jam Konsultasi</div>
                                <div className="value">{item.arrival_time || '-'}</div>
                            </div>
                        </div>
                    </CarouselItem>
                );
            })
        }
    }

    parseModal(data) {
        if (data && data.type) {
            switch (data.type) {
                case 'call': {
                    let noPrev = true
                    let noNext = true
                    const { queuePopupList, activeIndex, loadingCall } = this.state

                    if (queuePopupList.length > 0) {
                        if (queuePopupList.length !== 1) {
                            noPrev = false
                            noNext = false

                            if (activeIndex === 0) {
                                noPrev = true
                            }

                            if (activeIndex === queuePopupList.length - 1) {
                                noNext = true
                            }
                        }
                    }

                    return (
                        <div className="modal-call">
                            <div onClick={() => this.handleCloseModal(true)} className="call-close">
                                Batalkan Panggilan <span>x</span>
                            </div>
                            <Carousel
                                activeIndex={activeIndex}
                                next={this.next}
                                previous={this.previous}
                                interval={false}
                            >
                                {this.slides()}
                                <div>
                                    <div
                                        className={
                                            `carousel-control carousel-control-prev${noPrev ? ' disabled' : ''}`
                                        }
                                        onClick={noPrev
                                            ? () => { }
                                            : this.previous}
                                    >
                                        <i className="fas fa-chevron-left" />
                                        Antrian sebelumnya
                                    </div>
                                    <div
                                        className={
                                            `carousel-control carousel-control-next${noNext ? ' disabled' : ''}`
                                        }
                                        onClick={noNext
                                            ? () => { }
                                            : this.next}
                                    >
                                        Antrian sesudahnya
                                        <i className="fas fa-chevron-right" />
                                    </div>
                                </div>
                            </Carousel>
                            <div className="action">
                                <Button
                                    onClick={() => this.handleCall()}
                                    disabled={loadingCall}
                                    className={loadingCall ? 'disabled' : ''}
                                >
                                    {loadingCall
                                        ? (
                                            <BeatLoader
                                                sizeUnit={"px"}
                                                size={8}
                                                color={'#fff'}
                                                loading={loadingCall}
                                            />
                                        )
                                        : 'Hadir'}
                                </Button>
                            </div>
                        </div>
                    )
                }
                default:
                    return null
            }
        }
    }

    render() {
        let selectedTab = '';
        const {
            info,
            queue,
            activeTab,
            tabs,
            isModalOpen,
            modalData,
            loading,
        } = this.state

        if (activeTab === '' && queue.confirmed && queue.confirmed.data.length > 0) {
            selectedTab = 'confirmed';
        } else if (activeTab === '' && queue.unconfirmed && queue.unconfirmed.data.length > 0) {
            selectedTab = 'unconfirmed';
        } else if (activeTab === '' && queue.completed && queue.completed.data.length > 0) {
            selectedTab = 'completed';
        } else {
            selectedTab = 'confirmed';
        }
        const setActiveTab = activeTab !== '' ? activeTab : selectedTab

        return (
            <AccessPermission {...this.props} module="manage_doctor">
                <MasterTemplate
                    {...this.props}
                    customTitleWizard={!isEmpty(info) ? `${info.doctor.title || ''} ${info.doctor.name}` : 'Loading...'}
                    backUrl="/dashboardhospital"
                    wizard={true}
                    breadcrumbData=""
                >
                    <Container className="queue-wrapper">
                        <Row className="infos">
                            <Col className="info">
                                <div className="title">Hari</div>
                                <div className="desc">{info.date || '-'}</div>
                            </Col>
                            <Col className="info">
                                <div className="title">Waktu</div>
                                <div className="desc">
                                    {info.time
                                        ? `${info.time.start} - ${info.time.end}`
                                        : '-'}
                                </div>
                            </Col>
                            <Col sm="2" className="info">
                                <div className="title">Kuota</div>
                                <div className="desc">
                                    <NumberFormat
                                        value={info.quota}
                                        displayType={'text'}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'} />
                                </div>
                            </Col>
                        </Row>
                        <Row className="queue">
                            <Col>
                                <Nav tabs>
                                    {tabs.map(item => {
                                        const count = queue[item.value]

                                        return (
                                            <NavItem key={item.value}>
                                                <NavLink
                                                    className={setActiveTab === item.value ? 'active' : ''}
                                                    onClick={() => { this.toggleTab(item.value); }}
                                                >
                                                    {item.label}
                                                    <div className="tab-badge">
                                                        {count ? count.total : 0}
                                                    </div>
                                                </NavLink>
                                            </NavItem>
                                        )
                                    })}
                                    <Button
                                        color="link"
                                        className={`reload${loading ? ' sync' : ''}`}
                                        onClick={() => this.loadQueue()}
                                    >
                                        <i className="fas fa-sync" />
                                    </Button>
                                </Nav>
                                <TabContent activeTab={setActiveTab}>
                                    <TabPane tabId="unconfirmed">
                                        <Row>
                                            <Col sm="12">
                                                {queue.unconfirmed && queue.unconfirmed.data.length > 0
                                                    ? queue.unconfirmed.data.map(item => (
                                                        <div className="list" key={item.id}>
                                                            <div className="info">
                                                                <div className="no">{item.book_no}</div>
                                                                <div className="name">{item.patient_name}</div>
                                                                <div className="time">{item.arrival_time}</div>
                                                            </div>
                                                            <div className="action">
                                                                <Button onClick={() => this.handleOpenModal(item)}>
                                                                    Konfirmasi Hadir
                                                            </Button>
                                                            </div>
                                                        </div>
                                                    ))
                                                    : (
                                                        <div className="no-list">
                                                            Tidak ada pasien
                                                        </div>
                                                    )}
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="confirmed">
                                        <Row>
                                            <Col sm="12">
                                                {queue.confirmed && queue.confirmed.data.length > 0
                                                    ? queue.confirmed.data.map(item => (
                                                        <div className="list" key={item.id}>
                                                            <div className="info">
                                                                <div className="no">{item.book_no}</div>
                                                                <div className="name">{item.patient_name}</div>
                                                                <div className="time">{item.arrival_time}</div>
                                                            </div>
                                                            <div className="action">
                                                                <Button onClick={() => this.loadDataConfirm(item)}>
                                                                    Panggil
                                                            </Button>
                                                            </div>
                                                        </div>
                                                    ))
                                                    : (
                                                        <div className="no-list">
                                                            Tidak ada pasien
                                                        </div>
                                                    )}
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="completed">
                                        <Row>
                                            <Col sm="12">
                                                {queue.completed && queue.completed.data.length > 0
                                                    ? queue.completed.data.map(item => (
                                                        <div className="list" key={item.id}>
                                                            <div className="info">
                                                                <div className="no">{item.book_no}</div>
                                                                <div className="name">{item.patient_name}</div>
                                                                <div className="time right">{item.arrival_time}</div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    : (
                                                        <div className="no-list">
                                                            Tidak ada pasien
                                                        </div>
                                                    )}
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </Container>
                    <MasterModal
                        className="master-modal"
                        overlayClassName="master-modal-admin-overlay"
                        isOpen={isModalOpen}
                        ModalData={modalData}
                        handleClose={this.handleCloseModal}
                        handleSubmit={this.handleSubmitModal}>
                        {this.parseModal(modalData)}
                    </MasterModal>
                </MasterTemplate >
            </AccessPermission>
        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(Queue)
