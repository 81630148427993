import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

class PageNotFound extends Component
{
    render() {
        return (
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404">
                        <h1>404</h1>
                    </div>
                    <h2>Halaman yang anda cari tidak ditemukkan</h2>
                    <NavLink className="btn btn-primary" to="/">Kembali</NavLink>
                </div>
            </div>
    
        )
    }
}

export default PageNotFound