import axios, { axiosMaster, axiosSuperadmin } from './MasterConfig'

const axiosM = axiosMaster()
const axiosS = axiosSuperadmin()
const groupUrl = "user"
const appointmentUrl = "appointment"

export default class UserApi {
    static get = (hospitalId, page, pageSize, sortProperties, sortValue, filterData) => axios.get(`/${appointmentUrl}/list?id_hospital=${hospitalId}&page=${page}&sort=${sortProperties}&order=${sortValue}&limit=${pageSize}&name=${filterData}`)
    static option = (show) => axios.get(`/${groupUrl}/option?show=${show}`)
    static show = (id, withPermission) => axios.get(`/${groupUrl}/getwithaccess/${id}`)
    static post = (data) => axios.post(`/${groupUrl}/save`, data)
    static delete = (id) => axios.delete(`/${groupUrl}/delete/${id}`)
    static update = (id, data) => axios.put(`/${groupUrl}/save/${id}`, data)
    static bulkDelete = (data) => axios.post(`/${groupUrl}/bulkdelete`, data)
    static bulkDownload = (data) => axios.post(`/${groupUrl}/download`, data)
    static polyclinic = () => axios.get(`/${groupUrl}/getpoly`)
    static role = () => axios.get(`/${groupUrl}/getrole`)
    static access = (id) => axios.get(`/${groupUrl}/getaccess/${id}`)
    static fix = (data) => axiosM.post('/account/fix', data)
    static testingEmail = (data) => axiosS.post('/email', data)
}