import React from 'react'
import ArrowDown from '../../../../themes/v1/img/dropdown.png'

class Filter extends React.Component{
    constructor(){
        super()
        this.handleClick = this.handleClick.bind(this)
        this.handleOutsideClick = this.handleOutsideClick.bind(this)
    }

    handleClick(e){
        if(!this.props.toggleMenu){
            document.addEventListener('click', this.handleOutsideClick, false);
        }else{
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.props.toggleAdd(e)
    }

    handleOutsideClick(e){
        if (this.node.contains(e.target)) {
            return
        }

        this.handleClick(e)
    }

    render(){
        return(
            <div className="middle-right" ref={ node => this.node = node }>
                <div className="wrapper-menu">
                    <button className="btn-dashboard-add"
                        onClick={ this.handleClick }
                    >
                        <span>Tambah</span>
                        <img src={ ArrowDown } alt="" />
                    </button>
                    { this.props.showDownloadMenu() }
                </div>
            </div>
        )
    }
}

export default Filter