import React from 'react'
import DatePicker from 'react-date-picker'

class SearchDate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    onKeyPress = (event) => {
        let maxLength = 2
        const inputType = event.target.getAttribute('name')
        const value = event.target.value

        if (inputType === 'year') {
            maxLength = 4
        }

        if (value.length >= maxLength) {
            event.preventDefault();
            return false;
        }

        return true;
    }

    render() {
        const { element } = this.props

        return (
            <div 
            className={`wrap-search-filter wizard-datepicker ${element.className}`}
            style={element.showLabel ? {display: 'flex'} : {}}
            >
                {element.showLabel
                    ? <div className="label">{element.title}</div>
                    : null}
                <DatePicker
                    calendarIcon={element.filterKeyword
                        ? null
                        : <i className="fas fa-calendar-alt" />}
                    clearIcon={!element.filterKeyword
                        ? null
                        : <i className="fas fa-times" />}
                    onChange={val => element.handlerChange(element.actionType, val, element.type)}
                    onKeyPress={this.onKeyPress}
                    prev2Label={null}
                    next2Label={null}
                    locale="id-id"
                    autocomplete="off"
                    value={element.filterKeyword}
                    isOpen={false}
                    maxDetail="month"
                    minDetail="year"
                />
            </div>
        )
    }
}

export default SearchDate
