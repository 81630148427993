// import DashboardApi from './../../../../../services/apis/Dashboard/Api'
// import { handleToast, handleToastServiceError } from '../../../../../core/utils/UtilsToast'

const formHandler = {
    onPanelClose: function () {
        this.setState({
            isPaneOpen: false,
        })
    },
    onPanelOpen: function () {
        this.setState({
            isPaneOpen: true,
        })
    },
    buildForm: function () {
        this.TextName = this.TextInputFormView({
            label: 'Nama Peran',
            validator: 'required',
            name: 'role_name',
            placeholder: 'Isi nama peran',
            maxLength: 25,
            control: this.state.Form.controls.role_name
        })

        this.CheckboxRoleOptions = this.CheckBoxFormView({
            name: 'role_options',
            meta: {
                validator: '',
                options: this.state.roleOptions
            },
            control: this.state.Form.controls.role_options
        })
    },
    handleSubmit: async function (e) {
        e.preventDefault()

        if (this.state.isSubmit) {
            return
        }

        let formValue = Object.assign({}, this.state.Form.value)
        // let response

        console.log(formValue)

        // try {
        //     this.setState({
        //         isSubmit: true,
        //     })
        //     response = await DashboardApi.post(JSON.stringify(formValue))
        // } catch (e) {
        //     handleToastServiceError(e)
        //     return
        // }

        // if (response.data.code === 200) {
        //     const { history } = this.props
        //     history.push('/dashboard')
        // }

        // handleToast(response.data.message)
    }
}

export default formHandler