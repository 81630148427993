
import React, { Component } from 'react'
import Select from 'react-select'

class WidgetSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const {
            label,
            data,
            period,
            periodSelected,
            onChange,
            className,
        } = this.props
        const periodLabelSelected = period.find(option => option.value === periodSelected).label;

        return (
            <div className={`box-item ${className || ''}`}>
                <div className="box-container">
                    <div className="card-total">
                        <div className="box-top">
                            <div className="title-wrapper">
                                <span className="box-title">{label}</span>
                                <div
                                    style={
                                        label === 'Dokter'
                                            ? { minWidth: 125, width: `${11 * periodLabelSelected.length}px` }
                                            : {}
                                    }>
                                    <Select
                                        className="dropdown-filter select-period"
                                        classNamePrefix="react-select"
                                        options={period}
                                        value={period.filter(option => option.value === periodSelected)}
                                        onChange={value => onChange(value)}
                                    />
                                </div>
                            </div>
                            <p>{periodLabelSelected}</p>
                        </div>
                        <div className="box-bottom">
                            {data.map((item, i) => (
                                <div key={i} className="info-wrapper">
                                    <div className="count">{item.count}</div>
                                    <div className="label">{item.label}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WidgetSummary
