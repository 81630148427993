
import icoCalendar from '../../themes/v1/img/layout/icon/calendar.png'
import icoSearch from '../../themes/v1/img/layout/icon/search.png'
import icoPlus from '../../themes/v1/img/layout/icon/plus.png'
import icoLeft from '../../themes/v1/img/layout/icon/prev.png'
import icoRight from '../../themes/v1/img/layout/icon/next.png'
import icoClose from '../../themes/v1/img/layout/icon/close.png'
import icoCamera from '../../themes/v1/img/layout/icon/camera.png'
import icoDown from '../../themes/v1/img/layout/icon/arrow-down.png'
import icoTrashBin from '../../themes/v1/img/layout/icon/trash-bin.png'
import icoPrev from '../../themes/v1/img/layout/icon/arrow-prev.png'

/* menu dashboard */
import LogoLogin from '../../themes/v1/img/layout/logo/klikdokter.svg'
import dDashboard from '../../themes/v1/img/layout/icon/menu/dashboard.png'
import dDashboardh from '../../themes/v1/img/layout/icon/menu/dashboard-h.png'
import dAppointment from '../../themes/v1/img/layout/icon/menu/appointment.png'
import dAppointmenth from '../../themes/v1/img/layout/icon/menu/appointment-h.png'
import dDoctor from '../../themes/v1/img/layout/icon/menu/doctor.png'
import dDoctorh from '../../themes/v1/img/layout/icon/menu/doctor-h.png'
import dPatient from '../../themes/v1/img/layout/icon/menu/patient.png'
import dPatienth from '../../themes/v1/img/layout/icon/menu/patient-h.png'
import dUser from '../../themes/v1/img/layout/icon/menu/patient.png'
import dUserh from '../../themes/v1/img/layout/icon/menu/patient-h.png'
import dMedicalRecord from '../../themes/v1/img/layout/icon/menu/medical_record.png'
import dMedicalRecordh from '../../themes/v1/img/layout/icon/menu/medical_record-h.png'
import dRapid from '../../themes/v1/img/layout/icon/menu/rapid.png'
import dRapidh from '../../themes/v1/img/layout/icon/menu/rapid-h.png'
import dSubscription from '../../themes/v1/img/layout/icon/menu/subscription.png'
import dSubscriptionh from '../../themes/v1/img/layout/icon/menu/subscription-h.png'
import dBill from '../../themes/v1/img/layout/icon/menu/bill.png'
import dBillh from '../../themes/v1/img/layout/icon/menu/bill-h.png'
import dSetting from '../../themes/v1/img/layout/icon/menu/setting.png'
import dSettingh from '../../themes/v1/img/layout/icon/menu/setting-h.png'

const Icons = {
    calendar: icoCalendar,
    search: icoSearch,
    plus: icoPlus,
    left: icoLeft,
    prev: icoPrev,
    right: icoRight,
    close: icoClose,
    camera: icoCamera,
    TrashBin: icoTrashBin,
    down: icoDown,
    logoLogin: LogoLogin,
    dashboardMenu: {
        dashboard: {
            normal: dDashboard,
            hover: dDashboardh
        },
        appointment: {
            normal: dAppointment,
            hover: dAppointmenth
        },
        doctor: {
            normal: dDoctor,
            hover: dDoctorh
        },
        patient: {
            normal: dPatient,
            hover: dPatienth
        },
        setting: {
            normal: dSetting,
            hover: dSettingh
        },
        md: {
            normal: dMedicalRecord,
            hover: dMedicalRecordh
        },
        bill: {
            normal: dBill,
            hover: dBillh
        }
    },
    sAdmindashboardMenu: {
        dashboard: {
            normal: dDashboard,
            hover: dDashboardh
        },
        doctor: {
            normal: dDoctor,
            hover: dDoctorh
        },
        diagnosis: {
            normal: dMedicalRecord,
            hover: dMedicalRecordh
        },
        services: {
            normal: dAppointment,
            hover: dAppointmenth
        },
        setting: {
            normal: dSetting,
            hover: dSettingh
        },
        patient: {
            normal: dPatient,
            hover: dPatienth
        },
        medical: {
            normal: dMedicalRecord,
            hover: dMedicalRecordh
        },
        rapid: {
            normal: dRapid,
            hover: dRapidh
        },
        subscription: {
            normal: dSubscription,
            hover: dSubscriptionh
        },
        user: {
            normal: dUser,
            hover: dUserh
        }
    }
}

export default Icons