import { isEmpty, isArray } from 'lodash'

import Api from '../../../services/apis/AccountApi'
import {
    handleToast,
    handleToastServiceError
} from '../../../core/utils/UtilsToast'

const FormHandler = {
    buildForm() {
        this.passwordField = this.TextInputPasswordFormView({
            validator: 'required',
            label: 'Password Lama',
            name: 'password_old',
            placeholder: 'Password Lama',
            control: this.state.Form.controls.password_old,
            togglepass: this.showPass,
        })

        this.passwordNewField = this.TextInputPasswordFormView({
            validator: 'required',
            label: 'Password Baru',
            name: 'password_new',
            placeholder: 'Password Baru',
            control: this.state.Form.controls.password_new,
            callback: this.checkPass,
            togglepass: this.showPass,
        })

        this.passwordNewConfirmField = this.TextInputPasswordFormView({
            validator: 'required',
            label: 'Konfirmasi Password Baru',
            name: 'password_new_confirm',
            placeholder: 'Konfirmasi Password Baru',
            control: this.state.Form.controls.password_new_confirm,
            callback: this.checkPassConfirm,
            togglepass: this.showPass,
        })
    },
    checkPass(me, name) {
        const passNew = me.state.Form.value[name]
        const passNewConfirm = me.state.Form.value.password_new_confirm

        me.setState({
            minPassValid: !(passNew.length >= 6),
            passNotMatch: passNew !== passNewConfirm,
        })
    },
    checkPassConfirm(me, name) {
        const passNew = me.state.Form.value.password_new
        const passNewConfirm = me.state.Form.value[name]

        me.setState({ passNotMatch: passNew !== passNewConfirm })
    },
    showPass(me, name) {
        const { Form } = me.state

        Form.patchValue({ [name]: Form.value[name] })
        me.setState((prevState) => ({
            [`${name}_show_pass`]: !prevState[`${name}_show_pass`]
        }))
    },
    handleSubmit: async function (e) {
        e.preventDefault();

        this.setState({ loading: true })

        let response = {}
        const formValue = Object.assign({}, this.state.Form.value)

        try {
            response = await Api.changePass(formValue)

            handleToast(response.data.message)
            this.setState({ loading: false })
            this.state.Form.reset()
        } catch (e) {
            let message = e.message
            const errData = e.response

            if (!isEmpty(errData) && errData.data !== '') {
                message = errData.data.message
                if (!isArray(errData.data.data)) {
                    message = Object.keys(errData.data.data).map(k => errData.data.data[k]).join('\n')
                }
            }

            this.setState({ loading: false })
            handleToastServiceError({ message })
        }
    }
}

export default FormHandler
