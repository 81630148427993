import React, { Component } from 'react'
import { FieldGroup, FormBuilder, Validators } from 'react-reactive-form'
import ImageUploadForm from '../../../core/utils/ImageUploadForm'
import SubmitButtonForm from '../../../core/utils/SubmitButtonForm'
import TextInputForm from '../../../core/utils/wizard/TextInputForm'
import DropDownForm from '../../../core/utils/wizard/DropDownForm'
import TextInputEmailForm from '../../../core/utils/wizard/TextInputEmailForm'
import iconClose from '../../../themes/v1/img/icon-close.png'
import iconPlus from '../../../themes/v1/img/rectangle.png'
import formHandler from '../handler/Form'

class Form extends Component {
    constructor(props) {
        super(props)

        Object.assign(this, ImageUploadForm)
        Object.assign(this, TextInputForm)
        Object.assign(this, DropDownForm)
        Object.assign(this, TextInputEmailForm)
        Object.assign(this, formHandler)
        this.state = {
            name: "",
            picEmail: "",
            picName: "",
            typeOption: props.institutionList,
            institutionType: [],
            file: [],
            Form: FormBuilder.group({
                id: [""],
                code: ["", Validators.required],
                name: ["", Validators.required],
                pic_name: ["", Validators.required],
                pic_email: [
                    "", [Validators.required, Validators.email]
                ],
                institution_type: [{}, Validators.required],
                certificate: [""],
            }),
            isSubmit: false,
        }

        this.handleUpload = this.handleUpload.bind(this)
        this.TextInputFormView = this.TextInputFormView.bind(this)
        this.TextInputEmailFormView = this.TextInputEmailFormView.bind(this)
        this.DropDownFormView = this.DropDownFormView.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

        this.buildForm()
    }

    render() {
        const { file } = this.state

        return (
            <div style={{ position: 'relative', width: 'fit-content', margin: '34px auto' }}>
                <img
                    className="form-close"
                    alt=""
                    src={iconClose}
                    onClick={e => this.handleClose(e)} />
                <div className="dashboard-form-container wizard-form doctor-form">
                    <p className="form-title">Tambah Rumah Sakit</p>
                    <p className="form-subtitle">Isi data secara manual</p>
                    <FieldGroup
                        control={this.state.Form}
                        strict={false}
                        render={({ get, invalid }) => {
                            return (
                                <form onSubmit={() => this.handleSubmit} className="form-hospital">
                                    {this.TextCode}
                                    {this.TextName}
                                    {this.DropDownType}
                                    {this.TextPicName}
                                    {this.TextEmail}
                                    {this.state.Form.value.institution_type.value === 2 ? (
                                        <div className="upload-cert-wrapper">
                                            <ImageUploadForm
                                                ref="child"
                                                onUpload={this.handleUpload}
                                                desc="Tambah Sertifikat"
                                                icon={iconPlus}
                                                maxsize="2500000"
                                                image={file}
                                                source="Sertifikat"
                                                current={this.state.Form.controls.certificate}
                                            />
                                        </div>
                                    ) : null}

                                    <SubmitButtonForm invalid={invalid}
                                        showCancel={false}
                                        submitText={"Tambah"}
                                        onSubmit={(e) => this.handleSubmit(e)}
                                    />
                                </form>
                            )
                        }}
                    />
                </div>
            </div>
        )
    }
}

export default Form