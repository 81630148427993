import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'
import { Button } from 'reactstrap';

class SearchReset extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { loading, tooltip, handlerReset } = this.props

        return <>
            <ReactTooltip className="cinnamon-tooltip custom-action" effect="solid" />
            <Button
                color="link"
                className={`reset-filter-grid${loading ? ' loading' : ''}`}
                onClick={handlerReset}
                data-tip={tooltip}
            >
                <i className="fas fa-sync" />
            </Button>
        </>
    }
}

export default SearchReset
