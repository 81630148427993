import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

import icoArrow from '../../../themes/v1/img/ico-arrow-down.png'
import DashboardApi from '../../../services/apis/Dashboard/Api'
import { handleToast, handleToastServiceError } from '../../../core/utils/UtilsToast'

class AdminFilterHospital extends Component{
    constructor(props) {
        super(props)

        this.state = {
            toggleBox: false,
            data: [],
            currentHospitalNameState: props.currentHospitalName
        }

        this.handleClick = this.handleClick.bind(this)
        this.handleArrowClick = this.handleArrowClick.bind(this)
        this.showSearchBox = this.showSearchBox.bind(this)
        this.fetch = this.fetch.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        const { currentHospital } = this.props

        if (currentHospital !== nextProps.currentHospital) {
            this.setState({ currentHospitalNameState: nextProps.currentHospitalName })
        }
    }

    componentDidMount(){
        this.fetch(false, false)
    }

    handleClick(e){
        const { history } = this.props
        history.push('/dashboard')
    }

    handleArrowClick(e) {
        e.preventDefault()

        this.setState((prevState) => {
            return {
                toggleBox: !prevState.toggleBox,
            }
        })
    }

    handleSelect(e, id, name, verified) {
        this.handleArrowClick(e);
        this.props.onAdminChangeHospital(id, name, verified)
    }

    showSearchBox() {
        const { toggleBox, data } = this.state
        const { currentHospital } = this.props

        if (toggleBox) {
            return (
                <div>
                    <div className="close-outside" onClick={e => this.handleArrowClick(e)}/>
                    <div className="header-search">
                        <input
                            type="text"
                            placeholder="Cari Rumah Sakit / Klinik"
                            onKeyDown={e => this.handleSearch(e)}
                            autoFocus
                        />
                        <span className="header-title">RS terakhir didaftarkan</span>
                        <div className="search-result">
                            {data.map((item, index) => (
                                <div className="search-result-box" key={index}>
                                    <div className="search-logo">
                                        {
                                            (item.logo)
                                                ? <img src={item.logo} alt="" />
                                                : <div className="placeholder"></div>
                                        }
                                    </div>
                                    <div className="search-middle">
                                        <span>{item.name}</span>
                                        <span>{item.kota}</span>
                                    </div>
                                    {item.id !== currentHospital ? (
                                        <div className="search-button">
                                            <button
                                                className="btn-select"
                                                onClick={e => this.handleSelect(e, item.id, item.name, item.verified)}
                                            >
                                                <span>Pilih</span>
                                            </button>
                                        </div>
                                    ) : null}
                                </div>
                            )
                            )}
                        </div>
                    </div>
                </div>
            )
        }
    }

    fetch(theFilter, theLimit){
        DashboardApi.search(theLimit, theFilter).then((res) => {
            if ( res.status !== 200 ){
                handleToast('Something went wrong')
                return
            }

            if(res.data.data.length === 0)
                handleToast('Data tidak ditemukan')

            this.setState({
                data: res.data.data,
            })
        }, (e) => { handleToastServiceError(e) })
    }

    handleSearch(e){
        if(e.key === 'Enter'){
            const filter = e.target.value
            this.fetch(filter, false)
        }
    }

    render() {
        const { currentHospitalNameState } = this.state

        return (
            <div className="admin-navbar-header">
                <FontAwesomeIcon
                    icon={faHome}
                    onClick={e => this.handleClick(e)}
                    className="admin-header-icon" />
                <span onClick={e => this.handleArrowClick(e)}>{currentHospitalNameState}</span>
                <button
                    className="btn btn-filter-hospital"
                    onClick={e => this.handleArrowClick(e)}
                >
                    <img src={icoArrow} alt="" />
                </button>
                {this.showSearchBox()}
            </div>
        )
    }
}

export default AdminFilterHospital