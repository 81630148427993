
import React, { Component } from 'react'

class WidgetInfoRS extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const {
            data,
            className,
        } = this.props
        const review = data.satisfied || {}

        return (
            <div className={`box-item ${className || ''}`}>
                <div className="box-container">
                    <div className="card-total">
                        <div className="box-top">
                            {data.day
                                ? (
                                    <div className="time">{`${data.day}, ${data.date}`}</div>
                                ) : null}
                            <div className="title-wrapper">
                                <span className="box-title">{data.name}</span>
                                <span className="box-address">{data.city}</span>
                            </div>
                            <div style={{ display: 'flex', marginTop: '6px' }}>
                                <p>{data.type}</p>
                                {data.bpjs
                                    ? (
                                        <div className="badge-label">
                                            <span className="badge badge-pill badge-primary">
                                                BJPS
                                            </span>
                                        </div>
                                    )
                                    : null}
                            </div>
                            {review.total > 0
                                ? <div className="review">{`Berdasarkan ${review.total} ulasan, ${review.yes} merasa puas`}</div>
                                : <div className="review">Belum ada ulasan</div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WidgetInfoRS
