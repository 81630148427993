import React from 'react'
import ReactTable from 'react-table'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import { ContextMenuProvider } from 'react-contexify'

import MasterGrid from '../../../components/grid/MasterGrid'
import GridHandler from '../handler/GridHandler'

const CheckboxTable = checkboxHOC(ReactTable)

class Grid extends MasterGrid {
    constructor(props) {
        super(props)

        Object.assign(this, GridHandler)

        this.clickable = false
        this.filterable = false
        this.selectedTableStatus = false
        this.gridColumn = this.gridColumn.bind(this)
        this.gridMapping = (this.selectedTableStatus) ? CheckboxTable : ReactTable
        this.hidePagination = false
        this.page = 0
        this.pageSize = 20
        this.selectedMax = 10
        this.selected = []
        this.filtered = []
        this.sorted = []
        this.defaultSorted = []
        this.keyTable = '_id'
        this.ignoreColumn = [
            '_id'
        ]

        /* own property */
        this.fetchData = this.fetchData.bind(this)
        this.extraProperties = {
            onFetchData: this.fetchData,
            filterAble: true
        }

        /* data */
        const loading = this.extraProperties.hasOwnProperty('onFetchData') ? true : false
        const data = this.dataFromRedux ? this.props.data : (loading ? [] : this.getData())
        const columns = this.getColumns(data)
        const finalColumn = this.gridColumn(ContextMenuProvider, columns)
        const pages = loading ? null : 1

        this.customHandleFilter = this.customHandleFilter.bind(this)

        this.state = {
            loading: loading,
            data,
            columns: finalColumn,
            pages: pages,
            rows: 1,
            selection: [],
            deleted: [],
            filtered: [],
            filterStatus: [
                {
                    label: 'Semua Status',
                    value: '',
                },
                {
                    label: 'Dibayar',
                    value: true,
                },
                {
                    label: 'Belum Dibayar',
                    value: false,
                },
            ],
            disableHighlight: true,
            selectAll: false,
            headerFilter: {
                invoice_status: null,
            },
        }

        this.currentHospital = this.props.currentHospital
    }

    componentWillReceiveProps(nextProps) {
        const { currentHospital } = this.props

        if (currentHospital !== nextProps.currentHospital) {
            this.currentHospital = nextProps.currentHospital

            this.fetchData({
                page: 0,
                pageSize: this.pageSize,
                filtered: [],
                sorted: []
            })

            this.setState({
                headerFilter: {
                    invoice_status: null,
                }
            })
        }
    }

    customHandleFilter(action, value) {
        this.page = 0

        const { headerFilter } = this.state
        const filtered = this.filteredTemp
        let setFiltered = filtered

        if (action === 'invoice_status') {
            const getExist = filtered.find(value => value.id === action)

            if (getExist) {
                setFiltered = filtered.filter(value => value.id !== action)
            }

            if (value.value === 0) {
                setFiltered = filtered.filter(value => value.id !== action)
            } else {
                setFiltered.push({
                    id: action,
                    value: value.value,
                })
            }

            this.filteredTemp = setFiltered
            this.setState({
                headerFilter: {
                    ...headerFilter,
                    [action]: value,
                }
            })

            this.fetchData({
                page: this.page,
                pageSize: this.pageSize,
                sorted: this.sorted,
                filtered: setFiltered,
            })
        }
    }

    render() {
        const { filterStatus, headerFilter } = this.state
        const { history } = this.props

        this.leftAction = [
            {
                type: 'select',
                title: 'Semua Status',
                actionType: 'invoice_status',
                filterOpt: filterStatus,
                selectedOpt: headerFilter.invoice_status,
                handlerChangeOpt: this.customHandleFilter,
                className: 'rounded single'
            },
        ]

        return (
            <section className="tables with-top-filter no-paging-top">
                <button
                    className="add"
                    onClick={() => history.push('/billing/create')}
                    style={{ width: 130 }}
                >
                    Tambah
                </button>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 cn-table-container wrap-text">
                            <div className="card">
                                {super.render()}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Grid
