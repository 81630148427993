import React from 'react'
import Chance from 'chance'
import dateFns from 'date-fns'
import IndonesianLocale from 'date-fns/locale/id'
import qs from 'query-string'

// import Api from '../../../../../services/apis/UserMApi'
import ApiAppointment from '../../../../../services/apis/DaftarAppointmentApi'
import HospitalApi from '../../../../../services/apis/HospitalApi'
import HospitalListApi from '../../../../../services/apis/Hospital/List'
import DoctorApi from '../../../../../services/apis/doctor/DoctorApi'
import { handleToast, handleToastServiceError } from '../../../../../core/utils/UtilsToast'
import Api from '../../../../../services/apis/Appointment/List'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const chance = new Chance()

const GridHandler = {
    onPanelOpen(id) {
        if (id) {
            this.getDetail(id)
        } else {
            this.getHospital().then((h) => {
                this.setState({
                    isPaneOpen: true,
                    dataEdit: null,
                    panelData: { headTitle: <div>Tambah User</div> },
                })
            })
        }
    },
    handleOpenModal(modalData) {
        this.setState({
            isModalOpen: true,
            modalData,
            forceActive: 'false',
        })
    },
    handleCloseModal(isSubmit) {
        this.setState({
            isModalOpen: false,
            modalData: {},
            forceActive: isSubmit === true
                ? 'false'
                : 'true',
        })
        this.state.Form.reset()
    },
    onDetailClose() {
        this.setState({
            isDetailOpen: false,
            detailData: {},
            dataAppointment:{},
        })
    },    
    
    parseDate: function (row) {
        const dateFormat = 'dddd, D MMM YYYY'
        let strDate = ''
        let timeStart = ''
        let timeEnd = ''
        if (row.book_date) {
            strDate = dateFns.format(row.book_date, dateFormat, { locale: IndonesianLocale })
        }

        if (row.schedule_time_start) {
            timeStart = `${row.schedule_time_start.slice(0, -3)}`
        }

        if (row.practice_schedule) {
            timeEnd = ` - ${row.practice_schedule.split(' - ')[1].slice(0, -3)} WIB`
        }

        return (
            <div>
                {strDate}
                <br />
                {timeStart} {timeEnd}
            </div>)
    },
    parseTime(row) {
        return (
            <div>
                {dateFns.format(new Date(row.book_date), 'dddd', { locale: IndonesianLocale })}, {dateFns.format(new Date(row.book_date), 'DD/MM/YYYY', { locale: IndonesianLocale })} <br />
                {row.arrival_time} WIB
            </div>)
    },
    parseTimeNew(row) {
        let newData = row.order_date.split(",")
        let tanggalCustom = newData[1]
        let tanggal = tanggalCustom.split("/")
        let tgl = tanggal[0].replace (" ", "")
        let result = tanggal[2]+"-"+tanggal[1]+"-"+tgl
        let jam = newData[2].substring(0,6);
        return (
            <div>
                {dateFns.format(new Date(result), 'dddd', { locale: IndonesianLocale })}, {dateFns.format(new Date(result), 'DD/MM/YYYY', { locale: IndonesianLocale })} <br />
                {jam} WIB
            </div>)
    },

    gridColumn(ContextMenuProvider) {
        return [
            {
                Header: 'Booking',
                accessor: 'ref_no',
                sortable: true,
                width: 240,
                headerClassName: '-sort',
                Cell: (row) => {
                    let type = row.original.book_type_label
                    return (
                        <div
                            className="grid-link row-float"
                            onClick={() => this.handleOpenSlide(row.original)}
                        >
                            <strong>{row.original.ref_no}</strong>
                            <p>{type ? type : '-'}</p>
                        </div>
                    )
                }
            },
            {
                Header:  () => {
                    return (
                        <div className="row-float">
                            {'Tanggal Transaksi'}
                        </div>
                    )
                },
                accessor: 'order_date',
                sortable: true,
                wiidth: 150,
                headerClassName: '-sort',
                Cell: (row) => {
                    return (<div className="row-book-date">{this.parseTimeNew(row.original)}</div>)
                    // return (<div className="row-book-date">{row.original.order_date}</div>)
                },
            },
            {
                Header: () => {
                    return (
                        <div className="row-float">
                            {'Tanggal Booking'}
                        </div>
                    )
                },
                accessor: 'booking_schedule',
                sortable: true,
                width: 150,
                headerClassName: '-sort',
                Cell: (row) => {
                    return (<div className="row-book-date">{this.parseTime(row.original)}</div>)
                    // return (<div className="row-book-date">{row.original.booking_schedule}</div>)
                },
            },
            {
                Header: () => {
                    return (
                        <div className="row-float">
                            {'Dokter / Layanan'}
                        </div>
                    )
                },
                accessor: 'name',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return (
                        <div className="row-float">
                            <strong>{row.original.name}</strong>
                            <p>{row.original.hospital_name ? row.original.hospital_name : '-'}</p>
                        </div>
                    )
                },
            },
            {
                Header: 'Pasien',
                accessor: 'patient_name',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return (
                        <div className="row-float">
                            <strong>{row.original.patient_name}</strong>
                        </div>
                    )
                },
            },
            {
                Header:  () => {
                    return (
                        <div className="row-float">
                            {'Voucher / Diskon'}
                        </div>
                    )
                },
                accessor: 'voucher',
                maxWidth: 130,
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    let voucher = row.original.voucher
                    let diskon = this.toRp(row.original.discount)
                    return (
                        <div>
                            <strong>{voucher ? voucher : '-'}</strong>
                            <p>{diskon ? diskon : '-'}</p>
                        </div>
                    )
                },
            },
            {
                Header: 'Pembayaran',
                accessor: 'payment_method',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    let price = this.toRp(row.original.payment_price)
                    return (
                        <div>
                            <strong>{row.original.payment_method}</strong>
                            <p>{price ? price : '-'}</p>
                        </div>)
                },
            },
            {
                Header: 'Status',
                accessor: 'status_label',
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    const label = row.original.status_label
                    const badgeStyle = row.original.status_label === 'Buat Janji Disetujui'
                        ? 'badge-success'
                        : row.original.status_label === 'Pembayaran Berhasil'? 'badge-primary'
                        : row.original.status_label === 'Buat Janji Selesai'? 'badge-primary':
                        'badge-danger'

                    return (
                        <div className="badge-label">
                            <span className={`badge badge-pill ${badgeStyle}`}>
                                <div className="row-float">
                                {label}
                                </div>
                            </span>
                        </div>
                    )
                },
            },
            {
                Header: 'Action',
                accessor: 'status_label',
                maxWidth: 60,
                sortable: true,
                headerClassName: '-sort',
                Cell: (row) => {
                    return (
                        <button
                            className="action-row"
                            onClick={() => this.handleOpenSlide(row.original)}
                        >
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </button>
                    )
                },
            },

        ]
    },
    fetchData: async function (state) {
        this.setState({ loading: true })
        this.page = state.page
        this.pageSize = state.pageSize

        let res = {}
        let fArray = {}
        let sort_property = ''
        let sort_value = ''

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'DESC' : 'ASC'
            this.sorted = state.sorted
        }

        if (state.filtered && state.filtered.length > 0) {
            state.filtered.map(val => {
                if (val.value !== '') {
                    fArray[val.id] = val.value
                }
                return true
            })
        }

        const filterData = JSON.stringify(fArray)

        try {
            res = await ApiAppointment.list(
                this.page + 1,
                this.pageSize,
                sort_property,
                sort_value,
                filterData,
            )
        } catch (e) {
            this.setState({ loading: false })

            handleToastServiceError(e)
            return
        }

        const {
            status,
            data: resData,
        } = res

        if (status === 200) {
            const newRows = resData.data.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })

            this.setState({
                loading: false,
                data: newRows,
                pages: resData.data.total_page,
                rows: resData.data.total_rows,
            })
        } else {
            this.setState({ loading: false })
        }
    },
    getFilter: async function () {
        try {
            const response = await ApiAppointment.getFilter()
            const { data } = response.data
            const filterList = data

            this.setState({ filterList })
        } catch (e) {
            handleToastServiceError(e)
        }
    },
    handleOpenSlide(data) {
        this.getDetail(data)
        this.setState({
            isDetailOpen: true,
            dataAppointment: data
        })
    },
    getDetail(data) {
        Api.detail(data.id, data.id_hospital).then(res => {
            const {
                data: {
                    status,
                    data,
                    message,
                }
            } = res

            if (status) {
                this.setState({
                    isPaneOpen: true,
                    detailData: data
                })
            } else {
                handleToastServiceError({ message })
            }
        }, err => {
            handleToastServiceError(err)
        })
    },
    onPanelClose() {
        const { history, location } = this.props
        const params = qs.parse(location.search)

        if (params.id) {
            setTimeout(() => {
                history.push('/appointment/list')
            }, 300);
        }

        this.showNotif = false
        this.setState({
            isPaneOpen: false,
            openedAppointment: null,
            dataEdit: null,
            reasonSelected: '',
        })
    },
    getHospital: async function (keyword = '') {
        const { hospitalList } = this.state

        if (hospitalList.length > 0 && keyword === '') {
            return hospitalList
        }

        await HospitalApi.getHospital(keyword).then((res) => {
            const { data } = res.data
            if (res.status === 200) {
                this.setState({ hospitalList: data })
            }
        })
    },
    toRp(number) {
        if (number) {
            return `Rp ${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
        }

        return 'Rp 0 (Gratis)'
    },
    sendActivation: async function ({ hospital_id: hospitalId, user_type: userType, email }) {
        let response = {}
        let payload = userType === 'Dokter'
            ? { email }
            : {
                'id_hospital': hospitalId,
                'pic_email': email,
            }

        try {
            if (userType === 'Dokter') {
                response = await DoctorApi.reactivate(payload)
            } else {
                response = await HospitalListApi.reactivate(payload)
            }

            const { status, data } = response

            if (status === 200) {
                handleToast(data.message)
                this.handleCloseModal()
            }
        } catch (error) {
            handleToastServiceError(error)
            this.handleCloseModal()
        }
    },
}

export default GridHandler
