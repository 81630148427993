import { axiosBase } from './MasterConfig'

const axios = axiosBase()

class AreaApi {
    static provinsi = () => axios.get(`/lists/propinsi`)
    static kota = (propinsiId) => axios.get(`/lists/kota?id_propinsi=${propinsiId}`)
    static kecamatan = (kotaId) => axios.get(`/lists/kecamatan?id_kota=${kotaId}`)
    static kelurahan = (kecamatanId) => axios.get(`/lists/kelurahan?id_kecamatan=${kecamatanId}`)
}

export default AreaApi;