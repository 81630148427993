import React, { Component } from 'react'

class FormButtons extends Component{
    render() {
        if(!this.props.submitingForm){
            return (
                <div className="wrap-action-button">
                    <button 
                        type="submit" 
                        className={!this.props.invalid ? "btn btn-submit-wizard btn-primary": "btn btn-submit-wizard btn-secondary"}
                        onClick={(e) => this.props.handleSubmit(e)}
                        disabled={this.props.invalid}
                    >
                        Simpan Jadwal
                    </button>
                    <button type="button" className="cancel" onClick={this.props.toggleForm} >Batal</button>
                </div>
            )
        }

        return(<div className="wrap-action-button">Loading...</div>)
    }
}

export default FormButtons