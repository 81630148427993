import React, { Component } from 'react'

class Detail extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        const {
            data,
            // sendEmail,
            // loadingE,
        } = this.props

        return (
            <div className="m-detail-rapid">
                <h2>Informasi Pribadi</h2>
                <div className="info">
                    <div className="label">Nama</div>
                    <div className="content">{data.patient_name || '-'}</div>
                </div>
                <div className="info">
                    <div className="label">NIK</div>
                    <div className="content">
                        {data.patient_identity_type
                            ? `${data.patient_identity_type} - ${data.patient_identity_no}`
                            : '-'}
                    </div>
                </div>
                <div className="info">
                    <div className="label">Email</div>
                    <div className="content">{data.patient_email || '-'}</div>
                </div>
                <div className="info">
                    <div className="label">Telepon</div>
                    <div className="content">{data.patient_phone || '-'}</div>
                </div>
                <div className="info">
                    <div className="label">Kode Booking</div>
                    <div className="content">{data.book_code || '-'}</div>
                </div>
                <div className="info">
                    <div className="label">Lokasi</div>
                    <div className="content">{data.hospital_name || '-'}</div>
                </div>
                <div className="info">
                    <div className="label">Nama Dokter</div>
                    <div className="content">{data.doctor_name || '-'}</div>
                </div>
            </div>
        )
    }
}

export default Detail
