import Icons from '../../../../core/utils/Icons'
const icon = Icons.dashboardMenu
const Menu = {
    quickButton: true,
    menu: [
        {
            index: 'appointment',
            path: '/appointment/list',
            icon: icon.appointment.normal,
            iconHover: icon.appointment.hover,
            title: 'Appointment',
        },
        {
            index: 'doctor',
            path: '/doctor/list',
            icon: icon.doctor.normal,
            iconHover: icon.doctor.hover,
            title: 'Dokter / Tenaga Medis',
        },
        {
            index: 'patient',
            path: '/patient/list',
            icon: icon.patient.normal,
            iconHover: icon.patient.hover,
            title: 'Pasien',
        },
        {
            index: 'administration',
            path: null,
            icon: icon.setting.normal,
            iconHover: icon.setting.hover,
            title: 'Pengaturan',
            children: [
                {
                    index: 'administration',
                    path: '/administration/form-patient',
                    title: 'Formulir Pasien',
                }
            ]
        },
    ]
}

export default Menu