import React from 'react'

class ButtonUpload extends React.Component{
    render(){
        return(
            <div className="dashboard-submenu">
                <ul>
                    <li className="first">
                        <button className="btn-submenu" onClick={e => this.props.handleOpenModal(e, 'bulk_hospital')}>
                            Upload CSV
                        </button>
                    </li>
                    <li>
                        <button className="btn-submenu" onClick={e => this.props.handleOpenModal(e, 'add_hospital')}>
                            Tambah Satuan
                        </button>
                    </li>
                </ul>
            </div>
        )
    }
}

export default ButtonUpload