import { findIndex } from 'lodash'

import AppointmentApi from '../../../../services/apis/Appointment/List'
import HospitalApi from '../../../../services/apis/Hospital/List'
import { handleToast, handleToastServiceError } from '../../../../core/utils/UtilsToast'

const queueHandler = {
    queueList: async function (id, schId) {
        this.setState({ loading: true })
        await HospitalApi.getQueueDoctor(id, schId).then((res) => {
            const {
                status,
                data: {
                    data,
                },
            } = res

            if (status !== 200) {
                handleToastServiceError({ message: 'Something went wrong' })
                return
            }

            this.setState({
                info: data.info,
                queue: data.records,
                loading: false,
            })
        }).catch(e => {
            this.setState({ loading: false })
            handleToastServiceError(e)
        })
    },
    loadQueue() {
        const {
            currentHospital,
            match: {
                params: {
                    scheduleId,
                },
            },
        } = this.props

        this.queueList(currentHospital, scheduleId)
    },
    loadDataConfirm: async function (selPatient) {
        const {
            currentHospital,
            match: {
                params: {
                    scheduleId,
                },
            },
        } = this.props

        await AppointmentApi.queueList(currentHospital, scheduleId).then((res) => {
            const {
                status,
                data: {
                    data,
                    message,
                },
            } = res

            if (status !== 200) {
                handleToastServiceError({ message: 'Something went wrong' })
                return
            }

            if (data.length === 0) {
                handleToastServiceError({ message })
            } else {
                this.setState({ queuePopupList: data })
                const getIndex = findIndex(data, (obj) => obj.id === selPatient.id)

                if (getIndex >= 0) {
                    this.handleOpenModal(selPatient, 'call', getIndex)
                }
            }
        }).catch(e => {
            handleToastServiceError(e)
        })
    },
    handleOpenModal(data, type, index) {
        this.setState({
            activeIndex: index || 0,
            isModalOpen: true,
            modalData: {
                type: type || null,
                id: data.id,
                content: "Konfirmasi kedatangan Pasien ini.<br />Apakah Anda Yakin?",
                buttonConfirmText: "Konfirmasi",
                buttonCancelText: "Kembali",
            },
        })
    },
    handleCloseModal(reload) {
        if (reload === true) {
            this.loadQueue()
        }

        this.setState({
            activeIndex: 0,
            isModalOpen: false,
            modalData: null,
        })
    },
    handleSubmitModal() {
        const me = this
        const { modalData } = this.state

        AppointmentApi.confirm(modalData.id).then(function (res) {
            const { status, data } = res

            if (status !== 200) {
                handleToastServiceError({ message: 'Something went wrong' })
                return
            }

            handleToast(data.message)
            me.loadQueue()
            me.setState({ activeTab: 'unconfirmed' })
            setTimeout(() => {
                me.handleCloseModal()
            }, 500);
        }, function (e) {
            handleToastServiceError(e)
        })
    },
    toggleTab(tab) {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    },
    next: async function (isCall) {
        const { activeIndex, animating } = this.state
        const {
            currentHospital,
            match: {
                params: {
                    scheduleId,
                },
            },
        } = this.props

        if (animating) return

        await AppointmentApi.queueList(currentHospital, scheduleId).then((res) => {
            const {
                status,
                data: {
                    data,
                    message,
                },
            } = res

            if (status !== 200) {
                handleToastServiceError({ message: 'Something went wrong' })
                return
            }

            if (data.length === 0) {
                handleToastServiceError({ message })
            } else {
                let nextIndex = 0

                if (isCall === true) {
                    if (activeIndex === data.length) {
                        nextIndex = 0
                    } else {
                        nextIndex = activeIndex
                    }
                } else {
                    nextIndex = activeIndex === data.length - 1
                        ? 0
                        : activeIndex + 1
                }

                this.setState({
                    queuePopupList: data,
                    activeIndex: nextIndex,
                })
            }
        }).catch(e => {
            handleToastServiceError(e)
        })
    },
    previous: async function () {
        const { activeIndex, animating } = this.state
        const {
            currentHospital,
            match: {
                params: {
                    scheduleId,
                },
            },
        } = this.props

        if (animating) return

        await AppointmentApi.queueList(currentHospital, scheduleId).then((res) => {
            const {
                status,
                data: {
                    data,
                    message,
                },
            } = res

            if (status !== 200) {
                handleToastServiceError({ message: 'Something went wrong' })
                return
            }

            if (data.length === 0) {
                handleToastServiceError({ message })
            } else {
                const prevIndex = activeIndex === 0 ? data.length - 1 : activeIndex - 1

                this.setState({
                    queuePopupList: data,
                    activeIndex: prevIndex,
                })
            }
        }).catch(e => {
            handleToastServiceError(e)
        })
    },
    handleCall() {
        const me = this
        const { queuePopupList, activeIndex } = this.state

        me.setState({ loadingCall: true })

        if (queuePopupList.length > 0) {
            const appnId = queuePopupList[activeIndex].id
            const params = {
                action: 'present',
            }

            AppointmentApi.queueUpdateCall(appnId, params).then(function (res) {
                const { status, data } = res

                if (status !== 200) {
                    handleToastServiceError({ message: 'Something went wrong' })
                    return
                }

                me.setState({ loadingCall: false })

                handleToast(data.message)
                if (queuePopupList.length === 1) {
                    me.handleCloseModal(true)
                    setTimeout(() => {
                        me.setState({ activeTab: 'confirmed' })
                    }, 100);
                } else {
                    me.next(true)
                }
            }, function (e) {
                me.setState({ loadingCall: false })
                handleToastServiceError(e)
            })
        }
    },
}

export default queueHandler
