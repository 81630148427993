const routeGroup = 'administration';

export const ModuleConfig = {
    name: 'Module',
    title: 'Module',
    slug: 'module',
    baseUrl: `/${routeGroup}/module`,
    createUrl: `/${routeGroup}/module/add`,
    editUrl: `/${routeGroup}/module/edit/`,
};

export const PermissionConfig = {
    name: 'Permission',
    title: 'User Permission',
    slug: 'permission',
    baseUrl: `/${routeGroup}/permission`,
    createUrl: `/${routeGroup}/permission/add`,
    editUrl: `/${routeGroup}/permission/edit/`,
};

export const PermissionCodeConfig = {
    name: 'PermissionCode',
    title: 'User Permission Code',
    slug: 'permission_code',
    baseUrl: `/${routeGroup}/permission_code`,
    createUrl: `/${routeGroup}/permission_code/add`,
    editUrl: `/${routeGroup}/permission_code/edit/`,
};

export const UserConfig = {
    name: 'Pengaturan Admin',
    title: 'Pengaturan Admin',
    slug: 'user',
    baseUrl: `/${routeGroup}/user`,
    createUrl: `/${routeGroup}/user/add`,
    editUrl: `/${routeGroup}/user/edit/`,
    permissionUrl: `/${routeGroup}/user/permission/`,
};

export const RoleConfig = {
    name: 'Role',
    title: 'Role',
    slug: 'role',
    baseUrl: `/${routeGroup}/role`,
    createUrl: `/${routeGroup}/role/add`,
    editUrl: `/${routeGroup}/role/edit/`,
};

export const RolePermissionConfig = {
    name: 'RolePermission',
    title: 'Role & Permission',
    slug: 'role_permission',
    baseUrl: `/${routeGroup}/role_permission`,
    createUrl: `/${routeGroup}/role_permission/add`,
    editUrl: `/${routeGroup}/role_permission/edit/`,
};

export const AdministrationMenu = [
    {
        index: 'hospital',
        path: '/administration/info-hospital',
        title: 'Pengaturan RS',
        children: null
    },
    {
        index: 'administration',
        path: '/administration/form-patient',
        title: 'Formulir Pasien',
    },
    {
        index: UserConfig.slug,
        path: UserConfig.baseUrl,
        title: UserConfig.title,
        children: null
    }
]

export const AdministrationMenuReg = [
    {
        index: 'administration',
        path: '/administration/form-patient',
        title: 'Formulir Pasien',
    },
]

export const RoleOption = [
    {
        value: '5',
        label: 'Perawat / Queueing Administrator'
    },
    {
        value: '6',
        label: 'Admin Pendaftaran'
    }
]