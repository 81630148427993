import React from 'react'
import Chance from 'chance'
import { isEmpty } from 'lodash'

import Api from '../../../services/apis/BillingHospital'
import { handleToastServiceError } from '../../../core/utils/UtilsToast'

const chance = new Chance();

const GridHandler = {
    gridColumn() {
        return [
            {
                Header: 'Nomer Tagihan',
                accessor: 'invoice_no',
                filterable: true,
                sortable: true,
                headerClassName: '-sort',
                Cell: row => row.original.invoice_no || '-'
            },
            {
                Header: 'Nomer Resume Medis',
                accessor: 'emr_no',
                filterable: true,
                sortable: true,
                headerClassName: '-sort',
                Cell: row => row.original.emr_no || '-'
            },
            {
                Header: 'Nama Pasien',
                accessor: 'patient_name',
                sortable: true,
                headerClassName: '-sort',
                Cell: row => row.original.patient_name || '-'
            },
            {
                Header: 'Jenis Kelamin',
                accessor: 'patient_gender',
                sortable: true,
                headerClassName: '-sort',
                Cell: row => {
                    return row.original.patient_gender === 'M'
                        ? 'Laki-laki'
                        : row.original.patient_gender === 'F'
                            ? 'Perempuan'
                            : '-'
                },
            },
            {
                Header: 'Status Tagihan',
                accessor: 'invoice_status',
                sortable: true,
                headerClassName: '-sort',
                Cell: row => {
                    const badgeStyle = row.original.invoice_status
                        ? 'badge-primary'
                        : 'badge-danger'

                    return (
                        <div className="badge-label">
                            <span className={`badge badge-pill ${badgeStyle}`}>
                                {row.original.invoice_status_text}
                            </span>
                        </div>
                    )
                },
            },
            {
                Header: 'Action',
                width: 105,
                filterable: true,
                filterType: 'reset',
                sortable: false,
                className: 'grid-action center',
                Cell: row => {
                    const { id } = row.original
                    const { history } = this.props

                    if (row.original.invoice_status) {
                        return (
                            <div className="paid-bill">
                                <div onClick={() => this.download(row.original)}>
                                    Unduh
                                </div>
                                <div onClick={() => history.push(`/billing/detail/${id}`)}>
                                    Detail
                                </div>
                            </div>
                        )
                    }

                    return (
                        <button
                            className="process-payment"
                            onClick={() => history.push(`/billing/detail/${id}`)}
                        >
                            Proses Bayar
                        </button>
                    )
                }
            }
        ]
    },
    fetchData: async function (state) {
        this.setState({ loading: true })
        this.page = state.page
        this.pageSize = state.pageSize

        let res = {}
        let fArray = {}
        let sort_property = ''
        let sort_value = ''

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'DESC' : 'ASC'
            this.sorted = state.sorted
        }

        if (state.filtered && state.filtered.length > 0) {
            state.filtered.map(val => {
                if (val.value !== '') {
                    fArray[val.id] = val.value
                }
                return true
            })
        }

        const filterData = JSON.stringify(fArray)

        try {
            res = await Api.list(
                this.currentHospital,
                this.page + 1,
                this.pageSize,
                sort_property,
                sort_value,
                filterData,
            )
        } catch (error) {
            this.setState({ loading: false })

            let message = error.message
            let errData = error.response ? error.response.data : null

            if (!isEmpty(errData) && errData.data !== '') {
                errData = errData.data
                message = Object.keys(errData).map(k => errData[k]).join('\n')
            }

            handleToastServiceError({ message })
            return
        }

        const {
            status,
            data: resData,
        } = res

        if (status === 200) {
            const newRows = resData.data.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })

            this.setState({
                loading: false,
                data: newRows,
                pages: resData.data.total_page,
                rows: resData.data.total_rows,
            })
        } else {
            this.setState({ loading: false })
        }
    },
    download({ id, patient_name: patientName }) {
        const { token, currentHospitalName } = this.props

        const baseURL = process.env.REACT_APP_API_HOST.replace('admin', '')
        const link = `${baseURL}hospital/invoice/download/${id}?hospital_id=${this.currentHospital}`

        fetch(link, {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
                'X-API-Auth': process.env.REACT_APP_X_API_AUTH,
            },
        })
            .then(response => {
                if (response.status === 200) {
                    const fileName = `Tagihan - (${currentHospitalName}) - ${patientName}`
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob)
                        let a = document.createElement('a')
                        a.href = url
                        a.download = fileName
                        a.click()
                    })
                } else {
                    handleToastServiceError({ message: 'File not found.' })
                }
            })
    },
}

export default GridHandler
