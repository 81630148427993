import React from 'react'
import ReactTable from 'react-table'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import { Carousel, CarouselItem } from 'reactstrap';
import { ContextMenuProvider, ContextMenu, Item, theme } from 'react-contexify'

import MasterGrid from '../../../components/grid/MasterGrid'
import MasterModal from '../../../core/utils/MasterModal'
import SliderPanel from '../../../components/wizard/SliderPanel'
import Checkbox from '../../dashboard/components/Checkbox'
import GridHandler from '../handler/GridHandler'
import DataChat from './DataChat'
import DataPrescription from './DataPrescription'
import DataPrescriptionDetail from './DataPrescriptionDetail'
import DetailResumeMedis from './DetailResumeMedis'
import iconPdf from '../../../themes/v1/img/icon-pdf.png'

const CheckboxTable = checkboxHOC(ReactTable)

class Grid extends MasterGrid {
    constructor(props) {
        super(props)

        Object.assign(this, GridHandler)

        this.customFilter = ['DATE', 'DATE_RANGE']
        const initFilter = [
            {
                id: 'range',
                value: 'today',
            }
        ]

        this.clickable = false
        this.filterable = false
        this.selectedTableStatus = false
        this.gridColumn = this.gridColumn.bind(this)
        this.gridMapping = (this.selectedTableStatus) ? CheckboxTable : ReactTable
        this.hidePagination = false
        this.page = 0
        this.pageSize = 20
        this.selectedMax = 10
        this.selected = []
        this.filtered = []
        this.sorted = []
        this.defaultSorted = []
        this.keyTable = '_id'
        this.ignoreColumn = [
            '_id'
        ]
        this.filteredTemp = initFilter

        /* own property */
        this.fetchData = this.fetchData.bind(this)
        this.extraProperties = {
            onFetchData: this.fetchData,
            filterAble: true
        }

        /* data */
        const loading = this.extraProperties.hasOwnProperty('onFetchData') ? true : false
        const data = this.dataFromRedux ? this.props.data : (loading ? [] : this.getData())
        const columns = this.getColumns(data)
        const finalColumn = this.gridColumn(ContextMenuProvider, columns)
        const pages = loading ? null : 1

        this.getFilterList = this.getFilterList.bind(this)
        this.customHandleFilterReset = this.customHandleFilterReset.bind(this)
        this.handleMenuClick = this.handleMenuClick.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.onPanelFirstOpen = this.onPanelFirstOpen.bind(this)
        this.onPanelFirstClose = this.onPanelFirstClose.bind(this)
        this.onPanelSecondOpen = this.onPanelSecondOpen.bind(this)
        this.onPanelSecondClose = this.onPanelSecondClose.bind(this)
        this.downloadFile = this.downloadFile.bind(this)

        this.state = {
            loading: loading,
            loadingFirst: false,
            loadingSecond: false,
            data,
            columns: finalColumn,
            pages: pages,
            rows: 1,
            selection: [],
            deleted: [],
            filtered: initFilter,
            disableHighlight: true,
            headerFilter: {},
            filterList: {},
            filterKeyword: "",
            rangeOpen: false,
            lastRounded: false,
            isModalOpen: false,
            modalData: {},
            checkedConfirm: false,
            isPanelFirstOpen: false,
            panelDataFirst: {},
            isPanelSecondOpen: false,
            panelDataSecond: {},
            activeIndex: 0,
            animating: false,
        }

        this.currentHospital = this.props.currentHospital
    }

    componentDidMount() {
        this.getFilterList();
    }

    componentWillReceiveProps(nextProps) {
        const { currentHospital } = this.props

        if (currentHospital !== nextProps.currentHospital) {
            this.currentHospital = nextProps.currentHospital
            this.customHandleFilterReset(nextProps)
        }
    }

    parseModal() {
        const { modalData, checkedConfirm, loadingFirst, panelDataFirst, activeIndex } = this.state
        const patientName = modalData.data ? modalData.data.patient_name : ''

        if (modalData.action === 'preview') {
            const { file_supports: fileSupports } = panelDataFirst
            const item = fileSupports[activeIndex]
            const fileName = item.name

            return (
                <div className="modal-preview">
                    <div className="info">
                        <div className="filename">{fileName}</div>
                        <div className="action">
                            <button onClick={() => this.downloadFile(item.url, fileName)} className="download">
                                <i className="fas fa-download" />
                            </button>
                            <div onClick={() => this.handleCloseModal()} className="close-pre">
                                <i className="fas fa-times" />
                            </div>
                        </div>
                        <div style={{ clear: 'both' }} />
                    </div>
                    <ol className="carousel-indicators-pre">
                        {this.indicators()}
                    </ol>
                    <Carousel
                        activeIndex={activeIndex}
                        next={this.next}
                        previous={this.previous}
                        interval={false}
                    >
                        {this.slides()}
                        <div>
                            <div
                                className="carousel-control carousel-control-prev"
                                onClick={this.previous}
                            >
                                <div className="arrow">
                                    <i className="fas fa-chevron-left" />
                                </div>
                            </div>
                            <div
                                className="carousel-control carousel-control-next"
                                onClick={this.next}
                            >
                                <div className="arrow">
                                    <i className="fas fa-chevron-right" />
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
            )
        }

        return (
            <div className="master-modal-content emr">
                <div className="master-modal-head">
                    Ketentuan Penggunaan Data
                </div>
                <div className="master-modal-body">
                    <div className="content">
                        Dengan ini saya menyatakan, bahwa telah menerima kuasa dari pasien <strong>{patientName}</strong>,
                        untuk membuka riwayat catatan dokter. Terbatas hanya untuk proses screening dokter saat ini
                        <Checkbox
                            options={[{
                                value: true,
                                label: 'Saya telah konfirmasi',
                            }]}
                            prefix="emrConfirm"
                            onCheck={() => this.setState({ checkedConfirm: true })}
                            onRemove={() => this.setState({ checkedConfirm: false })}
                            items={[checkedConfirm]}
                            class="wrap-checkbox-left emr-confirm"
                        />
                    </div>
                </div>
                <div className="master-modal-foot">
                    <button
                        className={`master-btn confirm${!checkedConfirm ? ' disabled' : ''}`}
                        disabled={!checkedConfirm || loadingFirst}
                        onClick={() => this.onPanelFirstOpen(modalData)}
                    >
                        {loadingFirst ? 'Loading...' : 'Lanjutkan'}
                    </button>
                    <button
                        className="master-btn cancel"
                        onClick={this.handleCloseModal}
                    >
                        Batalkan
                    </button>
                </div>
            </div>
        )
    }

    indicators = () => {
        const { panelDataFirst, activeIndex } = this.state
        const { file_supports: fileSupports } = panelDataFirst

        return fileSupports.map((item, i) => {
            const filename = item.name
            const nameArr = filename.split('.')
            const ext = nameArr[nameArr.length - 1]

            return (
                <li
                    key={i}
                    className={activeIndex === i ? 'active' : ''}
                    onClick={() => this.goToIndex(i)}
                >
                    <div className="img-wrapper">
                        <img
                            src={ext === 'pdf'
                                ? iconPdf
                                : item.url}
                            alt={filename}
                        />
                    </div>
                </li>
            );
        })
    }

    slides = () => {
        const { panelDataFirst } = this.state
        const { file_supports: fileSupports } = panelDataFirst

        return fileSupports.map((item, i) => {
            const filename = item.name
            const nameArr = filename.split('.')
            const ext = nameArr[nameArr.length - 1]

            return (
                <CarouselItem key={i}>
                    <div className="item-img d-flex justify-content-center">
                        {ext === 'pdf'
                            ? <embed src={`${item.url}#toolbar=0&navpanes=0&scrollbar=0`} />
                            : <img src={item.url} alt={filename} />}
                    </div>
                </CarouselItem>
            );
        })
    }

    next = () => {
        const { animating, activeIndex, panelDataFirst } = this.state
        const { file_supports: fileSupports } = panelDataFirst
        if (animating) return;
        const nextIndex = activeIndex === fileSupports.length - 1 ? 0 : activeIndex + 1;
        this.setState({ activeIndex: nextIndex })
    }

    previous = () => {
        const { animating, activeIndex, panelDataFirst } = this.state
        const { file_supports: fileSupports } = panelDataFirst
        if (animating) return;
        const nextIndex = activeIndex === 0 ? fileSupports.length - 1 : activeIndex - 1;
        this.setState({ activeIndex: nextIndex })
    }

    goToIndex = (newIndex) => {
        const { animating } = this.state
        if (animating) return;
        this.setState({ activeIndex: newIndex })
    }

    customHandleFilterReset(nextProps) {
        this.page = 0

        const filterList = nextProps.filterList || this.state.filterList
        const filtered = []
        const headerFilter = []
        const filterListArr = Object.keys(filterList)

        if (filterListArr.length > 0) {
            filterListArr.forEach(key => {
                if (filterList[key].default !== '') {
                    const defaultVal = filterList[key].options.find(opt => opt.value === filterList[key].default)

                    filtered.push({
                        id: key,
                        value: defaultVal.value,
                    })

                    headerFilter.push({ [key]: defaultVal })
                }
            })
        }

        const setHeaderFilter = Object.assign({}, ...headerFilter)
        this.setState({
            rangeOpen: false,
            lastRounded: false,
            filtered,
            headerFilter: setHeaderFilter,
        })

        this.filteredTemp = filtered
        this.fetchData({
            page: this.page,
            pageSize: this.pageSize,
            filtered,
            sorted: this.sorted,
        })
    }

    render() {
        const {
            loading,
            headerFilter,
            filtered,
            filterList,
            rangeOpen,
            lastRounded,
            isModalOpen,
            isPanelFirstOpen,
            panelDataFirst,
            isPanelSecondOpen,
            panelDataSecond,
        } = this.state
        const { history } = this.props
        const menuStyle = { minWidth: '150px' }
        const filterListArr = Object.keys(filterList)

        this.leftAction = []
        if (filterListArr.length > 0) {
            filterListArr.forEach((key, index) => {
                let roundedPos = 'middle'
                const selectDefault = filterList[key].options.filter(s => {
                    return (s.value !== '') && (s.value === filterList[key].default)
                })

                if (index === 0) {
                    roundedPos = 'left'
                } else if ((filterListArr.length - 1) === index) {
                    roundedPos = lastRounded ? 'middle' : 'right'
                }

                this.leftAction.push({
                    actionType: key,
                    type: filterList[key].type,
                    title: filterList[key].placeholder,
                    tooltip: filterList[key].tooltip,
                    filterKeyword: headerFilter[key] ? headerFilter[key].value : filterList[key].default,
                    handlerSubmit: this.handleFilter,
                    selectedOpt: headerFilter[key] || selectDefault,
                    filterOpt: filterList[key].options,
                    path: filterList[key].path,
                    handlerChangeOpt: this.handleFilter,
                    handlerChange: this.handleFilter,
                    className: `rounded ${roundedPos}`,
                })
            })
        }

        const getDateORRange = filtered.find(f => this.customFilter.includes(f.value))
        if (getDateORRange) {
            let roundedPos = 'middle'
            const indexDateORRange = filterListArr.indexOf(getDateORRange.id) + 1

            if (filterListArr.length === indexDateORRange) {
                roundedPos = 'right'
            }

            if (getDateORRange.value === 'DATE_RANGE') {
                this.leftAction.splice(indexDateORRange, 0, {
                    type: 'range',
                    title: 'Date',
                    isOpen: rangeOpen,
                    actionType: `${getDateORRange.id}_range`,
                    filterKeyword: headerFilter[`${getDateORRange.id}_range`],
                    handlerChange: this.handleFilter,
                    className: `rounded ${roundedPos}`,
                })
            } else {
                this.leftAction.splice(indexDateORRange, 0, {
                    type: 'date',
                    title: 'Date',
                    actionType: `${getDateORRange.id}_date`,
                    filterKeyword: headerFilter[`${getDateORRange.id}_date`],
                    handlerChange: this.handleFilter,
                    className: `rounded ${roundedPos}`,
                })
            }
        }

        this.leftAction.push({
            type: 'reset',
            loading: loading,
            tooltip: 'Reset Filter',
            handlerReset: this.customHandleFilterReset,
        })

        const ActionMenu = () => (
            <ContextMenu
                id='action_menu'
                className="menu-custom custom-snippet-menu left-action-snippet-menu"
                theme={theme.light}
                style={menuStyle}
            >
                <Item onClick={(data) => this.handleMenuClick('edit', data.dataFromProvider)}>
                    Edit
                </Item>
                <Item onClick={(data) => this.handleMenuClick('download', data.dataFromProvider)}>
                    Download
                </Item>
                <Item onClick={(data) => this.handleMenuClick('prescription', data.dataFromProvider)}>
                    Riwayat Resep Online
                </Item>
                <Item onClick={(data) => this.handleMenuClick('chat', data.dataFromProvider)}>
                    Riwayat Konsultasi Online
                </Item>
            </ContextMenu>
        )

        return (
            <section className="tables with-top-filter no-paging-top">
                <button onClick={() => history.push('/emr/create')} className="add">
                    Tambah
                </button>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 cn-table-container wrap-text">
                            <div className="card">
                                {super.render()}
                                <ActionMenu />
                            </div>
                        </div>
                    </div>
                </div>
                <SliderPanel
                    {...this.props}
                    open={isPanelFirstOpen}
                    onPanelClose={this.onPanelFirstClose}
                    panelData={panelDataFirst}
                    width={panelDataFirst.action === 'detail' ? '75%' : undefined}
                >
                    {panelDataFirst.action === 'chat'
                        ? <DataChat {...panelDataFirst} />
                        : panelDataFirst.action === 'prescription'
                            ? <DataPrescription
                                {...panelDataFirst}
                                onPanelSecondOpen={this.onPanelSecondOpen} />
                            : <DetailResumeMedis
                                {...panelDataFirst}
                                handleOpenModal={this.handleOpenModal} />}
                </SliderPanel>
                <SliderPanel
                    {...this.props}
                    open={isPanelSecondOpen}
                    onPanelClose={this.onPanelSecondClose}
                    onHandleBack={this.onPanelSecondClose}
                    panelData={panelDataSecond}
                    customOverlay="slider-cinnamon-overlay-two"
                >
                    <DataPrescriptionDetail
                        {...panelDataSecond}
                    />
                </SliderPanel>
                <MasterModal
                    className="master-modal"
                    isOpen={isModalOpen}
                    handleClose={() => this.handleCloseModal()}
                >
                    {this.parseModal()}
                </MasterModal>
            </section>
        )
    }
}

export default Grid
