import { call, put } from "redux-saga/effects";
import LoginApi from "../../apis/LoginApi"
import { 
    LOGIN_API_CALL_SUCCESS, 
    LOGIN_API_CALL_FAILURE
  } from '../../../core/const/LoginConst'

export function* loginWorkerSaga(action) {
    try {
        const response = yield call(LoginApi.login, action.dataPost);
        const logindata = response.data;

        if (response.status === 200) {
            yield put({ type: LOGIN_API_CALL_SUCCESS, dataLogin: logindata });
        } else {
            yield put({ type: LOGIN_API_CALL_FAILURE, error: logindata.message});
        }
        
      } catch (error) {
        let errorText = (error.constructor === Error && error.hasOwnProperty("message")) ? error.message : 'Terjadi kesalahan pada sistem';

        yield put({ type: LOGIN_API_CALL_FAILURE, error: errorText});
      }

  }