import React from 'react'
import { NavLink } from 'react-router-dom'
import dateFns from "date-fns"
import IndonesianLocale from 'date-fns/locale/id'

import Config from '../../../config/DataConfig'

class DoctorList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            hideWhenMoreThan: 3
        }

        this.clearHide = this.clearHide.bind(this)
    }

    clearHide() {
        this.setState({
            hideWhenMoreThan: null
        })
    }

    render() {
        let index = 0

        const selectedDate = dateFns.format(
            this.props.selectedDate,
            'YYYY-MM-DD',
            { locale: IndonesianLocale }
        )

        return (
            <div className="wrap-doctors">
                <ul className="list-doctor">
                    {
                        this.props.doctors.map((data, key) => {
                            index = key + 1

                            return (
                                <li
                                    key={key}
                                    className={
                                        this.state.hideWhenMoreThan && index >= this.state.hideWhenMoreThan
                                            ? "row-list-doctor hide"
                                            : "row-list-doctor"
                                    }
                                >
                                    <span className="doctor-name">
                                        {data.doctor_title + ' ' + data.doctor_name}
                                    </span>
                                    <ul className="list-schedule">
                                        {
                                            data.schedule.map((schedule, key) => {
                                                return (
                                                    <li key={key}>
                                                        {!schedule.expired
                                                            ? (
                                                                <NavLink
                                                                    to={`${Config.assignUrl}/${selectedDate}/${schedule.id}`}
                                                                >
                                                                    {schedule.time_start.slice(0, -3)} - {schedule.time_end.slice(0, -3)}
                                                                </NavLink>
                                                            )
                                                            : (
                                                                <button className="disabled">
                                                                    {schedule.time_start.slice(0, -3)} - {schedule.time_end.slice(0, -3)}
                                                                </button>
                                                            )}

                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </li>
                            )
                        })
                    }
                </ul>
                {
                    this.state.hideWhenMoreThan && index >= this.state.hideWhenMoreThan
                        ? <div className="show-more" onClick={this.clearHide}>
                            <span>Lihat lainnya</span>
                            <i className="fas fa-angle-down" />
                        </div>
                        : null
                }
            </div>
        )
    }
}

export default DoctorList