import Icons from '../../../../core/utils/Icons'
const icon = Icons.dashboardMenu
const Menu = {
    quickButton: false,
    menu: [
        {
            index: 'appointment',
            path: '/appointment/list',
            icon: icon.appointment.normal,
            iconHover: icon.appointment.hover,
            title: 'Appointment',
        },
        {
            index: 'doctor',
            path: '/doctor/list',
            icon: icon.doctor.normal,
            iconHover: icon.doctor.hover,
            title: 'Dokter / Tenaga Medis',
        },
    ]
}

export default Menu