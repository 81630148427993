import React, { Component } from 'react'
import {
    FormBuilder,
    Validators,
    FieldGroup
} from 'react-reactive-form'

import ImageUploadForm from '../../../../../../core/utils/ImageUploadForm'
import TextInputEmailForm from '../../../../../../core/utils/forminput/TextInputEmailForm'
import Handler from '../../../../handler/superadmin/setting/fix/FormHandler'

class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            Form: FormBuilder.group({
                email: ["", [Validators.required, Validators.email]],
            })
        }

        Object.assign(this, ImageUploadForm)
        Object.assign(this, TextInputEmailForm)
        this.TextInputEmailFormView = this.TextInputEmailFormView.bind(this)

        // assign to handler
        Object.assign(this, Handler)
        this.handleSubmit = this.handleSubmit.bind(this)

        this.buildForm()
    }

    render() {
        const { Form } = this.state

        return (
            <FieldGroup control={Form}
                strict={false}
                render={({ invalid }) => {
                    return (
                        <div
                            className="wizard-form custom-form fix"
                            style={{ margin: '18px auto 70px' }}
                        >
                            <form onSubmit={this.handleSubmit} autoComplete="off">
                                {this.emailField}
                                <button
                                    type="submit"
                                    className={
                                        `save itgbtn primary ${invalid
                                            ? 'disabled'
                                            : ''}`
                                    }
                                    disabled={invalid}
                                    onSubmit={this.handleSubmit}
                                >
                                    Fixing
                                </button>
                            </form>
                        </div>
                    )
                }}
            />
        )
    }
}

export default Form
