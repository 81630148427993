import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { loginMapStateToProps } from '../../core/const/LoginConst'
import { isDenied } from '../../core/utils/UtilsPermission'

import User from '../../themes/v1/img/layout/icon/action/patient.png'
import Doctor from '../../themes/v1/img/layout/icon/action/doctor.png'
import EMR from '../../themes/v1/img/layout/icon/action/emr.png'

class QuickAction extends Component {
    render() {
        const { currentHospital, dataLogin } = this.props
        return (
            <ul className="mega-action">
                {!isDenied(currentHospital, dataLogin, 'manage_doctor')
                    ? ( dataLogin.user_type === 'a' ? '' :
                        <li>
                            <Link to="/doctor/findstr">
                                <img className="action-icon" src={Doctor} alt="Dokter" />
                                <span className="title">NaKes</span>
                            </Link>
                        </li>
                    ) : null}
                {!isDenied(currentHospital, dataLogin, 'manage_patient')
                    ? (
                        <li>
                            <Link to="/patient/add">
                                <img className="action-icon" src={User} alt="Data Pasien" />
                                <span className="title">Data Pasien</span>
                            </Link>
                        </li>
                    ) : null}
                {process.env.REACT_APP_HOSPITAL_MEDICAL_RECORD === 'true'
                    && !isDenied(currentHospital, dataLogin, 'medical_record')
                    ? (
                        <li>
                            <Link to="/emr/create">
                                <img className="action-icon" src={EMR} alt="EMR" />
                                <span className="title">Resume Medis</span>
                            </Link>
                        </li>
                    ) : null}
            </ul>
        )
    }
}

export default connect(loginMapStateToProps)(QuickAction)
