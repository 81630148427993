import axios from '../MasterConfig'
const groupUrl = "service_schedule"

class Api {
    static get = (serviceId, year, month, day, date, scheduleId) => {
        const setDay = day ? `&day=${day}` : '';
        const setDate = date ? `&date=${date}` : '';

        if (scheduleId) {
            return axios.get(`/${groupUrl}/${scheduleId}?service_id=${serviceId}&year=${year}&month=${month}${setDay}${setDate}`)
        }
        
        return axios.get(`/${groupUrl}?service_id=${serviceId}&year=${year}&month=${month}${setDay}`)
    }
    static post = (data) => axios.post(`/${groupUrl}/create`, data)
    static view = (scheduleId) => axios.get(`/${groupUrl}/view/${scheduleId}`)
    static update = (id, data) => axios.put(`/${groupUrl}/update/${id}`, data)
    static delete = (id) => axios.delete(`/${groupUrl}/delete/${id}`)
    static deleteDraft = (hospitalId, serviceId) => axios.delete(`/${groupUrl}/deletedraft/${hospitalId}/${serviceId}`)
}
export default Api