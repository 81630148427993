/* eslint-disable */

import React from 'react'
import Chance from 'chance'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'

import Api from '../../../../../services/apis/Appointment/List'
import {
    handleToastServiceError
} from '../../../../../core/utils/UtilsToast'

const chance = new Chance()

const GridHandler = {
    onPanelClose() {
        this.setState({
            isPaneOpen: false,
            dataEdit: [],
        })
    },
    onPanelOpen(data) {
        this.setState({
            isPaneOpen: true,
            // dataEdit: data,
            dataEdit: [ // dummy
                {
                    name: 'View Menu NaKes',
                    view: true,
                    edit: false,
                },
                {
                    name: 'Edit NaKes',
                    view: false,
                    edit: true,
                },
                {
                    name: 'View Appointment',
                    view: true,
                    edit: true,
                },
                {
                    name: 'Cancel Appointment',
                    view: false,
                    edit: false,
                },
                {
                    name: 'Confirm Appointment',
                    view: true,
                    edit: true,
                },
            ],
            panelData: {
                headTitle: `Hak Akses ${data.doctor_name || ''}`
            },
        })
    },
    handleOpenModal(ModalData) {
        this.setState({
            isModalOpen: true,
            ModalData: ModalData
        })
    },
    handleCloseModal() {
        this.setState({
            isModalOpen: false,
            ModalData: null
        })
    },
    modalHandleSubmit() {
        const { ModalData } = this.state
        console.log('modal submit confirm delete', ModalData)

        this.handleCloseModal()
        
        // if (ModalData.action === 'delete_role') {
        //     Api.delete(ModalData.id).then(function (response) {
        //         let state = {
        //             pageSize: this.pageSize,
        //             page: this.page,
        //             sorted: this.sorted,
        //             filter: ''
        //         }
        //         this.fetchData(state, null)
        //         handleToast(response.data.message)
        //         this.handleCloseModal()
        //     }, function (err) {
        //         handleToastServiceError(err, () => {
        //             this.handleCloseModal()
        //         })
        //     })
        // }
    },
    gridColumn() {
        return [
            {
                Header: "Peran",
                accessor: "doctor_name",
                Cell: (row) => {
                    return (<div className="row-float">{row.original.doctor_name}</div>)
                }
            },
            {
                Header: "Status",
                accessor: "status",
                width: 120,
                Cell: (row) => {
                    return (<div className="row-float">{row.original.status}</div>)
                }
            },
            {
                Header: "",
                width: 120,
                className: "grid-action",
                Cell: (row) => {
                    return (
                        <a onClick={() => this.onPanelOpen(row.original)}><FontAwesomeIcon icon={faPencilAlt} /></a>
                    )
                }
            }
        ]
    },
    fetchData: async function (state) {
        console.log('load data')

        let fArray = {}
        let sort_property = ''
        let sort_value = ''
        let res = {}

        state.filtered.map(val =>
            fArray[val.id] = val.value
        )

        const filterData = JSON.stringify(fArray)

        if (state.sorted !== null && state.sorted.length > 0) {
            sort_property = state.sorted[0].id
            sort_value = (state.sorted[0].desc) ? 'DESC' : 'ASC'
            this.sorted = state.sorted
        }

        try {
            res = await Api.list(
                state.page + 1,
                state.pageSize,
                sort_property,
                sort_value,
                filterData,
                3333,
                null,
                'all'
            )
        } catch (e) {
            this.setState({
                loading: false
            })

            handleToastServiceError(e)
            return
        }

        if (React.isValidElement(res)) {
            return
        }

        if (res.data.code === 200) {
            let newRows = res.data.data.rows.map(item => {
                const _id = chance.guid()
                return {
                    _id,
                    ...item
                }
            })

            if (this._isMounted) {
                this.setState({
                    data: newRows,
                    pages: res.data.data.total_page,
                    rows: res.data.data.total_rows,
                    loading: false,
                    disableHighlight: this.state.disableHighlight
                })
            }

        } else {
            this.setState({
                loading: false
            })
        }
    }
}

export default GridHandler
