import React, { Component } from 'react'
import MasterTemplate from '../../template/MasterTemplate';
import Form from './components/Form';

class AddMap extends Component
{
    componentDidUpdate() {

    }

    render() {
        return(
            <MasterTemplate {...this.props}>
                <Form {...this.props} />
            </MasterTemplate>
        )
    }
}

export default AddMap