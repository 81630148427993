import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import connect from 'react-redux/es/connect/connect'

import DashboardTemplate from '../../template/DashboardTemplate'
import DoctorGrid from '../doctor/list/components/Grid'

import { loginMapStateToProps, loginMapDispatchToProps } from '../../core/const/LoginConst'

class DashboardDoctor extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataGrid: [],
        }

        this.callBackState = this.callBackState.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    callBackState(state) {
        this.setState({
            dataGrid: state,
        })
    }

    render() {
        const {
            dataLogin,
            isLoggin,
            history,
            store,
            title,
        } = this.props
        let isAdmin = false

        if (isLoggin === true) {
            isAdmin = dataLogin.superadmin
        }

        if (!isAdmin) {
            return (
                <Redirect to="/dashboard" />
            )
        }

        return (
            <DashboardTemplate {...this.props} >
                <div className="dashboard-container wrapper-dashdoctor">
                    <h2 className="no-margin-top page-title">{title}</h2>
                    <DoctorGrid {...this.props} callBackState={this.callBackState} history={history} store={store} isDashboard={isAdmin} />
                </div>
            </DashboardTemplate>

        )
    }
}

export default connect(loginMapStateToProps, loginMapDispatchToProps)(DashboardDoctor)
