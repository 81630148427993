import React from 'react'
import { FieldControl } from 'react-reactive-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import validateReturn from '../ValidatorForm'

const TextInputPasswordForm = {
    TextInputPasswordFormView: function ({ label,
        validator,
        name,
        placeholder,
        control,
        readonly,
        maxLength,
        callback,
        togglepass,
        fieldControlAdditional,
    }) {
        const callBack = callback || null
        const togglePass = togglepass || null
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}
        Object.assign(control, additionalController)

        return <FieldControl
            name={name}
            render={(obj) => this.TextInputPasswordFormRender(obj, callBack, togglePass)}
            meta={{
                label: label,
                name: name,
                maxLength: maxLength,
                readonly: readonly,
                placeholder: placeholder,
                validator: validator,
            }}
            control={control}
        />
    },
    validateExtra(name, callback) {
        if (typeof callback === 'function') {
            callback(this, name)
        }
    },
    TextInputPasswordFormRender: function ({ handler, touched, hasError, meta }, callback, togglepass) {
        const isRequired = meta.validator === 'required' ? true : false
        const showPass = this.state[`${meta.name}_show_pass`]

        return (
            <div className={touched && hasError(meta.validator)
                ? "form-line pass has-danger"
                : "form-line pass"}
            >
                {
                    meta.label ?
                        <label
                            id={`label-${meta.name}`}
                            className="form-control-label"
                            htmlFor={`${meta.name}`}
                        >
                            {meta.label}
                            {isRequired ? <span className="required">*</span> : null}
                        </label> :
                        null
                }
                <div className="form-group">
                    <input
                        className="form-control form-control-success"
                        type={showPass ? 'text' : 'password'}
                        placeholder={meta.placeholder}
                        id={meta.name}
                        {...handler()}
                        readOnly={meta.readonly}
                        maxLength={meta.maxLength}
                        onKeyUp={() => this.validateExtra(meta.name, callback)}
                    />
                    <FontAwesomeIcon
                        size="sm"
                        className="show-pass"
                        icon={showPass ? faEyeSlash : faEye}
                        onClick={() => togglepass(this, meta.name)}
                    />
                </div>
                <span className="error-info">
                    {
                        touched
                        && hasError(meta.validator)
                        && validateReturn(meta.validator)
                    }
                </span>
            </div>
        )
    }
}

export default TextInputPasswordForm
