import React from 'react'
import validateReturn from './ValidatorForm'

import {FieldControl} from "react-reactive-form"

const TextInputForm = {
    TextInputFormView: function({label, validator, name, control, inputType, fieldControlAdditional}) {
        let additionalController = (fieldControlAdditional) ? fieldControlAdditional : {}        
        
        Object.assign(control, additionalController)

        return <FieldControl
            name={name}
            render={(obj) => this.TextInputFormRender(obj, this.handleFocus)}
            meta={{ label: label, name: name, validator: validator, inputType: inputType || 'text' }}
            control = { control }
        />
    },

    TextInputFormRender: function({handler, touched, hasError, meta}, handleFocus) {
        const newHandler = handler()
        Object.assign(newHandler, {
            onFocus: handleFocus,
        })
        return (
            <div onBlur={this.handleBlur} className={touched && hasError(meta.validator) ? "form-line has-danger" : "form-line"}>
                <div className="form-group">
                    <input type={meta.inputType} className="form-control form-control-success" id={`${meta.label}`} {...newHandler}  />
                    <label id={`label-${meta.name}`} className="form-control-label" htmlFor={`${meta.label}`}>
                        {`${meta.label}`}
                        {/* {meta.validator && <span style={{color: 'red'}}>*</span>} */}
                    </label>
                </div>
                <span className="error-info">
                    {
                        touched
                        && hasError(meta.validator)
                        && validateReturn(meta.validator)
                    }
                </span>
            </div>
        )
    },

    handleFocus(event) {
        if(event.target.value === '' && !event.target.parentNode.classList.contains('focused')){
            event.target.parentNode.classList.toggle('focused')
        }
    },
    handleBlur(event){
        if(event.target.value === '' && event.target.parentNode.classList.contains('focused')){
            event.target.parentNode.classList.toggle('focused')
        }
    }
}

export default TextInputForm
